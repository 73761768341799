import styled from "styled-components";
import { FaRegBuilding } from "react-icons/fa";
import { color } from "../constant/color";
import { IoSpeedometerOutline } from "react-icons/io5";
import { CgSmartphoneChip } from "react-icons/cg";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { IoIosWater } from "react-icons/io";
import LineCharts from "./Charts/LineChart";
import { numFormtter } from "../helperFunctions/numFormatter";
import Skimmer from "./Skimmer";

const TopCard = ({
  value,
  bgType,
  btInfo,
  iconType,
  activeValue,
  activeText,
  inActiveValue,
  inActiveText,
  tag,
  data,
  price,
  percentageChange,
  loading,
}) => {
  return (
    <CardWrapper type={bgType}>
      {loading ? (
        <Skimmer width={"100%"} heigth={"100%"} />
      ) : (
        <>
          <div
            className="card-top d-flex align-items-center justify-content-between"
            style={{
              marginTop: "-5px",
            }}
          >
            <CardTag tag={tag}>{tag.toUpperCase()}</CardTag>
            <div className="d-flex align-items-center justify-content-center">
              <div className="card-i d-flex align-items-center justify-content-center">
                {percentageChange < 0 ? (
                  <BsArrowDown size={14} color={color.redColor} />
                ) : (
                  <BsArrowUp size={14} color={color.greenColor} />
                )}
              </div>
              <div
                style={{
                  color: !percentageChange
                    ? color.greenColor
                    : percentageChange < 0
                    ? color.redColor
                    : color.greenColor,
                  fontWeight: "510",
                }}
              >
                {isNaN(percentageChange) ? "0" : percentageChange}.%
              </div>
            </div>
          </div>
          <div className="card-middle d-flex align-items-start justify-content-between ">
            <div
              className="card-i d-flex justify-content-center align-items-center"
              style={{
                // background: "#ebffd0",
                background:
                  tag === "pms"
                    ? "#ebffd0"
                    : tag === "ago"
                    ? "#cee7ff"
                    : tag === "dpk"
                    ? "#ffd8d1"
                    : tag === "lpg"
                    ? "#e6e6e6"
                    : "dodgerblue",
                width: "40px",
                height: "35px",
                borderRadius: "4px",
              }}
            >
              <IoIosWater
                size={25}
                color={
                  tag === "pms"
                    ? color.greenColor
                    : tag === "ago"
                    ? color.blue
                    : tag === "dpk"
                    ? color.redColor
                    : tag === "lpg"
                    ? "#000000"
                    : "dodgerblue"
                }
              />
            </div>
            <div
              className=""
              style={{
                fontSize: "18px",
                color:
                  tag === "pms"
                    ? color.greenColor
                    : tag === "ago"
                    ? color.blue
                    : tag === "dpk"
                    ? color.redColor
                    : tag === "lpg"
                    ? "#000000"
                    : "dodgerblue",
                fontWeight: "510",
              }}
            >
              {price}L
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              width: "95%",
              margin: "auto",
              height: "70px",
              top: "55px",
              left: "5px",
            }}
          >
            <LineCharts tag={tag} data={data} />
          </div>
        </>
      )}
    </CardWrapper>
  );
};

export default TopCard;

const switchBg = (type) => {
  switch (type) {
    case "purple":
      return `linear-gradient(160deg, #e19292, #537cfe)`;
    case "orange":
      return `linear-gradient(160deg, #EEC744, #F8786A)`;
    case "green":
      return `green`;
    case "blue":
      return `linear-gradient(160deg, #5EE6EB, #4E81FD)`;
    default:
      return `white`;
  }
};

const CardWrapper = styled.div`
  width: 100%;
  height: 130px;
  position: relative;
  border-radius: 10px;
  padding: 10px;
  // background: ${({ type }) => switchBg(type)};
  background: ${color.whiteColor};
  box-shadow: 0 2px 5px #eeeeeede;
`;

const CardValue = styled.div`
  position: absolute;
  color: #fff;
`;

const CardBottom = () => styled.div`
  position: absolute;
  bottom: 10px;
  width: 98%;
  // margin: auto;
  // height: 100px;
`;
const CardTag = styled.div`
  font-size: 18px;
  font-weight: 510;
  color: ${({ tag }) =>
    tag === "pms"
      ? color.greenColor
      : tag === "ago"
      ? color.blue
      : tag === "dpk"
      ? color.redColor
      : tag === "lpg"
      ? color.darkColor
      : "dodgerblue"};
`;

const CardPercentage = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: ${({ percentageChange }) =>
    percentageChange < 0 ? color.greenColor : color.redColor};
`;
