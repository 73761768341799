export const data = [
  {
    state: "Bassa",
    path: {
      id: "Path_7446",
      d: "M63.807,24.187V1L83.13,4.22,96.012,9.373h10.95l3.865,9.017,1.288,2.576v9.661l-1.288,9.017-2.576,6.441v9.661l2.576,1.288h3.865l3.22,3.22,2.576,3.865v9.017L119.2,77.647v8.373l-4.509,7.085V104.7L103.1,108.564l-10.306,3.22L79.265,120.8,70.892,133.04v4.509c.215,2.361.515,7.472,0,9.017s-5.8,9.232-8.373,12.882v9.017L56.078,178.77l-11.594,9.661-7.729,4.509H22.585L15.5,188.432l2.576-3.22V169.753l1.932-6.441-1.932-6.441-2.576-5.8v-4.509l4.509-6.441L15.5,116.937H25.806l7.085-5.153V104.7l-7.085-9.017-5.8-11.594V71.206l5.8-17.391,7.085-7.729,9.017-7.729Z",
      translate: { x: "4.179" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Bassa-2",
      name: "Bassa",
      fill: "#001755",
      translate: { x: "49.368", y: "76.315" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M",
      name: "6M",
      fill: "#001755",
      translate: { x: "56.426", y: "98.479" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "JOS_NORTH",
    path: {
      id: "Path_7447",
      dataName: "JOS NORTH",
      d: "M80.543,88.3l21.9-5.8,7.729,5.8-1.932,4.509v18.679l1.932,7.085,1.288,10.95-6.441-1.288L92.137,126.3l-18.679-3.22-5.8-2.576-8.373-4.509L58,110.2,67.661,96.67Z",
      translate: { x: "16.427", y: "23.487" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "JOS_NORTH-2",
      name: "JOS NORTH",
      fill: "#001755",
      translate: { x: "122.758", y: "98.479" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M",
      name: "5M",
      fill: "#001755",
      translate: { x: "91.173", y: "141.519" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "JOS_SOUTH",
    path: {
      id: "Path_7448",
      dataName: "JOS SOUTH",
      d: "M79.231,114.653,67.637,109.5,65.7,114.653l-8.373,14.17V137.2l-6.441,9.661L33.5,160.384l1.932,3.865v8.373L33.5,182.283l8.373,1.932,9.017,2.576,3.22,5.153c.429,1.288,1.675,3.993,3.22,4.509s2.362-2.792,2.576-4.509l2.576-5.153h5.153l3.22,5.153H76.01l9.017-5.153,5.153-10.306,11.594-16.1,5.8-9.017V133.332h9.661V121.738Z",
      translate: { x: "9.366", y: "31.269" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "JOS_SOUTH-2",
      name: "JOS SOUTH",
      fill: "#001755",
      translate: { x: "49.368", y: "192.432" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M",
      name: "4M",
      fill: "#001755",
      translate: { x: "76.529", y: "174.956" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Jos_East",
    path: {
      id: "Path_7449",
      dataName: "Jos East",
      d: "M97,93.814l2.576-5.153h3.865l9.661-1.288,9.017-1.932,10.306-1.932,11.594-3.22L151.748,79H162.7l9.661,1.288,7.085,3.22,3.22,3.865v5.153l-1.288,5.153,2.576,6.441,4.509,7.085,1.288,4.509-1.288,8.373-2.576,9.661-6.441,12.238L172.359,155l-11.594,5.153L151.1,166.6l-5.153,1.288-7.085,7.085-8.373,6.441-7.729,1.288-9.017-1.288-4.509-4.509-5.8-5.153-1.932-5.153-1.932-6.441-.644-7.085-.644-10.306,1.288-11.594-.644-10.95L97,113.137Z",
      translate: { x: "27.667", y: "22.479" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Jos_East-2",
      name: "Jos East",
      fill: "#001755",
      translate: { x: "150.541", y: "144.769" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M",
      name: "3M",
      fill: "#001755",
      translate: { x: "158.083", y: "160.956" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "RIYOM",
    path: {
      id: "Path_7450",
      d: "M13.238,151.653l6.441-5.153,7.729,4.509H40.29l2.576-1.288,1.288,5.153v7.085l-1.288,9.017,16.746,5.153,5.8,7.729,2.576,1.932,1.288-5.8,2.576-3.865H77l3.22,5.153h6.441l7.729-3.865v16.1l-7.085,12.238-14.17,9.661L58.324,232.809l-8.373,10.95-5.8,9.017-3.22-3.22H33.2l-9.017-5.8-4.509-7.729-3.865-9.661-2.576-1.288L4.22,218.639,1,207.689V200.6l3.22-7.085,3.865-7.085L4.22,183.858,1,179.993v-7.729l7.085-1.288,5.153-5.153v-14.17Z",
      translate: { x: "0", y: "41.932" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "RIYOM-2",
      name: "RIYOM",
      fill: "#001755",
      translate: { x: "19.035", y: "235.027" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_7M",
      name: "7M",
      fill: "#001755",
      translate: { x: "28.535", y: "252.569" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Barakin_Ladi",
    path: {
      id: "Path_7451",
      dataName: "Barkin Ladi",
      d: "M32.5,264.048l2.576-5.153,5.8-10.305,7.085-8.373,16.1-19.323,14.17-9.661,7.085-12.238.644-18.679,5.8-10.95L108.5,145.535V127.5h9.661v19.967l3.865,10.95,10.306,9.661h14.814l4.509,4.509,8.373,5.153,3.865,5.153-3.865,10.95-8.373,6.441h-10.95l-4.509,2.576-3.865,11.594-3.22,9.017-5.153,6.441-1.932,10.306-7.729,6.441-11.594,1.932L90.469,258.9c-2.791,1.717-8.76,5.153-10.306,5.153H69.858l-18.035,7.085L32.5,275.642V264.048Z",
      translate: { x: "9.078", y: "36.456" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Barkin_Ladi-2",
      name: "Barkin Ladi",
      fill: "#001755",
      translate: { x: "98.258", y: "231.776" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_1M",
      name: "1M",
      fill: "#001755",
      translate: { x: "108.758", y: "252.333" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "BOKKOS",
    path: {
      id: "Path_7452",
      d: "M35.958,256.222v-5.8l19.967-4.509L74.6,238.187h10.95l9.017-4.509,12.882-10.95,12.238-2.576,5.8-5.153,1.932,2.576,14.17,12.882,11.594,15.458,8.373,23.832v20.611l-2.576,18.679L155.76,324.5l-2.576,5.153-10.95,9.661-26.408,3.865-32.2-3.865-16.746-2.576V324.5l-9.017-5.8-1.932-3.865,1.932-13.526-4.509-8.373H34.026l-11.594-14.17L20.5,271.68l10.306-4.509Z",
      translate: { x: "5.62", y: "61.673" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "BOKKOS-2",
      name: "BOKKOS",
      fill: "#001755",
      translate: { x: "76.529", y: "336.066" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_7M-2",
      name: "7M",
      fill: "#001755",
      translate: { x: "98.258", y: "357.235" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "MANGU",
    path: {
      id: "Path_7453",
      d: "M144.366,148l-16.1,15.458,10.95,8.373,5.153,5.8-5.153,10.95-8.373,5.8h-9.661l-4.509,2.576-7.085,20.611-5.8,6.441L102.5,234.309l15.458,16.1,12.882,14.814,7.085,24.476v19.967l-1.932,19.323-5.153,19.323h5.153v6.441l3.865,7.085v13.526h4.509l4.509-6.441,10.95-14.17,13.526-11.594,12.238-8.373v-5.8l-2.576-9.661V296.142l-3.865-6.441V274.243l6.441-15.458,5.8-9.661,2.576-14.814-5.153-16.746-5.8-18.035,8.373-6.441,14.17,3.865-3.22-3.865L193.962,186v-5.153h-8.373v-25.12h-6.441l-10.95,7.729-8.373-3.865-7.729-9.017Z",
      translate: { x: "29.251", y: "42.364" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "MANGU-2",
      name: "MANGU",
      fill: "#001755",
      translate: { x: "161.779", y: "292.406" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M-2",
      name: "6M",
      fill: "#001755",
      translate: { x: "178.465", y: "316.184" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "SHENDAM",
    path: {
      id: "Path_7454",
      d: "M179.458,478.067,164,481.932l2.576-12.882,3.22-5.153,5.8-12.238L179.458,442l7.085-15.458-4.509-10.95,1.932-6.441,4.509-5.8-1.932-10.95,1.932-5.8,5.153-7.085,6.441-2.576-6.441-7.729h-7.085l-2.576-8.373V348.6l2.576-10.95-4.509-6.441-6.441-11.594v-7.729l3.22-17.391,9.661,1.288-4.509,5.153c-.644,3.865-1.932,12.5-1.932,16.1a31.924,31.924,0,0,0,1.932,9.661l4.509,4.509h28.34l9.017,1.932,11.594,11.594,7.729,3.865h23.832l29.628-3.865,15.458-1.932-1.932,12.238v28.984l-9.017,13.526h-16.1l-5.153,3.865L278,409.149,273.5,426.54l2.576,13.526L278,451.659l7.085,15.458,7.085,14.814,8.373,13.526,5.153,15.458H296.04l-19.967-3.865-14.814-1.932-5.153-4.509-7.729-3.22-7.729-1.932-8.373-3.865h-6.441l-15.458-9.661-14.814-3.865Z",
      translate: { x: "46.975", y: "84.584" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "SHENDAM-2",
      name: "SHENDAM",
      fill: "#001755",
      translate: { x: "247.688", y: "493.024" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_7M-3",
      name: "7M",
      fill: "#001755",
      translate: { x: "270.232", y: "517.299" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Quaian_pan",
    path: {
      id: "Path_7455",
      dataName: "Quan/pan",
      d: "M144.13,306.382h-5.8L126.1,316.687l2.576,3.865,3.22,7.729v9.661l-3.22,7.085-7.085,7.085-3.865,8.373-2.576,7.729-3.22,2.576H104.2l-8.373-2.576-1.288,14.814-4.509,1.932-5.8,1.932-6.441,9.661L76.5,405.573l7.729,6.441v6.441l5.8,2.576,5.8,7.085v10.95h3.22l5.153,3.865v19.323l3.865,3.865,5.153,2.576h14.17v5.8L139.621,479v9.017l7.729,2.576,7.085-4.509L183.42,484.8l6.441-1.932,1.932-14.17,9.017-16.746,11.594-25.12-4.509-10.95,1.932-6.441,4.509-5.153L212.4,392.691l1.932-5.8,4.509-7.729,7.085-2.576-7.085-7.085H212.4l-2.576-9.017V348.892l2.576-10.95L200.81,320.552v-9.017l3.22-16.746L192.437,293.5l-11.594,7.085-13.526,12.238-15.458,21.255H147.35V320.552l-4.509-9.017Z",
      translate: { x: "21.758", y: "84.296" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Quan_pan-2",
      name: "Quan/pan",
      fill: "#001755",
      translate: { x: "159.447", y: "470.007" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M-3",
      name: "6M",
      fill: "#001755",
      translate: { x: "171.965", y: "493.024" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "PANKSHIN",
    path: {
      id: "Path_7456",
      d: "M174.238,183l13.526,2.576,10.306,10.306,6.441,7.729,3.865,3.22h15.458v19.323h7.729c1.073.429,3.22,1.546,3.22,2.576v3.22l-1.288,4.509-1.932,4.509v9.661l-5.153,1.288-1.288,4.509,2.576,3.865,5.8,3.22,3.22,9.661,3.865,12.882,1.288,12.238h3.865l5.153-3.22,8.373-5.153,5.8-10.306,5.8-8.373h5.8l7.085,1.932,6.441,4.509,3.865,8.373v5.8L291.463,297l-3.22,5.8-4.509,8.373c-.859,2.147-2.705,6.441-3.22,6.441-.644,0-3.22,0-3.865,1.288-.515,1.031-5.368,1.717-7.729,1.932l-3.865,1.288c-1.073,1.717-3.478,5.668-4.509,7.729a7.549,7.549,0,0,1-2.576,3.22h-1.932l-1.932-3.22-3.22-2.576-3.22.644-.644,3.22-1.288,5.8V340.8l-3.865-1.288a33.425,33.425,0,0,1-5.153-2.576c-.644-.644-3.22-5.153-3.22-7.085,0-1.546-3.865-.644-5.8,0l-2.576,3.22-1.288,7.729c-.215,1.932-.515,6.055,0,7.085s-2.361.429-3.865,0l-5.153-5.153-6.441-8.373-11.594-7.085-12.238-1.288h-16.1v-8.373l-2.576-7.729V286.055L162,279.614v-16.1l6.441-14.814,5.8-7.729,3.22-16.746-11.594-34.781Z",
      translate: { x: "46.399", y: "52.451" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "PANKSHIN-2",
      name: "PANKSHIN",
      fill: "#001755",
      translate: { x: "228.012", y: "361.744" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_9M",
      name: "9M",
      fill: "#001755",
      translate: { x: "244.01", y: "343.066" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "KANKE",
    path: {
      id: "Path_7457",
      d: "M216.441,193,210,203.306v19.323h7.729l3.22,3.22-1.288,7.085-1.932,5.153c.215,3.005.644,9.146.644,9.661s-3.436,1.073-5.153,1.288l-1.932,5.153,3.22,3.22,5.153,2.576v1.932l7.085,20.611,1.288,12.882H231.9l14.17-9.017,10.306-18.679h6.441l7.085,1.932,6.441,5.153,3.22,6.441,1.288,5.153,1.932-2.576,6.441-7.085,5.153-9.017,3.865-10.95,3.865-15.458,1.932-15.458-2.576-15.458-5.153-6.441-8.373-8.373-3.865,1.932-4.509,2.576h-6.441l-1.288-.644H266.68l-6.441,1.932-14.17,3.865H231.9l-3.865-3.865-5.153-5.153Z",
      translate: { x: "60.232", y: "55.333" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "KANKE-2",
      name: "KANKE",
      fill: "#001755",
      translate: { x: "290.326", y: "291.83" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_7M-4",
      name: "7M",
      fill: "#001755",
      translate: { x: "303.115", y: "310.538" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "KANAM",
    path: {
      id: "Path_7458",
      d: "M288.535,196.85,270.5,213.6l14.17,16.1L286.6,247.09l-1.932,14.17-3.22,10.306-1.932,10.95h7.729l9.017,1.288,5.153,2.576,4.509,3.22,5.153,6.441,2.576,6.441,1.288,6.441,7.729,1.288,15.458-5.8,10.306-4.509,3.865-3.865-1.932-2.576-3.865-1.932-1.932-1.932v-3.22l5.8-7.085,7.729-3.865,14.814-5.8v-10.95l3.865-8.373,2.576-6.441,7.085-5.8,8.373-1.932,7.729,1.932,10.306,3.22h5.8l9.017-3.22h9.017l14.17,10.306,10.95,8.373,9.017,4.509h9.661l14.17-4.509,9.661-5.153,7.729,1.932,5.8-8.373-5.8-9.661-6.441-5.8-9.017-3.865-6.441-8.373-9.661-10.306-9.661-4.509-15.458-5.8-11.594-9.017-9.661-9.661-10.95-1.932-18.035,3.22H394.81l-1.932-5.153L389.014,164l-9.661-8.373-8.373-6.441-10.95-3.22H336.842l-8.373-1.932-5.8-4.509-9.017-7.729-5.8-5.8-8.373,11.594-7.085,1.932,5.153,4.509,3.865,5.153,6.441,8.373L315.587,164l4.509,7.085c-.859,2.361-2.705,7.343-3.22,8.373s-2.792,1.717-3.865,1.932l-5.153,1.932-5.153-1.932c-1.717-.644-5.41-1.932-6.441-1.932s-5.582-.859-7.729-1.288v3.22l1.932,6.441v7.085Z",
      translate: { x: "77.667", y: "36.024" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "KANAM-2",
      name: "KANAM",
      fill: "#001755",
      translate: { x: "395.169", y: "259.333" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_7M-5",
      name: "7M",
      fill: "#001755",
      translate: { x: "407.169", y: "281.876" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Langtang_north",
    path: {
      id: "Path_7459",
      dataName: "Langtang  north",
      d: "M294.79,248H283.84L277.4,258.95l-7.729,7.729-1.932,4.509L255.5,298.239l8.373,8.373h8.373v10.306l5.153,10.306,6.441,5.153,6.441,3.22,11.594,2.576,3.865,3.865v4.509l-3.865,3.22V357.5L298.01,362l-5.8,3.865-5.8,1.932V397.43l3.865,1.932,9.017-1.932,14.17,1.932,3.22-20.611-3.22-8.373v-7.085l3.22-5.8V338.173l-3.22-5.8v-5.153h3.22l5.8-2.576,5.153-7.729v-42.51h-8.373l-2.576-11.594-5.8-8.373-9.017-4.509Z",
      translate: { x: "73.344", y: "71.183" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Langtang_north-2",
      name: "Langtang \n          north",
      fill: "#001755",
      translate: { x: "350.083", y: "355.422" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-2",
      name: "5M",
      fill: "#001755",
      translate: { x: "368.169", y: "389.021" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Mikang",
    path: {
      id: "Path_7460",
      d: "M286.852,295.373,278.479,287l-3.22.644-2.576.644-1.932,1.288-10.306,2.576-7.085,10.95h-1.932l-5.153-5.8-3.22.644-1.932,8.373v4.509l-9.017-3.865-2.28-3.865-.94-3.22-1.288-.644-4.509.644-2.576,3.22-.644,3.865-.644,5.8v5.153l-.644.644-3.22-.644-5.153-5.153-6.441-8.373-11.594-7.085-7.085-.644-5.153,7.085L178,321.137l1.932,7.729,5.153,4.509h27.7l9.017,1.932,10.95,11.594,9.017,3.865H265.6l9.017-1.288,34.781-4.509-1.288,11.594,10.306-4.509,5.8-5.153v-8.373l3.865-3.22v-3.865l-3.865-4.509-5.8-1.288-9.017-2.576-9.017-5.8-5.153-11.594V295.373Z",
      translate: { x: "51.01", y: "82.422" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Mikang-2",
      name: "Mikang",
      fill: "#001755",
      translate: { x: "281.826", y: "403.021" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-3",
      name: "5M",
      fill: "#001755",
      translate: { x: "300.326", y: "421.729" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Langtang_south",
    path: {
      id: "Path_7461",
      dataName: "Langtang south",
      d: "M322.283,375.738l-7.085-8.373L301.672,363.5l-9.661,3.865L287.5,363.5l-9.661,14.814H262.382l-5.153,2.576-4.509,8.373L249.5,407.3l3.22,23.187,14.814,32.2,7.729,12.238,5.8,15.458,20.611-8.373,23.832-16.1,18.679-16.747,7.729-10.95V418.892l-7.729-7.729h-9.017l-7.085-6.441L325.5,384.755Z",
      translate: { x: "71.615", y: "104.469" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Langtang_south-2",
      name: "Langtang south",
      fill: "#001755",
      translate: { x: "332.083", y: "524.299" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_7M-6",
      name: "7M",
      fill: "#001755",
      translate: { x: "363.321", y: "546.684" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "WASE",
    path: {
      id: "Path_7462",
      d: "M392.106,437.866,339.29,482.308h-2.576V462.985l-7.729-7.729h-8.373l-7.085-5.8-1.932-18.679-1.932-5.153-2.576-6.441-6.441-8.373,3.22-20.611L300,381.185v-6.441l3.865-6.441V348.981L300,342.54v-4.509h3.865l5.153-2.576,5.8-7.085v-42.51L339.29,276.2l4.509-4.509-2.576-1.932-5.153-3.22v-3.865l5.153-8.373,23.187-9.661V232.4l7.085-12.238,5.8-6.441L387.6,210.5l16.746,6.441h6.441l8.373-3.22h10.306L455.227,232.4l6.441,3.865h10.95l10.95-3.865,11.594-5.153h10.95v17.391l1.932,6.441,3.865,7.085-3.865,4.509v10.95l9.017,10.306-5.153,10.306,7.085,3.865v5.8l9.017,7.085-9.017,14.814v18.035l4.509,23.187-43.154,2.576-14.17,10.95-10.95,12.882Z",
      translate: { x: "86.169", y: "60.376" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "WASE-2",
      name: "WASE",
      fill: "#001755",
      translate: { x: "471.833", y: "380.422" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-4",
      name: "5M",
      fill: "#001755",
      translate: { x: "482.174", y: "403.607" },
      tspan: { X: "0", y: "0" },
    },
  },
];
