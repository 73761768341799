import "./style.css";
const ThreasholdLegend = () => {
  return (
    <div className=" d-flex justify-content-start align-items-center mt-4 mr-5 t-l">
      {Array.from(Array(3)).map((item, i) => (
        <div
          className="m-legend d-flex justify-content-between align-items-center"
          key={i}
        >
          <div
            className="ml-i p-2 mx-2 rounded-1"
            style={{
              background:
                i === 0
                  ? "#FFC078"
                  : i === 1
                  ? "#82C91E"
                  : i === 2
                  ? "#FA5252"
                  : "#000000",
            }}
          ></div>
          <div
            className="ml-t"
            style={{
              fontSize: "12px",
              color: "#707070",
            }}
          >
            {i === 0
              ? "Below threshold"
              : i === 1
              ? "Within threshold"
              : i === 2
              ? "Above threshold"
              : "none"}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ThreasholdLegend;
