export const color = {
  baseColor: "#0C1238",
  secondaryColor: "#56BBF1",
  darkColor: "#707070",
  whiteColor: "#fff",
  greenColor: "#5c940d",
  blue: "#1864AB",
  redColor: "#FC7E82",
  newBaseColor: "#1d255a",
};
