import React, { useEffect } from "react";
import Chart from "chart.js/auto";
import { Doughnut, Pie } from "react-chartjs-2";
import { plugins } from "chart.js";

function DoughnutChart({ title, type, data }) {
  useEffect(() => {}, []);

  const a = [23, 44, 56];

  const state = {
    labels:
      type === "c" ? ["Independent", "Major"] : ["Dapman", "Ipman", "Moman"],
    datasets: [
      {
        label: {
          title: "text",
          position: "left",
        },
        // value: [23, 45, 35],
        backgroundColor:
          type === "c"
            ? ["tomato", "#C9DE00"]
            : ["dodgerblue", "grey", "#6800B4"],
        // hoverBackgroundColor:
        //   type === "c"
        //     ? ["#501800", "#4B5000"]
        //     : ["#175000", "#35014F", "#003350"],
        data:
          type === "c"
            ? data.map(
                (item, i) =>
                  (parseInt(item) /
                    data.reduce(
                      (prev, cur) => parseInt(prev) + parseInt(cur)
                    )) *
                  100
              )
            : data.map(
                (item, i) =>
                  (parseInt(item) /
                    data.reduce(
                      (prev, cur) => parseInt(prev) + parseInt(cur)
                    )) *
                  100
              ),
      },
    ],
  };

  // console.log([1,2 3])
  return (
    <div
      className="mt-4"
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Doughnut
        data={state}
        options={{
          animation: {
            // duration: 0,
          },
          elements: {},
          maintainAspectRatio: false,
          responsive: true,
          cutout: 90,
          title: {
            display: true,
            text: "Power Quality",
            fontSize: 20,
          },
          plugins: {
            legend: {
              display: true,
              position: "right",
            },
            title: {
              display: false,
              text: title,
              color: "#001755",
              font: {
                size: 14,
                weight: 500,
              },
            },
          },
        }}
      />
    </div>
  );
}

export default DoughnutChart;
