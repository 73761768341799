export const data = [
  {
    state: "OJO",
    path: {
      id: "Path_7161",
      d: "M153.758,117.785v-6.758h18.773l7.509-3.755,8.26-1.5,8.26-5.256,9.011-6.758,8.26-5.256v3.755l3,5.256,3,3,9.762,1.5h7.509v3.755l-.751,6.007.751,6.007c-1.251,2.753-3.755,8.41-3.755,9.011a27.6,27.6,0,0,1-1.5,4.505l-3.755,9.011-4.505,9.011v11.264a9.311,9.311,0,0,1-1.5,4.505l-5.256,4.505-5.256,4.505-3.755,4.505v5.256l2.253,6.758L150,189.122l7.509-17.271v-6.758L150,154.58v-6.758l-3-3.755,13.516-7.509V131.3Z",
      translate: { x: "406.266", y: "841.5" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OJO-2",
      name: "OJO",
      fill: "#001755",
      translate: { x: "585.82", y: "968.35" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M",
      name: "4M",
      fill: "#001755",
      translate: { x: "583.51", y: "990.826" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Alimosho",
    path: {
      id: "Path_7162",
      d: "M195.258,100.316v15.018c1.251,1.251,3.9,4.205,4.505,6.007s4.255,1.752,6.007,1.5h16.52l7.509-1.5,8.26,1.5h4.505V99.565l.751-5.256V88.3l7.509-3V62.771l-2.253,2.253-1.5,1.5-3.755,3.755v3.755h-3.755l-1.5-3.755V66.525l-3.755-3.755-6.758-6.007-5.256-4.505L214.782,45.5l-3,3-3,3.755c-1.753,2.253-5.407,7.209-6.007,9.011s-5.256,5.757-7.509,7.509L188.5,76.287v6.758l3,5.256v6.007Z",
      translate: { x: "427.09", y: "819.922" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Alimosho-2",
      name: "Alimosho",
      fill: "#001755",
      translate: { x: "566.781", y: "891.879" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-2",
      name: "4M",
      fill: "#001755",
      translate: { x: "631.602", y: "901.443" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Amuwo odofin",
    path: {
      id: "Path_7163",
      d: "M211.767,108.516V98.755l7.509-2.253,6.007-1.5,6.758,1.5h6.007l4.505,2.253,4.505,5.256h6.007l7.509,1.5-1.5,6.007v5.256l-1.5,8.26-3,8.26v7.509l-3,9.011-4.505,4.505-6.007,4.505-9.011,3-9.011,3.755-15.769-2.253-8.26-1.5-1.5-3,1.5-15.018,12.765-27.784Z",
      translate: { x: "431.607", y: "844.762" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_4M-3",
      name: "4M",
      fill: "#001755",
      translate: { x: "654.637", y: "959.248" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "Amuwo_odofin",
      name: "Amuwo odofin",
      fill: "#001755",
      translate: { x: "643.658", y: "973.757" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Badagry",
    path: {
      id: "Path_7164",
      d: "M1,166.834l2.253-17.271,3-9.011,4.505,2.253h7.509l9.011,2.253,9.011-2.253,6.007-2.253h8.26l3.755,2.253,13.516,10.513h5.256l3.755-5.256,7.509-9.762,8.26-5.256,11.264-6.007,3.755-6.007V113.52l-3.755-6.758V99.253L107.629,97l9.011,2.253,16.52,3,20.275,4.505h72.838v6.758l7.509,13.516v6.007l-7.509,3.755-6.758,3.755,3,2.253v6.758l6.758,9.762v9.011l-3,10.513-3.755,6.758-6.758,3H168.452l-21.776-3-46.556,3c-11.013,2-33.34,6.007-34.542,6.007s-37.045,4.005-54.816,6.007H6.256l-3-3A12.456,12.456,0,0,1,1,190.863V166.834Z",
      translate: { x: "333", y: "845.766" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Badagry-2",
      name: "Badagry",
      fill: "#001755",
      translate: { x: "454.277", y: "992.062" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-4",
      name: "4M",
      fill: "#001755",
      translate: { x: "454.277", y: "1008.316" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Apapa",
    path: {
      id: "Path_7165",
      d: "M187.5,166.551v-6.758l3.755-3.755,6.758-6.007,5.256-3.755,2.253.751,8.26,1.5,14.267,2.253,10.513-4.505,6.758-2.253,5.256-2.253,12.015,4.505H270.1l9.762-4.505,5.256-10.513,3.755-6.758h5.256v12.015l5.256,5.256,3,4.505-3,4.505v12.765l3,15.769h-8.26l-31.538-4.505H250.576l-15.018-2.253h-44.3l-1.5-.751-.751-1.5-1.5-2.253Z",
      translate: { x: "426.588", y: "859.565" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Apapa-2",
      name: "Apapa",
      fill: "#001755",
      translate: { x: "633.602", y: "1045.885" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-5",
      name: "4M",
      fill: "#001755",
      translate: { x: "643.375", y: "1025.347" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Eti_osa",
    path: {
      id: "Path_7167",
      dataName: "Eti osa",
      d: "M269.26,193.36l-3.755-1.5v-9.011l-2.253-2.253v-4.505l-2.253-1.5v-5.256l2.253-6.007v-2.253l2.253-3.755h13.516l1.5-2.253-1.5-3.755,1.5-8.26,2.253-1.5,4.505-2.253,3.755-.751,3-1.5,3.755,1.5,5.256,3,4.505,3.755,4.505,5.256,3.755,2.253h6.007l4.505-2.253,4.505-2.253,2.253-1.5a47.874,47.874,0,0,0,6.007-5.256c2.4-1.2,2.5-.5,2.253,0H343.6l2.253,1.5h1.5l5.256-1.5,3-2.253h7.509l1.5-2.253a3.567,3.567,0,0,1,.751-2.253c.751-.751.751-.751.751-1.5a2.759,2.759,0,0,1,.751-2.253l2.253-2.253c1.5-1.5,1.5,0,2.253-1.5s.751-.751,1.5-1.5l1.5-1.5,1.5-.751a3.37,3.37,0,0,0,.751-2.253v-3.755l-.751-2.253v-1.5l-2.253-3v-3l.751-2.253a17.157,17.157,0,0,1,3.755-1.5,29.025,29.025,0,0,0,3.755-.751l3.755-.751h5.256l3.755.751,4.505.751,3,1.5a10.366,10.366,0,0,0,1.5,2.253,2.383,2.383,0,0,1,.751,1.5v5.256l-1.5,2.253-2.253,4.505h1.5l11.264,7.509,3-3.755,1.5-3.755,2.253-3h9.762l1.5,3-1.5,7.509-7.509,16.52-6.007,7.509-6.007,8.26-4.505,8.26-3.755,2.253h-5.256l-8.26,1.5-12.015-1.5-9.011,1.5H357.116l-24.78,1.5H320.322l-12.765,1.5H280.524l-4.505,4.505-2.253,4.505v6.758Z",
      translate: { x: "463.473", y: "848.525" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Eti_osa-2",
      name: "Eti osa",
      fill: "#001755",
      translate: { x: "821.316", y: "1000.455" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-6",
      name: "4M",
      fill: "#001755",
      translate: { x: "821.316", y: "1014.347" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Oshodi isolo",
    path: {
      id: "Path_7172",
      dataName: "Oshodi ",
      d: "M239.518,117.307h-5.256l-3-2.253-3-3.755-3.755-2.253V80.513l1.5-3.755,2.253-3,3-1.5,3,3,2.253,3.755,3.755,3.755,1.5,4.505h3.755l1.5-5.256,3.755-5.256,3.755-3,1.5-3.755h3.755l2.253,3.755v8.26l-.751,5.256-3,7.509-3.755,6.007-3.755,6.007V112.8c0,1.2-2.5,2-3.755,2.253v2.253h-7.509Z",
      translate: { x: "445.156", y: "832.216" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Oshodi_2",
      name: "Oshodi ",
      fill: "#001755",
      translate: { x: "647.926", y: "925.453" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-7",
      name: "4M",
      fill: "#001755",
      translate: { x: "674.15", y: "939.453" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Ikeja",
    path: {
      id: "Path_7173",
      d: "M230.5,65.045V92.078l.751,3.755L232,98.085l2.253.751.751,2.253,1.5,1.5v1.5a9.96,9.96,0,0,0,1.5,3l1.5,1.5h2.253v-2.253l1.5-2.253,1.5-1.5,1.5-4.505,3.755-3h1.5l1.5-3.755,2.253-.751h1.5l.751,1.5,1.5,3,.751,4.505-.751,4.505,3-1.5h7.509V88.324l3.755-6.007,6.007-6.758V69.551l-2.253-4.505-.751-6.758.751-3.755,2.253-15.769-3-4.505c-1.2-1.8-2.5-2.253-3-2.253L269.547,27.5h-3.755l-3.755,4.505-1.5,4.505-3.755,5.256-3,3.755V56.034l-2.253,6.007v3h-3l-2.253-3-4.505-3.755h-6.758C233.8,58.287,231.5,62.793,230.5,65.045Z",
      translate: { x: "448.166", y: "810.889" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Ikeja-2",
      name: "Ikeja",
      fill: "#001755",
      translate: { x: "690.111", y: "888.812" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-8",
      name: "4M",
      fill: "#001755",
      translate: { x: "687.688", y: "899.216" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Agege",
    path: {
      id: "Path_7174",
      d: "M224.773,80.836,206,64.316V60.562l1.5-3.755L206,51.551l3-7.509,1.5-6.007V32.027l2.253-6.007,2.253-6.007.751-6.758L218.015,9.5l.751.751,3.755,3,2.253,2.253,3,5.256c2.253,2,7.059,6.308,8.26,7.509,1.5,1.5,6.007,6.758,6.758,7.509l4.505,4.505,7.509,7.509a32.3,32.3,0,0,1,1.5,3.755,13.773,13.773,0,0,0,3,4.505l3,3a13.186,13.186,0,0,0,2.253,3,3.845,3.845,0,0,1,.751,3.755,12.5,12.5,0,0,1-.751,4.505,27.972,27.972,0,0,0-1.5,4.505h-.751l-3-2.253-6.007-3.755-3.755-1.5-3.755,1.5-2.253,3.755c-.251,1.5-.751,4.656-.751,5.256a30.908,30.908,0,0,1-2.253,6.007c-.6,1.2-2.753,2.5-3.755,3-.751,1.251-2.253,3.9-2.253,4.505s-1.5.25-2.253,0l-2.253-3.755-.751-3.755Z",
      translate: { x: "435.873", y: "801.856" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Agege-2",
      name: "Agege",
      fill: "#001755",
      translate: { x: "601.611", y: "839.09" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-9",
      name: "4M",
      fill: "#001755",
      translate: { x: "656.15", y: "854.714" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IFAKO IJAYE",
    path: {
      id: "Path_7175",
      dataName: "IFAKO IJAIYE",
      d: "M261.655,59.571l.751,6.007-2.253,2.253-1.176-2.253-1.828-3-2.253-1.5-1.5-2.253-2.253-3.755-2.253-3.755-3-1.5-1.5-2.253-3-3.755-3-2.253-1.5-3.755-4.505-2.253-1.5-2.253-1.5-1.5-1.5-1.046-1.5-1.958-2.253-1.5-2.253-3-.751-1.5-1.5-2.253-2.253-2.253-1.5-3-1.045-.751v-1.5l.294-2.253.751-3,.751-2.253,2.253-3,3-1.5,3,1.5,3,1.5,3.755,1.5,5.256,3.755,9.011,3.755c2.5,2,7.509,6.157,7.509,6.758,0,.751,8.26,4.505,9.011,4.505.6,0,6.758,7.009,9.762,10.513l2.253,3c0,1-.15,3.154-.751,3.755a27.625,27.625,0,0,1-3,2.253l-.751,1.5-.751,2.253-2.253,3c-.751,1.5-2.4,4.505-3,4.505S262.155,58.069,261.655,59.571Z",
      translate: { x: "440.291", y: "797.591" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "IFAKO_IJAIYE-2",
      name: "IFAKO IJAIYE",
      fill: "#001755",
      translate: { x: "669", y: "795.591" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-10",
      name: "4M",
      fill: "#001755",
      translate: { x: "683.15", y: "832.548" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Shomolu",
    path: {
      id: "Path_7177",
      d: "M262.507,104.84l12.015,1.5c1,.25,2.7.3,1.5-1.5s-.5-1.752,0-1.5V95.829l2.253-1.5h6.007l1.5,1.5a19.252,19.252,0,0,0,4.505,3c2.4,1.2,3,2.5,3,3h3.755l1.5-3V89.822l1.5-4.505,3-4.505,3.755-5.256,4.505-2.253-.751-3-1.5-1.5-2.253-3-1.5-1.5-.751-2.253V56.782l2.253-2.253,3.755-2.253h4.505l3-1.5V45.518l-2.253-4.505-5.256-8.26L304.558,30.5h-4.505l-9.762,2.253-10.513,3H267.013v9.762l-4.505,9.011V63.54l4.505,10.513-6.007,6.758-3,7.509-1.5,7.509,1.5,6.007Z",
      translate: { x: "461.213", y: "812.395" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Somolu-2",
      name: "Somolu",
      fill: "#001755",
      translate: { x: "732.732", y: "861.63" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-11",
      name: "4M",
      fill: "#001755",
      translate: { x: "735.746", y: "880.443" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Kosofe",
    path: {
      id: "Path_7178",
      d: "M286.5,26.264l2.253,9.762,3.755,3h2.253l4.505,3.755v4.505l3.755,3,1.5,5.256-1.5,3.755H292.507l-3.755,4.505v5.256c.751,1.251,2.553,3.755,3.755,3.755s2,2.5,2.253,3.755h3l1.5-1.5h3.755l1.5-6.007,2.253-1.5,4.505,1.5v9.762c0,2.253,2.253,2.253,3.755,3,1.2.6,4.005-4.255,5.256-6.758l6.758-7.509,8.26-8.26,7.509-10.513V39.029l4.505-11.264V15H294.76l-6.007,2.253Z",
      translate: { x: "476.268", y: "804.617" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Kosofe-2",
      name: "Kosofe",
      fill: "#001755",
      translate: { x: "779.287", y: "811.095" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-12",
      name: "4M",
      fill: "#001755",
      translate: { x: "792.805", y: "839.103" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IKORODU",
    path: {
      id: "Path_7179",
      d: "M302,100.6l4.505-17.271,7.509-7.509,3.755-6.007L327.534,59.3l6.758-6.758,3-15.018,1.5-6.007,2.253-9.762V15h95.365V72.069l-4.505,3.755-4.505,4.505-6.758,7.509-7.509,4.505-3,3.755-4.505,4.505-5.256,6.007L389.86,114.12h-6.007v-7.509H369.585l-6.758,4.505-5.256,9.011-6.007,6.758-9.011,4.505H324.531L302,126.885l-3-6.758v-9.011Z",
      translate: { x: "482.541", y: "804.617" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "IKORODU-2",
      name: "IKORODU",
      fill: "#001755",
      translate: { x: "828.107", y: "868.63" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-13",
      name: "4M",
      fill: "#001755",
      translate: { x: "838.631", y: "894.443" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "EPE",
    path: {
      id: "Path_7180",
      d: "M391.756,43.785V16.753l5.256-2.253H601.26l4.505,1.5,3,2.253v3c.751,1,2.253,3.3,2.253,4.505,0,1.5.751,3.755,0,6.758s-2.253,6.758-2.253,7.509c0,.6-2,4.756-3,6.758l-3,4.505L596,54.3l-5.256,1.5-3.755,3.755v5.256l2.253,3.755a60.738,60.738,0,0,1,1.5,6.007,60.711,60.711,0,0,0,1.5,6.007v27.033l2.253,3h3l3,1.5,2.253,1.5c1.5,1,4.656,3.154,5.256,3.755s2.253,2.753,3,3.755l3.755,3,.751-.751c1-1.752,3.154-5.557,3.755-6.758a12.5,12.5,0,0,0,.751-4.505l2.253-4.505,6.007-2.253,4.505-3,5.256-3.755,3-3.755,3-3,2.253-3.755h5.256l3,1.5a13.648,13.648,0,0,0,3,2.253,10.056,10.056,0,0,1,3,3h3l3,.751h11.264l2.253,2.253v7.509l.751,5.256h2.253l2.253,5.256v5.256l-2.253,2.253-6.758,6.758-9.762,13.516,3.755-7.509V130.14l6.007-6.758v-7.509l-6.007-5.256H659.079c-2.753,1.752-8.861,5.256-11.264,5.256H629.794l-5.256,7.509-7.509,6.758-9.011,10.513-13.516,3-13.516-3-7.509-4.505-9.762-6.007-5.256-6.758-21.026-7.509-9.762-5.256H508.147l-5.256,5.256h-33.04l-12.765-5.256-11.264-7.509L439.814,98.6,430.8,94.847l-7.509-8.26h-6.758l-19.524-8.26H386.5l5.256-6.007V43.785Z",
      translate: { x: "526.449", y: "804.366" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "EPE-2",
      name: "EPE",
      fill: "#001755",
      translate: { x: "1001.6", y: "858.717" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-14",
      name: "4M",
      fill: "#001755",
      translate: { x: "994.6", y: "883.499" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IBEJU/LEKKI",
    path: {
      id: "Path_7181",
      dataName: "IBEJI LEKKI",
      d: "M376.024,146.064,356.5,176.1l13.516-.751,20.274-2.253,21.025-3.755h33.04l38.3-3.755,27.784,3.755L546.48,173.1l39.8,6.758,61.574,15.769L685.4,206.887l21.025,4.505H726.7l3.755-3-3.755-6.007-3.755-3.755-6.007-7.509-3-9.011H700.416l-14.267-3-15.018-3.755-18.022-6.007h-5.256l-4.505,3.755H626.076l-3-3.755h-3.755l-9.011-3.755-7.509-8.26-5.256-11.264h-3.755l-7.509-3.755-8.26-6.758-5.256-6.758-5.256-3.755-10.513-4.505-14.267-4.505h-6.758l-4.505-7.509-4.505-6.007L517.945,93.5h-33.04l-18.773,3.755L436.1,102.511l-10.513,3.755c-4.005,2-12.465,6.157-14.267,6.758s-14.767,5.256-21.025,7.509l-8.26,4.505v8.26Z",
      translate: { x: "511.395", y: "844.009" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "IBEJI_LEKKI-2",
      name: "IBEJI LEKKI",
      fill: "#001755",
      translate: { x: "956.6", y: "976.394" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-15",
      name: "4M",
      fill: "#001755",
      translate: { x: "987.6", y: "1001.175" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Lagos_island",
    path: {
      id: "Path_7168",
      dataName: "Lagos island",
      d: "M258,137.267l1.5-12.765h3.755l2.253-1.5,3.755,1.5,2.253,2.253h3l3,1.5,1.5,1.5,3.487,2.79a1.216,1.216,0,0,1,.267.213l-.267-.213c-1.084-.595-.114,4.169-1.234,6.971,0,1.2,1,1,1.5.751v1.5a10.136,10.136,0,0,1-2.253,5.256H267.762l-2.253-1.5-2.253-3.755Z",
      translate: { x: "461.967", y: "858.813" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_4M-16",
      name: "4M",
      fill: "#001755",
      translate: { x: "723.732", y: "998.578" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "Lagos_island-2",
      name: "Lagos island",
      fill: "#001755",
      translate: { x: "743.234", y: "982.35" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "lagos Mainland",
    path: {
      id: "Path_7176",
      d: "M262.328,147.083l-5.949-.751c-.792-.5-2.38-1.8-2.38-3s1.587-1.5,2.38-1.5l1.19-1.5V129.813l1.19-6.007V112.542l-1.19-6.758V76.77L263.518,78l5.949,3H277.8l9.518.751,2.38,2.253,3.569,4.505,2.38,5.256v18.022l-2.38,6.007V122.3l-3.569,3.755-4.759,6.007-2.38,8.26-1.19,1.5c-.792.751-2.38,2.4-2.38,3s-3.967.751-5.949.751l-3.569.751H265.9Z",
      translate: { x: "459.111", y: "836.231" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_4M-17",
      name: "4M",
      fill: "#001755",
      translate: { x: "732.355", y: "952.248" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "Mainland-2",
      name: "Mainland",
      fill: "#001755",
      translate: { x: "754.754", y: "952.772" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Ajeromi ifelodun",
    path: {
      id: "Path_7166",
      d: "M246.52,124l-8.26,3L233,137.516l-3,3.755,3,1.5,3.755,1.5,5.256,2.253h4.505l7.509-2.253,4.505-3,4.505-9.011,4.505-8.26H246.52Z",
      translate: { x: "447.916", y: "859.314" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_4M-18",
      name: "4M",
      fill: "#001755",
      translate: { x: "683.15", y: "1000.347" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "Ajeromi-2",
      name: "Ajeromi",
      fill: "#001755",
      translate: { x: "678.664", y: "1014.347" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Mushin",
    path: {
      id: "Path_7171",
      d: "M264.28,82.005v26.282h-2.253l-3.755,2.253-3.755,2.253H239.5l.751-2.253,3-2.253v-5.256l3.755-7.509,3-3.755,1.5-6.007,1.5-3.755,3-4.505h4.505l3.755,2.253Z",
      translate: { x: "452.684", y: "835.98" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_4M-19",
      name: "4M",
      fill: "#001755",
      translate: { x: "697", y: "942.071" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "Mushin-2",
      name: "Mushin",
      fill: "#001755",
      translate: { x: "704.721", y: "932.453" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Surulere",
    path: {
      id: "Path_7170",
      d: "M240.505,110.015l1.5-6.007,3-1.5h3.755l3.755-.751h3l6.758-2.253,3.755-1.5v3.755l.751,2.253-.751,2.253v3.755l.751,2.253v9.011c-.251,1.5-.6,4.806,0,6.007.751,1.5-.751,2.253-.751,3.755a5.045,5.045,0,0,1-1.5,3l-2.253,3H242.007L236,139.3V128.036l2.253-2.253v-3l2.253-3v-9.762Z",
      translate: { x: "450.926", y: "846.267" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_4M-20",
      name: "4M",
      fill: "#001755",
      translate: { x: "690.691", y: "973.248" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "Surulere-2",
      name: "Surulere",
      fill: "#001755",
      translate: { x: "692.924", y: "961.369" },
      tspan: { X: "0", y: "0" },
    },
  },
];
