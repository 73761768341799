export const data = [
  {
    state: "OYE",
    path: {
      id: "Path_7302",
      d: "M1877.193,96.2l-36.777,4.525V141.35l-9.917,44.383,9.917,73.554,21.469,9.916,29.075,18.389h19.929l61.231,23.78,19.159,14.538V275.365l5.391-63.542,16.848-40.628,18.388-46.693,24.454-55.166L2052.51,13.5l-20.6-13L1922.346,20.333l-14.538,16.078L1895.582,83.1Z",
      translate: { x: "-1477.634" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OYE-2",
      name: "OYE",
      fill: "#001755",
      translate: { x: "411.883", y: "166.518" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M",
      name: "3M",
      fill: "#001755",
      translate: { x: "419.958", y: "199.485" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IKOLE",
    path: {
      id: "Path_7303",
      d: "M2737.592,488.648l29.075,5.3,11.457-16.078,25.32-23.684,65.852-41.4,19.159-6.836,33.7-24.55-61.327-7.606-31.385-15.308L2813.36,340.1l-4.525-23.78,4.525-21.373,47.463-72.784-4.525-13.767L2841.761,203h-22.24L2745.2,226.01l-30.616,6.065-14.538,41.4-9.146,16.848-32.156,83.47-9.242,94.927,4.621,19.929,28.3-10.783,23.78,6.162Z",
      translate: { x: "-2138.935", y: "-163.509" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "IKOLE-2",
      name: "IKOLE",
      fill: "#001755",
      translate: { x: "590.569", y: "205.797" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M",
      name: "5M",
      fill: "#001755",
      translate: { x: "592.637", y: "238.196" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "EKITI_EAST",
    path: {
      id: "Path_7304",
      dataName: "EKITI EAST",
      d: "M3285.675,1206.135,3254,1245.223l3.755,12.9,3.273,21.951,7.991,18.292,12.323,10.687,18.773,8.087,22.528-4.333,18.2-38.029,26.861-34.852,42.361-24.646,70.184-36.489,15.6-12.323,2.7-12.323-9.146-9.627-22.529-4.333-56.8-10.687-28.979,20.314-24.069,11.264Z",
      translate: { x: "-2627.039", y: "-911.611" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "EKITI_EAST-2",
      name: "EKITI EAST",
      fill: "#001755",
      translate: { x: "684.534", y: "315.551" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M",
      name: "2M",
      fill: "#001755",
      translate: { x: "684.535", y: "347.918" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "GBONYIN",
    path: {
      id: "Path_7305",
      d: "M2588.43,1768.005l-28.4-32.637-33.793-18.292L2522,1699.458l8.568-13.96,10.687-16.656V1657.1l8.087-4.333,10.687-5.3,11.264-5.969h17.137l19.833,5.969H2620.1l17.714,5.3h15.5l9.146,4.333,3.755,4.236,2.7,24.165,6.354,23.01,9.724,12.9,24.069,9.146,22.047-4.332L2716.09,1770.7l-40.821-5.873-18.677-3.755h-45.634l-13.96,3.755Z",
      translate: { x: "-2035.985", y: "-1325.025" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "GBONYIN-2",
      name: "GBONYIN",
      fill: "#001755",
      translate: { x: "552.637", y: "383.328" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M",
      name: "4M",
      fill: "#001755",
      translate: { x: "569.569", y: "405.385" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "EMURE",
    path: {
      id: "Path_7306",
      d: "M2925.764,2265.5l-11.264,4.814,11.264,12.8v15.6l-2.7,38.029-5.391,43.42-3.177,27.342v36.488c15.371-6.258,47.829-19.1,54.684-20.411s23.972-14.089,31.675-20.314l21.373-23.106,6.45-36.97,4.814-69.125-18.2-3.754h-25.705l-17.233-4.814Z",
      translate: { x: "-2352.91", y: "-1828.874" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "EMURE-2",
      name: "EMURE",
      fill: "#001755",
      translate: { x: "602.961", y: "507.243" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4.5M",
      name: "4.5M",
      fill: "#001755",
      translate: { x: "604.589", y: "535.484" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ISE_ORUN",
    path: {
      id: "Path_7307",
      dataName: "ISE ORUN",
      d: "M2303.019,2339.793l12.323-21.469,26.765-4.236,28.5-6.45,28.883-17.137h9.146l10.687,14.441v38.029l-4.814,34.852-5.873,42.938,2.7,42.843-32.733,12.9-24.069,3.755-30.038-3.755-15.019-12.9-3.273-51.411-18.2-34.37Z",
      translate: { x: "-1847.042", y: "-1849.061" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ISE_ORUN-2",
      name: "ISE ORUN",
      fill: "#001755",
      translate: { x: "486.015", y: "530.873" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-2",
      name: "3M",
      fill: "#001755",
      translate: { x: "496.117", y: "559.554" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IREPODUN",
    path: {
      id: "Path_7308",
      dataName: "IREPODUN IFELODUN",
      d: "M1313.268,1368.724,1260.7,1343.5l-78.271,25.224-43.42-6.932v20.314l-11.746,50.448L1116,1451.328v12.323l4.814,16.078,25.705,13.382,25.705,8.087v-21.469L1191,1447.573l27.342-15.019,69.222-6.45,60.557,2.7,48.234,12.8,9.146-9.05v-14.537l9.627-7.991-2.7-4.814-34.274-17.137Z",
      translate: { x: "-900.71", y: "-1084.406" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "IREPODUN_IFELODUN-2",
      name: "IREPODUN IFELODUN",
      fill: "#001755",
      translate: { x: "312.142", y: "310.159" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-2",
      name: "4M",
      fill: "#001755",
      translate: { x: "371.391", y: "331.918" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ado ekiti",
    path: {
      id: "Path_7309",
      dataName: "ADO - EKITI",
      d: "M1443.047,1859.036l-37.547-12.8v-23.106l9.627-15.5,9.146-15.6,24.646-13.864,35.911-4.91,53.625-3.754,41.3,3.754,28.883,4.91,22.047,6.354-10.205,19.351,2.7,14.441,7.51,4.814,34.274,18.774,22.528,29.46-34.37,16.656-50.352,11.264h-17.715L1576,1896.1l-17.233-4.332-32.156-3.755-21.951-7.991-55.743-20.988Z",
      translate: { x: "-1134.467", y: "-1428.379" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ADO_-_EKITI-2",
      name: "ADO - EKITI",
      fill: "#001755",
      translate: { x: "367.597", y: "397.385" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.5M",
      name: "3.5M",
      fill: "#001755",
      translate: { x: "378.883", y: "419.144" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IKARE",
    path: {
      id: "Path_7310",
      d: "M1365.258,2342.328l-35.622-13.574-1.637-2.5,5.488-5.3,5.2-2.792,18-30.134,6.547-16.944,16.174-30.327,2.888-6.258h5.2l22.528,10.687,25.9,8.376,28.3,9.435,34.178,3.658,22.913,7.028h17.811l-7.606,14.345-7.8,14.923-2.407,9.724-4.621,8.28-3.466,13.671-20.314-8.376-28.69-1.54-63.349,17.618Z",
      translate: { x: "-1071.89", y: "-1803.844" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "IKERE-2",
      name: "IKERE",
      fill: "#001755",
      translate: { x: "328.789", y: "475.899" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-3",
      name: "4M",
      fill: "#001755",
      translate: { x: "337.174", y: "499.243" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "EKITI SOUTH WEST",
    path: {
      id: "Path_7311",
      dataName: "EKITI SOUTH WEST ILAWE",
      d: "M221.595,2197.981,131,2102.284h8.087l10.3-1.829,4.525.963,13.864.866,17.618,1.156h82.8l5.68-.866,15.982-7.413,8.857-7.125,23.684-52.277V2029.5l22.336,15.693,24.839,8.568,23.395,6.547,22.913,9.435-19.159,35.429-6.547,17.715L378.138,2143.2l-5.969,10.013-5.2,1.637-3.947,4.332v5.68l-34.852,2.6-17.426,4.814-39.473,18.581-19.736,10.013H230.452Z",
      translate: { x: "-105.372", y: "-1638.316" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "EKITI_SOUTH_WEST_ILAWE-2",
      name: "EKITI SOUTH WEST ILAWE",
      fill: "#001755",
      translate: { x: "75.099", y: "487.557" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-2",
      name: "5M",
      fill: "#001755",
      translate: { x: "157.133", y: "518.231" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "EFON",
    path: {
      id: "Path_7312",
      d: "M.5,1982.529,5.891,1944.5l68.548,32.637,14.634,12.8,20.314,6.354,22.817,11.649,35.429,26.861H74.439l-30.808-2.6-18,2.6-9.146-11.457-10.59-19.64Z",
      translate: { x: "0", y: "-1569.683" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "EFON-2",
      name: "EFON",
      fill: "#001755",
      translate: { x: "41.224", y: "428.385" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "51.066", y: "449.626" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IJERO",
    path: {
      id: "Path_7313",
      d: "M331.907,794.234,353.28,761.5l13.864,4.236,40.147,18.485,10.975,2.5L439.928,797.8,463.8,817.628l15.6,75v17.715l-3.947,14.634L467.655,963l-10.59,18.485-15.308-13.575-7.124-8.183L409.409,947.7l-29.845-22.721-32.733-30.23L327,868.462l-8.954-19.929L298.5,813.392Z",
      translate: { x: "-240.62", y: "-614.47" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "IJERO-2",
      name: "IJERO",
      fill: "#001755",
      translate: { x: "138.133", y: "256.095" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-2",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "143.133", y: "280.452" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IDO_-_OSI_IDO",
    path: {
      id: "Path_7314",
      dataName: "IDO - OSI IDO",
      d: "M1153,967.159,1197.094,948l85.974,2.792,8.568,74.613-78.175,23.106-42.65-3.177L1159.739,994.5Z",
      translate: { x: "-930.586", y: "-765.059" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "IDO_-_OSI_IDO-2",
      name: "IDO - OSI IDO",
      fill: "#001755",
      translate: { x: "255.725", y: "222.934" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.5M-2",
      name: "3.5M",
      fill: "#001755",
      translate: { x: "286.4", y: "246.196" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ILEJEMEJE",
    path: {
      id: "Path_7315",
      dataName: "ILEJE MEJE IYE",
      d: "M1344.05,473V455l36.2,11.072h64.312l-5.969,41.591-5.006,44.768h-82.411l-8.953-1.829L1335,510.647l4.043-20.6Z",
      translate: { x: "-1077.542", y: "-366.986" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ILEJE_MEJE_IYE-2",
      name: "ILEJE MEJE IYE",
      fill: "#001755",
      translate: { x: "288.674", y: "76.501" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-3",
      name: "3M",
      fill: "#001755",
      translate: { x: "302.9", y: "145.337" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "MOBA",
    path: {
      id: "Path_7316",
      dataName: "MOBA OTUN",
      d: "M700.061,344.466l-73.073,13.96-16.944-13.96L594.063,341l-14.249,11.264L575,421.678l23.2,8.28,34.37,15.4,18.966,6.547,16.656,12.419,19.544,13.093,40.917-18.389-5.3-29.075V410.992l5.3-30.23,3.562-17.811Z",
      translate: { x: "-463.88", y: "-274.937" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "MOBA_OTUN-2",
      name: "MOBA OTUN",
      fill: "#001755",
      translate: { x: "149.229", y: "117.548" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4.5M-2",
      name: "4.5M",
      fill: "#001755",
      translate: { x: "182.29", y: "150.518" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "EKITI WEST",
    path: {
      id: "Path_7317",
      dataName: "EKITI WEST ARAMOKO",
      d: "M87.635,1065.839,66.454,1025.5,11,1053.42v40.339l5.2,14.345V1160l-2.792,42.746,16.752,7.124,52.662,25.609L97.166,1247.8l20.41,6.835,21.469,10.3,37.162,27.535,17.907-7.895,10.783-11.649,20.41-48.619v-29.556c-7.061-5.84-21.662-18.331-23.587-21.566s-25.513-15.726-37.066-21.566l-35.911-29.556-28.786-32.252Z",
      translate: { x: "-8.478", y: "-827.637" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "EKITI_WEST_ARAMOKO-2",
      name: "EKITI WEST ARAMOKO",
      fill: "#001755",
      translate: { x: "41.815", y: "382.942" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-3",
      name: "5M",
      fill: "#001755",
      translate: { x: "92.815", y: "347.918" },
      tspan: { X: "0", y: "0" },
    },
  },
];
