export const data = [
  {
    state: "BALI",
    g: { translate: { x: "0.581", y: "0.5" } },
    path: {
      id: "Path_7516",
      d: "M474,185h17.5l7,1h5l4,2.5L512,191l2,3,2.5,1.5H524v1l1,4.5,1.5,2,2,4.5-2,6L523,218l-6,5.5-8,4-5.5.5-1,2-3,3.5-3.5,5-6,9.5-5.5,6.5,2,3-15,18V281l-6.5,4.5-8.5,5-12.5,15-5.5,5.5,1,3.5h-25l-15,3-3,3c-1.667,1.333-5.1,4-5.5,4s-3.5,3.333-5,5l-6.5,6.5-6.5,8-4,8.5-9.5,9.5-5.5.5-4,1.5-1,2.5v3l-.5,6.5-2,1.5-2.5,1-4.5,1.5-8.5,7.5-7.5,6.5-2-1.5-3.5-3-6.5,2-10,5.5L289,402l-11,8-3.5,3-6.5,4-4.5-.5L258,413l-5.5-5-5.5-7-4-6.5-2.5-5.5-2-3v-4.5l.5-2.5,2.5-.5,3-1.5,2.5-2.5,2.5-5.5V354.5L248,351l-2-6-3.5-7.5-2.5-6v-4l2.5-2.5,2-1.5,3-1,4.5-1,4-3,4-1.5,3.5-3.5L269,309l3.5-5.5,12-12.5,8-7.5,9.5-6,10-5.5,11-7.5,8.5-8L340,249l6-3.5,4.5-4L355,234l9.5-5,10.5-5,6.5-5.5L387,211l3-6v-8l2.5-3,3,3,5.5,5,8,3a35.977,35.977,0,0,0,5.5,1.5h6l14.5-7,19.5-8.5Z",
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "BALI-2",
      name: "BALI",
      fill: "#001755",
      translate: { x: "353", y: "271.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M",
      name: "5M",
      fill: "#001755",
      translate: { x: "353", y: "287.229" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Gassol",
    g: { translate: { x: "0.581", y: "0.5" } },
    path: {
      id: "Path_7517",
      d: "M245,185l19.5-2L278,172h11l22.5,3h14c2,0,6.167-2,8-3l10-7.5,6-7,11.5,7,6.5,7.5,3.5,6c1.333,1.667,4.3,5.4,5.5,7s4.5,2.333,6,2.5l5-2.5,3,5,2,6v4.5l-2,8.5-10,11.5-13,8.5-11,5-3.5,4-3.5,7-8.5,4.5L325.5,261l-14,11.5L289,287l-11,11.5c-3.5,3.833-10.8,11.8-12,13s-7.5,6.167-10.5,8.5a35.4,35.4,0,0,1-10.5,2c-5,0-4.5,5-4.5,2.5V291c-1.6-7.2-.667-8,0-7.5V276c0-2.4-4-4.667-6-5.5l-5-3L224,265h-5l-7,2.5-8.5,3-15,2-7.5-2-6-3-7-6.5,7-6,6-5.5,4-8,3.5-9.5,9-11.5L212,209l7-8.5,5-8.5,10.5-7Z",
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Gassol-2",
      name: "Gassol",
      fill: "#001755",
      translate: { x: "261.5", y: "232.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_9M",
      name: "9M",
      fill: "#001755",
      translate: { x: "261.5", y: "246.729" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "IBI",
    g: { translate: { x: "0.581", y: "0.5" } },
    path: {
      id: "Path_7518",
      d: "M201.5,183.5l22.5-25,5.5,9,5-2c0,2.167.4,6.5,2,6.5h6l5.5,3,7.5,8.5-21,2-10.5,7L214,208c-4.667,4.333-14.4,12.9-16,12.5s-6.667,7.833-9,12l-5.5,14L174,255l-6,4.5-4.5,4-4.5,2h-2.5l-5.5,7v8l-3.5,9.5-11,9.5-13,3c1,.5.7.6-8.5-3s-5.833-3.167-3-2.5l-11-4.5L95.5,294l-7,3L79,302.5,73,304H66l-7.5,5v-5L55,297v-7a40.043,40.043,0,0,0,3.5-7c.8-2.4-2-4-3.5-4.5l-9.5-6L42,270v-4.5l3.5-2,2-5L49,252l6-3,5.5,3L71,255l9.5,2,8,2.5h9l3-4.5-3-8.5h3v-8L112,244l5.5-2.5,9,2.5,12-2.5,9-6,3.5-3,12.5-12L177,208l12-13Z",
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "IBI-2",
      name: "IBI",
      fill: "#001755",
      translate: { x: "91.5", y: "286.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_7M",
      name: "7M",
      fill: "#001755",
      translate: { x: "116.5", y: "290.729" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "ardo-kola",
    g: { translate: { x: "0.581", y: "0.5" } },
    path: {
      id: "Path_7519",
      d: "M470.5,174l6,11.5L468,187l-6,2-16.5,5-12,7.5-12,4.5-9.5-1-14-5-3.5-6-5.5-5-1.5-2-6,2-3.5-2.5-1.5-2.5-6-5.5-3-6.5-6.5-8-8-4-3-2,5-11.5,6-16.5,6.5-15,3-7,5-4,8.5-7,5,9,5.5,4.5,6,4.5H407l5,2,2.5,2,5,4.5A52.5,52.5,0,0,1,423,130a69.977,69.977,0,0,0,4.5,8l9.5,8.5,6,5.5,6,3.5,8,4.5h5l2.5,9Z",
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ardo-kola-2",
      name: "ardo-kola",
      fill: "#001755",
      translate: { x: "366.5", y: "144.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_8M",
      name: "8M",
      fill: "#001755",
      translate: { x: "378", y: "169.5" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Jalingo",
    g: { translate: { x: "0.581", y: "0.5" } },
    path: {
      id: "Path_7520",
      d: "M417.5,121a25.8,25.8,0,0,1-5-4h5l3.5-2h3.5l4,2,2,5h2l1,1.5,5,6,2,5,5,2.5,5.5,11v2l3,1.5,5,4.5,2.5,4.5H459l-5-2c-1.667-1.5-5.7-4.6-8.5-5s-5.833-3.833-7-5.5l-10-8.5-4-5-3.5-7C420.5,125.667,419.1,121.8,417.5,121Z",
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Jalingo-2",
      name: "Jalingo",
      fill: "#001755",
      translate: { x: "425", y: "132.932" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M",
      name: "4M",
      fill: "#001755",
      translate: { x: "431.5", y: "141.364" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "YORRO",
    g: { translate: { x: "0.581", y: "0.5" } },
    path: {
      id: "Path_7521",
      d: "M508.5,100.5V94c0-.5-.2-1.5-1-1.5H499L494.5,94h-4L487,95.5l-4.5,3-3,3.5-4.5,4.5-5.5.5-4.5-1.5-3-1-3-.5h-2.5l-.5.5-2.5,2.5-6,3-3,3-2.5,5-3,3.5-4.5,1-1,1.5,2.5,2.5,2.5,3.5,1.5,5,4.5,2,3,4,3,6v2.5l3,1.5,4.5,3.5,3.5,5.5,3,7.5L472,176l4,9h22.5c2.333,0,6.9.4,6.5,2-.5,2,3,1,5,3a6.348,6.348,0,0,0,4,2l2.5,4h5l2.5-1.5,2.5-2.5v-2l-2.5-2-2.5-3-5-3.5L512,179l-4-3-2.5-3-2.5-5.5a27.7,27.7,0,0,1-1.5-5c0-.8-3-3.333-4.5-4.5l-2.5-6v-8l-2.5-1.5-2-3-3.5-2.5,1.5-5-4.5-2-2-3.5,2-2.5,4.5-4,6.5-3.5,7-1.5,7-5,1.5-3v-2.5Z",
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "YORRO-2",
      name: "YORRO",
      fill: "#001755",
      translate: { x: "449.5", y: "122.932" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-2",
      name: "4M",
      fill: "#001755",
      translate: { x: "463.5", y: "149" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Karim_lamido",
    g: { translate: { x: "0.581", y: "0.5" } },
    path: {
      id: "Path_7522",
      dataName: "Karim lamido",
      d: "M286.5,69l7,7-1,4.5,3,4v7L300,95l1,3.5-3,3.5,2,2.5,1,1.5v3.5l-1,5-3,6-3.5,4.5-4.5,2.5h-9.5a38.789,38.789,0,0,1-5-1l-6.5,1-4,2.5-6.5,2-7,2-3.5,2-3.5,4-1,3.5-3,8-6,3.5-4,1.5-6,1,1,1,1,2.5,1,1,2,1.5,1,4,4-1.5,1.5,2,1.5,4H241l6,1,5.5,6.5,4,5L260,183h4.5l5-3,5-5,5-2H288l20,2h20.5l12-7.5,8-8.5,2-4c2.833-6.833,8.6-20.7,9-21.5s4.833-11.667,7-17l4-8.5,6-5,8-6L395,89.5l9.5-2.5L415,84.5c2.667-1.833,8-5.6,8-6s3.667-4.167,5.5-6l1-4c2.5-1.5,7.6-4.6,8-5s5.833-3.167,8.5-4.5l11.5-8.5,6-3,6-2.5,5.5-4-1.5-4.5-4-2.5L467,31.5,465,28l-3-3.5L459.5,20V16.5l-3-4-7,1.5L445,12.5H423L420.5,11h-30l-6-2.5L382,11l-2.5,4h-6L369,11l-5-5h-9l-3-6H333l-9,6h-3l-4,2.5L310,15h-6l-6.5-4-8-2.5-3,2.5-6,4L277,20.5l3.5,4,3.5,3,5.5,5V36l-3,9.5L284,53l5.5,5.5Z",
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Karim_lamido-2",
      name: "Karim lamido",
      fill: "#001755",
      translate: { x: "314.5", y: "77.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-3",
      name: "4M",
      fill: "#001755",
      translate: { x: "317.5", y: "95.729" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "LAU",
    g: { translate: { x: "0.581", y: "0.5" } },
    path: {
      id: "Path_7523",
      d: "M388.5,105l-4-7,1.5-2.5,9.5-6,8-2,11-3,7-4.5,7-8,1-4H431l3-2.5,6.5-4L447,58l5-3,4.5-3.5L464,47l5.5-2.5,5-3.5,1,1,4.5,3.5h9l1.5,3,3,4.5,5.5,7,3,4.5,2,5,2.5,4.5,2.5,2.5v16H499L495,94h-4.5l-3,1.5-5,2.5-3.5,4.5-4,3.5-5.5.5-4-.5-5-2h-4l-3,2.5-5,3c-.667,1-2.3,3-3.5,3s-3.167,4.667-4,7l-2.5,2.5h-8v-2.5l-2.5-3-3.5-1.5h-2l-6,1.5h-5l-8-1.5h-4l-3.5-2.5-3-3Z",
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "LAU-2",
      name: "LAU",
      fill: "#001755",
      translate: { x: "445.5", y: "77.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M",
      name: "3M",
      fill: "#001755",
      translate: { x: "452.5", y: "96.5" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "ZING",
    g: { translate: { x: "0.581", y: "0.5" } },
    path: {
      id: "Path_7524",
      d: "M519,108.5l-9.5-5,.5,2-.5,3-2.5,3-2.5,1.5-3,2-3,.5-3.5,1-4,2-4.5,2.5-5,5.5,2,3.5,4.5,2-1.5,4.5,3,2.5,2.5,3.5,2.5,2V152l2.5,6,4,3.5.5,2,2,5,2,4L508,176l4,2.5,5,3,4.5,3L526,189l1.5,3,1-3,2.5-3.5,2-4,1.5-5.5,2-5,3.5-5v-6l4-3.5,5.5-7v-11l-3-10.5-6.5-9h-5.5l-7-2.5L522,114Z",
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ZING-2",
      name: "ZING",
      fill: "#001755",
      translate: { x: "503.5", y: "133.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_10M",
      name: "10M",
      fill: "#001755",
      translate: { x: "503.5", y: "150.729" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "GASHAKA",
    g: { translate: { x: "0.581", y: "0.5" } },
    path: {
      id: "Path_7525",
      d: "M338,394l-7.5-6.5,10-8L349,377l1.5-9v-7l3-1.5h7l6-3.5,4.5-5.5,3-6,6-7,7.5-8,6-8.5,7.5-3,12-4.5h14l12.5-2,10.5,18,9.5,17L469,356l4.5-1.5v-4l3-6,2.5-7,3.5-3H488l12.5,8,6.5,12L522.5,368l-2,6.5,5,5V386l-5,8.5c.667,2,2.1,6.4,2.5,8s1.833,11.667,2.5,16.5l14,18.5-7,15L530,465l2.5,17.5,5,7.5,2,4.5-2,5H515l-2,5-3,2.5-3.5,1.5a6.421,6.421,0,0,1-1.5,6c-2,2-3.5.833-4,0-1.5-.833-4.9-2.5-6.5-2.5s-4-5-5-7.5l-6-6.5H461l-7,3.5c-1.5,1-4.9,3-6.5,3s-5.333,1.667-7,2.5h-6l-5.5-2.5-7.5-6.5-6-8-6.5-5.5-8.5-5.5-2.5-8V451l-4-8h-5l-2,2h-4l-6,7.5-6.5,2.5-4.5-2.5-5-7.5-2-7.5-3-12v-10l-2.5-7-4.5-5-4-3.5Z",
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "GASHAKA-2",
      name: "GASHAKA",
      fill: "#001755",
      translate: { x: "377.5", y: "393.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-4",
      name: "4M",
      fill: "#001755",
      translate: { x: "383.5", y: "411.729" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Sardauna",
    g: { translate: { x: "0.581", y: "0.5" } },
    path: {
      id: "Path_7526",
      d: "M385,568h-8v3l-5.5,5.5c-1.2,1.2-1.5-1.833-1.5-3.5l-6,2-2-2h-3.5l-3-2v-9.5A3.36,3.36,0,0,0,353,558h-3.5v-6H345l-4-3-4.5-3.5v-8L339,533l2-5.5V524l4-4.5,4.5-5.5H353l5.5-8.5,5.5-7,6-9.5V453.5h7l5-8.5h6.5v-2h6l4,7v22l2.5,7,5.5,4.5L415,489l3.5,6,10,10.5,9.5,2,6.5-2,5.5-2,9-5h25.5l9,13,7,2.5v2.5l-7,3V530H487c-2.167,3.333-6.2,10.3-5,11.5s3.833,1.5,5,1.5v6l2.5,3-5,4,5,8.5c-4,2.167-11.4,6.5-9,6.5s1,5.333,0,8l-1.5,2-1.5,3.5-2,3v4l-4,2h-5l-3.5-2h-4l-4,2,4,7.5-4,4.5H442.5V609l-1.5,3.5-3-2.5-9.5-6.5-10,2-7.5-2-4.5-2.5-5.5-2-2.5,4.5h-7V571Z",
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Sardauna-2",
      name: "Sardauna",
      fill: "#001755",
      translate: { x: "423.5", y: "568.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_7M-2",
      name: "7M",
      fill: "#001755",
      translate: { x: "434", y: "587.229" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Kurmi",
    g: { translate: { x: "0.581", y: "0.5" } },
    path: {
      id: "Path_7527",
      d: "M295,488.5l-2.5-5.5h-4c-.8,0-1,1.667-1,2.5l-1,3v8l-1,2.5v14.5l-2.5,6-1,4c-.333,1.333-1.2,4-2,4s-3.333,2-4.5,3L273,534l-2.5-1.5-4.5-2-5.5,1-9.5,1L238,534l-13,1-8.5-2.5-3.5-2-2-2V525l2-1.5v-4l-2-3.5-2.5-1-1-6v-4l1-2,2.5-2.5,4.5-4,6-8v-9l-1-6v-4l2.5-5,3-3.5,3.5-2.5L231,461l5.5,3.5,1.5,1,2-2,2-.5h2.5l2.5,1.5,4,1,1-2-1-2.5,1-2,4.5,4h2v-2.5l-4-3V454l4-3.5,4-3.5,3.5-1.5,2-3,4,3v-7l10-7,5.5-7,5-5,11.5-9,12.5-11,6-5L329,387l18,14,9,11.5v12l5,21,5,8.5h5v34.5L353,515h-3.5l-10,10.5L333,522l-1-1.5h-9.5v-2L319,515l-4-3.5-4.5-4.5-2-4H306l-2,4-2.5-2-2-2-4.5-2.5-2.5-4L295,494Z",
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Kurmi-2",
      name: "Kurmi",
      fill: "#001755",
      translate: { x: "303.5", y: "442.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_9M-2",
      name: "9M",
      fill: "#001755",
      translate: { x: "303.5", y: "456.729" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Wukari",
    g: { translate: { x: "0.581", y: "0.5" } },
    path: {
      id: "Path_7528",
      d: "M50,314l8.5-7,7.5-3.5h8l7-3,5-2,6.5-3.5,8-2,10,5.5,12.5,5,14-5,10.5-9.5,3.5-9,1-7.5,2.5-5.5,4-2.5h5l3-3h3l4,5.5,7,2,7,3.5H198l10-3.5,11-4.5h5.5L227,267l11,5.5,3.5,6v7l-3.5,15-2,3h-6.5l-10.5,8-3.5,11-7.5,11L198,340l-14,7-3.5,8.5-7,6V370h-10L152,374.5l-12.5,11-3,10.5-7.5-8-15.5-13.5-8-19-13-4.5H50l-22.5,4.5h-11L5,361.5l-5-6L5,344l5.5-4v-6.5h6l11-4,12-7Z",
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Wukari-2",
      name: "Wukari",
      fill: "#001755",
      translate: { x: "125", y: "336.2" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-2",
      name: "5M",
      fill: "#001755",
      translate: { x: "133.5", y: "355.797" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "DONGA",
    g: { translate: { x: "0.581", y: "0.5" } },
    path: {
      id: "Path_7529",
      d: "M199.5,410.5l-7-4-3-1.5-8.5-4.5-7-2.5-8.5-2.5-6.5-1h-6.5l-2,3.5-5,2.5-5-2.5-4-2.5,4-10.5,8-7,6-5,4.5-1,4-2.5h11v-9l7-6c1.333-3.167,4.2-9.3,5-8.5s15.333-8,22.5-12.5l7-12L220,311l9.5-8h6l3.5-3.5,1.5,8V324l1,9.5,5,10.5,2,10.5V373l-9.5,7.5V387c1.5,3.5,4.8,10.8,6,12a51.388,51.388,0,0,0,5,4l4.5,6,4,5.5,7.5,2,7.5-2L276,413l5-4,4.5-5,4-1c3.333-1.833,10.3-5.8,11.5-7s6.833-3.167,9.5-4l11-1.5V396c-2.333,1.5-7.1,4.6-7.5,5-.5.5-11.5,12-14.5,13.5s-9,7-10,8c-.8.8-3,3.333-4,4.5l-2,4-4.5,2.5c-1.167.5-3.7,1.8-4.5,3A3.256,3.256,0,0,1,272,438v5.5a2.039,2.039,0,0,1-2.5,0c-.8-.8-3.333,1.333-4.5,2.5l-5,2.5-4,4.5v4.5l2.5,4c-.5,1-1.7,2.7-2.5,1.5a23.418,23.418,0,0,0-4.5-4v2.5a2.792,2.792,0,0,1,0,3c-1,1.5-1.5,1-3,1s-3-2.5-5-2.5H241l-4,2.5-6-5-2.5-4L225,451l-2.5-6.5-2-3.5,4.5-6v-5.5l-4.5-5-11.5-8Z",
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "DONGA-2",
      name: "DONGA",
      fill: "#001755",
      translate: { x: "183.5", y: "369.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M",
      name: "2M",
      fill: "#001755",
      translate: { x: "196.5", y: "385.729" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Takum",
    g: { translate: { x: "0.581", y: "0.5" } },
    path: {
      id: "Path_7530",
      d: "M101.5,577.5l-4.5,27,2.5,2.5h4l4.5-2.5,1.5,2.5,3.5,2.5h2.5l3-1.5,2-3.5V601l2-3v-3.5l3-7,2-7.5,1-2.5-1-2.5v-6.5l3-4.5,1.5-5,2-5.5,4,3v-3l-1.5-2V542l1.5-2,4.5-4V525l3.5-5.5,2.5-4L150,510v-4.5l3-9.5,3-8.5,4-6,12-9.5,7-6h5l6-2,7.5-2a52.884,52.884,0,0,1,5.5-5c.8-.4,2.667-3.167,3.5-4.5L209,447l1.5-3.5L214,440l3-2.5,2-3,2-2.5,3-3-3-4-25.5-16L181,400l-15-4.5L153,394l-3,3.5-4,2.5,7,10.5V425c0,3.833-.6,12.2-3,15s-3.667,8.833-4,11.5l-3.5,7.5-2,5v5l2,7.5L138,483l-7.5,6-3,7-2-2L105,533.5V566Z",
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Takum-2",
      name: "Takum",
      fill: "#001755",
      translate: { x: "162.5", y: "430.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M",
      name: "6M",
      fill: "#001755",
      translate: { x: "162.5", y: "446.729" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "USSA",
    g: { translate: { x: "0.581", y: "0.5" } },
    path: {
      id: "Path_7531",
      d: "M188,521.5l-20.5,19L161,548l-10.5,8h-12l-2-5V540.5l5-4v-11l9-11.5V503.5l5.5-15,5-8L179,466h6l12-4.5,8.5-7,5-11.5,6-5,5.5-8h4v5.5l-4,5.5v3.5l8,14-4,3-4,4.5-2.5,5,2.5,5.5v12l-5.5,5.5-5.5,6-3,3.5-2.5,2-4.5,2-4,6.5Z",
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "USSA-2",
      name: "USSA",
      fill: "#001755",
      translate: { x: "165.5", y: "503.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M-2",
      name: "6M",
      fill: "#001755",
      translate: { x: "165.25", y: "524.75" },
      tspan: { x: 0, y: 0 },
    },
  },
];
