export const numFormtter = (num) => {
  var commaFormatter = (num) =>
    num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  return Math.abs(Number(num)) > 999999999999
    ? Math.sign(Number(num)) *
        (Math.abs(Number(num)) / 1000000000000).toFixed(1) +
        "T"
    : Math.abs(Number(num)) > 999999999
    ? Math.sign(Number(num)) * (Math.abs(Number(num)) / 1000000000).toFixed(1) +
      "B"
    : Math.abs(Number(num)) > 999999
    ? Math.sign(Number(num)) * (Math.abs(Number(num)) / 1000000).toFixed(1) +
      "M"
    : Math.abs(Number(num)) > 999
    ? Math.sign(Number(num)) * (Math.abs(Number(num)) / 1000).toFixed(1) + "K"
    : Math.sign(Number(num)) * Math.abs(Number(num)).toFixed(1);
};
