export const data = [
  {
    state: "UNGOGO",
    path: {
      id: "Path_57",
      d: "M368.5,313.5V323l4.5,1,6.5-4,2.5,3,1-5-3.5-11,8-8,10.5-6.5,8.5-2.5,2.5.5,7,6,12.5,3.5,15,1,1.5-2,4-4,8.5-2.5h7L469,289l4.5-5.5L471,273l-3-6.5L447,273l-9-2.5-11.5-8-9-11.5L407,249.5l-2-2.5-7,5-7.5,8.5-6.5,10-13,11L364,294l2.5,3.5v8Z",
      translate: { x: "", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_5M",
      name: "5M",
      fill: null,
      translate: { x: "300", y: "232" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "UNGOGO-2",
      name: "UNGOGO",
      fill: null,
      translate: { x: "412", y: "285" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Ajingi",
    path: {
      id: "Path_26",
      d: "M721.5,325.5l-5,3.5-2-1-4.5-1h-4.5l-7.5,2H667l-3.5,5-4.5,4.5H647.5L623,333l-17,1.5-3-3-4-6.5-3.5-2-9,2-.5,2.5H575.5l-6.5-2V320l-4-7.5-4,1-5,6.5-5.5,4-2-13-3.5-9.5,1.5-9,7.5-4,8.5-8.5,9-9,4-6.5,26-11.5,20-9,14-6,7,2,4,8.5,3,12.5,9.5,7.5,13,2.5,6-2.5V247l3-7,8-11.5,6-5,8.5-2.5,7.5,2.5,2.5,11.5v12l-2.5,9v15l5.5,6h8l4.5,3-7.5,5.5-8,5.5-2.5,5.5,2.5,6.5,3,4.5,5,9.5Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Ajingi-2",
      name: "Ajingi",
      fill: null,
      translate: { x: "586", y: "287.864" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-2",
      name: "5M",
      fill: null,
      translate: { x: "587", y: "300.864" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Dawakin_kudu",
    path: {
      id: "Path_27",
      dataName: "Dawakin kudu",
      d: "M532.5,373.5v-4L526,363l-6.5-2.5L514,358l-6.5,2.5H486l-4.5-11-6-5-6-6.5-6.5-4.5-8.5-5-13-16-2.5,3-4.5,5v8l-11,5-13,2.5-9,5-11,2-3.5,8,5,5,9.5,4.5,9,6.5,5,2.5,4,4,6.5,8h5.5L437,389l6.5,9-2,6.5,8.5,9,4.5,16,7.5,5,7.5-21,4.5-9,6-13,4-2.5,8,2.5,8,10,1.5-3.5,12.5-9,8.5-7.5Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Dawakin_kudu-2",
      name: "Dawakin kudu",
      fill: null,
      translate: { x: "430.5", y: "375" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-3",
      name: "5M",
      fill: null,
      translate: { x: "416", y: "359.864" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Bebeji",
    path: {
      id: "Path_28",
      d: "M315,576.5l-23.5,17L288,589l-4-3.5-6.5-4-6-1.5-10-3.5-18-23-2-13,2-13,12.5-35-5.5-5,3.5-4,2-9L249,463l3.5-9.5,9-8.5,2-14,18-26h2l7,1.5L295,411l2,9.5,9.5,26,9,11,17,12.5,10,7.5,3.5,27L344.5,520l-7,9,1.5,8.5,2,13-3.5,6c-3.5,3.167-10.8,9.6-12,10S318,573.333,315,576.5Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Bebeji-2",
      name: "Bebeji",
      fill: null,
      translate: { x: "280.5", y: "518.5" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M",
      name: "4M",
      fill: null,
      translate: { x: "285", y: "532.5" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Karaye",
    path: {
      id: "Path_29",
      d: "M89.5,386.5l-1-13L93,372l7,6,13-2,3-9.5,6-8.5,4.5-1.5L138,358l9-1.5,9-6,11-9,17,.5,2.5-6,3.5-1.5,6.5,3.5,11.5,7,9.5,5.5,3.5,10,10,9.5h-4l-34,20-9,14.5-2,16-5,8-9,3.5-12-3.5L147,441h-9l-3.5,3.5-8-1.5-8.5,1.5-9.5-1.5-3-4.5v-10l-4-8L95,414l-2-9.5V393Z",
      translate: { x: "", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Karaye-2",
      name: "Karaye",
      fill: null,
      translate: { x: "153", y: "368.864" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-4",
      name: "5M",
      fill: null,
      translate: { x: "157", y: "376.864" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "gwarzo",
    path: {
      id: "Path_30",
      d: "M93,372l-4-1.5v-14L85.5,347,83,338l-8-13-2.5-12L71,302.5,69,293l2-8.5,1.5-8.5,4,2,6.5,5.5,8,6.5,8.5,5.5,8,9,6.5-3,8-6,9.5-1.5,13,1.5,14.5,4,11,2,8.5,6.5,8,3.5H199l3.5,1.5,5,6.5-2.5,4L194.5,329l-4.5,6-3.5,2-2,4.5h-17l-20.5,15-8,1.5-12.5-1.5L122,358l-2.5,4-4,5.5L112,376l-12.5,1.5Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "gwarzo-2",
      name: "gwarzo",
      fill: null,
      translate: { x: "87", y: "317.5" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M",
      name: "2M",
      fill: null,
      translate: { x: "101", y: "332.5" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Garum_Mallam",
    path: {
      id: "Path_31",
      dataName: "Garum Mallam",
      d: "M307.5,408.5l-13,2.5,2.5,8.5,1,4.5,8.5,22.5,10,11.5,12.5,9.5,14,9.5h1l5-1,2.5,2,8,1.5L365,476l2.5-5v-6l-2.5-8-3.5-6.5L358,438l-5-8.5V424l5-3.5,8-4V412l-4.5-3.5L358,405l-5,1.5-5,2h-5l-1.5-5-2-4-3.5-7h-5l-4,4.5-8,6.5Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Garum_Mallam-2",
      name: "Garum Mallam",
      fill: null,
      translate: { x: "306", y: "421.864" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-5",
      name: "5M",
      fill: null,
      translate: { x: "306", y: "429.864" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "ROGO",
    path: {
      id: "Path_32",
      d: "M16,479.5l5.5-8.5,11-10.5L46,453l13.5-3.5,15-3,18-6H100l3.5-3,5,5,6,1.5H122l5.5-1.5L135,444v20l6,11.5,5,7.5,7.5,7.5L159,496l4.5,5v14.5l-4.5,3-5.5,4H146l-7.5,2.5-10,6L117,538l-8.5,8.5-12.5,7-6.5,8-5,6H55l-9,2L35,565l-9-3.5-4.5-8-8.5-7L9,538l-4-9.5-2-10-2-5,2-4L5,501l4-12.5Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ROGO-2",
      name: "ROGO",
      fill: null,
      translate: { x: "57", y: "506.864" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-2",
      name: "4M",
      fill: null,
      translate: { x: "65", y: "525.5" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Kiru",
    path: {
      id: "Path_33",
      d: "M163.5,501l-.5,15,2.5,2,6.5,1.5,9.5-3.5,11-7.5,11-6,9,2,9.5,6,1.5,9v11L220,537l-7.5,11L207,560c1.5,2.5,4.7,7.5,5.5,7.5,1,0,5.5,6,7.5,8.5,1.6,2,5,3.5,6.5,4,2.333,1,7,3.2,7,4,0,1,5.5,5,6.5,5,.8,0,4,5,5.5,7.5h4l7.5-2,6.5-1.5,5,3.5L273,599h5.5l7.5-2.5,5-3.5-3-4-4-3.5-6.5-4-7-1.5-9.5-4-7-8.5L243,553l-1.5-12,1.5-12,8-20.5,4.5-16-4.5-5,3-4,1.5-9-6-11.5,3.5-9.5,8.5-8.5,2.5-13,8-13,9-13,2-6.5-2-7-1.5-8.5-6-7H264l-13,1-8.5-2.5-10.5-6h-5l-10,6-9.5,6-14.5,9L183.5,404l-2,17-4.5,8.5-9,2.5-6-2.5-6.5-1-4,6L147,441h-9l-3.5,2v20l11,19Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Kiru-2",
      name: "Kiru",
      fill: null,
      translate: { x: "192.75", y: "438.5" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M",
      name: "3M",
      fill: null,
      translate: { x: "189.5", y: "458" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Tudun_Wada",
    path: {
      id: "Path_34",
      dataName: "Tudun Wada",
      d: "M274.5,614V600.5l4-2L285,597l5.5-4,12.5-8,12-8.5,10-9.5,12.5-11.5,2-2.5,5,1L370,572l17,4.5,21,8.5,14.5,2.5,11.5-8,12-7.5,15-6,12.5-8.5L483,544l12-5.5V530l4.5-4.5,3,4.5,7,17,4,3.5v7l2,8.5-6,16.5-2.5,22-7.5,6.5H495l-6.5,5.5-2.5,8-3,11V645l9,12.5L509.5,676l4,4.5,13,12.5v4l-7,2.5-6,8c-1.333,4.667-4,14.3-4,15.5s-1.667,7.5-2.5,10.5l2.5,16,4,19-11-11.5-3-7.5L492,740l-9-4-7-10.5v-18l-10.5-8-10-12.5V671l-3-13.5-3-12.5-3.5-9.5h-7.5L427,639l-4.5,6c-2,3.333-6.4,10-8,10s-7.333,4-10,6L394,671H382l-12-6.5-4.5-9.5-5-5L348,639l-7-3.5h-8.5L326,639l-15,3-5,3-7.5-3-8-6.5-8-11-4-8Z",
      translate: { x: "", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_5M-6",
      name: "5M",
      fill: null,
      translate: { x: "372.5", y: "607.864" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "Tudun_Wada-2",
      name: "Tudun Wada",
      fill: null,
      translate: { x: "353.5", y: "621.864" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Doguwa",
    path: {
      id: "Path_35",
      d: "M391.5,686,388,671.5l6.5-1,10.5-10,5.5-4,5-2L427,639l10.5-3.5h9l3.5,11,5.5,23.5v16l9.5,13,10.5,8,1,19,7,10,8.5,4,7,9,4.5,8.5,10,11.5-2.5,6.5-9,24v20l9,15.5,13,11,8,8.5,2,9,7,9,3.5,13-7.5,12V906l-12,20-13,15-6.5,3-7.5,5.5H485L470,944l-9.5-10.5-6.5-10V917l-9-8.5L435,906l-9,2.5-16.5,5H397l-8.5-7.5-3.5-8.5-2.5-9L385,881l8.5-6H412l8-2.5-2-6.5-3.5-11.5v-31l-5-24v-19l5-11.5,7.5-16.5,2.5-21v-16L418,706l-11-4.5-9.5-5.5Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Doguwa-2",
      name: "Doguwa",
      fill: null,
      translate: { x: "441", y: "772.5" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-7",
      name: "5M",
      fill: null,
      translate: { x: "446.5", y: "788.136" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "SUMAILA",
    path: {
      id: "Path_36",
      d: "M509.5,582.5l7.5-17-2.5-5H519l8.5-3,8-5.5,4.5-8,3-11.5,4.5-7.5,4-5,3-9.5v-11l3-5.5,6-3.5L573,486l8-7.5,7-2,7,5.5,8,8.5,12,16,3,4-4,3.5-4.5,6-1,7.5v9c0,1.6,4.333.667,6.5,0l7.5,3.5,2.5-3.5v-13l4-2.5h4l2.5,2.5v4l-2.5,7-1.5,5.5,4,4,4.5,2h7l5,3.5,9.5,11,2,14.5,5,2.5,8.5,7v5L625,623l-8,13-14.5,14.5-8,3.5-8,7-7,10L570,684l-13.5,13.5L549,701l-8-3.5-8.5-2-7-3.5-42-47v-9l5-19,7-6h4l7-6Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "SUMAILA-2",
      name: "SUMAILA",
      fill: null,
      translate: { x: "536", y: "607.864" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M",
      name: "6M",
      fill: null,
      translate: { x: "552", y: "624.364" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Kibiya",
    path: {
      id: "Path_37",
      d: "M501,467.5l2-11.5-4-3-4-2-11.5,2.5L473,455l-13.5,4.5L450,462v3.5l-3.5,9L442,493l-8.5,6-8.5,6.5-2,6.5-2.5,12.5,4.5,12,4,10.5-2,9-7,3,3.5,7,9,14,16.5-9.5,11-3.5,14-9.5L484,543l11-4.5v-9l5-4,3-3,1-10.5,1-13-1-14-3-8.5Z",
      translate: { x: "", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Kibiya-2",
      name: "Kibiya",
      fill: null,
      translate: { x: "442.5", y: "520.864" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M-2",
      name: "6M",
      fill: null,
      translate: { x: "441", y: "545.864" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Bunkure",
    path: {
      id: "Path_38",
      d: "M438,457l12.5,5,23-6.5,10-2.5,12-2,7,3v-5l-4-6.5L501,438l1.5-13-1.5-8.5-2.5-2.5v-5.5l2.5-7-4.5-5-6-6.5-7-1-4,3-4,8-4,10-3.5,4,1,6c-.667,2.667-2.2,8.3-3,9.5s-2.667,4.167-3.5,5.5l-5.5-1.5-4-4-2.5-9.5-1-5-4-5-4-6-5-1-5.5-1.5-6-4-6.5-5.5-6-7-6-3.5H401l-6,2-4.5,3-4,3.5-2,6.5L382,403l-5,5.5-6.5,4-5,4L358,420l-5,4v5.5l5,8.5,3.5,13,4,6,2,6.5.5,8-2.5,4.5H368l7.5,4,6.5-4,9.5-6.5L400,464h6.5v5.5L409,476h8l5-6.5V462h4Z",
      translate: { x: "", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Bunkure-2",
      name: "Bunkure",
      fill: null,
      translate: { x: "376.5", y: "444" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_7M",
      name: "7M",
      fill: null,
      translate: { x: "377", y: "456.864" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Garko",
    path: {
      id: "Path_39",
      d: "M511,413h-9.5l-3,1,2.5,2.5,2,9.5-2,11-2.5,5.5L503,449v6.5L501,468l.5,9,2.5,8,1,14.5L503,522l-3.5,4,10,21.5,4,3.5.5,9h6.5l7-2.5,8-6L540,544l3-11.5,9-12.5,2.5-9.5-.5-11,4.5-6.5,15-7,8-7.5,7-1.5L604,492l13.5,18,4,1.5h6l4-5.5v-6.5L625,493l-16-16-11-8.5-9.5-9-29,6.5-5.5-4.5V457l5.5-3.5L565,445l-11-14.5v12l-6.5-3.5-5.5-8.5-10.5,2-5-9.5-5-4.5H513Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Garko-2",
      name: "Garko",
      fill: null,
      translate: { x: "515", y: "461.864" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-8",
      name: "5M",
      fill: null,
      translate: { x: "523.5", y: "484.864" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "takai",
    path: {
      id: "Path_40",
      d: "M631,506.5V500l3.5-1,4-1.5,2.5-10,4-8.5v-7l4-7.5,10.5-12,8-7,11.5-5h3.5l4,1,3,1.5v2l-3.5,2v5.5l5,5,4-2,8,2,7.5.5,2,4.5-1,4.5-1,8,3,7,5.5,2,14-1,10.5-5,15,6,9-1,8,2-4,2.5-6,3.5-2,5.5-4,7.5-8.5,2-17,9.5L713.5,522,712,535.5l-6,20-6,11V579l-14.5,8-8.5,2v-4.5l-8.5-7-5-2.5-2-14.5-9.5-11-5-3.5-7.5-.5-4.5-2-3.5-3.5,4-12.5v-4L633,521h-4.5l-3.5,2.5v13l-3,3.5-7-3.5-6,.5-.5-1,.5-12.5,1.5-5.5,8-8,3.5,1h5.5Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Takai-2",
      name: "Takai",
      fill: null,
      translate: { x: "663", y: "483.864" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-9",
      name: "5M",
      fill: null,
      translate: { x: "663", y: "498.864" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "albasu",
    path: {
      id: "Path_41",
      d: "M758,484.5l-13.5-7-12.5,6H718.5l-5.5-2-2-7,1.5-12-1.5-5h-8l-8-2.5-4,2.5-5-5V447l3-1.5v-3l-5.5-2H679l-12,5-5.5,5-5,5.5-8,9-3.5,7.5V479l-4,7.5-2,11-4.5,1.5H631l-12-12.5-11-10-8-6.5-10.5-9-3-3.5-2.5-7v-4l2.5-4.5,8-1.5,3-2V433l2.5-7.5h6.5l5-2.5,5.5,2.5h8.5L631,423l10-5.5,10,2.5h48l4-4.5,8-3,2,5,3,11,4.5,7.5,4.5,2.5,7-5.5h6.5v3L735,446.5l-3,11L748,468l13.5,3.5,15.5,2V479l-2,5.5-8.5-2.5Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Albasu-2",
      name: "Albasu",
      fill: null,
      translate: { x: "620", y: "442.864" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-10",
      name: "5M",
      fill: null,
      translate: { x: "622", y: "455.864" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "wudil",
    path: {
      id: "Path_42",
      d: "M597.5,432.5,600,426l-6,.5-2-2.5-5.5-5.5-7-8.5L567,387l-3.5-7.5L561,375l2-7.5,6.5-9.5,11-11,11-8-.5-7.5-4-4H575.5l-6-2.5v-4.5l-4-8-4,1-5.5,6-5,4v4l-1,11-3.5,6-6,3.5-3,5v12.5l-2.5,3-2.5,4-18.5,17L501.5,398l-1,3.5-2,7V414l3-1H511l1.5,5H521l5.5,5.5,5,9L542,430l5,8.5,7,3.5V430l11,15-5.5,8-5.5,4v4.5l5.5,4,28-6-1-2.5-2-7v-4l2-4,8-2,3-1.5Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Wudil-2",
      name: "Wudil",
      fill: null,
      translate: { x: "530.25", y: "407.5" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-2",
      name: "3M",
      fill: null,
      translate: { x: "532", y: "394.864" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Gaya",
    path: {
      id: "Path_43",
      d: "M586.5,327.5l5,4V339l-11,8.5-4,3.5-7,7.5-6.5,9-2,8,19,35,14.5,16,5.5-1h6.5L612,423l5.5,2.5h8l15.5-8,10.5,2.5H699l5-5,7-2.5,5.5.5,7-5.5L728,401l-2-6-5.5-1.5L712,395l-5.5,2.5H702l-3-2.5h-5l-3.5,1c-1.167,1-3.9,3-5.5,3a10.191,10.191,0,0,1-4-1l-2.5-2v-2.5l1-3-1-5-3-5.5,1-7v-5.5l19-16.5,7-6.5,5-5.5,7-5,2-4-2-2-4.5-1h-5.5l-6.5,2H667.5l-4.5,6-4.5,4h-10c-.4,0-8.167-2-12-3l-14-3-7.5,1h-9l-3-2.5-4.5-7L596,323l-9.5,1.5Z",
      translate: { x: "", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Gaya-2",
      name: "Gaya",
      fill: null,
      translate: { x: "606", y: "362.864" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-3",
      name: "4M",
      fill: null,
      translate: { x: "614", y: "376" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "warawa",
    path: {
      id: "Path_44",
      d: "M463.5,333.5l-9.5-5-12.113-15.02L441.5,308l-4-6-7-2.5-9.5-2,3.5-4.5,6-7,2.5,2,4.5,4.5,5,2,9,3,4-4.5,6-2.5,7-6.5,8-2,5.5,2,7.5,6.5,7.5,7h11.5l10.5-5,11-4.5h8.5l11.5,2.5-4.5,2V302l3,9.5,1.5,12V338l-2.5,5.5-7,5-4,4.5,2,10.5L534,371l-1.5-1.5-6-6L521,361l-7-3-5.5,3h-23L482,350.5l-3-2L468.5,338Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Warawa-2",
      name: "Warawa",
      fill: null,
      translate: { x: "462", y: "309.148" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-4",
      name: "4M",
      fill: null,
      translate: { x: "473.5", y: "325.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Nassarawa",
    path: {
      id: "Path_46",
      d: "M469,266.5,450,271l-3,2.5-14,3v7l-1.5,3,6.5,7,10,2.5,2-1,5.5-1.5L462,290l7-6.5h5Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Nasarrawa",
      fill: null,
      translate: { x: "454", y: "299" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-11",
      name: "5M",
      fill: null,
      translate: { x: "457", y: "309.5" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Dala",
    path: {
      id: "Path_47",
      d: "M357.5,258l-1.5-9.5,11-9.5,8-3.5,5,6v7l4.5,11.5-6.5,3.5-9.5,5.5-4.5-3-3.5-4Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Dala-2",
      name: "Dala",
      fill: null,
      translate: { x: "357", y: "254.148" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-5",
      name: "4M",
      fill: null,
      translate: { x: "362", y: "261.648" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "gezawa",
    path: {
      id: "Path_48",
      d: "M524,277l8,8.5-2,2.5-21.5,9.5H497L482.5,284l-6-2.5-3.5,1-4.5-16-18.5,5-3.5-10,6-4,3.5-5-2-10-2.5-5,2.5-5,6.5-4.5,6-5.5,6.5-6,6.5-5,5-6.5H492l8,2,9,3v6.5l-2,6,4.5,5.5v9.5l7.5,5,8.5,3,4.5,4v9l6.5,13-4.5,3Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Gezawa-2",
      name: "Gezawa",
      fill: null,
      translate: { x: "471", y: "269.5" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-6",
      name: "4M",
      fill: null,
      translate: { x: "475", y: "250.864" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Gabasawa",
    path: {
      id: "Path_49",
      d: "M538,288h-7.5l1.5-3-1.5-1.5c-1.833-1.833-5.6-5.7-6-6.5s5.833-2,9-2.5l4.5-3-6-13V250l-4.5-4.5-8-2.5-8-5.5V228l-4.5-6,2-5v-7l-16-4.5h-8v-3l2.5-6,.5-10-3-4.5,1-4,4-3,4-5,5-9,5-8,3-6,6-2,4.5-6.5,1-5.5,9-1H535l3,3v10l4.5,5,4-3,1-6,3.5-6,4-2,8-2.5h4.5V133l-2.5,5.5-5,5.5-1.5,6,3.5,7,4,8,9,9,9.5,8,7,8,1.5,7,3,9.5-1.5,2L588,210l-1.5,5-4,5,2,7,2,4.5v14l3-2.5,5-5.5,7-2,2.5,2,6,4,8.5,4-43.5,19-3,5.5-18.5,18-3.5,2.5Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Gabasawa-2",
      name: "Gabasawa",
      fill: null,
      translate: { x: "527", y: "213.864" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-7",
      name: "4M",
      fill: null,
      translate: { x: "532", y: "228.864" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Minjibir",
    path: {
      id: "Path_50",
      d: "M480,177l5,3v2.5l3,4-.5,9.5-2.5,6.5v3l-5,5.5-19,16.5-7,4.5-2.5,5,2.5,6,2.5,9.5-5,5L447,261l2.5,10-2.5,1.5-8.5-1.5L427,263l-9.5-12-9.5-1.5-3-2.5-7,4-6,8.5h-7.5l-4.5-10v-8l-4.5-6L368,239l3-7,9-11,7.5-4.5L386,213V190l3-6v-7l-3-7,3-3.5,1.5-8,3,2.5,4.5,2,6-2,5-9.5,5-4.5,4,4.5V161l4.5,3.5L430,161l7-2.5,13.5-4,8-9.5,5-7,10.5-3,6,6,5,7.5v6L483,170Z",
      translate: { x: "", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Minjibir-2",
      name: "Minjibir",
      fill: null,
      translate: { x: "400", y: "206.5" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-8",
      name: "4M",
      fill: null,
      translate: { x: "408", y: "225.864" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Madobi",
    path: {
      id: "Path_51",
      d: "M288.5,367,276,376v4.5l3,2,2.5,10L283,399l-1.5,6.5h7l5.5,3,3,1h5.5l12-4,11-7.5,6.5-5.5v-4l3-12.5,6-5,4.5-6.5L350,359l11-7h13.5l5-4.5,7,2.5,4.5-7,12.5-4.5v-3l-3-4H394l-10,2H363l-7.5-2-5.5-8-9-8.5-17.5,16.5L297,354l-4.5,5Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Madobi-2",
      name: "Madobi",
      fill: null,
      translate: { x: "286", y: "389.864" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-9",
      name: "4M",
      fill: null,
      translate: { x: "291", y: "396.864" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Kumbotso",
    path: {
      id: "Path_52",
      d: "M349,322.5l-7.5-7,1.5-7,3-7,3-3.5,1.5-5H355l7.5-3.5,2,3V298l4,7,7,3.5,9.5,3,8-3,12.5-5.5,7.5-1.5,9-3.5,11.5,2,4,3,4,5.5V313l-7,8v7.5l-11,5.5L410,336l-4.5,2.5h-3V334l-2.5-2.5h-8l-7,2.5H364.5l-8.5-2.5Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Kumbotso-2",
      name: "Kumbotso",
      fill: null,
      translate: { x: "384", y: "319" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-10",
      name: "4M",
      fill: null,
      translate: { x: "401", y: "332" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Fagge",
    path: {
      id: "Path_54",
      d: "M419,269.5h7.5l3,4L433,277v5.5l-2,4-10.5,12L419,297l-2-3.5-6.5-4-8-3V284l1-4.5,2-4.5v-7l6,1.5Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Fagge-2",
      name: "Fagge",
      fill: null,
      translate: { x: "408", y: "280.648" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-11",
      name: "4M",
      fill: null,
      translate: { x: "410", y: "286.648" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "DAWAKIN_TOFA",
    path: {
      id: "Path_56",
      dataName: "DAWAKIN TOFA",
      d: "M382.5,157l8,2.5L389,167l-3,3,3,7v7l-3,6v23l1.5,3-7,5-7,8L366,240l-13,10.5L346.5,263l2.5,3v8l1,4.5,1.5,5.5-1.5,3.5-6.5-1.5-3.5-3.5-2-5.5-8.5-6-11-9.5-7.5-11-11-9H289.5L282,235l-11-3.5-6-3.5-7.5-3-11-9-4.5-7.5-2-10v-5h8.5l12,5,4.5,5,8.5,6,13-3,7.5-10,6-3h9l11.5-3.5L338,173.5l2-4.5-2-4,10-2,4.5-3.5,5,3.5,2,4-2,2v4.5h7l5-6.5,2.5-10,6-4.5Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "DAWAKIN_TOFA-2",
      name: "DAWAKIN TOFA",
      fill: null,
      translate: { x: "289", y: "218.864" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-12",
      name: "4M",
      fill: null,
      translate: { x: "423", y: "295.148" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Kura",
    path: {
      id: "Path_58",
      d: "M335,375.5l-2,16,4,2.5,7,13.5h6l8.5-2.5,8,6.5V415l10.5-7.5,4.5-4,6-15.5,8-3.5,6-3h5l5.5,3,6,7.5,14,9.5,8.5,2,3-6-11.5-16h-6.5l-9-11.5-13-7.5-11-6.5-7.5-8h-5.5l-4.5,4H361.5L350,359l-3.5,3.5-6,9.5Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Kura-2",
      name: "Kura",
      fill: null,
      translate: { x: "355", y: "380.864" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-13",
      name: "4M",
      fill: null,
      translate: { x: "356", y: "391.648" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Kabo",
    path: {
      id: "Path_59",
      d: "M287.5,368l5.5-12.5-9-2.5-3.5-3v-9l7-9-3.5-5.5-3.5-8L275,310l-6-4-6.5,5.5-8,7-3.5-7-5-8-7-8h-4.5L227,293l-6-6.5-3.5,6.5H208l-8-4H185l-9.5,17,11.5,5.5h13l8,7-3,4L194.5,329l-4,6.5,27,14.5,3.5,10.5,11.5,9L251,378l22.5-2h3Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Kabo-2",
      name: "Kabo",
      fill: null,
      translate: { x: "212", y: "316.864" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-14",
      name: "4M",
      fill: null,
      translate: { x: "220", y: "339.5" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Rimin_Gado",
    path: {
      id: "Path_60",
      dataName: "Rimin Gado",
      d: "M234.5,281.5l-12.5,4,5.5,8,8,2,4,.5,15,22.5L270,305l5.5,5.5L288,332l-7.5,9v7.5l5,6h11l45-39-1.5-5L319.5,312l-10-10.5-17-16-14.5-13L264,267l-7,5.5-9,5Z",
      translate: { x: "", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Rimin_Gado-2",
      name: "Rimin Gado",
      fill: null,
      translate: { x: "230.5", y: "293" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-3",
      name: "3M",
      fill: null,
      translate: { x: "252", y: "304.864" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Bagwai",
    path: {
      id: "Path_61",
      d: "M207,279l11.5,6.5,8.5-2,15-4.5,11-4.5,11-8.5V253l-3-14.5v-7l-2.5-6.5-7-5-5.5-4.5-4-8.5-2.5-6.5-3.5-6-5.5-6-9-3H207l-3,3-10,6-6.5,6-4.5-6-6.5,3-5,5.5-2.5,4h-6l-6.5-6.5L154,207l-2,8.5,2,11.5v11.5l9,10.5h31l7,4,3,6v16.5Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Bagwai-2",
      name: "Bagwai",
      fill: null,
      translate: { x: "182", y: "217.864" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M-3",
      name: "6M",
      fill: null,
      translate: { x: "182", y: "231.228" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Tofa",
    path: {
      id: "Path_62",
      d: "M260.5,239V226.5l10,5,12,3.5,7,6.5H301l10,9,6.5,10,20.5,17,2,5,4,4h6.5v7l-4,7L344,307l-2.5,7.5L340,310l-20.5,2-40-39L264,266.5v-14Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Tofa-2",
      name: "Tofa",
      fill: null,
      translate: { x: "287.5", y: "273" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-15",
      name: "4M",
      fill: null,
      translate: { x: "298", y: "284.864" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Shanono",
    path: {
      id: "Path_63",
      d: "M194.5,248.5H163L154,237l-1.5-21,3-15-4.5-4.5h-6.5L136,201l-15.5-13-11-9.5L97,168v-6l-7-7.5-7.5-9-6-8-5,5-2,12v11l-3.5,6L64,188l2,2.5,2,6L66,203l5.5,10v11l2,7.5v17l-2,8.5,2,6.5-2,8.5,2,4.5L90,289l10,6.5,7.5,8.5,15.5-8.5,10-2.5,11.5,2.5,13.5,3,12.5,2.5,5.5,4.5L186,289h15l6,4h10l3.5-4-1.5-3-12-6-3.5-6V259.5l-2.5-7Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Shanono-2",
      name: "Shanono",
      fill: null,
      translate: { x: "89", y: "212.864" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-16",
      name: "4M",
      fill: null,
      translate: { x: "88", y: "227.864" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Tsanyawa",
    path: {
      id: "Path_64",
      d: "M84.5,125,78,135.5v6l18.5,20V170L135,199l16-4,15.5,12,14-12,9.5,4,17.5-12.5V179l-4.5-9v-8.5l-5-8v-12l-8-9.5v-7l-4-5v-6l-5.5-6-14-9-6,4-14,5L127,114l-21.5,6Z",
      translate: { x: "", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Tsanyawa-2",
      name: "Tsanyawa",
      fill: null,
      translate: { x: "101", y: "139.864" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-17",
      name: "4M",
      fill: null,
      translate: { x: "104", y: "150.864" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Bichi",
    path: {
      id: "Path_65",
      d: "M186.5,120.5v-6l4-5.5,6.5-4,6-3,5,1,7.5,4.5,7,5.5,8.5,1.5,10,3,15.5,9,31,13.5h8l12,6,4.5-6,10.5,1.5,14-11,16-13.5,3,2,5,5c.4.4,2.167,3.167,3,4.5l5,3.5,5.5,6.5,2.5,7.5,1.5,6.5-6.5,4.5-2.5,9.5-4,7h-7.5v-5l2-2-2-3.5-5-2.5L347,163l-8.5,2.5,1.5,4-2.5,4-17.5,17L309.5,194H300l-6,2.5-7,9.5-13.5,3.5-12.5-10L251,195l-4.5-1H240l-10-5-8-3.5H207.5V180l-5-10.5V162l-4.5-8V142.5L189.5,132l1-7Z",
      translate: { x: "", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Bichi-2",
      name: "Bichi",
      fill: null,
      translate: { x: "222", y: "146.864" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-18",
      name: "4M",
      fill: null,
      translate: { x: "225", y: "161" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Kunchi",
    path: {
      id: "Path_66",
      d: "M186,92l-19,7.5,13.5,8.5,5.5,5.5,5-5.5,6-3,7.5-4,6,4,12,8.5,19,4,45.5,23h10.5L308,146l4.5-5.5H323l30-23-2-4-5-10L336,92l-8.5-6L326,74l-7.5-5.5-7.5-11-8-6-12-11V7.5l-8-6-12,6L259,5,248,7.5,244,18v9.5l-9,11v13L227,60l-4.5,12-15,14Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Kunchi-2",
      name: "Kunchi",
      fill: null,
      translate: { x: "240", y: "96" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-12",
      name: "5M",
      fill: null,
      translate: { x: "250.5", y: "83" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Makoda",
    path: {
      id: "Path_67",
      d: "M326.5,60l-2,11,2,6,1,9,7.5,4.5,10,12,8.5,14.5,4.5,4.5,4.5,7,7.5,5,3.5,4.5,5.5,15,3.5,4,7.5,2,7.5,4,6-2.5,5-9.5,5.5-4,3.5,3v10.5L423,164l6-3.5V157l-3.5-7-5-6L419,133.5l-3-7-7-5-5.5-3.5-5-12-6.5-5.5h-5l-4.5,5.5-4.5,7-4.5-3.5,2.5-7,4.5-7v-5l2-10.5V70L384,60l11-5,12-4,2-9.5-17-20,3-4.5h5.5l4.5-3-1.5-8.5-5-2.5-9,1L378,5.5H364l-12,16-10,20Z",
      translate: { x: "", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Makoda-2",
      name: "Makoda",
      fill: null,
      translate: { x: "340", y: "56.864" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-19",
      name: "4M",
      fill: null,
      translate: { x: "339", y: "67.864" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Dambatta",
    path: {
      id: "Path_68",
      d: "M489.5,131.5,484,147l-9.5-12L464,138.5l-13,16-15,5-6.5,1.5-1.5-3-2.5-7.5-5-6.5-2-10.5-2.5-7L403,117l-5-10-6.5-7h-4l-9,14-5-4.5,7-14v-5l2-9v-12l2-9.5,23-8.5L409,42,391.5,21.5l3-4H400l5-2.5-2-9-5-3.5L400,1h6l18.5,1.5,9,3.5,10-3.5c5.167,1,15.8,3.1,17,3.5s7.167,2.167,10,3l9.5,5.5-2,7L466.5,34l-6,6c.833,5.333,2.7,16.1,3.5,16.5s9.667,8.833,14,13L488,73h9.5L510,78.5l8.5,3,6,5L523,92l-14.5,15-8,13.5Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Dambatta-2",
      name: "Dambatta",
      fill: null,
      translate: { x: "417", y: "106" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-20",
      name: "4M",
      fill: null,
      translate: { x: "430.5", y: "119.864" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "TARAUNI",
    path: {
      id: "Path_69",
      d: "M353,250.5,346.5,263l2.5,2.5V274l2,10.5-1,1.5,1,7h5l6.5-3.5L366,291v-4l-4.5-11.5,7.5-6-5-4-6.5-7.5L356,248Z",
      translate: { x: "", y: "" },
      fill: "#36cd1d",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "TARAUNI-2",
      name: "TARAUNI",
      fill: null,
      translate: { x: "313", y: "271" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-21",
      name: "4M",
      fill: null,
      translate: { x: "343", y: "280.25" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Rano",
    path: {
      id: "Path_70",
      d: "M346,502l-3-24.5,5.5-1.5,3.5,1.5,7.5,2,6-3.5h2l8.5,3.5c7.833-5.167,23.6-15.5,24-15.5h6.5v5l2,7h9l5-7v-7h4l11.5-4.5,12,4.5v3l-8,28-17,12.5-5,19,9,23-2.5,8.5-6.5,3,12.5,22-10,6.5-15.5-3-19-8-17.5-4-26-18L340,553l1-2.5L337,529l7.5-8.5Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Rano-2",
      name: "Rano",
      fill: null,
      translate: { x: "361", y: "503" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-13",
      name: "5M",
      fill: null,
      translate: { x: "372", y: "522.5" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "GWALE",
    path: {
      id: "Path_55",
      d: "M393,285.5h9.5l3-10.5v-7l-7-2-7.5-7-7,1.5-7,3-9.5,7L362,276l1.5,6,2,5.5-1,5V298l1.5,1.5,12.5-8.5,3-1.5Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "GWALE-2",
      name: "GWALE",
      fill: null,
      translate: { x: "368", y: "273" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-14",
      name: "5M",
      fill: null,
      translate: { x: "366.5", y: "284.5" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "KANO_MINICIPAL",
    path: {
      id: "Path_53",
      dataName: "KANO MINICIPAL",
      d: "M417.5,294l2.5,4-15,5-5,2.5L385.5,312l-17-6.5-2-5.5,2-2,9.5-7,16-5.5h7.5l10,5.5Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "KANO_MINICIPAL-2",
      name: "KANO MINICIPAL",
      fill: null,
      translate: { x: "368.5", y: "299" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-15",
      name: "5M",
      fill: null,
      translate: { x: "380", y: "308.5" },
      tspan: { x: 0, y: 0 },
    },
  },
];
