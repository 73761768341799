export const data = [
  {
    state: "orhionwon",
    path: {
      id: "Path_6908",
      d: "M525.028,464l-23.373,4.583-.917,1.375.458,2.292-8.249.458L475.99,488.748l-8.249,7.791-4.125,3.666L446.659,508l-1.375,1.375-1.833,8.249-8.249,7.333-11,3.208H399.454l-3.208,1.375-15.582,7.333-2.75.917-5.5.458-14.207.917-8.249,5.5-3.666,11.916-1.375,8.249-1.833,16.5-5.041,4.583L333,588.658l.917,2.75,2.292,7.333,6.875,19.249,7.791,5.5,16.5,8.708,9.166,3.666v4.125l6.416,6.875,9.166,5.958,13.749,7.333,10.083,5.958,1.833,4.125-1.833,5.958-1.833,8.249-3.208,6.875v7.333l-5.041,12.374,1.375,9.166h13.291c8.249-.611,25.023-2.017,26.123-2.75s9.624-1.222,13.749-1.375l11.458-3.666,11.458-5.5,6.875-6.416,2.292-5.958,4.583-6.416,5.5-5.5,7.791-6.416,9.166-4.125,15.582-7.333,4.583-4.583,6.875-7.333,2.75-7.791,3.666-8.708,3.666-6.875,3.666-8.708-38.5-22.915L508.071,577.2l-10.083-16.5-3.666-13.291L492.489,535.5l-9.624-10.083v-24.29l3.666-5.041,11.458-10.541,10.083-2.292,8.249,2.292,8.708,5.958,4.125,4.583,10.541,21.082L570.4,485.54l-28.873-19.707Z",
      translate: { x: "414.336", y: "570.807" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ORINIONMWON-2",
      name: "ORINIONMWON",
      fill: "#001755",
      translate: { x: "803.25", y: "1170.652" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M",
      name: "5M",
      fill: "#001755",
      translate: { x: "849.08", y: "1187.151" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ikpoba okha",
    path: {
      id: "Path_6909",
      d: "M297.41,468H284.119v26.581l-.458-.458H283.2V495.5l-2.75,2.75-1.375,2.292v22l4.125,5.5-8.249,15.582-3.208,1.375H259.829l-6.416,3.666-4.125,5.041v9.166l-.458.458-.917,1.833-.458,2.292-7.791,14.666-7.333,8.708c-2.292.611-6.966,1.833-7.333,1.833s-4.736-2.445-6.875-3.666H208.5l-11,27.5h35.289l6.875,6.875,8.249,8.708,4.583-6.416,3.666-5.958,3.666-4.583h5.041L269,612.823l12.832-3.666,11.458-4.583,10.541-1.375,6.416,1.375,16.5,11,4.125,1.375-5.5-16.957-2.292-6.416-.917-4.583,4.583-2.75,5.041-4.583,1.375-12.374,1.833-12.374,3.666-11.916,8.249-5.5,19.249-1.375-.917-12.374.917-2.292-13.749-9.166L340.49,506.5l-11-4.125h-8.249l-8.249-10.541-4.125-16.041-5.5-4.125Z",
      translate: { x: "425.637", y: "570.473" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "IKPOBA-UKPA-2",
      name: "IKPOBA-UKPA",
      fill: "#001755",
      translate: { x: "683.174", y: "1131.238" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M",
      name: "4M",
      fill: "#001755",
      translate: { x: "707.922", y: "1154.238" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OREDO",
    path: {
      id: "Path_6910",
      d: "M274.62,492,259.5,507.124l-2.292,3.666-13.749,11.916-17.415,13.749-8.249,26.581-5.958,18.79L210,590.993h9.166l6.875,3.666,7.333-1.833,7.791-9.166c2.445-4.278,7.333-13.2,7.333-14.666a8.17,8.17,0,0,1,1.833-4.125V555.7l4.125-5.041L260.871,547h11.916L276,545.621l8.249-15.582-4.125-5.5V503.458c0-3.666,4.125-4.583,4.125-5.958v-3.208Z",
      translate: { x: "424.594", y: "568.472" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OREDO-2",
      name: "OREDO",
      fill: "#001755",
      translate: { x: "0.643,", y: "-0.766," },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "0.643,", y: "-0.766," },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OVIA_SOUTH_WEST",
    path: {
      id: "Path_6911",
      dataName: "OVIA SOUTH WEST",
      d: "M94.41,294.917,182.4,294v16.041l-5.041,3.208-5.041,14.666-10.083,14.207L157.2,357.246l15.124,21.54v5.5l10.083,11,10.083,7.333,8.708,8.249,5.041,5.041v3.208l-3.666,5.041-13.291,11.458L182.4,447.073v26.581l4.125,7.333,2.75,7.791v9.166L182.4,507.11,169.113,509.4,157.2,514.9l-15.582,12.374,5.5,6.416-1.375,11.458L132.449,558.9H116.867l-3.208,5.5L107.7,577.689v6.875L105.409,595.1l-7.333-4.125L91.2,584.563l-2.75-4.125-3.666-6.875L72.412,562.106,48.58,545.149l1.833-3.666V536.9h-2.75l-7.333,1.375L29.79,539.65l-1.375-4.583-1.375-4.125-1.375-5.5a14.1,14.1,0,0,0-3.208-1.833,54.212,54.212,0,0,1-5.5-2.75c-1.222-.611-3.666-2.108-3.666-3.208,0-1.375-2.75-3.208-3.208-4.125A55.609,55.609,0,0,0,5.5,507.11l-4.125-4.125L0,497.944l1.375-4.583,2.75-6.416,4.125-4.583,5.041-4.583,13.749-6.416,9.166-1.833,5.041-5.5v-6.416l2.292-10.541,6.875-8.249c1.069-1.833,3.391-5.775,4.125-6.875s2.75-5.653,3.666-7.791V414.075l-1.375-4.583-2.75-5.041-9.624-9.166-4.125-5.041c-.611-1.375-1.833-4.491-1.833-5.958s-1.528-2.75-2.292-3.208l.917-6.875H43.08l3.666-1.375V360.454a42,42,0,0,1,1.375-5.5l2.292-3.666,2.75-3.208,7.791-5.958L71.5,334.789l10.083-6.875a17.126,17.126,0,0,0,1.375-4.583v-6.416l3.208-3.666.917-5.958,2.292-2.75,3.666-2.75Z",
      translate: { x: "442.107", y: "584.984" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OVIA_SOUTH_WEST-2",
      name: "OVIA SOUTH WEST",
      fill: "#001755",
      translate: { x: "509.936", y: "1018.496" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4.5M",
      name: "4.5M",
      fill: "#001755",
      translate: { x: "539.268", y: "1048.413" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OVIA_NORTH_EAST",
    path: {
      id: "Path_6912",
      dataName: "OVIA NORTH EAST",
      d: "M228.825,293.5H190.786v16.041l-5.041,8.708-5.958,9.166-5.958,12.832-6.416,9.624-1.833,4.583,3.208,7.791,1.375,5.958,3.666,2.75,5.958,6.874,1.375,5.958,3.208,4.125,8.708,8.249L213.7,413.117l2.292,3.666-1.375,4.583c-4.583,4.43-14.116,13.566-15.582,14.666s-5.8,8.4-7.791,11.916V470.4l2.292,5.5.917,7.333,2.75,4.583,1.375,4.125-2.75,9.166-5.958,7.333-11,1.375-9.624,2.292-6.875,5.5-10.083,10.083,3.208,6.875v8.708l-15.124,16.5L124.79,558.4l-1.833,5.958-7.333,17.874v10.541l-2.292,3.208L106,616.144l3.666,10.083,10.083,15.582,5.958,5.041,28.415-24.29-1.833-3.208c-1.68-1.528-5.408-4.766-6.875-5.5s-.611-2.445,0-3.208l1.833-5.5a8.406,8.406,0,0,1,1.833-2.75l3.208-.917,4.125-1.375H173.37l10.541,16.041,17.415-44,16.041-52.7,19.707-13.749c2.9-2.75,9.258-8.616,11.458-10.083s.917-3.055,0-3.666l17.415-15.582h5.041l5.5,3.208V443.823l4.125-6.416,16.041-15.124,23.373-16.957,18.79-13.291,5.041-11.458,4.125-18.79,2.75-9.624,5.041-12.832,7.333-11-1.375-10.083-4.583-10.083-10.541-1.375h-8.249l-8.708,4.583-5.958,1.833L321.4,321l-27.04,14.666-16.5-10.083h-3.208l-18.332,10.083-2.292,2.292v6.875l-1.375,2.75-4.583-2.75-12.832-19.249-7.791-7.333,1.375-5.041,4.583-8.249,11.458-2.75Z",
      translate: { x: "433.268", y: "585.026" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OVIA_NORTH_EAST-2",
      name: "OVIA NORTH EAST",
      fill: "#001755",
      translate: { x: "641.928", y: "978.166" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-2",
      name: "5M",
      fill: "#001755",
      translate: { x: "673.092", y: "995.581" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OWAN_EAST",
    path: {
      id: "Path_6913",
      dataName: "OWAN EAST",
      d: "M426.039,127H408.165l-2.75,1.833-1.375,4.583-5.5,3.666v9.624l2.75,4.583,4.125,6.416v6.416L382.5,177.413l2.292,11,34.373,9.624,18.332,5.041,3.666,3.666L443,214.536l-9.624,12.832,7.791,18.332L454,264.49l1.833,11,14.207-2.291-2.75,31.623,34.373-19.707,21.082-3.208,4.583-1.833V275.49l3.208-15.582,9.624-26.123-2.292-35.748-13.291-20.624-16.5-26.123-12.832-4.583-19.249-3.666V130.666l-5.5-3.666-6.874,5.5H454l-16.5,6.875Z",
      translate: { x: "410.209", y: "598.912" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OWAN_EAST-2",
      name: "OWAN EAST",
      fill: "#001755",
      translate: { x: "844.496", y: "776.513" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-2",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "866.035", y: "804.677" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ESAN_WEST",
    path: {
      id: "Path_6914",
      dataName: "ESAN WEST",
      d: "M537.162,298.958l-3.208-4.125-14.666,4.125-11.458,1.375-15.582,10.083-12.374,5.958L473,321.415v5.958l6.874,3.208,6.416,6.875,3.666,11,4.125,11.916L492.249,373.2l-5.958,10.541v14.207l3.666,16.957,9.166,16.041,26.123-12.832,6.416,2.75h19.249V410.325l-3.666-5.958-3.666-9.166v-8.708l3.666-6.416,3.666-3.666,11-8.249,9.624-7.791L570.16,351.2l-8.249-9.166v-4.583l3.666-10.083h4.583l15.582-4.125,28.415-1.833,3.666-3.208,15.582-17.874h-9.624l-11.458-1.375-17.415-4.125-9.166,5.5-7.333,10.083H567.868v-3.666l-2.292-3.208L563.744,293h-5.5Z",
      translate: { x: "402.66", y: "585.067" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ESAN_WEST-2",
      name: "ESAN WEST",
      fill: "#001755",
      translate: { x: "897.66", y: "929.586" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M",
      name: "2M",
      fill: "#001755",
      translate: { x: "918.74", y: "947.918" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IGUEBEN",
    path: {
      id: "Path_6915",
      d: "M596.41,359.375l-22.457,7.791L557,378.624l-9.166,9.166-2.292,6.875,5.041,15.582,2.75,7.333-2.75,9.624-24.29-3.666L502,436.828l11,14.666,1.833,11.916,24.29-6.416h8.708l7.333,1.375,3.666,3.208,24.29,15.582h3.666l10.541-21.54-7.333-9.166,1.833-11.458-1.833-2.75-8.249-14.666,6.875-20.165,11-.917L615.2,383.665l-2.75-24.29L603.285,358Z",
      translate: { x: "400.242", y: "579.646" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "IGUEBEN-2",
      name: "IGUEBEN",
      fill: "#001755",
      translate: { x: "926.99", y: "1023.079" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-2",
      name: "2M",
      fill: "#001755",
      translate: { x: "963.654", y: "1038.662" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ESAN_SOUTH_EAST",
    path: {
      id: "Path_6916",
      dataName: "ESAN SOUTH EAST",
      d: "M747.072,316c-15.124-1.833-45.555-5.5-46.288-5.5H689.326l-7.333,2.292c-2.138.458-6.783,1.1-8.249,0-1.833-1.375-9.166,1.833-12.374,1.375-2.566-.367-5.041,7.486-5.958,11.458v15.124l-12.832,1.375-12.374,5.041-9.166,9.166v24.748l-13.291,10.541-12.374,1.833L591.25,404.91l-2.75,10.083,10.541,16.957-1.375,11.916,6.416,7.333-6.416,20.165,68.287-34.831L685.2,434.7l27.04-21.082,12.374,1.375,14.207,1.375,24.748,2.292V382.453l-2.292-3.666c-5.194-5.347-15.857-16.5-16.957-18.332s-1.68-6.263-1.833-8.249l-1.375-22.915,8.249-8.249Z",
      translate: { x: "393.029", y: "583.608" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ESAN_SOUTH_EAST-2",
      name: "ESAN SOUTH EAST",
      fill: "#001755",
      translate: { x: "1021.4", y: "973.583" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M",
      name: "3M",
      fill: "#001755",
      translate: { x: "1053.482", y: "987.332" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "esan_central",
    path: {
      id: "Path_6918",
      dataName: "esan central",
      d: "M570.5,338l3.208-9.166,19.249-5.041,29.79-2.292L638.329,305l15.582,5.5,4.125,3.666-4.125,9.624v17.415H641.995l-12.832,4.583L620,355.413h-3.208L609,354.038l-28.873,8.249-1.833-10.083-7.791-9.166Z",
      translate: { x: "394.529", y: "584.067" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "esan_central-2",
      name: "esan central",
      fill: "#001755",
      translate: { x: "980.533", y: "915.586" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-2",
      name: "4M",
      fill: "#001755",
      translate: { x: "984.736", y: "929.586" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ETSAKO_CENTRAL",
    path: {
      id: "Path_6919",
      dataName: "ETSAKO CENTRAL",
      d: "M681.455,155l-28.415,1.833v21.54l-5.958,7.791-12.374,13.291,9.624,6.875v5.958l-5.958,9.624L631.5,227.87l1.833,7.333,5.041,7.791,22-5.5h10.541l14.207,10.083,23.832,16.041-7.333,15.124V284.7l2.75,13.291,45.83,5.041-10.083-26.123,25.665-35.748V235.2l2.292-9.166-8.708-2.292-21.54-1.833-24.29-7.333h-7.791l-1.833-9.624V173.79l-13.749-16.957Z",
      translate: { x: "389.441", y: "596.576" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ETSAKO_CENTRAL-2",
      name: "ETSAKO CENTRAL",
      fill: "#001755",
      translate: { x: "1092.969", y: "841.34" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-2",
      name: "3M",
      fill: "#001755",
      translate: { x: "0.571,", y: "0.821," },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ETSAKO WEST",
    path: {
      id: "Path_6920",
      d: "M613.453,149.333c1.467,1.467-25.665-4.278-39.414-7.333L569,145.208l-3.666,5.958L553.874,166.29,536,180.956l13.749,20.165,1.375,40.789-8.708,20.165-4.125,12.832,1.833,8.249,2.292,3.208,11-3.208,10.541-3.208,5.958,1.833v8.708l2.75,3.208,1.375,4.125h10.083l1.833-4.125,8.249-7.333,8.249-3.208,15.582,3.208L638.66,288.2l8.708-11.458,24.748-22.457,1.375-5.041,2.75-3.666-5.958-5.041-12.374-4.125-12.374,1.375-16.041,4.125-5.5-16.041,9.624-6.416c.917-3.819,2.75-11.916,2.75-13.749s-6.111-5.958-9.166-7.791l19.249-20.165-1.375-22C633.923,153,611.986,147.866,613.453,149.333Z",
      translate: { x: "397.406", y: "597.661" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ETSAKO-2",
      name: "ETSAKO",
      fill: "#001755",
      translate: { x: "964.57", y: "805.845" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.5M",
      name: "3.5M",
      fill: "#001755",
      translate: { x: "972.82", y: "818.677" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ETSAKO_EAST",
    path: {
      id: "Path_6921",
      dataName: "ETSAKO EAST",
      d: "M667.327,74H624.7l-3.666,12.374L610.956,90.5v6.416h-5.041L588.5,82.249,572,84.541l1.375,14.207,3.208,10.083,1.375,9.624-1.375,17.415,41.706,9.166,29.79,5.5,14.666-1.833,12.374-1.375,7.791,1.375,5.041,3.208,4.583,5.5,5.041,8.249,1.833,5.5v30.248l2.75,6.875h8.249l22,6.875,18.332,1.375,11.916,2.75V189.95l12.832-22.915L773.653,148.7l-2.75-12.832-5.041-4.583-11,1.833-13.291-18.332-4.583-1.833-17.874,4.125-19.707,5.5-16.5-7.791c-.153-1.68-.733-5.225-1.833-5.958s-2.291-5.194-2.75-7.333l-3.208-8.249c-2.6-3.361-7.791-10.266-7.791-11Z",
      translate: { x: "394.404", y: "603.332" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ETSAKO_EAST-2",
      name: "ETSAKO EAST",
      fill: "#001755",
      translate: { x: "995.736", y: "725.184" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4.5M-2",
      name: "4.5M",
      fill: "#001755",
      translate: { x: "1009.484", y: "738.016" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "AKOKO_EDO",
    path: {
      id: "Path_6922",
      dataName: "AKOKO EDO",
      d: "M431.415,25.207l-5.041,5.041.917,5.5,4.125,4.583,11,8.708v4.583l-3.666,7.791V71.5c0,2.933,2.445,5.5,3.666,6.416V88.452l-2.292,9.624L411.25,113.659,408.5,118.7l18.79-1.375,11.458,13.749,16.5-7.791h11l5.041-4.583,5.958,1.375,1.375,14.666,19.249,2.75,10.541,4.583,17.415,26.581c6.569-5.041,19.982-15.491,21.082-16.957s11.152-15.582,16.041-22.457v-5.958l2.292-12.374-5.5-18.332V77.911L575.78,75.62l16.041,15.124H596.4V83.869l11.916-2.292,3.666-14.207L573.03,35.748c-8.25,1.68-25.023,4.95-26.123,4.583s-13.6,8.708-19.707,13.291l-20.624-11-4.125-8.249,9.624-16.5-13.749-3.208L490.994,0,476.787,1.833,464.871,6.875,449.289,26.581Z",
      translate: { x: "408.039", y: "609.503" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "AKOKO_EDO-2",
      name: "AKOKO EDO",
      fill: "#001755",
      translate: { x: "877.494", y: "689.436" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-3",
      name: "4M",
      fill: "#001755",
      translate: { x: "909.574", y: "707.768" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OWAN_WEST",
    path: {
      id: "Path_6923",
      dataName: "OWAN WEST",
      d: "M371.373,198.958,381.456,193l3.208,1.833,6.875,1.833,8.249,2.292,16.041,4.125,14.207,3.666,6.875,4.125,2.291,8.708-9.166,11.458.458,5.958,18.79,30.706,2.75,12.832,14.207-1.375-1.375,21.54-2.75,11.916L450.2,311.242c-3.819-1.222-11.733-3.758-12.832-4.125-1.375-.458-9.166-2.292-11.458-2.292-1.833,0-10.846-1.222-15.124-1.833l-20.624-1.375-12.374-.917c-6.569-1.07-19.89-3.116-20.624-2.75s-8.555,4.125-12.374,5.958l-2.292-.917v-5.5l1.833-8.249,2.75-7.791v-6.874l6.875-1.375,4.125-3.208,4.125-5.041,1.833-3.666,2.75-4.125-4.583-3.666-6.416-6.416-1.833-4.583V226.914l4.125-9.624,5.958-9.624Z",
      translate: { x: "413.545", y: "593.407" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OWAN_WEST-2",
      name: "OWAN WEST",
      fill: "#001755",
      translate: { x: "774.496", y: "843.155" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-3",
      name: "3M",
      fill: "#001755",
      translate: { x: "808.916", y: "867.276" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "uhunmwonde",
    path: {
      id: "Path_6924",
      dataName: "UHUNNA WONDE",
      d: "M392.868,312.792c-1.1,0-11.763-1.528-16.957-2.292h-2.75l4.125,10.083,1.833,8.708-5.958,6.416-7.333,15.582-6.416,30.248-6.875,13.749-36.664,25.207-11,9.166-9.166,10.083L292.5,445.7v9.166h13.291l5.041,2.75,6.416,5.958,3.666,15.124,8.249,10.541h8.249l11.916,4.583,24.748,16.5-.917,2.75.917,12.374,22-10.083h25.207l11.916-3.666,6.875-6.875,1.375-8.249,2.291-1.833,17.415-7.791,10.083-9.624,18.79-17.874h7.333v-.917l-.458-3.666-1.833-7.333-.917-1.833L482.7,429.2l-8.249-14.207L471.7,401.7V384.745l7.333-15.124L478.112,360l-3.666-10.541-3.666-12.374c-3.208-2.75-10.266-8.433-12.832-9.166s-1.07-4.278,0-5.958h-9.624l-11-3.666-20.624-5.5Z",
      translate: { x: "417.715", y: "583.608" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "UHUNNA_WONDE-2",
      name: "UHUNNA WONDE",
      fill: "#001755",
      translate: { x: "751.002", y: "1016.663" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-3",
      name: "5M",
      fill: "#001755",
      translate: { x: "791.791", y: "1045.662" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "EGOR",
    path: {
      id: "Path_6925",
      d: "M262.893,534.833l8.393-6.043,3.357-4.028,6.714-8.057,12.59-14.1,7.554-5.035-1.679-3.021-5.036-4.028-4.2-3.021h-5.875l-7.554,3.021-10.072,7.05-2.518,6.042L256.179,515.7,254.5,527.783l1.679,4.028Z",
      translate: { x: "403.164", y: "560.5" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "EGOR-2",
      name: "EGOR",
      fill: "#001755",
      translate: { x: "0.78,", y: "-0.626," },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-3",
      name: "2M",
      fill: "#001755",
      translate: { x: "0.78,", y: "-0.626," },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ESAN_NORTH_EAST",
    path: {
      id: "Path_6917",
      dataName: "ESAN NORTH EAST",
      d: "M658.207,281.623,644,301.788l22,6.875h17.416l12.374-3.208H704.5l-1.833-17.415,1.833-4.583,5.5-11L686.164,255.5l-4.583,3.208-1.375,5.5-8.708,8.249Z",
      translate: { x: "388.4", y: "588.195" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-4",
      name: "2M",
      fill: "#001755",
      translate: { x: "1056.398", y: "889.067" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "ESAN_NORTH_EAST-2",
      name: "ESAN NORTH EAST",
      fill: "#001755",
      translate: { x: "1056.398", y: "874.276" },
      tspan: { X: "0", y: "0" },
    },
  },
];
