import "./style.css";
import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  RiDashboardFill,
  RiWallet3Fill,
  RiSettings5Fill,
  RiScales3Fill,
} from "react-icons/ri";
import { color } from "../../constant/color";
import { HiDeviceMobile } from "react-icons/hi";
import { GiChemicalTank } from "react-icons/gi";
import { IoLogOut } from "react-icons/io5";
import { deleteToken, getToken, setToken } from "../../utils/token";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { UPDATE_INTERVENTION, UPDATE_THRESHOLD } from "../../redux/optionSlice";

const SideNav = () => {
  const value = useSelector((state) => state);

  const [mouse, setMouse] = useState(false);
  const history = useHistory();
  const handlenavItems = () => {
    setMouse((prev) => true);
  };
  const handleMouseOut = () => {
    setMouse((prev) => false);
  };

  const navLink = useRef();
  const [name, setName] = useState("AK");
  const [fullName, setfullName] = useState("");

  useEffect(() => {
    (async () => {
      try {
        let user = await getToken("epump_agr_email");
        if (!user) return null;
        setName(user.substring(0, 2).toUpperCase());
        setfullName(user);
      } catch (err) {
        console.log(err.message);
      }
    })();
  }, []);

  const handleLogout = async () => {
    try {
      await deleteToken("epump_agr_token");
      history.push("/login");
      return;
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div
      className="p-2 side-nav__ic"
      onMouseOver={handlenavItems}
      onMouseOut={handleMouseOut}
    >
      <div className="brand p-2 mt-2 rounded-3 d-flex align-items-center ">
        <div className="brand-icon d-flex align-items-center justify-content-center">
          <div className="profile-alias">{name}</div>
        </div>
        <div
          className="brand-name px-2"
          style={{
            display: mouse ? "block" : "none",
          }}
        >
          {fullName}
        </div>
      </div>
      <div className="">
        <Link to={"/"} className="nav-item px-2 d-flex align-items-center my-3">
          <div className="nav-icon  px-2">
            <RiDashboardFill size={23} color={"#0c1238"} />
          </div>
          <div
            className="nav-link"
            ref={navLink}
            style={{
              display: mouse ? "block" : "none",
            }}
          >
            <div className="px-1 nav-link__text">Overview</div>
          </div>
        </Link>
        <Link
          to={
            "/aggregate/tabledata/company/nigeria/00000000-0000-0000-0000-000000000000"
          }
          className="nav-item px-2 d-flex align-items-center my-3"
        >
          <div className="nav-icon px-2">
            <RiWallet3Fill size={23} color={"#0c1238"} />
          </div>
          <div
            className="nav-link "
            ref={navLink}
            style={{
              display: mouse ? "block" : "none",
            }}
          >
            <div className="px-1 nav-link__text">Companies</div>
          </div>
        </Link>
        <Link
          to={"/aggregate/tabledata/nigeria"}
          className="nav-item px-2 d-flex align-items-center my-3"
        >
          <div className="nav-icon px-2">
            <GiChemicalTank size={23} color={"#0c1238"} />
          </div>
          <div
            className="nav-link "
            ref={navLink}
            style={{
              display: mouse ? "block" : "none",
            }}
          >
            <div className="px-1 nav-link__text">States</div>
          </div>
        </Link>
        {value.threshold && (
          <Link
            to={"/aggregated/company/branchabovetrigger"}
            className="nav-item px-2  d-flex align-items-center my-3"
          >
            <div className="nav-icon px-2">
              <HiDeviceMobile size={23} color={"#0c1238"} />
            </div>
            <div
              className="nav-link "
              ref={navLink}
              style={{
                display: mouse ? "block" : "none",
              }}
            >
              <div className="px-1 nav-link__text">Branch Threashold</div>
            </div>
          </Link>
        )}

        {value.intervention && (
          <Link
            to={"/aggregate/company/interventions/nigeria"}
            className="nav-item px-2 d-flex align-items-center my-3"
          >
            <div className="nav-icon px-2">
              <RiScales3Fill size={23} color={"#0c1238"} />
            </div>
            <div
              className="nav-link "
              ref={navLink}
              style={{
                display: mouse ? "block" : "none",
              }}
            >
              <div className="px-1 nav-link__text">Interventions</div>
            </div>
          </Link>
        )}
        <Link
          to={"/aggregate/company/settings"}
          className="nav-item px-2 d-flex align-items-center my-3"
        >
          <div className="nav-icon px-2">
            <RiSettings5Fill size={23} color={"#0c1238"} />
          </div>
          <div
            className="nav-link "
            ref={navLink}
            style={{
              display: mouse ? "block" : "none",
            }}
          >
            <div className="px-1 nav-link__text">Settings</div>
          </div>
        </Link>
        <div
          className="nav-item px-2 d-flex align-items-center my-3"
          style={{
            cursor: "pointer",
          }}
        >
          <div className="nav-icon px-2">
            <IoLogOut size={23} color={"#0c1238"} />
          </div>
          <div
            className="nav-link "
            ref={navLink}
            style={{
              display: mouse ? "block" : "none",
            }}
            onClick={handleLogout}
          >
            <div className="px-1 nav-link__text">Log out </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
