import axios from "axios";
import toast from "react-hot-toast";
import { getToken, deleteToken } from "./token";

const instance = axios.create({
  baseURL: "https://to.epump.com.ng/",
});

instance.interceptors.request.use(async (config) => {
  try {
    let token = await getToken("epump_agr_token");
    config.headers.Authorization = `Bearer ${token}`;
    config.headers["Content-Type"] = "text/jplain";
    return config;
  } catch (err) {
    toast.error(err);
  }
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err.response && err.response.status == 403) {
      // redirect to login page
      return (window.location.href = "/login");
    }

    if (
      err.response &&
      err.response.status == 401 &&
      window.location.pathname !== "/login"
    ) {
      deleteToken("epump_agr_token");
      window.location.href = "/login";
    }
    return Promise.reject(err);
  }
);

export default instance;
