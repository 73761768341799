export const data = [
  {
    state: "OSHIMILI_NORTH",
    path: {
      id: "Path_7036",
      dataName: "OSHIMILI NORTH",
      d: "M469.38,4.251c2.4-2.834,4.79-4.208,8.5-4.251,8.405-.1,1.52,13.063,3.4,21.256,2.42,10.541,5.314,16.022,8.5,26.357,2.756,8.935,4.743,13.835,6.8,22.956,2.729,12.093,4.251,31.459,4.251,31.459a13.55,13.55,0,0,0-5.952,2.551l-8.5,5.952c-3.321,2.324-6.458,11.442-7.652,19.555-.774,5.256,0,13.6,0,13.6s7.652,3.4,7.652,8.5-2.591,6.737-4.251,11.053-3.01,6.579-6.8,8.5c-4.146,2.1-11.9,0-11.9,0s-11.565-6.411-14.454-13.6a21.985,21.985,0,0,1-1.7-8.5c-.233-5.794.162-9.645,3.4-14.454,2.637-3.914,7.795-3.2,9.353-7.652.986-2.821.74-4.757,0-7.652-1.286-5.025-5.09-6.3-8.5-10.2-6.028-6.9-16.154-17-16.154-17s-8.234-9.461-6.8-16.154c.809-3.786,3.721-4.251,5.1-8.5s-2.724-21.211-2.551-34.859c.119-9.32,1.7-23.806,1.7-23.806a18.025,18.025,0,0,1,3.4-3.4c4.469-2.891,11.279-.146,13.6.85l5.952,2.551h11.053A19.051,19.051,0,0,1,469.38,4.251Z",
      translate: { x: "299.729" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OSHIMILI_NORTH-2",
      name: "OSHIMILI NORTH",
      fill: "#001755",
      translate: { x: "799.288", y: "58.498" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "754.654", y: "85.864" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ANIOCHA_NORTH",
    path: {
      id: "Path_7037",
      dataName: "ANIOCHA NORTH",
      d: "M449.62,5.982c7.169-2.634,19.555,0,19.555,0s-1.864,15.006-1.7,24.657c.165,9.679,2.277,14.98,2.551,24.657.1,3.651,1.25,5.921,0,9.353-.978,2.684-3.273,4.118-4.251,6.8-1.25,3.432-.765,4.931,0,8.5,1.093,5.1,6.975,9.555,11.9,15.3,4.129,4.817,11.053,11.9,11.053,11.9s-5.88,3.015-9.353,1.7c-3.79-1.435-3.3-5.44-5.952-8.5-5.436-6.272-9-10.549-17-12.753a25.014,25.014,0,0,0-11.053-.85c-9.788,1.146-12.573,8.939-21.256,13.6-9.563,5.138-14.668,10.441-25.507,11.053-8.126.459-20.406-4.251-20.406-4.251s3.806-5.064,5.952-8.5c2.595-4.159,5.239-6.2,5.952-11.053.8-5.418-1.746-8.384-3.4-13.6-2.964-9.352-10.2-22.956-10.2-22.956s28.381-11.65,44.212-22.956c8.822-6.3,12.031-12.162,21.256-17.855C444.877,8.438,446.411,7.161,449.62,5.982Z",
      translate: { x: "263.373", y: "3.371" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ANIOCHA_NORTH-2",
      name: "ANIOCHA NORTH",
      fill: "#001755",
      translate: { x: "633.129", y: "4.474" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-2",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "684.936", y: "72.261" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ANIOCHA_SOUTH",
    path: {
      id: "Path_7038",
      dataName: "ANIOCHA SOUTH",
      d: "M490.731,75.28a15.508,15.508,0,0,0,5.1-1.7s7.735,5.073,8.5,10.2c.5,3.349-1.7,8.5-1.7,8.5a34.093,34.093,0,0,0-6.8,5.1,19.886,19.886,0,0,0-5.1,10.2c-1.184,5.181.558,9.5,2.542,14.432l.009.022c1.937,4.811,4.328,5.425,8.5,8.5,2.988,2.2,6.8,5.1,6.8,5.1s1.24,5.783-1.7,9.353a54.98,54.98,0,0,1-5.1,4.251H485.63c-3.984,0-7-2.377-10.2,0a7.62,7.62,0,0,0-3.4,5.952c-.371,3.855,5.1,8.5,5.1,8.5l-1.7,18.705-41.661,4.251s-15.3,2.551-23.806,1.7-9.353-9.353-9.353-9.353.473-9.038-3.4-9.353a8.729,8.729,0,0,0-3.4.85l-5.952,11.9s-3.7,3.84-6.8,4.251c-3.977.527-6.421-1.512-9.353-4.251-3.7-3.454-4.355-6.9-5.1-11.9-.789-5.3.554-8.375,1.7-13.6,1.665-7.593,2.755-12.068,6.8-18.705,1.488-2.438,4.251-5.952,4.251-5.952s-3.642-7.793-2.551-12.753c.93-4.228,3.006-6.179,5.952-9.353,4.261-4.592,13.6-8.5,13.6-8.5s-.874-4.7-1.7-7.652C392.29,85.545,385.3,73.58,385.3,73.58l11.053,4.251s7.846.854,12.753,0c4.863-.846,7.45-2.122,11.9-4.251a54.731,54.731,0,0,0,11.053-6.8c3.185-2.418,4.547-4.284,7.652-6.8,2.58-2.091,3.807-3.669,6.8-5.1,4.829-2.31,8.308-2.49,13.6-1.7,5,.746,7.686,2.31,11.9,5.1,4.7,3.11,7.237,5.412,10.2,10.2,1.173,1.894.862,3.648,2.551,5.1C486.611,75.157,488.316,75.385,490.731,75.28Z",
      translate: { x: "256.271", y: "36.95" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ANIOCHA_SOUTH-2",
      name: "ANIOCHA SOUTH",
      fill: "#001755",
      translate: { x: "654.327", y: "169.188" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-3",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "681.129", y: "183.896" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IKA_NORTH EAST",
    path: {
      id: "Path_7039",
      dataName: "IKA NORTH-EAST",
      d: "M399.194,35.751c5.843-2.084,15.3-4.251,15.3-4.251s4.34,8.722,6.8,14.454c2.532,5.892,4.726,9.01,5.952,15.3.7,3.6,1.794,5.809.85,9.353-.806,3.027-2.544,4.175-4.251,6.8-2.86,4.4-7.652,11.053-7.652,11.053a113.276,113.276,0,0,1,6.8,12.753,113.375,113.375,0,0,1,4.251,14.454s-4.751,2.985-7.652,5.1c-3.812,2.781-6.917,3.609-9.353,7.652a15.213,15.213,0,0,0-2.551,10.2c.241,3.141,2.551,7.652,2.551,7.652s-7.924-1.235-11.9-.85a26.874,26.874,0,0,0-9.353,2.551c-4.4,1.9-5.725,4.255-10.2,5.952-2.862,1.085-4.591,2.551-7.652,2.551a16.43,16.43,0,0,1-7.652-1.7c-4.515-1.995-6.327-4.6-9.353-8.5-3.823-4.93-4.251-8.5-6.8-14.454-1.575-3.675-3.891-5.445-7.652-6.8-3.435-1.239-5.707.222-9.353,0-3-.182-5.081.765-6.8-1.7-1.532-2.2-1.85-4.016,0-5.952,1.451-1.518,3.207-.794,5.1-1.7a19.853,19.853,0,0,0,5.1-3.4,24.587,24.587,0,0,0,5.952-7.652c1.767-3.109,1.233-5.659,3.4-8.5,2.459-3.223,4.59-4.046,8.5-5.1,4.533-1.223,7.553-1.431,11.053,1.7,1.995,1.785,3.416,2.558,4.251,5.1.629,1.919.213,2.886,1.7,4.251,2.017,1.851,2.843-.151,5.1-1.7a13.117,13.117,0,0,0,5.1-5.952c1.243-2.55,1.49-4.171,2.551-6.8.945-2.345,1.753-3.424,1.7-5.952-.066-3.132-1.447-4.354-3.4-6.8a28.642,28.642,0,0,0-8.5-7.652,49.5,49.5,0,0,0-7.652-3.4s10.645-15.162,20.406-21.256C389.439,39.09,393.033,37.948,399.194,35.751Z",
      translate: { x: "225.375", y: "22.065" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "IKA_NORTH-EAST-2",
      name: "IKA NORTH-EAST",
      fill: "#001755",
      translate: { x: "534.004", y: "51.255" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-4",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "603.313", y: "147.081" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OSHIMILI_SOUTH",
    path: {
      id: "Path_7040",
      dataName: "OSHIMILI SOUTH",
      d: "M494.011,63.4c2.61-1.416,6.8-3.4,6.8-3.4a94.871,94.871,0,0,0,1.7,12.753,52.887,52.887,0,0,0,4.251,11.9c3.877,8.158,13.6,18.705,13.6,18.705s.847,7.333,0,11.9c-1.056,5.7-2.593,8.876-5.952,13.6-3.4,4.79-6.577,6.4-11.053,10.2-3.239,2.753-8.5,6.8-8.5,6.8l8.5,9.353v19.555l-4.251,5.1a65.687,65.687,0,0,0-11.053-4.251c-7.12-1.632-18.705,0-18.705,0a46.677,46.677,0,0,0-4.251-2.551c-2.826-1.391-4.629-3.433-7.652-2.551-3.188.93-5.1,6.8-5.1,6.8H431.094s1.211-5.152,1.7-8.5c.578-3.957.85-10.2.85-10.2a13.434,13.434,0,0,1-2.551-1.7,7.686,7.686,0,0,1-2.551-5.1,7.878,7.878,0,0,1,.85-5.1c1.024-2.114,4.251-4.251,4.251-4.251a34.263,34.263,0,0,0,5.952,1.7c2.3.316,3.627,0,5.952,0h11.9a17.245,17.245,0,0,0,3.4-1.7,10.166,10.166,0,0,0,4.251-6.8,18.756,18.756,0,0,0,0-5.1s5.319.942,8.5,0c3-.889,5.305-3.4,6.8-5.1s2.551-7.652,2.551-7.652,3.006-2.059,3.4-4.251a9.279,9.279,0,0,0-1.7-7.652c-1.609-2.361-5.952-4.251-5.952-4.251a93.2,93.2,0,0,1,0-11.9,46.527,46.527,0,0,1,1.7-9.353c1.374-4.867,1.615-8.239,5.1-11.9C488.178,65.7,490.606,65.247,494.011,63.4Z",
      translate: { x: "299.754", y: "42.028" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OSHIMILI_SOUTH-2",
      name: "OSHIMILI SOUTH",
      fill: "#001755",
      translate: { x: "807.548", y: "190.896" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-5",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "756.354", y: "204.896" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "NDOKWA_EAST",
    path: {
      id: "Path_7041",
      dataName: "NDOKWA EAST",
      d: "M540.251,129.6c5.241,1.146,12.753,5.1,12.753,5.1s.951,8.376,0,13.6c-.752,4.132-1.854,6.3-3.4,10.2-3.945,9.954-9.067,14.107-13.6,23.806-3.92,8.378-6.208,13.146-8.5,22.106-3.549,13.866-2.551,36.56-2.551,36.56s-3.207,3.017-5.1,5.1a118.072,118.072,0,0,0-9.353,12.753s-1.149,5.681-1.7,9.353c-1.286,8.562-1.7,22.106-1.7,22.106s-2.619,25.753-14.454,34.009c-5.259,3.67-15.3,5.952-15.3,5.952l-2.551,14.454s3.367,5.44,4.251,9.353c1.4,6.188,1.33,10.582-1.7,16.154-2.243,4.125-4.187,6.652-8.5,8.5-4.578,1.962-12.753,0-12.753,0s-2.121,5.142-1.7,8.5c.593,4.751,6.8,10.2,6.8,10.2v15.3s-1.7,7.652-6.8,10.2h-17s-5.845,3.959-9.353,6.8c-3.471,2.813-4.387,5.914-8.5,7.652-4.894,2.066-8.375.94-13.6,0-4.858-.874-7.108-3.078-11.9-4.251-4.561-1.115-7.232-2.183-11.9-1.7a20.93,20.93,0,0,0-10.2,3.4c-3.6,2.219-7.652,7.652-7.652,7.652l-12.753-5.1s2.437-4.931,4.251-7.652c1.7-2.551,3.236-2.2,5.952-2.551,2.964-.381,4.9,2.018,7.652.85,3.731-1.583,4.855-4.6,5.952-8.5.9-3.2.694-5.256,0-8.5-.684-3.2-2.617-4.477-3.4-7.652-1.52-6.159-.84-10.342,1.7-16.154,1.808-4.139,3.04-6.853,6.8-9.353,3.96-2.631,11.9-2.551,11.9-2.551l5.1-5.1,9.353.85s2.909,2.855,4.251,4.251c2.267,2.357,2.751,2.733,5.952,3.4a17.488,17.488,0,0,0,11.053-.85c3.365-1.255,6.115-1.855,7.652-5.1a7.751,7.751,0,0,0,0-7.652c-1.483-2.76-3.756-3.517-6.8-4.251a20,20,0,0,0-5.952,0l-17.855,4.251-.85-19.555s-3.139-16.377-6.8-26.357c-2.765-7.533-3.986-12.073-8.5-18.705-4.1-6.02-12.753-13.6-12.753-13.6l19.555-8.5,18.705-6.8s11.638.024,17.855-3.4c4.734-2.608,10.2-9.353,10.2-9.353s.661-8.819-2.551-12.753c-3.006-3.683-11.053-5.1-11.053-5.1l-1.7-27.207,17.855-21.256v-10.2l-14.454-6.8L445.025,136.4l41.661-4.251h19.555s.362-3.483,1.7-5.1c1.235-1.491,2.314-2.551,4.251-2.551s2.535,1.655,4.251,2.551c2.241,1.172,5.952,2.551,5.952,2.551S533.439,128.112,540.251,129.6Z",
      translate: { x: "245.861", y: "87.208" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "NDOKWA_EAST-2",
      name: "NDOKWA EAST",
      fill: "#001755",
      translate: { x: "682.554", y: "303.902" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-6",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "714.13", y: "337.993" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "NDOKWA_WEST",
    path: {
      id: "Path_7042",
      dataName: "NDOKWA WEST",
      d: "M361.359,148.71l6.8-20.406h28.058s.592,4.853,3.4,6.8c2.25,1.561,5.9,4.266,8.5,3.4,2.551-.85,6.708-1.513,8.5-5.1,1.7-3.4,4.251-10.2,4.251-10.2s1.92-1.814,3.4-1.7c1.872.145,2.432,1.792,3.4,3.4,1.721,2.858.257,7.195,1.7,10.2,1.838,3.835,11.675,6.479,7.652,5.1-.753-.257,6.747.3,11.053,0a125.733,125.733,0,0,0,15.3-2.551l-15.3,39.961,15.3,7.652v8.5l-17.855,22.106.85,26.357s8.405,2.882,11.053,5.952c3.5,4.062,2.551,12.753,2.551,12.753s-6.025,6.766-11.053,9.353c-6.312,3.246-17.855,3.4-17.855,3.4L392.818,289l-16.154,5.1H342.654l-8.5-11.053-2.551-17-5.1-17.855s6.452-2.455,8.5-5.952c1.384-2.362,1.7-6.8,1.7-6.8l2.551-10.2s20.147,3.051,33.159,3.4c4.647.126,7.29.565,11.9,0,8.026-.983,13.049-2,19.555-6.8,4.926-3.636,8.723-5.962,10.2-11.9,1.214-4.875-.292-7.931-1.7-12.753-1.665-5.7-3.7-8.541-6.8-13.6-4.205-6.849-7.5-10.074-12.753-16.154-4.763-5.51-6.535-9.813-12.753-13.6C373.6,149.869,361.359,148.71,361.359,148.71Z",
      translate: { x: "228.35", y: "85.104" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "NDOKWA_WEST-2",
      name: "NDOKWA WEST",
      fill: "#001755",
      translate: { x: "585.004", y: "337.993" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-7",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "609.322", y: "358.146" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ISOKO_NORTH",
    path: {
      id: "Path_7043",
      dataName: "ISOKO NORTH",
      d: "M303.957,310.975a68.282,68.282,0,0,0,7.652,7.652s12.589-10.213,19.555-17.855c8.815-9.671,19.555-27.207,19.555-27.207l6.8-11.9,7.652,6.8s7.95,5.569,13.6,5.1a14.527,14.527,0,0,0,6.8-1.7c3.653-1.755,5.984-4.549,9.353-6.8,3.6-2.406,5.967-4.205,10.2-5.1,4.273-.9,6.1-2.064,9.353.85,2.435,2.183,4.4,4.385,4.251,7.652-.134,2.967-2.416,4-3.4,6.8-1.449,4.12-2.8,10.616.85,13.6s4,3.814,7.652,6.8,6.8,13.6,6.8,13.6l5.1-5.1s6.639-1.782,10.2,0c2.52,1.26,2.741,3.562,5.1,5.1,4.255,2.775,7.713,3.176,12.753,2.551,4.284-.532,7.771-.685,10.2-4.251,1.542-2.262,2.211-4.112,1.7-6.8-.792-4.178-4.448-5.518-8.5-6.8-4.79-1.517-7.786.947-12.753,1.7-3.994.605-10.2,1.7-10.2,1.7s-.078-10.4-.85-17a103.137,103.137,0,0,0-5.1-23.806l-.158-.478c-3.115-9.446-5-15.153-10.895-23.329-3.986-5.525-11.9-12.753-11.9-12.753l-17,5.1H363.474s-5.052,3.343-8.5,5.1c-3.845,1.961-6.744,3.134-11.053,3.4-3.379.209-4.28-.537-7.652-.85-2.976-.275-4.885-1.131-7.652,0-3.267,1.337-4.61,3.537-5.952,6.8a12.036,12.036,0,0,0-.85,7.652c.527,2.923,2.551,3.833,2.551,6.8,0,2.1.634,2.767-.85,4.251-2.551,2.551-8.683,4.906-13.6,8.5-4.743,3.466-8.128,5.108-11.053,10.2-3.67,6.394-3.865,11.451-2.551,18.705C297.5,302.246,299.856,305.7,303.957,310.975Z",
      translate: { x: "206.681", y: "154.102" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ISOKO_NORTH-2",
      name: "ISOKO NORTH",
      fill: "#001755",
      translate: { x: "553.709", y: "397.256" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-8",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "571.004", y: "410.652" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ISOKO_SOUTH",
    path: {
      id: "Path_7044",
      dataName: "ISOKO SOUTH",
      d: "M379.47,362.033l4.251-7.652s2.309-7.011,5.952-9.353c4.2-2.7,8.674,2.027,12.753-.85,3.164-2.233,6.8-7.652,5.952-12.753-.835-5.01-4.251-7.627-4.251-13.6s0-5.952.85-10.2a18.083,18.083,0,0,1,6.8-11.053c3.85-3.09,11.9-4.251,11.9-4.251s-3.882-8.814-7.652-13.6c-2.9-3.69-7.111-4.018-8.5-8.5-.886-2.853-.347-4.683,0-7.652.609-5.214,6.086-7.834,4.251-12.753-1.871-5.016-6.55-6.734-11.9-6.8-4.753-.061-6.834,2.909-11.053,5.1-6.215,3.231-8.305,9.072-15.3,9.353-3.384.136-5.3-.592-8.5-1.7-6.528-2.263-14.454-10.2-14.454-10.2s-12.248,21.116-22.106,33.159c-8.181,9.994-22.956,23.806-22.956,23.806s2.017,9.269,2.551,15.3c.616,6.946-1.393,11.022,0,17.855,1.367,6.707,3.1,10.4,6.8,16.154,4.571,7.11,8.824,9.878,15.3,15.3,4.736,3.965,12.753,9.353,12.753,9.353l3.4-5.1,33.159-.85Z",
      translate: { x: "213.641", y: "170.212" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ISOKO_SOUTH-2",
      name: "ISOKO SOUTH",
      fill: "#001755",
      translate: { x: "537.097", y: "473.57" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-9",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "559.398", y: "500.525" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "PATANI",
    path: {
      id: "Path_7045",
      d: "M404.035,308.5l13.6,5.1s4.748,7.688,2.551,11.9c-1.66,3.185-4.154,4.285-7.652,5.1a20.626,20.626,0,0,1-5.952,0s-5.62,8.128-11.053,8.5c-3.459.238-5.632-.607-8.5-2.551-2.535-1.716-5.1-5.952-5.1-5.952s-.139,3.632,0,5.952c.243,4.032,1.649,6.164,1.7,10.2.054,4.367,1.821,8.47-1.7,11.053-2.945,2.16-5.78-.76-9.353,0-5.237,1.114-7.678,3.515-11.9,6.8-3.707,2.882-4.112,6.838-8.5,8.5-5.006,1.9-8.742.541-13.6-1.7-3.862-1.78-4.318-7.552-8.5-6.8-3.474.622-3.845,3.971-5.952,6.8-3.2,4.3-2.36,8.916-6.8,11.9-3.408,2.292-6.118,2.127-10.2,2.551-7.006.724-11.264-.066-17.855-2.551-5.435-2.049-12.753-7.652-12.753-7.652v-4.251l4.251-6.8,2.551-17s9.982-.383,13.6-.85a22.262,22.262,0,0,0,10.2-4.251s19.242-3.287,31.458-5.952c9.677-2.112,24.657-5.952,24.657-5.952l3.4-5.1h33.159v-9.353Z",
      translate: { x: "193.327", y: "216.093" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "PATANI-2",
      name: "PATANI",
      fill: "#001755",
      translate: { x: "515.85", y: "567.096" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-10",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "525.673", y: "580.495" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Group_3055",
    path: {
      id: "Path_7046",
      dataName: "Group 3055",
      d: "M321.03,399.343c5.033,5-1.794,22.691,3.4,17.855,2.617-2.437,3.4-8.5,3.4-8.5l2.551-12.753,14.454-.85,16.154-5.952s-11.91-7.13-16.154-14.454a30.265,30.265,0,0,1-4.251-11.9c-1.134-6.912,1.347-10.859,1.7-17.855.4-7.958,1.92-12.672,0-20.406-2.333-9.4-7.487-13.133-12.753-21.256-1.954-3.013-5.1-7.652-5.1-7.652l5.1-10.2s3.406-5.664,2.551-9.353c-.954-4.117-4.173-5.253-7.652-7.652-4.671-3.221-8.026-4.062-13.6-5.1-5.875-1.093-12.432,5.241-15.3,0-2.643-4.819,5.952-12.753,5.952-12.753l-2.551-6.8L275.118,239.5l-4.251,7.652s2.517,14.614,8.5,21.256c3.335,3.7,8.135,3.12,10.2,7.652,1.791,3.926,1.151,6.892,0,11.053-1.7,6.147-4.766,9.419-10.2,12.753-5.219,3.2-9.249,4.3-15.3,3.4-6.03-.9-6.176-5.7-11.053-9.353-2.45-1.836-8.5-3.4-8.5-3.4a47.3,47.3,0,0,1-5.952,5.1,28.629,28.629,0,0,1-10.2,4.251c-6.766,1.687-17.855,0-17.855,0s4.938,8.5,6.8,14.454c1.615,5.157,1.32,8.212,1.7,13.6.233,3.313,0,9.353,0,9.353l9.353,1.7s7.973-8.305,13.6-11.053c6.322-3.086,10.051-4.882,17-5.952,11.053-1.7,11.929-2.1,22.956-2.551,5.64-.23,19.555-.85,19.555-.85s1.872,6.217,0,9.353c-1.464,2.452-5.952,4.251-5.952,4.251s-12.459-.61-20.406,0l-.034,0c-9.721.747-15.673,1.2-24.623,5.1-7.858,3.42-17.855,11.9-17.855,11.9s-9.143,3.952-9.353,9.353c-.167,4.326.944,6.443,5.1,7.652,4.277,1.245,7.87-.661,11.9-2.551,6.2-2.9,7.863-5.8,14.454-7.652,5.345-1.5,11.9-3.4,16.154-2.551,2.551.51,3.442,5.423,4.251,9.353.736,3.578,1.439,6,0,9.352-2.551,5.952-3.988,9.693-9.353,12.753-5.224,2.981-14.784-4.29-15.3,1.7-.406,4.678,8.5,9.352,8.5,9.352s4.309-1.765,6.8-.85c2.435.893,5.1,4.251,5.1,4.251s9.193-7.14,16.154-7.652c5.783-.425,13.192,1.047,14.454,3.4,1.724,3.219,0,9.353,0,9.353l5.952-1.7s2.212-7.057,5.952-7.652C316.753,394.555,318.605,396.934,321.03,399.343Z",
      translate: { x: "147.097", y: "167.761" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2.5M-11",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "443.47", y: "538.086" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "UGHELI_SOUTH",
      name: "UGHELI SOUTH",
      fill: "#001755",
      translate: { x: "423.064", y: "524.584" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "BOMADI",
    path: {
      id: "Path_7047",
      d: "M311.2,363.245V369.2l-7.652-4.251-1.7-5.952h-4.251c-4.283,1.224-5.1,10.2-5.1,10.2l-8.5,12.753s-2.418-5.275-4.251-8.5c-3.411-6.008-4.7-10.276-10.2-14.454-5.6-4.255-10.2-4.164-17-5.952-7.814-2.052-13.847,1.316-20.406-3.4l-.116-.083c-3.183-2.289-5.355-3.85-6.686-7.569-1.823-5.089-.247-8.978,2.551-13.6,2.311-3.823,4.72-5.275,8.5-7.652,5.6-3.522,9.6-4.2,16.154-5.1,5.263-.721,13.6,0,13.6,0a80.82,80.82,0,0,1-4.251,10.2c-1.758,3.131-1.809,5.365-5.1,6.8-2.435,1.063-4.146,0-6.8,0-2.988,0-6.2-2.612-7.652,0-.806,1.451-.469,2.658,0,4.251.794,2.7,2.559,3.886,5.1,5.1,3.3,1.576,5.851-1.041,9.353,0a25.77,25.77,0,0,1,5.1,2.551s6.955-4.059,11.9-5.1c5.2-1.1,8.99-2.636,13.6,0,2.445,1.4,4.21,2.428,5.1,5.1.629,1.889,0,5.1,0,5.1h5.1s1.156-6.2,4.251-7.652c3.426-1.609,6.707-.158,9.353,2.551,2.422,2.479,2.389,5.04,2.551,8.5C313.936,357.145,311.2,363.245,311.2,363.245Z",
      translate: { x: "156.931", y: "220.864" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "BOMADI-2",
      name: "BOMADI",
      fill: "#001755",
      translate: { x: "444.479", y: "613.814" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-12",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "429.866", y: "580.699" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "BURUTU",
    path: {
      id: "Path_7048",
      d: "M109.406,283.058C101.277,278.93,89,267.754,89,267.754s17.107-4.083,28.058-4.251c10.157-.156,15.775,2.19,25.507,5.1,15.086,4.511,27.207,9.353,38.26,15.3,9.475,5.1,18.705,7.652,34.859,9.353s21.764,2.631,35.71,5.952c0,0,16.79.624,26.357-3.4,7.9-3.323,16.154-13.6,16.154-13.6s6.836,8.21,8.5,13.6c1.289,4.173,1.345,6.7,1.7,11.053a122.636,122.636,0,0,1,0,12.753s9.114,3.719,11.9,0c2.551-3.4,7.315-6.426,12.753-9.353,9.929-5.343,16.906-5.141,28.058-6.8a142.125,142.125,0,0,1,19.555-1.7c3.983-.119,10.2,0,10.2,0s1.711,6.125,0,9.353c-1.556,2.933-6.8,5.1-6.8,5.1s-13.525-.954-22.106,0a74.163,74.163,0,0,0-17,3.4c-13.335,4.29-30.608,18.705-30.608,18.705s-2.16,6.407,0,9.353c2.945,4.016,12.753,0,12.753,0l17.855-9.353s13.02-5.312,17,0c2.988,3.984,3.4,13.6,3.4,13.6s-22.363-1.257-30.608,5.952c-3.75,3.279-6.077,5.477-7.652,10.2s-2.241,8.305,0,12.753c3.358,6.662,10.888,4.13,17.855,6.8,10.307,3.952,18.076,2.906,26.357,10.2,7.263,6.4,12.753,21.256,12.753,21.256a39.724,39.724,0,0,1-3.4,7.652c-3.664,5.538-13.6,10.2-13.6,10.2l-6.8-10.2h-15.3s-3.367,7.664-7.652,10.2c-5.714,3.386-17,0-17,0s-5.2,11.4-11.9,13.6c-4.1,1.345-6.9,1.173-11.053,0a31.927,31.927,0,0,1-6.8-3.4s-1.95,6.251-5.1,8.5c-4.593,3.28-9.038,1.588-14.454,0-5.744-1.683-8.232-4.579-12.753-8.5a94.994,94.994,0,0,1-9.353-10.2s-14.493,4.814-21.256,0c-4.965-3.535-7.652-13.6-7.652-13.6s-10.919,2.656-17,0c-8.356-3.647-12.753-19.555-12.753-19.555a29.116,29.116,0,0,0-6.8-2.551c-5.013-.816-7.7,2.073-12.753,2.551-4.629.437-7.718,2.025-11.9,0-4.726-2.287-5.328-6.346-7.652-11.053-5.474-11.078-3.4-31.458-3.4-31.458s-8.443-21.314-10.2-35.71c-1.371-11.206,0-28.908,0-28.908a39.346,39.346,0,0,0-4.251-5.952C123.273,284.8,116.718,286.772,109.406,283.058Z",
      translate: { x: "61.99", y: "184.569" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "BURUTU-2",
      name: "BURUTU",
      fill: "#001755",
      translate: { x: "282.315", y: "540.694" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-13",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "292.641", y: "567.096" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "UDU",
    path: {
      id: "Path_7049",
      d: "M248.756,246.956a10.631,10.631,0,0,0,9.352,0s4.843,12.811,10.2,19.555c3.214,4.044,7.73,4.448,9.353,9.353,1.578,4.77.087,8.057-1.7,12.753-1.867,4.906-3.306,8.108-7.652,11.053-5.016,3.4-9.363,3.741-15.3,2.551-6.775-1.359-8.421-6.836-14.454-10.2-2.593-1.447-6.8-3.4-6.8-3.4s1.415-6.479,0-10.2c-1.013-2.67-4.251-5.952-4.251-5.952s3.314-3.253,4.251-5.952c1.088-3.137,0-8.5,0-8.5s5.674,5.074,10.2,5.952a28.12,28.12,0,0,0,6.8,0s-3.921-5.287-6.8-8.5a41.915,41.915,0,0,1-5.952-6.8c-1.127-1.772-2.493-3.156-1.7-5.1.731-1.792,2.321-2.4,4.251-2.551C241.83,240.752,245.855,245.408,248.756,246.956Z",
      translate: { x: "159.004", y: "168.808" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "UDU-2",
      name: "UDU",
      fill: "#001755",
      translate: { x: "400.958", y: "448.063" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-14",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "400.958", y: "456.565" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OKPE",
    path: {
      id: "Path_7051",
      d: "M238.862,195.906c4.989-7.574,10.2-20.406,10.2-20.406s9.427,11.189,17.855,13.6c6.384,1.83,10.461,1.133,17,0,7.353-1.272,17.855-6.8,17.855-6.8s5.154.93,6.8,3.4c1.842,2.763,0,8.5,0,8.5s-7.212,2.3-11.053,5.1c-5.154,3.756-7.467,6.992-10.2,12.753-2.9,6.118-3.4,17-3.4,17l13.6,5.1s8.121,5.7,11.053,11.053c2.785,5.086,3.4,14.454,3.4,14.454s-14.631,7.361-24.657,10.2c-4.261,1.207-6.708,3.416-11.053,2.551-4.43-.883-5.975-3.8-9.353-6.8-3.69-3.278-3.867-7.657-8.5-9.353a12.56,12.56,0,0,0-9.353,0c-4.9,1.634-5.263,6.2-9.353,9.353-6.7,5.171-19.555,9.353-19.555,9.353L198.9,262.224a38.08,38.08,0,0,1,3.4-5.952,16.484,16.484,0,0,1,4.251-4.251c3.671-2.479,6.336.463,8.5-3.4,2.008-3.583-2.551-9.353-2.551-9.353l-17-13.6s16.729-6.514,24.657-11.053C230.55,208.661,232.274,205.908,238.862,195.906Z",
      translate: { x: "136.59", y: "122.932" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OKPE-2",
      name: "OKPE",
      fill: "#001755",
      translate: { x: "377.353", y: "344.822" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-15",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "379.951", y: "366.836" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ETHIOPE_EAST",
    path: {
      id: "Path_7052",
      dataName: "ETHIOPE EAST",
      d: "M259.812,190.9c4.411-2.666,12.753-3.4,12.753-3.4s5.037,11.677,9.353,9.353l11.053-5.952c4.316-2.325,17,0,17,0s9.514,9.279,14.454,16.154c6.759,9.407,11.616,14.945,13.6,26.357,1.139,6.542,2.811,10.988,0,17-2.9,6.2-7.5,7.958-13.6,11.053-5.248,2.66-9.235,1.536-14.454,4.251-4.418,2.3-5.4,6.321-10.2,7.652a16.588,16.588,0,0,1-9.353,0c-5.385-1.238-8.871-3.032-11.9-7.652-2.785-4.248-2.551-12.753-2.551-12.753s-.8-8.785-3.4-13.6c-2.568-4.756-5.124-6.838-9.353-10.2-5-3.977-14.454-7.652-14.454-7.652s-1.416-8.99,0-14.454C250.676,199.655,253.271,194.854,259.812,190.9Z",
      translate: { x: "173.455", y: "131.337" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2.5M-16",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "462.175", y: "366.44" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "ETHIOPE_EAST-2",
      name: "ETHIOPE EAST",
      fill: "#001755",
      translate: { x: "438.141", y: "351.993" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "SAPELE",
    path: {
      id: "Path_7053",
      d: "M173.3,147.452c5.166-.575,11.9-5.952,11.9-5.952s2.707,10.041,7.652,13.6c4.183,3.013,8.37,6.113,12.753,3.4,2.663-1.648,1.973-4.652,4.251-6.8,2.918-2.753,9.353-4.251,9.353-4.251s5,6.883,8.5,11.053c3.778,4.5,4.831,8.677,10.2,11.053,5.465,2.418,11.078-4.226,15.3,0s-3.316,10.332,0,15.3c4.972,7.46,17.984-7.46,22.956,0,3.316,4.972,1.291,9.468,0,15.3-1.932,8.728-11.9,19.555-11.9,19.555s-9.756,14.342-18.705,20.406c-8.014,5.43-22.956,9.353-22.956,9.353s-11.133-8.946-14.454-17c-1.564-3.8-2.637-6.1-2.551-10.2.08-3.785,2.551-9.353,2.551-9.353H188.608a34.575,34.575,0,0,0,0-15.3c-2.653-10.443-8.973-19.339-15.3-28.058C167.606,161.71,158,150,158,150l3.4-5.1S168.579,147.977,173.3,147.452Z",
      translate: { x: "110.322", y: "99.116" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "SAPELE-2",
      name: "SAPELE",
      fill: "#001755",
      translate: { x: "320.641", y: "302.232" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-17",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "329.563", y: "319.225" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ETHIOPE_WEST",
    path: {
      id: "Path_7054",
      dataName: "ETHIOPE WEST",
      d: "M175.051,159.715c-1.877-5.761-2.551-15.3-2.551-15.3a48.9,48.9,0,0,1,5.1-8.5c4.154-4.93,7.635-6.917,13.6-9.353,7.132-2.909,19.555-2.551,19.555-2.551s3.193-8.235,7.652-8.5c3.413-.2,7.652,4.251,7.652,4.251l40.811,22.106s15.189,1.248,21.256,7.652c4.5,4.748,6.8,15.3,6.8,15.3l38.26,22.956s1.114,6.883,0,11.053c-1.765,6.613-8.366,7.309-11.053,13.6-1.867,4.372-2.551,11.9-2.551,11.9s-11.939,6.785-20.406,7.652c-7.661.786-17.976,1.248-22.956-3.4s-4.372-6.4-9.353-11.053c0,0,3.248-8.977,0-12.753-2.132-2.479-4.4-3.086-7.652-3.4-4.18-.4-6.045,3.994-10.2,3.4l-.077-.01c-2.908-.415-5.385-.77-6.725-3.391-1.578-3.086,3.6-6.9,2.551-10.2-.855-2.685-2.537-2.234-5.1-3.4-3.023-1.374-8.5,0-8.5,0h-5.1c-5.084-1.3-4.329-6.989-7.652-11.053-4.161-5.089-10.2-11.9-10.2-11.9s-6.618,5.469-10.2,8.5c-2.535,2.144-6.8,5.1-6.8,5.1s-7.73-3.588-11.053-7.652C177.144,167.088,176.523,164.235,175.051,159.715Z",
      translate: { x: "120.479", y: "80.901" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ETHIOPE_WEST-2",
      name: "ETHIOPE WEST",
      fill: "#001755",
      translate: { x: "363.641", y: "207.368" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-18",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "378.853", y: "261.012" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "WARRI_NORTH",
    path: {
      id: "Path_7055",
      dataName: "WARRI NORTH",
      d: "M.5,172.677,88.074,71.5s13.528,1.695,18.705,7.652c5.766,6.636,4.251,22.106,4.251,22.106s28.269,16.971,43.362,31.459c5.592,5.368,12.106,6.849,13.6,14.454,1.045,5.3-1.108,8.4-2.551,13.6-1.712,6.179-6.078,8.893-5.952,15.3.12,6.1,1.315,10.483,5.952,14.454,4.9,4.193,9.733,2.819,16.154,3.4,4.96.449,7.987,1.445,12.753,0,5.023-1.524,8.04-3.353,11.053-7.652l2.551-5.1s-6.455-1.814-8.5-5.1c-2.831-4.544-1.935-9.674,1.7-13.6,4.083-4.414,9.342-2.481,15.3-1.7,7.22.944,17,7.652,17,7.652s0-5.1,4.251-11.9,21.654-.894,34.009,4.251c9.693,4.037,22.106,15.3,22.106,15.3l1.7,14.454s-6.4,4.4-11.053,5.952c-5.705,1.9-11.9-5.952-15.3,1.7s25.555,23.857,28.908,44.212c1.348,8.191,0,21.256,0,21.256s-27.432-14.945-46.763-17.855c-13.463-2.025-24.657,0-34.859,0-10.1,0-18.705,17.855-18.705,17.855s2.226,9.832,1.7,16.154c-.454,5.457.187,9.466-3.4,13.6-3.921,4.523-8.479,5.453-14.454,5.1-7.448-.439-16.154-10.2-16.154-10.2l-2.551,18.705-68.869,7.652-11.9-16.154V279.807L64.267,265.353Z",
      translate: { x: "0", y: "50.084" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "WARRI_NORTH-2",
      name: "WARRI NORTH",
      fill: "#001755",
      translate: { x: "69.59", y: "259.51" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-19",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "110.998", y: "291.422" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "WARRI central",
    path: {
      id: "Path_7056",
      dataName: "WARRI SOUTH-WEST",
      d: "M215.194,175.518c9.866,1.561,24.657,6.8,24.657,6.8s-.051,9.784-2.551,15.3a47.259,47.259,0,0,1-4.251,6.8s10.786,15.875,10.2,27.207c-.281,5.469.6,9.864-3.4,13.6-3.069,2.867-10.2,3.4-10.2,3.4s-1.092,8.916,0,14.454c2.646,13.432,12.124,17.838,22.106,27.207,17.777,16.688,31.459,22.106,54.415,30.608,4.459,1.651,7.167,2.964,11.9,2.551,5.038-.439,11.9-5.1,11.9-5.1s4.559-7.717,10.2-3.4,4.559,1.636,10.2,5.952,4.841,12.9,2.551,17c-3.476,6.224-9.239,6.771-16.154,8.5-7.086,1.774-18.705,0-18.705,0s-8.921,9.249-16.154,12.753c-14.641,7.094-41.661,0-41.661,0s-30.042-3.037-48.463-8.5c-17.974-5.331-26.044-14.082-44.212-18.705-9.47-2.41-14.889-4.01-24.657-4.251-11.407-.282-28.908,4.251-28.908,4.251S99.791,321.8,94.461,312.405c-6.816-12.01-5.952-34.859-5.952-34.859s-6.3-5.613-10.2-9.353C68.468,258.766,54.5,242.686,54.5,242.686l70.569-6.8,3.4-17.855s9.883,11.17,17.855,10.2c4.867-.59,8.069-2.061,11.053-5.952,3.29-4.289,3.7-10.777,4.251-16.154.677-6.606-3.809-12.415,0-17.855,5.952-8.5,5.952-8.5,17.853-12.753h0C191.387,171.267,201.42,173.338,215.194,175.518Z",
      translate: { x: "37.824", y: "121.213" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "WARRI_SOUTH-WEST-2",
      name: "WARRI SOUTH-WEST",
      fill: "#001755",
      translate: { x: "144.338", y: "388.15" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-20",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "192.304", y: "418.744" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "WARRI_SOUTH",
    path: {
      id: "Path_7057",
      dataName: "WARRI SOUTH",
      d: "M188.108,186.3h20.406s-4.712,13.979,0,20.406c9.353,12.753,24.657,25.507,24.657,25.507s11.917,6.025,10.2,11.9c-1.539,5.277-9.138,1.813-12.753,5.952-2.84,3.251-4.251,10.2-4.251,10.2s11.915,7.261,19.555,11.9c10.949,6.654,20.832,6.423,28.058,17,4.559,6.678,9.841,12.061,6.8,19.555-1.073,2.648-4.251,5.952-4.251,5.952l-12.753-5.952a58.171,58.171,0,0,1-6.8,5.952c-3.931,2.671-11.053,5.1-11.053,5.1s-11.017-2.687-17.855-5.1c-16.668-5.885-25.655-10.874-39.961-21.256C174.8,283.774,157.5,264.523,157.5,264.523V244.118s6.841-2.535,9.353-3.4c3.78-1.3,5.1-4.5,5.1-8.5,0-9.353-.911-8.519-3.4-16.154-2.211-6.778-8.5-16.154-8.5-16.154s5.1-5.169,6.8-9.353c1.646-4.045,1.7-11.053,1.7-11.053Z",
      translate: { x: "109.972", y: "125.733" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "WARRI_SOUTH-2",
      name: "WARRI SOUTH",
      fill: "#001755",
      translate: { x: "288.815", y: "411.744" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-21",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "306.641", y: "392.896" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "UGHELI_NORTH",
    path: {
      id: "Path_7058",
      dataName: "UGHELI NORTH",
      d: "M249,262.671l25.507-10.2s1.085,9.048,2.551,12.753c1.908,4.822,3.042,3.574,7.652,5.952,5.154,2.658,8.931,4.316,14.454,2.551,4.919-1.573,9.353-8.5,9.353-8.5s6.224-1.536,10.2-2.551c6.321-1.612,7.389-1.243,11.9-5.952,3.852-4.018,8.118-6.351,8.5-11.9.495-7.15-1.751-11.138-4.251-17.855-3.636-9.766-7.186-14.746-13.6-22.956a162.617,162.617,0,0,0-12.753-13.6l19.555-3.4s10.907,7.3,18.705,10.2c12.544,4.666,34.009,4.251,34.009,4.251s2.228,7.213,3.4,11.9c2.214,8.844,4.251,22.956,4.251,22.956l8.5,11.9s-10.936,6.5-18.705,8.5c-7.422,1.916-14.209-3.794-19.555,1.7-3.01,3.093-3.79,5.911-4.251,10.2-.507,4.727,4.748,7.688,2.551,11.9-1.66,3.185-4.714,3.035-7.652,5.1-6.576,4.624-12.087,5.821-16.154,12.753-4.566,7.78-4.588,14.168-2.551,22.956,2.163,9.33,13.6,20.406,13.6,20.406s1.711,5.139,2.551,8.5c2.908,11.636-1.741,19.407,2.551,30.608,4.732,12.347,11.238,17.726,21.256,26.357,3.773,3.251,10.2,7.652,10.2,7.652l-22.106,6.8-25.507,5.1s-13.02-9.4-17-18.705c-2.794-6.525-3.4-13.6-1.7-22.106,1.668-8.337.85-11.053,1.7-19.555.786-7.853-5.379-13.587-9.353-20.406a99.373,99.373,0,0,0-9.353-12.753s3.579-7.674,5.952-13.6c1.7-4.251,1.551-6.343,0-9.353-2.741-5.321-7.315-6-12.753-8.5-3.816-1.757-5.234-1.673-9.353-.85-2.626.525-4.19.264-6.8.85-1.944.435-3.443,1.105-5.1,0-2.486-1.658-1.024-4.845,0-7.652,1.049-2.875,3.639-4.652,4.251-7.652.631-3.086-1.7-5.952-1.7-5.952L250.7,276.274Z",
      translate: { x: "174.064", y: "130.986" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "UGHELI_NORTH-2",
      name: "UGHELI NORTH",
      fill: "#001755",
      translate: { x: "453.673", y: "417.455" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-22",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "477.479", y: "425.957" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IKA_SOUTH",
    path: {
      id: "Path_7059",
      dataName: "IKA SOUTH",
      d: "M363.356,69.61c6.807-.724,13.6-11.053,13.6-11.053s9.475,3.791,13.6,8.5c2.552,2.912,4.715,4.65,5.1,8.5.347,3.449-1.308,5.266-2.551,8.5-1.6,4.158-1.748,7.269-5.1,10.2-2.234,1.956-4.022,4.444-6.8,3.4-2.949-1.106-.578-5.2-2.551-7.652-2.141-2.665-4.317-3.5-7.652-4.251a13.458,13.458,0,0,0-6.8,0,14.148,14.148,0,0,0-7.652,4.251c-3.415,3.308-2.377,7.156-5.1,11.053-2.69,3.849-4.833,5.573-8.5,8.5-2.807,2.24-7.525,1.512-7.652,5.1-.063,1.787-.012,3.737,1.7,4.251,8.5,2.55,14.406-2.239,19.555,3.4a36.947,36.947,0,0,1,3.4,4.251s4.064,14.254,11.053,19.555c3.789,2.874,6.3,4.9,11.053,5.1,3.782.158,9.353-2.551,9.353-2.551s10.73-7.618,18.705-8.5c4.632-.513,11.9.85,11.9.85s-5.979,10.022-8.5,17c-2.435,6.742-4.409,10.689-4.251,17.855a56.77,56.77,0,0,0,1.7,10.2H384.612s-13.264-7.292-21.256-12.753c-11.689-7.987-19.46-11.708-28.058-22.956-8.21-10.742-10.721-18.463-14.454-31.458-2.7-9.391-4.251-24.657-4.251-24.657s-8.8-7.141-11.053-13.6c-2.188-6.27-1.246-10.482,0-17,1.2-6.3,1.976-10.273,5.952-15.3,4-5.058,7.161-9.145,13.6-9.353,5.055-.163,11.9,5.1,11.9,5.1s14.838,7.444,19.555,16.154c1.85,3.418-.333,8.279,3.4,9.353A7.69,7.69,0,0,0,363.356,69.61Z",
      translate: { x: "212.75", y: "27.317" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "IKA_SOUTH-2",
      name: "IKA SOUTH",
      fill: "#001755",
      translate: { x: "531.894", y: "104.569" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-23",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "542.097", y: "113.072" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "UKWUANI",
    path: {
      id: "Path_7060",
      d: "M362.93,164.707,382.486,137.5s10.31,1.354,16.154,4.251c9.254,4.59,12.607,10.366,18.705,18.705,5.35,7.315,10.121,12.038,13.6,20.406,2.945,7.077,5.521,11.339,3.4,18.705-1.566,5.433-4.862,7.618-9.353,11.053-7.681,5.873-14.2,5.717-23.806,6.8-10.25,1.158-16.093-.687-26.357-1.7-5.657-.558-14.454-1.7-14.454-1.7s-1,5.19-1.7,8.5c-.634,2.995-.2,4.984-1.7,7.652-2.348,4.173-10.2,6.8-10.2,6.8s-39.111,0-50.164-14.454c-3.466-4.53,27.207-18.705,27.207-18.705s8.4-15.206,16.154-22.956S362.93,164.707,362.93,164.707Z",
      translate: { x: "207.224", y: "96.314" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "UKWUANI-2",
      name: "UKWUANI",
      fill: "#001755",
      translate: { x: "562.875", y: "287.553" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-24",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "575.875", y: "302.926" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "UVWIE",
    path: {
      id: "Path_7050",
      d: "M264.765,240.562c4.5,1.53,11.9-2.551,11.9-2.551s3.316,10.786,0,16.154c-1.855,3-3.506,4.688-6.8,5.952-3.411,1.306-5.8.842-9.353,0-4.489-1.065-6.035-7.928-10.2-5.952,0,0-2.688,1.663-3.4,3.4-1.612,3.933,4.1,5.215,6.8,8.5,1.644,2,2.852,2.918,4.251,5.1a41.878,41.878,0,0,1,2.551,5.1,24.552,24.552,0,0,1-6.8,0c-4.86-1.09-10.2-7.652-10.2-7.652s-5.135-9.759-10.2-14.454c-7.519-6.967-23.806-11.053-23.806-11.053s14.519-4.562,21.256-11.053c3.063-2.952,3.042-6.518,6.8-8.5,4.4-2.326,9.766-1.765,12.753,2.551s4.664,4.185,7.652,8.5C257.963,234.611,261.424,239.425,264.765,240.562Z",
      translate: { x: "146.396", y: "155.646" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2.5M-25",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "378.853", y: "403.851" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "UVWIE-2",
      name: "UVWIE",
      fill: "#001755",
      translate: { x: "375.451", y: "395.349" },
      tspan: { X: "0", y: "0" },
    },
  },
];
