import React, { useState } from "react";
import "./App.css";
import { useEffect, Suspense } from "react";
import { nigeria } from "./helperFunctions/mapdata";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Dashboard from "./screens/Dashboard";
import Layout from "./component/Layout";
import "./index.css";
import LGA from "./screens/LGA";
import Test from "./screens/Test";
import toast, { Toaster } from "react-hot-toast";
import Login from "./screens/Login";
import { color } from "./constant/color";
import { getToken } from "./utils/token";
import { BounceLoader } from "react-spinners";
import { css } from "@emotion/react";

import { Provider } from "react-redux";
import NotFoundImg from "./assets/images/notfound.png";
import { router } from "./route/routes";
import { store } from "./redux/store";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Toaster position="top-center" />
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path={"/login"} component={Login} />
            <ProtectedRoute />
          </Switch>
        </Suspense>
      </Router>
    </Provider>
  );
}

export default App;

const Loader = () => {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <BounceLoader
        color={color.baseColor}
        loading={true}
        css={override}
        size={25}
      />
    </div>
  );
};
const ProtectedRoute = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let token = await getToken("epump_agr_token");
        if (!token) {
          setLoading(false);
          return (window.location.pathname = "/login");
        }
        setLoading(false);
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
      }
    })();
  }, []);
  return loading ? (
    <Loader />
  ) : (
    router.map((item) => (
      <Route
        key={item?.id}
        exact
        path={item?.path}
        component={item?.component}
      />
    ))
  );
};

const NotFound = () => {
  return (
    <div
      className=""
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "320px",
          height: "300px",
        }}
        className={"mt-5"}
      >
        <img
          alt={"not found image"}
          style={{
            width: "100%",
            height: "100%",
          }}
          src={NotFoundImg}
        />
      </div>

      <div
        className="text-center"
        style={{
          color: color.baseColor,
          fontSize: "20px",
          fontWeight: "600",
        }}
      >
        404 | Page not found
      </div>
      <Link
        to={"/"}
        style={{
          color: color.secondaryColor,
          fontSize: 18,
          fontWeight: "bold",
          textDecoration: "underline",
        }}
      >
        Go back home
      </Link>
    </div>
  );
};
