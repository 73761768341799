export const data = [
  {
    state: "TAFA",
    path: {
      id: "Path_6926",
      d: "M265.53,107H224.913l-13.54,21.489v47.277l22.566,17.191h13.54V175.766l18.051-12.894,13.54-12.894-13.54-8.6,13.54-21.489L292.61,107H265.53Z",
      translate: { x: "924.391", y: "897.184" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "TAFA-2",
      name: "TAFA",
      fill: "#001755",
      translate: { x: "1217", y: "1037.163" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M",
      name: "2M",
      fill: "#001755",
      translate: { x: "1158.382", y: "1037.163" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "GURARA",
    path: {
      id: "Path_6927",
      d: "M191.514,193.563l-2.562,21.9-5-14.149c1.179-2.493,2.586-9.589-1.217-18.024l-4.755-10.542,8.049-.543,4.755,10.542,9.328-11.494-.729-10.813-5-14.149,12.075-.815,18.109-1.224c3.218-.217,6.22-7.661,7.317-11.357l6.038-.409,4.023-.272,7.32-11.357,9.087-15.1,5.549-7.617,10.06-.68,9.816-4.284L285,111.2l1.217,18.023L291.524,118l8.049-.543.732,10.813-7.32,11.36,8.293,3.06.732,10.813-6.038.409L292.679,165l-7.32,11.357a66.728,66.728,0,0,0-1.461-21.627c-2.194-8.541-4.449,3.923-5.305,11.223l.732,10.813-9.331,11.494.488,7.21L265.417,210.3l-10.06.681c-3.008-4.628-11.133-15.184-19.571-20.406s-8.885-1.812-8.049.543l-9.576,7.891-12.56-6.4Z",
      translate: { x: "842.971", y: "863.485" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "GURARA-2",
      name: "GURARA",
      fill: "#001755",
      translate: { x: "1038.029", y: "1077.012" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M",
      name: "3M",
      fill: "#001755",
      translate: { x: "1076.029", y: "1045.441" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "LAPAI",
    path: {
      id: "Path_6928",
      d: "M160.789,281.466,157,269.253h24.628l7.578-5.233c1.265-1.162,3.789-4.184,3.789-6.976,0-3.49-3.789-3.49-5.683-10.466-1.031-3.8-1.537-5.123-1.754-5.395l-.141.162c0-.186.038-.289.141-.162L193,232.621l3.789-8.723V180.287c-2.524-1.162-7.578-4.535-7.578-8.72s-2.524-8.723-3.789-10.466v-5.237L181.629,145.4h7.578l11.367-1.743h5.687l5.684,1.743h5.684l3.789-5.233c0-4.071.756-12.907,3.789-15.7s1.262-3.49,0-3.49l3.789-3.486h11.367l3.789-3.49,5.684,3.49v6.976l-5.684,3.49-3.789-3.49-7.578-3.49-3.789,3.49,3.789,3.49,3.789,12.209V290.189l3.789,8.72h9.473v12.213l-5.684,8.723-7.578,6.976c-3.033,2.792-3.789,1.162-3.789,0v8.723h-3.789l-7.578,5.233v6.976l-3.789,3.49c-1.895-2.909-5.684-9.07-5.684-10.466s-5.054-7.561-7.578-10.466c-3.16-3.49-10.611-10.466-15.159-10.466-5.684,0-3.789-5.233-11.367-12.213-6.062-5.58-7.578-2.324-7.578,0l-3.789-13.956Z",
      translate: { x: "791.813", y: "914.252" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "LAPAI-2",
      name: "LAPAI",
      fill: "#001755",
      translate: { x: "1047.469", y: "1152.592" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "988.264", y: "1195.694" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "PAIKORO",
    path: {
      id: "Path_6929",
      d: "M242.581,138.917l-5.157-5.157-3.438,5.157h-8.6l-5.157-6.877-10.315-5.157-5.157-6.877-3.438,6.877,3.438,6.877-15.472,5.157H175.534l-6.877,3.438-5.157,6.877v6.877l3.438,6.877v12.034L163.5,185.334l3.438,8.6,8.6,3.438H204.76l5.157-12.034V175.019l6.877-5.157h8.6c1.145.574,3.782.688,5.157-3.438s2.864-1.719,3.438,0h15.472c3.438-.574,11-2.063,13.753-3.438s3.438-2.864,3.438-3.438c2.293.574,6.877,1.375,6.877,0s6.877-2.864,10.315-3.438L289,149.232l3.438-3.438c2.864.574,8.94,1.375,10.315,0s4.013-1.719,5.157-1.719l6.877-5.157L325.1,133.76l3.438-6.877,5.157-6.877-5.157-3.438v-5.157l-3.438-5.157-5.157-6.877-5.157-3.438L307.909,92.5h-6.877l-3.438,3.438v6.877l10.315,3.438v13.753H292.436l-8.6-8.6-5.157,8.6v6.877l-5.157,5.157a17.481,17.481,0,0,1-6.877,1.719c-2.751,0-5.732,3.438-6.877,5.157-.574,1.719-3.438,4.126-10.315,0S242.007,137.2,242.581,138.917Z",
      translate: { x: "807.662", y: "861.829" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "PAIKORO-2",
      name: "PAIKORO",
      fill: "#001755",
      translate: { x: "993.511", y: "1017.936" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-2",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "996.949", y: "1031.688" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "AGAIE",
    path: {
      id: "Path_6930",
      d: "M196.987,125.963V119h-11.5l-11.5,6.963-5.749,6.963-13.409,5.223v8.7L151,157.3v12.182l3.834,12.185v24.371L164.413,213l-3.834,6.963-5.745,5.219L151,228.661l9.579,3.48,7.664,3.483v5.223l5.749,8.7,5.749,5.223,5.749,3.479h15.328a86.383,86.383,0,0,1,11.5-8.7c4.6-2.785,1.915-6.963,0-8.7l-5.749-8.706,5.749-6.963c1.915-1.159,5.749-4.525,5.749-8.7,0-5.223,3.83-5.223,0-17.4V169.478c-1.919-6.963-5.749-6.963-5.749-12.182,0-4.178-3.834-6.385-5.749-6.963,1.279-1.162,3.067-4.528,0-8.706s-1.279-7.54,0-8.7C203.372,132.344,196.987,130.14,196.987,125.963Z",
      translate: { x: "777.183", y: "926.443" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "AGAIE-2",
      name: "AGAIE",
      fill: "#001755",
      translate: { x: "952.251", y: "1121.084" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-2",
      name: "3M",
      fill: "#001755",
      translate: { x: "962.566", y: "1134.837" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "KATCHA",
    path: {
      id: "Path_6931",
      d: "M171.726,114.877,163.13,108l5.157,12.034-5.157,8.6v5.157l-5.157,5.157-8.6,6.877,3.438,10.315,5.157,3.438h5.157v8.6l-5.157,8.6-5.157,5.157-5.157,6.877-1.719,1.719v10.315l-3.438,8.6,3.438,12.034,6.877,12.034v6.877l5.157,5.157,10.315,6.877H178.6c2.293-1.719,6.877-6.533,6.877-12.034L178.6,233.5V221.464c-1.145-5.157-3.438-16.5-3.438-20.63V185.362a13.127,13.127,0,0,1,3.438-8.6v-8.6l6.877-5.157,5.157-3.438,6.877-6.877,6.877-5.157h13.753V125.191l-3.438-5.157h-3.438l-6.877,5.157-6.877,3.438H178.6Z",
      translate: { x: "756.457", y: "899.622" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "KATCHA-2",
      name: "KATCHA",
      fill: "#001755",
      translate: { x: "916.864", y: "1128.135" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-2",
      name: "2M",
      fill: "#001755",
      translate: { x: "929.179", y: "1114.4" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "SHIRORO",
    path: {
      id: "Path_6932",
      d: "M214.9,60.411l-6.591,3.438L211.6,77.6V93.074l-9.885,10.315L191.834,113.7,180.3,122.3h-4.941v6.877l4.941,5.157,4.944,6.877h9.885l6.588,5.157,4.944,6.877,4.941,8.6v7.508c-1.1,2.082-3.624,6.245-4.941,6.245h-4.944l-3.294,10.315-3.294,5.157v6.877l-9.885-3.438-9.885-3.438-11.536-1.719-4.941-3.438L149,189.347l3.294,5.157v6.877L149,211.7l3.294,5.157h32.953l6.588-5.157,9.885-3.438L211.6,211.7H218.2l3.294,8.6v6.877l9.885,3.438,8.238,12.034v6.877l6.591,3.438,3.294,6.877,13.179,3.438,4.944-6.877V230.606l4.944-3.438-4.944-6.877V182.47l4.944-12.034,3.294-8.6,3.294-8.6-3.294-6.877v-8.6l6.588-3.438,8.238-5.157V122.3h11.532v-8.6l4.944-6.877a90.682,90.682,0,0,0-9.885-6.877,59.53,59.53,0,0,0-9.885-3.438h-4.944V82.76l4.944-5.157a8.555,8.555,0,0,0,0-6.877c-1.317-2.751-8.238-5.731-11.532-6.877V60.411l-8.238,3.438V56.972c-1.1-2.292-3.624-6.877-4.944-6.877s-3.844-5.731-4.941-8.6h-9.885l-4.944,8.6-6.588,3.438H223.137Z",
      translate: { x: "772.307", y: "737.476" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "SHIRORO-2",
      name: "SHIRORO",
      fill: "#001755",
      translate: { x: "990.072", y: "866.65" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-3",
      name: "3M",
      fill: "#001755",
      translate: { x: "1007.264", y: "883.403" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "RAFI",
    path: {
      id: "Path_6933",
      d: "M170.028,77.3l-16.012-5.383-4,5.383-6,10.766V97.04l6,7.177-6,5.383v8.972L140,134.72v21.531l-4,12.56-4,7.177v10.766l4,10.766,8.008,8.971h26.018l14.008-10.766,2-5.383,8.008-3.589h8l12.01,5.383h8l10.005,3.589,14.011,1.794v-5.383l6-5.383c1.334-1.794,4.006-6.459,4.006-10.766s2.668-1.794,4,0c2-1.2,6-4.306,6-7.177v-5.383l-6-7.177-4-8.971L246.079,140.1H236.074c-3.2,0-8.008-5.981-10.009-8.971l-4-5.383V122.16l8-3.589,6.007-3.589c2.668-.6,8.4-2.512,10.005-5.383,2-3.589,6-3.589,6-5.383s8.008-5.383,8.008-7.177,6-5.383,6-8.971V77.3c-.667-4.186-2.8-13.278-6-16.148s-1.334-3.589,0-3.589c-1.334-3.589-4.8-11.483-8.008-14.354s-9.338,1.2-12.007,3.589l-10.009,3.589-8,7.177-8,7.177-12.01,7.177L190.042,82.686l-6.007,8.972L174.03,88.069Z",
      translate: { x: "730.855", y: "739.243" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "RAFI-2",
      name: "RAFI",
      fill: "#001755",
      translate: { x: "904.115", y: "883.841" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-3",
      name: "2M",
      fill: "#001755",
      translate: { x: "910.991", y: "901.033" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "WUSHISHI",
    path: {
      id: "Path_6934",
      d: "M188.143,89.1V80.5L184.7,89.1l-6.877,3.438-3.438,6.877-6.877,6.877H148.6v6.877l-12.034,10.315-8.6,6.877H112.5v8.6h8.6l6.877,6.877,8.6,6.877,5.157,6.877v8.6l10.315,8.6h12.034l3.438-8.6,3.438,3.438,6.877,5.157V161.3h20.63l6.877,3.438,15.472-8.6,3.438,3.438,6.877,5.157,3.438,3.438H238l-3.438-8.6v-3.438l-3.438-3.438V138.951L238,133.794v-20.63c1.145-1.146,3.438-4.126,3.438-6.877V99.411l-6.877-6.877-3.438,6.877-6.877,6.877-3.438,3.438H196.738l-5.157-3.438-3.438-6.877Z",
      translate: { x: "683.309", y: "832.569" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "WUSHISHI-2",
      name: "WUSHISHI",
      fill: "#001755",
      translate: { x: "838.787", y: "965.453" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-4",
      name: "2M",
      fill: "#001755",
      translate: { x: "862.855", y: "982.646" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "GBAKO",
    path: {
      id: "Path_6935",
      d: "M141.218,227.357l-7.609,3.717c0,2.974-5.072-6.192-7.609-11.15V201.343c2.537-4.336,7.609-15.61,7.609-26.014v-11.15l3.8-14.864v-7.434l3.806-5.577V123.3l3.8-7.43v-9.294h22.823l7.609,5.577,7.609-5.577L190.671,101l7.605,5.573,3.806,5.577,5.7,3.717v7.43l7.609,9.294-7.609,9.29v7.434l-9.51,9.29-3.8,5.573v5.574l3.8,5.577h3.806v3.717h5.7v9.29l-5.7,3.717v5.573l-7.609,3.717H183.062l-3.806,7.434-3.8-7.434-3.8-3.717h-7.609l-9.51,3.717-3.806,7.434v5.573l-5.7,5.573v3.717Z",
      translate: { x: "716.226", y: "882.554" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "GBAKO-2",
      name: "GBAKO",
      fill: "#001755",
      translate: { x: "866.924", y: "1038.441" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.5M",
      name: "3.5M",
      fill: "#001755",
      translate: { x: "873.17", y: "1054.943" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "EDATI",
    path: {
      id: "Path_6936",
      d: "M110.289,143.411,99.5,153.726h6.474l4.315,5.157,6.474,3.438h6.474l8.634,10.315H144.82l6.474,3.438,10.789,5.157,8.634,3.438,19.423-3.438,4.315-5.157h6.474V164.04l-6.474-5.157H179.348V146.849l6.474-3.438-6.474-5.157H166.4L162.084,124.5H151.295l-6.474,8.6-8.634,5.157H125.4l-4.315,5.157Z",
      translate: { x: "651.61", y: "939.854" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "EDATI-2",
      name: "EDATI",
      fill: "#001755",
      translate: { x: "783.774", y: "1092.672" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-5",
      name: "2M",
      fill: "#001755",
      translate: { x: "800.966", y: "1109.863" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "BORGU",
    path: {
      id: "Path_6937",
      d: "M0,191.072l12,8.6,10.282,3.438,3.427,15.472,6.855,6.877,5.141,5.157,5.141-5.157H49.7l6.855,5.157v8.6h13.71v15.472h6.855l6.855,6.877h20.564l6.855,8.6,12,8.6L133.668,292.5l12,12.034,10.282,13.753,8.568,12.034,8.569,8.6,13.71,10.315,12,10.315h6.855l-6.855-10.315-5.141-10.315-6.855-15.472,6.855-10.315,5.141-8.6,6.855-8.6,6.855-10.315-6.855-6.877v-8.6l-6.855-15.472-5.141-15.472v-8.6l-6.855-12.034v-36.1l-10.282-5.157-3.427-13.753-3.427-8.6,6.855-13.753V130.9l-6.855-5.157H145.664V106.834L150.8,94.8l-5.141-10.315V58.7L137.1,53.54V41.506h-8.568V36.349H89.112L70.261,32.911H63.407l-6.855-6.877L49.7,20.877H42.842L37.7,14h-12V32.911l6.855,3.438v41.26L37.7,89.643,42.842,94.8,49.7,106.834v12.034l-6.855,6.877v15.472L37.7,154.97l-10.282-5.157H12L5.141,160.128v10.315L0,177.319Z",
      translate: { x: "409", y: "670.422" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "BORGU-2",
      name: "BORGU",
      fill: "#001755",
      translate: { x: "477.766", y: "827.921" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M",
      name: "5M",
      fill: "#001755",
      translate: { x: "484.643", y: "848.551" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "AGWARA",
    path: {
      id: "Path_6938",
      d: "M60.215,34.815H41.365V53.726L44.792,65.76v8.6L41.365,84.67v8.6l3.427,6.877H65.357l6.855,6.877h5.141v-6.877l3.427,3.438h3.427l3.427-3.438L89.348,96.7h3.428l5.141-3.438,3.427-3.438h3.428V84.67h6.855V69.2c0-6.877,3.427-5.157,5.141-3.438V48.568h3.428V34.815l-8.569-3.438V26.219L109.913,24.5h-5.141l-3.428,6.877H77.353l-5.141,3.438Z",
      translate: { x: "509.86", y: "696.024" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "AGWARA-2",
      name: "AGWARA",
      fill: "#001755",
      translate: { x: "560.285", y: "748.84" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-6",
      name: "2M",
      fill: "#001755",
      translate: { x: "575.785", y: "772.784" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "MAGAMA",
    path: {
      id: "Path_6939",
      d: "M72.313,173.866h-15.8L53,183.024l8.779,7.326V210.5l5.267,7.326,5.267,9.158,12.29-9.158,8.779-7.326,10.534-9.158H114.45L128.5,210.5V201.34l-3.511-10.99,3.511-12.821V164.708l5.267-7.326h15.8l14.046-3.663,5.267-7.327,8.779-3.663,7.023-5.495v-5.495l5.267-7.327h22.824l5.267-7.326-5.267-14.653V76.791l5.267-10.99V31l-5.267,9.158h-8.779l-14.046,5.495-17.557-5.495h-8.779l-8.779,5.495-8.779,7.326-12.29,3.663h-8.779L114.45,52.979h-7.023L103.916,65.8l3.512,3.663V93.275l7.023,9.158v29.306l-7.023,5.495H86.359v12.821l-8.779,3.663,7.023,7.326v16.485Z",
      translate: { x: "538.229", y: "711.874" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "MAGAMA-2",
      name: "MAGAMA",
      fill: "#001755",
      translate: { x: "659.996", y: "803.853" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.5M-2",
      name: "3.5M",
      fill: "#001755",
      translate: { x: "677.188", y: "822.044" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "RIJAU",
    path: {
      id: "Path_6940",
      d: "M142.842,110.525v8.6l23.992,3.438,13.71-3.438H187.4l3.427-5.157,1.715-8.6V83.019l3.428-8.6,6.853-5.157,1.716-8.6V53.794l3.425-3.438-3.425-8.6V34.883h-5.144l-6.853-5.157V17.692L187.4,9.1V.5h-6.855l-8.568,1.719-3.428,3.438L161.693,9.1,154.838.5h-12V19.411L137.7,24.568l-5.141,5.157-3.428-10.315-3.427-1.719c-2.856-.573-8.569-3.782-8.569-12.034V.5H98.286L91.431,5.657,82.863,9.1H76.008V19.411l-5.141,5.157H62.3V34.883l8.569,6.877,12,5.157h12l8.568,6.877h13.71l12,6.877,5.141,5.157L137.7,77.862l-3.427,13.753-5.141,3.438,3.428,10.315H137.7Z",
      translate: { x: "560.901", y: "637.505" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "RIJAU-2",
      name: "RIJAU",
      fill: "#001755",
      translate: { x: "701.255", y: "684.513" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-4",
      name: "3M",
      fill: "#001755",
      translate: { x: "711.57", y: "700.704" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "KONTAGORA",
    path: {
      id: "Path_6941",
      d: "M140.405,39.751H124.981l-1.712,9.377-3.428,13.128-1.714,9.377,1.714,18.755,3.428,13.128-8.569,5.626H95.849l-5.141,11.253,10.282,9.377,13.71,3.751h10.281l15.424,5.626H188.39l10.281-5.626-5.14-13.128V101.64l-5.14-15-5.144-18.755-8.568-13.128-3.428-9.377L164.4,36l-8.568,3.751Z",
      translate: { x: "630.173", y: "724.065" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "KONTAGORA-2",
      name: "KONTAGORA",
      fill: "#001755",
      translate: { x: "758.07", y: "784.06" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-7",
      name: "2M",
      fill: "#001755",
      translate: { x: "766.471", y: "819.911" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "MARIGA",
    path: {
      id: "Path_6942",
      d: "M117.314,50.821l-3.428-12.034-6.856,3.438-6.853,8.6v41.26l3.428,10.315h30.845l6.856-3.438,8.568,12.034,6.853,12.034,5.144,10.315,3.425,12.034,5.144,13.753v13.753l5.14,15.472,6.856,5.157,6.853,5.157,8.568,5.157,8.568,3.438,13.712,6.877,8.568,8.6V207.264c1.142-2.865,3.428-9.627,3.428-13.753V183.2l5.14-5.157v-5.157L232.133,166v-6.877l5.14-13.753h6.853v-5.157c-.571-2.865-1.368-9.283,0-12.034s5.144-1.146,6.856,0V102.4h-6.856V80.047l6.856-6.877V69.732h6.856l-3.428-8.6v-8.6c0-2.292-.684-6.877-3.428-6.877s-5.711-4.584-6.856-6.877l-6.853-5.157-5.14-6.877V21.6L228.705,13l-8.568,3.438-6.856,6.877-6.856,3.438-5.14,6.877H182.436l-6.856,5.157-5.14,6.877h-8.568c-3.428,1.719-11.312,5.157-15.424,5.157H117.314Z",
      translate: { x: "653.264", y: "667.984" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "MARIGA-2",
      name: "MARIGA",
      fill: "#001755",
      translate: { x: "807.843", y: "762.594" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M",
      name: "4M",
      fill: "#001755",
      translate: { x: "831.91", y: "779.785" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "MASHEGU",
    path: {
      id: "Path_6943",
      d: "M68.75,241.006v9.479L61.9,235.316l-8.569-13.268,10.282-18.959,8.568-11.377,8.569-11.375-6.855-13.271-1.714-13.271,1.714-7.584,12-3.792,12-7.584,6.855-11.375,12,3.792,10.282,7.583v-7.583l-3.427-15.167,3.427-13.271V91.23c1.714-3.16,5.484-9.479,6.855-9.479h13.71L163,76.063l6.855-9.479,6.855-1.9L185.281,59l13.71,11.375h22.277l13.712,5.688,18.849-1.9,22.28,1.9h8.568l10.281-5.688,13.708,11.375,13.712,9.479,12,7.583,6.853,9.479V123.46l-6.853,3.792v15.167l-12,3.792-6.856,11.375H294.957v9.479l-10.281,13.271-8.568,5.688H260.684v5.688L250.4,186.024l-8.568-5.688h-12l-8.568,5.688H210.987l-6.855,5.688-5.141,18.958v20.857h-8.569c-2.285,3.16-7.2,9.479-8.568,9.479S171,243.533,166.43,244.8l-13.71,5.69-12,11.374h-13.71l-15.423-5.687V222.048H89.315l-8.569-7.585-6.855-3.792v11.377l-5.141,9.479Z",
      translate: { x: "539.027", y: "780.146" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "MASHEGU-2",
      name: "MASHEGU",
      fill: "#001755",
      translate: { x: "707.804", y: "904.632" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4.5M",
      name: "4.5M",
      fill: "#001755",
      translate: { x: "722.305", y: "930.262" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "MOKWA",
    path: {
      id: "Path_6944",
      d: "M58.311,154.294l3.428-3.438-3.428-12.034V119.911L61.739,109.6V101l17.137,13.753H99.44V142.26l13.71,5.157H132l15.423-8.6,10.282-5.157,17.137-5.157,5.141-8.6,6.855,3.438,3.427-8.6,3.427,5.157,6.855,3.438,8.567,10.315,6.856,8.6-3.428,8.6,6.856,6.877,5.14,8.6,6.856,5.157v8.6l-6.856,5.157,6.856,6.877v6.877h-6.856l-5.14,5.157-6.856,6.877H219.4l5.14,5.157,6.856,3.438,6.853,8.6h10.284l8.568,6.877,6.856,5.157h22.277l3.428-5.157h5.14V223.06l-5.14-6.877V202.11l5.14-10V166.328l3.428-12.034,3.428-6.877v-8.6l5.14-5.157V123.349l3.428,5.157v10.315l-3.428,8.6v6.877l-5.14,12.034v18.911l-3.428,10.315-3.428,8.6v12.034l3.428,3.438,3.428,10.762,5.14,4.71-5.14,8.6-6.856,3.438V254l-5.14,5.157v6.712h5.14l6.856,1.884,12-1.884H341.07l8.568-3.273h10.284l5.14,3.273,5.14,1.884,8.568,5.157h6.856l8.568,3.438H373.63l-8.568-3.438-5.14,6.877-3.428-6.877H286.232l-10.284-13.753L263.956,254H257.1l-8.568-6.877c-2.857-2.864-8.912-9.283-10.284-12.034s-5.14-1.145-6.853,0l-12-6.877L205.69,223.06h-12l-3.427-3.438c-2.285-1.145-7.54-3.438-10.282-3.438h-5.141l-8.569-8.6L150.85,195.553l-10.282-10.315H123.431l-10.282,3.438-8.568,6.877H87.444c-.571-2.293-2.4-7.564-5.141-10.315s-1.143-6.877,0-8.6l-8.569-10.315-12-3.438Z",
      translate: { x: "551.18", y: "882.554" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "MOKWA-2",
      name: "MOKWA",
      fill: "#001755",
      translate: { x: "673.531", y: "1044.535" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-3",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "687.283", y: "1062.728" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "LAVUN",
    path: {
      id: "Path_6945",
      d: "M153.026,217.741h-9.84l-5.907-5.343-3.937-7.131,9.844-3.562v-8.912l-9.844-5.343-7.874-8.912-3.94-10.693,3.94-7.128-9.845-5.347-5.906-10.693-11.812-5.343H92V116.167l5.906-14.256,7.874-5.346h9.843L125.468,93h11.811l9.844,3.564,5.9,5.346,7.877,5.346h5.9l7.877,3.564,7.874,5.346,5.907,7.128,5.9,8.909v7.131l11.814,5.343h13.781l3.937-5.343,5.9,5.343-5.9,10.693-3.937,5.347v7.128l-5.907,19.605V212.4l-7.874,16.036v10.693H194.368V228.435l5.907-7.128-5.907-3.566H182.558l-3.937-12.474H166.807l-5.9,7.131Z",
      translate: { x: "633.323", y: "863.048" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "LAVUN-2",
      name: "LAVUN",
      fill: "#001755",
      translate: { x: "770.021", y: "1014.591" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-8",
      name: "2M",
      fill: "#001755",
      translate: { x: "783.774", y: "1034.221" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "MUNYA",
    path: {
      id: "Path_6946",
      d: "M209.145,77.124,197.076,71l-4.019,6.124-4.023,14.291L183,103.664v40.83l6.034,10.208L183,160.826v28.583l6.034,4.081h4.023l10.054,8.166,10.057-8.166,12.065-4.081,6.034-6.127v-10.2l8.042-8.168,12.068,8.168h14.076V160.826L255.4,154.7V144.494l10.054-6.124,4.023-6.125,12.065,6.125v-6.125l8.046-10.207V109.788l-8.046-6.124V77.124h-72.4Z",
      translate: { x: "855.208", y: "809.405" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "MUNYA-2",
      name: "MUNYA",
      fill: "#001755",
      translate: { x: "1065.715", y: "925.101" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-9",
      name: "2M",
      fill: "#001755",
      translate: { x: "1070.469", y: "944.575" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "SULEJA",
    path: {
      id: "Path_6947",
      d: "M210.956,149.785,197,166.364l13.956,9.947,9.3,9.947,13.956,16.576h23.257l18.608-16.576-18.608-19.894-9.3-9.947V136.523L234.216,110,220.26,136.523Z",
      translate: { x: "889.345", y: "904.499" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "SULEJA-2",
      name: "SULEJA",
      fill: "#001755",
      translate: { x: "1113.852", y: "1128.524" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-5",
      name: "3M",
      fill: "#001755",
      translate: { x: "1113.852", y: "1079.824" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "BIDA",
    path: {
      id: "Path_6948",
      d: "M146.13,156.506l-8.6,5.157,5.157,5.157-5.157,8.6-6.877,3.438v6.877l-5.157,5.157v6.877h51.574l8.6-3.438h10.315l-1.719-6.877v-5.157l-3.438-5.157-6.877-18.911c.574-2.864,2.063-8.94,3.438-10.315s.574-6.3,0-8.6l3.438-5.157h-6.877l-3.438,5.157-6.877-8.6L165.04,129l-8.6,3.438-3.438,6.877v6.877l-6.877,5.157Z",
      translate: { x: "715.006", y: "950.826" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_1M",
      name: "1M",
      fill: "#001755",
      translate: { x: "869.732", y: "1129.271" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "BIDA-2",
      name: "BIDA",
      fill: "#001755",
      translate: { x: "869.732", y: "1113.395" },
      tspan: { X: "0", y: "0" },
    },
  },
];
