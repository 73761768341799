export const data = [
  {
    state: "KIRI_KASAMMA",
    path: {
      id: "Path_7318",
      dataName: "KIRI KASAMA",
      d: "M2375.127,276.554l-7.413,15.882,4.235,4.963-4.235,3.971h-6.352l3.175,12.9,3.177,6.948-3.177,5.956-23.3-2.978-9.53,5.956-5.295,11.911,3.177,7.941-9.532,15.882,18.005,5.956,44.475-8.933,6.355,11.911,11.647-2.978,37.065-11.911-20.12-3.971,12.708-7.941-12.708-8.933-5.295-11.911,14.825-15.882V295.414l3.177-11.911-7.413-13.9h-10.59l-26.475,13.9Z",
      translate: { x: "-1700.56", y: "-197.615" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "KIRI_KASAMA-2",
      name: "KIRI KASAMA",
      fill: "#001755",
      translate: { x: "637.51", y: "157.97" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.5M",
      name: "3.5M",
      fill: "#001755",
      translate: { x: "665.09", y: "141.655" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "MALAM_MADORI",
    path: {
      id: "Path_7319",
      dataName: "MALLAM MADORI",
      d: "M2018.667,372.4l-12.892,2.769-5.372,8.306,4.3,5.538-1.076,11.075h-7.519L1967.1,427.772v5.538l12.892-9.23,9.669,5.538h20.411l6.448-1.846,3.223,8.306-5.372,3.692V449h9.669l19.338-15.69,18.264-5.538,3.223-6.461,6.446-7.384-3.223-8.306,5.372-10.152,7.519-5.538h5.372l11.816.923h8.6l3.223-4.615-3.223-5.538V367.78h5.372l3.223-3.692-2.149-4.615-18.264,1.846-8.593,3.692-7.522-3.692-7.519,2.769-3.223,6.461-13.968,1.846-10.742,4.615-6.446-6.461-6.446,6.461Z",
      translate: { x: "-1441.846", y: "-263.487" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "MALLAM_MADORI-2",
      name: "MALLAM MADORI",
      fill: "#001755",
      translate: { x: "569.913", y: "125.432" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M",
      name: "2M",
      fill: "#001755",
      translate: { x: "0.865,", y: "-0.502," },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "AUYO",
    path: {
      id: "Path_7320",
      d: "M2064.195,654.925H2054.6l-4.264,5.039-17.057,3.78-8.529,5.04-2.131,12.6,15.989,7.559,6.4,12.6,8.526-2.52,6.4-5.039h9.594l6.4-8.819v-8.819l6.4-10.079,12.793-11.339,2.131-7.559,5.33,3.78h12.793l5.33-8.819,20.253,21.418,14.924-3.78,22.387,11.339,1.065-28.978,4.264-8.819-11.725,3.78-5.33-15.119-18.123,3.78-27.717,7.559-18.123-7.559-18.12,7.559Z",
      translate: { x: "-1482.542", y: "-456.037" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "AUYO-2",
      name: "AUYO",
      fill: "#001755",
      translate: { x: "701.043", y: "196.449" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-2",
      name: "2M",
      fill: "#001755",
      translate: { x: "655.51", y: "188.051" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "KAUGAMA",
    path: {
      id: "Path_7321",
      d: "M1837.885,221.216l-30.71,1.846,18,12,15.885,24,4.235,10.152-6.352,12-30.712,10.153-2.118,15.69h-10.59l3.177,5.538v5.538l-4.235,6.461,1.057,4.615-6.352,1.846v5.538l-8.473,3.692,9.53,11.075h18l5.295,7.384v5.538l9.533-1.846h9.53l1.06,5.538,13.765-.923,3.177-10.153L1858,353.2l18-3.692,3.178-2.769v-9.229l5.3-3.692-3.178-8.306-7.412,1.846h-19.062l-9.53-5.538-12.707,9.23v-5.538l28.592-27.689h7.412l1.06-10.152-4.238-6.461,4.238-7.383V260.9l-8.473-5.538-6.355-10.153-13.765-5.537-2.118-10.153Z",
      translate: { x: "-1305.219", y: "-162.146" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "KAUGAMA-2",
      name: "KAUGAMA",
      fill: "#001755",
      translate: { x: "0.895,", y: "-0.447," },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-3",
      name: "2M",
      fill: "#001755",
      translate: { x: "0.895,", y: "-0.447," },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "KAFIN_HAUSA",
    path: {
      id: "Path_7322",
      dataName: "KAFIN HAUSA",
      d: "M2075.027,805.289l-18-10.152-5.295,5.538h-11.647l-5.295-32.3-4.238-13.844,13.767-8.307v-12l10.59-6.461,8.472-1.846,5.3-3.692h10.59l6.353-6.461V709.3l6.355-8.306,12.707-7.384,2.118-5.538,5.295,2.769H2124.8l6.355-6.461,8.47,6.461L2151.275,701l12.708-3.692,23.3,8.306-29.653,48.916,6.355,8.306v12.922l-24.358,12-42.357,12.921Z",
      translate: { x: "-1488.354", y: "-501.64" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "KAFIN_HAUSA-2",
      name: "KAFIN HAUSA",
      fill: "#001755",
      translate: { x: "578.913", y: "250.046" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M",
      name: "5M",
      fill: "#001755",
      translate: { x: "611.561", y: "273.118" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "KIYAWA",
    path: {
      id: "Path_7323",
      d: "M1521.082,1129.889l-18-12,8.473-8.3,6.355-4.617,3.175-12h5.3l9.533-2.769v-7.383l13.765-2.769,10.59-10.152,12.707-1.848,10.59,14.769,40.242-2.769,5.3-10.152,4.235,6.459h30.71l5.295-6.459,15.885,10.152-15.885,10.152-55.067,12.921v14.766l-12.707,5.538,8.473,6.462-14.825,2.769-2.117,2.769-5.295,3.69h-18l-9.533,10.152v12l-16.942,4.614-7.412-4.614v-7.383l-8.473-4.617v-10.152l-6.355-3.69Z",
      translate: { x: "-1101.729", y: "-782.859" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "KIYAWA-2",
      name: "KIYAWA",
      fill: "#001755",
      translate: { x: "439.476", y: "311.938" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M",
      name: "3M",
      fill: "#001755",
      translate: { x: "437.356", y: "328.553" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "BUJI",
    path: {
      id: "Path_7324",
      d: "M1881.757,1261.62l-36.008,7.5v10.32l10.59,13.132h-14.825v7.506l-5.3,7.5v68.48l9.53-5.629,4.238-11.258,10.59-6.566h11.647l19.063-8.443-3.177-16.884,14.825-9.38-3.175-48.782Z",
      translate: { x: "-1345.914", y: "-924.743" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "BUJI-2",
      name: "BUJI",
      fill: "#001755",
      translate: { x: "520.486", y: "372.943" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-4",
      name: "2M",
      fill: "#001755",
      translate: { x: "511.486", y: "395.943" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "birni KUDU",
    path: {
      id: "Path_7325",
      dataName: "BRININ KUDU",
      d: "M1346.875,1364.012l19.06-12h16.945l5.295-6.462h6.352l6.355-7.383,15.885-5.538,2.117-8.307,8.473-12,18-1.845,7.412-5.538,13.767-1.845v9.228l8.47,12-13.765,3.693v4.614l-6.355,7.386v83.988l-5.3,14.766-20.12-4.614-4.235-16.614-8.473-2.769L1403,1424.006h-19.06l-37.065-15.69-38.125,2.766,5.295-9.228v-11.076l10.59-18.459Z",
      translate: { x: "-959.289", y: "-955.146" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "BRININ_KUDU-2",
      name: "BRININ KUDU",
      fill: "#001755",
      translate: { x: "397.116", y: "417.155" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M",
      name: "4M",
      fill: "#001755",
      translate: { x: "426.769", y: "431.924" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "DUTSE",
    path: {
      id: "Path_7326",
      d: "M1260.667,1024.08l6.352-12,14.828-3.69,12.707-12.922,24.355,8.307,11.65,24.918h9.53V1037l-8.47,3.693-7.415.921v11.076l-8.47,2.769-6.355,8.307,18,14.766-3.177,3.693,8.473,3.69v9.231l7.412,3.69v10.155l9.533,3.69-7.415,7.383h-6.352l-5.295,6.462h-18l-3.177-3.693-12.708-2.769-22.237-14.766v-6.462l-7.415-20.3-3.175,3.693-7.415,5.538h-8.47l-10.59-25.845,3.177-6.459h10.59l5.292-9.231,10.59-7.383-6.352-4.614Z",
      translate: { x: "-906.969", y: "-729.658" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "DUTSE-2",
      name: "DUTSE",
      fill: "#001755",
      translate: { x: "364.116", y: "328.553" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "369.116", y: "356.457" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "JAHUN",
    path: {
      id: "Path_7327",
      d: "M1475.885,897.366l-24.355-8.308,3.177-12.921,14.825-7.384L1460,859.525l7.415-9.23,31.767,5.538,24.358-3.692,12.707,14.767,46.595-1.846,13.768,8.306,9.53-1.846,4.238,12,4.235,26.766v7.383h-20.12l-4.238-4.614-5.3,7.383-40.24,2.769-8.473-15.69-12.707,2.769-13.768,10.152-13.765,2.769h-8.473Z",
      translate: { x: "-1063.944", y: "-623.25" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "JAHUN-2",
      name: "JAHUN",
      fill: "#001755",
      translate: { x: "418.946", y: "251.023" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-2",
      name: "3M",
      fill: "#001755",
      translate: { x: "451.656", y: "270.563" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "GAGARAWA",
    path: {
      id: "Path_7328",
      d: "M1563.652,462.952l-12.708,1.846,2.117,6.461V476.8l-6.352,10.153v5.538h4.235l2.117,3.692v8.306l36.008,7.384,16.942-1.846,5.3,1.846,8.473-3.692v-5.538l5.295-1.846v-4.615l4.235-5.538V484.18l-2.118-5.538h10.59v-15.69h-26.475l-10.59-10.152-16.942,2.769-16.945,1.846Z",
      translate: { x: "-1133.709", y: "-331.894" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "GAGARAWA-2",
      name: "GAGARAWA",
      fill: "#001755",
      translate: { x: "424.446", y: "152.053" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-2",
      name: "4M",
      fill: "#001755",
      translate: { x: "446.356", y: "168.667" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "GUMEL",
    path: {
      id: "Path_7329",
      d: "M1490.712,352.562l-22.24,1.846-4.235,9.23-12.708,4.615,2.117,8.306v8.307l10.59,5.537v7.384l15.885-5.538,13.765-1.846,3.177-5.537,18-3.692-10.59-19.382Z",
      translate: { x: "-1063.944", y: "-258.421" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "GUMEL-2",
      name: "GUMEL",
      fill: "#001755",
      translate: { x: "401.351", y: "115.292" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-5",
      name: "2M",
      fill: "#001755",
      translate: { x: "400.946", y: "129.292" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "TAURA",
    path: {
      id: "Path_7330",
      d: "M1209.61,694.1l8.47-14.767,27.535-1.846,28.593,8.306,4.235,5.538,10.59-12,13.767-1.846,5.3,5.538,27.532-8.307,6.355-8.306,16.942-2.769,4.235,1.846,11.65,12-19.062,11.075,11.65,7.384-13.768,1.846-6.352,17.536-23.3,3.692-34.948-5.538-5.3,9.229-23.3,4.615v-12l-23.3-12.921-14.825,2.769-2.12-5.538,9.533-5.537Z",
      translate: { x: "-886.621", y: "-486.439" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "TAURA-2",
      name: "TAURA",
      fill: "#001755",
      translate: { x: "380.461", y: "214.705" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-6",
      name: "2M",
      fill: "#001755",
      translate: { x: "423.591", y: "221.431" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "GARKI",
    path: {
      id: "Path_7331",
      d: "M773.376,533.3l4.236-6.461,26.474-15.69v-5.538h-9.531l7.413-10.152,13.767-1.846L840.09,477l38.125,12,11.647-6.461,11.65,9.23,4.235,9.229,9.53,1.846,9.533,12,9.53,5.538,14.825-5.538,14.828,7.384h9.53l37.065,7.384-7.412,8.306L976.7,546.217l-5.295-5.538L958.7,542.525l-11.65,12-4.235-5.538-27.535-8.306-28.592,1.846-11.647,3.692-23.3-5.538-9.533,1.846-26.473,8.306-29.651-1.846-16.944,8.306-11.649-6.461,6.354-8.306Z",
      translate: { x: "-555.226", y: "-349.629" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "GARKI-2",
      name: "GARKI",
      fill: "#001755",
      translate: { x: "278.512", y: "152.329" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4.5M",
      name: "4.5M",
      fill: "#001755",
      translate: { x: "276.392", y: "168.941" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "SULE_TANKARKAR",
    path: {
      id: "Path_7332",
      dataName: "SULE TANKARKAR",
      d: "M1080.6,200.477l-2.117,14.767,15.885,12.921v4.615l-10.59,10.152-9.532,2.769v6.461l5.295,9.23-12.707,12.921,20.123,5.538,19.06,6.461,10.59-6.461,12.707,9.23,3.178,8.307,9.53,2.769,9.533,12,9.53,5.538,15.885-5.538,12.708,7.384h11.65v-7.384l-2.12-4.615h-4.235V302l4.235-6.461,2.12-3.692v-5.538l-2.12-6.461-16.942,6.461V279.85l-10.59-5.538v-8.306l-2.117-9.23,12.707-4.615,4.235-9.23-6.352-2.769-5.295-12,11.647-11.075-4.235-10.152-24.358-2.769-31.77-1.846Z",
      translate: { x: "-781.966", y: "-146.945" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "SULE_TANKARKAR-2",
      name: "SULE TANKARKAR",
      fill: "#001755",
      translate: { x: "299.961", y: "38.707" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-3",
      name: "4M",
      fill: "#001755",
      translate: { x: "333.978", y: "104.924" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "KAZAURE",
    path: {
      id: "Path_7333",
      d: "M277.885,348.689l-4.236-7.384,1.059-7.384,16.944-4.615V323.77H313.89l2.118-5.538,7.413-3.692,13.767,3.692,3.177,9.23L332.952,333l-4.236,6.461h11.649l5.295-3.692,7.413.923v-5.538h6.354l8.472-3.692,7.413-1.846,4.236-5.538,7.413,9.23-3.177,12-8.472,9.229-11.649,4.615L319.185,353.3l-5.295,6.461-6.354-4.615H288.475v-6.461Z",
      translate: { x: "-200.579", y: "-230.552" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "KAZAURE-2",
      name: "KAZAURE",
      fill: "#001755",
      translate: { x: "121.731", y: "143.348" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-4",
      name: "4M",
      fill: "#001755",
      translate: { x: "102.721", y: "114.252" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "RONI",
    path: {
      id: "Path_7334",
      d: "M47.591,372.16v-5.538H64.535l3.177-3.692,19.062,6.461,6.354,4.615,6.354-1.846,8.472,4.615,4.236,7.384h10.59v6.461H141.84l6.354,4.615-22.238,21.228,3.177,9.229-13.767,2.769L98.422,412.77,89.95,390.619H75.124l-16.944-9.23Z",
      translate: { x: "-34.883", y: "-266.021" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "RONI-2",
      name: "RONI",
      fill: "#001755",
      translate: { x: "25.204", y: "147.24" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-3",
      name: "3M",
      fill: "#001755",
      translate: { x: "68.995", y: "126.211" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "YANKWASHI",
    path: {
      id: "Path_7335",
      d: "M372.07,231.584h-46.6l-4.236,7.384,5.295,14.767,1.059,10.152,22.238-.923,1.059-5.538,6.354-3.692,14.826,3.692,3.177,9.229-7.413,5.538-4.236,6.461h11.649l4.236-3.692h8.472v-4.615h6.354l7.413-3.692,9.531-2.769,2.118-4.615-19.061-1.846-2.118-3.692,2.118-4.615-6.354-13.844Z",
      translate: { x: "-235.462", y: "-169.746" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "YANKWASHI-2",
      name: "YANKWASHI",
      fill: "#001755",
      translate: { x: "107.022", y: "45.149" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-4",
      name: "3M",
      fill: "#001755",
      translate: { x: "97.122", y: "75.639" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "GWIWA",
    path: {
      id: "Path_7336",
      d: "M12.708,227.676,0,231.367l9.531,3.692,3.177,4.615,6.354,2.769V248.9H7.413V252.6l5.295,10.153h18l2.118-3.692,18,6.461,7.413,5.538,6.354-2.769L73.07,272.9v-7.384l18-3.692v-15.69l-5.295-14.767,5.295-7.384-50.831-2.769-5.295,6.461Z",
      translate: { x: "0", y: "-162.146" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "GWIWA-2",
      name: "GWIWA",
      fill: "#001755",
      translate: { x: "19.801", y: "45.467" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-7",
      name: "2M",
      fill: "#001755",
      translate: { x: "30.538", y: "76.485" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "BABURA",
    path: {
      id: "Path_7337",
      d: "M722.152,289.006l-57.185-3.692-2.118,12-8.472,10.152,12.708,9.23,8.472,1.846-6.354,5.538v9.23l-2.118,4.615v16.613l18,1.846,9.531,6.461,7.413,2.769,26.475-17.536V343.46h-9.531l7.413-10.152,13.767-1.846,23.3-16.613,13.765-12.921-5.295-8.307v-8.306l10.59-1.846,9.533-10.152v-5.538l-14.828-12H760.275l-7.412,15.69L740.154,280.7Z",
      translate: { x: "-479.646", y: "-187.481" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "DABURA-2",
      name: "DABURA",
      fill: "#001755",
      translate: { x: "205.078", y: "117.46" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.5M-2",
      name: "3.5M",
      fill: "#001755",
      translate: { x: "206.386", y: "144.749" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "RINGIM",
    path: {
      id: "Path_7338",
      d: "M967.685,733.03l3.177-7.384,34.947-10.152,23.3,6.461,10.59-4.615-7.412,15.69h4.235l6.355-.923h9.53l-9.53,5.538,2.117,5.538,13.765-2.769,24.357,12.921v12l23.3-4.615,8.473,9.23-14.828,7.384-3.175,14.766-11.65,11.076-15.885,4.614V794.867l-7.412-2.769-3.177-11.075-8.47,4.615-7.412,9.229-21.18,2.769V783.792l-47.655,1.846V767.179l6.355-3.692V753.335l-6.355-5.538Z",
      translate: { x: "-709.295", y: "-524.443" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "RINGIM-2",
      name: "RINGIM",
      fill: "#001755",
      translate: { x: "282.747", y: "219.646" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-8",
      name: "2M",
      fill: "#001755",
      translate: { x: "295.454", y: "234.412" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "MAIGATARI",
    path: {
      id: "Path_7339",
      d: "M1505.247,224.673h-28.592l7.412,9.23-12.708,12.921,5.3,12,7.412,2.769,21.18-2.769,13.765,9.229,9.533,20.3,16.942-2.769,10.59,10.152h27.532l31.77-10.152,6.355-12-3.177-8.307-9.53-12-8.473-12.921-16.945-12.921Z",
      translate: { x: "-1078.479", y: "-164.681" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "MAIGATARI-2",
      name: "MAIGATARI",
      fill: "#001755",
      translate: { x: "431.004", y: "77.512" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.5M-3",
      name: "3.5M",
      fill: "#001755",
      translate: { x: "447.946", y: "90.434" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "MIGA",
    path: {
      id: "Path_7340",
      d: "M1769.657,715.494h-18.005l-19.06,11.075,11.647,6.461-11.647,2.769-7.412,17.536,12.707,14.767,45.535-1.846,13.767,8.306,8.473-1.846,14.825-8.306V751.489l4.235-3.692,6.355-2.769-6.355-9.229-16.942-4.615-13.768,1.846v-6.461h-10.59l-8.47,2.769V723.8Z",
      translate: { x: "-1264.524", y: "-524.443" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "MIGA-2",
      name: "MIGA",
      fill: "#001755",
      translate: { x: "481.476", y: "211.663" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-9",
      name: "2M",
      fill: "#001755",
      translate: { x: "495.073", y: "231.045" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "GWARAM",
    path: {
      id: "Path_7341",
      d: "M1896.582,1596.068l-60.362,6.459,5.3-14.766v-15.69l12.707-4.614V1557.3l10.59-8.3h11.65l20.12-7.386,13.768-13.842,19.06,13.842-3.175,7.386,3.175,18.459,5.295-3.693,25.415,26.766,10.59-2.769,48.715,11.076,46.595,3.69,19.06,2.769v13.845l-14.825,14.766h-10.59l-15.885,12.921-15.885-3.69-13.765,17.535,13.765,10.152,3.177,11.076h-34.945l-9.533-9.231h-15.885l-22.237,9.231-34.948-9.231-9.53-12v-26.766l6.355-11.076Z",
      translate: { x: "-1350.241", y: "-1124.042" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "GWARAM-2",
      name: "GWARAM",
      fill: "#001755",
      translate: { x: "585.523", y: "490.469" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-2",
      name: "5M",
      fill: "#001755",
      translate: { x: "596.113", y: "507.08" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "biriniwa",
    path: {
      id: "Path_7342",
      d: "M2034.835,54.454l-36,6.461,4.235,18.459,12.707,6.461,8.473,9.23,8.47,5.538v12l12.71-1.846,10.587,3.692,7.415-6.461,7.412,6.461,10.59-3.692,13.765-2.769,3.178-7.384,6.355-2.769,7.412,2.769,10.59-2.769H2136.5l8.47-15.69L2154.5,88.6l27.532-12h9.53l3.177-9.23-15.885-9.229,7.412-6.461-14.825-2.769,7.412-16.613L2166.147,6.461l-18-6.461-25.415,9.229h-18l-33.887,8.307L2056.012,24Z",
      translate: { x: "-1465.104" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "BIMINWA-2",
      name: "BIMINWA",
      fill: "#001755",
      translate: { x: "608.913", y: "46.131" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-5",
      name: "4M",
      fill: "#001755",
      translate: { x: "630.09", y: "62.744" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "GURI",
    path: {
      id: "Path_7343",
      d: "M2657.978,179.737l-8.47,5.538-4.238,6.461,15.885,9.229-3.177,8.307,7.412,12-4.235,12v15.69L2645.27,262.8l5.295,11.075,14.825,7.384-14.825,7.384,22.24,5.538,10.587,13.844,21.18-4.615,12.708-12,18-4.615,11.65-26.765V233.268L2738.46,218.5V198.2l-10.59-6.461h-27.532Z",
      translate: { x: "-1938.932", y: "-131.743" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "GURI-2",
      name: "GURI",
      fill: "#001755",
      translate: { x: "748.698", y: "101.508" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-6",
      name: "4M",
      fill: "#001755",
      translate: { x: "744.46", y: "118.121" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "HADEJIA",
    path: {
      id: "Path_7344",
      d: "M2052.568,604.99l-53.361,5.9-23.527,17.62-28.63,38.29,18.779-14.43,31.818-13.207,43.017-6.208,14.029-11.241Z",
      translate: { x: "-1427.15", y: "-443.445" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "HADEJIA-2",
      name: "HADEJIA",
      fill: "#001755",
      translate: { x: "569.011", y: "177.133" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-10",
      name: "2M",
      fill: "#001755",
      translate: { x: "560.913", y: "189.561" },
      tspan: { X: "0", y: "0" },
    },
  },
];
