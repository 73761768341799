export const data = [
  {
    state: "Imeko-afon",
    path: {
      id: "Path_7183",
      d: "M5.805,38.311,1,30.4l.848-1.979,3.957-2.827L10.61,23.33l3.675-3.392,12.154-8.48,7.349-5.936,7.067-3.392L49.618,1l9.328,1.131,5.371,2.544,7.632,3.675,5.371,9.045,2.544,7.349V54.423L84.1,76.188,89.19,87.777,92.3,91.734,97.1,89.19l1.413-4.805,1.7-8.2.848-10.176.848-5.653,3.109-5.936,3.109-4.805,5.936-.565,3.109.565,5.371,3.392,3.109,9.045-1.413,3.957v14.7a78.4,78.4,0,0,0-1.7,8.48c0,1.413-4.523,7.349-4.523,8.48,0,.9-1.7,6.219-2.544,8.762a62.391,62.391,0,0,0-1.979,5.936c0,.452-1.131,1.7-1.7,2.261l-3.675,4.24.848,3.109v9.893l2.827,8.763,5.088,5.936,5.653,5.088,5.371,5.371c1.413.471,4.24,1.866,4.24,3.675s3.392,3.015,5.088,3.392L141.2,167.2l1.413,4.24,1.413,4.523,3.675,7.915,3.109,4.523a27.025,27.025,0,0,1,1.413,5.088,16.089,16.089,0,0,0,1.131,3.957c.565.754,1.922,2.261,2.827,2.261s2.45,1.319,3.109,1.979c1.036,2.261,3.335,7.01,4.24,7.915a15.117,15.117,0,0,1,1.7,1.979h-4.24L155.05,209.6h-4.24l-8.2-1.131-7.349-3.957-7.349-1.131h-6.5l-4.523-1.7-6.219-4.24-4.805-2.544-10.741,1.413H92.582L88.06,197.45l-8.48,1.131L48.2,199.711l-1.131-20.069-1.413-3.675L33.506,165.509H30.4L28.418,160.7v-7.632l-3.392-2.827-3.109-7.632c-.283-3.015-.848-9.384-.848-10.741s-.565-9.233-.848-13l.848-9.61.848-6.784-.848-5.936V85.8l.848-6.219,2.544-5.653L21.069,62.055,15.7,56.684,10.61,46.508Z",
      translate: { x: "476.076", y: "623.17" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Imeko-afon-2",
      name: "Imeko-afon",
      fill: "#001755",
      translate: { x: "516.633", y: "775.857" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M",
      name: "5M",
      fill: "#001755",
      translate: { x: "538.934", y: "755.337" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "yewa_North",
    path: {
      id: "Path_7184",
      dataName: "yewa North",
      d: "M54.809,352.2l4.523-3.109,10.458-.848,22.9-.565,13.285-2.261L118.69,344l10.458,6.219,4.24,1.979L134.8,357v7.914l1.979,8.2v7.349l4.523,6.219h1.979l4.523,3.675v3.675l4.523,5.088v11.306L156,416.644l5.088,11.872,1.979,6.784,1.979,9.045L171.265,450l3.675,4.805-1.131,14.133v4.805l2.261,8.48-2.261,5.371-8.762,5.371-7.349,3.109-5.371,5.653v8.2l-1.7,7.067v7.349l-2.827-2.827h-4.523l-3.957-3.109H131.41l-1.979,3.109-6.219,5.37-3.392,5.653-3.957,4.805h-7.349l-5.371,2.827-6.784,2.261L92.968,547.8H74.312l-13.285,6.5-4.805,1.979H45.481L42.937,554.3V530.556h-8.2l-4.24-3.675v-5.37l1.7-4.523v-7.067l2.544-5.653,10.741-8.2,13.85-6.219-4.523-3.675-9.328-6.5-4.24-5.936v-7.349l5.936-2.827,5.088-7.632,2.544-8.763v-9.61L52.265,435.3V422.862l-3.392-6.219-7.632-6.219V352.2H54.809Z",
      translate: { x: "463.254", y: "474.076" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "yewa_North-2",
      name: "yewa North",
      fill: "#001755",
      translate: { x: "533.326", y: "904.517" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-2",
      name: "5M",
      fill: "#001755",
      translate: { x: "552.826", y: "929.475" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Ipokia",
    path: {
      id: "Path_7185",
      d: "M39.958,719.936V714l4.805,2.827H55.5l4.24-1.413v5.936l2.261,7.066V735.2l-2.261,8.2,2.261,3.392V754.7l2.544,5.653v5.936l8.48,7.914,1.413,7.349,6.784,7.915c1.79,1.413,5.6,4.24,6.5,4.24h3.109l7.915,2.261L97.9,798.8l4.805,5.936-8.2,4.805-9.328,4.523-3.957,6.784V826.5l3.957,7.914,5.653,9.893-1.7,5.371-7.915,3.109L67.659,863.81l-3.109-3.392L55.5,857.027,47.59,858.44,37.7,860.418l-8.2-3.392v-7.349l2.544-1.413v-9.328l7.915-8.2,2.544-7.915-2.544-6.5-4.523-13.85V797.1l7.067-9.045,13-11.589V756.4l-4.24-3.392H42.5L37.7,751.029,35.436,743.4l-1.979-5.088L42.5,724.176Z",
      translate: { x: "463.688", y: "313.245" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Ipokia-2",
      name: "Ipokia",
      fill: "#001755",
      translate: { x: "504.633", y: "1149.397" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M",
      name: "4M",
      fill: "#001755",
      translate: { x: "516.633", y: "1124.11" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ota",
    path: {
      id: "Path_7186",
      dataName: "Ado odo/ota",
      d: "M131.806,864.734l-3.675,1.131-7.632-13v-5.653l5.088-7.915,6.219-2.544,10.176-6.219-4.805-5.936,1.413-3.957-1.413-3.957-1.131-6.219v-4.523l5.371-2.827,10.176-4.24,5.653,1.413,6.5,2.827,7.632,1.131-.283-5.371,1.7-3.392,4.24-6.219,2.827-7.914,2.261-5.088,3.109,1.131,1.979,1.413,9.61-1.413,5.936-1.131h15.829l9.893,2.544h7.915l10.176-1.413L256.46,774l5.371,3.392,5.936,7.632,1.7,9.045,3.109,4.805h-7.915l-2.827,4.24-5.371,9.893v13.568l-3.109,2.827-11.024,9.893v3.675l-1.413,16.112c-1.036,1.884-3.392,5.653-4.523,5.653s-11.024,1.884-15.829,2.827h-8.2l-12.154-1.7-9.61,1.7-21.765-1.7H151.593Z",
      translate: { x: "424.133", y: "287.164" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Ado_odo_ota-2",
      name: "Ado odo/ota",
      fill: "#001755",
      translate: { x: "585.291", y: "1108.148" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M",
      name: "6M",
      fill: "#001755",
      translate: { x: "609.645", y: "1135.397" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Yewa_south",
    path: {
      id: "Path_7187",
      dataName: "Yewa south",
      d: "M181.931,665l-9.045-6.5-1.7-2.827-5.088-.565L162.71,652h-8.48l-2.544,3.675-5.653,5.371L138.4,671.221h-7.067l-12.154,5.088-3.109,5.371H97.133L83,688.746v5.371l2.544,6.784v7.915L83,717.012l2.544,3.392v7.632l2.261,5.088v6.219l8.762,7.914,1.131,7.632,12.72,11.872h3.957l5.936,1.979,2.544-.565-3.109-10.458V752.91l16.112-7.349L146.6,749.8l8.48,1.413-.848-5.653,6.5-9.045,4.805-13.568,5.653,3.109,9.61-1.413,2.827-7.067-1.7-3.675-3.957-6.219L172.886,696.1l-3.392-7.632,1.7-3.675,4.24-5.088,5.371-4.24,2.827-3.392v-3.675Z",
      translate: { x: "440.434", y: "340.195" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Yewa_south-2",
      name: "Yewa south",
      fill: "#001755",
      translate: { x: "541.113", y: "1040.659" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_9M",
      name: "9M",
      fill: "#001755",
      translate: { x: "559.613", y: "1061.564" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Ifo",
    path: {
      id: "Path_7188",
      d: "M332.735,771.383l-9.328-3.675-3.675-5.37-1.979-9.045-3.109-4.24-3.957-3.957-3.957-2.827-17.242,4.24-11.024.565-10.176-2.827h-14.7l-5.088.848h-1.131l2.544-6.5L235.5,709.763l5.088-7.632h2.544L245.393,701h8.2l3.675,1.131,3.392,1.7,3.109,1.413,2.261,3.392,3.957,1.131h5.653l6.784,1.979,8.2,1.413h10.458c1.79.283,5.6.848,6.5.848s5.088.565,7.067.848l4.523,1.413,4.24,1.131,2.827,3.392,3.392,1.979,6.219.848L348,721.634l7.632-1.7,3.675,1.7,1.413,3.109v13.285l-3.109,12.437-.848,8.763,2.544,5.653,7.066,6.5,3.109,1.7h3.957l1.413,3.109v3.957l3.957,4.805,5.088,2.261-9.045,1.131-5.371.848L357.892,790.6l-1.7-2.261-2.544-4.523-3.109-3.675-4.523-2.827-3.392-1.979Z",
      translate: { x: "374.145", y: "318.896" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Ifo-2",
      name: "Ifo",
      fill: "#001755",
      translate: { x: "658.689", y: "1050.116" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M",
      name: "3M",
      fill: "#001755",
      translate: { x: "693.967", y: "1061.697" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Sagamu",
    path: {
      id: "Path_7189",
      d: "M481,742.756v11.307l.848,5.37,3.675,4.805,4.24,1.413-2.261-5.088,2.261-4.24,5.653-5.088h41.551l3.675,2.827h35.333L578.8,737.95V727.775l1.7-9.328,5.653-11.024-3.109-3.392-7.067-11.589-5.088-4.805-10.741-7.915-2.827-10.176L550.535,654l-2.544,3.109-7.349,7.632v2.544h1.979v6.784l-5.653-2.544h-4.805l-1.979,4.805-3.675,3.392-11.872,5.088-1.413,4.523-5.653-1.7-6.219,4.805,2.544,1.979-4.24,6.784-7.632,13.285-7.632,7.067,3.109,6.219Z",
      translate: { x: "267.432", y: "339.325" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Shagamu-2",
      name: "Shagamu",
      fill: "#001755",
      translate: { x: "780.5", y: "1067.445" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_9M-2",
      name: "9M",
      fill: "#001755",
      translate: { x: "794.5", y: "1047.659" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Obafemi owode",
    path: {
      id: "Path_7190",
      d: "M411.592,631.673,420.637,641l.848,1.131,3.392,1.7,1.7,3.675v12.437l-2.827,12.154-.848,9.61,1.979,5.371,10.176,8.2h5.088V684.813l6.784-14.133-3.109-6.784,7.067-6.784,11.872-20.352-2.261-1.7,5.653-5.088,6.219,1.979.848-4.523,12.72-5.088,4.523-4.24V614.43l1.413-1.131h3.957l4.24,2.544V611.6l-1.131-3.392,2.261-2.544,4.24-6.219,6.219-5.088,8.2-9.893-1.979-2.544,1.979-1.131,3.392-1.7v-4.24l1.979-3.957,3.392-2.827,5.371-1.7,5.37-1.7,5.371-4.523,1.7-3.674,3.957-1.131,2.827-1.979,6.5-6.219,5.653-6.219,6.5-3.957,1.413-3.957L571.86,530.2l-1.7-3.392v-2.827h-3.109l-2.544.848h-13l-6.784-.848-4.24.848H527.2l-5.936.848-11.024,1.131H494.129l-2.261-1.131-2.544.565h-7.632l-6.5-2.261h-1.979l-3.392,2.261-3.109,1.7h-5.088l-3.109,1.13h-3.675l-2.261-2.827-4.24-1.413-4.805-1.7L438.162,522H435.9l-.848,2.827-5.371,3.109-7.632,1.13-3.957,3.109-11.024,3.392h-9.893l-5.936,5.371,1.131,2.261,1.7,4.805-1.131,8.2-3.957,12.154L387,579.38l1.979,17.525,5.653,13.85,4.805,7.632,4.24,6.219Z",
      translate: { x: "308.291", y: "396.703" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Obafemi-owode-2",
      name: "Obafemi-owode",
      fill: "#001755",
      translate: { x: "751.541", y: "949.858" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M",
      name: "2M",
      fill: "#001755",
      translate: { x: "751.541", y: "963.991" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Odogbolu",
    path: {
      id: "Path_7192",
      d: "M696.074,762.125H650l.565-5.653,1.7-9.328V736.685l1.979-9.61,4.24-8.2,1.413-1.413,5.936-10.176,4.805-13.85-.565-10.741,1.7-1.413,2.544-6.784,1.7,1.413,7.349,3.957,8.2,6.219h10.741l8.48,1.131H733.1V697.4l-2.827,2.261,1.131,3.675-9.893,8.763,1.131,8.2,6.5-2.261H733.1l8.48,2.261,1.979,9.328v13.568l-5.371,8.2-5.088,8.48v4.24Z",
      translate: { x: "193.971", y: "330.415" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Odogbolu-2",
      name: "Odogbolu",
      fill: "#001755",
      translate: { x: "864.322", y: "1068.879" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-3",
      name: "5M",
      fill: "#001755",
      translate: { x: "872.75", y: "1085.697" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Ijebu-ode",
    path: {
      id: "Path_7193",
      d: "M845.121,773.688l-55.967-1.131-.848-2.261,10.176-16.96.565-14.133-2.827-8.762-7.914-1.979h-4.24l-6.219,1.979-.848-8.2,9.328-8.2-1.131-4.24,3.109-2.544V698.5h1.413l6.5.283v5.088l2.827,3.392c.754,1.319,2.431,4.07,3.109,4.523a15.939,15.939,0,0,1,2.544,3.109l5.936,1.7,5.936,1.131,1.7,6.219-5.088,4.523-5.37,5.088-2.261,3.957v4.805l2.261,2.827h10.458l5.371-.848-1.131,6.219-3.674,5.936h9.328l9.328,1.413,1.979,6.5,1.413,3.957,2.827,1.979Z",
      translate: { x: "138.766", y: "319.982" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Ijebu-ode-2",
      name: "Ijebu-ode",
      fill: "#001755",
      translate: { x: "932.314", y: "1113.499" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_8M",
      name: "8M",
      fill: "#001755",
      translate: { x: "938.314", y: "1082.697" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Ijebu_East",
    path: {
      id: "Path_7194",
      dataName: "Ijebu East",
      d: "M873.291,717.266h-5.936l-1.7-2.544-2.827-2.544L859.441,702l-7.914-1.413H840.785l3.675-6.219,1.131-5.936-15.264,1.131-2.827-3.675v-3.957l1.7-3.392,2.261-2.261,8.48-8.48-1.7-5.936-.565-.848h.565l2.544-3.109,2.544-2.544,3.392.565,1.979.848,4.523-.848,5.37,2.544,6.5,5.088,1.131-3.392v-4.24l-1.131-5.088,3.109-6.219,1.131-2.827,4.523-3.109,4.805-6.219-4.805-6.219,1.7-2.261,11.024-.848,5.088-5.088,5.653-3.675,3.392-2.544,2.544-3.109,3.109-1.131,3.109-6.219,4.523-6.5,3.675-4.523,1.413-4.24,1.131-4.523,6.5-3.675L940.847,570H954.98l8.48,3.957,3.392,7.632,5.653,10.741,4.24,13.85,1.7,5.653,3.675,2.544,3.675-2.544,3.957-3.109,5.371-4.523,6.784-1.131,4.24,1.413,7.349,3.109,10.176-5.37,5.088-.848h5.653l3.957,1.7,5.088-3.109h7.915v4.24l-3.109,4.523,1.413,7.066a18.443,18.443,0,0,0,1.074,2.158c.345-.438.622-.565.622.1s-.277.449-.622-.1a12.062,12.062,0,0,0-1.074,1.8l-3.392,5.371v5.088l-1.7,11.872-1.979,7.349-1.7,6.784-3.957,5.653-3.957,3.957-9.328,5.653-7.632,2.827-7.066,5.088-3.392,5.088c-1.979,1.979-6.106,5.992-6.784,6.219s-2.923,1.413-3.957,1.979l-3.109,3.675v1.7l-9.611,12.72-7.067,10.176-1.413,5.088.848,2.261.565,5.653-1.413,6.5-1.979,3.392-7.632-.565H961.2l-3.675-1.131h-3.109l-3.109,1.7-7.915,4.523-2.261,3.957-3.109,3.109H927.562l-2.827,2.544-3.392-.848h-1.7V754.3l-13-.848-7.632,2.827-7.632,3.392-9.61,8.762-5.936,1.131-2.544-3.957-2.827-1.979h-3.109l-.848-1.413-.848-7.914-3.675-5.088v-2.544l1.413-5.371,2.261-4.805,5.653-3.109,6.219-6.5,1.7-3.109-1.7-3.109Z",
      translate: { x: "116.814", y: "375.839" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Ijebu_East-2",
      name: "Ijebu East",
      fill: "#001755",
      translate: { x: "1027.508", y: "1049.618" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-4",
      name: "5M",
      fill: "#001755",
      translate: { x: "1043.008", y: "1027.354" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Ogun_waterside",
    path: {
      id: "Path_7196",
      dataName: "Ogun waterside",
      d: "M979.874,892.243l-3.675-5.088h3.675l3.675-1.979c3.013-.283,9.328-.678,10.458,0s3.488-4.428,4.523-7.066l1.979-1.131,8.48-5.088h2.544a27.961,27.961,0,0,0,5.936,1.413h9.611l1.979-2.827,1.7,8.2,6.784,5.653,4.24,2.827h6.784l3.109,1.131,4.24,2.261,14.416,1.7,3.957-2.827,3.957,2.827,2.544-3.957,7.915-3.109,5.088-7.066,3.957-6.219,6.219-3.392h5.371l5.088,6.784v14.133l-1.413,7.349v6.784l1.413,5.37,3.109,9.893-1.7,9.328-2.827,3.675-9.045,1.7-4.523,1.7-5.653,7.915-7.349,3.392-2.827-1.7H1075.7l-6.5,5.371-3.109,4.24,4.24,1.7,5.371-1.7h13l1.7,1.7,13.568,5.936,4.523,5.936v5.653l-3.392,8.763h-6.5l-8.2-2.261h-8.763l-4.805-1.7-13-6.219-19.786-7.349-18.656-2.827v-5.936l1.7-4.805V940.861l-4.523-2.261h-14.981l-5.936-1.7h-9.045l-3.957,1.7a13.577,13.577,0,0,1-5.088,0c-1.357-.452-3.013-2.827-3.675-3.957l-3.675-2.827-5.371-2.261-10.458-3.392-3.957-3.392L955,917.118l1.413-3.957,5.936-3.957,11.589-4.805,7.067-5.37v-3.392Z",
      translate: { x: "61.395", y: "246.087" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Ogun_waterside-2",
      name: "Ogun waterside",
      fill: "#001755",
      translate: { x: "1066.432", y: "1163.397" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M-2",
      name: "6M",
      fill: "#001755",
      translate: { x: "1105.432", y: "1184.397" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Ijebu_north_east",
    path: {
      id: "Path_7197",
      dataName: "Ijebu north east",
      d: "M810.5,689.026v-5.088l4.24-1.979,5.653-2.544,2.827-1.131,2.827-1.979,4.805-1.131h3.109l2.261-3.109,3.957-1.413,3.675-.565,4.523-2.827h5.936l.848-1.413,6.784.565L867.6,665l-.848,2.261,4.523,6.219-4.523,5.936-4.805,3.675-4.24,9.045.565,2.544.565,4.805-1.131,5.088L852.9,700.9l-7.066-3.957-4.523,1.131-1.413-.565-3.675-1.131-5.371,6.784-11.589-2.827-3.392-3.957Z",
      translate: { x: "124.205", y: "334.544" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Ijebu_north_east-2",
      name: "Ijebu north east",
      fill: "#001755",
      translate: { x: "964.012", y: "1015.816" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M-3",
      name: "6M",
      fill: "#001755",
      translate: { x: "946.012", y: "1029.816" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Ijebu_north",
    path: {
      id: "Path_7198",
      dataName: "Ijebu north",
      d: "M859.7,572.638l1.979-6.784-5.371,1.131-2.827-1.131h-2.261l-.848-3.392-1.979-5.088v-3.392l1.413-3.957-.565-7.349-1.413-2.261-2.544-4.24-5.37-3.675-3.957,1.413-3.109,3.674-5.936,2.827-2.544,2.261H819.85v1.413c-.377.754-1.3,2.261-1.979,2.261a14.019,14.019,0,0,1-3.392-1.131l-1.131,1.131-5.371,1.131-7.067.565-13.285.848-5.653,1.7-3.957,1.131-10.176,9.045-4.24.848-2.261,1.413-5.088,2.261-4.523,1.7-1.131-1.7H735.9l-6.784,1.7h-3.675l-5.088,2.827-13.85-.848v13.85l-1.7,7.349-3.675,4.24v5.936h-2.544l-5.088,13,17.242,10.741h12.437l7.632.848H748.9l4.523,1.413h7.067l2.261-1.413,8.2-3.675,4.24-2.544,5.371-.848H783.1l2.261-3.675,2.544-.848,5.088-.848,5.371-2.827,6.219-1.131h8.763l3.109-1.413h11.306l14.416-11.589,3.109-3.109,2.544-.848L854.9,579.7Z",
      translate: { x: "175.063", y: "392.139" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Ijebu_north-2",
      name: "Ijebu north",
      fill: "#001755",
      translate: { x: "929.887", y: "990.91" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_8M-2",
      name: "8M",
      fill: "#001755",
      translate: { x: "946.012", y: "966.004" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Remo_North",
    path: {
      id: "Path_7199",
      dataName: "Remo North",
      d: "M651.178,581.848l3.674-.848-1.413,2.544-2.261,6.5,2.261,1.413h5.371l1.7-1.413,3.109.565,1.131.848h3.675l3.109-1.413h6.5V603.9l-1.7,6.5-3.392,5.088v5.936h-2.827l-4.805,12.154-2.261,5.653-1.979,2.827-2.261-1.131-5.371-5.653-7.067-6.5-4.24-5.088-5.088-4.24-4.805-3.957L628,605.591v-5.371l1.7-3.675,3.675-3.109,10.458-2.827,5.371-4.805Z",
      translate: { x: "203.533", y: "371.057" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Remo_North-2",
      name: "Remo North",
      fill: "#001755",
      translate: { x: "868.563", y: "949.991" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M-4",
      name: "6M",
      fill: "#001755",
      translate: { x: "843.971", y: "982.561" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "EWEKORO",
    path: {
      id: "Path_7200",
      d: "M277.2,561.936l-10.741,3.675,1.979,9.328-2.544,4.805-4.805,3.392-10.458,4.523-5.936,6.5v7.632l-1.7,7.066v7.349l.565,1.131,7.914,5.936,1.7,3.109v3.675l-1.7,2.827-5.936,4.523-1.131,1.7h2.261a7.437,7.437,0,0,1,2.261-1.131h8.2l9.893,4.24,2.261,3.109,3.675,1.413h6.219l14.7,3.392h10.458l13.568,1.7,8.762,2.827,2.544,2.827,3.675,2.261,5.936.565,9.045-1.131,10.741-2.544-1.413-1.979-6.784-6.219-9.328-8.48-3.675-5.37-3.392-5.371-3.109-6.219-4.523-10.741-1.7-16.677v-5.653l2.827-8.762.848-4.805,2.544-7.632v-6.784l-1.7-5.936-.848,3.957-.848,3.957-6.219,3.109-5.936,1.7h-5.936l-3.675-1.7-3.957,1.7-4.523,3.392h-5.653l-5.088-3.392-4.523-4.805Z",
      translate: { x: "370.885", y: "381.924" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "EWEKORO-2",
      name: "EWEKORO",
      fill: "#001755",
      translate: { x: "633.002", y: "983.777" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_7M",
      name: "7M",
      fill: "#001755",
      translate: { x: "650.729", y: "1004.325" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Odeda",
    path: {
      id: "Path_7202",
      d: "M340.024,378.252,329,362.423h3.675l7.349,5.088,9.045-.283,3.109,2.544,5.371-2.261,8.763,2.261,8.762-2.261,7.915-12.437,3.109-10.458h3.392l2.261-2.261,1.413-2.827-1.413-6.219,3.109-3.957,1.7-5.088.848-5.653,2.827-2.544,1.7-1.979,1.7-.565L408.993,309h11.589l7.067,9.611,2.544,10.741,3.109,7.915,7.915,6.219h4.24l7.632-4.523,4.523-4.523,5.088-1.979,4.523-.848h4.523l3.109,1.7,2.261-1.7h26.853l4.24,1.7,4.805,10.176,1.131,8.2,1.979,4.805v16.677a2.156,2.156,0,0,1-1.979,1.979,9.275,9.275,0,0,0,0,3.109c.226,1.583,4.052,3.486,5.936,4.24l6.784,3.675,5.371,1.979,2.827,1.7,3.392,4.805v14.133l4.24,7.349,1.979,7.349v8.2h-3.109l-1.7,1.131-19.5-1.131h-4.24l-17.808,1.131L469.2,434.5l-2.261-1.7-2.544.848h-8.2l-5.653-1.979h-2.261l-6.784,3.957h-5.371l-5.936,1.131-2.827-3.109-9.045-3.675h-7.067l-3.392-4.805-3.109-7.349-5.371-6.219-5.371-3.109-3.392-2.827-3.109-3.392-6.5-7.632L364.9,389.841l-7.349-1.979-9.328-3.109Z",
      translate: { x: "333.502", y: "489.289" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Odeda-2",
      name: "Odeda",
      fill: "#001755",
      translate: { x: "767.008", y: "855.799" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_8M-3",
      name: "8M",
      fill: "#001755",
      translate: { x: "780.371", y: "875.585" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "abeokuta_North",
    path: {
      id: "Path_7203",
      dataName: "abeokuta North",
      d: "M243.789,365.588l13,3.109,10.741,7.632c2.544,1.319,7.632,4.127,7.632,4.805s1.884,2.732,2.827,3.675l11.024,15.264,7.632,6.784,8.48,3.392,24.309,6.219,11.024,12.154-5.936,12.437L323.216,457.17v6.219l-3.109,2.544-4.805,3.675v2.261c-.565,1.7-1.809,5.484-2.261,7.067s-8.668,4.24-12.72,5.371l-7.632-1.7-9.045,4.523H279.4l-6.5-2.827-5.936-5.371-4.24-1.979-9.328,3.957-1.131-1.979L253.4,461.41l-9.61-9.045-1.413-3.675-3.109-13.568-3.109-7.632-5.936-9.611V406.856l-3.957-5.653v-2.827l-4.523-4.523h-1.7l-4.523-6.219-1.979-12.154L211,360.5h15.264Z",
      translate: { x: "384.793", y: "466.903" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "abeokuta_North-2",
      name: "abeokuta North",
      fill: "#001755",
      translate: { x: "629.729", y: "909.155" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_8M-4",
      name: "8M",
      fill: "#001755",
      translate: { x: "645.541", y: "895.155" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Ikenne",
    path: {
      id: "Path_7191",
      d: "M613.11,663.638l-8.762-22.9-.848-1.13,10.458-11.024-2.261-2.544,5.088-2.544,1.979,5.088,2.827,5.371,6.5,5.371,5.936,5.936,5.653,5.371,5.371,5.936,5.088,3.675v11.306l-2.827,7.067-2.261,7.632-5.371,9.045-3.109-4.24-6.784-11.024-4.24-4.24-12.437-9.045Z",
      translate: { x: "214.184", y: "352.583" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_7M-2",
      name: "7M",
      fill: "#001755",
      translate: { x: "826.162", y: "1006.91" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "Ikenne-2",
      name: "Ikenne",
      fill: "#001755",
      translate: { x: "829.971", y: "1018.325" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Abeokuta_south",
    path: {
      id: "Path_7201",
      dataName: "Abeokuta south",
      d: "M409,508.983v8.48h8.48l10.458-3.109,3.675-2.826,8.2-1.979L444.615,507l1.979-1.979-3.109-4.523-1.7-2.544L439.81,494l-1.413-3.109-4.24-4.523L426.525,481,422,488.914,420.024,494Z",
      translate: { x: "298.729", y: "414.524" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-2",
      name: "2M",
      fill: "#001755",
      translate: { x: "716.252", y: "920.611" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "Abeokuta_south-2",
      name: "Abeokuta south",
      fill: "#001755",
      translate: { x: "741.5", y: "906.611" },
      tspan: { X: "0", y: "0" },
    },
  },
];
