export const data = [
  {
    state: "KEBBI",
    path: {
      id: "Path_7227",
      d: "M132.654,408.581l4.693-5.866,31.09-7.626,13.492-7.039,19.358-10.559,10.559-7.039h58.073L275.783,364c-.2,21.508-.469,65.347,0,68.632s-1.76,26.006-2.933,36.956l-1.76,8.212-2.933,6.453v10.559l2.933,1.173v4.106h-9.972l-1.76,3.52h-5.279l-2.346-3.52H243.52c-1.877,0-2.346,3.129-2.346,4.693l-7.039-1.173-3.52-3.52h-8.8l-4.693,4.693-5.279,4.106-9.386,3.52-11.145,2.933-14.078,1.76-2.933-3.52h-3.52l-11.145-1.173-4.106,5.866H141.452c-4.5,3.129-13.844,9.5-15.251,9.972-1.76.587-11.732,9.972-15.251,9.972a13.843,13.843,0,0,1-9.386-4.106c-1.877-1.877-10.95-9.385-15.251-12.905l-9.386-8.8v-3.52l4.106-4.106L126.2,481.319l1.76-19.944,7.626-13.492V434.978Z",
      translate: { x: "341.125", y: "736.438" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "KEBBE-2",
      name: "KEBBE",
      fill: "#001755",
      translate: { x: "524.813", y: "1170.098" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M",
      name: "5M",
      fill: "#001755",
      translate: { x: "529.506", y: "1191.215" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "TAMBUWAL",
    path: {
      id: "Path_7228",
      d: "M214.148,254.26l11.145-1.76,24.05,19.358-1.173,25.224-6.453,1.76,6.453,9.386,2.346,7.039v24.05l11.732,5.866h16.425l.587-1.173.587-11.145,1.76-12.905h18.184v2.933l28.157,21.117,17.6,8.212v23.464l-33.436,2.346-21.7-8.8H279.847l-7.039,8.8-9.385,11.732h-58.66L194.2,397.389l-11.732,5.866L161.941,414.4l-31.09,7.626-4.106-4.693-7.039-14.078-5.279-15.838V372.752l5.279-3.52c5.67-2.346,17.481-7.039,19.358-7.039s4.693-3.91,5.866-5.866l1.76-18.771,3.52-4.693h18.184l15.838,13.492,2.933,15.838,3.52,4.106h11.732l5.279-6.453V346.355l1.173-8.8,4.693-1.76,4.693-2.933,4.106-1.76,4.106-2.933,4.693-5.279,8.212-7.626v-7.039l-5.279-3.52-11.732-7.626-9.972-5.866-6.453-10.559V269.511l1.173-6.453Z",
      translate: { x: "347.619", y: "717.127" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "TAMBUWAL-2",
      name: "TAMBUWAL",
      fill: "#001755",
      translate: { x: "476.711", y: "1097.36" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.5M",
      name: "3.5M",
      fill: "#001755",
      translate: { x: "484.924", y: "1118.478" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "SHAGARI",
    path: {
      id: "Path_7229",
      d: "M298.6,243.693,296.838,239l-8.212,7.626,1.76,14.078,7.626,4.106,2.933,4.693v7.039l-2.933,3.52-34.023,1.76-30.5,17.6H229.38l-6.453,1.76,1.173,1.76,5.279,7.039,2.346,7.626v24.05l11.732,5.866h16.425l2.346-25.224h18.184v2.346l28.743,21.117,18.184,9.386-1.173,22.291h43.408l-3.52-59.833-6.453-5.279V301.179l7.626-10.559-1.173-11.732-6.453-5.866h-6.453l-11.145-4.693-24.05-15.251Z",
      translate: { x: "366.41", y: "714.789" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "SHAGARI-2",
      name: "SHAGARI",
      fill: "#001755",
      translate: { x: "643.305", y: "1018.756" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "664.422", y: "1036.354" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "TURETA",
    path: {
      id: "Path_7230",
      d: "M493.7,262.5h-27.57L419.2,280.1l-34.609-7.626-19.944-7.039-8.8,4.106-14.665-1.173-1.76,1.173,5.866,4.693,1.76,12.905L339.427,297.7v10.559l5.866,5.279,1.76,9.386,2.346,50.447,5.279,2.933,15.251,1.76,26.4,2.346h39.3l7.039-4.106h25.81l7.039-2.933,14.665-2.933,6.453,5.866,12.318,1.76L524.2,353.422V321.16l-9.386-14.665-18.184-41.062Z",
      translate: { x: "386.588", y: "718.858" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "TURETA-2",
      name: "TURETA",
      fill: "#001755",
      translate: { x: "777.049", y: "1036.354" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M",
      name: "4M",
      fill: "#001755",
      translate: { x: "794.535", y: "1056.624" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "RABATH",
    path: {
      id: "Path_7231",
      d: "M408.343,173.263l-29.916,5.866,9.972,5.279h14.665l9.386,1.76,5.279,9.972,1.76,8.212,1.173,4.106-2.933,5.279,11.732,21.7,19.944,21.117,38.129,21.117,12.905-14.665,22.291-12.319h19.944l24.05,12.319,24.05,9.386,23.464,2.346,4.693-2.346-12.318-21.7-1.76-21.7,4.106-2.933V194.38h-4.106l-9.385-2.346-21.117-8.8-22.877-15.251-8.8-3.52-37.542,1.76V150.386l7.039-3.52L500.439,141H477.562Z",
      translate: { x: "393.342", y: "697.816" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "RABATH-2",
      name: "RABATH",
      fill: "#001755",
      translate: { x: "834.535", y: "909.649" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-2",
      name: "4M",
      fill: "#001755",
      translate: { x: "853.307", y: "930.767" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ISA",
    path: {
      id: "Path_7232",
      d: "M858.823,170.746l-43.408-52.207v4.693l-39.888,15.251h-74.5l-6.453-5.866H622.425l-20.531-6.453L580.19,111.5l-8.212,5.866-12.905,15.251L549.1,144.936l-1.173,3.52v2.933l28.743,25.224,10.559,8.8-2.933,5.866-1.76,4.106v8.8l2.933,1.76h4.693l26.983-10.559h29.33l8.8,8.8,11.145,1.76,1.173-1.76,1.76-31.09h39.3l45.168,19.358,35.2,2.933h19.944l43.408-17.6c2.15-.977,6.8-3.285,8.212-4.693S860,170.942,858.823,170.746Z",
      translate: { x: "422.697", y: "692.707" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ISA-2",
      name: "ISA",
      fill: "#001755",
      translate: { x: "1079.535", y: "850.068" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M",
      name: "3M",
      fill: "#001755",
      translate: { x: "1151.297", y: "866.241" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "GORONYO",
    path: {
      id: "Path_7233",
      d: "M445.67,85.1l1.76-9.972,9.972-3.52,1.76-4.106,27.57,2.346,1.173,18.184,10.559,15.251h14.078v-1.76L527.207,67.5l5.866,1.76,7.626,2.346h28.157l24.05,34.023,9.972,13.492-7.626,5.279c-6.648,7.822-20.648,24.4-23.464,28.157s-1.173,7.039,0,8.212l38.129,31.676-3.52,5.279c.2,2.933-.821,8.916-6.453,9.386s-23.464-7.235-31.676-11.145l-19.358-13.492c-3.129-1.76-9.972-5.279-12.318-5.279-2.933,0-35.782,2.346-38.129,1.76-1.877-.469-.782-10.755,0-15.838l9.386-2.346h14.078l5.279-3.52,2.933-4.693-2.933-8.8-11.732-4.106H462.681c-.939,0-19.554-2.346-28.743-3.52l-14.078-7.626-2.933-1.173,21.7-25.81Z",
      translate: { x: "400.01", y: "685.087" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "GORONYO-2",
      name: "GORONYO",
      fill: "#001755",
      translate: { x: "904.926", y: "806.408" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-2",
      name: "3M",
      fill: "#001755",
      translate: { x: "944.814", y: "829.872" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "WURNO",
    path: {
      id: "Path_7234",
      d: "M379.852,140.117c-1.877,1.408-4.693,6.062-5.866,8.212l-4.693-1.173,1.173,4.106-5.866,5.279-1.173,1.76,1.173,4.106,1.173,5.279v5.279l1.76,4.106,4.106,2.933,8.212,2.933,16.425-2.933,14.078-2.933,38.715-17.011,31.09-15.838h22.877l14.078,7.626c3.91.391,12.2.939,14.078,0,2.346-1.173,4.106-2.346,5.279-2.933s3.52-4.693,2.933-5.866-1.76-7.039-2.933-8.212c-.939-.939-7.822-3.129-11.145-4.106H470.774l-26.4-2.933L428.539,119h-2.933l-6.453,4.693-22.877,2.346C391.584,130.145,381.729,138.71,379.852,140.117Z",
      translate: { x: "390.744", y: "694.006" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "WURNO-2",
      name: "WURNO",
      fill: "#001755",
      translate: { x: "780.568", y: "839.258" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-3",
      name: "3M",
      fill: "#001755",
      translate: { x: "788.781", y: "858.029" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "KWARE",
    path: {
      id: "Path_7235",
      d: "M319.005,135.645h-2.933l-4.106-5.866v1.76L304.927,150.9v1.76L310.793,155l7.626,2.346,24.637,2.933,7.039,2.346v25.224l.587,1.173,5.866,7.039,1.173,2.346,1.76,8.212h2.933l14.665,5.866a60.9,60.9,0,0,1,8.212,1.173c1.408.469,14.274-.977,20.531-1.76h27.57c-.2-1.173-.7-3.637-1.173-4.106-.587-.587-1.76-8.8-2.346-10.559-.469-1.408-3.715-6.062-5.279-8.212l-9.386-1.76H401.129l-10.559-5.279a70.669,70.669,0,0,1-11.145-4.106c-4.693-2.346-3.52-9.972-3.52-11.732,0-1.408-1.564-6.843-2.346-9.386L380.6,150.9l-1.173-4.693-3.52-3.52L362.413,124.5H336.6L323.7,133.886Z",
      translate: { x: "380.613", y: "694.959" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "KWARE-2",
      name: "KWARE",
      fill: "#001755",
      translate: { x: "697.271", y: "846.297" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M",
      name: "2M",
      fill: "#001755",
      translate: { x: "730.121", y: "862.722" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "BODINGA",
    path: {
      id: "Path_7236",
      d: "M291.159,241.975l4.693-8.8-.587-7.626-9.386-5.279-6.453-5.866L295.265,201.5h21.117l16.425,9.386,14.078,4.693,2.933,4.693a39.976,39.976,0,0,0,5.279,2.346h14.665v14.665H365.07l.587,24.637h1.76l2.346,5.279,5.279,5.866v4.106l-7.626,2.346-24.637-.587-10.559-4.106-23.464-15.251L288.813,249.6l-1.173-4.693Z",
      translate: { x: "376.197", y: "708.294" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "BODINGA-2",
      name: "BODINGA",
      fill: "#001755",
      translate: { x: "671.461", y: "942.499" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-2",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "684.367", y: "962.443" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "YABO",
    path: {
      id: "Path_7237",
      d: "M205.88,248.8l-6.453-14.665,18.771-3.52,7.039-.587,13.492-1.173h6.453l4.106-1.76,10.559-5.866,1.173-2.346,1.173-9.386h21.7l8.8,3.52,3.52,2.346,3.52,3.52,9.972,5.866v7.626l-4.693,8.212L292.7,251.148l1.173,15.251,7.626,4.106,3.52,4.106v7.626l-3.52,2.933-34.023,1.76-29.916,18.184-4.106-1.173,1.173-24.637-24.637-19.358V248.8C209.986,246.925,207.249,248.019,205.88,248.8Z",
      translate: { x: "362.342", y: "709.68" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "YABO-2",
      name: "YABO",
      fill: "#001755",
      translate: { x: "595.203", y: "964.789" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-4",
      name: "3M",
      fill: "#001755",
      translate: { x: "603.416", y: "984.733" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "WAMAKO",
    path: {
      id: "Path_7238",
      d: "M328.936,153.193,316.031,148.5l-7.039,7.626-14.665,7.626-4.106-1.173-18.184,5.279-34.609,13.492,2.933,1.173,7.626,4.693,10.559,9.972-.587,6.453-1.76,6.453-.587,9.972h21.7l9.386,3.52c4.693-4.3,14.313-12.905,15.251-12.905H323.07l3.605-1.76,4.693-5.279,5.866-6.453,7.039-4.693,17.512-8.8V158.472l-7.039-2.346Z",
      translate: { x: "368.922", y: "699.115" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "WAMAKKO-2",
      name: "WAMAKKO",
      fill: "#001755",
      translate: { x: "638.863", y: "876.867" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-2",
      name: "2M",
      fill: "#001755",
      translate: { x: "653.863", y: "903.783" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "SILAME",
    path: {
      id: "Path_7239",
      d: "M171.111,185.165c1.408-.939,3.324-4.3,4.106-5.866l19.944-8.8h22.291l8.212,5.279,11.145,3.52,14.665-1.76,4.693,1.76,5.866,3.52,11.145,10.559v6.453l-2.346,5.866V209.8l-1.76,17.011c-4.3,2.933-13.844,8.8-17.6,8.8s-18.771,1.173-25.81,1.76l-21.7,4.106L184.6,235.612,155.86,209.8l-2.933-4.106,2.933-3.52,12.905-12.319C168.961,188.684,169.7,186.1,171.111,185.165Z",
      translate: { x: "354.287", y: "702.925" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "SILAME-2",
      name: "SILAME",
      fill: "#001755",
      translate: { x: "540.064", y: "911.996" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-2",
      name: "5M",
      fill: "#001755",
      translate: { x: "563.527", y: "933.113" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "SABON_BIRNI",
    path: {
      id: "Path_7240",
      dataName: "SABON BIRNI",
      d: "M627.964,59.165,589.835,44.5,546.427,76.763l34.609,46.928,20.531,13.492,19.944,6.453,28.157,1.173h45.168l6.453,5.279h74.5l5.279-2.346,35.2-12.905V130.73l-83.3-58.66c-12.905-5.279-39.067-16.19-40.475-17.6s-18.184-2.151-26.4-2.346Z",
      translate: { x: "422.438", y: "681.104" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "SABON_BIRNI-2",
      name: "SABON BIRNI",
      fill: "#001755",
      translate: { x: "1044.535", y: "782.944" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-3",
      name: "5M",
      fill: "#001755",
      translate: { x: "1085.598", y: "802.889" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "BINJI",
    path: {
      id: "Path_7241",
      d: "M174.947,165.782,182.572,130h61.006l12.905,9.386,14.078,13.492,7.039,6.453,24.637,6.453-18.771,5.279-34.609,13.492-15.838,1.76-10.559-3.52-9.386-5.279H191.958l-5.866,2.933-14.665,5.866v-3.52Z",
      translate: { x: "357.492", y: "695.911" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "BINJI-2",
      name: "BINJI",
      fill: "#001755",
      translate: { x: "544.758", y: "849.816" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-3",
      name: "2M",
      fill: "#001755",
      translate: { x: "556.488", y: "867.415" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "GADA",
    path: {
      id: "Path_7242",
      d: "M611.311,52.207,430.639,0l1.173,21.7-7.626,9.972-1.76,17.011,3.52,13.492,2.346,4.693,5.279,9.972,7.039,7.039,6.453,2.346,9.386-2.346,1.76-4.106,27.57,1.76,1.173,18.184,10.559,15.251H511l15.251-35.2,8.8,2.346,11.145,1.76h21.117Z",
      translate: { x: "400.963", y: "673.396" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "GADA-2",
      name: "GADA",
      fill: "#001755",
      translate: { x: "872.076", y: "731.324" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-4",
      name: "2M",
      fill: "#001755",
      translate: { x: "888.502", y: "750.096" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "gawabawa",
    path: {
      id: "Path_7243",
      d: "M314.36,146.47l-2.933-5.866,1.173-4.693V115.967L319.639,97.2V85.464h2.933l11.145,5.279h20.531l1.173-.587,3.52-2.346L365.98,83.7l1.76-1.76,11.145-12.319,1.173-2.933,1.173-.587h4.106L392.964,62l9.386,4.106,2.346,7.626-.587,1.173L400,80.771V92.5l5.866,2.346H421.12l12.905-5.866,12.905-6.453,13.492-9.386,5.279,2.346v2.346l-1.76,9.386-7.626,17.011-21.7,24.637-5.866,4.693-22.877,1.76-17.011,14.665-5.866,8.212-5.279-1.76-7.039-8.212-9.386-12.905H347.8c-2.933-.2-9.385-.469-11.732,0-2.933.587-12.319,8.8-15.252,9.972A19.774,19.774,0,0,1,314.36,146.47Z",
      translate: { x: "381.738", y: "684.134" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "GWADABAWA-2",
      name: "GWADABAWA",
      fill: "#001755",
      translate: { x: "707.83", y: "797.023" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-5",
      name: "3M",
      fill: "#001755",
      translate: { x: "746.545", y: "815.794" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "TANGAZA",
    path: {
      id: "Path_7244",
      d: "M231.888,26H216.47L205.2,49.726,194.53,76.419v26.1l-16.6,45.08h62.262l11.859,8.305L273.4,176.663l29.649,8.3,16.009-8.3,7.117-8.9,8.3-26.1V121.5l7.115-18.981v-69.4H252.048Z",
      translate: { x: "358.617", y: "677.899" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "TANGAZA-2",
      name: "TANGAZA",
      fill: "#001755",
      translate: { x: "577.605", y: "760.654" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-3",
      name: "4M",
      fill: "#001755",
      translate: { x: "597.551", y: "779.425" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "GUDU",
    path: {
      id: "Path_7245",
      d: "M25.125,104.578l-24.756.141L.711,164.75l16.5-.094,17.033-10.691,23.637,10.46,20.081,6.948L91,183.658l20.04-.114,38.366,9.2,29.539,11.6,15.2-21.275,10.609-.06,8-44.188,13.945-35.393-.154-27.073L243.37,27.41,117.333,45.785Z",
      translate: { x: "327.865", y: "678.144" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "GUDU-2",
      name: "GUDU",
      fill: "#001755",
      translate: { x: "402.043", y: "805.202" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-6",
      name: "3M",
      fill: "#001755",
      translate: { x: "416.303", y: "825.131" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "illela",
    path: {
      id: "Path_7246",
      d: "M346,39.3h-27.57V96.2h2.933l11.145,5.279h20.531l11.732-7.039,12.905-14.078c.391-1.173,1.408-3.52,2.346-3.52h4.106l7.039-4.106,9.972,4.106,2.346,7.626-4.693,7.039v11.732l5.866,2.346h15.251l24.05-11.145L459.21,84.47l-7.626-7.626-7.626-14.665-3.52-13.492,1.76-17.011,7.626-9.972L448.651,0H425.774l-30.5,8.212-29.33,18.771Z",
      translate: { x: "382.951", y: "673.396" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "NLELA-2",
      name: "NLELA",
      fill: "#001755",
      translate: { x: "733.641", y: "725.458" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-5",
      name: "2M",
      fill: "#001755",
      translate: { x: "745.373", y: "743.057" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "DANGA-SHNSI",
    path: {
      id: "Path_7247",
      d: "M357.017,262.545v-24.05h4.693V224.416H347.044l-5.866-3.52-1.76-4.106-13.492-4.106v-1.76l11.145-8.8h2.346l16.425-7.626h3.52l14.078,5.279,9.386,2.346,17.6-2.346h29.33l-2.933,4.693,12.905,22.291,19.944,20.531,14.078,8.8L496.626,269v4.693h-26.4l-47.514,18.184-28.743-5.866L366.4,277.8V273.69l-7.039-11.145Z",
      translate: { x: "384.25", y: "707.082" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "DANGA-SHUNI-2",
      name: "DANGA-SHUNI",
      fill: "#001755",
      translate: { x: "0.799,", y: "0.601," },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-7",
      name: "3M",
      fill: "#001755",
      translate: { x: "0.806,", y: "0.592," },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Sokoto_North",
    path: {
      id: "Path_7249",
      dataName: "Sokoto North",
      d: "M325,189.645c6.843-3.52,20.648-10.676,21.117-11.145l6.453,7.626,1.76,2.346,1.173,8.8L339.665,204.9h-2.346l-.587-4.106Z",
      translate: { x: "384.09", y: "704.311" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Sokoto_North-2",
      name: "Sokoto North",
      fill: "#001755",
      translate: { x: "730.121", y: "892.013" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-4",
      name: "4M",
      fill: "#001755",
      translate: { x: "717.994", y: "901.982" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Sokoto_south",
    path: {
      id: "Path_7248",
      dataName: "Sokoto south",
      d: "M314.02,201.992l-3.52,1.76,9.972,5.279,8.212,4.693v-2.346l10.559-7.626v-4.693l-4.693-4.693-7.626-5.866-2.933,1.76Z",
      translate: { x: "381.578", y: "706.043" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_4M-5",
      name: "4M",
      fill: "#001755",
      translate: { x: "699.707", y: "912.108" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "Sokoto_south-2",
      name: "Sokoto\n           south",
      fill: "#001755",
      translate: { x: "684.541", y: "896.057" },
      tspan: { X: "0", y: "0" },
    },
  },
];
