export const data = [
  {
    state: "faskari",
    path: {
      id: "Path_71",
      d: "M15,608v17l10.5-6.5,9.5-5L42,603l12-8.5L67.5,599l4-4.5,9-3.5,4-15,9.5-4.5,9-3.5,15-7.5,10-3.5h30l-4.5-13,4.5-16-4.5-16-11-9.5,3.5-11L142.5,471l-5-7.5L128,450l-16,7.5-9-3.5-5.5,3.5L94,471l-13.5,6.5-13,14L50,486H35l-20,5.5V512L1,550l3.5,10.5,17,11V580L15,586.5l6.5,8Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "faskari-2",
      name: "faskari",
      fill: null,
      translate: { x: "38.5", y: "543.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_7k",
      name: "7k",
      fill: null,
      translate: { x: "38.5", y: "557.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "sabuwa",
    path: {
      id: "Path_72",
      d: "M34,631l-13-8,13-10,19-19,15,4.5,6.5-4.5H82l-4,8.5v7L88.5,618l16,20,10.5,6,2.5,8.5L115,659v6.5l15,20-15,9L100.5,705,73,676H63.5l-9-5H44v-5.5l4-6.5,6.5-9L59,638l-4.5-7H34Z",
      translate: { x: "", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "sabuwa-2",
      name: "sabuwa",
      fill: null,
      translate: { x: "61.5", y: "650.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6k",
      name: "6k",
      fill: null,
      translate: { x: "70.5", y: "661.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Dandume",
    path: {
      id: "Path_73",
      d: "M99.5,575,95,571.5,84,576l-4,15-4,1.5V594h6l-4,8.5V609l26.5,29,10.5,6.5,2.5,8.5-1.5,3h1.5l10-4.5,6-1.5,7.5,1.5,7,3,6.5,4.5,2-3v-7.5l-2-3L148,642l-5-7.5,1-7L143,616l-2-7-7.5-8.5-6-5-1-4.5-5-6.5h-17l-2-4.5Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Dandume-2",
      name: "Dandume",
      fill: null,
      translate: { x: "96.5", y: "613.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4K",
      name: "4K",
      fill: null,
      translate: { x: "96.5", y: "626.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "_Funtua",
    path: {
      id: "Path_74",
      dataName: " Funtua",
      d: "M156.5,655.5,155,659l10-1,3-5.5,1-12L179.5,624l1.5-5.5-10-7-4-8.5,1-2.5,5-7-5-5-3.5-6.5v-9.5L163,557H127L95,571.5l4.5,4L104,584h17.5l5.5,7,1,5,2.5,2L141,609l2,7,1,11.5-1,7.5,5,7,3,1,5.5,4.5Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_Funtua-2",
      name: " Funtua",
      fill: null,
      translate: { x: "123.5", y: "580.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5K",
      name: "5K",
      fill: null,
      translate: { x: "123.5", y: "591.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Danja",
    path: {
      id: "Path_75",
      d: "M196,579.5,173,594l-5,6-1,3.5,4,8,10,7,2,3.5,7.5-5.5H206l2.5,8,7,9,6-2,3.5,2,3.5,3.5,10,4.5,5,4.5,9.5,10h9.5l12-7,17-3,1.5-12.5,3.5-4-5-2-5-8.5-9.5-8.5H253l-19.5-1-16-4.5-8-6.5V593l3-5-3-8.5-6-3.5Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Danja-2",
      name: "Danja",
      fill: null,
      translate: { x: "171.5", y: "613.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6K-2",
      name: "6K",
      fill: null,
      translate: { x: "171.5", y: "602.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Kafur",
    path: {
      id: "Path_76",
      d: "M228,589l6,19.5,10.5,2H277l-3-6-4-9.5,1.5-9.5,2.5-6,1.5-5,7.5-9,3-7,20.5-11,17.5-4,16-2,3.5-7.5-3.5-8.5-4.5-7.5v-6.5l-2.5-12V494l-3.5-12.5-3-10.5-4.5-9-8,4-20,12.5L274,490l-2.5,4-11,10.5-16,8.5L228,518l-4.5,12-4,26L237,574.5Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Kafur-2",
      name: "Kafur",
      fill: null,
      translate: { x: "238.5", y: "541.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_8K",
      name: "8K",
      fill: null,
      translate: { x: "242.5", y: "552.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Bakori",
    path: {
      id: "Path_77",
      d: "M227.5,589l7,20-17-4-8-7v-5.5l3-4.5-3-8.5-6-4-10.5,6-16.5,10-3.5,2-5-5-3.5-7V573l-.5-5-1-11h-5l-4.5-13,4.5-15.5L153.5,512l-11-9.5,3.5-11-.5-2.5H157l5-2,5-3h8.5l3,5,2,5.5,6,11,4,1.5,4.5,5,6.5,4.5,7,10,5,7,3-3,9-4-2.5,3v4l-3,16v7L236.5,574Z",
      translate: { x: "", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Bakori-2",
      name: "Bakori",
      fill: null,
      translate: { x: "169.5", y: "553.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_7K-2",
      name: "7K",
      fill: null,
      translate: { x: "171.5", y: "563.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Malumfashi",
    path: {
      id: "Path_78",
      d: "M210,517.5l-3,6.5,6.5,9.5,3-3,8-3L228,518l15-4.5,8.5-4,9-5,1.5-2,8.5-7,4-5.5,16-9.5,23-14L322,462c.167-2.333.4-7,0-7s-1.5-10.667-2-16H293v-6l-2.5-5-6-2.5H277l-15,1-6.5.5-7-1.5-8,2.5-4,5,.5,8.5,5,1,5,3,2.5,5.5-5.5,5-7,9-4,7-6,8.5L209,501Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Malumfashi-2",
      name: "Malumfashi",
      fill: null,
      translate: { x: "257.5", y: "466.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5K-2",
      name: "5K",
      fill: null,
      translate: { x: "246.5", y: "481.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "_Kankara",
    path: {
      id: "Path_79",
      dataName: " Kankara",
      d: "M210,517.5l-3,6.5-4.5-6.5-7-5.5-4.5-4.5-4.5-2.5-5.5-9.5-2.5-6-3-5.5-8-.5-10.5,6H145.5l-3-18.5-12-17.5-3-4L126,446l-5.5-3-1.5-7.5-2-4L98,423l-9.5-7-7-6.5L79,406l3.5-2.5,4-3,3-5,4-8L98,382l1.5-8.5V366l-3-9-2-6,3.5-1,3,1,3.5,2,4,1.5,2,6.5,2.5,6,10.5,13,2,3.5,1.5.5,4.5-.5,3-2.5h12l7,2.5,16,9,17.5-1,8,3,12,4,13.5,6.5,5,7.5L229,420l.5,3.5-5,4.5L211,436l-9,5.5,2.5,1.5,10,2,5,6,5.5-1.5,3-3.5,9-4.5,6.5,1.5,3.5,2.5,2.5,5.5-6,5.5-7,9-4.5,8-6,8-17,19Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_Kankara-2",
      name: " Kankara",
      fill: null,
      translate: { x: "101.5", y: "413.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6K-3",
      name: "6K",
      fill: null,
      translate: { x: "147.5", y: "420.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "_Musawa",
    path: {
      id: "Path_80",
      dataName: " Musawa",
      d: "M208,385.5l-10.5,10,9.5,3,13.5,6.5,5,7.5L229,420l.5,3.5-5.5,5-22.5,13L204,443l10.5,2,5,6.5,5.5-2,3-3.5,9-4.5-.5-8.5,4.5-5,8-2.5,7,1.5,29-1.5,5.5,2.5,3,5.5V439h26V393.5l-2-10-2-10v-8l2-12.5V342H312l-10.5-7.5L290,348l-1.5,5L284,364l-16,6.5L252.5,375,237,379.5H222Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_Musawa-2",
      name: " Musawa",
      fill: null,
      translate: { x: "243.5", y: "416.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_7K-3",
      name: "7K",
      fill: null,
      translate: { x: "242.5", y: "390.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Matazu",
    path: {
      id: "Path_81",
      d: "M218.5,368.5,226,380l11-.5,21-6,26-9.5,6.5-16.5,11-12.5-1.5-1.5-6-3.5h-9l-6-6.5-9-13H250l-5.5,3h-7l-9-5.5h-7l-3,2.5V316l3,3.5v4l-6,3.5v28.5Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Matazu-2",
      name: "Matazu",
      fill: null,
      translate: { x: "231.5", y: "359.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4K-2",
      name: "4K",
      fill: null,
      translate: { x: "233.5", y: "341.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "_Dan_Musa",
    path: {
      id: "Path_82",
      dataName: " Dan Musa",
      d: "M218.5,368l6.5,11.5h-2.5l-7.5,3-7,3-2.5,2.5-8,7.5-10.5-4-17.5,1L156,385l-9-4H134.5l-2.5,2.5h-6.5l-1.5-3-10.5-13-3-6-1.5-7L99,350H96l-2.5-3.5L88,338l-4-6,6-1.5,6-3.5,7.5-5,8.5-6.5,9.5-2.5,4.5,3.5h6l12.5,3h12L169,329h6l5,4,14-1,5,13.5,17-1V356Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_Dan_Musa-2",
      name: " Dan Musa",
      fill: null,
      translate: { x: "101.5", y: "345.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6K-4",
      name: "6K",
      fill: null,
      translate: { x: "116.5", y: "364.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Dustin_Ma",
    path: {
      id: "Path_83",
      dataName: "Dustin – Ma",
      d: "M199.5,345.5l-5.5-13-3-9-1.5-9.5,3-6.5,5-6,2-10-3.5-6L188,270l-8-16v-9c2.333-.5,7.2-1.5,8-1.5h7l10,4.5h24l10-7,7-2v1l3,6,2.5,5v8l4,5-1.5,9.5-8,5L237,290l2,14,4.5,9H237l-8-4.5-7-1-3.5,3v6L222,320v3.5l-6.5,4,.5,17Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Dustin_Ma-2",
      name: "Dustin – Ma",
      fill: null,
      translate: { x: "185.5", y: "259.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5K-3",
      name: "5K",
      fill: null,
      translate: { x: "206.5", y: "275.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Safana",
    path: {
      id: "Path_84",
      d: "M84,317l1.5,14.5,4.5-1,7.5-4.5,9-6,5.5-4.5,10-2,3.5,3H132l12,3h12.5L169,329h6.5l4,4,14-1-2.5-8-1.5-10,3-6,5-6.5,2-9.5L180,254l-.5-9-10-22.5L175,214H157l-6,3.5-7,5-10,3-9-3-15.5-5h-13L81,219l-14,6.5L64.5,233l2.5,9,3.5,29.5L74,298Z",
      translate: { x: "", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Safana-2",
      name: "Safana",
      fill: null,
      translate: { x: "96.5", y: "257.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6k-5",
      name: "6k",
      fill: null,
      translate: { x: "116.5", y: "271.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Kankia",
    path: {
      id: "Path_85",
      d: "M247,278l-10.5,12,2.5,14.5,4.5,9H245l5-3h20L285,330h9.5l7,5,10,7H317l3-7,3.5-8.5,4-3.5,7-2.5,6-4L339,310v-6.5l-1.5-4-1.5-4-2.5-7.5-2.5-7.5,3.5-4,9-4,5-4.5,1.5-5.5V256l-1.5-5,1.5-4,2-3-3.5-4-5-2.5-6-3.5-9-3L315,229l-8-2.5,1.5,7.5-2.5,3.5-6.5,2.5-6.5,4-7,4.5-4,6,1.5,6.5,2.5,5-4,2-8,10,6,2.5V288l-6,4-6.5,3.5L264,292v-9l-4.5-2.5-6-6.5Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Kankia-2",
      name: "Kankia",
      fill: null,
      translate: { x: "278.5", y: "303.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6k-6",
      name: "6k",
      fill: null,
      translate: { x: "294.5", y: "317.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Kusada",
    path: {
      id: "Path_86",
      d: "M362,245H351.5l-1.5,2-1.5,4.5,1.5,4V263l-1.5,5-4.5,4-9.5,4.5-3.5,4,8.5,23V310l1.5,7.5,7.5-2,21-5.5L401,300l-1-4-1.5-3.5v-10l-5.5-5-3.5-4-1-4.5-1.5-7.5L386,251l-4.5-6c-1.833-.667-5.7-2.2-6.5-3s-4-1.333-5.5-1.5l-2,1.5-2,3Z",
      translate: { x: "", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Kusada-2",
      name: "Kusada",
      fill: null,
      translate: { x: "348.5", y: "305.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5k-4",
      name: "5k",
      fill: null,
      translate: { x: "348.5", y: "288.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Ingawa",
    path: {
      id: "Path_87",
      d: "M357.5,235v10.5h8l1.5-3,2.5-2,6,2,5.5,3,5,5.5.5,11,3,11,9,9.5v10L401,300l7.5-2,8-4.5,5.5-7,4.5-7.5,7.5-9-2.5-6.5L439,256v-8.5l3-7h12l10-4.5-1-11.5V220l-4,3-5,1.5-6-6-9,4.5-5-3-10-3.5-7.5-4.5,3.5-8,2-9.5,4.5-11V168H410l-9-3-1.5,8-13,16-11,7c-2.667,1.167-8.1,3.2-8.5,2s-4.5-4.833-6.5-6.5l-10.5-11-6,3V189l-5.5,12.5-4,15,6,3.5H350l3.5,3Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Ingawa-2",
      name: "Ingawa",
      fill: null,
      translate: { x: "353.5", y: "218.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6k-7",
      name: "6k",
      fill: null,
      translate: { x: "377.5", y: "232.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "_Charanchi",
    path: {
      id: "Path_88",
      dataName: " Charanchi",
      d: "M251.5,234.5l-6,5.5,6,11v8l4,5-1,5.5-1,5,5,5.5,5.5,3v9l3.5,3.5L280,288v-7.5l-5-2-1-1,8-9.5,4-2-2-4.5-2-7,1-1.5,3.5-5,13.5-8,6-2.5,2.5-3.5-1.5-7v-3.5l-3.5-5-6-4h-6l-3-3.5v-3.5l2-6h-4l-9-3-6.5-2-8.5,2-7-3h-8l-9-2.5-9-4-5,6.5v3h5V204l6.5,13,5,6.5,9,2Z",
      translate: { x: "", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_Charanchi-2",
      name: " Charanchi",
      fill: null,
      translate: { x: "241.5", y: "217.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6k-8",
      name: "6k",
      fill: null,
      translate: { x: "245.5", y: "231.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "_Kurfi",
    path: {
      id: "Path_89",
      dataName: " Kurfi",
      d: "M236,217.5l5,6,9,2,1.5,9-4,4-2.5.5-6,2-10.5,7h-24l-10-4.5H188l-9,1-9-22,4.5-8,9-6-3.5-3v-7l3-4.5,3-10,3-8.5v-9l-4-6.5h-9.5l-5.5-5.5,2-2h5l6.5,3,7,3.5,6-1.5,3.5-2,5-3,9-1.5,7-4.5,7.5-3,2.5,3,7.5,4v4L236,160l-5,3.5-6.5,8L220,183l-6.5,10v7.5l4,1.5,7-3.5,5,.5v4.5Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_Kurfi-2",
      name: " Kurfi",
      fill: null,
      translate: { x: "192.5", y: "233.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6k-9",
      name: "6k",
      fill: null,
      translate: { x: "189.5", y: "223.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Batsari",
    path: {
      id: "Path_90",
      d: "M81,219l-14,6.5L64,219V203l-1.5-5.5-3-13v-11l11.5-2,9.5-8.5,10-18,2-9,7-1.5L118,144l11,7,4-1.5,4.5-4.5-3-9-6.5-9-8-11.5-2-10,2-5c2.5.333,7.4,1.1,7,1.5s8.167,3.833,12.5,5.5l14,9.5,2.5,7,12,10.5v15l2.5,5,5,5.5H185l4,6.5v9L184.5,190l-1.5,4.5-3,4v7l3.5,3-9,5.5-17.5-.5-12.5,9-11,3-3-1.5L109,217.5H93Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Batsari-2",
      name: "Batsari",
      fill: null,
      translate: { x: "82.5", y: "203.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5k-5",
      name: "5k",
      fill: null,
      translate: { x: "121.5", y: "211.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Batagarewa",
    path: {
      id: "Path_91",
      d: "M218,202.5l-2.5-1-2-1v-8l5.5-8,2-4.5,3.5-8.5,6.5-8,5-3.5,2.5-5.5v-4l-8-4-2-3L214,151l-8.5,1.5-8.5,5-6.5,1.5-9-4.5-4.5-2h-4.5l-2.5,1-2-4V134l11.5,6.5,8-1,12-5.5,13-9.5,12.5-10,9.5,12h11l8-5.5v-6.5l7-4.5,6.5-7,5-2,5.5-5,7.5-6.5c.667,1.5,2,4.9,2,6.5s-6.333,4-9.5,5a20.678,20.678,0,0,0,1,4c.4.8,3.167,2.667,4.5,3.5l5.5,1.5,7,3v4l-7,6-14,10L262,142l-11,13-13,14.5L234.5,181l-5,8.5L225,196v3Z",
      translate: { x: "", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Batagarewa-2",
      name: "Batagarewa",
      fill: null,
      translate: { x: "210.5", y: "140.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5k-6",
      name: "5k",
      fill: null,
      translate: { x: "190.5", y: "147.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Bindawa",
    path: {
      id: "Path_92",
      d: "M295,198.5l-4,3.5-1,1-1.5,4.5V211l3.5,3.5h5.5l6,4L307,224v2.5h1l29,7,10.5,6,4.5,5h5.5v-10l-4-12-3-3H340l-4.5-3,8.5-28V183l6.5-2.5,12,13.5,3-5.5v-9l-6-8L352,169l-4.5-7H341l-8.5,2.5h-13L308,167l-9,4.5-2.5,3L299,185Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Bindawa-2",
      name: "Bindawa",
      fill: null,
      translate: { x: "301.5", y: "183.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6k-10",
      name: "6k",
      fill: null,
      translate: { x: "299.5", y: "197.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Mani",
    path: {
      id: "Path_93",
      d: "M301.5,114.5l-4-17H307l2.5-1.5,3-2.5,2.5-5,3.5-7.5,6-10.5,2,2.5,1,10.5,4.5,7,2,15.5,4,5.5,5,9c2.167,2.5,6.7,7.7,7.5,8.5s4,1,5.5,1l2.5-4.5,5-5,5.5,6,6,1.5,7-5-2-5.5,2-6,4-5.5,4,3.5,6,9.5,5.5,11,1,7.5V147l-7.5,9,2.5,7,3,4v5.5l-3,3.5-5.5,6-1,2.5-5,4.5-9,6.5-4.5,1.5-5,1.5-4.5-5,2.5-4v-10l-6-8L352,169l-5-7h-6l-8.5,2.5H320L308,167l-8.5,5-7.5-6.5,1.5-4.5,2-9,2-9,2-11.5Z",
      translate: { x: "", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Mani-2",
      name: "Mani",
      fill: null,
      translate: { x: "318.5", y: "149.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4k-3",
      name: "4k",
      fill: null,
      translate: { x: "314.5", y: "136.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Jibia",
    path: {
      id: "Path_94",
      d: "M58,162l4,10.5,1,.5,8-1.5,9.5-8.5,10-17.5,2-9.5,7-1.5,16,8L129,151l4-1.5,4.5-4.5-3.5-9.5-14-20-2-9.5,2-6,7.5,2.5,12,5,14,9.5,3,7,12,10.5,11,6L189,139l13-7,23.5-17.5L230,120V109.5l-5.5-9-6-6.5L210,83.5V75l2-4.5,9.5-6,3-9h-3l-6,3.5-7,3.5L191,73l-15,6-10,2.5L158,75l-15-2-19-5-3.5,2.5L108,83.5,94.5,96,78,104.5l-16,2V117l4.5,10L62,133v8.5l-4,10Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Jibia-2",
      name: "Jibia",
      fill: null,
      translate: { x: "82.5", y: "114.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4k-4",
      name: "4k",
      fill: null,
      translate: { x: "76.5", y: "128.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Katsina",
    path: {
      id: "Path_95",
      d: "M237,92.5,226,103l4,7-.5,9.5,5,7L246,126l7.5-4.5V114l8-5,6-6,4.5-2-2-2.5L264,95l-6-7-7.5-6-8,2L239,86Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Katsina-2",
      name: "Katsina",
      fill: null,
      translate: { x: "229.5", y: "108.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5k-7",
      name: "5k",
      fill: null,
      translate: { x: "227.5", y: "118.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "_Kaita",
    path: {
      id: "Path_96",
      dataName: " Kaita",
      d: "M299.5,7.5,225,54.5,221.5,64,212,71.5l-2.5,5v7l17,19.5,9-8.5,3-5.5,2.5-5.5,9-2,5.5,4,9,9,8.5,7L284.5,89l4.5,2,8,5.5,10,2,4.5-4,4.5-9,6.5-14,5.5-2L338.5,51l5-13.5c-1.167-2.5-3.8-7.7-5-8.5s-4.167-7.667-5.5-11L331.5,5,320,1H309Z",
      translate: { x: "", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_Kaita-2",
      name: " Kaita",
      fill: null,
      translate: { x: "226.5", y: "77.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6k-11",
      name: "6k",
      fill: null,
      translate: { x: "267.5", y: "77.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Rimi",
    path: {
      id: "Path_97",
      d: "M289,91.5,285,90l2.5,5.5-1,1.5L284,98.5l-6.5,2.5,1.5,4.5,4,3,4.5,1,8,4V117L283,127l-13.5,9.5L262,142l-2.5,3L247,159.5l-9,10-3,11-5,8.5,9.5,4,8,2.5h8l7.5,3,8-2,16,5h4l4-3,4-13-2.5-11L299,172l-7-6.5,1.5-3.5,4.5-22,3.5-25L297,97Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Rimi-2",
      name: "Rimi",
      fill: null,
      translate: { x: "254.5", y: "193.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5k-8",
      name: "5k",
      fill: null,
      translate: { x: "251.5", y: "180.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Mashi",
    path: {
      id: "Path_98",
      d: "M365,4,331.5,5.5,333,18l5,10.5,2.5,2.5,3,6.5-4,11.5-11,20-4,1.5,1.5,2,1.5,11L332,91l2.5,15.5,9,14.5,7,8.5,6,.5,2-5,5-4,5.5,5.5,6.5,1,6-4.5-1.5-6,1.5-5.5,4-5.5,1.5-2,3.5,2,7.5,5.5,5.5-1.5,1.5-8,11.5-2.5,6-3.5-6-5c-.833,0-2.7-.3-3.5-1.5s-1.667-6.5-2-9l-4-16L395,44V33.5L392,24V11L381,8Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Mashi-2",
      name: "Mashi",
      fill: null,
      translate: { x: "348.5", y: "100.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5k-9",
      name: "5k",
      fill: null,
      translate: { x: "347.5", y: "87.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Dutsi",
    path: {
      id: "Path_99",
      d: "M414,168h13l-2.5-4,1.5-4,4-1.5h18V156l-4.5-7.5L440,139l-4.5-7.5-7-17-2-12-4-6.5L417,99.5l-4,.5-8,2.5-1.5,7.5-6,1.5-3.5-3-7-5-1.5,2,1,1,3.5,3,1,2L401.5,130l1,5.5V147l-7.5,8.5,2.5,7.5,3,3.5,1-1.5,7.5,3Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Dutsi-2",
      name: "Dutsi",
      fill: null,
      translate: { x: "405.5", y: "142.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5k-10",
      name: "5k",
      fill: null,
      translate: { x: "405.5", y: "156.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Mai'adua",
    path: {
      id: "Path_100",
      dataName: "Mai’adua",
      d: "M400,11.5h-9l1.5,13L395,33V44l12,20,2.5,9L412,86.5l2,3.5,3,1,2,2V92l7-4.5L438,80h6.5l8.5,5,8.5,5c2.833,1,8.9,3,10.5,3h6.5l6,4v10l8.5,3.5,6.5-5.5L502,95l-2.5-10L488,74.5l-26.5-21L447,40.5l-19-8L419,20l-8.5-2.5Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Mai_adua-2",
      name: "Mai’adua",
      fill: null,
      translate: { x: "408.5", y: "55.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5k-11",
      name: "5k",
      fill: null,
      translate: { x: "412.5", y: "69.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Daura",
    path: {
      id: "Path_101",
      d: "M438,80,419,92l-.5,1,1.5,1,2.5,2.5,4,6.5,1.5,9.5,1,3,3.5,8.5,2,6,4,7,2.5,4.5,3.5,8.5,1.5-1,3.5-1.5,12.5-9,11-5.5V119.5l4-6.5,7.5-6.5V97l-6-4H473l-11-2.5-8-5L444.5,80Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Daura-2",
      name: "Daura",
      fill: null,
      translate: { x: "436.5", y: "107.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5k-12",
      name: "5k",
      fill: null,
      translate: { x: "438.5", y: "128.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Katsina-3",
    path: {
      id: "Path_102",
      dataName: "Katsina",
      d: "M448,156l-3.5-6,6-3,7.5-5.5,5-3,10-5.5V119.5l4-7,7.5-6L493,111l3.5-3.5,2.5.5,2,2,7,6.5,1.5,4.5,4,8.5-2,9,2,8.5,2,10v5l-6,1H501l-12-1-8.5-3.5H448Z",
      translate: { x: "", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Katsina-4",
      name: "Katsina",
      fill: null,
      translate: { x: "462.5", y: "147.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6k-12",
      name: "6k",
      fill: null,
      translate: { x: "482.5", y: "135.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "zango",
    path: {
      id: "Path_103",
      d: "M500,104l-2,3.5,2,1.5,8,7.5,2,6,3.5,7-2,9L514,150l1,6,.5,5,5-.5H550l6,3.5,8.5-3.5L573,158l4-6h7c3,.667,9.3,2.2,10.5,3s6.5-1.667,9-3l-1.5-7.5-2-9-7-2-6-4-7-3.5-3-6.5-10.5-12-13-11-13-10-11-2-9,1-11,4H500l2,7Z",
      translate: { x: "", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_5k-13",
      name: "5k",
      fill: null,
      translate: { x: "521.5", y: "128.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "zango-2",
      name: "zango",
      fill: null,
      translate: { x: "521.5", y: "117.296" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Baure",
    path: {
      id: "Path_104",
      d: "M562,172l-6.5-8.5L573,158l4-6h6.5l12,3,7.5-2.5-3-17h5.5l19.5,3,24,9,21,8.5,16,4.5h21.5l2.5,7H699.5l-8.5-4-8.5,4-4.5,16L673,196l-20.5,5.5-12.5,2-11,4-7.5-2-5,5.5-6.5-5.5-13-7-6.5,7-10.5-13-15-3v-8Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Baure-2",
      name: "Baure",
      fill: null,
      translate: { x: "603.5", y: "189.296" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6k-13",
      name: "6k",
      fill: null,
      translate: { x: "590.5", y: "175.296" },
      tspan: { x: 0, y: 0 },
    },
  },
];
