export const data = [
  {
    state: "Abaji",
    path: {
      id: "Path_7588",
      d: "M79.093,77.829,97.767,75H163.41L147,77.829l-7.357,4.527-6.791,2.829-7.922,3.961-6.791,2.264v3.961l-1.7,5.093-4.527,7.357-6.225,7.922H87.581l-3.4,3.4V124.8l-5.093,6.791v2.264L67.2,137.816l-9.054,3.4-3.961,5.659v5.093l-3.4,4.527,3.4,5.093,6.791,5.093,9.054,2.829L74,176.295v6.791l-3.961,7.922-2.829,3.961-3.4,7.922v7.357l3.4,6.225,4.527,9.62v3.4l-1.7,5.093v28.86l1.7,9.054,2.829,5.093-2.829,7.922-1.7,7.922v35.651l-7.357,12.45L55.891,354.55l-9.62,9.62-9.054,9.054-6.225,7.922-1.7,12.45,1.7,10.752,2.264,19.806v10.752l-6.791,14.147,4.527,13.016L25.333,484.7l7.922,18.674v16.977L72.3,530.543H98.333v2.264c5.282,1.509,16.071,4.867,16.977,6.225s6.791,4.716,9.62,6.225l7.357,6.791,7.357,5.093,8.488,11.318,3.4,2.829h5.093l2.829,2.829v13.016l-2.829,6.225-5.093,19.806-5.659,6.791-11.318,5.093h-24.9l-11.318-1.7H37.783l-10.186-3.4L23.07,611.46l-9.62-18.109L4.4,571.287V561.1l2.264-3.961,2.264-7.922-2.264-6.791L8.922,525.45,6.659,501.116V487.535L8.923,468.3c0-4.527-.453-14.374-2.264-17.543s-.755-11.884,0-15.845v-8.488L4.4,386.806V373.225l2.264-16.411V266.837L1,242.5V223.829l5.659-28.86V166.674L1,131.589V93.674L6.659,82.357l5.659-4.527H79.093Z",
      translate: { x: "0", y: "9.752" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M",
      name: "3M",
      fill: "#001755",
      translate: { x: "24.768", y: "308.113" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "Abaji-2",
      name: "Abaji",
      fill: "#001755",
      translate: { x: "24.768", y: "267.878" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "gwagwalada",
    path: {
      id: "Path_7590",
      d: "M150.19,75.5h9.62l7.357,3.961,5.093,5.659,7.922,5.659,10.752,9.62,10.752,9.054,12.45,9.62,14.713,14.713,9.62,9.054c2.829,3.584,8.488,10.865,8.488,11.318s4.527,4.716,6.791,6.791l8.488,4.527,9.054,1.7,2.264,4.527V176.8l-2.264,12.45-2.829,5.093V200l-4.527,7.922-7.922,9.62-9.054,7.922-4.527,13.016L234.509,249.8l-11.884,14.713-10.186,13.581-6.791,5.093v6.791l6.791,5.093-3.961,2.829-6.225,3.4h-22.07l-8.488-3.4-3.4-5.659-5.093-6.791-5.093-4.527-9.62,2.264-10.752,2.264-13.581,6.791L106.05,297.9H88.508l-17.543-7.922-4.527-4.527,1.132-4.527.566-4.527-2.264-5.659-1.132-3.4V233.95l1.132-4.527v-3.961L63.043,219.8,57.95,209.616v-6.225l1.132-3.4,3.961-7.357,2.829-5.093,2.264-4.527v-6.225l-5.093-7.357-7.357-2.264-2.829-1.7-4.527-4.527-2.83-5.084,2.829-4.527v-5.093l4.527-5.093,20.938-7.357v-2.829l4.527-6.791v-5.093l3.4-3.4,17.543.566,7.922-9.62L112.279,95.3V90.779l9.054-3.4,11.318-4.527L142.833,77.2Z",
      translate: { x: "5.863", y: "9.818" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Gwagwala-2",
      name: "Gwagwala",
      fill: "#001755",
      translate: { x: "145.867", y: "176.203" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-2",
      name: "3M",
      fill: "#001755",
      translate: { x: "155.488", y: "229.429" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Kwali",
    path: {
      id: "Path_7591",
      d: "M219.43,284.729,205.849,278.5h-22.07l-9.054-3.4-1.7-5.659-8.488-9.054-3.4-3.4-9.054,1.7-6.791,1.7-9.054,2.829-10.752,6.225L109.075,275.1H92.1l-5.659-3.4-8.488-3.961L72.3,263.225H68.337v40.744l-6.225,10.186c-1.32,2.453-4.074,7.7-4.527,9.054s-3.961,5.47-5.659,7.357L37.779,346.977l-9.62,10.752L25.9,369.046,28.159,379.8c.755,5.282,2.264,16.524,2.264,19.24v11.318L24.2,425.07l3.961,13.581-5.659,23.2,7.922,19.24,1.7,15.279L47.4,500.9l23.767,5.093H89.841l5.659,1.7,3.4,1.7,11.884,5.093,9.62,4.527,4.527,5.093,4.527,3.4,3.961,3.4,6.791,5.659,3.961,5.659,3.961-3.4,3.961-5.659,4.527-5.659,3.4-8.488,2.264-9.62,7.357-6.791,9.054-4.527,13.581-7.357,14.147-9.62,9.054-11.318,5.659-7.922,5.659-8.488,3.4-11.318v-8.488l-5.093-6.791-3.961-7.357-1.7-6.225-1.7-7.357,1.7-12.45v-9.054l7.357-7.922,10.186-7.357,14.147-4.527c4.527-.377,13.581-1.358,13.581-2.264s5.282-4.9,7.922-6.791V338.488l-3.961-7.922L264.7,326.6l-8.488-8.488-6.791-2.264c-2.075-1.132-6.564-3.509-7.922-3.961s-5.47-5.47-7.357-7.922l-3.961-5.659-5.093-3.4Z",
      translate: { x: "2.832", y: "33.736" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Kwali-2",
      name: "Kwali",
      fill: "#001755",
      translate: { x: "107.389", y: "392.482" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M",
      name: "5M",
      fill: "#001755",
      translate: { x: "112", y: "429.899" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Kuje",
    path: {
      id: "Path_7592",
      d: "M156.6,592.058c-3.961.377-13.355.905-19.24,0h-3.4v-2.264l1.132-1.7.566-2.829,1.7-5.093,1.132-5.093,2.264-8.488,2.264-5.093V548.484l-3.4-3.4h-4.527L130,540.562l2.829-2.829,5.659-7.357,2.829-3.4,2.829-5.659,3.961-13.581,7.922-6.791,12.45-6.791,12.45-6.791,11.884-7.922,2.829-4.527,12.45-15.845,3.961-6.791,3.961-11.884v-8.488l-5.093-6.225-3.961-8.488-1.7-5.093-1.7-7.922,1.7-9.62v-12.45l5.659-5.659,6.225-5.093,6.225-4.527L232.426,364l5.659-1.7,11.884-1.132,3.4-2.829,5.093-3.961V336.841l-3.4-7.357-5.093-5.659-7.922-7.357-5.659-2.264-5.659-2.264-5.093-3.4L215.45,296.663l-4.527-3.4-5.659-10.186-5.093-2.829-7.922-3.961,9.62-6.791,1.7,1.7h17.543l13.016,7.357,13.016,2.829,10.186,4.527,13.016,7.357,14.713,7.922,10.186,2.829,10.186,3.4,15.279,9.054,13.016,7.357,8.488,1.7,32.256,5.093h68.473l9.62,5.093v9.62l2.264,10.186,6.791,5.659L470.1,365.7l13.581-.566-7.921,28.3-7.36,10.752-6.791,7.357-18.674,18.674-16.977,12.45,2.264,5.659H461.61l-5.093,6.225-6.791,11.884L432.752,485.1l-10.186,17.543-15.845,11.884-14.713,6.225-13.581,9.62L355.791,541.69c-5.093,2.64-15.845,7.922-18.109,7.922s-17.166,4.9-24.333,7.357L287.884,561.5l-31.69,12.45-35.085,5.093L176.4,585.267Z",
      translate: { x: "17", y: "35.384" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_7M",
      name: "7M",
      fill: "#001755",
      translate: { x: "303.186", y: "470.491" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "Kuje-2",
      name: "Kuje",
      fill: "#001755",
      translate: { x: "298.941", y: "437.419" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Bwari",
    path: {
      id: "Path_7593",
      d: "M246.5,181.519c.189-2.829.9-8.488,2.264-8.488l3.961-5.093,7.357-10.186,7.357-11.318,9.62-10.752,10.186-15.845,11.318-13.016L304.787,95.5l9.62-15.845,9.62-12.45,5.659-12.45,8.488-13.581H348.36l12.45-3.4,13.016,1.132,22.07-5.093,15.279,3.961,30.558,1.132,14.713,7.922,9.62-1.7,5.659-7.357L481.911,22.5l9.054-14.713L495.492,1h10.752l5.093,3.961,5.093,7.922,3.961,9.62,2.264,9.62,5.659,5.659,7.357,3.4,6.225-2.264,8.488-6.791,2.264,9.054V57.023L548.12,67.209H535.67c-3.4,1.886-9.96,6-9.054,7.357S524.353,89.09,522.655,95.5l-6.225,18.674-10.186,27.163V195.1l-4.527,11.318c-5.47,1.132-16.75,3.4-18.109,3.4s-8.112-2.264-11.318-3.4l-3.4-5.093V195.1l-2.829-6.791v-3.961c-2.264-2.64-6.9-8.6-7.357-11.318s-4.338-4.527-6.225-5.093l-13.016-6.791-15.845-3.4-26.6-5.659H379.484l-18.674-9.054-10.186-3.961H326.857L317.8,152.093l-19.24,9.054-24.333,18.109Z",
      translate: { x: "32.354" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Bwari-2",
      name: "Bwari",
      fill: "#001755",
      translate: { x: "432.209", y: "66.42" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_8M",
      name: "8M",
      fill: "#001755",
      translate: { x: "437.867", y: "101.562" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Abuja municipal",
    path: {
      id: "Path_7594",
      d: "M254.473,177.957V166.074l27.729-2.829L307.1,144l18.674-7.922,9.054-13.581H358.6c8.677,4.527,26.484,13.581,28.3,13.581H405L446.876,144l17.543,10.186,2.264,3.961,7.922,9.62v5.659l2.264,4.527,1.7,8.488,2.829,5.093,10.186,2.829,17.543-2.829,4.527-3.4,2.829,7.922V215.3l1.132,9.054v16.411l-3.961,9.62v35.651l-6.791,14.713L504.034,311.5l-3.4,9.054v6.791l-3.961,6.225-6.791,9.62c-.189,5.659-1.019,18-2.829,22.07a25.228,25.228,0,0,0-2.264,9.054v22.636L474.6,413.368v11.884l-7.357,2.829-6.791,11.884v9.62l-5.093,15.845-10.186,3.961H419.147v-7.357l5.093-3.4,10.752-9.054,12.45-9.62,10.186-11.884,9.62-14.713,3.4-10.752L474.6,385.64h-3.961a20.18,20.18,0,0,1-6.225,0c-1.811-.453-5.282-1.7-6.791-2.264l-5.659-3.4-4.527-5.659-2.264-9.054V354.516l-10.186-3.4h-69.6l-38.48-7.916-28.3-15.845-20.372-6.791-13.016-6.791-10.752-6.225-14.147-6.791-13.581-2.829L213.729,290H196.752L186,284.345v-7.357l6.225-2.264,4.527-7.357,4.527-6.225,5.093-5.659L213.729,247l6.791-10.752,3.961-5.093,2.264-9.62,3.4-4.527,6.225-4.527,9.62-10.752,4.527-9.054v-4.527Z",
      translate: { x: "24.381", y: "16.012" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "AbujaMum-2",
      name: "AbujaMum",
      fill: "#001755",
      translate: { x: "345.063", y: "275.8" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M",
      name: "2M",
      fill: "#001755",
      translate: { x: "366", y: "245.274" },
      tspan: { X: "0", y: "0" },
    },
  },
];
