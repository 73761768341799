import { color } from "../../constant/color";

import "./style.css";
import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  RiDashboardFill,
  RiWallet3Fill,
  RiSettings5Fill,
  RiScales3Fill,
} from "react-icons/ri";
import { HiDeviceMobile } from "react-icons/hi";
import { GiChemicalTank } from "react-icons/gi";
import { IoLogOut } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { deleteToken, getToken } from "../../utils/token";
import toast from "react-hot-toast";

const MobileNav = ({ closeModal }) => {
  const history = useHistory();
  const [name, setName] = useState("AK");
  const [fullName, setfullName] = useState("");

  useEffect(() => {
    (async () => {
      try {
        let user = await getToken("epump_agr_email");
        if (!user) return null;
        setName(user.substring(0, 2).toUpperCase());
        setfullName(
          user.split("@")[0][0].toUpperCase() + user.split("@")[0].substring(1)
        );
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, []);

  const handleLogout = async () => {
    try {
      await deleteToken("epump_agr_token");
      history.push("/login");
      return;
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <div className="backdrop" onClick={() => closeModal(false)}>
      <div className="mobile-nav__cover pt-2 px-1">
        <div
          className="p-2 mt-2 rounded-1 d-flex align-items-center"
          style={{
            background: color.baseColor,
          }}
        >
          <div className="brand-icon d-flex align-items-center justify-content-center">
            <div className="profile-alias">{name}</div>
          </div>
          <div className="brand-name px-2">{fullName}</div>
        </div>
        <div className="">
          <Link
            to={"/"}
            className="nav-item px-2 d-flex align-items-center mb-0"
          >
            <div className="nav-icon  px-2">
              <RiDashboardFill size={18} color={"#0c1238"} />
            </div>
            <div className="nav-link">
              <div className="px-1 nav-link__text">Overview</div>
            </div>
          </Link>
          <Link
            to={
              "/aggregate/tabledata/company/nigeria/00000000-0000-0000-0000-000000000000"
            }
            className="nav-item px-2 d-flex align-items-center my-3"
          >
            <div className="nav-icon px-2">
              <RiWallet3Fill size={18} color={"#0c1238"} />
            </div>
            <div className="nav-link ">
              <div className="px-1 nav-link__text">Companys</div>
            </div>
          </Link>
          <Link
            to={"/aggregate/tabledata/nigeria"}
            className="nav-item px-2 d-flex align-items-center my-3"
          >
            <div className="nav-icon px-2">
              <GiChemicalTank size={18} color={"#0c1238"} />
            </div>
            <div className="nav-link ">
              <div className="px-1 nav-link__text">States</div>
            </div>
          </Link>
          <Link
            to={"/aggregated/company/branchabovetrigger"}
            className="nav-item px-2  d-flex align-items-center my-3"
          >
            <div className="nav-icon px-2">
              <HiDeviceMobile size={18} color={"#0c1238"} />
            </div>
            <div className="nav-link ">
              <div className="px-1 nav-link__text">Branch Triggers</div>
            </div>
          </Link>
          <Link
            to={"/aggregate/company/settings"}
            className="nav-item px-2 d-flex align-items-center my-3"
          >
            <div className="nav-icon px-2">
              <RiSettings5Fill size={18} color={"#0c1238"} />
            </div>
            <div className="nav-link ">
              <div className="px-1 nav-link__text">Settings</div>
            </div>
          </Link>
          <Link
            to={"/aggregate/company/interventions/nigeria"}
            className="nav-item px-2 d-flex align-items-center my-3"
          >
            <div className="nav-icon px-2">
              <RiScales3Fill size={18} color={"#0c1238"} />
            </div>
            <div className="nav-link ">
              <div className="px-1 nav-link__text">Interventions</div>
            </div>
          </Link>
          <div
            className="nav-item px-2 d-flex align-items-center my-3"
            style={{
              cursor: "pointer",
            }}
          >
            <div className="nav-icon px-2">
              <IoLogOut size={18} color={"#0c1238"} />
            </div>
            <div className="nav-link " onClick={handleLogout}>
              <div className="px-1 nav-link__text">Log out </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MobileNav;
