import { color } from "../../../constant/color";
import "./style.css";
import { nigeria } from "../../../helperFunctions/mapdata/index";
import { PanZoom } from "react-easy-panzoom";

const StateMap = ({ data }) => {
  var commaFormatter = (num) =>
    num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

  const volumeFormatter = (num) => {
    return Math.abs(Number(num)) > 999999999999
      ? Math.sign(Number(num)) *
          (Math.abs(Number(num)) / 1000000000000).toFixed(1) +
          "T"
      : Math.abs(Number(num)) > 999999999
      ? Math.sign(Number(num)) *
          (Math.abs(Number(num)) / 1000000000).toFixed(1) +
        "B"
      : Math.abs(Number(num)) > 999999
      ? Math.sign(Number(num)) * (Math.abs(Number(num)) / 1000000).toFixed(1) +
        "M"
      : Math.abs(Number(num)) > 999
      ? Math.sign(Number(num)) * (Math.abs(Number(num)) / 1000).toFixed(1) + "K"
      : Math.sign(Number(num)) * Math.abs(Number(num)).toFixed(1);
  };

  let newNum = volumeFormatter("234553.23");

  return (
    <div
      className="map py-3 d-flex align-items-center  justify-content-center"
      style={{
        width: "auto",
        height: "500px",
        marginTop: "20px",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        style={{
          transform: "scale(1.1)",
        }}
        viewBox="0 0 925.78 725.25"
      >
        <g id="ng-02" transform="translate(-26.072 -29.749)">
          <g id="Nigeria" transform="translate(67.45 29.999)">
            {data.map((item, i) => (
              <a href={`/nigeria/${item.state}`} key={i}>
                <g
                  id="Osun"
                  transform={`translate(${item.g.translate.x} ${item.g.translate.y})`}
                >
                  <path
                    id={`${item.path.id}`}
                    data-name="Path 6660"
                    d={item.path.d}
                    transform={`translate(${item.path.translate.x} ${item.path.translate.y})`}
                    fill={
                      item.threasholdStatus === 0
                        ? "#FFC078"
                        : item.threasholdStatus === 1
                        ? "#82C91E"
                        : item.threasholdStatus === 2
                        ? "#FA5252"
                        : item.state.toLowerCase() === "lagos"
                        ? "dodgerblue"
                        : "black"

                      // item.state === "lagos"
                      // ? "#fff"
                      // : item.state.toLowerCase() === "enugu"
                      // ? "black"
                      // : "blue"
                    }
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.5"
                  />
                  <text
                    id={item.textValue.id}
                    data-name={item.textValue.name}
                    transform={`translate(${item.textValue.translate.x} ${item.textValue.translate.y})`}
                    fill={
                      item.state.toLowerCase() === "lagos"
                        ? "green"
                        : item.state.toLowerCase() === "ogun"
                        ? "green"
                        : item.state.toLowerCase() === "enugu"
                        ? "green"
                        : "#fff"
                    }
                    fontSize="14"
                    fontFamily="QuietSans-SemiBold, Quiet Sans"
                    fontWeight="600"
                  >
                    <tspan
                      x={item.textValue.tspan.x}
                      y={item.textValue.tspan.y}
                    >
                      {volumeFormatter(item.volume)}
                    </tspan>
                  </text>
                  <text
                    id={item.textState.id}
                    data-name={item.textState.name}
                    transform={`translate(${item.textState.translate.x} ${item.textState.translate.y})`}
                    fill={item.textState.fill}
                    fontSize="12"
                    fontFamily="QuietSans-SemiBold, Quiet Sans"
                    fontWeight="600"
                  >
                    <tspan
                      x={item.textState.tspan.x}
                      y={item.textState.tspan.y}
                    >
                      {item.state[0].toUpperCase() + item.state.substring(1)}
                    </tspan>
                  </text>
                </g>
              </a>
            ))}
          </g>
        </g>
      </svg>
    </div>
  );
};

export default StateMap;
