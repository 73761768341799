import * as uuid from "uuid";
import React, { lazy } from "react";

const Dashboard = lazy(() => import("../screens/Dashboard"));
const LGA = lazy(() => import("../screens/LGA"));
const StateTable = lazy(() => import("../screens/LGA/StateTable"));
const LGATable = lazy(() => import("../screens/LGATable"));
const BranchVolumeReport = lazy(() =>
  import("../screens/Company/Branch/BranchVolumeReport")
);
const BranchVolumeBreakDown = lazy(() =>
  import("../screens/Company/Branch/BranchVolumeBreakDown")
);
const ExceptionSummary = lazy(() =>
  import("../screens/Company/Branch/ExceptionSummary")
);
const CompanyTable = lazy(() => import("../screens/Company/CompanyTable"));
const CompanyBranchTable = lazy(() =>
  import("../screens/Company/CompanyBranchTable")
);
const MapComponent = lazy(() => import("../screens/Googlemap"));
const CompanyDashboardTable = lazy(() =>
  import("../screens/Company/CompanyDashboard")
);
const CompanyBranchVolumeTable = lazy(() =>
  import("../screens/Company/CompanyBranchVolumeTable")
);
const CompanyFuelSale = lazy(() => import("../screens/Company/FuelSale"));
const CompanyBranchTrigger = lazy(() =>
  import("../screens/Company/CompanyBranchTrigger")
);
const BranchTrigger = lazy(() => import("../screens/Company/BranchTrigger"));
const Settings = lazy(() => import("../screens/Company/Setting"));
const Intervention = lazy(() => import("../screens/Interventions"));
const InterventionHistory = lazy(() =>
  import("../screens/Interventions/InterventionHistory")
);

export const router = [
  {
    id: `${uuid.v4()}`,
    path: "/",
    component: Dashboard,
  },
  {
    id: `${uuid.v4()}`,
    path: "/:country/:state",
    component: LGA,
  },
  {
    id: `${uuid.v4()}`,
    path: "/aggregate/tabledata/:country",
    component: StateTable,
  },
  {
    id: `${uuid.v4()}`,
    path: "/aggregate/tabledata/:country/:state",
    component: LGATable,
  },
  {
    id: `${uuid.v4()}`,
    path: "/aggregate/volume/:companyid/:branchid",
    component: BranchVolumeReport,
  },
  {
    id: `${uuid.v4()}`,
    path: "/aggregate/breakdown/:companyId/:branchId",
    component: BranchVolumeBreakDown,
  },
  {
    id: `${uuid.v4()}`,
    path: "/aggregate/exceptionsummary/:companyId/:branchId",
    component: ExceptionSummary,
  },
  {
    id: `${uuid.v4()}`,
    path: "/aggregate/tabledata/company/:country/:id",
    component: CompanyTable,
  },

  {
    id: `${uuid.v4()}`,
    path: "/aggregate/tabledata/company/:country/:state/:id",
    component: CompanyBranchTable,
  },
  {
    id: `${uuid.v4()}`,
    path: "/nigeria/:state/:lga",
    component: MapComponent,
  },
  {
    id: `${uuid.v4()}`,
    path: "/aggregate/:companyId/dashboard/:companyname",
    component: CompanyDashboardTable,
  },
  {
    id: `${uuid.v4()}`,
    path: "/aggregate/statevolume/:companyid/:state",
    component: CompanyBranchVolumeTable,
  },
  {
    id: `${uuid.v4()}`,
    path: "/aggregate/:companyId/fuel-sale/:branchId/:branchName",
    component: CompanyFuelSale,
  },
  {
    id: `${uuid.v4()}`,
    path: "/aggregated/company-branch-trigger/:companyId",
    component: CompanyBranchTrigger,
  },
  {
    id: `${uuid.v4()}`,
    path: "/aggregated/company/branchabovetrigger",
    component: BranchTrigger,
  },
  {
    id: `${uuid.v4()}`,
    path: "/aggregate/company/settings",
    component: Settings,
  },
  // Intervention
  {
    id: `${uuid.v4()}`,
    path: "/aggregate/company/interventions/nigeria",
    component: Intervention,
  },
  {
    id: `${uuid.v4()}`,
    path: "/aggregate/company/interventions-history",
    component: InterventionHistory,
  },
];
