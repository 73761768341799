import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { color } from "../../../constant/color";

const BarCharts = ({ pmsData, agoData, dpkData, lpgData }) => {
  return (
    <div
      style={{
        height: "500px",
        width: "100%",
      }}
    >
      <Bar
        data={{
          labels: [
            "South-west",
            "South-south",
            "North-central",
            "South-east",
            "North-west",
            "North-east",
          ],
          datasets: [
            {
              label: "PMS",
              fill: true,
              backgroundColor: "tomato",
              data: pmsData,
              // borderColor: "#56BBF1",
              barThickness: 30,
              radius: 0,
              // borderWidth: 2,
              pointBorderWidth: 1,
              tension: 0.1,
            },
            {
              label: "AGO",
              fill: true,
              backgroundColor: "dodgerblue",
              data: agoData,
              // borderColor: "#56BBF1",
              barThickness: 30,
              radius: 0,
              // borderWidth: 2,
              pointBorderWidth: 1,
              tension: 0.1,
            },
            {
              label: "DPK",
              fill: true,
              backgroundColor: "#fefe2e",
              data: dpkData,
              barThickness: 30,
              // borderColor: "#56BBF1",
              radius: 0,
              // borderWidth: 2,
              pointBorderWidth: 1,
              tension: 0.1,
            },
            {
              label: "LPG",
              fill: true,
              backgroundColor: "lawngreen",
              data: lpgData,
              barThickness: 30,
              // borderColor: "#56BBF1",
              radius: 0,
              // borderWidth: 2,
              pointBorderWidth: 1,
              tension: 0.1,
            },
          ],
        }}
        options={{
          indexAxis: "y",
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              labels: {
                color: color.baseColor,
              },
            },
            title: {
              display: false,
              text: "Zonal Sales",
              color: "#001755",
              font: {
                size: 14,
                weight: 500,
              },
            },
          },
          scales: {
            x: {
              display: true,
              grid: {
                display: false,
              },
              stacked: true,
            },
            y: {
              display: true,
              grid: {
                display: true,
                color: "#DEDEDE",
                borderDash: [10, 3],
              },
              stacked: true,
            },
          },
        }}
      />
    </div>
  );
};

export default BarCharts;
