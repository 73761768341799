import { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { Button } from "../../component/Button";
import { color } from "../../constant/color";
import axios from "axios";
import { getToken, setToken } from "../../utils/token";
import "./style.css";
import Typed from "typed.js";
import { useHistory } from "react-router-dom";
import LaptopImg from "../../assets/images/laptopbg.png";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [eye, setEye] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        let token = await getToken("epump_agr_token");
        if (token) {
          return history.push("/");
        }
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, []);
  const element = useRef(null);
  const typed = useRef(null);
  useEffect(() => {
    const options = {
      strings: [
        `Welcome to <span style="color:orange;font-weight:600;">DORAS</span>`,
        "Downstream Retail Accountability System",
      ],
      typeSpeed: 50,
      backSpeed: 50,
    };
    typed.current = new Typed(element.current, options);
    return () => {
      // Make sure to destroy Typed instance during cleanup
      // to prevent memory leaks
      typed.current.destroy();
    };
  }, []);

  const handleSubmit = async () => {
    try {
      setLoading((prev) => true);
      let res = await axios.post("https://to.epump.com.ng/Account/login", {
        userName: email,
        password: password,
      });
      setLoading((prev) => false);
      await setToken("epump_agr_token", res.data.token);
      await setToken("epump_agr_email", res.data?.firstName);
      return history.push("/");
    } catch (err) {
      if (err.response) {
        if (err.response.status == "400") {
          setError((prev) => `${err.response.data.message}. Please try again.`);
          setLoading((prev) => false);
          return;
        }
        if (err.response.status >= "500") {
          setError((prev) => "Server is currently down. Please try again");
          setLoading((prev) => false);
          return;
        }
      }
      toast.error(err.message);
      setLoading((prev) => false);
    }
  };
  return (
    <div className="login-c d-flex align-items-center justify-content-center">
      <div
        className="container cc-cover mb-5"
        style={{
          margin: "",
        }}
      >
        <div className="row d-flex align-items-center justify-content-between ">
          <div className="col-sm-12 col-md-6 col-md-6 col-lg-5 col-xl-5 cod-view">
            <div>
              <div
                className=""
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className="hero-brand text-center">DORAS</div>
                <span className="hero-caption mt-4 text-center" ref={element} />
                <div className="lp-bg ">
                  <img
                    src={LaptopImg}
                    alt="laptop image"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-5 col-xl-5 d-flex align-items-center justify-content-center codd-fill">
            <div className="auth-form rounded-1 bg-white p-5 shadow">
              <div
                className="auth-title text-left mt-2"
                style={{
                  color: color.baseColor,
                }}
              >
                Login
              </div>
              <div className="auth-caption text-center d-none">
                Please fill in the form to continue.
              </div>
              {error && <div className="error-helper text-center">{error}</div>}
              <div className="my-2">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control form-input"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
                <div id="emailHelp" className="form-text">
                  We'll never share your email with anyone else.
                </div>
              </div>
              <div className="mb-1">
                <label htmlFor="exampleInputPassword1" className="form-label">
                  Password
                </label>
                <div className="form-control d-flex align-items-center justify-content-between">
                  <input
                    type={eye ? "text" : "password"}
                    value={password}
                    className="form-input custom"
                    onChange={(e) => setPassword(e.target.value)}
                    id="exampleInputPassword1"
                  />
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{
                      width: "30px",
                      height: "30px",
                    }}
                    onClick={() => setEye((prev) => !prev)}
                  >
                    {eye ? (
                      <IoEyeOutline size={20} color={color.baseColor} />
                    ) : (
                      <IoEyeOffOutline size={20} color={color.baseColor} />
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <Button
                  text={"Login"}
                  fontSize={"16px"}
                  height={"45px"}
                  bg={color.baseColor}
                  color={color.whiteColor}
                  loaderColor={color.whiteColor}
                  status={loading}
                  onClick={handleSubmit}
                />
              </div>

              <div className="auth-helper text-center mt-2">
                Having issues with authentication. Contact Fuel metrics.
              </div>
              <a
                href="mailto:support@doras.com"
                className=" auth-email text-center d-block"
              >
                support@doras.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

{
  /* <div className="auth-form px-2 mx-2 rounded-1 py-3 border border-primary d-none">
        <form className="px-2">
          <div
            className="auth-title text-center"
            style={{
              color: color.baseColor,
            }}
          >
            Fuel Metrics
          </div>
          <div className="auth-caption text-center">
            Fuel metrics fuel volume aggregate
          </div>
          {error && <div className="error-helper text-center">{error}</div>}
          <div className="mb-1">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Email address
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
            <div id="emailHelp" className="form-text">
              We'll never share your email with anyone else.
            </div>
          </div>
          <div className="mb-1">
            <label htmlFor="exampleInputPassword1" className="form-label">
              Password
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              id="exampleInputPassword1"
            />
          </div>

          <div className="my-3">
            <Button
              text={"Login"}
              fontSize={"15px"}
              height={"40px"}
              bg={color.baseColor}
              color={color.whiteColor}
              loaderColor={color.whiteColor}
              status={loading}
              onClick={() => handleSubmit()}
            />
          </div>

          <div className="auth-btn" onClick={handleSubmit}></div>
          <div className="auth-helper text-center mt-2">
            Having issues with authentication. Contact Fuel metrics.
          </div>
          <a
            href="mailto:support@fuelmetrics.com"
            className=" auth-email text-center d-block"
          >
            support@fuelmetrics.com
          </a>
        </form>
      </div> */
}
