export const data = [
  {
    state: "TOUNGO",
    path: {
      id: "Path_6984",
      d: "M16.664,1576.811,1,1542.226l2.637-6.049,2.326-7.444,4.187-5.583,9.771-6.2,7.91-4.963,7.6-9.15,6.2-10.546,6.049-8.685,5.893-3.1,11.942,6.824h9.771l2.481,2.481h2.792l2.171,1.241h2.947l2.637,2.636,2.792-1.4,1.861,2.947,3.412-1.551,9.305,3.877h4.5l7.91,5.273,2.792-2.947,13.028,7.444c3.774-.208,11.446-.5,11.942,0s3.825-2.792,5.428-4.5l4.032-2.947h4.963l5.118-3.412,9.15,1.861H182.3l3.1-1.861,5.738-2.792v10.7l-3.722,20.627-20.937,33.655-22.488,23.574-1.551,12.407v23.574l-13.493,17.835L107.857,1668l-9.305-27.761-4.808-9.461-3.567-12.562-7.444-30.4L70.17,1576.811l-22.488-19.386-7.91,3.567L31.4,1576.811Z",
      translate: { x: "517", y: "-382.367" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "TOUNGO-2",
      name: "TOUNGO",
      fill: "#001755",
      translate: { x: "579.971", y: "1148.336" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M",
      name: "4M",
      fill: "#001755",
      translate: { x: "587.867", y: "1178.415" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Ganaye",
    path: {
      id: "Path_6985",
      d: "M183.287,1353.654,171.5,1347.14V1339.7l2.947-4.5,4.963-2.792,8.84-3.877,8.53-5.583,7.289-5.428,4.187-3.722,4.187-2.171,2.481-3.722,2.326-3.257h3.567l1.551-3.722,2.947-1.861,4.5-3.877,5.428-6.669,4.5-2.016,3.257.931,5.273,2.171,4.963,1.861,5.273,3.722,3.257,3.877,1.706,4.808.931,6.049-2.637,3.877-4.808,4.963,1.551,4.032,4.963,2.636h15.509c5.48-.878,16.595-2.636,17.215-2.636s5.118-1.138,7.289-1.706h4.343l3.567,1.706,4.187,2.636,1.241,4.032,1.861,4.188-8.53,8.685-4.653,8.84,3.257,8.53-9,4.653h-7.6l-9-1.551-5.273,3.412h-4.808l-9.616,7.134H251.216l-12.717-7.134-2.637,2.947-7.91-5.428H223.3l-9-3.877-3.567,1.4-1.861-2.637-2.792,1.241-2.637-2.637H200.5l-2.637-1.241h-2.326l-2.171-2.326Z",
      translate: { x: "399.386", y: "-248.542" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Ganye-2",
      name: "Ganye",
      fill: "#001755",
      translate: { x: "615.793", y: "1078" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-2",
      name: "4M",
      fill: "#001755",
      translate: { x: "629.971", y: "1109.688" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Jada",
    path: {
      id: "Path_6986",
      d: "M492.834,1223.485l-10.546,1.551-.931-.62-1.241-3.257-1.551-4.032-7.755-4.5-4.342.31-3.412.775c-3.567.62-10.825,1.861-11.322,1.861s-3.309.62-4.653.931l-5.118.62h-15.2l-5.273-2.637-1.4-4.032,4.808-5.118,2.637-3.877-.775-5.738-.465-.931-1.4-4.032-3.1-3.877-5.273-3.722-4.5-1.551L408,1179.749l.31-1.706,1.706-3.567,4.343-3.877,6.049-4.653,3.877-2.481,6.979-6.514,6.669-5.894,6.514-3.567,3.257-4.808,7.444-.775,4.187-3.567.775,1.706,1.086,3.722,2.326,4.963,6.669,7.6,11.322.62,19.7,9.615,15.354-.931,4.653-4.808,16.44-5.273,5.583-2.171h5.738l5.118-1.551,6.979-2.792,3.722-4.032,4.032-3.1,7.91-6.824,9.616-5.583,5.273,1.086v3.877l-3.257,5.583v23.109l-7.289,7.6-11.167,8.685-10.081,4.808-6.669,3.722-5.273,1.241-15.819,3.1-12.1,3.877-8.84,4.808-4.808,5.738-1.4,11.322-6.669,5.428Z",
      translate: { x: "236.243", y: "-140.24" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Jada-2",
      name: "Jada",
      fill: "#001755",
      translate: { x: "696.199", y: "1037.985" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-3",
      name: "4M",
      fill: "#001755",
      translate: { x: "696.199", y: "1062.253" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Fufore",
    path: {
      id: "Path_6987",
      d: "M522.953,826.278v15.664l1.551,5.273,2.171,5.583,6.979,7.6,11.167.62,19.7,9.771,15.354-.931,4.808-4.963,21.868-7.755,5.118.31,12.873-4.653,3.722-3.877,12.1-9.771,9.15-5.583,5.738.776,5.583-1.706,5.893-8.375,1.086-8.065-1.086-14.268,2.326-22.953-2.326-8.22,3.412-6.824,10.391-7.91,16.284-7.444,13.183-5.583,4.5-6.824-1.241-3.722-7.289-5.893-12.872-2.792-8.685,2.792H671.375L660.829,721.9l-13.958-10.236-8.84-6.049-5.738-5.118-3.877,1.4-4.343,6.669v15.2l-2.326,6.359v6.049l4.187,3.722v7.444l-6.824,4.187-7.444,6.669-3.257,5.738,3.257,6.824,4.032,9v5.118l-4.032,12.717-4.5,9.926v6.979l1.241,4.342-5.738,3.257-9.616.776H574.133l-9.616-1.706-7.134-2.326-2.326-4.342-4.5-5.583-2.792-3.412-5.273-5.583-6.824-4.187L525.9,792H509.616l-1.241,1.551L500,795.726l1.861,4.187,1.706,7.6,1.241,9.926,3.567,2.326,4.032,4.5h8.84Z",
      translate: { x: "172.78", y: "155.691" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Fufore-2",
      name: "Fufore",
      fill: "#001755",
      translate: { x: "800.824", y: "939.338" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-4",
      name: "4M",
      fill: "#001755",
      translate: { x: "803.986", y: "963.398" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Mayo Belewa",
    path: {
      id: "Path_6988",
      d: "M257.546,1104.742v15.2l2.792,1.4,4.343-2.171,5.118-7.289h3.1l1.706-3.412,7.444-5.583,5.738-7.134,4.187-2.016h5.118l2.016-3.722,14.423-11.167,13.8-12.252,5.893-3.1,3.877-5.428,6.979-.931,4.808-2.792v-16.129l-2.637-2.326h-8.22l-7.444-6.514-5.118-21.868-3.257,2.792L316.17,1012h-5.738l-7.444-1.706L297.87,1012l-8.065,2.792-4.653-1.551-10.546,1.551-10.856-1.551-10.7-1.241-2.481,1.241-3.567,7.6v7.444c2.016,1.706,6.421,5.273,7.91,5.893s6.514,2.947,8.84,4.032l10.856,2.171,4.653,12.407,3.567,10.7-8.22,11.167L262.044,1086.6Z",
      translate: { x: "347.305", y: "-56.083" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "May-Belewa-2",
      name: "May-Belewa",
      fill: "#001755",
      translate: { x: "613.243", y: "977.398" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-5",
      name: "4M",
      fill: "#001755",
      translate: { x: "640.513", y: "1014.063" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Demsa",
    path: {
      id: "Path_6989",
      d: "M265.257,834.735l-3.257,4.5,1.706.155c6.669,1.033,20.193,3.1,20.937,3.1s7.651-1.138,11.011-1.706L300,842.49l13.028-4.653,6.669,1.551c2.43-.053,7.289-.124,7.289,0s3.825-.983,5.738-1.551l3.1-2.947,8.53-1.861.775-1.551,1.241-1.4v-2.947l4.963-3.877,6.824-7.6,7.134-11.632,3.722-5.118,4.963-7.444,5.273-4.653-1.551-2.171-2.016-3.257-1.706-9.616V757.5H346.369l-18.456,1.861h-4.342l1.4,1.086,2.947,2.947c-.31,2.275-.931,6.948-.931,7.444a69.871,69.871,0,0,1-3.412,7.289l1.4,3.257,2.016,5.428c.31,2.533.931,7.91.931,9.15s-4.239,2.481-6.359,2.947l-7.444,1.551-8.685,1.086-7.91,2.481-6.048,3.722-7.755,3.567-7.444,4.343-3.412,5.428c-1.551,2.171-4.653,6.669-4.653,7.289S266.239,832.874,265.257,834.735Z",
      translate: { x: "336.957", y: "116.372" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Demsa-2",
      name: "Demsa",
      fill: "#001755",
      translate: { x: "648", y: "930.259" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-6",
      name: "4M",
      fill: "#001755",
      translate: { x: "634.938", y: "946.338" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Yola_south",
    path: {
      id: "Path_6990",
      dataName: "Yola south",
      d: "M561.486,858.463l5.583-4.963,3.1,1.4,2.792,4.032,4.187,5.118,5.428,6.049,5.118,4.5-.465.465H585.68l-4.032,1.4-5.118,3.1-4.808,3.877-2.792,3.722.465,4.187,3.567,2.171,3.567.931,3.722,2.637L586.3,899.1l4.343,1.241h6.979l5.738-1.241,5.583-2.637-.62-8.685,3.412,1.861,6.824,3.722,6.979-1.086h6.979l6.048-2.637,1.241,1.4-1.241,2.326-7.444,19.386V920.5l1.4,4.032-5.583,2.947-11.167.931H597.622l-9.305-1.861-6.979-2.016-2.016-4.032-2.792-3.1-4.343-5.738-6.514-6.669-5.583-3.412-10.236-3.722H534.5v-4.5l6.979-6.2,3.722-4.963,2.016-2.637,8.53-13.338Z",
      translate: { x: "148.981", y: "50.149" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Yola_south-2",
      name: "Yola south",
      fill: "#001755",
      translate: { x: "726.752", y: "957.194" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-7",
      name: "4M",
      fill: "#001755",
      translate: { x: "743.094", y: "976.757" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "yola north",
    path: {
      id: "Path_6991",
      d: "M656.891,923.257l7.6-3.257,3.1,1.086,6.049,1.4,4.963,1.861c1.241,1.448,3.846,4.343,4.343,4.343s2.378,3.309,3.257,4.963v8.685l-5.428,2.947-6.2.776h-6.979l-5.738-1.551-4.032-1.551-4.343-2.481-3.877-1.241-3.1-2.481v-4.5l3.1-3.567,3.877-2.947Z",
      translate: { x: "71.722", y: "4.276" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Y.N-2",
      name: "Y.N",
      fill: "#001755",
      translate: { x: "741.752", y: "946.508" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-8",
      name: "4M",
      fill: "#001755",
      translate: { x: "725.474", y: "938.711" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Girei",
    path: {
      id: "Path_6992",
      d: "M626.62,803.768l3.877,5.118,2.792,1.4,2.481,4.343,3.722,4.808,2.792,3.1,8.065,7.755,14.113,4.032,4.808,4.5,2.636,5.273,10.081,5.118,6.359-1.241h7.91l5.273-2.171.775.775V840.99l-2.326-5.118-4.342-10.546,2.792-5.893,7.6-6.824,6.824-3.722c.1-2.378.248-7.134,0-7.134a51.669,51.669,0,0,1-4.5-4.5v-5.428l-2.792-1.551H704.01l-1.706,1.551-3.877,2.792c-.62.155-2.047.527-2.792.775-.931.31-2.637,1.861-3.257,1.861s-2.481,2.481-2.947,2.481a27.922,27.922,0,0,0-4.032,2.016l-3.412-2.016-4.653-2.481-2.792-2.637-5.273-2.792-3.722-2.326h-6.824l-4.342,2.326-5.118,2.792-3.1,3.877-5.738,2.016H627.861L626,799.736Z",
      translate: { x: "85.863", y: "94.298" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Girei-2",
      name: "Girei",
      fill: "#001755",
      translate: { x: "731.094", y: "903.211" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M",
      name: "5M",
      fill: "#001755",
      translate: { x: "744.752", y: "920.327" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "NUMAN",
    path: {
      id: "Path_6993",
      d: "M150.7,835.7l2.481,11.011,1.861-1.086,2.171-3.257,3.722-7.444,2.481-4.343,5.583-8.53,7.91-4.342,7.444-3.412,6.2-3.877,7.755-2.637,4.653-.31,7.444-1.086,5.118-1.241,4.187-1.551,1.086-.931-1.086-9.305-3.257-8.84,3.257-6.824,1.086-7.6-1.086-1.241L216.769,767l-5.893,1.861-8.53,2.326-6.514,2.171,1.706,4.343,1.086,5.118,1.086,5.428-2.171,2.637-7.755,1.551h-7.444l-3.722-.775h-5.583l-6.2-1.551-3.722.776-6.049.776H140.31l-4.653,3.1-7.6,2.171-6.2,3.567-8.065,3.1-7.289,4.653,4.5,2.016,9.461,2.636,7.6,3.1,10.546,7.755,8.685,6.049Z",
      translate: { x: "444.224", y: "109.818" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "NUMAN-2",
      name: "NUMAN",
      fill: "#001755",
      translate: { x: "590.322", y: "914.649" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-2",
      name: "5M",
      fill: "#001755",
      translate: { x: "587.471", y: "932.338" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Lamurde",
    path: {
      id: "Path_6994",
      d: "M57.15,767.009c2.947,2.326,9.088,7.2,10.081,8.065l.931-2.016,4.343-2.637,8.22-3.412,6.359-3.722,8.22-2.171,5.118-3.412,22.178-.775h4.808l7.6,1.706,2.637-.931,2.947.931h7.755l8.375-1.706,1.861-2.637v-4.808l-3.257-9.616h-5.738l-5.428-2.947-6.514-3.412-9.15-4.963L116.085,718l-2.637,1.861-5.893,4.5L96.7,733.509l-9.616,3.412-6.359-1.551-9.461,1.551-12.252,4.808L48,746.071l4.963,9.305Z",
      translate: { x: "484.578", y: "143.62" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Lamurde-2",
      name: "Lamurde",
      fill: "#001755",
      translate: { x: "573.971", y: "884.57" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-3",
      name: "5M",
      fill: "#001755",
      translate: { x: "548.397", y: "900.431" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Guyuk",
    path: {
      id: "Path_6995",
      d: "M284.44,596.067,268,608.784l12.1,10.081,21.558,11.322H306a4.081,4.081,0,0,0,1.241-.62l7.444-2.326,14.268-3.722-2.016-3.722-3.722-2.326L318.87,612.2V592.189l-1.4-13.028v-9.616L318.87,559c-1.6.362-4.963,1.086-5.583,1.086S306.1,559.362,302.9,559l-9.461,1.086-4.343,3.567-.775,5.893c.258,2.843.775,8.747.775,9.616a26.888,26.888,0,0,1-1.706,5.273Z",
      translate: { x: "332.818", y: "253.301" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Guyuk-2",
      name: "Guyuk",
      fill: "#001755",
      translate: { x: "621.938", y: "833.897" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M",
      name: "3M",
      fill: "#001755",
      translate: { x: "620.971", y: "868.793" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Shelleng",
    path: {
      id: "Path_6996",
      d: "M442.974,407.883l-13.648,6.824-.62.465L427,426.184v8.685l1.706,13.493v20.162l4.187,5.118,3.877,2.171.931,2.637h6.359l14.889-2.016H487.02l-3.722-3.257-4.343-7.755-2.326-5.893V448.362l2.326-7.444-2.326-5.273,2.326-6.2L481.9,422l1.4-10.236v-6.2l5.428-9.305v-7.289l2.637-4.808,1.861-5.428,6.669-7.444,2.792-6.514V350.5h-2.792l-6.669,1.706-4.5,4.343-4.187,8.22-10.081,13.958h-4.963l-6.359,6.514-3.1,9-5.118,8.53Z",
      translate: { x: "223.137", y: "397.129" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Shelleng-2",
      name: "Shelleng",
      fill: "#001755",
      translate: { x: "661.5", y: "826.897" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M",
      name: "6M",
      fill: "#001755",
      translate: { x: "665", y: "870.872" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Gombi",
    path: {
      id: "Path_6997",
      d: "M643.326,300.56l-15.2,4.032-1.241,13.338-2.326,6.514-7.289,7.91-4.032,10.391v6.2L607.5,359.029l5.738,4.343h7.134l1.706-1.706,9.616-1.086,6.979-1.551h17.37l7.754,1.551,3.1,2.792,1.861-1.706,2.016-2.637,4.808,2.637,4.5-1.086h12.252l9.926,9.616h2.947l11.011,5.893,6.048,1.241,2.792-3.567,5.118-1.706,6.2-8.685,1.706-4.343-3.1-2.326-4.808-3.1-5.118-3.257-4.5-1.4v-4.343l-4.343-5.583L711.1,334.68l-3.1-5.893-2.792-7.289-2.947-8.685-2.481-4.343-5.273-4.5-2.171-3.412,1.086-3.877-1.086-5.428L689.7,283.5h-6.669l-6.514,2.637-9.616,5.118-6.979,4.808Z",
      translate: { x: "98.625", y: "443.347" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Gombi-2",
      name: "Gombi",
      fill: "#001755",
      translate: { x: "737.918", y: "762.09" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_9M",
      name: "9M",
      fill: "#001755",
      translate: { x: "748.474", y: "781.652" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Song",
    path: {
      id: "Path_6998",
      d: "M591.083,521.882,585.5,534.6l2.792,4.963-2.171,7.289v11.787l6.359,13.183,4.963,3.722-.62,14.113,1.551,5.738,1.4.931h12.717l6.049-2.171,2.792-3.722,9.461-5.118h6.979l9,5.118,2.481,2.637,8.22,4.5,4.343-2.016,2.326-2.481,3.1-1.551,3.1-1.086,5.893-4.343h1.4l2.947,1.861,2.326-6.824V565.772l4.5-6.669,3.722-1.551,5.738,5.118,6.2,4.032,16.129,12.1,11.477,4.963h12.252l9.15-2.792,12.717,2.792,6.2,4.963.465-.62.931-5.118V568.254l-2.792-5.893-3.257-6.669v-6.359l-1.861-4.963v-8.375l-1.241-6.514-2.326-5.893v-8.53l2.326-10.856,1.241-9.305-3.567-1.4-7.134,1.4H735.007l-3.412,2.171-4.343,5.118L722.91,504.2,715,516.608l-4.808,2.171-3.257,3.1h-5.273L690.5,515.058h-3.1L677,505.907H663.976l-3.877,1.241-5.118-2.947-3.412,4.343-3.1-4.343h-25.59l-15.819,2.947-1.861,1.4h-6.979l-5.738-4.343v2.947Z",
      translate: { x: "113.801", y: "298.484" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_8M",
      name: "8M",
      fill: "#001755",
      translate: { x: "763.752", y: "858.62" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "Song-2",
      name: "Song",
      fill: "#001755",
      translate: { x: "763.752", y: "837.288" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Maiha",
    path: {
      id: "Path_6999",
      d: "M1167.319,542.262l-3.567-.775-1.086-3.412-.931-1.241,1.241-5.738V516.827l-6.359-12.873.62-6.2-2.326-4.963v-8.375l-1.086-6.669-2.326-5.893V462.7l1.241-5.273,2.326-14.268.776-2.016,2.326-4.808,8.065-10.7,8.685-7.134,7.444,2.947,4.343,5.738,4.653,6.359,3.412.775,4.342,6.824,4.5,4.808-4.5,6.514,2.326,6.359-2.326,6.979-6.669,11.322,4.963,12.562-1.551,13.648-6.2,7.134-1.706,5.583,1.706,7.444-6.049,11.787-11.011,6.2Z",
      translate: { x: "-276.637", y: "350.221" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Maiha-2",
      name: "Maiha",
      fill: "#001755",
      translate: { x: "882.772", y: "833.432" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-4",
      name: "5M",
      fill: "#001755",
      translate: { x: "887.271", y: "853.191" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Hong",
    path: {
      id: "Path_7000",
      d: "M881.34,224.511,872.5,234.9l4.032,13.183-1.241,3.877,2.326,4.032,4.808,4.187,5.583,13.183,5.893,13.028,4.653,3.722,4.653,6.359.465,4.342,4.187,1.086,7.755,5.273,5.738,3.567,3.877-1.551,2.947-3.722,2.016-2.016,2.947-1.861,18.766.31,6.824-1.551,3.1,1.241a6.165,6.165,0,0,1,1.4-.62c.5-.124.723-1.913.775-2.792l4.343-6.514,2.016-2.947,3.412-4.5,8.84-7.134,2.016-2.171,4.5-4.653,2.016-4.963,1.086-3.877v-4.187l.775-5.273,1.241-7.134-1.241-5.583-.775-4.343-1.086-6.824-2.016.62c-1.449,1.189-4.5,3.567-5.118,3.567s-7.081,2.585-10.236,3.877l-8.685,1.4L958.42,230.4l-7.755-7.444-16.44-5.583-15.044-3.1-15.664-.775-12.562,2.637Z",
      translate: { x: "-84.178", y: "491.634" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Hong-2",
      name: "Hong",
      fill: "#001755",
      translate: { x: "817.324", y: "738.517" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_7M",
      name: "7M",
      fill: "#001755",
      translate: { x: "836.324", y: "760.955" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Mubi_North",
    path: {
      id: "Path_7001",
      dataName: "Mubi North",
      d: "M1239.632,251.4l-3.567,1.861,3.567,16.129-2.326,12.872v4.187l-3.567,9.926L1228,302.265l8.065,3.257,8.22,12.407h1.4l1.706-3.1,2.326-5.583,1.861-5.118,1.086-5.893,2.481-6.669,1.241-3.1,4.343-3.257,3.877-1.086h7.289l4.653-.931h8.065l6.669.931,5.428,2.326,4.343-4.187,4.653-5.273,4.343-7.6,2.481-4.653-.776-8.685-1.706-3.722-5.893-.931h-8.53l-23.729.931-2.636-.931h-11.787l-4.808-1.4-2.947,1.4Z",
      translate: { x: "-329.408", y: "466.455" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_6M-2",
      name: "6M",
      fill: "#001755",
      translate: { x: "936.888", y: "737.847" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "Mubi_North-2",
      name: "Mubi North",
      fill: "#001755",
      translate: { x: "982.5", y: "739.934" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Michika",
    path: {
      id: "Path_7002",
      d: "M1267,145.794v11.942h9.771l2.637-1.861,4.808,1.861h11.942l2.481.775,19.386-.775,12.563-.775,6.2,1.861,1.706-4.5-2.637-8.53,2.637-3.722,1.086-10.7,5.428-10.7-4.032-2.016-3.722-1.706h-4.187l-1.086,3.722h-8.84l-6.2-1.086-3.412-2.637-5.893-1.241h-8.065L1287.937,114c-1.241,1.809-3.877,5.676-4.5,6.669-.776,1.241-6.359,5.738-6.669,6.2-.248.372-4.653,6.772-6.824,9.926Z",
      translate: { x: "-356.312", y: "560.271" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Michika-2",
      name: "Michika",
      fill: "#001755",
      translate: { x: "872.068", y: "687.027" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_7M-2",
      name: "7M",
      fill: "#001755",
      translate: { x: "933.957", y: "707.633" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Mubi_South",
    path: {
      id: "Path_7003",
      dataName: "Mubi South",
      d: "M1301.784,400.34l-6.2,3.567-4.187-5.118-4.032-6.2-1.861-.465,5.583-13.8,1.241-5.738,3.412-9.461,4.653-3.567,3.567-.775,9.15-.775h17.215l5.739,2.637.93.775-1.861,5.893-2.947,6.2-1.861,5.583L1326.6,387l-4.653,4.343-6.979,4.808-8.064,2.637Z",
      translate: { x: "-369.072", y: "391.955" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Mubi_South-2",
      name: "Mubi South",
      fill: "#001755",
      translate: { x: "942.957", y: "799.593" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-5",
      name: "5M",
      fill: "#001755",
      translate: { x: "924.957", y: "779.771" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "MADAGALI",
    path: {
      id: "Path_7004",
      d: "M1331,24.729l1.241,11.787h1.861l10.081.931h6.514l6.514.931,4.032,3.567h14.578l1.551-3.567,5.273,1.4,6.979,2.171,1.551-3.567,3.257-10.391,5.738-9.15,5.893-8.53L1407.46,1h-9.15l-20.937,2.792c-8.53,1.086-25.838,3.288-26.831,3.412s-12.615,2.12-18.3,3.1L1331,15.578Z",
      translate: { x: "-400.46", y: "638.221" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "MADAGALI-2",
      name: "MADAGALI",
      fill: "#001755",
      translate: { x: "1028", y: "656" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_8M-2",
      name: "8M",
      fill: "#001755",
      translate: { x: "943.568", y: "666.244" },
      tspan: { X: "0", y: "0" },
    },
  },
];
