export const data = [
  {
    state: "YUNUSARI",
    path: {
      id: "Path_7532",
      d: "M613.318,3.51,563.771,0V10.529l6.712,26.907V50.3l17.9,14.428L599.253,78.38v9.749L616.2,93.2h45.392l18.861,10.918,1.279,12.478-4.156,7.02,2.877,4.289,46.99-13.649-1.918-8.189,20.459-3.51,13.425,3.51h48.589l13.106-5.849,41.237-12.868-1.6-19.5H847.951l-10.23-1.95-9.27,1.95-5.434-7.8H808.952L784.977,65.9l-13.426-5.849L723.6,30.806l-22.057-5.459L673.1,20.277Z",
      translate: { x: "-132.867" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "YUNUFARI-2",
      name: "YUNUFARI",
      fill: "#001755",
      translate: { x: "512.097", y: "58.259" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M",
      name: "4M",
      fill: "#001755",
      translate: { x: "523.562", y: "73.546" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "GEIDAM",
    path: {
      id: "Path_7533",
      d: "M697.6,236.728l27.81-25.736V195l-13.745-40.165,47.309-12.868-2.237-8.579,20.138-3.51,14.7,3.51h47.309L852,127.152l41.876-12.869-12.147,23.4v11.7l33.245,33.536v11.7l-9.59,10.529-14.7,21.447-35.163,19.888-16.623,14.038-39.957,3.51h-19.18l-72.563-3.51-9.589-9.359Z",
      translate: { x: "-164.408", y: "-26.934" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "GEIDAM-2",
      name: "GEIDAM",
      fill: "#001755",
      translate: { x: "613.752", y: "155.329" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-2",
      name: "4M",
      fill: "#001755",
      translate: { x: "613.752", y: "185.138" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "TARMUWA",
    path: {
      id: "Path_7534",
      d: "M662.052,287.651l2.557-15.209H638.077l-45.392,6.239L556.243,293.5l24.295,45.234h17.581l7.992,9.358V366.03l9.909,14.038h20.138l23.655,17.158,18.54-7.8,7.672-3.12,16.3,3.12,6.712,4.679,19.18-1.95,25.253-9.359,12.467,3.51,52.745-6.239L832.751,328.6l3.516-10.529-10.229-21.058L822.2,282.971l-17.581,14.038-46.991,3.9-82.792-3.9Z",
      translate: { x: "-131.093", y: "-64.208" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "TARMUWA-2",
      name: "TARMUWA",
      fill: "#001755",
      translate: { x: "530.44", y: "271.506" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M",
      name: "3M",
      fill: "#001755",
      translate: { x: "532.733", y: "293.672" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "DAMATURU",
    path: {
      id: "Path_7535",
      d: "M773.41,510.2l-54.023,19.888V499.285l1.918-23.007-5.115-10.529-7.672-18.718-18.86-16.768,22.376-8.969h12.147l8.311,1.56,4.795,2.729,2.238,1.95,19.5-1.95,25.573-8.969,11.508,2.729,23.336-2.729,30.688-2.34,1.918,45.625-11.508,14.428-12.147,26.517Z",
      translate: { x: "-162.536", y: "-97.635" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "DAMATURU-2",
      name: "DAMATURU",
      fill: "#001755",
      translate: { x: "581.651", y: "369.34" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-2",
      name: "3M",
      fill: "#001755",
      translate: { x: "580.122", y: "382.333" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "GUJBA",
    path: {
      id: "Path_7536",
      d: "M786.2,660.362l-11.188,4.289-19.82-11.7-10.548-10.139-6.073,10.139-10.23-6.629-12.147-12.869-13.746-3.9-54.662-6.239,1.279-7.02-9.59-21.447,1.279-3.12,34.2-3.9L690.3,576.912l17.262-20.278,53.7-18.718,56.26-9.359h24.294l11.508,12.868,6.073,18.718L826.472,585.1l-3.2,18.328L798.342,616.3l3.836,17.158,9.909,21.448-4.156,5.46Z",
      translate: { x: "-150.707", y: "-124.568" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "GUJBA-2",
      name: "GUJBA",
      fill: "#001755",
      translate: { x: "592.333", y: "465.384" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M",
      name: "5M",
      fill: "#001755",
      translate: { x: "596.814", y: "481.762" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "GULANI",
    path: {
      id: "Path_7537",
      d: "M642.141,717.237l-19.819-23.786,18.86-27.3,2.877-12.089,54.343,5.46,14.065,4.289,11.188,11.309,10.868,7.019,5.755-9.749,11.188,9.749,20.458,11.309-8.631,8.579-17.9,11.7c-14.278,3.509-43.026,10.529-43.793,10.529s-9.91,3.9-14.385,5.849H675.706l-7.672,5.069v3.51h4.795v4.679l2.877,1.95,7.672-1.95,9.271,4.289,5.754,4.289-5.754,8.58,3.516,4.289,10.869,10.139v5.069l-7.352,2.729-9.271,6.63-15.984,4.289-24.933,5.069-6.074,4.289-4.156,1.17-2.557-5.459,4.475-15.988-4.475-7.8,2.557-8.189,2.877-6.239V717.237Z",
      translate: { x: "-146.666", y: "-154.147" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "GULANI-2",
      name: "GULANI",
      fill: "#001755",
      translate: { x: "507.511", y: "539.784" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "513.058", y: "561.313" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "yusufari",
    path: {
      id: "Path_7538",
      d: "M264.192,21.568,211.447,24.3,204.1,72.652,217.52,60.563l9.59-6.239h26.851l10.23,6.239,9.59,4.679L290.4,82.4h16.943l8.631.78L329.4,82.4l3.836-3.12-12.467-3.509-3.2-3.12,3.2-5.849,15.344-9.749h24.294l12.787,3.51,2.877,8.189L369.36,79.281l3.836,6.63,31.327,3.119,12.147,2.34,5.114,18.328,24.295-11.308,47.949-2.34,9.271-7.02h10.868V79.281L503.3,65.243,486.037,50.425V37.946l-3.2-11.7L479.324,10.26V.511H376.073L319.493,3.63Z",
      translate: { x: "-48.1", y: "-0.12" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "YUNUSARI-2",
      name: "YUNUSARI",
      fill: "#001755",
      translate: { x: "315.665", y: "35.33" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-2",
      name: "5M",
      fill: "#001755",
      translate: { x: "350.824", y: "55.202" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "NGURU",
    path: {
      id: "Path_7539",
      d: "M219.023,74.6,205.6,89.024V93.7l7.991,18.328v13.258l-7.033,7.8v5.46l9.909.39,3.517-1.169,2.557-2.34h21.417c4.688,2.47,14.9,7.721,18.221,8.969h9.27v-3.12l19.5,1.56.958-3.9v-5.849l-.958-3.119L275.6,114.37v-4.679l13.106-9.359,2.238-3.119L273.686,80.835l-18.221-9.749H226.7Z",
      translate: { x: "-48.454", y: "-16.753" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "NGURU-2",
      name: "NGURU",
      fill: "#001755",
      translate: { x: "173.501", y: "81.189" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-3",
      name: "3M",
      fill: "#001755",
      translate: { x: "180.38", y: "98.004" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "MACHINA",
    path: {
      id: "Path_7540",
      d: "M55.3,135.509H0L42.515,98.853,55.3,81.306,86.629,55.959,112.2,43.87l35.163-13.258h15.344l-6.393,50.694h-8.951L129.143,92.224,86.629,98.853,71.285,112.112Z",
      translate: { x: "0", y: "-7.214" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "MACHINA-2",
      name: "MACHINA",
      fill: "#001755",
      translate: { x: "74.903", y: "65.903" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M",
      name: "2M",
      fill: "#001755",
      translate: { x: "74.903", y: "79.661" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "KARASUWA",
    path: {
      id: "Path_7541",
      d: "M440.15,133.491l4.475-6.629-6.713-17.548-43.155-6.239-3.2-6.629,6.073-12.478-2.877-6.239L386.446,75H357.038l-13.745,8.969-3.836,6.239L354.8,96.446l-3.2,3.12H312.925l-15.984,12.868v4.679l10.23,9.749,5.754,6.629v7.8l-2.877,3.12,2.877,2.34,15.024-5.46,8.31-4.679h26.213v-3.12Z",
      translate: { x: "-69.982", y: "-17.675" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "KARASUWA-2",
      name: "KARASUWA",
      fill: "#001755",
      translate: { x: "256.048", y: "91.89" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-2",
      name: "2M",
      fill: "#001755",
      translate: { x: "274.392", y: "104.119" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "JAKUSKO",
    path: {
      id: "Path_7542",
      d: "M308.907,167.26l7.132-6.485,15.847-12.969,24.96-3.242,59.824-19.917H453.12v6.948l6.735,7.874-3.566,5.095-13.866,22.7,10.7,20.843,21,29.644h32.091l24.168,31.033L494.72,284.908l-15.452,28.254L456.289,330.3l-13.866,7.411-29.318,20.38-28.129,27.791-10.7-24.085V330.3l-5.547-10.653-9.508-26.4-5.547-56.972-2.773-8.8L326.34,212.188H308.907V201.535l3.962-8.337-3.962-5.095Z",
      translate: { x: "-72.802", y: "-29.376" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "JAKUSKO-2",
      name: "JAKUSKO",
      fill: "#001755",
      translate: { x: "301.08", y: "195.511" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-3",
      name: "4M",
      fill: "#001755",
      translate: { x: "311.382", y: "219.596" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "BADE",
    path: {
      id: "Path_7543",
      d: "M506.329,213.514l-2.877-5.849L476.92,187.777v-8.189l-13.107,24.177,7.672,15.6,5.434,8.969,12.467,17.938h25.892v-7.409l-8.95-13.649Z",
      translate: { x: "-109.309", y: "-42.325" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "BADE-2",
      name: "BADE",
      fill: "#001755",
      translate: { x: "368.404", y: "176.73" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-3",
      name: "2M",
      fill: "#001755",
      translate: { x: "372.99", y: "187.431" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "BURSARI",
    path: {
      id: "Path_7544",
      d: "M595.3,120.763l-17.581-5.46h-9.909l-8.631,7.8h-8.311l-41.556,3.12-22.376,9.358-4.475,8.579,2.557,6.239-2.557,2.34h-7.353v4.289l5.115,7.409v8.969l26.213,19.5,2.877,5.849v12.089l8.631,12.478v8.189l18.86,24.957,36.761-14.038,45.711-6.63h27.172L673.3,210.841V195.634l-13.106-38.605-3.517-6.63,3.517-6.239V131.681l-19.18-10.918Z",
      translate: { x: "-111.971", y: "-27.174" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "BURSARI-2",
      name: "BURSARI",
      fill: "#001755",
      translate: { x: "441.779", y: "156.093" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-4",
      name: "3M",
      fill: "#001755",
      translate: { x: "441.779", y: "174.437" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "NANGERE",
    path: {
      id: "Path_7545",
      dataName: "NANG ERE",
      d: "M398.571,444.019l3.517-9.359,23.335-22.227L444.6,398l16.623-9.749-7.992,24.177,2.557,6.239,7.992,13.259,16.3,12.089,10.868,7.8-13.746,7.8-15.983,9.749-5.434,16.768,5.434,3.9v5.849l-25.253,23.007h-3.516L429.9,490.032l-14.7-26.906-2.877-7.019-4.156-4.289Z",
      translate: { x: "-93.933", y: "-91.502" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "NANG_ERE-2",
      name: "NANG\n          ERE",
      fill: "#001755",
      translate: { x: "327.654", y: "353.077" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.5M",
      name: "3.5M",
      fill: "#001755",
      translate: { x: "331.475", y: "382.333" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "POTISKUM",
    path: {
      id: "Path_7546",
      dataName: "POTIS KUM",
      d: "M529.715,466.315l-22.7,4.289-29.728,17.158-5.754,16.769,5.754,3.9v6.239l-10.549,9.358h32.926l11.827-3.9,12.467-15.6,13.106,1.95,5.755-4.289,1.278-35.876Z",
      translate: { x: "-110", y: "-109.899" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "POTIS_KUM-2",
      name: "POTIS\n          KUM",
      fill: "#001755",
      translate: { x: "382.635", y: "381.934" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-3",
      name: "5M",
      fill: "#001755",
      translate: { x: "378.049", y: "408.154" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "FIKA",
    path: {
      id: "Path_7547",
      d: "M439,549.07l15.344-13.648H487.27l11.188-3.509,12.147-15.6,13.746,1.95,4.475,5.849,4.475,11.308-2.237,19.888,1.278,4.679,2.557,7.02,10.549,16.378,11.508,6.239,30.368-1.56-.959,3.12,2.557,5.849,6.713,15.987-.959,8.579-2.238,12.088-19.5,27.3-9.589-7.02-17.9-19.107-13.107-21.838-14.065,1.95-13.745-3.509-15.344-4.68-15.024-7.8h-7.672l-22.376,5.07-23.335-9.359-7.992-7.409,18.54-14.428,4.156-12.089-2.557-5.46,2.557-6.239Z",
      translate: { x: "-97.284", y: "-121.683" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "FIKA-2",
      name: "FIKA",
      fill: "#001755",
      translate: { x: "371.935", y: "440.161" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4.5M",
      name: "4.5M",
      fill: "#001755",
      translate: { x: "390.944", y: "460.705" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "FUNE",
    path: {
      id: "Path_7548",
      d: "M559.814,345.857c-7.352-14.428-22.376-43.6-23.655-44.844L508.669,328.7l-13.746,26.517-16.942,12.478-8.311,23.4,11.188,19.888,27.811,21.057,21.1-3.9h15.024l-1.918,34.316-4.795,5.849,2.877,5.46,3.836,7.409v23.4l3.835,11.7,10.549,15.6,11.508,6.239h29.089l33.884-3.509,16.623-11.7,17.582-18.328V473.762l1.918-24.957-6.394-10.919-6.073-17.547L640.05,405.13l-24.614-19.107h-21.1l-8.631-12.478V355.217l-7.992-9.359Z",
      translate: { x: "-110.69", y: "-70.941" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "FUNE-2",
      name: "FUNE",
      fill: "#001755",
      translate: { x: "474.645", y: "394.563" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-2",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "473.116", y: "424.371" },
      tspan: { X: "0", y: "0" },
    },
  },
];
