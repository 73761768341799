import { useState } from "react";
import Layout from "../../component/Layout";
import StateMap from "../../component/Maps/StateMap";
import TopCard from "../../component/TopCards";
import TopNav from "../../component/TopNav";
import "./style.css";
import StateImg from "../../assets/svg/stateimg.svg";
import Buildimg from "../../assets/svg/buildimg.svg";
import { color } from "../../constant/color";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import KogiState from "../../component/Maps/LGAState/Kogi";
import axios from "../../utils/axios";
import moment from "moment";
import OndoState from "../../component/Maps/LGAState/Ondo";
import Jigawa from "../../component/Maps/LGAState/Jigawa";
import Gombe from "../../component/Maps/LGAState/Gombe";
import Borno from "../../component/Maps/LGAState/Borno";
import Anambra from "../../component/Maps/LGAState/Anambra";
import Plateau from "../../component/Maps/LGAState/Plateau";
import Ogun from "../../component/Maps/LGAState/Ogun";
import Ebonyi from "../../component/Maps/LGAState/Ebonyi";
import Benue from "../../component/Maps/LGAState/Benue";
import Akwaibom from "../../component/Maps/LGAState/Akwaibom";
import Oyo from "../../component/Maps/LGAState/Oyo";
import Niger from "../../component/Maps/LGAState/Niger";
import Kebi from "../../component/Maps/LGAState/Kebbi";
import Enugu from "../../component/Maps/LGAState/Enugu";
import Delta from "../../component/Maps/LGAState/Delta";
import Bayelsa from "../../component/Maps/LGAState/Bayelsa";
import Adamawa from "../../component/Maps/LGAState/Adamawa";
import Sokoto from "../../component/Maps/LGAState/Sokoto";
import Osun from "../../component/Maps/LGAState/Osun";
import Imo from "../../component/Maps/LGAState/Imo";
import Ekiti from "../../component/Maps/LGAState/Ekiti";
import Bauchi from "../../component/Maps/LGAState/Bauchi";
import Abia from "../../component/Maps/LGAState/Abia";
import Skimmer from "../../component/Skimmer";
import toast from "react-hot-toast";
import { BsArrowsFullscreen } from "react-icons/bs";
import LoaderIcon from "../../component/LoaderIcon";
import { data as bayelsa } from "../../component/Maps/LGAState/Bayelsa/data";
import { numFormtter } from "../../helperFunctions/numFormatter";
import { PanZoom } from "react-easy-panzoom";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Zamfara from "../../component/Maps/LGAState/Zamfara";
import Yobe from "../../component/Maps/LGAState/Yobe";
import Lagos from "../../component/Maps/LGAState/Lagos";
import Taraba from "../../component/Maps/LGAState/Taraba";
import Abuja from "../../component/Maps/LGAState/Abuja";
import { percentageDiffCalc } from "../../helperFunctions/percentageDiffCalc";
import Edo from "../../component/Maps/LGAState/Edo";
import Kastina from "../../component/Maps/LGAState/Kastina";
import CrossRivers from "../../component/Maps/LGAState/CrossRivers";
import Kwara from "../../component/Maps/LGAState/Kwara";
import Kaduna from "../../component/Maps/LGAState/Kaduna";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import DoughnutChart from "../../component/Charts/DoughtnutChart";
import Kano from "../../component/Maps/LGAState/Kano";
import { Select } from "antd";

const LGA = () => {
  const [tabIndex, setTabIndex] = useState(1);
  const { country, state } = useParams();

  const [date, setDate] = useState(moment(Date.now()).format("YYYY-MM-DD"));
  const [data, setData] = useState([]);
  const Option = Select;

  const [loading, setLoading] = useState(true);
  const [pmsData, setPmsData] = useState([]);
  const [lpgData, setLpgData] = useState([]);
  const [dpkData, setDpkData] = useState([]);
  const [agoData, setAgoData] = useState([]);
  const [pmsPrice, setPmsPrice] = useState("");
  const [agoPrice, setAgoPrice] = useState("");
  const [dpkPrice, setDpkPrice] = useState("");
  const [lpgPrice, setLpgPrice] = useState("");
  const [topLga, setTopLga] = useState([]);
  const [topCompany, setTopCompany] = useState([]);

  // Price Instance
  const [pmsVol, setPmsVol] = useState(0);
  const [agoVol, setAgoVol] = useState(0);
  const [dpkVol, setDpkVol] = useState(0);
  const [lpgVol, setLpgVol] = useState(0);
  // PMS Chart
  const [pmsChart, setPmsChart] = useState([]);
  const [agoChart, setAgoChart] = useState([]);
  const [dpkChart, setDpkChart] = useState([]);
  const [lpgChart, setlpgChart] = useState([]);
  // Tab loader
  const [tabLoader, setTabLoader] = useState({
    pms: false,
    ago: false,
    lpg: false,
    dpk: false,
  });

  const [pmsLoader, setPmsLoader] = useState(true);
  // state product
  const [pmsLga, setPmsLga] = useState([]);
  const [agoLga, setAgoLga] = useState([]);
  const [dpKLga, setDpKLga] = useState([]);
  const [lpgLga, setLpgLga] = useState([]);

  // Percentages
  const [pmsPer, setPmsPer] = useState(0);
  const [agoPer, setAgoPer] = useState(0);
  const [dpkPer, setDpkPer] = useState(0);
  const [lpgPer, setlpgPer] = useState(0);

  const [refresh, setRefresh] = useState(false);

  // Refresh LGA
  const [lgaRefresh, setLgaRefresh] = useState(false);
  const [charttype, setChartType] = useState("agol");

  // Charts Call
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.get(
          `/Aggregated/StateSalesSummary/${country}/${state}?date=${date}`
        );

        //  pms percentage
        let cdvp = parseInt(
          res.data
            .filter((item) => item.product === "PMS")
            .find(
              (item) =>
                moment(item.date).format("YYYY-MM-DD") ===
                moment(date).format("YYYY-MM-DD")
            ).volume
        );
        let pdvp = parseInt(
          res.data
            .filter((item) => item.product === "PMS")
            .find(
              (item) =>
                moment(item.date).format("YYYY-MM-DD") ===
                moment(date).subtract(1, "days").format("YYYY-MM-DD")
            ).volume
        );

        //  ago percentage
        let cdva = parseInt(
          res.data
            .filter((item) => item.product === "AGO")
            .find(
              (item) =>
                moment(item.date).format("YYYY-MM-DD") ===
                moment(date).format("YYYY-MM-DD")
            ).volume
        );
        let pdva = parseInt(
          res.data
            .filter((item) => item.product === "AGO")
            .find(
              (item) =>
                moment(item.date).format("YYYY-MM-DD") ===
                moment(date).subtract(1, "days").format("YYYY-MM-DD")
            ).volume
        );

        //  LPG percentage
        let cdvl = parseInt(
          res.data
            .filter((item) => item.product === "LPG")
            .find(
              (item) =>
                moment(item.date).format("YYYY-MM-DD") ===
                moment(date).format("YYYY-MM-DD")
            ).volume
        );
        let pdvl = parseInt(
          res.data
            .filter((item) => item.product === "LPG")
            .find(
              (item) =>
                moment(item.date).format("YYYY-MM-DD") ===
                moment(date).subtract(1, "days").format("YYYY-MM-DD")
            ).volume
        );

        //  DPK percentage
        let cdvd = parseInt(
          res.data
            .filter((item) => item.product === "DPK")
            .find(
              (item) =>
                moment(item.date).format("YYYY-MM-DD") ===
                moment(date).format("YYYY-MM-DD")
            ).volume
        );
        let pdvd = parseInt(
          res.data
            .filter((item) => item.product === "DPK")
            .find(
              (item) =>
                moment(item.date).format("YYYY-MM-DD") ===
                moment(date).subtract(1, "days").format("YYYY-MM-DD")
            ).volume
        );

        setPmsPer((prev) => percentageDiffCalc(cdvp, pdvp));
        setAgoPer((prev) => percentageDiffCalc(cdva, pdva));
        setlpgPer((prev) => percentageDiffCalc(cdvl, pdvl));
        setDpkPer((prev) => percentageDiffCalc(cdvd, pdvd));

        // PMs chart
        setPmsChart((prev) =>
          res.data
            .filter((item) => item.product === "PMS")
            .map((item) => {
              return {
                volume: item.volume,
                date: moment(item.date).format("YYYY-MM-DD"),
              };
            })
        );
        // Ago chart
        setAgoChart((prev) =>
          res.data
            .filter((item) => item.product === "AGO")
            .map((item) => {
              return {
                volume: item.volume,
                date: moment(item.date).format("YYYY-MM-DD"),
              };
            })
        );
        // DPk chart
        setDpkChart((prev) =>
          res.data
            .filter((item) => item.product === "DPK")
            .map((item) => {
              return {
                volume: item.volume,
                date: moment(item.date).format("YYYY-MM-DD"),
              };
            })
        );
        // LPG chart
        setlpgChart((prev) =>
          res.data
            .filter((item) => item.product === "LPG")
            .map((item) => {
              return {
                volume: item.volume,
                date: moment(item.date).format("YYYY-MM-DD"),
              };
            })
        );

        setPmsVol((prev) =>
          res.data
            .filter((item) => item.product === "PMS")
            .find((item) => moment(item.date).format("YYYY-MM-DD") === date)
            .volume.toLocaleString("en-US", { currency: "USD" })
        );

        setAgoVol((prev) =>
          res.data
            .filter((item) => item.product === "AGO")
            .find((item) => moment(item.date).format("YYYY-MM-DD") === date)
            .volume.toLocaleString("en-US", { currency: "USD" })
        );

        setDpkVol((prev) =>
          res.data
            .filter((item) => item.product === "DPK")
            .find((item) => moment(item.date).format("YYYY-MM-DD") === date)
            .volume.toLocaleString("en-US", { currency: "USD" })
        );
        setLpgVol((prev) =>
          res.data
            .filter((item) => item.product === "LPG")
            .find((item) => moment(item.date).format("YYYY-MM-DD") === date)
            .volume.toLocaleString("en-US", { currency: "USD" })
        );

        setLoading((prev) => false);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, [date]);

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.get(
          `/Aggregated/StateLGASalesSummary/${country}/${
            state[0].toUpperCase() + state.substring(1)
          }?date=${date}`
        );

        let responseData =
          charttype == "vol"
            ? res.data.map((item) => {
                return {
                  amount: item.amount,
                  lga: item.lga,
                  product: item.product,
                  state: item.state,
                  threasholdStatus: item.volThreasholdStatus,
                  volume: item.volume,
                };
              })
            : res.data;

        // pms amount
        setPmsData((prev) =>
          responseData
            .filter((item) => item.product === "PMS")
            .map((item) => item.volume)
        );

        // setTabLoader({ pms: true });
        setData((prev) => responseData);

        setTabLoader({ pms: false });

        // setData((prev) => test);
        // setPmsLoader((prev) => false);
        // ago amount
        setAgoData((prev) =>
          responseData
            .filter((item) => item.product === "AGO")
            .map((item) => item.amount)
        );

        // dpk amount
        setDpkData((prev) =>
          responseData
            .filter((item) => item.product === "DPK")
            .map((item) => item.amount)
        );

        // lpg amount
        setLpgData((prev) =>
          responseData
            .filter((item) => item.product === "LPG")
            .map((item) => item.amount)
        );

        // pms price
        setPmsPrice((prev) =>
          responseData
            .filter((item) => item.product === "PMS")
            .reduce((prev, cur) => prev.amount + cur.amount, 0)
            .toLocaleString("en-US", { currency: "USD" })
        );

        // Ago Price
        setAgoPrice((prev) =>
          responseData
            .filter((item) => item.product === "AGO")
            .reduce((prev, cur) => prev.amount + cur.amount, 0)
            .toLocaleString("en-US", { currency: "USD" })
        );

        // DPk Price
        setDpkPrice((prev) =>
          responseData
            .filter((item) => item.product === "DPK")
            .reduce((prev, cur) => prev.amount + cur.amount, 0)
            .toLocaleString("en-US", { currency: "USD" })
        );

        // Lpg Price
        setLpgPrice((prev) =>
          responseData
            .filter((item) => item.product === "LPG")
            .reduce((prev, cur) => prev.amount + cur.amount, 0)
            .toLocaleString("en-US", { currency: "USD" })
        );
        setLoading((prev) => false);
      } catch (err) {
        toast.err(err.message);
      }
    })();
  }, [date, charttype]);

  // stats
  useEffect(() => {
    (async () => {
      try {
        const res = await Promise.all([
          axios.get(
            `/Aggregated/StateTopCompanySalesSummary/${country}/${
              state[0].toUpperCase() + state.substring(1)
            }?count=3&date=${date}`
          ),
          axios.get(
            `/Aggregated/StateTopLGASalesSummary/${country}/${
              state[0].toUpperCase() + state.substring(1)
            }?count=3&date=${date}`
          ),
        ]);
        setTopLga((prev) => [...res[1].data]);
        setTopCompany((prev) => [...res[0].data]);

        setLoading((prev) => false);
      } catch (err) {
        toast.err(err.message);
      }
    })();
  }, [date]);

  const [association, setAssociation] = useState([]);
  const [classification, setClassification] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.get(
          `/Aggregated/AssocitedStateSalesSummary/${country}/${state}`
        );

        setAssociation((prev) => res.data);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, [date]);

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.get(
          `/Aggregated/ClassifiedStateSalesSummary/${country}/${state}`
        );
        setClassification((prev) => res.data);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, [date]);

  const handlePMS = useFullScreenHandle();
  const handleAGO = useFullScreenHandle();
  const handleLPG = useFullScreenHandle();
  const handleDPK = useFullScreenHandle();
  return (
    <Layout>
      <TopNav
        date={date}
        showDate={true}
        setDate={setDate}
        setRefresh={setRefresh}
        loading={pmsLoader}
        showArrow
        setLoading={setLoading}
        title={`Volume comsumption of companies in ${state}`}
      />
      <div className="db-c">
        <div>
          <div className="dashbboard-cover">
            <div className="container-fluid ">
              {/* Cards */}
              <div className="row mt-3 ">
                <div className="col-sm-3 col-md-3 col-mg-3 col-lg-3 d-tc">
                  <TopCard
                    percentageChange={pmsPer}
                    bgType={"blue"}
                    tag="pms"
                    price={pmsVol}
                    data={pmsChart}
                  />
                </div>
                <div className="col-sm-3 col-md-3 col-mg-3 col-lg-3 d-tc">
                  <TopCard
                    percentageChange={agoPer}
                    bgType={"ago"}
                    tag="ago"
                    price={agoVol}
                    data={agoChart}
                  />
                </div>
                <div className="col-sm-3 col-md-3 col-mg-3 col-lg-3 d-tc">
                  <TopCard
                    percentageChange={dpkPer}
                    bgType={"ago"}
                    tag="dpk"
                    price={dpkVol}
                    data={dpkChart}
                  />
                </div>
                <div className="col-sm-3 col-md-3 col-mg-3 col-lg-3 d-tc">
                  <TopCard
                    percentageChange={lpgPer}
                    bgType={"blue"}
                    tag="lpg"
                    price={lpgVol}
                    data={lpgChart}
                  />
                </div>
              </div>

              {/* maps */}
              <div className="row mt-3 p-0 m-0">
                <div className="tab-top bg-white d-flex align-items-center justify-content-between">
                  {/* START */}
                  <div className="d-flex align-items-center justify-content-start">
                    {/* PMS */}
                    <div
                      className={
                        tabIndex === 1
                          ? "tab-t active px-4 pb-2 pt-2 mx-3 "
                          : "tab-t px-4 pb-2 pt-2 mx-3"
                      }
                      onClick={() => {
                        setTabLoader({ pms: true });
                        setTabIndex((prev) => 1);
                        setTimeout(() => {
                          setTabLoader({ pms: false });
                        }, 2000);
                      }}
                    >
                      PMS
                    </div>
                    {/* AGO */}
                    <div
                      className={
                        tabIndex === 2
                          ? "tab-t active px-4 pb-2 pt-2 mx-3 "
                          : "tab-t px-4 pb-2 pt-2 mx-3"
                      }
                      onClick={() => {
                        setTabLoader({ ago: true });
                        setTabIndex((prev) => 2);
                        setTimeout(() => {
                          setTabLoader({ ago: false });
                        }, 2000);
                      }}
                    >
                      AGO
                    </div>
                    {/* DPk */}
                    <div
                      className={
                        tabIndex === 3
                          ? "tab-t active px-4 pb-2 pt-2 mx-3 "
                          : "tab-t px-4 pb-2 pt-2 mx-3"
                      }
                      onClick={() => {
                        setTabLoader({ dpk: true });
                        setTabIndex((prev) => 3);
                        setTimeout(() => {
                          setTabLoader({ dpk: false });
                        }, 2000);
                      }}
                    >
                      DPK
                    </div>
                    {/* LPG */}
                    <div
                      className={
                        tabIndex === 4
                          ? "tab-t active px-4 pb-2 pt-2 mx-3 "
                          : "tab-t px-4 pb-2 pt-2 mx-3"
                      }
                      onClick={() => {
                        setTabLoader({ lpg: true });
                        setTabIndex((prev) => 4);
                        setTimeout(() => {
                          setTabLoader({ lpg: false });
                        }, 2000);
                      }}
                    >
                      LPG
                    </div>
                    {/* END */}
                  </div>
                  <div className="">
                    <Select
                      placeholder="Select map type"
                      style={{
                        width: "120px",
                      }}
                      value={charttype}
                      defaultValue={"Volumetric"}
                      onChange={(e) => setChartType(e)}
                    >
                      {[
                        { id: 1, value: "vol", title: "Volumetric" },
                        { id: 2, value: "agol", title: "Algorithmic" },
                      ].map(({ title, id, value }) => (
                        <Option key={id} value={value}>
                          {title}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="tab-body bg-white">
                  <div
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "15px",
                      cursor: "pointer",
                      zIndex: "20",
                    }}
                    onClick={
                      tabIndex === 1
                        ? handlePMS.enter
                        : tabIndex === 2
                        ? handleAGO.enter
                        : tabIndex === 3
                        ? handleLPG.enter
                        : tabIndex === 4
                        ? handleDPK.enter
                        : null
                    }
                    className="p-1 "
                  >
                    <BsArrowsFullscreen size={18} color={color.darkColor} />
                  </div>
                  <div
                    className={
                      tabIndex === 1 ? "tab-content active" : "tab-content"
                    }
                  >
                    <div className="tab-content__map">
                      {tabLoader.pms ? (
                        <div className="mt-5">
                          <LoaderIcon text={"Fetching LGA data"} />
                        </div>
                      ) : (
                        <>
                          <FullScreen handle={handlePMS}>
                            <div className="state-map d-flex align-items-center justify-content-center">
                              <PanZoom autoCenter={true}>
                                {state.toLowerCase() === "kogi" && (
                                  <KogiState data={data} type={"PMS"} />
                                )}

                                {state.toLowerCase() === "kaduna" && (
                                  <Kaduna data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() == "cross river" && (
                                  <CrossRivers data={data} type={"PMS"} />
                                )}

                                {state.toLowerCase() == "kwara" && (
                                  <Kwara data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "katsina" && (
                                  <Kastina data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "ondo" && (
                                  <OndoState data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "edo" && (
                                  <Edo data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "yobe" && (
                                  <Yobe data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "jigawa" && (
                                  <Jigawa data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "gombe" && (
                                  <Gombe data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "borno" && (
                                  <Borno data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "anambra" && (
                                  <Anambra data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "lagos" && (
                                  <Lagos data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "zanfara" && (
                                  <Zamfara data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "plateau" && (
                                  <Plateau data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "ogun" && (
                                  <Ogun data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "ebonyi" && (
                                  <Ebonyi data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "benue" && (
                                  <Benue data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "akwa ibom" && (
                                  <Akwaibom data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "oyo" && (
                                  <Oyo data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "niger" && (
                                  <Niger data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "kebi" && (
                                  <Kebi data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "enugu" && (
                                  <Enugu data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "delta" && (
                                  <Delta data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "bayelsa" && (
                                  <Bayelsa data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "adamawa" && (
                                  <Adamawa data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "sokoto" && (
                                  <Sokoto data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "taraba" && (
                                  <Taraba data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "osun" && (
                                  <Osun data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "imo" && (
                                  <Imo data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "ekiti" && (
                                  <Ekiti data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "bauchi" && (
                                  <Bauchi data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "abia" && (
                                  <Abia data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "fct" && (
                                  <Abuja data={data} type={"PMS"} />
                                )}
                                {state.toLowerCase() === "kano" && (
                                  <Kano data={data} type={"PMS"} />
                                )}
                              </PanZoom>
                            </div>
                          </FullScreen>
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className={
                      tabIndex === 2 ? "tab-content active" : "tab-content"
                    }
                  >
                    <div className="tab-content__map">
                      {tabLoader.ago ? (
                        <div className="mt-5">
                          <LoaderIcon text={"Fetching LGA data"} />
                        </div>
                      ) : (
                        <>
                          <FullScreen handle={handleAGO}>
                            <div className="state-map d-flex align-items-center justify-content-center">
                              <PanZoom autoCenter={true}>
                                {state.toLowerCase() === "kogi" && (
                                  <KogiState data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() == "cross river" && (
                                  <CrossRivers data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "kaduna" && (
                                  <Kaduna data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "katsina" && (
                                  <Kastina data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() == "kwara" && (
                                  <Kwara data={data} type={"AGO"} />
                                )}

                                {state.toLowerCase() === "borno" && (
                                  <Borno data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "yobe" && (
                                  <Yobe data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "edo" && (
                                  <Edo data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "lagos" && (
                                  <Lagos data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "ondo" && (
                                  <OndoState data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "jigawa" && (
                                  <Jigawa data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "gombe" && (
                                  <Gombe data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "zanfara" && (
                                  <Zamfara data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "anambra" && (
                                  <Anambra data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "plateau" && (
                                  <Plateau data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "taraba" && (
                                  <Taraba data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "ogun" && (
                                  <Ogun data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "ebonyi" && (
                                  <Ebonyi data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "benue" && (
                                  <Benue data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "akwa ibom" && (
                                  <Akwaibom data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "oyo" && (
                                  <Oyo data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "niger" && (
                                  <Niger data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "kebi" && (
                                  <Kebi data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "enugu" && (
                                  <Enugu data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "delta" && (
                                  <Delta data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "bayelsa" && (
                                  <Bayelsa data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "adamawa" && (
                                  <Adamawa data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "sokoto" && (
                                  <Sokoto data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "osun" && (
                                  <Osun data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "imo" && (
                                  <Imo data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "ekiti" && (
                                  <Ekiti data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "bauchi" && (
                                  <Bauchi data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "abia" && (
                                  <Abia data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "fct" && (
                                  <Abuja data={data} type={"AGO"} />
                                )}
                                {state.toLowerCase() === "kano" && (
                                  <Kano data={data} type={"AGO"} />
                                )}
                              </PanZoom>
                            </div>
                          </FullScreen>
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className={
                      tabIndex === 3 ? "tab-content active" : "tab-content"
                    }
                  >
                    <div className="tab-content__map">
                      {tabLoader.dpk ? (
                        <div className="mt-5">
                          <LoaderIcon text={"Fetching LGA data"} />
                        </div>
                      ) : (
                        <>
                          <FullScreen handle={handleDPK}>
                            <div className="state-map d-flex align-items-center justify-content-center">
                              <PanZoom autoCenter={true}>
                                {state.toLowerCase() === "kogi" && (
                                  <KogiState data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() == "cross river" && (
                                  <CrossRivers data={data} type={"DPK"} />
                                )}

                                {state.toLowerCase() === "kaduna" && (
                                  <Kaduna data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "edo" && (
                                  <Edo data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() == "kwara" && (
                                  <Kwara data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "katsina" && (
                                  <Kastina data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "yobe" && (
                                  <Yobe data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "ondo" && (
                                  <OndoState data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "jigawa" && (
                                  <Jigawa data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "gombe" && (
                                  <Gombe data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "borno" && (
                                  <Borno data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "anambra" && (
                                  <Anambra data={data} type={"DPK"} />
                                )}

                                {state.toLowerCase() === "kastina" && (
                                  <Kastina data={data} type={"DPK"} />
                                )}

                                {state.toLowerCase() === "taraba" && (
                                  <Taraba data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "lagos" && (
                                  <Lagos data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "plateau" && (
                                  <Plateau data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "ogun" && (
                                  <Ogun data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "ebonyi" && (
                                  <Ebonyi data={data} type={"DPK"} />
                                )}

                                {state.toLowerCase() === "zanfara" && (
                                  <Zamfara data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "benue" && (
                                  <Benue data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "akwa ibom" && (
                                  <Akwaibom data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "oyo" && (
                                  <Oyo data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "niger" && (
                                  <Niger data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "kebi" && (
                                  <Kebi data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "enugu" && (
                                  <Enugu data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "delta" && (
                                  <Delta data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "bayelsa" && (
                                  <Bayelsa data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "adamawa" && (
                                  <Adamawa data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "sokoto" && (
                                  <Sokoto data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "osun" && (
                                  <Osun data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "imo" && (
                                  <Imo data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "ekiti" && (
                                  <Ekiti data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "bauchi" && (
                                  <Bauchi data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "abia" && (
                                  <Abia data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "fct" && (
                                  <Abuja data={data} type={"DPK"} />
                                )}
                                {state.toLowerCase() === "kano" && (
                                  <Kano data={data} type={"DPK"} />
                                )}
                              </PanZoom>
                            </div>
                          </FullScreen>
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className={
                      tabIndex === 4 ? "tab-content active" : "tab-content"
                    }
                  >
                    <div className="tab-content__map">
                      {tabLoader.lpg ? (
                        <div className="mt-5">
                          <LoaderIcon text={"Fetching LGA data"} />
                        </div>
                      ) : (
                        <>
                          <FullScreen handle={handleLPG}>
                            <div className="state-map d-flex align-items-center justify-content-center">
                              <PanZoom autoCenter={true}>
                                {state.toLowerCase() === "kogi" && (
                                  <KogiState data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "katsina" && (
                                  <Kastina data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "kaduna" && (
                                  <Kaduna data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() == "cross river" && (
                                  <CrossRivers data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "yobe" && (
                                  <Yobe data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "edo" && (
                                  <Edo data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "jigawa" && (
                                  <Jigawa data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "gombe" && (
                                  <Gombe data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "borno" && (
                                  <Borno data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "anambra" && (
                                  <Anambra data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "taraba" && (
                                  <Taraba data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "lagos" && (
                                  <Lagos data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "plateau" && (
                                  <Plateau data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "zanfara" && (
                                  <Zamfara data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "ogun" && (
                                  <Ogun data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "ebonyi" && (
                                  <Ebonyi data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "benue" && (
                                  <Benue data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "akwa ibom" && (
                                  <Akwaibom data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "oyo" && (
                                  <Oyo data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "niger" && (
                                  <Niger data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "kebi" && (
                                  <Kebi data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "enugu" && (
                                  <Enugu data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "delta" && (
                                  <Delta data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "bayelsa" && (
                                  <Bayelsa data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "adamawa" && (
                                  <Adamawa data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "sokoto" && (
                                  <Sokoto data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "osun" && (
                                  <Osun data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "imo" && (
                                  <Imo data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "ekiti" && (
                                  <Ekiti data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "bauchi" && (
                                  <Bauchi data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "abia" && (
                                  <Abia data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "fct" && (
                                  <Abuja data={data} type={"LPG"} />
                                )}
                                {state.toLowerCase() === "kano" && (
                                  <Kano data={data} type={"LPG"} />
                                )}
                              </PanZoom>
                            </div>
                          </FullScreen>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-block bg-white p-3 rounded-2 ">
                <div className="row p-4 m-0 d-flex justify-content-between align-items-start stats-cover">
                  <div className="col-sm-12 col-md-6 col-lg-5 col-xl-5 ">
                    <div className="stats-t text-center">
                      Top companies in{" "}
                      {state[0].toUpperCase() + state.substring(1)} state
                      (Litres)
                    </div>
                    {tabIndex === 1 && (
                      <div className="">
                        {loading ? (
                          Array.from(Array(5)).map((_, i) => (
                            <div key={i} className="my-4">
                              <Skimmer width={"90%"} heigth={"30px"} />
                            </div>
                          ))
                        ) : !topCompany.length ? (
                          <div
                            className="text-center pt-5"
                            style={{
                              color: color.baseColor,
                            }}
                          >
                            No top company data
                          </div>
                        ) : (
                          topCompany
                            .filter((item) => item.product === "PMS")
                            .map((item, i) => (
                              <div
                                className="stats-e my-1 py-2 d-flex justify-content-between align-items-center"
                                key={i}
                              >
                                <div className="stats-left d-flex justify-content-center align-items-center">
                                  <div className="stats-i__c d-flex justify-content-center align-items-center">
                                    <img src={Buildimg} className="stats-img" />
                                  </div>
                                  <div className="stats-name mx-3">
                                    {item.company}
                                    {/* {"Company " + i} */}
                                  </div>
                                </div>
                                <div
                                  className="stats-right"
                                  style={{
                                    color: color.greenColor,
                                  }}
                                >
                                  {numFormtter(item.volume)}
                                </div>
                              </div>
                            ))
                        )}
                      </div>
                    )}
                    {tabIndex === 2 && (
                      <div className="">
                        {loading ? (
                          Array.from(Array(5)).map((_, i) => (
                            <div key={i} className="my-4">
                              <Skimmer width={"90%"} heigth={"30px"} />
                            </div>
                          ))
                        ) : !topCompany.length ? (
                          <div
                            className="text-center pt-5"
                            style={{
                              color: color.baseColor,
                            }}
                          >
                            No top company data
                          </div>
                        ) : (
                          topCompany
                            .filter((item) => item.product === "AGO")
                            .map((item, i) => (
                              <div
                                className="stats-e my-1 py-2 d-flex justify-content-between align-items-center"
                                key={i}
                              >
                                <div className="stats-left d-flex justify-content-center align-items-center">
                                  <div className="stats-i__c d-flex justify-content-center align-items-center">
                                    <img src={Buildimg} className="stats-img" />
                                  </div>
                                  <div className="stats-name mx-3">
                                    {/* {item.company} */}
                                    {"Company " + i}
                                  </div>
                                </div>
                                <div
                                  className="stats-right"
                                  style={{
                                    color: color.greenColor,
                                  }}
                                >
                                  {numFormtter(item.volume)}
                                </div>
                              </div>
                            ))
                        )}
                      </div>
                    )}

                    {tabIndex === 3 && (
                      <div className="">
                        {loading ? (
                          Array.from(Array(5)).map((_, i) => (
                            <div key={i} className="my-4">
                              <Skimmer width={"90%"} heigth={"30px"} />
                            </div>
                          ))
                        ) : !topCompany.length ? (
                          <div
                            className="text-center pt-5"
                            style={{
                              color: color.baseColor,
                            }}
                          >
                            No top company data
                          </div>
                        ) : (
                          topCompany
                            .filter((item) => item.product === "DPK")
                            .map((item, i) => (
                              <div
                                className="stats-e my-1 py-2 d-flex justify-content-between align-items-center"
                                key={i}
                              >
                                <div className="stats-left d-flex justify-content-center align-items-center">
                                  <div className="stats-i__c d-flex justify-content-center align-items-center">
                                    <img src={Buildimg} className="stats-img" />
                                  </div>
                                  <div className="stats-name mx-3">
                                    {/* {item.company} */}
                                    {"Company " + i}
                                  </div>
                                </div>
                                <div
                                  className="stats-right"
                                  style={{
                                    color: color.greenColor,
                                  }}
                                >
                                  {numFormtter(item.volume)}
                                </div>
                              </div>
                            ))
                        )}
                      </div>
                    )}

                    {tabIndex === 4 && (
                      <div className="">
                        {loading ? (
                          Array.from(Array(5)).map((_, i) => (
                            <div key={i} className="my-4">
                              <Skimmer width={"90%"} heigth={"30px"} />
                            </div>
                          ))
                        ) : !topCompany.length ? (
                          <div
                            className="text-center pt-5"
                            style={{
                              color: color.baseColor,
                            }}
                          >
                            No top company data
                          </div>
                        ) : (
                          topCompany
                            .filter((item) => item.product === "LPG")
                            .map((item, i) => (
                              <div
                                className="stats-e my-1 py-2 d-flex justify-content-between align-items-center"
                                key={i}
                              >
                                <div className="stats-left d-flex justify-content-center align-items-center">
                                  <div className="stats-i__c d-flex justify-content-center align-items-center">
                                    <img src={Buildimg} className="stats-img" />
                                  </div>
                                  <div className="stats-name mx-3">
                                    {/* {item.company} */}
                                    {"Company " + i}
                                  </div>
                                </div>
                                <div
                                  className="stats-right"
                                  style={{
                                    color: color.greenColor,
                                  }}
                                >
                                  {numFormtter(item.volume)}
                                </div>
                              </div>
                            ))
                        )}
                      </div>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-5 col-xl-5">
                    <div className="stats-t text-center">
                      Top LGA in {state[0].toUpperCase() + state.substring(1)}{" "}
                      state (Litres)
                    </div>
                    {tabIndex === 1 && (
                      <div className="">
                        {loading ? (
                          Array.from(Array(5)).map((_, i) => (
                            <div key={i} className="my-4">
                              <Skimmer width={"90%"} heigth={"30px"} />
                            </div>
                          ))
                        ) : !topLga.length ? (
                          <div
                            className="text-center pt-5"
                            style={{
                              color: color.baseColor,
                            }}
                          >
                            No LGA data
                          </div>
                        ) : (
                          topLga
                            .filter((item) => item.product === "PMS")
                            .map((item, i) => (
                              <div
                                className="stats-e my-1 py-2 d-flex justify-content-between align-items-center"
                                key={i}
                              >
                                <div className="stats-left d-flex justify-content-start align-items-center">
                                  <div className="stats-i__c d-flex justify-content-center align-items-center">
                                    <img src={StateImg} className="stats-img" />
                                  </div>
                                  <div className="stats-name mx-3">
                                    {item.lga}
                                  </div>
                                </div>
                                <div
                                  className="stats-right"
                                  style={{
                                    color: color.baseColor,
                                  }}
                                >
                                  {numFormtter(item.volume)}
                                </div>
                              </div>
                            ))
                        )}
                      </div>
                    )}

                    {tabIndex === 2 && (
                      <div className="">
                        {loading ? (
                          Array.from(Array(5)).map((_, i) => (
                            <div key={i} className="my-4">
                              <Skimmer width={"90%"} heigth={"30px"} />
                            </div>
                          ))
                        ) : !topLga.length ? (
                          <div
                            className="text-center pt-5"
                            style={{
                              color: color.baseColor,
                            }}
                          >
                            No LGA data
                          </div>
                        ) : (
                          topLga
                            .filter((item) => item.product === "AGO")
                            .map((item, i) => (
                              <div
                                className="stats-e my-1 py-2 d-flex justify-content-between align-items-center"
                                key={i}
                              >
                                <div className="stats-left d-flex justify-content-start align-items-center">
                                  <div className="stats-i__c d-flex justify-content-center align-items-center">
                                    <img src={StateImg} className="stats-img" />
                                  </div>
                                  <div className="stats-name mx-3">
                                    {item.lga}
                                  </div>
                                </div>
                                <div
                                  className="stats-right"
                                  style={{
                                    color: color.baseColor,
                                  }}
                                >
                                  {numFormtter(item.volume) === NaN
                                    ? "0"
                                    : numFormtter(item.volume)}
                                </div>
                              </div>
                            ))
                        )}
                      </div>
                    )}

                    {tabIndex === 3 && (
                      <div className="">
                        {loading ? (
                          Array.from(Array(5)).map((_, i) => (
                            <div key={i} className="my-4">
                              <Skimmer width={"90%"} heigth={"30px"} />
                            </div>
                          ))
                        ) : !topLga.length ? (
                          <div
                            className="text-center pt-5"
                            style={{
                              color: color.baseColor,
                            }}
                          >
                            No LGA data
                          </div>
                        ) : (
                          topLga
                            .filter((item) => item.product === "DPK")
                            .map((item, i) => (
                              <div
                                className="stats-e my-1 py-2 d-flex justify-content-between align-items-center"
                                key={i}
                              >
                                <div className="stats-left d-flex justify-content-start align-items-center">
                                  <div className="stats-i__c d-flex justify-content-center align-items-center">
                                    <img src={StateImg} className="stats-img" />
                                  </div>
                                  <div className="stats-name mx-3">
                                    {item.lga}
                                  </div>
                                </div>
                                <div
                                  className="stats-right"
                                  style={{
                                    color: color.baseColor,
                                  }}
                                >
                                  {numFormtter(item.volume)}
                                </div>
                              </div>
                            ))
                        )}
                      </div>
                    )}

                    {tabIndex === 4 && (
                      <div className="">
                        {loading ? (
                          Array.from(Array(5)).map((_, i) => (
                            <div key={i} className="my-4">
                              <Skimmer width={"90%"} heigth={"30px"} />
                            </div>
                          ))
                        ) : !topLga.length ? (
                          <div
                            className="text-center pt-5"
                            style={{
                              color: color.baseColor,
                            }}
                          >
                            No LGA data
                          </div>
                        ) : (
                          topLga
                            .filter((item) => item.product === "LPG")
                            .map((item, i) => (
                              <div
                                className="stats-e my-1 py-2 d-flex justify-content-between align-items-center"
                                key={i}
                              >
                                <div className="stats-left d-flex justify-content-start align-items-center">
                                  <div className="stats-i__c d-flex justify-content-center align-items-center">
                                    <img src={StateImg} className="stats-img" />
                                  </div>
                                  <div className="stats-name mx-3">
                                    {item.lga}
                                  </div>
                                </div>
                                <div
                                  className="stats-right"
                                  style={{
                                    color: color.baseColor,
                                  }}
                                >
                                  {numFormtter(item.volume)}
                                </div>
                              </div>
                            ))
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div
                className="row mt-4 bg-white rounded-2 p-0 mx-2"
                style={{
                  height: "320px",
                }}
              >
                <div
                  className="col-sm-12 col-md-6 col-lg-6 col-xl-6  d-flex align-items-center justify-content-center dsk-c__c pt-3"
                  style={{
                    height: "300px",
                    borderRight: "1px solid #eee",
                  }}
                >
                  <div className="chart-t__c">
                    <div className="chart-t__t">Sales by Association</div>
                    <div className="ct-t__c d-none">
                      Graphical representation showing the sales by
                      Classification
                    </div>
                  </div>
                  {tabIndex == 1 && (
                    <div
                      style={{
                        height: "250px",
                        width: "100%",
                      }}
                      className=""
                    >
                      <DoughnutChart
                        title={"Sales by association"}
                        type={"a"}
                        data={association
                          .filter((item) => item.product == "PMS")
                          .map((item) => item.volume)}
                      />
                    </div>
                  )}

                  {tabIndex == 2 && (
                    <div
                      style={{
                        height: "250px",
                        width: "100%",
                      }}
                      className=""
                    >
                      <DoughnutChart
                        title={"Sales by association"}
                        type={"a"}
                        data={association
                          .filter((item) => item.product == "AGO")
                          .map((item) => item.volume)}
                      />
                    </div>
                  )}
                  {tabIndex == 3 && (
                    <div
                      style={{
                        height: "250px",
                        width: "100%",
                      }}
                      className=""
                    >
                      <DoughnutChart
                        title={"Sales by association"}
                        type={"a"}
                        data={association
                          .filter((item) => item.product == "DPK")
                          .map((item) => item.volume)}
                      />
                    </div>
                  )}
                  {tabIndex == 4 && (
                    <div
                      style={{
                        height: "250px",
                        width: "100%",
                      }}
                      className=""
                    >
                      <DoughnutChart
                        title={"Sales by association"}
                        type={"a"}
                        data={association
                          .filter((item) => item.product == "LPG")
                          .map((item) => item.volume)}
                      />
                    </div>
                  )}
                </div>
                <div
                  className="col-sm-12 col-md-6 col-lg-6 col-xl-6  d-flex align-items-center justify-content-center dsk-c__c"
                  style={{
                    height: "300px",
                  }}
                >
                  <div className="chart-t__c ">
                    <div className="chart-t__t">Sales by Classification</div>
                    <div className="ct-t__c d-none">
                      Graphical representation showing the sales by
                      Classification
                    </div>
                  </div>
                  {tabIndex == 1 && (
                    <div
                      style={{
                        height: "250px",
                        width: "100%",
                      }}
                      className=""
                    >
                      <DoughnutChart
                        title={"Sales by classification"}
                        type={"c"}
                        data={classification
                          .filter((item) => item.product === "PMS")
                          .map((item, index) => item.volume)}
                      />
                    </div>
                  )}
                  {tabIndex == 2 && (
                    <div
                      style={{
                        height: "250px",
                        width: "100%",
                      }}
                      className=""
                    >
                      <DoughnutChart
                        title={"Sales by classification"}
                        type={"c"}
                        data={classification
                          .filter((item) => item.product === "AGO")
                          .map((item, index) => item.volume)}
                      />
                    </div>
                  )}
                  {tabIndex == 3 && (
                    <div
                      style={{
                        height: "250px",
                        width: "100%",
                      }}
                      className=""
                    >
                      <DoughnutChart
                        title={"Sales by classification"}
                        type={"c"}
                        data={classification
                          .filter((item) => item.product === "DPK")
                          .map((item, index) => item.volume)}
                      />
                    </div>
                  )}

                  {tabIndex == 4 && (
                    <div
                      style={{
                        height: "250px",
                        width: "100%",
                      }}
                      className=""
                    >
                      <DoughnutChart
                        title={"Sales by classification"}
                        type={"c"}
                        data={classification
                          .filter((item) => item.product === "LPG")
                          .map((item, index) => item.volume)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LGA;
