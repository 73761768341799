export const data = [
  {
    state: "Anambra_west",
    path: {
      id: "Path_6859",
      dataName: "Anambra west",
      d: "M388.078,128.98,379,126.666v5.1l2.848,4.331,4.509,5.1,4.569,7.417,4.984,6.7,2.492,4.984,2.077,8.663,1.721,16.91v24.208l4.509,14.892,4.925,18.334,3.857,17.147v14.121l1.483,17.5v15.427l3.382,15.13,6.171,10.265,5.637,9.968,3.8,9.315,4.865,4.925,4.094-.712,6.467-2.017,1.6-2.2-.89-3.5V322.642l.89-11.155,3.2-5.993,3.026-3.975,3.975-3.323,4.569-2.967,5.577-2.373,1.6-3.857,3.382-8.069,6.823-10.324,6.467-6.171,4.331-4.628,5.933-4.569,8.722-7.713,4.213-5.933,3.323-6.23v-5.933l.653-7.951,1.365-4.569,4.331-7.476,3.975-5.1,3.145-5.459,2.373-6.349.653-4.865,1.009-7.357v-6.527l1.009-3.085,2.136-3.026,2.67-3.441,2.729-2.433,4.094-.949,3.916-1.424,1.661-2.848-.415-6.23.415-6.171.593-3.2.831-2.433,1.958-.831h2.611l.771-1.958v-6.052h-2.611l-2.136-.534-2.017-1.6-1.009-1.9v-2.729l1.6-2.611,2.789-2.848,4.272-2.017,6.052-1.958.771-2.2.831-4.984.771-3.857,3.382-6.289,2.373-3.619,5.221-2.789,5.1-3.56V84.184l-1.9-2.433-2.2-1.721L579.9,77.183l2.848-2.255,2.67-3.56V66.147L583.579,61.1l-2.2-4.213,1.365-1.721,3.263-2.017,3.8-2.492-.712-1.839-4.984-3.5-3.2-2.848L579.9,40.041l-1.424-4.391V30.488l1.424-6.7,2.077-6.645,1.6-4.331.771-6.171-.771-3.619L580.731,1H570.466l-5.221,1.6L554.506,6.637l-11.689,4.569-16.672,7.357-19.639,9.909L486.689,36.54l-7.239,5.1v4.925l3.263,5.34,3.975,7.179,3.5,11.333,2.373,11.214L490.189,97.3l-4.687,8.6-6.052,6.586-10.8,3.323-7.061,1.305-10.205,1.127L439.7,122.632l-14.6,4.035-20.232,2.314Z",
      translate: { x: "205.713", y: "629.855" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_4M",
      name: "4M",
      fill: "#001755",
      translate: { x: "661.656", y: "820.566" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "Anambra_west-2",
      name: "Anambra west",
      fill: "#001755",
      translate: { x: "632.007", y: "793.314" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Ayamelum",
    path: {
      id: "Path_6860",
      d: "M1638.522,287.891l-10.443,3.857-1.246,1.068.356.3.534.475.831.712.593.475,4.331,3.026.712,1.839-7.061,4.509-1.365,1.721,2.2,4.213,1.839,5.1v5.162l-2.67,3.56-2.848,2.255,2.017,2.848,2.255,1.78,1.839,2.433v1.958l-5.162,3.56-5.162,2.789-2.314,3.56-.712,1.246-1.068,2.017-.89,1.661-.771,1.424-1.009,5.34-.593,3.5-.771,2.2-6.111,1.958-4.213,2.017-2.789,2.848-1.6,2.611v2.729l1.009,1.9,2.077,1.661,2.136.475H1605v6.111l-.771,1.9h-2.611l-1.958.831-.831,2.492-.593,3.263-.415,6.23.415,5.993-1.72,2.907-3.916,1.424-4.094.949-2.611,2.433-3.145,4.035-1.721,2.433-1.009,3.085v6.586l-.237,1.721-.415,2.967-.475,3.5-.534,3.975-.653,1.78-.89,2.373-.831,2.2-.356.653-1.068,1.839-.89,1.483-.653,1.187-.475.712-1.839,2.314-1.958,2.551-4.213,7.3-.534,2.077.949.475,2.848-.949,2.848-1.721,2.967-1.127,7.3-2.017,9.315-3.8,10.62-3.8,7.417-3.026,6.052-3.145,5.518-2.848,3.145-4.153,3.2-4.094,2.017-2.077h1.78l2.2.949,1.424,2.373,1.721,5.4,1.127,5.221v9.2l2.373,3.8,3.8,4.865,4.569,1.78h6.171l1.78-3.5,3.8-3.145,5.518-3.8,4.925-2.077,10.086-3.8,11.273-3.323,5.221-3.619,1.068-4.153,1.305-4.094,1.246-3.323,2.67-5.4,3.5-2.67h3.323l2.2-3.8,1.6-6.942v-7.595l.949-8.425V387.451l1.127-3.975,5.043-4.272,5.043-1.9h7l7.891-.949,5.874-1.543,3.916-5.577,5.577-6.942,4.391-3.8,5.4-3.145,6.942-4.45,1.6-3.8v-6.645l1.6-4.094,2.67-4.747,5.874-5.1,1.543-2.848-2.967-2.492-4.45-2.729-6.942-2.67-7.773-6.349-11.392-6.942H1732.03l-14.121-2.2-9.137-4.272-9.2-4.272-10.443-4.806-11.1-3.441H1654.66l-8.425,2.2Z",
      translate: { x: "-838.669", y: "380.878" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Ayamelum-2",
      name: "Ayamelum",
      fill: "#001755",
      translate: { x: "816.819", y: "732.858" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-2",
      name: "4M",
      fill: "#001755",
      translate: { x: "822.103", y: "754.264" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Anambra_East",
    path: {
      id: "Path_6861",
      dataName: "Anambra East",
      d: "M1061.63,1600.828l.771-2.492v-.119l.237.119.89.415.771-.3,2.136-.712,2.611-1.543,1.424-.593,1.721-.653,2.373-.653,2.67-.771,2.255-.593,4.925-2.077,4.568-1.78,4.628-1.661,3.5-1.305,2.789-1.009,3.263-1.305,3.145-1.246,1.127-.534,3.085-1.6,2.789-1.483,3.382-1.78,1.6-.831.831-1.009,1.009-1.424,1.187-1.483,1.365-1.721.712-1.009,1.424-1.721,1.068-1.127.831-.831h1.78l2.255,1.009,1.424,2.373,1.6,5.1.593,2.789.653,2.789v9.2l3.619,5.518,2.907,3.323,4.272,1.365h5.815v7l-3.916,2.2-7.654,2.729-1.068,3.263,2.551,3.857,3.56,3.382,3.975,3.56,1.009,7v8.247l-1.009,7.951-2.967,5.34-2.551,4.391-3.5.831-13.409.653-15.6,1.127-8.247,2.492-6.408,3.56-9.552,4.035-8.425,1.127-3.8,2.967-10.917,14.952-7.417,9.434-8.01,7.179-7.773,10.146-3.085,5.7-.475,6.111-1.305,5.1-3.441,1.72-6.526.356-14.774-3.2-10.621-1.6-4.272.475L995,1727.919V1701.99l.178-2.2.237-3.323.475-5.7.712-1.305.712-1.424,1.78-3.263.949-1.246.712-.949,1.365-1.78.712-.593,3.263-2.729,4.628-3.026,5.518-2.373,1.483-3.5,1.958-4.628,1.6-3.857,1.483-2.2,2.017-3.085,2.255-3.382,1.009-1.543,1.721-1.6,2.967-2.848,1.661-1.6,1.839-1.9,2.67-2.907,2.729-2.077,3.263-2.551,2.314-1.958,2.077-1.9,2.373-2.077,1.839-1.721,4.213-5.874,1.187-2.373,2.136-3.857v-5.993Z",
      translate: { x: "-337.189", y: "-749.434" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_4M-3",
      name: "4M",
      fill: "#001755",
      translate: { x: "728.813", y: "905.501" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "Anambra_East-2",
      name: "Anambra East",
      fill: "#001755",
      translate: { x: "716.117", y: "886.785" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Idemili_North",
    path: {
      id: "Path_6863",
      dataName: "Idemili North",
      d: "M1266.313,3050.034v-.534l-13.528,7.12-42.779,11.214-13.884.831-1.009-.238v3.085l-1.009,2.2-1.6,3.619-1.305,2.433-3.382,2.551-3.323,2.254-7.595,1.958-3.619,1.187-1.009,1.305-.534,1.246-.237,2.373v1.542l.771,1.127,1.78.3h28.836l7.3,1.068h4.035l4.984,1.187,4.569,1.246,3.975,2.077h10.8l3.145.653,4.806,1.543,5.34,1.839,5.1.356h17.918l12.222-1.365h9.315l8.781-.89,6.467-1.424,1.187-1.721v-4.747l-1.9-2.729-.771-5.815-2.2-1.9-6.645-7.179-5.637-7.772-4.925-5.577-3.2-2.907-15.367-7.594Z",
      translate: { x: "-492.745", y: "-2056.894" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Idemili_North-2",
      name: "Idemili North",
      fill: "#001755",
      translate: { x: "722.385", y: "1022.771" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-4",
      name: "4M",
      fill: "#001755",
      translate: { x: "760.195", y: "1039.98" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Njikoka",
    path: {
      id: "Path_6864",
      d: "M2034.926,2791.416l-15.426-7.595,1.365-.949,19.936-15.723,4.984-3.975,3.382-2.67,3.619-2.017,3.263-1.78,3.975-2.2,7.772-4.332,9.79-4.153,6.7-2.2,1.483-.831,2.907.653v1.661l.712,2.967.949,4.331,1.839,3.857,5.281,6.289,4.806,8.307,1.78,4.865.89,5.577v8.781l-.771,5.637-1.78,2.729h-3.441l-3.5.594h-3.263l-4.213.771-2.077,2.077-.415,2.433v4.984l-2.136,3.085-8.366,3.679-11.688,1.127-5.755-.594-8.781-9.078-.356-.653-5.34-7.061-4.865-5.577Z",
      translate: { x: "-1240.117", y: "-1786.765" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Njikoka-2",
      name: "Njikoka",
      fill: "#001755",
      translate: { x: "814.448", y: "987.431" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-5",
      name: "4M",
      fill: "#001755",
      translate: { x: "819.226", y: "1003.367" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Awka_North",
    path: {
      id: "Path_6866",
      dataName: "Akwa North",
      d: "M2288.65,1663.036l-13.231-4.509-5.281-2.729-5.162-3.8-5.281,3.8-9.019,2.729-3.441,1.009-13.528,5.162-5.7,3.857L2224.1,1672l-2.017,3.679v6.526l-3.678,2.2-8.069,2.789-.831,3.263,2.551,3.857,7.417,7.061,1.127,7.12v8.129l-1.068,7.654-2.67,4.628,6.052,3.2,5.043,4.213,7.417,8.484,1.483,3.026,3.56,5.993,3.441,5.7,2.848,6.467v11.036l6.526,4.272,2.907.771,4.212-.771,7.239-2.077,9.79-5.459,6.883-4.747h4.094l7.891.89,11.392,2.729,22.487,7.239,11.214,5.518,5.577.653,4.153-.653,1.9-2.967v-19.758l1.068-5.933,3.2-3.263,14.477-5.162v-3.441l-5.933-3.026-4.806-1.661-3.738-5.815-5.162-11.1-3.619-9.434-6.23-11.629-8.9-15.664-10.443-11.867-5.7-4.509-6.349-2.967-10.977-2.967Z",
      translate: { x: "-1407.57", y: "-825.229" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Akwa_North-2",
      name: "Akwa North",
      fill: "#001755",
      translate: { x: "841.166", y: "886.785" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-6",
      name: "4M",
      fill: "#001755",
      translate: { x: "859.819", y: "900.785" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Ogbaru",
    path: {
      id: "Path_6867",
      d: "M80.09,3610.765l4.153-13.231.415-.534,4.391,2.967h13.231l15.07,1.187,1.721.237v4.687l4.806,10.264,8.247,18.334,13.053,28-1.958,6.111c1.226,2.65,3.762,8.117,4.094,8.781s5.281,8.386,7.713,12.163l-5.34,9.493-10.917,16.791-9.493,5.281-8.069,6.942v4.806l3.679,10.027,2.136,8.01.89,21.359v7.713l-1.78,11.926-4.687,4.153-2.077,3.382-2.077,4.45-1.839,6.764-7.061,4.391-58.739-2.077-15.9,8.366-10.383-2.077-14.3-10.68-5.221-1.839L1,3804.842v-2.611l2.848-1.543,5.221-4.45,2.848-5.7,1.305-13.528v-21.063l3.382-5.993,6.764-5.458,6.23-9.375,9.612-12.757,10.443-7.772,8.544-8.6,3.145-6.764,2.611-18.215,9.375-15.308L75.64,3648.5l-2.314-23.377,2.314-9.908Z",
      translate: { x: "538.857", y: "-2539.425" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Ogabaru-2",
      name: "Ogabaru",
      fill: "#001755",
      translate: { x: "613.607", y: "1161.075" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-7",
      name: "4M",
      fill: "#001755",
      translate: { x: "613.607", y: "1192.818" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ihiala",
    path: {
      id: "Path_6868",
      d: "M1029.988,4496.233l-24.267-9.611-1.721-.89,1.483-2.551,4.687-4.035,1.78-11.629v-9.553l-1.009-19.757-2.017-7.951-3.679-10.443v-4.568l8.129-7,9.375-5.1,9.078-13.884,7.061-12.282.593-.475.771.475,2.433,1.958,18.986,16.732,13.825,10.68,9.2-2.433,3.263-5.043-.949-6.527-2.314-5.814h2.314l4.331,2.077,30.022,7.061,21.063,2.789,11.451,5.458h14.893l-1.6,5.518-5.281,4.806L1146.1,4426.4l-19.936,10.74-34.828,16.02-13.528,6.883-3.857,8.247-2.551,18.334-8.01,9.611-17.384,2.255Z",
      translate: { x: "-345.121", y: "-3226.425" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ihiala-2",
      name: "ihiala",
      fill: "#001755",
      translate: { x: "697.385", y: "1199.818" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-8",
      name: "4M",
      fill: "#001755",
      translate: { x: "702.007", y: "1222.069" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Nnewi_south",
    path: {
      id: "Path_6869",
      dataName: "Nnewi south",
      d: "M1785.9,3976.836l-24.742,6.23-1.958,1.008h-14.893l-11.688-5.577-21.063-2.789-29.726-7-4.391-2.254-1.78-1.483-1.661-2.254,1.661-2.077,7.713-3.976,9.434-7.535,13.35-10.917,10.205-11.51,5.993-11.451,5.4-14.3,10.265.653h4.747V3900l5.162,4.035h3.5l2.611,1.187,1.127,5.7,5.993,8.366,7.12,2.492,18.69,4.925,20.41,9.79,7.476,6.586v4.628l-7.476,12.935v5.518Z",
      translate: { x: "-935.615", y: "-2806.469" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Nnewi_south-2",
      name: "Nnewi south",
      fill: "#001755",
      translate: { x: "840.717", y: "1185.819" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-9",
      name: "4M",
      fill: "#001755",
      translate: { x: "799.813", y: "1147.076" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Aguata",
    path: {
      id: "Path_6870",
      d: "M2403.267,3809.9l-15.96-1.068-1.068,1.068v-5.874c0-.617,5.3-8.88,7.95-12.934v-4.332l-7.95-6.764-20.767-9.79-17.622-4.747-7.476-2.017-5.933-8.959-1.424-5.7-2.017-1.009,6.942-.237,2.433-.771,1.78-.949,3.323-2.611,1.6-1.721,2.373-2.432,4.865-3.2h6.823l6.171-4.094,4.094-3.145,6.764-3.619,6.7-2.492h6.586l9.909.89,10.739,2.017,13.706,4.45,13.409,11.985,5.577,13.587,3.026,3.085,7.713,10.086,1.068,8.425,5.043,9.908,3.975,3.026,2.848,13.646,6.111,6.289,8.781,1.78,7.179,2.552,9.552,6.645,7.179,3.916v1.839l-1.424,3.382-4.331,3.975-5.221,4.865-8.6-.949-5.043-3.916-5.933-1.6-9.315-2.373-12.579-3.382-7-5.755-9.2-3.619-18.809-4.806Z",
      translate: { x: "-1514.652", y: "-2650.032" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Aguata-2",
      name: "Aguata",
      fill: "#001755",
      translate: { x: "864.822", y: "1112.631" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-10",
      name: "4M",
      fill: "#001755",
      translate: { x: "888.18", y: "1132.208" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Orumba south",
    path: {
      id: "Path_6871",
      dataName: "Oruma south",
      d: "M3379.562,3918.2l-2.374,3.2-.415.771-2.67-1.246-4.865-2.967-9.493-6.349-7.239-2.729-8.544-1.661-6.052-6.349L3335,3887.408l-4.094-3.145-4.925-9.908-1.008-8.307-5.578-7.357-1.9-2.314v-.475h1.9l6.408-2.789,4.806-2.907,4.806-3.026,11.451-5.637,11.154-3.56,2.967-.653,6.527-1.246h42.3l9.019-.534,6.586-.89,1.187-1.661,5.814,2.136,8.188,2.433,12.994,3.145,11.273,2.551L3473.6,3847l4.628,4.272v7.417l-1.6,7.417-9.968,5.578h-10.324l-2.729-1.068-10.739-4.509h-8.306l-9.137,5.578-6.526,7.238-5.518,12.282v14.062l3.323,10.562v5.577l-2.136,1.72h-4.45l-6.349-1.72-11.273-3.2-9.078-1.424Z",
      translate: { x: "-2384.09", y: "-2747.42" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Oruma_south-2",
      name: "Oruma south",
      fill: "#001755",
      translate: { x: "968.18", y: "1108.676" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-11",
      name: "4M",
      fill: "#001755",
      translate: { x: "991.18", y: "1127.644" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Awka_South",
    path: {
      id: "Path_6872",
      dataName: "Awka South",
      d: "M2704.789,2668.294,2697.432,2655v-1.068l-4.153.653-5.577-.653-2.374-1.187-8.9-4.331-2.2-.771-9.078-2.907-11.155-3.56-11.51-2.789-7.772-.89h-4.035l-7.476,5.1-9.315,5.162-6.882,1.958-4.509.89v1.78l1.661,7.239,1.6,3.678,5.637,6.646,4.628,8.128,1.839,4.806,1.009,5.636v8.663l-.356,1.127-.712,4.688-1.661,2.551,1.661,3.975,1.839,2.67,4.094,3.975,6.467,1.246,3.382,2.314,2.2,4.331,7.12,5.518,11.51,3.263,11.629-2.136,5.755-4.331,7.891-6.645,4.272-4.806,5.755-2.729,7.713-4.45v-5.043l12.341-13.943-6.052-5.043v-7l2.848-3.2Z",
      translate: { x: "-1753.936", y: "-1693.784" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Awka_South-2",
      name: "Awka South",
      fill: "#001755",
      translate: { x: "874.986", y: "980.431" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-12",
      name: "4M",
      fill: "#001755",
      translate: { x: "893.201", y: "997.97" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ANAOCHA",
    path: {
      id: "Path_6874",
      d: "M2290.646,3274.664l6.349-2.729,1.068-.3v-2.67l8.307-8.306,18.452-23.377-7.95-2.2-7-5.577-2.433-4.331-3.738-2.433-6.111-1.187-4.035-3.916-1.839-2.551-1.661-4.094-3.441.178-3.323.534h-3.382l-4.213.712-2.136,2.255-.237,2.61v4.688l-2.314,3.2-8.307,3.56-11.807,1.068-5.637-.534.831,5.7,1.721,2.67v4.865l-1.127,1.483-.356,9.078-9.552,18.868L2232.5,3287.3l2.136,5.1,5.7,4.45c2.947.039,9.125.095,10.265,0a19.245,19.245,0,0,0,4.509-1.543l3.619-2.907,3.679-4.153,5.281-2.967h6.645l4.035-2.61,6.23-4.569Z",
      translate: { x: "-1427.841", y: "-2199.229" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ANIOCHA-2",
      name: "ANIOCHA",
      fill: "#001755",
      translate: { x: "824.82", y: "1051.724" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-13",
      name: "4M",
      fill: "#001755",
      translate: { x: "826.986", y: "1071.127" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Onitsha_north",
    path: {
      id: "Path_6875",
      dataName: "Onitsha north",
      d: "M842.857,2970.308l1.187-9.849,4.272-.89,5.459-1.839,1.78-2.077,4.094-.653,10.205,1.6,15.13,3.441,6.823-.534,1.721-.771v12.638l1.068,4.391v6.349l2.017,2.373,2.433.712v2.967l-4.094,8.366-6.645,4.806-11.214,3.026-1.424,1.958-.949,3.856-.949,2.136-2.611.712-2.373-1.9-2.611-4.094-.712-3.916-.356-3.026h-20l-7.773-3.026-1.839-3.916,1.6-4.45,2.67-7.891Z",
      translate: { x: "-196.616", y: "-1973.607" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Onitsha_north-2",
      name: "Onitsha north",
      fill: "#001755",
      translate: { x: "565.626", y: "1001.115" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-14",
      name: "4M",
      fill: "#001755",
      translate: { x: "655.497", y: "1002.73" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ONITSHA_South",
    path: {
      id: "Path_6876",
      dataName: "ONITSHA South",
      d: "M712.7,3322.247l5.577-8.247h.949l6.527,2.551h19.936l.356,2.729-.593,1.068-3.026,9.849c-1.148,1.207-3.513,3.7-3.8,4.035s-3.718.139-5.4,0l-.653,1.9-.415,2.552.415,4.865.653,8.425-5.874-.712-9.078-.653H705.1l-5.1-3.5v-4.924l10.977-9.019Z",
      translate: { x: "-77.195", y: "-2290.007" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ONITSHA_SOUTH-2",
      name: "ONITSHA\n          SOUTH",
      fill: "#001755",
      translate: { x: "566.294", y: "1039.01" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-15",
      name: "4M",
      fill: "#001755",
      translate: { x: "634.256", y: "1049.01" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "idemili_south",
    path: {
      id: "Path_6877",
      dataName: "idemili south",
      d: "M971.856,3377.054l.653-1.78h5.221l3.975-3.975,3.026-9.908.475-.89,1.009,4.153,2.729,4.094a8.105,8.105,0,0,0,2.255,1.661,14.449,14.449,0,0,0,2.611-.89h32.811l7.476,1.365h4.153l9.552,2.314,3.975,2.077h10.68l13.291,3.975,22.843.416,12.4-1.424h9.375l3.085-.475,11.926-1.483-.475,8.6-9.375,18.571-4.45,15.664,2.373,5.1v2.136h-4.806l-12.638-1.246-15.189-9.2-11.807-17.562-7.772-5.4-5.993-.949-9.909,6.348-8.129,5.043-12.816,4.925-18.749,1.365-18.868-1.365-7.891-3.145-7.535-6.527-5.993-5.755h-2.789L971.5,3379.6Z",
      translate: { x: "-316.478", y: "-2330.989" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "idemili_south-2",
      name: "idemili\n          south",
      fill: "#001755",
      translate: { x: "771.659", y: "1061.576" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-16",
      name: "4M",
      fill: "#001755",
      translate: { x: "710.199", y: "1063.01" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Ekwusigo",
    path: {
      id: "Path_6878",
      d: "M1069.42,3658.021l-2.136-11.866-10.62,4.094-18.453,1.424-19.224-1.424-7.3-2.729-5.933-4.984-7.713-7.061L996.5,3635v4.628l6.408,12.756,19.758,41.889-1.6,8.366L1034,3723.7l35.422,28.955,8.662-2.2,3.8-4.569-3.8-15.13-3.8-4.331-4.865-6.764-7.832-14.6v-10.8l4.035-16.257Z",
      translate: { x: "-338.512", y: "-2572.915" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Ekwusigo-2",
      name: "Ekwusigo",
      fill: "#001755",
      translate: { x: "676.679", y: "1099.37" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-17",
      name: "4M",
      fill: "#001755",
      translate: { x: "687.852", y: "1117.242" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Nnewi_north",
    path: {
      id: "Path_6879",
      dataName: "Nnewi north",
      d: "M1552.332,3711.295l-7.832-14.6.3-11.214,3.916-16.139,3.679-20.054-.949-5.162-1.009-5.992v-.415l1.958-.831,5.281-3.323,12.578-8.069,6.052,1.068,7.713,5.34,11.689,17.385,15.248,9.315,2.729.356-5.459,14.477-1.543,2.907-4.45,8.484-.712.653-9.552,10.917-23.14,18.512-7.417,3.857-1.721,2.076-2.492-2.907Z",
      translate: { x: "-821.482", y: "-2564.543" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Nnewi_North-2",
      name: "Nnewi \n          North",
      fill: "#001755",
      translate: { x: "738.385", y: "1096.242" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-18",
      name: "4M",
      fill: "#001755",
      translate: { x: "740.42", y: "1128.918" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Oyi",
    path: {
      id: "Path_6880",
      d: "M1324,2432.32v-6.764l.178-2.729,1.424-.712,1.305-5.222.415-4.984.059-1.009,3.085-5.7,1.483-1.9,1.958-2.551,4.391-5.7,4.509-4.154,3.441-3.085,3.857-4.925,2.848-3.56,2.551-3.382,3.026-4.035,5.933-8.188,3.8-3.145,8.722-1.305,9.375-3.975,6.527-3.56,8.188-2.492,29.192-1.72,2.136-.534a8.946,8.946,0,0,1,0,3.026,31.413,31.413,0,0,0-1.068,4.687l-4.569,7.951v16.316l-2.077,15.545-3.857,12.638-7,12.579-9.137,8.544-17.5,9.256-19.164,4.806-23.614,6.349-13.943.831-3.323-.831-2.017-2.492v-6.289l-1.127-4.45Z",
      translate: { x: "-627.148", y: "-1437.756" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_5M",
      name: "5M",
      fill: "#001755",
      translate: { x: "746.106", y: "968.436" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "Oyi-2",
      name: "Oyi",
      fill: "#001755",
      translate: { x: "747.106", y: "949.808" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Dunukofia",
    path: {
      id: "Path_6862",
      d: "M1976.314,2394.33l-5.34-3.678-.356-.3-.119-.534.356-.238.534-.237,3.085-1.6,2.789-2.67,2.136-1.958,1.721-1.6,2.492-2.373.771-1.305,1.068-1.9,2.017-3.679,1.78-3.2,1.365-2.433.415-1.424.771-2.433.949-3.2.771-2.314.356-1.306.653-2.017.178-1.6.415-2.967.949-7.476.475-3.263v-16.554l4.569-7.95.3-1.6.475-2.2.415-1.127v-1.6l-.178-1.246,1.246-.3,2.907-5.043,2.907,1.661,2.907,1.483,5.34,4.332,7.239,8.366,7.832,13.409,3.8,7.595v11.273l6.349,4.391-1.661.89-6.7,2.2-9.612,4.035-18.749,10.383-17.385,13.765Z",
      translate: { x: "-1196.932", y: "-1397.215" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_4M-19",
      name: "4M",
      fill: "#001755",
      translate: { x: "804.659", y: "954.716" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "Dunukofia-2",
      name: "Dunukofia",
      fill: "#001755",
      translate: { x: "801.486", y: "938.779" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ORUMBA_NORTH",
    path: {
      id: "Path_6873",
      dataName: "ORUMBA NORTH",
      d: "M2876.059,2829.518l-9.315,1.008-2.374,2.136,1.721,5.162-2.789,3.263v7l5.993,5.1-12.282,13.884v4.984l-3.2,1.9-4.747,2.611-5.518,2.67-4.272,4.806-3.738,3.2-4.094,3.382-5.874,4.509-11.807,2.077-3.2-.949-.356.475-18.453,23.081-8.247,8.128v2.67h7.239l9.493.89,10.977,2.2,5.814,1.958,7.476,2.432,7.713,6.171c1.5,1.5,4.723,4.747,5.577,5.7s2.334,5.023,2.967,6.942l2.789,6.349,3.382,3.5h1.661l6.408-2.848,8.544-5.162,12.519-6.289,11.214-3.679,9.552-1.958h42.008l11.51-.712,4.153-.593,1.6-1.839v-2.551l-1.6-2.374-1.365-1.9-1.009-3.8v-15.486l-2.907-4.865-5.518-6.23-12.816-7.772-7.3-7.061-4.094-6.7v-12.875l4.094-11.748-4.687-4.687-7.891-9.553c-1.563-2.887-4.747-8.817-4.984-9.434s-2.353-3.9-3.382-5.458l-5.874-7.179-4.569,3.145-3.8,2.374c-2,.336-6.135,1.008-6.7,1.008S2877.7,2829.854,2876.059,2829.518Z",
      translate: { x: "-1913.457", y: "-1858.153" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ORUMBA_NORTH-2",
      name: "ORUMBA NORTH",
      fill: "#001755",
      translate: { x: "906.18", y: "1054.575" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-20",
      name: "4M",
      fill: "#001755",
      translate: { x: "938.343", y: "1078.127" },
      tspan: { X: "0", y: "0" },
    },
  },
];
