export const data = [
  {
    state: "IDEATO_NORTH",
    path: {
      id: "Path_7089",
      dataName: "IDEATO NORTH",
      d: "M406,5.443c4.327-3.145,13.288-3.322,13.288-3.322s5.375,12.4,7.474,20.762c1.287,5.12,2.913,8.025,2.491,13.288-.442,5.524-2.957,8.13-4.983,13.288-3.349,8.522-9.135,21.593-9.135,21.593S409.673,82.244,406,89.323c-4.081,7.855-10.8,19.932-10.8,19.932l-4.152,27.406s-8.6-9.293-11.627-16.61c-2.043-4.943-1.1-8.423-3.322-13.288-2.319-5.075-4.907-7.2-8.3-11.627-6.3-8.2-8.283-15.123-17.44-19.932-5.365-2.817-8.891-4.232-14.949-4.152-6.871.09-10.172,3.407-16.61,5.813-19.988,7.47-49.829,22.423-49.829,22.423s-5.838-27.423-12.457-44.016c-2.1-5.261-1.918-9.175-5.813-13.288-6-6.341-12.859-5.842-21.593-5.813a66.591,66.591,0,0,0-11.627,1.661L210,27.866s5.3-3.653,9.135-4.983c4.637-1.606,7.856.049,12.457-1.661a29.768,29.768,0,0,0,7.443-4.131l.032-.022c5.292-3.637,5.963-8.6,11.627-11.627,5-2.668,8.474-2.849,14.118-3.322,6.818-.571,10.64.907,17.44,1.661,9.757,1.081,15.474.635,24.915,3.322a136.232,136.232,0,0,1,13.288,4.983s14.255.932,23.254,2.491c7.21,1.249,11.3,1.919,18.271,4.152,11.326,3.631,27.406,13.288,27.406,13.288s5.812-7.939,9.135-13.288C401.664,13.674,401.18,8.944,406,5.443Z",
      translate: { x: "509.306", y: "619.977" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M",
      name: "3M",
      fill: "#001755",
      translate: { x: "853.159", y: "686.304" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "IDEATO_NORTH-2",
      name: "IDEATO NORTH",
      fill: "#001755",
      translate: { x: "810.659", y: "661.138" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IDEATO_SOUTH",
    path: {
      id: "Path_7090",
      dataName: "IDEATO SOUTH",
      d: "M359.277,99.28c2.565,4.369,8.3,9.966,8.3,9.966l-2.491,10.8-3.322,10.8-6.644,6.644-16.61-3.322a103.682,103.682,0,0,0-9.966-5.813c-7.333-3.461-11.853-5.126-19.932-5.813-7.139-.607-18.271,1.661-18.271,1.661s-2.139-7.563-3.322-12.457c-1.478-6.121-.462-10.169-3.322-15.779-2.508-4.921-4.528-7.751-9.135-10.8-5.054-3.341-9.408-1.7-14.949-4.152-6.006-2.657-14.118-9.135-14.118-9.135L302.8,45.3s8.629-2.486,14.118-1.661c6.455.97,10.079,3.128,14.949,7.474,3.1,2.766,4.108,5.016,6.644,8.3a215.033,215.033,0,0,1,14.118,19.932c5.813,9.135,1.237,6.155,2.491,9.966A43.591,43.591,0,0,0,359.277,99.28Z",
      translate: { x: "532.771", y: "647.392" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M-2",
      name: "3M",
      fill: "#001755",
      translate: { x: "842.218", y: "750.737" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "IDEATO_SOUTH-2",
      name: "IDEATO SOUTH",
      fill: "#001755",
      translate: { x: "791.797", y: "724.162" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OKIGWE",
    path: {
      id: "Path_7091",
      d: "M347.618,32.506c0-8.3-9.966-29.9-9.966-29.9h17.44A50.036,50.036,0,0,1,364.228.117c6.514-.768,16.61,2.491,16.61,2.491l11.627,12.457c4.541,4.865,13.656,1.606,22.423,2.492,11.014,1.112,17.4.236,28.237,2.491,8.637,1.8,12.922,5.015,21.593,6.644,5.774,1.084,9.107,2.272,14.949,1.661,5.406-.566,8.278-2.039,13.288-4.152,3.34-1.41,8.3-4.152,8.3-4.152l5.813,8.3s-2.02,6.582-3.322,10.8c-3.211,10.388-8.3,26.576-8.3,26.576a183.035,183.035,0,0,1,5.813,19.1c1.636,7.672,1.616,12.136,2.491,19.932,3.877,34.541.716,54.368,4.983,88.863.6,4.87,3.86,8.069,1.661,12.457a10.5,10.5,0,0,1-4.983,4.983c-4.252,2.362-12.457,0-12.457,0s-12.625-5.134-20.762-8.3c-6.792-2.646-10.888-3.452-17.44-6.644-5.75-2.8-8.3-3.322-14.118-8.3s-24.084-34.051-24.084-34.051h-8.3s-24.578-18.322-35.711-34.05c-8.8-12.43-7.944-22.973-17.44-34.881-2.721-3.411-3.814-5.932-7.474-8.3C342.866,73.441,333.5,73.2,333.5,73.2a242.374,242.374,0,0,0,9.966-23.254C345.009,45.627,347.618,40.811,347.618,32.506Z",
      translate: { x: "590.937", y: "618.658" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M-3",
      name: "3M",
      fill: "#001755",
      translate: { x: "1016.061", y: "730.688" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "OKIGWE-2",
      name: "OKIGWE",
      fill: "#001755",
      translate: { x: "1000.74", y: "695.612" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "UNUIMO",
    path: {
      id: "Path_7092",
      d: "M329.6,81.112,348.7,44.57s9.139-.068,14.118,2.491c6.983,3.59,12.457,15.779,12.457,15.779s4.754,15.341,9.966,24.084c6.126,10.273,12.9,15.037,21.593,23.254,6.368,6.02,15.779,14.118,15.779,14.118s-6.986,3.289-11.627,4.983c-5.4,1.97-14.118,4.152-14.118,4.152s-8.77-1.341-14.118,0c-6.205,1.556-14.118,8.3-14.118,8.3s-11.3,9.331-19.932,12.457c-14.027,5.081-38.2,0-38.2,0l2.491-17.44a56.85,56.85,0,0,0,5.813-7.474c3.9-6.65,4.983-19.1,4.983-19.1Z",
      translate: { x: "575.733", y: "648.119" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M-4",
      name: "3M",
      fill: "#001755",
      translate: { x: "927.54", y: "773.136" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "ONUIMO-2",
      name: "ONUIMO",
      fill: "#001755",
      translate: { x: "912.039", y: "752.136" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ORLU",
    path: {
      id: "Path_7093",
      d: "M224.737,47.006c.1-8.457,1.661-21.593,1.661-21.593s10.017-3.873,16.61-3.322c6.961.582,11.627.83,16.61,6.644s11.627,30.728,11.627,30.728l7.474,26.576a26.615,26.615,0,0,0,5.813,4.983,72.129,72.129,0,0,0,10.8,4.983c4.762,1.9,6.521-.339,10.8,2.492a29.48,29.48,0,0,1,10.8,12.457c2.216,5.09,1.43,9.5,2.491,14.949.963,4.942,2.491,11.627,2.491,11.627s-7.5-2.637-12.457-2.491c-4.325.127-6.7,1.1-10.8,2.491-11.675,3.963-25.745,18.272-25.745,18.272s-11.472,7.923-19.932,9.966c-8.828,2.131-14.4,2.025-23.254,0-8.767-2-14.932-3.118-20.762-9.966-4.333-5.092-4.461-9.46-6.644-15.78-3.158-9.146-5.813-24.084-5.813-24.084s11.1-7,17.44-12.457c5.214-4.489,12.457-7.475,12.457-12.457s.191-11.138,0-18.271C226.129,62.68,224.612,57.081,224.737,47.006Z",
      translate: { x: "500.382", y: "633.226" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M-5",
      name: "3M",
      fill: "#001755",
      translate: { x: "741.169", y: "769.101" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "ORLU-2",
      name: "ORLU",
      fill: "#001755",
      translate: { x: "732.594", y: "749.17" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ORSU",
    path: {
      id: "Path_7094",
      d: "M243.049,17.57l8.3,9.966s-1.628,15.1-1.661,24.915c-.025,7.165,1.282,13.608,1.661,20.762.532,10.04-1.661,23.254-1.661,23.254s-6.36,6.328-10.8,9.966c-6.679,5.475-18.271,12.457-18.271,12.457s-.746-5.4-2.491-8.3c-2.375-3.95-4.895-5.666-9.135-7.474-5.966-2.545-16.61,0-16.61,0s-7.134-5.887-12.457-8.3C172.692,91.519,160,90.653,160,90.653s15.954-3.929,24.915-9.135a119.092,119.092,0,0,0,11.627-8.3s.3-5.291,1.661-8.3c2.362-5.229,6.7-5.949,10.8-9.966,10.175-9.974,14.191-17.152,24.084-27.406C236.9,23.575,243.049,17.57,243.049,17.57Z",
      translate: { x: "476.257", y: "630.272" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M-6",
      name: "3M",
      fill: "#001755",
      translate: { x: "690.805", y: "728.414" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "ORSU-2",
      name: "ORSU",
      fill: "#001755",
      translate: { x: "683.374", y: "701.351" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "NKWERRE",
    path: {
      id: "Path_7095",
      d: "M281.533,90.108c4.385.486,10.8,3.322,10.8,3.322a95.8,95.8,0,0,1,14.118-1.661c7.891-.005,12.53.584,19.932,3.322,6.679,2.47,15.779,9.135,15.779,9.135a19.179,19.179,0,0,1-2.491,3.323c-2.541,2.4-5.031,2.1-8.3,3.322-4.805,1.791-8.237,1.239-12.457,4.152-4.965,3.427-3.922,9.423-9.135,12.457a17.57,17.57,0,0,1-7.474,2.491c-5.23.718-8.087-3.4-13.288-2.491-6.837,1.2-8.494,6.729-14.118,10.8-3.207,2.319-8.3,5.813-8.3,5.813l-8.3-8.3s-.99-7.994-3.322-12.457c-3.186-6.1-12.457-12.457-12.457-12.457s8.86-8.741,18.271-14.119C266.584,93.43,274.642,89.345,281.533,90.108Z",
      translate: { x: "530.787", y: "678.156" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M-7",
      name: "3M",
      fill: "#001755",
      translate: { x: "810.659", y: "800.661" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "NKWERRE-2",
      name: "NKWERRE",
      fill: "#001755",
      translate: { x: "800.693", y: "790.695" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "NWANGELE",
    path: {
      id: "Path_7096",
      d: "M336.676,98.57l15.779,1.661-2.491,18.27s-6.644,14.949-10.8,25.745c-2.938,7.641-5.478,12.037-11.627,17.44-4.262,3.744-7.008,5.9-12.457,7.474-5.92,1.712-9.624.3-15.779,0-6.511-.316-16.61-1.661-16.61-1.661l-22.423,18.271a95.609,95.609,0,0,1-4.152-9.135c-3.242-9.378-4.084-15.034-4.983-24.915-.764-8.4,0-21.593,0-21.593l10.8,8.3a108.292,108.292,0,0,0,10.8-8.3c3.021-2.814,3.558-6.169,7.474-7.474,2.769-.923,4.583-.392,7.474,0,3.355.455,6.754,2.732,9.966,1.661,4.983-1.661,5.978-.747,9.135-4.152,2.867-3.091,2.7-7.127,5.813-9.966,2.583-2.352,3.34-2.186,6.644-3.322,4.76-1.638,8.307-.472,12.457-3.322A30.315,30.315,0,0,0,336.676,98.57Z",
      translate: { x: "536.271", y: "683.812" },
      fill: "#cc914d",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M-8",
      name: "3M",
      fill: "#001755",
      translate: { x: "827.27", y: "837.201" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "NWANGELE-2",
      name: "NWANGELE",
      fill: "#001755",
      translate: { x: "805.677", y: "827.235" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "NJABA",
    path: {
      id: "Path_7097",
      d: "M294.769,120.645c2.271,4.767,2.491,13.288,2.491,13.288s-10.56-2.491-17.44-2.491-11.346-.7-17.44,2.491c-4.677,2.45-7.237,4.616-9.966,9.135-3.091,5.119-1.661,15.779-1.661,15.779s8.4,8.277,11.627,12.457c3.737,4.845,2.113,3.367,4.152,9.135,3.136,8.868,0,24.915,0,24.915l-8.3,4.983s-13.766-1.968-21.593-2.491c-9.061-.606-21.593,0-21.593,0s1.865-17.87,0-29.067c-1.046-6.285-2.274-9.69-4.152-15.779-3.392-10.992-4.445-17.816-10.8-27.406-4.312-6.513-10.363-7.707-13.288-14.949-2.442-6.044-5.053-11.042-1.661-16.61,2.721-4.466,6.772-4.7,11.627-6.643,5.259-2.106,14.118-3.322,14.118-3.322s1.9,6.4,4.152,9.965c2.942,4.652,4.744,8.225,9.966,9.966,7.474,2.491,10.8,4.983,20.762,4.983,7,0,14.208-.028,20.762-2.491,6.519-2.45,15.779-8.3,15.779-8.3S291.81,114.435,294.769,120.645Z",
      translate: { x: "491.806", y: "680.837" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M-9",
      name: "3M",
      fill: "#001755",
      translate: { x: "717.645", y: "822.252" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "NJABA-2",
      name: "NJABA",
      fill: "#001755",
      translate: { x: "711.001", y: "812.286" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ORU_EAST",
    path: {
      id: "Path_7098",
      dataName: "ORU EAST",
      d: "M199.439,64.57l10.8,7.474a31.182,31.182,0,0,1,5.813-1.661,18.083,18.083,0,0,1,10.8,1.661c4.231,1.832,6.76,3.524,9.135,7.474,1.746,2.9,2.491,8.3,2.491,8.3l6.644,25.745a72.825,72.825,0,0,0-11.627,1.661c-4.764,1.311-7.893,1.748-11.627,4.983-2.991,2.593-5.813,5.177-5.813,9.135,0,4.983,2.844,8.763,6.644,14.118,3.056,4.305,5.835,5.845,9.135,9.966,4.533,5.661,5.483,9.929,8.3,16.61,4.872,11.532,9.135,30.728,9.135,30.728l-1.661,27.406s-5.569-.093-9.135,0c-7.808.2-12.434-.527-19.932,1.661-5.85,1.706-14.118,6.644-14.118,6.644h-7.474c-2.918,0-16.61-1.661-16.61-1.661s-11.889-9.85-19.1-16.61C149.52,207.226,133,188.313,133,188.313s7.1-5.963,12.457-8.3c4.614-2.016,7.423-3.322,12.457-3.322,6.644,0,4.87,2.686,8.3,3.322a35.788,35.788,0,0,0,8.3,0s8.122-4.89,10.8-9.966c2.164-4.109,2.491-6.983,2.491-11.627,0-3.322-2.491-9.966-2.491-15.779a18.756,18.756,0,0,1,2.491-9.135c1.864-3.161,1.827-4.152,6.644-7.474,3.471-2.393,8.64-1.712,9.966-3.321a10.672,10.672,0,0,0,2.491-9.135c-.5-3.749-2.068-5.894-4.983-8.3-3.161-2.615-7.474-4.152-9.966-4.152-6.644,0-9.135,1.661-17.44,1.661-2.771,0-3.985,3.27-6.644,2.491a6,6,0,0,1-3.322-2.491c-1.457-1.858-2.173-3.508-1.661-5.813,1.661-7.474,3.322-17.44,3.322-17.44Z",
      translate: { x: "458.41", y: "661.338" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M-10",
      name: "3M",
      fill: "#001755",
      translate: { x: "659.51", y: "862.115" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "ORU_EAST-2",
      name: "ORU EAST",
      fill: "#001755",
      translate: { x: "637.918", y: "852.149" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ORU_WEST",
    path: {
      id: "Path_7099",
      dataName: "ORU WEST",
      d: "M201.9,63.231l10.8,3.322L180.31,81.5l-4.152,16.61s.271,8.6,4.152,9.135c2.031.28,3-1.121,4.983-1.661a44.767,44.767,0,0,1,11.627-1.661c4.872-.2,7.9-.905,12.457.83,5.161,1.966,9.468,3.775,10.8,9.135a12.035,12.035,0,0,1,0,5.813c-1.57,8.681-13.934,5.606-18.271,13.287-4.476,7.928-1.435,14.152-1.661,23.254-.121,4.863,1.538,7.841,0,12.457a17.2,17.2,0,0,1-7.474,9.966c-3.39,2.309-5.863,3.322-9.966,3.322s-5.875-3.008-9.966-3.322c-5.333-.41-8.343,1.282-13.288,3.322-5.657,2.334-13.288,8.3-13.288,8.3s-18.885-12.721-25.745-24.915a50.419,50.419,0,0,1-5.813-14.118,48.237,48.237,0,0,1-1.661-16.61c.394-8.33,4.983-20.762,4.983-20.762s24.915,20.762,28.237,23.253,5.31,1.244,8.3,0c4.074-1.691,6.644-9.135,6.644-9.135s.914-14.18,1.661-23.253c.588-7.14-.143-11.337,1.661-18.271,1.5-5.771,2.138-9.423,5.813-14.118,5.634-7.2,20.762-10.8,20.762-10.8Z",
      translate: { x: "445.15", y: "659.355" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M-11",
      name: "3M",
      fill: "#001755",
      translate: { x: "599.715", y: "822.252" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "ORU_WEST-2",
      name: "ORU WEST",
      fill: "#001755",
      translate: { x: "579.783", y: "812.286" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ISU",
    path: {
      id: "Path_7100",
      d: "M256.556,116.689a115.571,115.571,0,0,1,13.288,1.661s-.523,13.152,0,21.593c.512,8.253-.286,12.974,2.491,20.762a114.583,114.583,0,0,0,5.813,12.457L274,184.789l-9.966,3.322s-7.577-2.187-12.457-1.661c-4.794.517-11.627,4.152-11.627,4.152s.8-10.677,0-17.44a43.876,43.876,0,0,0-1.661-9.135c-2.809-9.115-14.118-19.932-14.118-19.932s-1.669-11.006,1.661-16.61c3.081-5.187,6.866-6.866,12.457-9.135C244.924,115.656,249.4,116.448,256.556,116.689Z",
      translate: { x: "518.393", y: "695.592" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M-12",
      name: "3M",
      fill: "#001755",
      translate: { x: "759.169", y: "853.813" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "ISU-2",
      name: "ISU",
      fill: "#001755",
      translate: { x: "759.169", y: "843.847" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ISIALA_MBANO",
    path: {
      id: "Path_7101",
      dataName: "ISIALA MBANO",
      d: "M422.259,98.205c4.476-.762,11.627,0,11.627,0s.54,12.672,0,20.763c-.61,9.154-1.076,14.359-3.322,23.254-2.286,9.054-3.222,14.59-8.3,22.423-2.757,4.25-4.131,7.094-8.3,9.966-3.971,2.734-7.549,1.58-11.627,4.152-4.853,3.061-9.966,10.8-9.966,10.8L390.7,236.067s-9.486,3.528-15.779,3.322c-6.768-.221-10.338-2.43-16.61-4.983-9.017-3.669-21.593-12.457-21.593-12.457l-23.254-6.644s-6.538-6.406-11.627-9.135c-6.244-3.347-10.537-3.4-17.44-4.983-11.489-2.638-29.9-4.152-29.9-4.152l3.322-10.8s4.581-3.511,7.474-5.813c5.946-4.732,14.949-12.457,14.949-12.457s31.559,4.152,38.2,0,7.214-5.485,10.8-9.966c4.981-6.232,5.853-11,9.135-18.271,3.593-7.959,8.3-20.762,8.3-20.762a116.5,116.5,0,0,0,12.457,2.491c6.446.724,10.2.99,16.61,0a47.3,47.3,0,0,0,15.779-4.983c7.282-3.566,9.486-8.587,16.61-12.457C413.379,101.172,416.381,99.205,422.259,98.205Z",
      translate: { x: "538.719", y: "683.347" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M-13",
      name: "3M",
      fill: "#001755",
      translate: { x: "887.064", y: "863.776" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "ISIALA_MBANO-2",
      name: "ISIALA MBANO",
      fill: "#001755",
      translate: { x: "865.472", y: "853.811" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "EHIME_MBANO",
    path: {
      id: "Path_7102",
      dataName: "EHIME MBANO",
      d: "M379.685,102.536l25.745-9.966h9.135s6.268,10.337,10.8,16.609c5.129,7.106,7.469,11.731,14.118,17.44a59.288,59.288,0,0,0,14.118,9.135c7.057,3.546,19.1,6.644,19.1,6.644s-12.52,5.315-15.779,12.457c-2.962,6.491,0,11.135,0,18.271,0,12.324,3.721,19.809,0,31.559-2.99,9.439-5.784,15.133-13.288,21.593-6.046,5.2-18.271,9.135-18.271,9.135s-9.032-2.354-14.949-2.491c-6.878-.159-10.813.643-17.44,2.491-6.44,1.8-9.258,5.167-15.779,6.644-8.225,1.862-13.18.578-21.593,0-7.5-.515-19.1-2.491-19.1-2.491l1.661-47.338s6.053-6.739,10.8-9.966c5.039-3.427,9.605-2.548,14.118-6.644,4.945-4.488,5.573-8.855,8.3-14.949,5.212-11.629,6.837-18.9,8.3-31.559C380.881,118.8,379.685,102.536,379.685,102.536Z",
      translate: { x: "592.919", y: "679.846" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M-14",
      name: "3M",
      fill: "#001755",
      translate: { x: "992.672", y: "888.43" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "EHIME_MBANO-2",
      name: "EHIME MBANO",
      fill: "#001755",
      translate: { x: "962.74", y: "870.776" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IHITTE/UBOMA",
    path: {
      id: "Path_7103",
      dataName: "IHITTE/UBOMA",
      d: "M443.948,122.57l18.271,8.3a20.855,20.855,0,0,0,0,4.983c.829,4.333,3.691,5.858,6.644,9.135,5.43,6.023,11.3,6.325,16.61,12.457a41.244,41.244,0,0,1,7.474,11.627c3.4,7.536,1.038,13.1,4.152,20.762,2.408,5.926,5.747,8.255,8.3,14.118,2.834,6.493,4.983,17.44,4.983,17.44,0,5.813-6.644,7.474-6.644,7.474a54.092,54.092,0,0,1,0,6.644,54.324,54.324,0,0,1-2.491,9.966,81.624,81.624,0,0,0-10.8-7.474c-7.72-4.011-12.893-4.684-21.495-5.8l-.1-.013c-8.363-1.086-21.593,0-21.593,0s-8.4-7.164-14.949-9.135c-7.453-2.244-12.183-.744-19.932,0-8.23.791-20.762,4.152-20.762,4.152s-5.928.342-8.3-2.491c-2.146-2.558-3.322-10.8-3.322-10.8a66.38,66.38,0,0,1,8.3,0,66.445,66.445,0,0,1,8.3,1.661s9.115-3.166,14.118-6.644c8.526-5.928,12.984-11.032,16.61-20.762,3.292-8.835,1.639-14.656,1.661-24.084.03-13.288-1.661-15.779-1.661-24.915,0-4.352,2.081-6.724,4.983-9.966C435.807,125.317,443.948,122.57,443.948,122.57Z",
      translate: { x: "621.672", y: "699.675" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M-15",
      name: "3M",
      fill: "#001755",
      translate: { x: "1071.433", y: "906.962" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "IHITTE_UBOMA-2",
      name: "IHITTE/UBOMA",
      fill: "#001755",
      translate: { x: "1122.093", y: "877.776" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "AHIAZU_MBAISE",
    path: {
      id: "Path_7104",
      dataName: "AHIAZU MBAISE",
      d: "M403.686,177.176a51,51,0,0,1,9.966,0l.831,4.983,4.983,7.474,6.644,2.491,1.661,23.254-4.152,8.3s-1.186,8.526,0,13.288c1.445,5.8,8.3,10.8,8.3,10.8l9.135,2.491s-.035,6.853-1.661,10.8c-2.106,5.106-9.135,10.8-9.135,10.8s-12.61,2.016-20.762,2.491c-7.77.453-12.148,0-19.932,0H371.3s-5.656,4.827-9.966,6.644c-4.279,1.8-11.627,2.491-11.627,2.491l-11.627-5.813s-1.933-5.6-3.322-9.135c-2.339-5.945-6-8.594-6.644-14.949-.568-5.569-.51-9.391,2.492-14.118,2.471-3.892,5.843-4.249,9.135-7.474,4.45-4.36,8.092-6.516,9.966-12.457,1.56-4.95,2.209-8.592,0-13.288-1.757-3.736-4.468-4.644-7.474-7.474-2.844-2.677-6.307-2.917-7.474-6.644-.679-2.166,0-5.813,0-5.813l7.474-1.661,35.711,3.322s7.074-3.066,11.627-4.983C395.064,180.676,397.794,178.09,403.686,177.176Z",
      translate: { x: "587.189", y: "735.594" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M-16",
      name: "3M",
      fill: "#001755",
      translate: { x: "960.147", y: "971.742" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "AHIAZU_MBAISE-2",
      name: "AHIAZU MBAISE",
      fill: "#001755",
      translate: { x: "925.26", y: "992.742" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OBOWO",
    path: {
      id: "Path_7105",
      d: "M488.826,199.512c3.78,1.719,8.3,6.644,8.3,6.644l-8.3,8.3a45.863,45.863,0,0,1,2.491,8.3c.46,3.211,1.3,5.333,0,8.3-1.38,3.159-6.644,5.813-6.644,5.813s.9,12.065-1.661,19.1c-3.309,9.092-15.779,19.1-15.779,19.1s-20.413-7.263-32.389-14.118c-8.509-4.872-20.762-14.118-20.762-14.118a54.487,54.487,0,0,1-9.135,0c-5.084-.674-8.421-.988-12.457-4.152-5.137-4.026-6.94-8.444-7.474-14.949-.5-6.038,4.152-14.949,4.152-14.949V187.885s20.865-5.72,30.728-5.813c7.474-.07,7.592.606,14.949,4.152,4.382,2.113,9.966,7.474,9.966,7.474s10.177-2.472,18.271-1.661C472.572,192.988,479.691,195.36,488.826,199.512Z",
      translate: { x: "624.962", y: "739.001" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M-17",
      name: "3M",
      fill: "#001755",
      translate: { x: "1056.619", y: "976.789" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "OBOWO-2",
      name: "OBOWO",
      fill: "#001755",
      translate: { x: "1042.619", y: "956.727" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IKEDURU",
    path: {
      id: "Path_7106",
      d: "M376.317,193.485a35.238,35.238,0,0,0,7.474-1.661s.106,2.706,0,4.152c-.171,2.332.218,2.312,1.661,4.152,3.689,4.707,9.634,4.694,12.457,9.966,2.043,3.814,2.013,6.484,1.661,10.8-.485,5.935-3.531,8.826-7.474,13.288-3.995,4.521-8.317,5.754-11.627,10.8-3.456,5.264-5.191,7.906-4.152,14.118.835,5.008,3.731,7,5.813,11.627,2.149,4.779,4.983,12.457,4.983,12.457a37.426,37.426,0,0,1-4.983,3.322c-3.591,1.789-5.988,1.972-9.966,2.491-4.5.59-7.239-1.169-11.627,0-5.216,1.39-7.179,4.417-11.627,7.474-7.056,4.85-17.44,13.288-17.44,13.288s-7.624-13.542-15.779-18.271c-6.4-3.711-10.089-1.09-15.779-5.813-4.154-3.447-6.308-6.827-9.966-10.8-4.088-4.436-7.385-5.87-12.457-9.135-7.685-4.946-20.762-10.8-20.762-10.8s-4.7-9.946-2.492-15.779c1.312-3.458,3.706-4.435,5.813-7.474,3.805-5.488,6.654-8.478,8.3-14.949,1.6-6.285-2.789-10.753,0-16.61,2.169-4.554,5.453-7.348,9.135-10.8,5.881-5.506,8.993-7.287,15.779-11.627,7.214-4.613,19.932-9.135,19.932-9.135l24.084,6.644s13.884,10.062,24.915,14.118C367.592,191.315,370.577,193.757,376.317,193.485Z",
      translate: { x: "538.153", y: "730.08" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M-18",
      name: "3M",
      fill: "#001755",
      translate: { x: "851.354", y: "974.86" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "IKEDURU-2",
      name: "IKEDURU",
      fill: "#001755",
      translate: { x: "842.218", y: "948.285" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "MBAITOLI",
    path: {
      id: "Path_7107",
      d: "M359.521,168.7c3.912,2.438,8.3,7.474,8.3,7.474s-12.06,4.232-19.1,8.3c-6.234,3.606-9.738,5.825-14.949,10.8-4.838,4.616-9.072,6.828-10.8,13.288-1.088,4.074,0,6.581,0,10.8a40.553,40.553,0,0,1,0,4.983c-.639,6.059-3.149,9.127-6.644,14.118-2.5,3.574-6.069,4.174-7.474,8.3-1.149,3.377-.716,5.641,0,9.135a35.7,35.7,0,0,0,2.491,6.644s-5.742,4.634-9.966,6.644c-9.154,4.357-15.613,3-25.745,3.322-10.07.319-25.745-1.661-25.745-1.661s-13.059-7.773-21.593-12.457c-4.833-2.653-6.954-6.3-12.457-6.644-4-.247-6.028,1.724-9.966,2.491a98.637,98.637,0,0,1-13.288,1.661c-8.408.646-21.593,0-21.593,0s1.4-11.33,4.983-17.44a56.934,56.934,0,0,1,5.813-7.474s.307-14.683,0-24.084c-.415-12.68-2.491-32.389-2.491-32.389l9.966-4.152c3.892-1.621,13.1-2.553,21.593-3.322,12.275-1.111,19.242-.458,31.559,0,9.745.362,24.915,1.661,24.915,1.661s6.524-5.712,11.627-7.474c7.051-2.437,19.1,0,19.1,0l10.8-4.152s9.62,1.495,15.779,2.491c7.79,1.261,12.378,1.038,19.932,3.322C350.279,164.609,354.46,165.544,359.521,168.7Z",
      translate: { x: "483.527", y: "722.479" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M-19",
      name: "3M",
      fill: "#001755",
      translate: { x: "729.271", y: "956.791" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "MBAITOLI-2",
      name: "MBAITOLI",
      fill: "#001755",
      translate: { x: "715.983", y: "946.825" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OGUTA",
    path: {
      id: "Path_7108",
      d: "M162.98,84.577c10.027,4.266,22.423,16.61,22.423,16.61s-3.221,9.476-4.152,15.779c-1.232,8.341-1.824,13.359,0,21.593,2.707,12.218,7.831,18.487,16.61,27.406,5.687,5.779,16.61,12.457,16.61,12.457S226.6,193.1,235.232,201.676c9.459,9.4,25.745,22.423,25.745,22.423s7.559,1.349,12.457,1.661c4.531.289,11.627,0,11.627,0l2.491,54.812s-5.981,7.092-8.3,12.457c-2.126,4.908-3.322,13.288-3.322,13.288s-9.14,1.9-14.949,3.322c-7.184,1.757-12.363.533-18.271,4.983-4.249,3.2-8.3,10.8-8.3,10.8s-9.431-6.169-14.949-10.8c-8.26-6.928-12.21-11.64-19.1-19.932-7.134-8.582-8.718-15.362-16.61-23.254-7.385-7.385-12.366-10.886-21.593-15.779-9.425-5-15.2-7.521-25.745-9.135-11.559-1.771-29.9,1.661-29.9,1.661L88.236,243.2,11,237.387l14.118-98.828s29.747-27.19,52.321-39.033c18.085-9.488,49-18.271,49-18.271S149.794,78.967,162.98,84.577Z",
      translate: { x: "377.771", y: "672.062" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M-20",
      name: "3M",
      fill: "#001755",
      translate: { x: "512.217", y: "876.115" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "OGUTA-2",
      name: "OGUTA",
      fill: "#001755",
      translate: { x: "501.717", y: "853.813" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OHAJI/EGBEMA",
    path: {
      id: "Path_7109",
      dataName: "OHAJI/EGBEMA",
      d: "M38.2,299.8C23.214,299.055,0,295.645,0,295.645L18.9,174.839l75.774,6.2,19.1,4.152h30.728s22.284,5.209,34.05,13.288c12.5,8.584,25.745,29.067,25.745,29.067s15.779,20.762,27.406,29.067c3.089,2.206,9.135,5.813,9.135,5.813l3.322,9.135s-9.062,15.628-12.457,26.576c-2.355,7.594-4.466,11.987-4.152,19.932.264,6.68.972,10.728,4.152,16.61,2.628,4.858,4.431,7.9,9.135,10.8,4.556,2.8,7.968,2.772,13.288,3.322a75.777,75.777,0,0,0,9.966,0s8.991,22.187,19.1,33.22c5.752,6.277,9.19,9.943,16.61,14.118,6.883,3.873,13.288,1.661,19.1,6.644s7.043,5.255,9.135,8.3c2.139,3.119,1.661,3.692,1.661,7.474s-3.3,4.92-4.983,8.3c-3.342,6.7-5.089,10.818-5.813,18.271-.759,7.808-.284,12.6,2.491,19.932,1.85,4.892,4.719,6.765,6.644,11.627a40.914,40.914,0,0,1,3.322,17.44c0,6.933-1.661,14.949-1.661,19.932s.372,7.016,4.152,11.627c3.084,3.762,9.966,7.474,9.966,7.474s-7.26,4.315-12.457,4.983-13.288-1.661-13.288-1.661-10.889,4.554-18.271,5.813c-8.951,1.526-23.254,0-23.254,0a38.841,38.841,0,0,1-6.644,5.813c-5.4,3.234-9.506,3.87-15.779,3.322-7.948-.694-18.271-9.135-18.271-9.135s-5.835-7.489-10.8-10.8-8.383-4.181-14.118-5.813c-5.692-1.621-9.16-1.259-14.949-2.491-8.235-1.752-20.762-5.813-20.762-5.813s-33.23-25.533-50.66-45.677c-5.435-6.282-11.433-8.513-13.288-16.61-1.384-6.039.347-9.723,1.661-15.779a114.247,114.247,0,0,1,4.983-14.949s-1.678-6.038-2.491-9.966c-1.591-7.682-2.72-12.09-2.491-19.932.27-9.283,4.983-23.254,4.983-23.254s-4.04-13.985-9.135-21.593c-2.819-4.209-7.03-5.063-8.3-9.966-1.154-4.44-1.186-8.031,1.661-11.627,1.9-2.4,5.571-.83,6.644-4.152s-.957-8.237-2.491-13.288c-1.646-5.42-1.371-9.775-5.813-13.288-3.154-2.495-5.788-2.814-9.727-3.292l-.239-.03C82.475,293,78.579,298.4,70.592,299.8,58.132,301.977,50.836,300.424,38.2,299.8Z",
      translate: { x: "370.5", y: "734.224" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M-21",
      name: "3M",
      fill: "#001755",
      translate: { x: "536.156", y: "1059.771" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "OHAJI_EGBEMA-2",
      name: "OHAJI/EGBEMA",
      fill: "#001755",
      translate: { x: "491.783", y: "1012.274" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OWERRI_WEST",
    path: {
      id: "Path_7110",
      dataName: "OWERRI WEST",
      d: "M272.215,232.974v12.457s-9.674,1.814-14.949,4.983c-4.918,2.955-5.5,7.184-9.135,11.627-7.547,9.233-8.635,15.595-13.288,26.576-1.927,4.548-4.824,6.689-4.983,11.627-.143,4.408,3.322,10.8,3.322,10.8l26.576,10.8s7.127-.874,10.8-3.322c3.249-2.168,3.564-5.073,6.644-7.474a20.118,20.118,0,0,1,7.474-4.152c4.347-1.312,11.627,0,11.627,0s5.988,6.161,9.966,9.966c3.815,3.649,9.966,9.135,9.966,9.135s-10.938,7.976-15.779,14.949c-3.89,5.6-5.37,9.293-7.474,15.779-2.535,7.81-1.153,12.843-3.322,20.762-1.927,7.029-5.986,10.182-6.644,17.44-.5,5.529.523,8.684,1.661,14.118,1.47,7.028,5.813,17.44,5.813,17.44a81.8,81.8,0,0,1,0,10.8c-.806,7.755-1.812,12.409-5.813,19.1-5.433,9.089-11.59,12.15-20.762,17.44-8.978,5.177-24.915,9.135-24.915,9.135s2.551-12.172,1.661-19.932a43,43,0,0,0-1.661-8.3c-2.146-7.383-7.031-10.046-9.135-17.44a40.423,40.423,0,0,1-1.661-17.44c.671-6.739,2.408-10.346,4.983-16.61,1.93-4.7,6.141-6.561,5.813-11.627-.291-4.5-2.87-6.554-5.813-9.966-5.97-6.92-13.007-5.961-20.762-10.8-7.871-4.907-13.062-7.079-19.1-14.118-5.108-5.953-6.074-10.63-9.966-17.44l-9.966-17.44s-14.944,1.413-22.423-3.322c-6.249-3.956-8.9-8.074-11.627-14.949-3.584-9.046-1.9-15.372,0-24.915,1.48-7.446,3.726-12.432,6.644-18.271l7.474-14.949-2.491-8.3s3.907-6.217,7.474-9.135c5.408-4.423,9.9-4.7,16.61-6.644,6.966-2.018,18.271-3.322,18.271-3.322s14.751,1.166,24.084,0c6.249-.781,9.5-2.91,15.779-3.322,2.913-.191,4.629-.649,7.474,0,4.561,1.04,6,4.171,9.966,6.644C258.751,226.412,272.215,232.974,272.215,232.974Z",
      translate: { x: "461.209", y: "760.352" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M-22",
      name: "3M",
      fill: "#001755",
      translate: { x: "641.239", y: "1044.823" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "OWERRI_WEST-2",
      name: "OWERRI WEST",
      fill: "#001755",
      translate: { x: "616.882", y: "1021.919" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OWERRI_MUNICIPAL",
    path: {
      id: "Path_7111",
      dataName: "OWERRI MUNICIPAL",
      d: "M218.85,240.875c5.443-4.343,15.779-8.3,15.779-8.3l13.288,12.457s7.348,5.485,14.949,6.644c3.526.538,8.287,2.9,11.627,4.152,5.99,2.244,8,6.068,8.3,12.457.329,6.956-3.76,10.5-8.3,15.779-4.7,5.456-14.949,10.8-14.949,10.8S249.9,294,244.6,296.518c-4.769,2.267-5.365,6.544-9.966,9.135-3.309,1.862-2.491,2.491-9.135,3.322s-19.932-6.644-24.915-8.3-6.4-4.654-7.474-9.135c-1.271-5.287,2.355-8.157,4.152-13.288,2.309-6.594,3.413-10.414,6.644-16.61C208.521,252.777,211.042,247.107,218.85,240.875Z",
      translate: { x: "497.964", y: "772.383" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M-23",
      name: "3M",
      fill: "#001755",
      translate: { x: "729.271", y: "1054.789" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "OWERRI_MUNICIPAL-2",
      name: "OWERRI MUNICIPAL",
      fill: "#001755",
      translate: { x: "699.374", y: "1044.823" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OWERRI_NORTH",
    path: {
      id: "Path_7112",
      dataName: "OWERRI NORTH",
      d: "M265.178,229.205c6.47-2.227,14.949-9.135,14.949-9.135s11.474,4.958,18.271,9.135a91.87,91.87,0,0,1,15.779,11.627c4.941,4.456,5.687,9.456,11.627,12.457,3.023,1.526,5.166,1.222,8.3,2.491a34.25,34.25,0,0,1,6.644,3.322c6.78,4.35,8.655,9.189,13.288,15.779,2.432,3.46,3.493,5.6,5.813,9.135a131.627,131.627,0,0,0,9.966,12.457s-9.23,5.609-13.288,9.966c-4.355,4.674-5.377,8.687-6.644,14.949-1.614,7.973,4.307,11.748.831,19.1-2.352,4.976-9.966,9.966-9.966,9.966s-18.178-4.289-31.559-1.661c-8.456,1.661-17.63,1.764-24.084,7.474-5.124,4.533-5.734,9.012-9.135,14.949-6.852,11.956-6.931,20.995-15.779,31.559-7.709,9.2-24.084,19.1-24.084,19.1s-7.435-16.422-6.644-27.406c.611-8.491,4.749-12.462,6.644-20.762,1.317-5.77,1.056-9.207,2.491-14.949,2.193-8.765,3.094-14.211,8.3-21.593,4.769-6.755,15.779-14.118,15.779-14.118s-5.122-4.49-8.3-7.474c-5.019-4.706-12.457-12.457-12.457-12.457s8.135-4.368,12.457-8.3c5.132-4.674,9.71-7.263,10.8-14.118.948-5.985.023-10.559-4.152-14.949-4.03-4.236-8.728-2.717-14.118-4.983-6.016-2.53-9.662-3.648-14.949-7.474-6.134-4.44-11.408-6.783-13.288-14.118-.967-3.77,0-9.966,0-9.966s16.18,1.877,26.576,1.661C253.057,230.705,257.794,231.748,265.178,229.205Z",
      translate: { x: "514.753", y: "764.12" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M-24",
      name: "3M",
      fill: "#001755",
      translate: { x: "805.677", y: "1085.422" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "OWERRI_NORTH-2",
      name: "OWERRI NORTH",
      fill: "#001755",
      translate: { x: "768.168", y: "1107.979" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ABOH_MBAISE",
    path: {
      id: "Path_7113",
      dataName: "ABOH MBAISE",
      d: "M430.887,236.231c6.184-.392,15.779-1.661,15.779-1.661l-1.661,7.474-6.644,5.813s-8.049,3.9-12.457,7.474a44.057,44.057,0,0,0-11.627,13.288c-3.079,5.106-5.813,14.118-5.813,14.118s4.569,10.874,7.474,17.44c5.021,11.353,6.285,18.578,15.779,26.576a44.379,44.379,0,0,0,13.288,8.3c4.647,1.937,12.457,3.322,12.457,3.322s1.252,16.1-1.661,25.745c-1.428,4.729-1.438,8.187-4.983,11.627-2.4,2.325-4.908,2.015-7.474,4.152a20.007,20.007,0,0,0-4.983,5.813c-2.937,4.843-.531,9.19-3.322,14.118-2.149,3.8-5.938,4.221-7.474,8.3-1.485,3.946,0,10.8,0,10.8s-11.685-5.765-18.271-10.8c-9.483-7.245-14.4-12.325-20.762-22.423-4.563-7.245-9.135-19.932-9.135-19.932s-7.471.279-11.627-1.661c-5.833-2.722-9.238-6.21-10.8-12.457-1.618-6.488,1.418-10.509,4.152-16.61,2.025-4.518,4.712-6.239,6.644-10.8,2.334-5.506,4.772-9.147,3.322-14.949-1.025-4.1-2.855-6.112-5.813-9.135-4.363-4.458-7.9-7.029-14.118-7.474-5.023-.359-7.458,2.726-12.457,3.322-4.187.5-6.729,1.106-10.8,0-5.385-1.465-11.627-8.3-11.627-8.3a83.241,83.241,0,0,1-7.474-7.474c-4.053-4.95-8.3-14.118-8.3-14.118s9.33-7.981,15.779-12.457c5.028-3.488,7.533-6.225,13.288-8.3,7.043-2.545,12.143,1.106,19.1-1.661a52.23,52.23,0,0,0,7.474-4.152l11.627,5.813s8.484-.968,13.288-3.322a51.394,51.394,0,0,0,8.3-5.813H406.8C416.209,236.231,421.5,236.826,430.887,236.231Z",
      translate: { x: "569.124", y: "773.704" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M-25",
      name: "3M",
      fill: "#001755",
      translate: { x: "948.25", y: "1084.053" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "ABOH_MBAISE-2",
      name: "ABOH MBAISE",
      fill: "#001755",
      translate: { x: "897.757", y: "1043.832" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "EZINIHITTE",
    path: {
      id: "Path_7114",
      dataName: "EZINIHITE MBAISE",
      d: "M463,242.663c6.345,2.923,16.61,6.644,16.61,6.644s-6.1,8.645-8.3,14.949c-1.43,4.084-.812,6.808-2.491,10.8-1.641,3.9-3.272,5.755-5.813,9.135-3.172,4.221-7.006,5.134-9.135,9.966-1.721,3.9.533,7.139-1.661,10.8-2.269,3.782-5.244,4.568-9.135,6.644-3.081,1.644-5.323,1.5-8.3,3.322-6.312,3.855-7.614,8.737-11.627,14.949C418.962,336.322,414,347.3,414,347.3s-8.931-2.286-14.118-4.983c-6.966-3.623-8.591-2.491-15.779-12.457S365,290.831,365,290.831s5.981-15.337,13.288-22.423c5.036-4.885,9.242-5.885,14.949-9.966,3.956-2.83,9.966-7.474,9.966-7.474l1.661-8.3s8.081-5.97,9.966-11.627c1.334-4,.831-9.966.831-9.966h10.8s9.971,6.825,16.61,10.8A180.428,180.428,0,0,0,463,242.663Z",
      translate: { x: "611.757", y: "764.781" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M-26",
      name: "3M",
      fill: "#001755",
      translate: { x: "1011.638", y: "1064.755" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "EZINIHITE_MBAISE-2",
      name: "EZINIHITE MBAISE",
      fill: "#001755",
      translate: { x: "998.35", y: "1053.146" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "NGOR_OKPALA",
    path: {
      id: "Path_7115",
      dataName: "NGOR OKPALA",
      d: "M369.757,274.375c4.059-4.579,13.288-8.3,13.288-8.3s8.024,8.84,14.949,9.966c4.843.787,7.656-.643,12.457-1.661,3.618-.767,5.441-2.646,9.135-2.491,4.1.171,6.358,1.7,9.7,3.97l.271.183c5.156,3.5,8.617,6.375,9.966,12.457,1.294,5.838-1.2,9.356-3.322,14.949-2.837,7.491-8.494,9.768-10.8,17.44-1.226,4.086-2.546,6.624-1.661,10.8,1.284,6.061,5.179,9.011,10.8,11.627,4.45,2.071,12.457,1.661,12.457,1.661s5.973,18.027,13.288,27.406c5.93,7.6,10.4,11.044,18.271,16.61a157.891,157.891,0,0,0,15.779,9.135l1.661,14.949s-4.2,7-6.644,11.627c-3.184,6.029-5.945,9.135-7.474,15.779-1.6,6.953,1.56,11.308,0,18.271-1.839,8.21-7.755,10.982-9.966,19.1-1.712,6.29,2.6,11.678-1.661,16.61-2.845,3.292-9.966,4.983-9.966,4.983s-4.82,8.944-9.966,12.457c-9.911,6.765-18.947,2.282-30.728,0-8.056-1.56-12.178-3.96-19.932-6.644-8.144-2.82-20.762-7.474-20.762-7.474s-12.672.521-20.762,0c-14.474-.934-22.624-2.56-36.542-6.644-17-4.988-25.825-10.061-41.524-18.271-7.592-3.971-10.589-9.811-19.1-10.8-5.2-.6-8.127.816-13.288,1.661-13.587,2.224-34.05,9.135-34.05,9.135s-12.9-6.355-14.949-14.118c-.995-3.762,0-9.966,0-9.966a123.816,123.816,0,0,0,13.288-4.152c15.175-6.254,25.255-11.394,33.22-25.745,2.543-4.581,3.938-7.323,4.983-12.457,1.163-5.72,0-14.949,0-14.949s14.514-10.346,21.593-19.1c7.665-9.481,8.582-17.01,14.949-27.406,4.887-7.978,5.6-14.6,13.288-19.932,6.152-4.269,10.923-4.372,18.271-5.813,12.412-2.437,32.389,0,32.389,0s10.572-3.405,12.457-9.135c1.013-3.081,0-8.3,0-8.3s-1.041-8.2,0-13.288C364.335,282.524,365.714,278.934,369.757,274.375Z",
      translate: { x: "501.528", y: "794.525" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M-27",
      name: "3M",
      fill: "#001755",
      translate: { x: "867.133", y: "1199.294" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "NGOR_OKPALA-2",
      name: "NGOR OKPALA",
      fill: "#001755",
      translate: { x: "845.54", y: "1189.328" },
      tspan: { X: "0", y: "0" },
    },
  },
];
