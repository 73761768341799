export const data = [
  {
    state: "TALATA_MAFARA",
    g: { translate: { x: "0.5", y: "0.111" } },
    path: {
      id: "Path_7549",
      dataName: "TALATA MAFARA",
      d: "M410.62,202.52l36.54-13.86,9.66-16.38,15.12-7.98,9.24-13.44V137l31.5,39.06-8.82,12.6,8.82,13.86L532,213.02h4.62v6.72L519.4,235.7l-9.66,27.72,2.94,10.92-20.16,15.54H461.86l-24.78-8.82-18.9-33.6-4.2-17.64-7.98-21Z",
      translate: { x: "-64.96", y: "-21.84" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "TALATA_MAFARA-2",
      name: "TALATA MAFARA",
      fill: "#001755",
      translate: { x: "362.88", y: "204.551" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M",
      name: "3M",
      fill: "#001755",
      translate: { x: "388.92", y: "219.67" },
      tspan: { x: "0", y: "0" },
    },
  },
  {
    state: "chafe",
    g: { translate: { x: "0.5", y: "0.111" } },
    path: {
      id: "Path_7550",
      d: "M950,357.58l-35.7-12.6h-6.72l-34.02-4.62L845,337l-43.68,15.96-26.88,18.48-9.24,29.82L761,431.92v16.8L777.8,463l32.76,9.24,7.14-12.18,3.78-15.54,14.7-21.42V405.46l37.38-4.2,21.84,4.2,26.88-17.64,4.62-6.3,2.94-6.3,13.86,2.94,18.06-6.72Z",
      translate: { x: "-121.76", y: "-53.84" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "TALATA-2",
      name: "TALATA",
      fill: "#001755",
      translate: { x: "672", y: "326.351" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.5M",
      name: "3.5M",
      fill: "#001755",
      translate: { x: "672", y: "347.351" },
      tspan: { x: "0", y: "0" },
    },
  },
  {
    state: "gusau",
    g: { translate: { x: "0.5", y: "0.111" } },
    path: {
      id: "Path_7551",
      d: "M832.34,266.5l-31.08,4.62-2.52,10.08,2.52,6.3v18.9l-2.52,15.12-10.5,7.98-27.72-3.78-13.86-13.44-16.38-28.14h-10.5l-4.2,3.36-7.56,4.2-7.14,10.5,2.52,10.08-7.56,9.24v15.54l-10.5,15.12,5.04,7.98v12.18l-11.34,5.04v10.5l-10.5,12.18-8.82,11.76-10.5,19.74L623.6,450.04l-2.1,6.72,2.1,3.36,25.62,2.94,46.62,13.02,19.74,17.64h9.66l5.04,4.2v11.34l26.04,28.98h4.2l27.72-16.38,21.84-19.74,2.94-13.44,3.78-7.14v-5.46l-3.78-5.46,3.78-3.78v-6.72l-6.72-5.04-13.86-7.56-7.98,15.54-22.26-7.98-14.28-5.04-13.86-12.6V422.32l4.2-28.98,9.66-33.18,23.1-15.96,47.88-18.48c9.8,1.96,29.736,5.46,31.08,3.78s20.72,2.66,30.24,5.04h9.24L884,325.72l21-19.32,3.36-8.82L905,287.5l-16.8-21H832.34Z",
      translate: { x: "-99.44", y: "-42.56" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "GUSASU-2",
      name: "GUSASU",
      fill: "#001755",
      translate: { x: "594.72", y: "412.87" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-2",
      name: "3M",
      fill: "#001755",
      translate: { x: "608.16", y: "428.83" },
      tspan: { x: "0", y: "0" },
    },
  },
  {
    state: "BUNGUDU",
    g: { translate: { x: "0.5", y: "0.111" } },
    path: {
      id: "Path_7552",
      d: "M555.14,231.88V220.54h13.02l16.8,5.46,22.68,10.5h13.02l20.16-21h21.42l20.58,13.02,17.22,10.92,9.66,15.12,31.5-3.36,19.74,3.36,23.1,8.4-2.94,9.66,2.94,4.62V299.5l-2.94,14.28-8.82,8.82-26.88-3.36-21.42-24.78-11.34-17.22h-12.6l-8.82,5.88-6.3,11.34v10.92l-5.46,8.4v13.86l-10.5,14.7,4.2,10.92v10.5l-10.92,4.62v11.34l-11.76,13.02-5.04,7.56-15.12,23.52L577.4,396.94v-4.2l-19.32-7.98L544.22,360.4l-12.18-7.14L516.5,331l3.36-11.76L516.5,299.5l12.18-11.76v-10.5c2.24-7.42,6.72-23.016,6.72-26.04S548.56,237.06,555.14,231.88Z",
      translate: { x: "-82.64", y: "-34.399" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "BUNGUDU-2",
      name: "BUNGUDU",
      fill: "#001755",
      translate: { x: "496.44", y: "276.79" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.5M-2",
      name: "3.5M",
      fill: "#001755",
      translate: { x: "504", y: "291.07" },
      tspan: { x: "0", y: "0" },
    },
  },
  {
    state: "BIRIN_MAGAJI/kiyaw",
    g: { translate: { x: "0.5", y: "0.111" } },
    path: {
      id: "Path_7553",
      dataName: "BIRIN MAGAJI",
      d: "M894.02,201.44l-3.78-23.94H850.76l-25.62,4.2-15.12,12.18-9.66-5.46,9.66-5.04-3.36-4.2h-12.6l-5.88,4.2-8.4-4.2-16.38,7.14-8.4,7.56,8.4,27.72,10.92,10.92,22.26,9.24L818,254.36l4.62,1.68,28.14-3.78h59.22v-10.5l-2.1-3.78L897.8,219.92Z",
      translate: { x: "-120.8", y: "-28.319" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "BIRIN_MAGAJI-2",
      name: "BIRIN MAGAJI",
      fill: "#001755",
      translate: { x: "677.04", y: "176.83" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M",
      name: "2M",
      fill: "#001755",
      translate: { x: "696.36", y: "188.591" },
      tspan: { x: "0", y: "0" },
    },
  },
  {
    state: "KAURA_NAMODA",
    g: { translate: { x: "0.5", y: "0.111" } },
    path: {
      id: "Path_7554",
      dataName: "KAURA NAMODA",
      d: "M686.02,204.32l-24.78-1.68L652,185.84l1.68-24.36L664.6,143l21.42,2.52,7.56,4.2,8.4-4.2,7.98,4.2,16.8,2.1,14.28,5.46,5.88,7.98,16.38,8.4-16.38,7.56-8.82,6.3,5.04,15.12,3.78,13.02,10.92,10.92,21,9.24,23.1,13.44-40.32-10.92-30.24,3.78-10.5-13.02-10.92-7.98-13.02-12.18Z",
      translate: { x: "-104.32", y: "-22.8" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "KAURA_NAMODA-2",
      name: "KAURA NAMODA",
      fill: "#001755",
      translate: { x: "553.56", y: "152.471" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-2",
      name: "2M",
      fill: "#001755",
      translate: { x: "577.08", y: "170.11" },
      tspan: { x: "0", y: "0" },
    },
  },
  {
    state: "ZURMI",
    g: { translate: { x: "0.5", y: "0.111" } },
    path: {
      id: "Path_7555",
      d: "M745.52,14.78l-16.8,8.82L743,44.053l2.52,9.367-18.9,13.44H702.68L684.62,55.94h-8.4L671.6,59.3l-4.62-3.36H663.2l-13.44-4.62-1.68-7.267-4.62,1.807,1.68,15.12-10.5,10.08v6.72h15.12l8.4,6.3-5.04,5.04L627.5,100.04v5.46l11.34,5.88,9.24-2.94,10.08,2.94,3.36,7.56-1.68,2.52,22.68,1.68,6.72,3.36,8.4-3.36,8.4,3.36L722,129.44l15.54,5.04,5.46,7.98,8.4,4.2,9.24,4.2,7.56,4.2,5.46-4.2h13.02l2.94,4.2-9.66,5.04,9.66,5.46,15.12-12.6,25.2-3.78h40.32V131.54l-2.52-14.7-5.46-22.68V74l5.46-2.94V59.3L864.8,42.92H825.32L776.6.5,754.76,13.1Z",
      translate: { x: "-100.4", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ZURMI-2",
      name: "ZURMI",
      fill: "#001755",
      translate: { x: "661.92", y: "81.91" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.5M-3",
      name: "3.5M",
      fill: "#001755",
      translate: { x: "662.76", y: "100.391" },
      tspan: { x: "0", y: "0" },
    },
  },
  {
    state: "SHINKAFI",
    g: { translate: { x: "0.5", y: "0.111" } },
    path: {
      id: "Path_7556",
      d: "M605.14.5H578.26L577,8.9V24.86l10.5,2.94,17.64,3.78h10.92l10.08,3.78,12.6-3.78,11.34,2.94-11.34,9.24,4.2,8.4,11.34,4.2h5.04l5.04,2.1,3.36-2.1h8.82l19.32,10.92h21.42l20.16-13.02-2.1-10.5L721.06,23.18h-25.2L654.28,17.3,627.82,8.9l-9.66-5.04Z",
      translate: { x: "-92.32", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "SHINKAFI-2",
      name: "SHINKAFI",
      fill: "#001755",
      translate: { x: "573.72", y: "44.95" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-3",
      name: "2M",
      fill: "#001755",
      translate: { x: "599.76", y: "56.711" },
      tspan: { x: "0", y: "0" },
    },
  },
  {
    state: "MARADUN",
    g: { translate: { x: "0.5", y: "0.111" } },
    path: {
      id: "Path_7557",
      d: "M521.2,159.22,490.54,121l-10.08-11.34L464.5,75.22l6.3-15.96V43.72L509.44,26.5h18.48l25.2,13.02L559,29.44l27.3,5.88h11.76l10.08,4.2,14.28-4.2,9.66,4.2-13.86,10.5V65.56l-10.08,9.66V81.1h14.28l9.66,7.14-5.46,5.46-25.2,10.08v5.88l10.92,6.3,10.08-3.36,9.66,3.36,2.94,7.14-12.6,19.74v24.78L632.08,184l-19.74,21H598.06l-21.42-10.08-23.52-5.88H546.4v5.88h-5.46L521.2,184l-7.56-12.18Z",
      translate: { x: "-74.32", y: "-4.159" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "MARADUN-2",
      name: "MARADUN",
      fill: "#001755",
      translate: { x: "441.84", y: "94.511" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-3",
      name: "3M",
      fill: "#001755",
      translate: { x: "462", y: "115.511" },
      tspan: { x: "0", y: "0" },
    },
  },
  {
    state: "BAKURA",
    g: { translate: { x: "0.5", y: "0.111" } },
    path: {
      id: "Path_7558",
      d: "M357.16,192.74l-3.78,32.34,36.54,2.52,10.5-5.04-2.52-5.04-3.78-13.44,3.78-7.56,6.3-3.78,17.22-5.46,12.18-4.2,3.36-6.72,5.46-8.82,15.12-8.82,9.24-11.34,2.1-9.66v-4.2l-8.82-11.76-14.28,3.36-12.18-5.04H421.42l-27.3-11.34L378.58,99.5h-16.8l-15.96,9.24L326.5,129.32Z",
      translate: { x: "-52.24", y: "-15.84" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "BAKURA-2",
      name: "BAKURA",
      fill: "#001755",
      translate: { x: "314.16", y: "145.75" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "317.52", y: "160.03" },
      tspan: { x: "0", y: "0" },
    },
  },
  {
    state: "ANKA",
    g: { translate: { x: "0.5", y: "0.111" } },
    path: {
      id: "Path_7559",
      d: "M355.62,247.94c-4.2,7.56-13.1,22.932-15.12,23.94l2.94,31.5,8.4,13.86H374.1l7.56,15.96,2.94,21.84V396.2l-7.56,32.34,35.7-2.94,33.18,16.38,19.74,3.78,15.54,3.36h22.26l11.34-28.14,14.7-7.14,17.22-17.64-17.22-6.3-6.72-10.08L514.8,364.7l-11.34-6.3L489.6,336.14l2.52-12.18-2.52-20.58-8.4,3.78H450.54l-23.94-8.82-5.04-9.66-13.44-25.2L402.66,245l-11.76,6.3Z",
      translate: { x: "-54.48", y: "-39.119" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ANKA-2",
      name: "ANKA",
      fill: "#001755",
      translate: { x: "367.08", y: "322.15" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-2",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "375.48", y: "347.351" },
      tspan: { x: "0", y: "0" },
    },
  },
  {
    state: "BUKKUYUM",
    g: { translate: { x: "0.5", y: "0.111" } },
    path: {
      id: "Path_7560",
      d: "M229.8,278.009l-28.14-2.94-11.34,26.88,5.88,12.6-8.4,17.22-13.44,12.18H163.44v18.9l3.36,3.78-3.36,5.04,3.36,13.02-6.3,13.02,17.22,21.42h35.7v2.94l5.04,6.3H240.3l13.02-2.94,10.08,2.94H297l7.56-2.94,18.48-3.36,21.42,9.24h4.62l7.56-36.12v-32.34l-4.2-25.2-7.98-17.64H323.04l-9.66-13.86v-31.08H301.62c-2.94-3.08-9.408-9.408-11.76-10.08s-13.86,4.48-19.32,7.14H249.96Z",
      translate: { x: "-25.68", y: "-42.309" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "BUKKUYUM-2",
      name: "BUKKUYUM",
      fill: "#001755",
      translate: { x: "199.92", y: "317.95" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M",
      name: "4M",
      fill: "#001755",
      translate: { x: "204.12", y: "339.79" },
      tspan: { x: "0", y: "0" },
    },
  },
  {
    state: "GUMMI",
    g: { translate: { x: "0.5", y: "0.111" } },
    path: {
      id: "Path_7561",
      d: "M6.3,282.38,15.54,266h9.24l12.18,3.36,19.32,3.36,27.3-3.36,57.12,3.36,35.28,2.94L163.8,302.12l5.88,12.18-8.4,18.06-12.18,10.5H137.76v19.32l2.94,4.62-2.94,5.04,2.94,13.02-6.3,13.02-2.94,5.46-20.16-2.52-5.46,7.14L88.62,397.88l-5.04,2.94,5.04,7.14-5.04,3.78-9.66,2.94-10.08-2.94H56.28l-9.66,5.46H33.18l-8.4-9.24-9.24,3.78H0V397.88L9.66,314.3,6.3,296.24Z",
      translate: { x: "0", y: "-42.479" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "GUMMI-2",
      name: "GUMMI",
      fill: "#001755",
      translate: { x: "36.12", y: "303.67" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-3",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "52.92", y: "322.15" },
      tspan: { x: "0", y: "0" },
    },
  },
  {
    state: "MARU",
    g: { translate: { x: "0.5", y: "0.111" } },
    path: {
      id: "Path_7562",
      d: "M404.32,465.16,385,456.34l36.54-2.1,31.92,15.12,34.44,9.24h22.68l11.76-28.98,14.7-7.56L553.84,424l1.68,2.52v3.36l52.92,27.3-22.26,18.9-3.36,5.04,3.36,4.2,22.26,2.52,16.38,3.36,21,5.88,12.6,4.62,7.14,6.72,11.76,9.24h8.82l5.46,5.46v11.34l26.04,28.14h5.04v5.88l-5.04,4.62,2.52,7.14-2.52,9.66h-3.36l-5.04,3.78,2.94,5.04-2.94,5.04h-6.3l-2.1,6.72-12.18,3.36H674.38l-11.34,6.72h-8.82l-8.4-2.1-9.24,5.46h-7.56l-6.3-3.36-4.62,6.72h-8.82l-7.98-8.82H588.7l-7.14,5.46-10.5-3.36-23.94,15.12-13.02,7.14L532,653.74l-7.14,8.82-14.28-2.1-2.94-14.7-17.22-18.48-31.08-21V595.36l-4.62-2.52-7.14-4.2-12.18,6.72-6.3-23.1,9.66-11.76-5.46-10.08-10.08-5.46,10.08-3.78,21.42,3.78,12.6-8.4V520.18l-7.98-13.44-36.12-21Z",
      translate: { x: "-61.6", y: "-67.76" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "MARU-2",
      name: "MARU",
      fill: "#001755",
      translate: { x: "483.84", y: "473.351" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M",
      name: "5M",
      fill: "#001755",
      translate: { x: "487.2", y: "491.83" },
      tspan: { x: "0", y: "0" },
    },
  },
];
