export const data = [
  {
    state: "EASTERN_OBOlO",
    path: {
      id: "Path_7250",
      dataName: "EASTERN OBOTO",
      d: "M497.524,3985.1l-9.373-5.376,4.687-3.842,4.687-2.3,4.26-1.537,5.113,3.073h3.408l3.835,4.608h10.651l1.7-3.842-3.835-2.687-6.391-3.073-2.982-2.3,5.965-2.688,7.243-4.993c3.124,1.025,9.458,3.073,9.8,3.073s5.823.512,8.521.767l9.373-6.528,8.095-4.608,3.835,3.073-4.26,2.688-.426.767-1.278,4.225,1.7,1.92h8.521l3.835-2.688,16.19-4.223,8.1-1.153,9.372-1.92,9.374-1.92,5.112-1.152,10.226-2.688,11.5-1.92,7.669-3.073H657.3l1.278,4.993,1.277,4.225,2.983,5.76,2.557,8.448,2.129,1.536v.384l-28.972,2.3-12.781,3.072-11.929.768-11.93,3.072-15.338,2.688-12.781,1.152-5.114,1.536-7.242.769-5.113,1.92-5.539,3.457-8.521,4.223-10.651,4.225-10.651,1.537-5.539,1.92-3.835,1.92h-3.408l-1.278-.768-2.13-6.528-1.7-4.225-1.7-3.84Z",
      translate: { x: "120.409", y: "-2696.973" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "EASTERN_OBOTO-2",
      name: "EASTERN OBOTO",
      fill: "#001755",
      translate: { x: "673.96", y: "1304.715" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M",
      name: "5M",
      fill: "#001755",
      translate: { x: "679.561", y: "1279.588" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IKOT_ABASI",
    path: {
      id: "Path_7251",
      dataName: "IKOT ABASI",
      d: "M257.246,3350.381l4.261-1.153,5.539,5.761,10.225,14.208,8.947-3.84,5.539,1.92h14.912l6.817-3.457,1.7-.767,4.687-1.536,11.93,3.84h2.556v-3.84l-2.556-1.537v-3.457h10.651l1.278,1.921h5.113l9.8-5.761,7.669-5.76,3.408-6.145v-1.92h14.061v12.289h9.372v9.216l-8.946,13.824,2.555,3.072,3.409,2.688,6.391,1.152,5.964,3.073,7.243,4.223,1.278,1.537v8.833l-2.557,6.143-5.538,8.833-3.834,6.145-2.983,3.457-7.669,8.448-2.983,1.152-4.26,1.92-2.557,1.537-5.538,3.072-5.539,3.073-.852-.768-2.982-2.3-9.373,5.376-8.1,5.76-8.947-.767-9.373-3.073-7.243,4.993-5.965,2.688,2.982,2.3,6.391,3.073,3.835,2.687-1.7,3.841H315.616l-3.835-4.608h-3.408l-5.113-3.073-3.409,1.153-5.965,3.072-4.261,3.456-9.8-3.073-15.338-14.593-5.539-8.063-1.7-4.225,1.7-3.072,7.243-2.688,3.408-4.608,1.7-2.3V3419.5l-.852-2.688-5.539-6.143-3.408-4.608-1.7-1.153v-5.375l-2.556-1.921v-4.223l-1.7-1.92-1.7-1.537v-3.84l1.7-2.3v-13.824l-1.7-1.152v-12.673l1.7-3.072Z",
      translate: { x: "318.933", y: "-2183.6" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "IKOT_ABASI-2",
      name: "IKOT ABASI",
      fill: "#001755",
      translate: { x: "611.96", y: "1211.574" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.5M",
      name: "3.5M",
      fill: "#001755",
      translate: { x: "633.545", y: "1240.938" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "MKPAT_ENIN",
    path: {
      id: "Path_7252",
      dataName: "MKPOI ENIN",
      d: "M1047.758,2638.168l2.131-.768,3.834.383,2.131,8.065,4.26,15.744,1.7,4.608v4.608l-6.817,5.376-2.983,3.157v9.132l-.426.768-2.555,5.376v7.68l8.52,15.361v9.216l-2.129,3.84v24.192l-7.669,4.225,11.5,8.448,8.1,5.761,5.538,5.76v5.76l-5.112,4.993L1055.427,2796l-5.538,1.537-4.261,4.225v4.223l2.557,6.913,3.408,7.68,1.7,6.145,3.409,10.368h-5.54c-2.271,1.025-6.986,3.073-7.669,3.073s-7.953,1.28-11.5,1.92l-14.912,3.84-19.173,3.84-8.1,1.153-6.391,1.92-9.8,2.3-3.835,2.688h-8.521l-1.7-1.92.426-1.92,1.278-3.072,7.243-4.225,10.652-6.145,3.408-1.535,3.835-1.537,4.686-4.993,5.964-6.912,4.688-7.681,4.686-7.3,2.557-6.143v-8.833l-1.278-1.537-6.391-3.84-7.669-3.84-5.538-.768-3.835-3.072-2.131-2.688,8.947-13.824v-9.216H981.72V2744.54H967.659V2743l3.835-4.223,3.834-7.681,8.521-11.9,9.374-8.063h5.964l1.278-1.921,4.686-5.375V2695l-1.7-1.92v-8.833h1.7l2.983-3.457,5.538-4.223,13.209-13.441,5.112-7.68,3.408-4.608,3.409-3.841,2.983-3.072,3.834-3.84Z",
      translate: { x: "-279.001", y: "-1589.28" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "MKPOI_ENIN-2",
      name: "MKPOI ENIN",
      fill: "#001755",
      translate: { x: "706.851", y: "1147.036" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.5M-2",
      name: "3.5M",
      fill: "#001755",
      translate: { x: "730.284", y: "1178.549" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ETINAN",
    path: {
      id: "Path_7253",
      d: "M1554.3,2278.231l-4.261-15.361,6.391.385,7.669.768,6.817-6.913v-11.9l-2.555-5.376v-5.376l-2.557-3.072v-9.6l.426-.384,4.686-5.76v-.385l1.7-7.68.852-2.3,2.983-8.448,2.555-1.92.426.383,3.835,3.073v31.874l2.129,3.072,2.557,4.991,2.131,4.225,1.278,1.92,7.243,11.136.852,5.376-6.817,11.521,4.26,11.136v12.673l-2.983,7.68,1.7,1.92,5.112,6.145,4.261,4.993v12.671l-2.131,7.681,3.835,6.145v15.359l-1.7,4.608-.852.385-.852,1.152v.768l-14.486,3.84-9.374,3.457h-10.224l-17.469-10.368V2355.8l2.131-3.84v-9.216l-2.131-3.457-3.409-6.145-2.981-5.76v-7.3l2.981-6.913v-8.831l1.7-1.92,8.1-6.53v-4.608l-1.7-4.992Z",
      translate: { x: "-775.314", y: "-1214.368" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ETHAN-2",
      name: "ETHAN",
      fill: "#001755",
      translate: { x: "784.193", y: "1112.134" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M",
      name: "3M",
      fill: "#001755",
      translate: { x: "798.955", y: "1133.234" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ONNA",
    path: {
      id: "Path_7254",
      d: "M1568.618,3300.906l15.764-4.225v5.76l.426,3.457,5.964,7.3v5.376l-6.817,6.145-2.555,7.68,1.7,6.145,1.7,1.535,2.131.769,7.669,2.688,7.669-.383h3.408l1.7,3.457-5.112,9.6.852,2.688,9.372,5.76,3.835,3.073-2.983,3.84-3.834,1.92-4.686-1.92-4.688-.768-8.521-3.072-3.408,1.92,2.129,8.832,5.54,10.369-1.7,9.216h-58.8L1529,3376.557l-3.835-8.832-2.131-5.761v-4.223l4.261-4.225,5.538-1.537,12.355-6.143,5.114-4.993v-5.76l-5.114-5.376-8.1-5.76-11.929-8.833,7.669-4.223V3293.61l17.469,10.368h10.224Z",
      translate: { x: "-756.403", y: "-2145.262" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ONNA-2",
      name: "ONNA",
      fill: "#001755",
      translate: { x: "796.28", y: "1213.772" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M",
      name: "4M",
      fill: "#001755",
      translate: { x: "800.711", y: "1235.469" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "EKET",
    path: {
      id: "Path_7255",
      d: "M1907.745,3301.318a3.121,3.121,0,0,1,2.131-2.688l10.226,3.073,5.112,3.072,6.817,4.225,8.521,5.76,9.8,7.68,1.277.385h7.243l.426-.385,11.078-9.216,2.557.768,1.7,1.536,5.966,6.912.851.385h4.261l5.112-3.457,3.409-1.92,5.964-1.92h4.686l-.852,10.368-1.277,13.824-9.8,4.225-3.409,3.457v8.065l1.7,2.3,1.278,4.608,1.278,3.457.426,1.152.852,5.761,1.277,2.687.852,8.449.852.768h-12.355l-.852-1.921h-7.669v1.921h-4.26v1.152l-1.278.384h-6.817c-.341,0-8.1,2.3-11.93,3.456l-5.112,2.3-11.5,6.143-13.634,3.84h-4.686l1.7-8.448-5.54-10.753-2.129-8.831,3.408-1.92,8.521,3.071,4.686.769,4.688,1.92,3.834-1.92,2.983-3.84-3.835-3.073-9.372-5.76-.852-2.688,5.112-9.6-1.7-3.457c-3.408.255-10.651.614-12.355,0s-4.118-1.537-5.112-1.92l-3.409-1.153-2.131-1.92-1.277-5.76,2.555-7.68,6.817-6.145v-5.376l-5.964-7.3C1908.313,3307.848,1907.745,3302.855,1907.745,3301.318Z",
      translate: { x: "-1080.192", y: "-2149.515" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "EKET-2",
      name: "EKET",
      fill: "#001755",
      translate: { x: "878.732", y: "1195.428" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-2",
      name: "3M",
      fill: "#001755",
      translate: { x: "866.431", y: "1213.772" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IBENO",
    path: {
      id: "Path_7256",
      d: "M1730.468,3783h20.45l14.486-2.3,12.783-2.3,13.207-2.688h10.651l2.557-1.921h14.486l5.538-1.152h13.209l2.982-2.3h2.983l3.408-1.537h5.966l1.277,1.537h33.659l1.279,1.151,5.964,5.376,1.279,1.537,8.521,9.983,11.929,8.449,8.521,1.535,14.912-4.608-1.278,2.3-2.981,2.688-2.131,3.84v6.528l-1.7,4.225-7.669,1.92-5.538-1.92H1914.1l-3.835-1.537-8.521-2.688h-11.077c-2.841-1.023-8.778-3.072-9.8-3.072h-33.233l-2.129,1.535h-27.695c-9.94.257-30.76.615-34.51,0s-9.516,1.281-11.93,2.3h-31.1l-2.557,1.152h-5.964l-3.409-1.152h-2.557l-3.834,3.072H1684.88l-6.391,3.457h-12.783l-11.077,1.92h-28.972l-2.983,1.537-9.8.768-2.129-1.537-1.278-4.608-1.278-3.84-2.983-5.76-1.277-4.225H1664l3.834-.768,6.391-1.537,4.261-1.152,3.407-1.152,9.374-4.993,3.409-1.535,2.981-1.537,5.964-1.537,5.966-1.92h6.817l1.278-.384V3783c0-.308,2.839-.128,4.26,0v-1.921h7.669Z",
      translate: { x: "-824.947", y: "-2547.863" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "IBONO-2",
      name: "IBONO",
      fill: "#001755",
      translate: { x: "922.365", y: "1249.801" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4.5M",
      name: "4.5M",
      fill: "#001755",
      translate: { x: "974.117", y: "1253.749" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ESIT_EKET",
    path: {
      id: "Path_7257",
      dataName: "ESIT EKET",
      d: "M2470.7,3388.717l2.129-24.192h11.93l8.948-5.376h10.651l1.278-2.688,6.391,3.072,9.8,4.993,4.26,1.152,11.078,2.688,9.374,3.457,8.946,4.992,5.54,5.376,2.129,3.84,1.7,6.145v16.9l-.852,11.9-2.981,2.3h-13.209l-5.964,1.152h-14.06l-2.557,1.921h-10.65l-7.669,1.535-7.669,1.536-10.652,1.92-14.486,2.3h-8.1l-.852-.768-.852-8.449-1.277-2.687-.852-6.145-2.131-5.76-.852-3.073-1.7-2.3V3396.4l3.409-3.457Z",
      translate: { x: "-1548.135", y: "-2198.512" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ESIT_EKET-2",
      name: "ESIT EKET",
      fill: "#001755",
      translate: { x: "939.125", y: "1193.543" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-2",
      name: "4M",
      fill: "#001755",
      translate: { x: "956.543", y: "1213.772" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "MBO",
    path: {
      id: "Path_7258",
      d: "M3266.052,3439.288v-10.751l-.426-2.688-2.131-1.537-2.131-1.151-5.112-2.3h-4.687l-1.7-1.535h-9.374l-5.112,3.84-6.392,2.3-2.555,1.153-5.114,5.76-2.555,4.225-11.5.283V3424.7H3194.9l-3.835,3.841v10.751l-3.408,3.456-7.669-5.376h-8.521l-3.834,2.688-3.409,2.3-4.686,3.84h-3.409l-.426,16.129-.852,11.9h2.983l2.981-1.536h6.392l1.277,1.536h33.659l7.669,6.912,9.8,11.521,11.5,8.065,8.1,1.535,5.964-1.535,9.374-3.073-.852-4.992,10.226-11.521v-11.9l7.669-2.3v-8.449l2.131-1.92,4.26-6.528-4.26-4.991Z",
      translate: { x: "-2138.98", y: "-2251.771" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "MBO-2",
      name: "MBO",
      fill: "#001755",
      translate: { x: "1085.694", y: "1206.12" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-3",
      name: "4M",
      fill: "#001755",
      translate: { x: "1087.68", y: "1224.928" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "URUAN",
    path: {
      id: "Path_7259",
      dataName: "URUE  OFFONG ORUKO",
      d: "M2897.914,3050.964l-13.634-3.457,14.486-13.439,7.669-10.753,3.407-7.3,4.688-6.145.426-.383,5.112-2.688h14.486l.852-.385,1.7-1.535,11.077-3.841-3.407-8.448,9.8-10.753,5.112,3.073,2.131,1.92h2.129l.426.383,3.409,3.073v1.535l-4.261,2.3-3.407,4.225v14.593l2.131,2.3,5.538,2.688,5.964-1.152,3.409-1.537h2.555l1.7,1.537h4.26l3.835,4.991-8.521,2.3-7.243,3.072,5.112,3.457-1.7,4.991-3.409,3.073-3.409,5.76-4.686,4.225v5.375l-3.834,3.456v11.136l-3.409,3.456-7.669-5.376h-8.521l-7.669,5.376-4.26,3.457h-3.409l-1.278-3.457-.852-3.84-2.129-3.457-5.54-5.376-5.538-3.072-4.26-2.3Z",
      translate: { x: "-1909.737", y: "-1881.111" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "URUE_OFFONG_ORUKO-2",
      name: "URUE \n        OFFONG ORUKO",
      fill: "#001755",
      translate: { x: "1037.338", y: "1144.579" },
      tspan: { X: "-16.836", y: "0" },
    },
    textState: {
      id: "_4M-4",
      name: "4M",
      fill: "#001755",
      translate: { x: "1026.287", y: "1173.364" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "MBO-3",
    path: {
      id: "Path_7260",
      dataName: "MBO",
      d: "M3488.212,3246.148l1.277-3.84-4.686-6.528-3.834,1.152-2.131,2.3-3.409,2.688-4.26,3.072h-12.355l-7.669-5.376-1.7-1.92-8.521-.768-8.521,2.3-7.243,3.072,5.112,3.457-1.7,4.991-3.409,3.073-3.409,5.76-4.686,4.225v5.375h12.355v12.289l11.5-.383,2.557-4.225,5.112-5.76,8.948-3.456,5.112-3.84h9.374l1.7,1.535h4.686l3.407,1.536,3.835,1.92,2.131,1.537.426,3.072,3.834,2.688v3.072l1.7,2.3h4.26v-29.569l-3.834-1.921-.426-1.92Z",
      translate: { x: "-2361.141", y: "-2096.265" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "MBO-4",
      name: "MBO",
      fill: "#001755",
      translate: { x: "1139", y: "1171.549" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-3",
      name: "3M",
      fill: "#001755",
      translate: { x: "1089.359", y: "1164.549" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "UDUNG_UKO",
    path: {
      id: "Path_7261",
      dataName: "UDUNG UKO",
      d: "M3384.208,3049.515l4.261-2.3.852.385,6.817-.385v2.3h17.894l11.078-.385h10.224l.426-1.92h4.688l3.408,2.3,1.7,1.152,3.835,2.3,5.965,4.992,1.278,1.92,2.129,3.456,2.557,1.537,1.7,2.3-1.7,2.688V3081l-2.557,1.92-4.686-6.911-3.834,1.152-2.557,2.688-3.835,3.072-3.408,2.3h-12.356l-7.669-5.376-1.7-1.92-8.521-.768-3.835-4.991h-4.26l-1.7-1.537h-2.557l-3.408,1.537-5.964,1.152-5.54-2.688-2.129-2.3V3053.74Z",
      translate: { x: "-2330.42", y: "-1936.497" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "UDUNG_UKO-2",
      name: "UDUNG UKO",
      fill: "#001755",
      translate: { x: "1132.609", y: "1130.579" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "1087.68", y: "1130.819" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ORON",
    path: {
      id: "Path_7262",
      d: "M3385.015,2736.78l-7.669-3.84v4.608l-11.929,5.76-5.54,2.3-7.667,3.072-5.966,6.528-1.7,15.744,2.129,1.537,2.983,1.537,2.131,1.92h2.129l1.7,1.536,2.131,1.92v1.92l7.669-.385v2.3h17.467l19.6-.385h2.131l.426-1.92-1.7-1.535-2.557-1.92-2.557-1.153-1.277-2.3-1.7-2.3-1.7-2.688-2.131-3.455-2.129-3.842-2.131-1.92v-1.535l2.131-1.921,2.129-1.92,2.983-1.92,2.983-3.072-.426-1.92-1.7-1.92-1.7-1.537-2.131-1.92-2.129-1.92-2.131-1.537Z",
      translate: { x: "-2299.698", y: "-1670.228" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ORON-2",
      name: "ORON",
      fill: "#001755",
      translate: { x: "1112.694", y: "1076.471" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.5M-3",
      name: "3.5M",
      fill: "#001755",
      translate: { x: "1055.338", y: "1104.064" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OKOBO",
    path: {
      id: "Path_7263",
      d: "M2779.184,2395.057l-8.521-1.537-3.835,6.528-5.112,5.761-5.965,3.84-1.7,2.3h-5.538l-.852,3.073-2.983,5.376-4.686,6.143-2.557,1.92-11.077,8.833h-.852l-8.521,4.993-5.538,7.295-5.966,3.842-4.26,4.991v3.073l-3.409.767v4.993l15.764,12.288,2.557,4.608-.852,4.225-6.391,6.913-5.112,6.911-6.392,12.289v6.912l1.7,6.145,3.409,3.457,3.834,6.912,2.557,2.688,17.043,8.448,14.912-13.824,7.243-10.368,3.407-7.3,4.688-6.145,5.538-3.072h14.912l1.7-1.92,11.5-3.842-3.407-8.448,9.8-10.753,1.7-15.744,5.964-6.528,5.114-1.92,10.651-4.608,9.374-4.608v-5.76l-4.686-1.92-4.687-8.065-3.408-9.6-2.557-7.3-4.686-4.992-6.817-2.688Z",
      translate: { x: "-1751.384", y: "-1382.65" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OKOBO-2",
      name: "OKOBO",
      fill: "#001755",
      translate: { x: "993.287", y: "1087.137" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.5M-4",
      name: "3.5M",
      fill: "#001755",
      translate: { x: "993.439", y: "1097.064" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "NSIT_UBIUM",
    path: {
      id: "Path_7264",
      dataName: "NSIT UBIUM",
      d: "M1924.051,2832.412V2819.74l17.469-14.593h28.545l2.131-2.3,2.555-4.225,1.278-3.84,1.278-1.92,7.243-4.608,4.26,4.608-1.277,8.833,2.555,4.223,2.557,4.993,4.686,6.145,1.7,5.76,5.538,3.84,5.54,10.753,7.243,3.84,13.207,11.9,10.224,3.072v1.153l1.279,4.608,3.835,3.457,3.408,6.911,2.557,2.688-1.279,2.688h-10.65l-5.54,3.072-3.834,2.3H2014.8l-6.391,1.92-5.964,3.456-2.557,1.92H1995.2l-5.538-6.528-2.555-2.3-2.557-.768-11.5,9.6h-7.669l-2.129-1.152-6.391-5.376-5.966-4.225-4.686-3.072-5.964-3.84-5.964-3.457-10.226-2.688,1.7-5.376v-14.976l-3.835-6.145C1922.63,2837.788,1924.051,2833.027,1924.051,2832.412Z",
      translate: { x: "-1094.367", y: "-1717.09" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "NSIT_UBIUM-2",
      name: "NSIT UBIUM",
      fill: "#001755",
      translate: { x: "844.355", y: "1126.234" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-2",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "866.431", y: "1144.579" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "NSIT_ATAI",
    path: {
      id: "Path_7265",
      dataName: "NSIT ATAI",
      d: "M2421.565,2555.187l8.521-4.991-11.078-13.056-8.521-4.608h-8.094l-2.131-1.537-5.538-.383-4.687-1.922-7.243-1.92-8.1,3.457-1.278.768h-1.277v4.225l-.852,5.376-1.7,13.439-8.948,11.521-3.834,3.457h-1.278l-5.114,2.3-1.7,8.833,2.981,4.223,2.983,5.761,4.26,5.376,1.7,5.76,5.538,3.84,2.131,4.225,3.835,6.528,6.391,3.457,12.781,11.9,5.112,1.92,5.54,1.535v-6.911l1.7-3.072,1.7-3.841,2.557-4.992,5.538-7.681,6.391-6.911.853-4.225-2.557-4.608-15.763-12.288-1.278-4.993,4.686-.767v-3.073l4.26-4.991,5.966-3.841Z",
      translate: { x: "-1455.97", y: "-1495.548" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "NSIT_ATAI-2",
      name: "NSIT ATAI",
      fill: "#001755",
      translate: { x: "907.447", y: "1079.957" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-5",
      name: "4M",
      fill: "#001755",
      translate: { x: "921.125", y: "1106.65" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "NSIT_IBOM",
    path: {
      id: "Path_7266",
      dataName: "NKOT IBOM",
      d: "M1769.971,2098.236l-4.261-3.457,10.226-7.68-1.7-2.688,7.669-7.681,11.078,10.369v11.136l10.651,14.208,5.538,8.833,6.392,9.985,7.669,12.673,2.129,9.216,3.835,10.753v5.76l-1.7,5.376,1.7,5.76,8.521,9.216h6.817l-2.557,6.145-2.557,4.225-1.7,1.92h-28.547l-8.946,7.68-8.521,6.913-11.078-13.058,1.278-3.455,1.7-4.225v-12.673l-2.983-7.68-1.278-3.457,4.261-6.913,2.555-4.608-.851-5.376-8.521-13.056-4.688-9.216-2.129-3.072v-31.874Z",
      translate: { x: "-962.017", y: "-1114.247" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "NKOT_IBOM-2",
      name: "NKOT IBOM",
      fill: "#001755",
      translate: { x: "827.412", y: "1031.845" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-6",
      name: "4M",
      fill: "#001755",
      translate: { x: "837.34", y: "1054.822" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ORUK_ANAM",
    path: {
      id: "Path_7268",
      dataName: "ORUK ANAM",
      d: "M276.155,2339.048v-2.688l2.556-2.688,7.243-4.223,8.521-9.984,11.077-13.058,4.687-3.072,2.556-2.3h10.651l3.835,4.225h10.651a15.605,15.605,0,0,0,2.982,2.3,32.935,32.935,0,0,0,4.26-2.3l3.835,2.3,6.817-4.608,6.817-5.376,9.373-6.53,2.556-5.375,2.13-3.456,3.835-10.37,2.13-3.455,3.835-9.6,5.965-3.072,7.243-5.376,8.521-3.84,2.131-2.688,1.7-5.376,2.129-3.456,1.7-4.608,2.131-2.688,8.1-10.753,2.555-1.92,4.688.768,11.929,3.072,7.669,7.681,8.521,2.688,2.557,12.288-6.391,10.369v7.3l6.391,10.751,3.408,3.457,2.131,1.152,2.131,4.608-2.983,1.153-7.243,6.911-5.964,6.913-7.669,11.136-14.06,14.209-4.261,3.072-3.408,3.84h-1.7v8.833l1.7,1.92v8.833l-4.688,5.375-1.278,1.921H414.2l-9.372,8.063-4.261,6.145-4.26,5.76-3.835,7.681-1.7,1.92-2.129,2.3v3.457l-3.409,6.145-8.1,6.145-6.391,3.455-2.982,1.921h-5.113l-1.278-1.921H350.715v3.456l2.556,1.537v3.84h-2.556l-11.93-3.84-6.391,2.3-6.817,3.456H310.666l-5.539-1.92L296.18,2417l-3.835-5.376-6.391-8.831-5.539-5.761,9.8-9.983,2.13-4.608v-4.225l1.7-3.072-.852-2.3-1.7-2.3L288.085,2369l-3.834-3.457-6.391-5.376v-19.2Z",
      translate: { x: "300.024", y: "-1231.406" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ORUK_ANAM-2",
      name: "ORUK ANAM",
      fill: "#001755",
      translate: { x: "633.545", y: "1101.107" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-4",
      name: "3M",
      fill: "#001755",
      translate: { x: "649.935", y: "1126.234" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "UKANAFUN",
    path: {
      id: "Path_7269",
      d: "M129.979,2164.772l-1.7-2.3,9.373-9.216,3.835-3.841,5.965-6.528,2.982-2.688,11.077-9.985,6.391-6.143,8.947-8.449,10.225-8.063,2.556-1.153h14.486l17.468-7.3,4.686-1.92,5.113,4.225,5.965,7.68,6.817,5.376,5.113,3.072,5.113,1.153h6.39l5.54-3.073,2.129-3.072V2101.41l4.688-4.225,1.278-3.072v-5.376l-2.131-1.536-2.557-3.457,5.114-4.223,6.391-4.225,14.912-5.376,7.669-5.76,8.521,3.072v8.065h-16.19l-3.409,4.225,3.409,3.456,9.372,1.92-1.7,3.072-5.114-.768-2.555,1.92-3.835,4.993-3.834,4.991-2.557,3.842-1.7,4.223-2.129,4.225-1.7,4.991-2.131,2.3-3.834,1.92-4.261,1.92-7.243,4.991-6.391,3.457-3.408,8.833-2.556,4.223-1.278,3.073-2.556,7.3-1.7,2.688-2.982,6.143-10.225,7.3-6.391,4.993-6.391,4.225-4.26-2.3-3.835,2.3-2.982-2.3H196.87l-3.408-4.225H182.81l-2.556,2.3-4.687,3.072-19.6,23.042-7.243,4.223-2.556,2.688-23.433-20.737,7.243-6.911,2.13-3.457-2.13-3.072-1.7-2.3,1.7-1.537Z",
      translate: { x: "430.011", y: "-1103.597" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "UKANATUN-2",
      name: "UKANATUN",
      fill: "#001755",
      translate: { x: "609.935", y: "1033.845" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-7",
      name: "4M",
      fill: "#001755",
      translate: { x: "631.935", y: "1055.872" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ETIM_EKPO",
    path: {
      id: "Path_7270",
      dataName: "ETIM EKPO",
      d: "M71.866,2011.207l8.521-10.753H95.3l5.113-1.535,8.1-3.841,9.373-6.528,3.408-9.216,3.835-4.225,19.173-23.041,17.468-8.831,5.539-7.3,7.669,16.128h2.556l11.5-8.831,15.338-6.53h8.948v13.826l16.189,16.128h6.818l-8.521,5.761-2.983,2.687,2.131,3.073,2.555,1.92v5.76l-1.7,3.073-4.261,3.84v11.52l-2.131,2.688L218,2008.9l-2.557,1.537h-5.964l-5.965-1.537-5.113-3.455-5.965-4.993-5.965-7.68-5.113-3.84-4.687,1.535-5.113,2.3-12.356,4.991H145.573l-3.408,1.153-10.651,8.063-14.486,14.209-13.634,12.288-9.373,9.984-10.651,10.37-5.965-5.376-1.7-4.993-3.834-3.072v-14.209l-2.13-2.688,2.13-2.3Z",
      translate: { x: "474.916", y: "-994.961" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ETIM_EKPO-2",
      name: "ETIM EKPO",
      fill: "#001755",
      translate: { x: "620.604", y: "977.532" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.5M-5",
      name: "3.5M",
      fill: "#001755",
      translate: { x: "620.604", y: "994.604" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ABAK",
    path: {
      id: "Path_7271",
      d: "M1098.166,1635.943l2.981,6.53-5.538.767v2.688l5.538,6.528v3.457l-2.981,3.073v6.143l7.243,17.666,2.555,9.6,5.114,13.824-2.131,4.608-2.983,11.138-4.26,8.063-2.557,4.608v16.9l-2.981,3.072-2.557,5.761v6.528l-7.669,13.439-4.686-4.223-6.392-11.136v-7.681l6.392-9.985-2.557-12.673-8.948-2.3-6.817-7.3-12.355-3.072,2.129-3.457-9.372-1.92-3.409-3.457,3.409-4.225h16.19v-8.065l-8.948-3.072-7.243,5.76-14.486,5.376h-7.669l-8.1-8.065-8.1-8.448v-13.441h19.172l1.7-4.223,2.983-9.6,8.1-4.223h6.391l7.243,1.535h8.948l12.355-19.2,2.981-3.455,5.966-8.065.426.383,2.981,2.3h5.54l3.834,1.535Z",
      translate: { x: "-319.184", y: "-737.207" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ABAK-2",
      name: "ABAK",
      fill: "#001755",
      translate: { x: "745.238", y: "940.965" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M",
      name: "2M",
      fill: "#001755",
      translate: { x: "750.89", y: "961.106" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "UYO",
    path: {
      id: "Path_7272",
      d: "M1547.013,1706.167l5.538-1.537,4.261,5.76v18.818l2.555,6.528,4.261,4.991,6.391,3.073h25.563v-1.152l20.452,2.688,8.947-7.681V1734.2l5.112-5.376,2.131-3.84,1.277-3.456,1.279-1.535,6.391,4.223h6.818v1.537h3.834l2.131,1.537h22.155l2.981,1.535,1.7,3.073v10.753l1.7,3.455,8.521,5.761,7.243,5.376,8.1,6.528.852,1.92v8.831l-7.669,8.833-8.948,5.76h-14.06l-23.859-13.439-12.781-2.3-2.983-1.535h-35.362l-11.078-10.37-7.669,7.681,1.7,2.688-5.112,3.84-7.669,5.76-3.835,10.753-1.7,8.065-5.112,6.143v9.6l2.557,3.072v5.376l2.555,5.376v11.9l-6.817,6.913-17.041-1.537-2.557-5.376,8.1-13.824v-5.376l1.7-6.528,2.981-3.84v-16.513l8.521-14.209,2.557-12.288-4.261-16.513-9.8-25.73v-4.991l2.981-2.688c-.554-1.9-1.283-5.008-.761-5.392-1.274-1.074-3.356-2.926-4.778-4.209Z",
      translate: { x: "-770.587", y: "-798.981" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "UYO-2",
      name: "UYO",
      fill: "#001755",
      translate: { x: "868.313", y: "951.979" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-2",
      name: "2M",
      fill: "#001755",
      translate: { x: "883.313", y: "969.468" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "uruan",
    path: {
      id: "Path_7273",
      d: "M2262.979,1584.5l-5.114-3.073h-46.439l-8.947,8.45-8.1,2.688h-7.243l-17.9,14.976-10.226,8.065,2.983,2.3,3.835,2.3h6.817v1.152h3.834l1.7,1.92H2201.2l2.557,1.152,1.7,2.688v11.138l1.278,3.455,14.486,9.6,10.226,8.448.852,1.536-.852,9.216-2.131,2.3-5.538,6.53-8.948,5.76-12.355-.385v4.225l1.278,3.072,1.7,3.456,2.983,14.593h3.408v19.969l1.7-.383,2.131-1.153,6.391-2.688,7.243,1.92,4.688,1.921,5.538.383,2.131,1.536h8.094l8.521,4.608,3.835,4.608,7.243,8.831,6.817-4.992,7.669-6.145,3.833-4.991,3.835-6.528.852-3.073h5.112l1.7-1.92,6.391-4.223,4.686-5.376,3.835-6.913-2.983-2.688-3.407-3.456-2.131-1.92-2.131-1.92-1.7-1.92-2.557-3.456-1.7-3.455-2.981-4.225-1.278-9.6-1.279-3.84-2.557-1.92-1.7-.383v-1.537l-2.555-3.072-2.557-2.3-.852-3.072-1.7-1.92-2.129-.768v-4.993l-2.131-.383v-6.913h-1.7v-9.985h1.7v-33.794l-1.7-3.455-5.965-5.761Z",
      translate: { x: "-1295.253", y: "-694.599" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "UKPUNU-2",
      name: "UKPUNU",
      fill: "#001755",
      translate: { x: "919.311", y: "918.613" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-5",
      name: "3M",
      fill: "#001755",
      translate: { x: "936.623", y: "937.979" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ITU",
    path: {
      id: "Path_7274",
      d: "M1976.107,1137.777l-5.964-6.145h-3.409l-8.1,11.521-1.278,6.911v2.688l6.817,6.913-14.486,12.288-2.129,2.3-8.521,9.984-2.983,3.072-6.391,10.368-1.7,3.841v3.455h1.7l1.279,7.3-8.521,5.761-9.8,4.991-3.834,8.448-5.114,5.761-1.277,2.688,20.45,2.687,9.374-7.68v-3.457l5.538-5.76,3.408-7.68,5.114-4.225,13.633-11.136,10.226-8.448h5.964l8.521-2.3,8.946-8.833h46.866l-2.981-3.072-2.131-1.92v-4.993l-1.7-1.535v-8.833l1.7-3.072v-17.281l-18.747-26.882-5.964-4.223h-10.651l-6.392,14.593-8.946,11.9Z",
      translate: { x: "-1077.829", y: "-296.259" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "IBU-2",
      name: "IBU",
      fill: "#001755",
      translate: { x: "905.004", y: "863.526" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-3",
      name: "2M",
      fill: "#001755",
      translate: { x: "905.004", y: "881.148" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ESSIEN_UDIM",
    path: {
      id: "Path_7275",
      dataName: "ESSIEN UDIM",
      d: "M320.125,1399.042c1.7-2.459-.71-3.841-2.13-4.225l18.32-7.68,8.1-1.536,8.947-8.448H372.53l5.113,2.688h10.651v-.768l1.278-8.833h11.5l1.7,1.92,1.7,9.6,10.651,7.68,9.8,3.456H429.2l2.555-1.92h5.114l6.817,3.072,5.964,3.456,8.521,6.528h12.781l12.357-9.984,5.112.768h14.06l5.538,1.92-8.52,13.056-10.226,13.441-7.669,10.753-4.261,6.911H469.67l-3.408-1.152h-11.93l-8.094,4.993-1.7,9.6-2.131,2.688h-28.12l-3.409,1.92-11.93,4.993-5.113,3.84-6.391,4.991h-2.556l-5.113-12.288-1.7-3.457-2.982-1.536-3.835-1.92h-6.391l-14.486-4.223-20.451-16.513-7.243-10.37-2.556-4.991C319.415,1412.353,318.421,1401.5,320.125,1399.042Z",
      translate: { x: "264.575", y: "-515.666" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ESSIEN_UDIM-2",
      name: "ESSIEN UDIM",
      fill: "#001755",
      translate: { x: "639.33", y: "897.832" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-6",
      name: "3M",
      fill: "#001755",
      translate: { x: "653.935", y: "921.17" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IKOT_EKPENE",
    path: {
      id: "Path_7276",
      dataName: "IKOT EKPENE",
      d: "M877.161,1079.655l-1.278-9.6,4.26-6.53v-8.831l2.13-7.681v-19.968h11.078l4.686-1.921,1.7-1.92,10.226-9.985,7.669,9.985,2.555,6.528,2.557,5.761,10.226,9.216,5.538,2.3,2.557,1.92,6.391,7.3-3.835,9.6V1078.5l12.357,13.056-12.357,10.368H930.418l-7.669-6.528-9.374-4.992-3.834-1.536H904l-1.7,1.536h-4.261l-10.651-3.456Z",
      translate: { x: "-208.102", y: "-213.177" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "IKOT_EKPENE-2",
      name: "IKOT EKPENE",
      fill: "#001755",
      translate: { x: "676.89", y: "848.397" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-2",
      name: "5M",
      fill: "#001755",
      translate: { x: "695.209", y: "863.526" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IKONO",
    path: {
      id: "Path_7277",
      d: "M1026.781,831.876l-8.521-10.753c-2.131-2.048-6.647-6.452-7.669-7.68l-6.391-7.68v-4.224l17.467-4.992,10.226-9.6,23.007.768,6.391,3.456,10.652,10.368,4.26,3.456,4.261,2.688,12.355,3.072,1.7,1.152v3.072l1.278,1.152,7.243,2.3,5.112,3.84h3.409v3.457l2.981,3.456,3.835,3.456.852,11.136,2.555,6.528-7.243,10.753-2.981,8.448,2.981,8.833,4.688,6.528-1.7,8.448-2.983,6.913-.852,7.3,3.835,1.92v16.513l5.964,2.688v4.992l-5.964,6.145,1.278,7.3,5.965,3.457v8.448l-5.965,3.072v4.608l-4.687-1.152-4.26-2.3-4.688-5.76-2.129-5.76V932.872l-5.54-8.065-1.7-4.608h-2.983l-3.409-1.535h-5.964l-2.981-2.688,8.946-13.441L1083.446,901h-12.781l-5.964-1.152-4.687-3.456-8.1-9.6V874.5l4.261-10.368-7.243-7.68L1041.693,853l-10.652-9.216-2.131-5.376Z",
      translate: { x: "-316.819", y: "-21.463" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "IKONO-2",
      name: "IKONO",
      fill: "#001755",
      translate: { x: "739.238", y: "819.021" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-7",
      name: "3M",
      fill: "#001755",
      translate: { x: "756.693", y: "829.923" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OBOT_AKARA",
    path: {
      id: "Path_7278",
      dataName: "OBOT AKORA",
      d: "M398.094,742l11.93-10.368v4.608l12.782,19.2v2.3l.852,2.3,9.8,10.753,3.835,3.456-6.817,6.528-5.114,5.376-5.538,2.3H409.171v20.354l-1.7,8.831v6.913l-4.261,7.3-2.13-2.3h-11.5l-1.278,9.218h-11.93l-3.835-2.688H353.784l-9.8,8.831-8.1,1.537-11.077,4.225-6.817,3.072V838.775l1.7-4.608,2.13-4.61,1.7-3.455,1.278-2.688v-1.537l1.278-1.537V788.467l1.7-5.375,2.13-2.3,3.835-4.992v-6.145l-2.13-1.92-1.7-2.688-7.669-6.912v-5.376l2.13-1.536h3.408v1.536h9.8l1.7-1.536,2.13-4.224,2.13-4.992h8.521l1.278,1.536h10.651l3.409-3.456h4.261V742l7.669,3.84h6.391l1.7-2.3Z",
      translate: { x: "264.575", y: "25.401" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OBOT_AKORA-2",
      name: "OBOT AKORA",
      fill: "#001755",
      translate: { x: "602.33", y: "804.043" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4.5M-2",
      name: "4.5M",
      fill: "#001755",
      translate: { x: "614.219", y: "832.984" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IBIONO IBOM",
    path: {
      id: "Path_7279",
      d: "M1636.8,603.583l-4.26-2.688-6.391,5.376-16.19,3.456-8.521,14.977L1584.4,644.289v8.448l1.278,1.152v3.84l2.131,1.536,6.391,1.92,5.538,3.841h3.409v3.84l2.555,2.688,4.261,2.688,1.277,12.673,2.131,5.76-2.981,4.225-4.687,6.911-2.555,8.065,2.555,8.448,4.688,6.913-1.7,9.985-2.983,5.76v7.3l3.409,1.537-.426,16.128,5.964,3.457v4.993l-5.538,5.76,1.278,6.528,5.964,3.84v8.833l-5.964,3.072v4.608h22.581v-1.92l1.7-2.688,1.7-1.92,2.983-3.072,1.7-3.84,2.129-4.993,10.652-5.376,7.243-4.992-1.278-7.681h-1.278v-3.84l8.1-13.441,12.781-14.976,15.338-13.056-7.243-6.913,1.7-9.6,8.1-11.138h3.409V696.9l-2.131-5.761-3.835-3.072-3.834-1.92-8.521-4.225-6.817-6.528V662.722l-2.131-3.456-4.26-6.528-8.948-7.3-3.409-5.376-6.391-1.152-2.981-3.456H1628.71l1.278,1.92h-7.243l-1.278-1.92-11.5-13.057v-2.688h20.024l2.557,2.688,11.929,2.3,7.669-1.152,1.278-4.992L1636.8,606.271Z",
      translate: { x: "-808.4", y: "136.17" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "IBIONO-2",
      name: "IBIONO",
      fill: "#001755",
      translate: { x: "822.768", y: "829.093" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-8",
      name: "4M",
      fill: "#001755",
      translate: { x: "837.34", y: "852.07" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "INI",
    path: {
      id: "Path_7280",
      d: "M796.991,0l-2.555,5.76,10.651,12.289-3.408,22.273L800.4,46.85l2.983,1.536v5.376H798.7l-4.26-5.376H783.784l-10.225-6.144L755.664,57.987V72.963l-3.409,3.84-4.687,8.832h8.1L773.558,76.8,783.784,81.8V94.084l-10.225,18.049v3.84l12.782,19.585,17.043-5.76,11.077-8.832h22.155l8.1,3.84,10.226,10.753,7.669,5.376,12.781,3.072v-8.448l3.835-3.456L892,115.973,900.524,101l15.338-3.072,11.5-9.6V81.8L919.7,72.963v-7.68l-3.834-4.608H892l-20.876-8.448-8.521-7.68-4.687-16.129L844.71,22.273v-5.76L842.153,5.76,836.615,0H821.7l-9.8,3.072L806.791,0Z",
      translate: { x: "-99.386", y: "645.285" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "INI-2",
      name: "INI",
      fill: "#001755",
      translate: { x: "728.843", y: "711.288" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-9",
      name: "4M",
      fill: "#001755",
      translate: { x: "725.843", y: "737.838" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IKA",
    path: {
      id: "Path_7281",
      d: "M56.239,1788.123l4.261-8.063,20.877,16.513,14.486,4.223h6.391l5.965,3.073-5.113,6.911-7.243,3.457-5.113,2.688-6.817,3.84-4.687,5.376-7.669,9.985-6.391,8.448-2.982,3.073-2.982,8.448L49,1863.391l-8.521,3.84-5.539,1.537H20.877v-7.681l-2.982-2.688H9.373l-7.243-6.143L0,1849.182l2.13-4.608V1841.5l2.556-2.3,4.687-3.072,8.521-1.92,2.982-3.84,7.243-2.688,6.817-6.913,2.13-3.84-2.13-2.688v-3.457h11.5l4.686-5.376L40.9,1800.412l12.782-8.065Z",
      translate: { x: "534", y: "-862.89" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "IKO-2",
      name: "IKO",
      fill: "#001755",
      translate: { x: "582.57", y: "963.532" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-3",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "565.629", y: "984.532" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ibesikpo_ASUTAN",
    path: {
      id: "Path_7267",
      dataName: "IBAAKPO  ASUTAN",
      d: "M1944.24,2153.236V2142.1h34.936l3.409,1.92,12.781,2.3,23.859,13.439h1.278v3.841l2.981,6.912,2.983,14.209,2.983.768v23.424l-2.557,19.2-5.538,8.065c-1.989,2.3-6.307,6.913-7.669,6.913s-4.546,1.535-5.966,2.3l-4.26-4.608-6.391,4.608h-8.1l-8.521-9.216-1.7-5.376,1.7-5.376V2218.9l-3.834-9.985-2.131-9.6-2.131-4.225-11.5-18.049Z",
      translate: { x: "-1113.278", y: "-1169.632" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "IBAAKPO_ASUTAN-2",
      name: "IBAAKPO \n        ASUTAN",
      fill: "#001755",
      translate: { x: "859.412", y: "1008.667" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-8",
      name: "3M",
      fill: "#001755",
      translate: { x: "884.431", y: "1040.822" },
      tspan: { X: "0", y: "0" },
    },
  },
];
