export const data = [
  {
    state: "Yagba_west",
    path: {
      id: "Path_6824",
      dataName: "Yagba west",
      d: "M47.8,48.811,55.727,38.5h6.345l3.173,2.379,7.138,5.552,11.9,6.345L96.97,61.5l14.277,3.966v34.9l2.379,13.483-2.379,9.518-3.966,7.931-6.345,4.759L93,147.16v20.622l2.379,10.311H78.728l-3.966,3.173-6.345,4.759-4.759,3.966-3.173,3.966-4.759,1.586V188.4l1.586-10.311V167.782H53.347v-3.173l3.966-1.586,4.759-3.966,1.586-5.552V147.16l1.586-4.759-1.586-3.173L58.9,136.056H51.761L47.8,139.229H40.657l-2.379-3.173L35.9,132.884H31.139l-2.379-3.966v-7.931a15.436,15.436,0,0,1,.793-3.966l-3.173-3.173H20.035l-3.966,3.966H10.518v-2.379L8.931,105.124,1,100.365V97.192l3.966-7.138L12.1,82.123l7.931-9.518L28.76,63.88l9.518-7.138Z",
      translate: { x: "0", y: "21.986" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Yagba_west-2",
      name: "Yagba west",
      fill: "#001755",
      translate: { x: "21.622", y: "110.097" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M",
      name: "4M",
      fill: "#001755",
      translate: { x: "35.898", y: "139.16" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "yagba_East",
    path: {
      id: "Path_6825",
      dataName: "yagba East",
      d: "M80.76,90.1V56H95.036L99.8,57.586l5.552,3.173,11.1,1.586H148.97l10.311,3.966,14.276,3.173,11.9,1.586,11.9-1.586V79l-4.759,3.966-11.1,4.759-13.483,3.966-8.725,4.759-11.9,3.966-9.518,3.966-6.345,6.345-10.311,11.9-7.931,10.311-3.173,8.725-4.759,7.931-4.759,11.1-.793,7.931v17.449l2.379,9.518,2.379,5.552h8.725L122,215.421l-25.38,27.76H64.9l-4.759-6.345-3.173-4.759v-7.931l-2.379-8.725L53,209.076v-7.931l1.586-1.586h2.379l1.586,1.586h3.966l3.966-1.586v-3.966l1.586-2.379h2.379l1.586-3.173v-3.173l-1.586-2.379L68.07,182.9l-1.586-5.552L64.9,175.764l1.586-2.379,3.966-2.379v-3.173H66.483l-3.966-11.1V136.9l7.931-11.1,7.138-5.552,3.173-7.138,2.379-8.725Z",
      translate: { x: "30.486", y: "32.245" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "yagba_East-2",
      name: "yagba East",
      fill: "#001755",
      translate: { x: "118.385", y: "129.169" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_7M",
      name: "7M",
      fill: "#001755",
      translate: { x: "111.416", y: "172.062" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Mopa-muro",
    path: {
      id: "Path_6826",
      d: "M105.5,207.127l-8.725-15.07H87.259l-2.379-6.345L82.5,176.194V157.159l.793-6.345,2.379-4.759,7.138-14.277,3.173-7.931,11.9-15.07,12.69-13.483,21.415-7.931L152.3,81.811l7.931-1.586,7.138-3.173,8.725-3.966L178.47,71.5h3.173V89.742l-5.552,12.69,2.379,10.311-6.345,5.552v6.345l6.345,8.725h-11.9l-6.345,4.759h-4.759l-4.759,7.931-11.9,15.863-5.552,5.552v6.345L128.5,180.16l-12.69,15.863Z",
      translate: { x: "47.781", y: "41.333" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Mopa-muro-2",
      name: "Mopa-muro",
      fill: "#001755",
      translate: { x: "150.11", y: "168.826" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_9M",
      name: "9M",
      fill: "#001755",
      translate: { x: "162.585", y: "188.412" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Ijumu",
    path: {
      id: "Path_6827",
      d: "M79.5,211.056c9.518-9.783,28.553-29.029,28.553-27.76l26.967-34.9V141.26l7.138-6.345L158.814,113.5h4.759l1.586,2.379,4.759,7.138,6.345,9.518,1.586,4.759,2.379,7.138v7.931l-3.966,11.9v11.1L172.3,192.814l-7.138,11.1,3.173,7.138,3.966,9.518,7.931,8.725a51.186,51.186,0,0,1,2.379,5.552c.635,1.9,4.494,7.138,6.345,9.518l4.759,11.1v5.552l-1.586,5.552-3.173,3.966v7.138l-3.966,3.966-4.759,1.586h-9.518l-3.966,3.173v3.173l-3.173,6.345-3.173,3.173-1.586-3.173-1.586-6.345L147.71,275.3l-7.138-8.725-3.173-5.552V247.541l-2.379-5.552,2.379-5.552v-3.966H125.5l-3.966,2.379h-3.966l-7.138,3.966h-7.138c-2.644-.265-8.249-1.11-9.518-2.379L92.19,234.85l-3.173-2.379L92.19,229.3l1.586-4.759v-3.966H85.845L84.259,217.4H82.673L79.5,215.022Z",
      translate: { x: "46.022", y: "65.957" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Ijumu-2",
      name: "Ijumu",
      fill: "#001755",
      translate: { x: "169.605", y: "252.656" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M",
      name: "2M",
      fill: "#001755",
      translate: { x: "169.604", y: "276.45" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Kabba/Bunu",
    path: {
      id: "Path_6828",
      dataName: "Kabba/Bunu",
      d: "M144.9,166.419,133,148.177l5.552-3.966h9.518l-6.345-8.725v-5.552l7.931-7.138-3.173-11.1L151.242,99V83.932l-1.586-2.379V72.035l3.966-2.379,5.552-5.552,4.759-4.759,6.345-1.586L178.209,53h11.1L202,55.379l8.725,3.966,11.9,10.311,8.725,8.725,8.725,11.1L247.212,99l8.725,10.311,7.931,11.1,3.966,9.518,4.759,7.931,3.173,6.345-3.173,6.345-4.759,6.345-3.966,7.138-2.379,5.552-1.586,5.552v6.345l1.586,4.759,4.759,3.173v19.828h-2.379l-3.966-1.586-3.173-3.173-3.966-2.379-2.379-3.173-4.759-3.173-4.759-3.966-3.966-5.552-3.173-3.173-3.966-3.173-5.552-5.552-3.966-4.759-4.759-5.552-4.759-3.173H196.451l-3.966,3.173-1.586,5.552,1.586,5.552,3.966,4.759,3.173,6.345,1.586,7.931,5.552,4.759,7.138,7.138,6.345,5.552L225,216.387v4.759l-1.586,4.759v15.863l-4.759,3.173-7.138,4.759-5.552,3.966v6.345l1.586,11.1v10.311h-1.586L202,279.045l-4.759-2.379H179l-3.966,2.379-4.759,3.966-5.552,3.173-2.379,4.759-3.173-7.931-7.138-11.9-2.379-5.552-8.725-10.311-6.345-15.863,6.345-10.311,3.966-19.828V198.938l4.759-12.69V179.9Z",
      translate: { x: "77.389", y: "30.487" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Kabba_Bunu-2",
      name: "Kabba/Bunu",
      fill: "#001755",
      translate: { x: "240.493", y: "146.261" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_9M-2",
      name: "9M",
      fill: "#001755",
      translate: { x: "265.766", y: "168.311" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Lokoja",
    path: {
      id: "Path_6829",
      d: "M151,92.211V77.141l3.173-19.035,4.759-15.07,7.931-16.656,4.759-11.1,4.759-8.725V1h23l11.1,5.552,11.9,8.725,9.518,11.1,3.966,9.518L243,51.761l2.379,10.311V70.8l4.759,9.518,3.966,7.138,6.345,4.759,8.725,7.138,5.552,9.518h7.138l12.69,17.449,7.931,9.518,6.345,9.518,11.1,11.9,5.552,4.759V174.7l7.931,11.1,11.1,10.311,6.345,11.9V220.7l2.379,15.863v17.449l-2.379,17.449-3.966,7.931-2.379,9.518-2.379,13.483c-2.908-1.321-8.725-4.6-8.725-7.138s-4.231-10.046-6.345-13.483l-4.759-5.552-4.759-6.345-11.1-3.966-11.9-4.759h-6.345l-1.586-2.379-3.173-3.173h-3.173l-3.966,1.586L266.8,261.15l-7.931,3.173h-7.138l-3.173,1.586-2.379,3.966-3.123,3.905a.272.272,0,0,1-.049.06l.049-.06c.081-.165-.765.128-4.808,2.44-4.442,2.538-5.024,2.115-4.759,1.586l-4.759,1.586h-6.345l-5.552-3.173-2.379-2.379-1.586-2.379v-15.07l1.586-4.759v-4.759l-23.794-22.208-1.586-8.725-3.173-5.552-3.966-5.552-1.586-5.552,1.586-4.759,3.173-3.173h15.07l4.759,3.173,11.9,13.483,7.138,6.345,6.345,7.931,10.311,7.931,1.586,2.379,3.966,2.379,3.966,3.966,4.759.793.793-3.173V219.906l-4.759-3.173-1.586-5.552.793-8.725,1.586-3.966,3.966-7.138,3.966-7.138,6.345-8.725-1.586-2.379-7.931-15.07-7.931-15.07L234.28,126.316l-11.1-14.277L201.761,90.625l-11.1-4.759-11.1-2.379h-11.1l-3.173,1.586-4.759,2.379-3.173,1.586-3.966.793Z",
      translate: { x: "87.941", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Lokoja-2",
      name: "Lokoja",
      fill: "#001755",
      translate: { x: "357.913", y: "198.45" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-2",
      name: "2M",
      fill: "#001755",
      translate: { x: "366.116", y: "219.944" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Kogi",
    path: {
      id: "Path_6831",
      d: "M265.173,130.641l-2.379,5.552L262,145.711l4.759,6.345,5.552,6.345,6.345,5.552,1.586,3.173,5.552,10.311.793,14.277,1.586,8.725v25.38l-2.379,11.9L281.828,251.2h2.379l3.966-3.966,5.552-3.966,8.725-5.552,6.345-2.379,9.518-3.173,7.931-1.586,13.483-2.379,1.586-2.379v-1.586l-1.586-2.379-2.379-2.379a10.519,10.519,0,0,1-1.586-3.173v-1.586h3.966v-3.966l1.586-7.138.793-3.173,1.586-1.586,1.586-5.552v-4.759l-1.586-3.966V182.2l1.586-4.759,2.379-3.173V156.815l1.586-7.138,2.379-6.345v-7.138l-9.518-14.277v-11.9l-2.379-3.173-3.966-3.173-3.173-1.586V98.123l1.586-6.345,3.173-4.759,2.379-7.138L343.693,66.4l2.379-11.9H298.484l3.173,15.863v5.552l-11.1,14.277h-9.518v7.931H277.07L273.9,99.709l.793,2.379,3.966,1.586,2.379,3.173-2.379,3.173-1.586,1.586H274.69l-2.379-3.173v-1.586l-3.173-1.586-1.586,1.586-.793,1.586v3.173l2.379,3.173v1.586l-2.379,1.586-1.586,2.379Z",
      translate: { x: "153.019", y: "31.366" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Koton_Karfe",
      name: "Koton Karfe",
      fill: "#001755",
      translate: { x: "425.331", y: "175.036" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M",
      name: "6M",
      fill: "#001755",
      translate: { x: "456.262", y: "223.116" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Okehi",
    path: {
      id: "Path_6832",
      d: "M153.259,235.555v-7.931l3.173-5.552,4.759-3.173,3.966-2.379,4.759-3.173h18.242l9.518,4.759.793-.793-.793-11.9-.793-8.725v-6.345l17.449-11.9,2.379,3.173,7.931,3.966h2.379l5.552-.793,4.759-.793,7.138-3.966,5.552-7.931,3.173-1.586,8.725-.793,15.863-7.138L280.954,161l4.759,1.586,1.586,2.379V172.9l-3.966,6.345-5.552,7.931-9.518,10.311-12.69,7.931-15.863,11.9L230.986,230.8l-7.138,6.345-15.863,1.586-5.552,4.759h-3.173L194.5,241.9l-7.138-.793-6.345.793-2.379,1.586-7.931,3.966-8.725,3.173-8.725,3.966-4.759-3.966v-7.138l3.173-4.759Z",
      translate: { x: "86.477", y: "93.805" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Okehi-2",
      name: "Okehi",
      fill: "#001755",
      translate: { x: "295.538", y: "293.565" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-2",
      name: "4M",
      fill: "#001755",
      translate: { x: "297.719", y: "312.13" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Adavi",
    path: {
      id: "Path_6833",
      d: "M155.966,261.263l-2.379-7.931L152,251.745l6.345-2.379,21.415-9.518,6.345-.793,6.345.793,4.759,1.586h3.966l5.552-4.759,15.863-1.586,7.138-7.138,8.725-13.483,15.07-11.1,12.69-7.931,9.518-10.311,9.518-14.277.793-6.345h9.518l11.9,5.552,9.518,4.759,6.345,7.138,3.966,7.931-6.345,3.173L313.8,199.4l-18.242,10.311L277.316,222.4,260.66,233.5l-12.69,7.931L244,249.366l-4.759,11.9-1.586,8.725-3.173,4.759-4.759,2.379h-5.552l-13.483-2.379h-2.379l-3.173-3.173-3.966-3.966-6.345-3.173-5.552-3.173-9.518-5.552-6.345-2.379L167.07,257.3Z",
      translate: { x: "88.528", y: "95.857" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Adavi-2",
      name: "Adavi",
      fill: "#001755",
      translate: { x: "361.8", y: "295.811" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-3",
      name: "4M",
      fill: "#001755",
      translate: { x: "343.478", y: "312.617" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Okene",
    path: {
      id: "Path_6834",
      d: "M170.69,220.5,158,227.638l1.586,7.138,3.966,2.379,4.759,2.379,2.379,3.173,3.966,5.552.793,4.759h3.966l3.966-2.379H192.1l3.966-3.966,4.759-3.966,2.379,3.966,3.173,3.966,4.759,5.552c1.586,2.644,4.917,8.566,5.552,11.1s4.494,4.231,6.345,4.759l3.173,3.173,5.552-3.173,4.759-2.379h1.586l3.173,2.379.793,3.173-3.966,2.379,1.586,1.586,2.379,1.586v3.173l2.379,3.966,4.759-3.966h3.173v3.966l3.173,3.173,1.586-3.173V277.606a10.455,10.455,0,0,0,1.586-3.173v-3.173c0-.793.793-3.173,0-3.966-.635-.634-1.321-3.437-1.586-4.759l-1.586-3.173-3.173-6.345-3.173-3.966-4.759-3.173-2.379-3.173-3.966-5.552-4.759-2.379v2.379l-1.586,3.966-1.586,1.586-7.138,2.379-11.9-2.379L204,241.122l-5.552-6.345Z",
      translate: { x: "92.046", y: "128.688" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Okene-2",
      name: "Okene",
      fill: "#001755",
      translate: { x: "278.854", y: "411.624" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M",
      name: "5M",
      fill: "#001755",
      translate: { x: "295.537", y: "429.232" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Ogori/magongo",
    path: {
      id: "Path_6835",
      dataName: "Ogori/Magor",
      d: "M160.6,233.966,164.57,230l3.966.793,5.552,3.173,5.552,7.931,2.379,4.759v2.379l-2.379,3.173-2.379,2.379-3.173,3.966-2.379,2.379-3.173-3.966-2.379-2.379-3.173-.793-4.759,2.379-3.173.793-3.966-3.173-.793-4.759L149.5,241.9l.793-2.379,4.759-2.379Z",
      translate: { x: "87.063", y: "134.258" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Ogori_Magor-2",
      name: "Ogori/Magor",
      fill: "#001755",
      translate: { x: "155.663", y: "399.387" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-2",
      name: "5M",
      fill: "#001755",
      translate: { x: "189.099", y: "415.17" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Ajaokuta",
    path: {
      id: "Path_6836",
      dataName: "Ogori/Magor",
      d: "M287.4,185.259l7.931-4.759h.793l4.759,10.311,9.518,7.931v5.552l-1.586,8.725-2.379,7.931-10.311,9.518-3.966,10.311,1.586,7.931,1.586,14.276.793,7.138-.793,7.138v33.312l-3.173,19.035v16.656l-2.379,5.552-2.379-2.379-1.586-3.173-3.173-3.966-1.586-3.173-3.966-5.552L274.71,331.2h-9.518l-1.586,2.379-3.173,1.586h-11.9l-7.138,3.173-5.552.793v-2.379l-.793-3.173-1.586-2.379V316.92l-3.966-.793-.793-5.552.793-10.311.793-3.173.793-5.552-1.586-1.586-1.586-5.552-2.379-4.759-4.759-7.138-3.966-3.173-7.138-8.725-3.173-2.379.793-6.345,3.966-11.1,5.552-8.725,31.726-21.415,11.9-7.931,10.311-5.552,11.1-6.345Z",
      translate: { x: "120.48", y: "105.237" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Ajaokuta",
      fill: "#001755",
      translate: { x: "349.766", y: "352.591" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_7M-2",
      name: "7M",
      fill: "#001755",
      translate: { x: "367.344", y: "375.116" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Bassa",
    path: {
      id: "Path_6837",
      d: "M278.742,226.677l3.173-20.622,4.759-1.586,3.966-3.966,11.9-7.931,11.9-4.759,11.1-1.586,15.863-4.759,1.586-.793,7.138-3.173,11.9-7.931,10.311-6.345,11.1-4.759,11.1-3.966,11.9-5.552,7.931-3.173L424.68,143.4l12.69-3.966,12.69-3.173c2.644-.528,8.249-1.586,9.518-1.586a45.628,45.628,0,0,0,7.138-1.586l8.724-.793,10.311-.793-1.586,3.173-1.586,4.759-5.552,7.931-3.966,7.138-11.1,15.07-3.966,6.345-.793,4.759.793,12.69-3.172,3.173-4.759.793-3.966,2.379-4.759,2.379-3.966,2.379-8.724,1.586-9.518,1.586-4.759,3.173-4.759,3.173-4.759,3.173-3.173,4.759-3.173,4.759-3.966,5.552-5.552,1.586-5.552,1.586-7.931,1.586H354.09l-5.552,1.586-4.759,5.552-3.966,7.931-3.173,3.173-5.552,1.586-7.931,1.586-5.552.793-6.345,1.586-4.759,3.173-3.173,3.966-3.173,3.173-3.173,7.138v33.312l-1.586,1.586h-2.379l-4.759,1.586-3.966-3.173-2.379-3.173-1.586-2.379-1.586-4.759H268.431l-3.966-2.379v-3.966l-1.586-12.69L260.5,270.3l.793-2.379,3.173-8.725,7.138-6.345,3.966-3.966,1.586-4.759,1.586-11.1Z",
      translate: { x: "152.14", y: "76.509" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Bassa-2",
      name: "Bassa",
      fill: "#001755",
      translate: { x: "521.012", y: "263.717" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-3",
      name: "5M",
      fill: "#001755",
      translate: { x: "525.266", y: "281.324" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Omala",
    path: {
      id: "Path_6838",
      d: "M436.347,131.5H414.932l-11.1,16.656L395.1,162.432,384,175.916v19.035l3.173,3.966,4.759,9.518,9.518,7.138,4.759,11.9,7.138,9.518V255.23a87.13,87.13,0,0,0,3.173,11.9c1.269,3.173,4.759,10.839,6.345,14.277v10.311h10.311a68.77,68.77,0,0,1,7.138-5.552c1.269-.634,4.231-5.552,5.552-7.931l3.173-8.725,2.379-6.345,8.724-3.966,20.622-7.931,15.07-2.379,12.69,4.759V221.918l-5.552-6.345-7.138-7.138-8.724-13.483-6.345-12.69V156.087l3.966-14.277h-7.138l-7.931-3.173-9.518-3.966H445.865Z",
      translate: { x: "224.545", y: "76.509" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Omala-2",
      name: "Omala",
      fill: "#001755",
      translate: { x: "646.514", y: "263.717" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M-2",
      name: "6M",
      fill: "#001755",
      translate: { x: "652.648", y: "286.024" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Dekina",
    path: {
      id: "Path_6839",
      d: "M437.369,174.379,444.507,172l5.552,11.1,10.311,8.725,4.759,11.9,6.345,9.518v19.035l3.966,10.311,3.173,8.725,3.966,10.311V295.73l-1.586,6.345-4.759,1.586h-46l-3.966-3.173h-11.1l-1.586,4.759-.793,3.966-1.586,2.379-2.379,4.759-2.379.793h-6.345l-6.345,1.586-8.725-2.379-3.966-2.379-1.586-2.379-1.586-2.379-1.586-5.552-1.586-3.173-4.759-6.345-1.586-7.138v-8.725h-6.345l-3.966.793-4.759,2.379-2.379,3.966-1.586,3.966v3.966l-3.173,3.966-3.173,1.586h-23l-3.966-1.586H311.26l-6.345,1.586H298.57l-4.759-1.586-3.173-3.966-1.586-2.379-3.173-3.173H283.5v-34.1l3.966-6.345,3.173-3.966,3.173-3.966,3.173-1.586,3.966-2.379,22.208-3.966,3.173-3.966,3.966-7.931,4.759-4.759,5.552-1.586h22.208l19.035-4.759,9.518-15.863,14.276-9.518,18.242-2.379Z",
      translate: { x: "165.624", y: "100.254" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Dekina-2",
      name: "Dekina",
      fill: "#001755",
      translate: { x: "543.507", y: "334.212" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-4",
      name: "4M",
      fill: "#001755",
      translate: { x: "551.054", y: "365.38" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Ankpa",
    path: {
      id: "Path_6840",
      d: "M418.794,263.4v-15.07h8.724l9.518-8.725,5.552-15.07,3.173-6.345,12.69-3.966,21.415-7.138,11.9-1.586,8.725,5.552v44.416l3.966,20.622,11.1,3.173,10.311-3.173,7.138,3.173v7.931l-7.138,12.69-2.379,7.138v7.931h-7.931l-11.1-3.173h-3.966l-6.345,5.552h-2.379L487,314.953H472.728l-6.345-7.931H452.106l-12.69,10.311c-3.173,0-9.518-.476-9.518-2.379s-11.632-.793-17.449,0H398.966L395,311.781V309.4l3.966-2.379,7.138-3.173,6.345-3.966,3.966-5.552V276.089Z",
      translate: { x: "230.994", y: "119.894" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Ankpa-2",
      name: "Ankpa",
      fill: "#001755",
      translate: { x: "668.823", y: "382.988" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M",
      name: "3M",
      fill: "#001755",
      translate: { x: "676.698", y: "407.598" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "OFU",
    path: {
      id: "Path_6841",
      d: "M488.131,265.26l4.759-1.586h1.586a8.064,8.064,0,0,1,.793,3.173v8.725L492.1,280.33l-7.138,3.966-3.966,2.379-6.345,3.966v3.173l-2.379,2.379-3.966.793-3.966,1.586h-3.966l-2.379,2.379-1.586,3.173v5.552l-1.586,7.138-4.759,7.931-3.966,3.966-3.966,1.586h-3.966L434.2,329.5l-3.173-.793-3.966-2.379-3.173-3.966-3.173-5.552-3.966-3.966c-.634-.634-3.966-1.321-5.552-1.586l-4.759.793H384.23l-4.759-2.379-7.931-3.966-6.345-.793v9.518l.793,4.759-.793,3.173H364.4l-3.173,1.586-7.138,1.586-13.483,1.586-6.345-1.586-3.173-1.586-7.138-3.173-5.552-3.173c-2.115-1.058-6.5-3.173-7.138-3.173s-7.138-1.586-10.311-2.379l-7.931-2.379-2.379-3.173-6.345-7.138-2.379-2.379-3.966-1.586-3.173,1.586-1.586,4.759.793,4.759-.793,8.725-3.173,3.173-2.379,3.966-1.586,5.552-2.379,7.931-.793-3.173-1.586-11.9v-5.552l-.793-4.759V296.985l3.966-21.415-.793-27.76.793-10.311,2.379.793h11.1l1.586,3.173,1.586,3.966,3.173,4.759,3.966,3.173,3.966-1.586H295.4l2.379-2.379,3.173.793,2.379,3.173,3.966,3.966,3.173,3.173h5.552l3.966-.793,7.138-.793,3.966.793h26.174l3.173-2.379,2.379-3.173V250.19l1.586-2.379,1.586-4.759,3.173-1.586,3.173-1.586h9.518l.793,11.9,1.586,4.759,5.552,8.725,1.586,5.552,3.966,4.759,3.966,2.379,7.138,2.379,7.138-2.379H422.3l3.966-6.345,2.379-9.518,11.1-.793,4.759,3.966h43.623Z",
      translate: { x: "152.14", y: "138.655" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Ofu-2",
      name: "Ofu",
      fill: "#001755",
      translate: { x: "478.9", y: "415.686" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_1M",
      name: "1M",
      fill: "#001755",
      translate: { x: "477.643", y: "438.25" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Olamaboro",
    path: {
      id: "Path_6842",
      d: "M432.985,343.262l-7.138-7.931-.793-.793-2.379,1.586h-.793l-2.379.793-1.586,1.586-1.586.793h-3.966l-2.379-1.586-3.966.793-1.586.793c.265.793.635,2.538,0,3.173a1.082,1.082,0,0,0,0,1.586l-.793,3.966-2.379,3.173-1.586,1.586-3.173-1.586-.793-3.173-2.379-3.966-1.586-2.379-.793-2.379-.793-3.173-3.966-.793h-8.725l-3.966-3.966v-3.173l.793-6.345,1.586-3.173,2.379-3.966,3.173-6.345,3.173-3.173,3.966-6.345,1.586-7.931v-3.173l.793-3.966,2.379-3.966h3.966l3.966-.793,4.759-2.379,2.379-1.586,2.379,1.586h19.829l4.759-.793,6.345.793,2.379,2.379,6.345.793,7.138-4.759,5.552-4.759,3.173-.793h12.69l.793.793,6.345,7.138h13.483l5.552,2.379h1.586l7.138-5.552h3.966l3.173.793,7.931,2.379h7.138l-2.379,2.379-4.759,4.759v6.345l-4.759,7.138-5.552,10.311-10.311,4.759L498.023,316.3l-15.07,7.931-8.724,7.138-7.138,6.345v5.552l-2.379,7.931,7.138,6.345-1.586,4.759-5.552-3.173h-4.759l-5.552-4.759h-3.966c-1.321.528-4.283,1.269-5.552,0s-2.644-4.759-3.173-6.345h-3.966Z",
      translate: { x: "218.389", y: "157.416" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Olamabolo-2",
      name: "Olamabolo",
      fill: "#001755",
      translate: { x: "629.381", y: "461.251" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-2",
      name: "3M",
      fill: "#001755",
      translate: { x: "652.648", y: "480.655" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Igalamela-odolu",
    path: {
      id: "Path_6843",
      d: "M456.526,339.831l-1.586-5.552-2.379-1.586H442.249l-2.379-2.379-1.586-.793.793-3.173v-3.966l.793-3.966,1.586-3.173,2.379-3.173v-3.966h-2.379l-1.586.793H436.7l-3.966-.793-6.345-2.379-2.379-1.586-3.173-6.345-2.379-3.966-4.759-3.173-3.966-.793-5.552.793-7.138.793-11.1-.793-3.966-.793-2.379-.793-6.345-3.173-4.759-2.379h-3.966v15.07l-2.379,2.379-11.1,3.173-8.725,1.586h-6.345L332,304.139c-3.7-1.851-11.263-5.552-11.9-5.552s-8.2-4.231-11.9-6.345l-13.483-2.379-3.173-.793-1.586-1.586-4.759-5.552-4.759-5.552L277.276,274l-3.966,1.586-1.586,4.759v13.483l-3.966,3.966-2.379,4.759L263,309.691v5.552L265.379,320l2.379,3.173,5.552,1.586,4.759,5.552v3.966l-4.759,2.379-1.586,4.759-1.586,7.138,3.173,1.586,4.759,2.379c5.024.793,15.7,2.855,18.242,4.759a9.709,9.709,0,0,0,7.138,1.586l7.931-1.586H320.9l3.966,3.173-6.345,4.759,2.379,3.173,6.345,3.966H332l6.345,10.311,2.379,18.242v6.345l4.759,1.586,3.173,2.379v17.449h16.656l3.173-3.966h4.759l6.345-7.138,7.931-8.725,8.725-7.931,6.345-5.552a45.625,45.625,0,0,1,7.138-1.586h5.552l8.725-3.966-1.586-5.552v-11.9l6.345-3.966,8.725-3.966,16.656-9.518,4.759-2.379,1.586-2.379v-1.586L459.7,343.8Z",
      translate: { x: "153.605", y: "160.054" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Igalamela-odolu-2",
      name: "Igalamela-odolu",
      fill: "#001755",
      translate: { x: "463.275", y: "492.653" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_9M-3",
      name: "9M",
      fill: "#001755",
      translate: { x: "509.692", y: "519.309" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "IDAH",
    path: {
      id: "Path_6844",
      d: "M266.725,317.1v-9.518L276.242,306,281,311.552v2.379l-1.586,1.586-3.173,3.173-1.586,3.173-.793,4.759-1.586,3.966-4.759.793-5.552-.793H258v-3.966l3.173-3.966Z",
      translate: { x: "150.674", y: "178.815" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Idah-2",
      name: "Idah",
      fill: "#001755",
      translate: { x: "381.923", y: "496.798" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-3",
      name: "2M",
      fill: "#001755",
      translate: { x: "381.923", y: "508.505" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Ibaji",
    path: {
      id: "Path_6845",
      d: "M265.122,335.276V321.793L267.5,321l1.586.793h11.9l4.759,2.379a65.252,65.252,0,0,1,6.345,1.586,24.415,24.415,0,0,0,6.345.793l7.931,1.586c1.586.793,5.076,2.379,6.345,2.379h3.173l6.345-.793,8.725-.793,1.586,1.586,1.586,3.173-2.379,2.379-2.379,2.379,1.586,1.586,6.345,4.759h5.552l5.552,7.931c.793,3.966,2.379,12.214,2.379,13.483s1.058,9.518,1.586,13.483v2.379l-3.966,2.379v3.966a49.506,49.506,0,0,1-1.586,11.9c-1.269,4.442-3.7,5.552-4.759,5.552l-1.586-5.552v-5.552h-4.759l-4.759,5.552-1.586,4.759-9.518,4.759-8.725,10.311-4.759,5.552-4.759,3.173V436.8l2.379,7.138,2.379,12.69v3.966c-2.115,1.586-6.821,4.759-8.725,4.759s-13.483,3.7-19.035,5.552l-10.311,4.759H259.57v-25.38l-3.966-6.345-4.759-7.138-3.966-3.966v-12.69l3.966-10.311V401.9l-3.966-7.138L244.5,390.8l2.379-3.966,3.966-4.759,4.759-7.931L257.19,367v-4.759l4.759-4.759,3.173-7.931V335.276Z",
      translate: { x: "142.759", y: "187.609" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Ibaji-2",
      name: "Ibaji",
      fill: "#001755",
      translate: { x: "416.604", y: "582.59" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_9M-4",
      name: "9M",
      fill: "#001755",
      translate: { x: "422.265", y: "604.036" },
      tspan: { x: 0, y: 0 },
    },
  },
];
