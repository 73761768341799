export const data = [
  {
    state: "SHANI",
    path: {
      id: "Path_6881",
      d: "M116.042,1949.2l-10.673,4.2-17.3-14.893L86.6,1935.45l-22.081,19.095-11.777,1.528-7.36,1.528L36.55,1966l-2.944,4.583,9.937.765v1.528l4.416,6.492,7.36,8.785,4.784,7.635,11.777,1.528,5.888,1.147,12.513.765,7.729-3.44,4.416,1.528h4.416l2.208-3.437v-6.873l4.048-4.583,1.472-4.964,5.52-4.583,4.784-4.964,5.152-3.055,4.784-3.055,2.944-5.73-1.472-4.2-12.881-4.58Z",
      translate: { x: "486.436", y: "-704.116" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "SHANI-2",
      name: "SHANI",
      fill: "#001755",
      translate: { x: "552.92", y: "1306.113" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.5M",
      name: "3.5M",
      fill: "#001755",
      translate: { x: "555.555", y: "1274.224" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "CHIBOK",
    path: {
      id: "Path_6882",
      d: "M569.06,1617.792v-17.567l-5.152-8.4-3.68-14.9L546.612,1567h-8.833l-20.977,4.2-20.241,12.218L480,1600.225l2.576,4.583v3.437l2.576,3.437v3.055l4.784,3.055h3.68l1.1,1.912h5.152l2.576-1.912,2.944,1.912h5.52l3.68,1.143,3.68-1.143,3.312,2.671,11.776-1.528,2.944-1.143h4.416l3.68,2.671,3.68-1.528,6.625-1.143,4.048,2.671,2.576-2.671,2.576,2.671,3.68-1.528Z",
      translate: { x: "186.697", y: "-456.716" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "CHOBOK-2",
      name: "CHOBOK",
      fill: "#001755",
      translate: { x: "689.178", y: "1134.972" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M",
      name: "3M",
      fill: "#001755",
      translate: { x: "702.227", y: "1151.06" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "HAWUL",
    path: {
      id: "Path_6883",
      d: "M297.188,1803.411l9.568-6.492c-3.557-8.02-11.04-24.594-12.513-26.733s-4.294-.89-5.52,0c-1.227-.762-3.9-2.29-4.785-2.29s-2.576-3.564-3.312-5.349h-5.152l-7.36-6.107-12.145,7.635-9.937,9.932-7.728,8.02-4.048-2.674L228,1774.007l-3.312-6.111-2.944,4.964h-4.784l-11.04-14.512h-3.312l-7.729,5.727-2.208,12.6,4.784,9.166,1.1,4.964v12.6l-5.888,12.606,19.5,17.182,10.673-4.2,6.993-1.528,8.464,2.29,4.784,2.293,9.937-9.547h22.449l11.041-3.44,10.673-7.635Z",
      translate: { x: "379.629", y: "-583.917" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "HAWUL-2",
      name: "HAWUL",
      fill: "#001755",
      translate: { x: "618.73", y: "1208.729" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M",
      name: "4M",
      fill: "#001755",
      translate: { x: "624.191", y: "1221.902" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "BIU",
    path: {
      id: "Path_6884",
      d: "M90.9,1520.447c.589-.611,3.926-1.019,5.52-1.147l41.954,32.844,9.568,16.039h9.937l2.944,4.2v3.818l-5.52,1.146-16.929,20.241-12.145,7.638L114.454,1616.3l-5.152,6.111-4.784-2.293-6.624-6.492-2.576-4.583-2.944,4.583H87.589L76.548,1599.5H72.5l-6.624,5.73-2.944,11.837H50.419L33.49,1605.229l-1.84-5.73,4.048-7.257-2.208-6.111-7.36,7.257-3.312-7.257-4.784-3.436v-4.964l2.576-1.528v-2.29l-.736-3.821-4.416-2.671-2.576-3.821H9.2l-3.68,2.674-3.68-3.821L0,1557.489l14.353-5.726,22.449-7.639,7.361-1.146,3.68-2.674,7.728-.762,2.944-2.293,4.416-3.436,9.568-13.365h5.52l6.624,2.671C86.484,1522.484,90.312,1521.058,90.9,1520.447Z",
      translate: { x: "509", y: "-424.687" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "BIU-2",
      name: "BIU",
      fill: "#001755",
      translate: { x: "578.555", y: "1136.93" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.5M-2",
      name: "3.5M",
      fill: "#001755",
      translate: { x: "573.92", y: "1158.043" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ASKIRA/UBA",
    path: {
      id: "Path_6885",
      d: "M438.115,1670.614l5.52-1.528,2.576,2.674v3.818l5.152,3.44h3.68l1.472,1.524h4.416l2.944-1.524,2.944,1.524h5.152l3.68,1.147,3.68-1.147,4.416,2.293,10.673-1.147,2.576-1.147h4.416l4.048,2.293,10.673-2.293,4.048,2.293,2.576-2.293,2.576,2.293,2.944-1.147,1.84-3.818v-15.277l.736-1.909,27.233-3.055,6.992,4.2,23.185-1.147,1.1,4.583-4.784,14.13-12.881,28.261v6.492l-2.944,2.674v16.039l-8.1,1.909h-5.888l-16.561-6.873-8.833-11.075-7.728-3.821-7.36-3.055-6.624-2.29H490.006l-1.84-2.674-5.52,1.528-4.784,6.492-8.832,14.9-2.576,5.726-4.417-3.055h-2.208l-12.513-26.733H441.8l-3.68-1.909-4.416-5.73H427.81l-6.624-5.726Z",
      translate: { x: "226.189", y: "-517.57" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3M-2",
      name: "3M",
      fill: "#001755",
      translate: { x: "748.98", y: "1191.165" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "ASKIRAUBA-2",
      name: "ASKIRAUBA",
      fill: "#001755",
      translate: { x: "705.951", y: "1177.165" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "DAMBOA",
    path: {
      id: "Path_6886",
      d: "M298.988,1276.664l5.888-4.964,4.048,4.964,7.728,10.313,4.416,3.437,7.361,4.964,9.568,5.349,18.4,18.329h5.888l10.3-6.492,17.3,11.456,1.1,3.44,4.048,2.29,2.208-2.29,1.472-6.111,8.833-8.785h5.52l11.409-6.111,6.256-6.873,5.888-16.8,5.888-1.147,4.048,1.147,10.673,9.547,8.832,9.166,13.985,8.02,5.888,1.147v14.13l2.208,2.674v17.948l1.472,6.111-8.833,17.567,5.152,10.313-1.472,22.15-24.289,1.146-5.52-3.821-27.969,2.674-5.888-8.02-3.68-15.277-12.881-9.166h-9.2l-20.609,3.821-20.609,12.218-16.929,16.424h-9.937l-10.3-16.424-41.218-32.459-2.576-6.111L266.6,1335.1v-16.8l16.561-12.984v-8.4l5.152-9.928Z",
      translate: { x: "329.986", y: "-258.432" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "DAMBOA-2",
      name: "DAMBOA",
      fill: "#001755",
      translate: { x: "732.426", y: "1080.16" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M",
      name: "5M",
      fill: "#001755",
      translate: { x: "740.688", y: "1105.613" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "GWOZA",
    path: {
      id: "Path_6887",
      d: "M1037.078,1317.565V1308.4l-30.179,3.818-7.359-3.818-8.831-11.078-34.595-19.095-5.887,1.912-5.889-1.912-3.312-4.2-1.1.762-2.208,3.437v8.4l-3.312,6.111-2.208,9.928-6.992,9.547v14.511l1.84,2.293v17.567l1.84,7.254-8.1,16.8,4.416,9.928,14.353,5.349h11.041l8.463-3.436,7.731-5.349H988.5l1.84-4.964,7.359-1.528,2.944-2.29,6.255-1.912,3.683-6.107,2.576-8.023h2.944l7.359-9.163v-4.2l4.048-3.818,3.315-10.694Z",
      translate: { x: "-109.271", y: "-259.997" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "GWOZA-2",
      name: "GWOZA",
      fill: "#001755",
      translate: { x: "843.936", y: "1068.316" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-3",
      name: "3M",
      fill: "#001755",
      translate: { x: "857.145", y: "1087.16" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "BAMA",
    path: {
      id: "Path_6888",
      d: "M1184.436,1087.665l5.52-6.492-11.775-1.528-16.929-6.492-21.345-10.694-26.129,9.932-5.891-2.293-9.567,6.111-4.047,6.111v3.818l-4.419,4.2h-5.519l-9.935-4.2h-5.155l-5.151-1.528h-.736l-6.255,1.528-11.778-3.818-2.576-2.674-6.991,8.785v7.638l-9.938,9.928v5.349l-.736,3.818,3.315,6.111-.736,4.2-3.315,1.909-19.873-6.111h-3.68l-7.359,6.111-6.993,1.143-4.048,3.821,2.944,3.437,6.257,2.293,5.519-2.293,28.7,15.658,6.627,4.583,8.1,10.313,7.359,3.437,30.547-3.437,15.458,3.437,13.983,8.4,12.882-6.111,8.1-11.837h3.312l18.769-8.785,13.618-8.785,1.84-6.873h5.887l12.146-3.437,12.146-8.02,4.783-15.277-4.783-9.928Z",
      translate: { x: "-150.641", y: "-117.936" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "BAMA-2",
      name: "BAMA",
      fill: "#001755",
      translate: { x: "926.242", y: "1004.824" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-4",
      name: "3M",
      fill: "#001755",
      translate: { x: "937.482", y: "1018.824" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "DIKWA",
    path: {
      id: "Path_6889",
      d: "M1208.438,872.937V869.5l-6.255,12.984-11.778-2.673-2.208,4.583-8.1,6.874V901.58l2.944,9.929-2.944,4.584-2.576,22.15-2.944,6.873h-4.051l-3.312,4.964,2.576,2.674,11.41,3.818,6.991-1.528,4.783,1.528h5.523l9.935,4.2h5.151l4.787-4.2v-3.818l4.047-6.111,9.567-6.111,5.891,2.293,14.718-6.111,12.146-3.821,1.472-29.405v-12.6l-20.977,8.4c-1.965,2.292-6.183,6.874-7.359,6.874a29.432,29.432,0,0,0-5.891,1.528l-8.463-11.075-3.68-11.075Z",
      translate: { x: "-274.744", y: "11.63" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "DIKWA-2",
      name: "DIKWA",
      fill: "#001755",
      translate: { x: "923.477", y: "933.633" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M",
      name: "2M",
      fill: "#001755",
      translate: { x: "932.238", y: "950.052" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "KONDUGA",
    path: {
      id: "Path_6890",
      d: "M634.105,867.423l-4.416-9.548L615.7,864.749l-6.256,12.221-1.472,6.492-5.888,8.4-1.84,5.729,4.048,3.819v10.312l-2.208,3.437-22.449,8.4H561.605l-13.617,3.055-2.208-.762,3.68,6.111,2.208,6.111v6.107l-5.152,3.44-4.784,1.909-2.944,3.436,1.1,6.111-2.208,2.674,2.208,2.671,1.1,16.8,1.84,2.674v4.964l4.784,9.547v18.713l5.888,12.222,1.1,5.726,17.3,11.075,1.1,3.44,4.416,2.29,2.208-2.29a28.484,28.484,0,0,0,1.1-5.349c0-1.222,5.643-6.62,8.464-9.166h5.888l11.777-7.254,5.888-6.111,2.944-8.785,2.576-8.02,6.256-1.146,3.68,1.146,9.569,8.02,9.568,10.694,14.353,8.02,6.256,1.147,6.992-9.166,1.84-9.547c1.35-1.909,4.048-6.416,4.048-9.166V998.8l1.84-3.055,5.152-4.583,6.625-1.143,7.359-6.111h3.68l19.873,6.111,3.312-1.912,1.107-4.2-3.315-5.73,1.1-4.583v-4.583l9.57-10.309v-7.257l6.991-9.166L727.58,929.291H715.07l-9.57,6.492-45.265,1.909-6.992,1.147-1.1-3.055,1.1-2.29,9.937-6.111,2.944-7.257-4.784-10.31-5.152-1.528-5.152-7.257,1.84-4.965,1.1-6.874-2.944-3.819-8.464-6.874-4.048-4.2Z",
      translate: { x: "147.965", y: "19.436" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "KONDUGA-2",
      name: "KONDUGA",
      fill: "#001755",
      translate: { x: "722.492", y: "998.814" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4.5M",
      name: "4.5M",
      fill: "#001755",
      translate: { x: "749.652", y: "1017.304" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "MAIDUGURI",
    path: {
      id: "Path_6891",
      d: "M789.29,1027.022v-9.547l1.1-1.528,2.576-1.146,2.208,1.912,1.84,2.671,1.84,2.674,4.048,3.818,5.52,11.075v4.968h-6.256l-16.193-2.674-.736-4.964Z",
      translate: { x: "-18.262", y: "-85.934" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "MAIDUGURI-2",
      name: "MAIDUGURI",
      fill: "#001755",
      translate: { x: "705.951", y: "952.841" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-2",
      name: "2M",
      fill: "#001755",
      translate: { x: "0.751,", y: "0.66," },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "JERE",
    path: {
      id: "Path_6892",
      d: "M770.871,886.437,779.7,878.8l10.672,13.749,9.2,6.492L802.152,904l-1.1,6.874-2.944,4.2,6.256,7.257,4.784,1.528,4.784,10.691-2.208,6.5-2.576,1.909H798.1l-2.576,4.964,2.576,5.345,5.152,5.73,5.888,5.73-2.944,4.964H795.528l-5.152-8.4h-5.52l-16.929,8.4-9.569-2.674v-5.73c0-1.222,3.68-4.327,5.52-5.726l4.048-7.639,16.929,2.674h5.52v-5.349l-5.52-10.694c-1.717-1.4-5.594-5.115-7.36-8.782s-5.152-1.528-6.625,0v-9.166l-2.944-7.256,2.944-7.256Z",
      translate: { x: "-0.209", y: "5.386" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "JERE-2",
      name: "JERE",
      fill: "#001755",
      translate: { x: "769.951", y: "913.29" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "770.631", y: "923.768" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Kaga",
    path: {
      id: "Path_6893",
      d: "M391.508,1037.716l-3.68-6.492-13.617-14.13L314.96,1014.8v16.424l1.472,3.818L305.023,1059.1l-2.576,12.6H314.96l10.673,10.309,3.312,16.039,3.312,5.73,8.832,11.84,11.04,7.254,9.937,4.964,18.033,18.713h6.256l9.936-5.345-.736-7.257-2.208-4.583-3.312-6.492-.736-19.095L385.251,1095v-5.726l-1.84-2.293-1.472-17.186-2.208-2.671,2.208-2.674-.736-6.492,2.208-2.674,5.52-1.909,4.784-3.818v-6.111Z",
      translate: { x: "305.918", y: "-85.934" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Kaga-2",
      name: "Kaga",
      fill: "#001755",
      translate: { x: "641.4", y: "971.313" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-2",
      name: "4M",
      fill: "#001755",
      translate: { x: "648.697", y: "991.744" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "mafa",
    path: {
      id: "Path_6894",
      d: "M996.4,823.513l2.208-4.583-6.623-8.4-4.784-.764-12.146-5.728L962.543,818.93l-13.25-1.146-6.623-3.055-5.891-6.874-6.623-3.819-8.831-15.276-2.209,1.909-2.576-.763-7.36,4.2-10.673.764-10.3-16.04h-5.888v9.929l1.1,1.909v3.437l.736,1.91v3.437l.736,3.055v5.347l1.472,2.292v4.583l1.84,2.291.736,5.729-4.048,6.11-3.312,3.055,3.312,4.965-.736,6.492-2.576,4.583,2.576,4.2,2.208,3.055,5.152,1.143,5.52,11.075-2.576,6.5-10.3,6.873-1.472,1.909,1.472,3.437,3.68-1.143,5.52-.765,43.057-1.528,9.2-6.111H958.86l16.194,12.987,3.68-5.349h4.419l2.208-6.111,2.944-22.912,2.944-4.584L988.3,841.08V830.769Z",
      translate: { x: "-82.947", y: "72.512" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "mafa-2",
      name: "mafa",
      fill: "#001755",
      translate: { x: "840.936", y: "906.29" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-5",
      name: "3M",
      fill: "#001755",
      translate: { x: "840.219", y: "921.377" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "KALA/BALGE",
    path: {
      id: "Path_6895",
      d: "M1482.823,763.718l-11.042,1.528,1.84,8.784V791.6l-2.208,4.583,2.208,3.437-2.208,2.673-1.1,11.075-2.208,5.729-3.312,1.528v3.819l-1.84,37.81,20.609,10.31,16.929,6.492,11.407,1.912,5.519-4.583-1.472-17.57,7.73-9.163-1.1-2.293,3.312-6.492-5.519-9.93a26.06,26.06,0,0,1,0-5.728c.292-1.528,3.312-3.692,4.783-4.583l-3.312-4.965,3.312-4.965c1.594-2.419,4.856-7.485,5.152-8.4s-2.822-4.456-4.416-6.11l-8.834,1.146-1.84-6.874-7.727-1.146v-4.583l-1.472-6.11-5.519-1.528v-7.256l-8.466-6.11Z",
      translate: { x: "-473.316", y: "82.659" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "KALADALGE-2",
      name: "KALADALGE",
      fill: "#001755",
      translate: { x: "1072.391", y: "914.377" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-3",
      name: "4M",
      fill: "#001755",
      translate: { x: "1007.719", y: "927.29" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Ngala",
    path: {
      id: "Path_6896",
      d: "M1326.539,651.26l-1.1-6.11L1317.7,658.9l-3.68,8.784-6.991,16.04-4.783,12.6L1292.68,720v2.291l11.042,12.221,3.312,11.075,8.463,10.693h6.259l8.1-8.02,8.463-2.673,15.458-6.493,2.208-4.583,1.472-12.221,2.208-2.291-2.208-3.437,2.208-4.583V694.8l-2.208-9.166-3.68-3.819-4.783,1.91h-9.2l-9.935-3.437-4.415-6.874,4.415-5.729h-4.415v-5.347l4.415-6.874Z",
      translate: { x: "-358.986", y: "162.272" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Ngala-2",
      name: "Ngala",
      fill: "#001755",
      translate: { x: "955.482", y: "863.226" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-4",
      name: "4M",
      fill: "#001755",
      translate: { x: "960.982", y: "879.019" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "MARTE",
    path: {
      id: "Path_6897",
      d: "M1131.015,588.952l-4.051-27.5-24.657,1.528-5.519,2.292h-20.609l-3.68-3.819-2.576,3.819h-4.416l-10.674,8.4-5.151,7.256,2.576,4.2-2.576,1.528,1.472,7.638-1.472,4.2v1.528l-2.208,8.784-6.627,1.909-10.671,8.784h-5.519l-3.683-3.055-6.623,3.055h-5.887l-1.472,3.055v7.256l-3.312,2.673V642.8l4.784,8.02,3.312,7.256,7.359,4.2,5.523,6.874,6.991,2.673,13.25,1.146,12.143-14.894,11.042,5.347,5.887,1.146,6.623,8.4,11.778,2.673,7.359-15.658,9.938-23.3,2.576-6.874,2.576-5.347,2.208-4.965,5.888-16.422Z",
      translate: { x: "-164.934", y: "218.471" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-3",
      name: "2M",
      fill: "#001755",
      translate: { x: "905.477", y: "848.021" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "MARTE-2",
      name: "MARTE",
      fill: "#001755",
      translate: { x: "905.873", y: "829.058" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "MANGUNO",
    path: {
      id: "Path_6898",
      d: "M1044.98,519.307l-3.315-20.623-28.336,6.492-32.387,6.874-9.2,5.347H959.964L946.35,520.07l-5.887,6.11-5.155,9.929L925,542.985l-14.354,1.146-4.416,1.146-16.929,16.8-7.728,11.075v16.04l1.1,1.528h5.52l4.416,7.256,6.256,9.166L909.547,606l6.992-3.819,2.576.764,2.209-1.909V590.722l3.68-3.437v-7.256l1.1-1.909h6.623l5.887-3.819,3.683,2.673h5.887l1.1-1.146,9.567-7.638,6.995-1.909,1.84-7.256v-2.673l1.1-3.819-1.1-7.256,2.208-2.291-2.208-3.819,5.519-8.02,10.306-7.638h4.047l2.576-3.437,4.047,3.437H1014.8l4.048-1.909,15.826-1.528Z",
      translate: { x: "-82.947", y: "260.619" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "MANGUNO-2",
      name: "MANGUNO",
      fill: "#001755",
      translate: { x: "809.719", y: "830.135" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-6",
      name: "3M",
      fill: "#001755",
      translate: { x: "857.145", y: "815.048" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "KUKAWA",
    path: {
      id: "Path_6899",
      d: "M1032.389,166.758l-12.879-16.8-15.09,3.055-28.7,11.075H970.2l-5.155,2.673h-4.784l-2.944,1.909H937.81l-5.52,8.4-4.784,11.075,3.312,2.673,1.472,6.874-2.576,2.673v32.08l3.312,3.819.736,3.819,18.77,11.457,8.1,14.894,6.991,20.241,3.683,5.347,6.255-6.493,12.879-2.291h11.41l4.416-1.909,4.416-2.673,14.354-3.437,47.474-10.693-5.151-26.733-2.208-18.331-2.208-7.256-14.722-21Z",
      translate: { x: "-113.785", y: "494.778" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "KUKAWA-2",
      name: "KUKAWA",
      fill: "#001755",
      translate: { x: "857.145", y: "710.857" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-5",
      name: "4M",
      fill: "#001755",
      translate: { x: "870.719", y: "727.211" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "NGANZAI",
    path: {
      id: "Path_6900",
      d: "M718.171,466.085l-2.576-3.437-7.729,1.909-4.416,5.347-3.312,6.493v3.437l2.208,3.437L698.3,498.165,692.778,510l-1.472,5.347-1.84,9.547-11.777,27.5-1.1,3.437,9.569,10.311,4.784,2.292,7.729,5.347c3.925,2.928,11.629,8.784,11.041,8.784s5.4,2.8,8.464,4.2l4.416,5.347,3.312,6.874,9.2,14.13,9.2,6.493,3.312-3.055,3.68-6.492-.736-5.729-1.472-1.91V597.46l-1.472-1.528V590.2l-.736-2.291v-3.819l-.736-1.528v-4.2l-1.1-1.146V566.907l-1.1-1.909v-16.04l7.729-11.457,9.937-9.166,7.36-7.638,4.048-.764-4.784-5.347-3.68-1.909V510H750.189l-12.144-11.075v-6.492l-.736-1.909v-2.674l-3.68-6.11-3.312,1.527-4.048-5.728V471.05l-2.944-3.437Z",
      translate: { x: "54.697", y: "284.816" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "NGANZAL-2",
      name: "NGANZAL",
      fill: "#001755",
      translate: { x: "750.652", y: "815.531" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-6",
      name: "4M",
      fill: "#001755",
      translate: { x: "762.959", y: "834.021" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "guzamala",
    path: {
      id: "Path_6901",
      d: "M757.438,270.033c0-.611-2.453-2.037-3.68-2.673l-5.152,13.367-14.721,10.311H708.123l-8.464-4.583-6.256-1.146h-7.728l-14.721,8.4-2.208,4.583,3.68,7.638L675,309.369l3.68,4.2,4.048,9.548v10.312l-2.576,5.347,2.576,4.2,2.944,2.673h9.2l3.312-6.874,4.784-5.347,6.992-1.91,2.576,3.055,5.52,1.909,3.312,3.819V346.8l3.312,5.347,3.68-1.528,3.312,6.11v2.292l1.1,1.909v7.256l12.513,11.075h13.985v2.291l3.312,1.91,5.152,5.347h5.152l8.1-.764,10.671-6.493,5.891-10.311-3.683-5.347-4.415-12.6-2.576-7.638-8.1-15.276-19.138-11.457V315.1l-3.68-3.437V278.817l2.208-1.528C757.928,275.125,757.438,270.644,757.438,270.033Z",
      translate: { x: "59.963", y: "415.945" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "GUZAMDO-2",
      name: "GUZAMDO",
      fill: "#001755",
      translate: { x: "754.041", y: "733.325" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4.5M-2",
      name: "4.5M",
      fill: "#001755",
      translate: { x: "784.631", y: "751.814" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "GUBIO",
    path: {
      id: "Path_6902",
      d: "M521.609,369.138l-4.048-8.784-32.386,46.21h-4.048V410l-4.784-2.292-2.208,6.492-4.048,2.292-8.832-1.146-8.832,9.166v11.457l-7.728,9.166-1.1,5.728,8.1,13.367,4.784,2.292h13.617l10.673,11.457,19.5,4.2,19.505,4.965,7.728-19.1,5.52-12.221,1.84-9.548,1.472-4.965,5.888-12.6L545.9,414.2l-2.208-3.055V407.71h-9.569c-.883,0-2.331-1.273-2.944-1.909l-2.208-4.2,2.208-6.11V385.56l-3.312-9.548Z",
      translate: { x: "211.146", y: "353.503" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "GUBIO-2",
      name: "GUBIO",
      fill: "#001755",
      translate: { x: "695.283", y: "786.662" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-7",
      name: "3M",
      fill: "#001755",
      translate: { x: "704.283", y: "804.422" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "MOBBAR",
    path: {
      id: "Path_6903",
      d: "M475.379,166.493l8.464-8.4,8.464,8.4,1.472,2.292,8.464,4.583,10.673,9.166,4.784,6.11,19.5,24.824v4.583h-7.36l-9.2-4.583-6.624-1.909h-6.992l-3.312,1.909-11.408,6.493L489,225.306l-31.282,45.828H453.3v3.055l-4.784-1.909-1.84,5.728-3.68,2.673-9.568-1.146-8.1,8.784h-3.312l-5.52-4.583-6.624-9.548-3.312-5.347-4.416-8.02v-8.4l4.416-4.583,3.312-9.548V227.6l-2.208-2.291v-7.256l14.353,4.2,1.472-4.2,3.312-1.909v-7.256l5.152-4.583H436l2.208-6.874-4.416-4.583,4.416,1.528,1.472-5.729v-6.11l4.416-1.527h7.361l1.84-4.965,5.888,4.965,1.472-7.638,5.888,2.673-1.84-2.673,5.52-1.909v-4.965Z",
      translate: { x: "238.977", y: "489.315" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "MOBBAR-2",
      name: "MOBBAR",
      fill: "#001755",
      translate: { x: "683.15", y: "698.433" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-2",
      name: "5M",
      fill: "#001755",
      translate: { x: "689.039", y: "712.756" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ABADAM",
    path: {
      id: "Path_6904",
      d: "M658.166,61.486,649.7,51.939V46.974l3.312-2.291,5.52-1.91-.368-5.347h2.944l.736-4.2,7.361,2.291,2.944-2.291h3.68l2.944-3.819h6.256l3.312-3.055,2.576-1.146,1.84-3.819,3.68,1.146-1.1,3.819,2.576,3.055,3.68-4.2,3.68,1.146L708.585,21l2.576,8.4,4.048-4.583,5.152,4.583V24.824L725.882,21V16.04h2.944v-8.4L735.45,0h67.714L829.3,40.863l3.68,8.4-15.09,2.673-14.722,6.11-13.983,5.728h-5.887l-4.419,2.292h-5.151l-2.576,2.291H751.643L745.755,76,740.6,87.837,735.45,101.2l-15.089,10.311H703.433v-4.583L690.92,91.275,678.775,76l-9.568-7.638Z",
      translate: { x: "72.75", y: "595.467" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ABADAM-2",
      name: "ABADAM",
      fill: "#001755",
      translate: { x: "799.238", y: "631.566" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-7",
      name: "4M",
      fill: "#001755",
      translate: { x: "803.041", y: "647.706" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "magumeri",
    path: {
      id: "Path_6905",
      dataName: "MAGUM ORI",
      d: "M365.558,648.437l9.2-13.749,7.361,12.221,5.52,3.819h13.984l11.04,11.457,37.17,8.784,11.409,11.457h1.472l22.449,16.422,7.728,3.437,4.416,5.728-2.944,1.528-5.888,2.673-5.152,3.055-6.256,11.839L475.6,733.6l-5.888,8.02-1.84,6.11,4.048,3.819v10.312l-2.208,3.436-22.449,8.4H429.226c-3.925,1.018-12.292,3.055-14.352,3.055s-7.483-6.62-9.937-9.928l-4.784-4.964H391.32l-24.657-1.147-26.13-1.147V745.058l1.84-1.527v-6.493l2.944-9.929,2.576-11.839,1.1-7.256,2.576-4.2V683.953l-3.68-1.528-1.1-3.819-3.312-9.929v-4.2l12.144-8.784Z",
      translate: { x: "280.344", y: "169.297" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "MAGUM_ORI-2",
      name: "MAGUM ORI",
      fill: "#001755",
      translate: { x: "649.65", y: "903.718" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-3",
      name: "5M",
      fill: "#001755",
      translate: { x: "674.15", y: "878.13" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "_KUSAR",
    path: {
      id: "Path_6906",
      dataName: " KUSAR",
      d: "M89.724,1850.736l-11.04,1.146,3.68-6.111-3.68-31.316-5.152-6.873-1.84-14.13,10.673-12.6,15.825,11.456h12.881l5.152,9.166,1.472,6.111V1819.8l-5.888,12.987L98.188,1843.1Z",
      translate: { x: "460.861", y: "-600.308" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_KUSAR-2",
      name: " KUSAR",
      fill: "#001755",
      translate: { x: "535.555", y: "1204.121" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-8",
      name: "3M",
      fill: "#001755",
      translate: { x: "543.654", y: "1221.902" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "kwaya kusar",
    path: {
      id: "Path_6907",
      d: "M40.761,1773.356l-6.242-5.779v-14.2l8.706-11.4-2.464-5.45,4.764-6.935-2.3-8.092-8.706,8.092-9.363,6.935-4.764,15.195v29.233L13,1790.532l19.055,23.122,8.706-1.981,2.464-5.286Z",
      translate: { x: "500.271", y: "-560.457" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "kwaya-2",
      name: "kwaya",
      fill: "#001755",
      translate: { x: "483.271", y: "1204.121" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-8",
      name: "4M",
      fill: "#001755",
      translate: { x: "520.041", y: "1228.902" },
      tspan: { X: "0", y: "0" },
    },
  },
];
