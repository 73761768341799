export const data = [
  {
    state: "Arewa Dandi",
    path: {
      id: "Path_7345",
      dataName: "Arewa Dandi",
      d: "M136.784,26.388V3.308l3.077,2.308,8.078,1.923L151.4,3.308,159.1,1l8.463,6.539,8.463,2.308,6.155,5,2.693,8.078h11.925l7.693,3.462h13.079l5.385,5-5.385,4.231-17.31,18.464v3.847l-5,8.847-8.078,11.54-8.847,6.155L174.1,91.013,161.4,94.86,159.1,98.707,151.4,113.324l-8.463,6.539c-1.026,1.539-3.077,5-3.077,6.539s-5.129,6.539-7.693,8.847l-9.232,4.616-9.232,6.924-2.693,4.616-8.078,11.156-8.078,7.309H80.237v6.539l4.231,5.385-1.923,6.155-7.693,3.077L66,197.952l-4.616,10.771-1.923,10L36,229.111v-6.155l2.308-9.232,2.308-7.309,1.539-6.155v-9.232L40.616,174.1l-2.308-27.312,56.547-41.929,8.078-10,20-28.081,9.232-17.31,2.308-11.156Z",
      translate: { x: "-8.073" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Arewa_Dandi-2",
      name: "Arewa Dandi",
      fill: "#001755",
      translate: { x: "16.045", y: "76.474" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M",
      name: "5M",
      fill: "#001755",
      translate: { x: "98.551", y: "113.172" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Augie",
    path: {
      id: "Path_7346",
      d: "M234.347,90.2,230.5,85.97l14.618-21.542v-5l21.926-20.772,10.386,5,7.309-1.539,2.308-6.924,6.539-7.693,4.616,1.923v5.77l-1.539,3.462v5l-3.077,9.617-8.847,8.847,2.308,2.308,4.616,6.539,9.617,12.31,11.156,5,4.231,4.231c.769,2.949,2.77,8.847,4.616,8.847s.769,3.847,0,5.77l-6.924,9.232-1.923-3.077H291.663l-6.924-2.693-7.309-3.462h-7.309l-6.155-5.77-3.077-7.309H238.963Z",
      translate: { x: "-52.935", y: "-6.112" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Augie-2",
      name: "Augie",
      fill: "#001755",
      translate: { x: "258.848", y: "69.622" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-2",
      name: "5M",
      fill: "#001755",
      translate: { x: "203.722", y: "71.16" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Argungu",
    path: {
      id: "Path_7347",
      d: "M184.078,136.082,176,143.775l1.923,1.154L181,146.083l3.077,2.308,1.154,2.693v3.462l2.308,5.77,5,4.616h32.7l3.077-1.539,11.54-3.077,5-3.077L251.4,155.7l11.54-6.155,11.156-4.616,10.386-3.077,13.464-3.077,3.847-5-1.923-3.077H278.708l-13.848-6.155h-7.309l-6.155-5.77-3.077-7.309H226.392L217.545,103l-6.155,3.847-4.616,7.693L194.464,118Z",
      translate: { x: "-40.365", y: "-23.526" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Argungu-2",
      name: "Argungu",
      fill: "#001755",
      translate: { x: "159.953", y: "106.165" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M",
      name: "4M",
      fill: "#001755",
      translate: { x: "173.645", y: "129.247" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Gwandu",
    path: {
      id: "Path_7348",
      d: "M320.74,157.5l-1.539-10-1.539,1.539-16.926,3.847-13.848,5.385L271.5,165.964l-6.539,1.154-3.462,2.308,4.231,2.308,4.616,1.154,3.847,8.463,4.231,6.924,5.77,6.155-5.77,3.077-9.617,1.923-6.155,6.539V217.9l7.693,9.617,3.847-2.308,2.693-3.847,8.463-6.155,1.539-12.694,2.308-6.924h6.924l10,6.924,3.077,6.924.769,10,6.924,1.923c1.283-.641,3.847-2.231,3.847-3.462v-7.309l2.308-11.156,12.694-9.232,4.616-8.847-4.616-8.463-10.771-5.77Z",
      translate: { x: "-60.085", y: "-33.791" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Gwandu-2",
      name: "Gwandu",
      fill: "#001755",
      translate: { x: "228.367", y: "157.021" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-2",
      name: "4M",
      fill: "#001755",
      translate: { x: "225.649", y: "136.17" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "birnin_kebbi",
    path: {
      id: "Path_7349",
      dataName: "Benin kebbi",
      d: "M134.462,195.237l-2.693-4.616L131,189.467l1.154-1.539L136,182.542l8.078-6.155,8.078-4.231,3.462-2.693,4.616-4.231,1.539-3.077.769-2.308L165.236,156l4.616,1.923,3.462,1.923,1.539,3.462v3.462l2.693,6.155,4.616,4.231h32.313l3.077-1.539,5.77-1.539,8.078-2.693,3.077,1.923,5.77,1.539,3.077,7.693,5.385,7.693,5.77,6.539-5.77,2.693-10,1.923-7.309,8.078-5.385-6.155h-3.462l-9.617,2.693-5,5.385-4.616,6.539-3.077,6.924-13.848,4.231-6.924-4.231-2.693-6.924-8.847-6.539-18.464-1.923-11.54-6.155Z",
      translate: { x: "-29.985", y: "-35.751" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Benin_kebbi-2",
      name: "Benin kebbi",
      fill: "#001755",
      translate: { x: "132.645", y: "157.94" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_8M",
      name: "8M",
      fill: "#001755",
      translate: { x: "144.755", y: "176.484" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Kalgo ",
    path: {
      id: "Path_7350",
      d: "M182.475,251.815l-4.616-11.925-6.924-5-2.693-6.924-8.847-6.539L140.93,219.5l-11.54-6.155L122.85,199.5l-5.77,7.693-8.847,8.463H94v6.924l3.462,5-1.923,6.155,1.923.769,13.464,1.923,6.155,3.462,6.539,10.386,5.77,1.539,8.078,4.231,4.231,8.847v9.232l5,6.924,15.772,3.462,9.232-3.462,18.464-6.924,2.693-3.462-2.693-7.309-4.231-3.462Z",
      translate: { x: "-21.451", y: "-45.785" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Kalgo-2",
      name: "Kalgo",
      fill: "#001755",
      translate: { x: "87.551", y: "183.484" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M",
      name: "6M",
      fill: "#001755",
      translate: { x: "119.943", y: "198.872" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Aleiro",
    path: {
      id: "Path_7351",
      d: "M225.385,250.582,220,239.426l1.154-1.154,2.693-6.155,5-7.309,4.616-4.616,10-2.693h3.077l5,6.155,1.923-1.539v11.54l6.924,8.463v12.694l1.539,2.308-1.539,3.847Z",
      translate: { x: "-50.513", y: "-49.937" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Aleiro-2",
      name: "Aleiro",
      fill: "#001755",
      translate: { x: "214.493", y: "206.717" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_7M",
      name: "7M",
      fill: "#001755",
      translate: { x: "179.873", y: "198.872" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Jega",
    path: {
      id: "Path_7352",
      d: "M259.547,276.658l-3.077-9.617-35.005-10.386-5-11.155L203,250.116l7.693,20,4.616,3.847,2.308,6.924-1.154,2.308,8.847,11.156-1.923,2.693-5.77,6.155,3.847,8.463L228,321.28H235.7l5.385,3.077,12.694,6.539h5.77l4.231-9.617v-9.617l-1.539-12.31v-12.31Z",
      translate: { x: "-46.592", y: "-56.395" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Jega-2",
      name: "Jega",
      fill: "#001755",
      translate: { x: "179.104", y: "218.875" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M-2",
      name: "6M",
      fill: "#001755",
      translate: { x: "182.645", y: "248.422" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Maiyama",
    path: {
      id: "Path_7353",
      d: "M212.85,294,184,305.925v6.924l5.385,11.54,1.539,24.619-1.539,24.619,7.309,8.463,3.077-6.924h2.308l5-3.462,5.77,3.462,1.923-1.539h4.231l6.924,5.77,28.466-23.08V342.084H249.01l-17.31-8.847h-8.078l-10.771-18.08,8.078-9.232Z",
      translate: { x: "-42.21", y: "-67.582" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Maiyama-2",
      name: "Maiyama",
      fill: "#001755",
      translate: { x: "157.373", y: "278.118" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M-3",
      name: "6M",
      fill: "#001755",
      translate: { x: "167.868", y: "292.884" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Bunza",
    path: {
      id: "Path_7354",
      d: "M68.539,264.5,67,274.889v3.847l3.077,3.847,5.77,5,5.77,1.923,6.539,1.923,7.693,1.154,4.231,3.847v4.616l2.308,5.385,3.847,1.154,33.082-18.08-3.847-4.616v-10l-4.616-8.463-6.155-3.462-7.309-2.308-6.539-10.386-6.539-3.462-8.463-1.154L89.311,244.5l-7.693,3.077-8.463,5.77Z",
      translate: { x: "-15.223", y: "-56.164" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Bunza-2",
      name: "Bunza",
      fill: "#001755",
      translate: { x: "67.548", y: "214.258" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-3",
      name: "5M",
      fill: "#001755",
      translate: { x: "80.548", y: "232.875" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Dandi ",
    path: {
      id: "Path_7355",
      d: "M40.117,306.2l-5-12.694,23.465-10v4.231l8.847,8.847,10,3.077,9.617,1.539,4.231,3.847v4.616l3.077,5.77,3.847,1.154,3.462,12.694,4.616,14.618,3.462,10,3.077,8.463-1.154,7.693v10.386l-5.77,7.309-12.694,5-15.387,2.308H65.89l-11.54-2.308-10.771-5-6.539-5.385-3.847-6.155L30.5,372.359l2.693-3.462,8.847-8.847V353.9l-1.923-3.462-6.924-5.77V329.276l10.386-11.155L42.04,311.2Z",
      translate: { x: "-6.804", y: "-65.16" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Dandi-2",
      name: "Dandi",
      fill: "#001755",
      translate: { x: "42.929", y: "267.344" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M-4",
      name: "6M",
      fill: "#001755",
      translate: { x: "54.929", y: "285.117" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Suru",
    path: {
      id: "Path_7356",
      d: "M142.778,302.5l-32.7,18.849,1.154,3.847L124.7,366.74l-1.154,7.693V385.2l-5.77,6.924-13.848,5-14.618,1.923H82l8.847,5L104.7,414.824l6.924-2.308,3.077-8.463,1.923-5,5.77-2.308,2.308,2.308,5.385,2.693,4.231-2.693,6.539-6.155,5-6.539h6.924l2.693,5,3.462-1.539,4.231-5,6.539-4.616-3.462-5,.769-23.85-.769-25.773-5-11.156v-6.539l-16.926-3.462Z",
      translate: { x: "-18.683", y: "-69.542" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Suru-2",
      name: "Suru",
      fill: "#001755",
      translate: { x: "112.595", y: "274.344" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M-5",
      name: "6M",
      fill: "#001755",
      translate: { x: "115.785", y: "292.42" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Bagudo",
    path: {
      id: "Path_7357",
      d: "M16.772,418.234l6.155-18.08L23.7,399l6.539,9.617,6.155,5.77,10,4.616,3.847.769,8.078,1.539h4.616L86.4,437.083l6.539-2.693,4.616-13.079L104.092,419l1.923,2.308L112.17,424l7.309-6.155,8.078-9.617H134.1l3.077,5.385,5.385,4.231L144.1,424l6.924,8.847h11.925l6.539,6.539,6.155,8.078-2.308,7.309L162.947,469.01l-11.156,9.232-1.923,3.462,13.079,6.155h9.232l6.924,5-8.847,9.232-3.847,8.463-6.155,3.077c-2.308,3.077-7.232,9.078-8.463,8.463s-1.795,6.668-1.923,10.386l-5-3.077-45.391,3.077L54.854,510.555H41.391L1,478.243,4.077,461.7,11,432.467Z",
      translate: { x: "0", y: "-91.8" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Bagudo-2",
      name: "Bagudo",
      fill: "#001755",
      translate: { x: "54.474", y: "362.085" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-4",
      name: "5M",
      fill: "#001755",
      translate: { x: "72.052", y: "387.209" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Koko/Besse",
    path: {
      id: "Path_7358",
      dataName: "Koko /Besse",
      d: "M183.616,416.926,179,412.31l2.308-1.154,4.616-5.385,6.539-4.231L195.541,405l3.077-6.924h2.693L205.927,395l5.77,3.077,2.308-1.154h3.462l7.309,5.77,3.077,5.77,15.772,11.925,4.616,11.156,8.078-2.308,1.539,11.156v9.232c-2.052,2.308-6.309,7.155-6.924,8.078s-5.9,5-8.463,6.924l-4.231,9.232,1.154,6.924,3.077,9.617,1.154,10.386h-2.693l-10-5-11.156-3.847-6.924-5H204l-13.079-6.155,2.308-3.847L204,468.472l10-14.233,2.693-7.309L204,432.313l-11.925-.769-6.539-8.463Z",
      translate: { x: "-41.057", y: "-90.878" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Koko_Besse-2",
      name: "Koko\n          /Besse",
      fill: "#001755",
      translate: { x: "174.145", y: "342.281" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-5",
      name: "5M",
      fill: "#001755",
      translate: { x: "173.645", y: "385.978" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Yauri",
    path: {
      id: "Path_7359",
      d: "M215.772,529.847l7.693-8.078.769-.769,4.231,1.539,6.924,2.693,9.232,4.616h2.308L265.4,542.926l10.771,11.54,10,6.155,10.386,1.923,12.31,6.539,9.232,1.923,5.385,4.231-5.385,2.693H311.94l-.769,2.693-.769,2.308-3.462,7.693-6.155,5.77-2.693,5,1.154,10.386-1.154,8.463-4.231,3.462-3.847.769v-4.231l1.154-5.77.769-6.539,1.923-9.232,4.231-9.232-1.539-6.539-5.385-3.077-3.462-7.693h-6.924l-1.154,3.077-7.309,6.155h-10l-5-1.539h-8.463l-5.77,3.077-13.848,1.154-16.541-4.231-6.924-1.154V566.776h-5.385L195,560.621l1.539-10.386,2.693-1.539,6.539-7.309,5.385-2.308Z",
      translate: { x: "-44.747", y: "-119.94" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Yauri-2",
      name: "Yauri",
      fill: "#001755",
      translate: { x: "174.145", y: "426.446" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M-6",
      name: "6M",
      fill: "#001755",
      translate: { x: "182.645", y: "447.07" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Ngaski",
    path: {
      id: "Path_7360",
      d: "M303.045,642.627l2.308-8.848H309.2l4.616-3.462.769-8.847-.769-10.386,2.693-5,5.77-5.77,5.77-12.694,7.693-1.154,6.155-2.693,14.618-9.617L372.286,563l7.693,6.924,3.077,13.848-3.077,6.539-9.617,4.231,1.923,9.232,7.693,3.462,6.924,3.847v7.693L375.748,625.7l-5.385-3.077-2.308,3.077-5.77,4.616-5.77,3.462H344.589l-8.847-3.462H326.51v3.462l2.693,3.847-5,8.847,5,5.77V668.4l6.539,9.232-2.693,23.08-6.539,8.078H313.046l-7.693-1.923v13.848c0,2.154-1.539,2.437-2.308,2.308l-4.616,3.077,2.693,3.847,6.924,9.232-4.616,6.924-8.847-1.539-3.847-3.077h-7.309l-6.155,3.077h-6.155v-3.077l1.923-4.231,1.923-7.309,2.308-6.924v-5l-4.231-6.539-8.463-4.616-3.077-6.155v-23.08l3.077-9.232,3.847-4.231,8.847-4.231,10.386-5,6.924-5.385Z",
      translate: { x: "-60.085", y: "-129.628" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Ngaski-2",
      name: "Ngaski",
      fill: "#001755",
      translate: { x: "211.416", y: "568.157" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-3",
      name: "4M",
      fill: "#001755",
      translate: { x: "211.416", y: "550.462" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "SHANGA",
    path: {
      id: "Path_7361",
      d: "M337.82,555.825l32.313-21.542h-4.616l-5.385-5H348.975l-6.539-2.693-11.156-3.077H325.9l-9.617-5L302.43,507.741V503.51l8.847-7.693,4.616-3.077.385-6.924,1.923-6.155,3.077-8.463-5.385-2.308-1.154-5.77-6.539-3.077-8.847-7.693L285.12,439.654,272.81,438.5l2.693,21.542-6.155,6.539-8.847,7.693-5,10.386,5,18.849,1.154,7.693,18.08,14.233,19.618,15.772,11.925,1.923,10,6.924,10,1.539Z",
      translate: { x: "-58.702", y: "-100.911" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "SHANGA-2",
      name: "SHANGA",
      fill: "#001755",
      translate: { x: "249.348", y: "408.209" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-4",
      name: "4M",
      fill: "#001755",
      translate: { x: "221.417", y: "401.209" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Fakai",
    path: {
      id: "Path_7362",
      d: "M267.347,429.545l-3.847-10,7.309-4.231,5.385-5,3.847-4.616h8.847l1.923-1.923,5-1.923,3.077,1.923,5,1.923,1.923-1.923,3.847-1.923h6.924l5-3.462,2.693-3.462,3.462-4.616,7.693,4.616,3.077-1.539,4.231-3.077,1.154,4.616,6.539-1.539,5.77-3.077L362.746,388l5.385,1.539,3.847,5.385h5.385l1.539,10.771,5.385,13.848,6.924,5.385,13.079,8.463v5.385l-3.462,1.154v11.156l-3.462,2.308v11.54l-6.155-1.539-4.231,1.539v9.617l-2.693,5.77-3.847,3.847c.256,2.18.615,6.77,0,7.693s-2.308.9-3.077.769l-1.539-3.077,3.847-5.385-2.308-3.847h-1.539l-5,3.847-1.539-1.923-2.693-4.616-2.693-3.077-1.154-3.847-3.077-8.847-2.693-1.154-3.462,4.231-3.077-3.077-3.847-1.154-2.693,1.154L325.432,470.7l-7.309,2.308,5-13.464-5-2.308-1.539-6.155-6.924-2.693-23.08-20.772H276.194Z",
      translate: { x: "-60.547", y: "-89.263" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Fakai-2",
      name: "Fakai",
      fill: "#001755",
      translate: { x: "254.115", y: "333.584" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M-7",
      name: "6M",
      fill: "#001755",
      translate: { x: "271.272", y: "349.282" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Zuru ",
    path: {
      id: "Path_7363",
      d: "M443.886,435.083,433.5,428.928h11.54l6.924-2.693,4.616,5.385h10.771l5-5.385,7.693-18.464L478.891,397l2.308.769h5.385l9.617,1.923,3.462,8.078-3.462,8.847-15,20V455.47l-3.077,2.308-6.539,6.155h-6.924l-6.924,1.923-4.616,3.462-1.923,3.462,3.077,4.616v6.924l-3.077,1.539-1.539-5.385-1.539-5.77-1.539-4.616-9.232-2.693V455.47l2.693-2.308V442.776l3.847-1.923Z",
      translate: { x: "-99.758", y: "-91.339" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Zuru-2",
      name: "Zuru",
      fill: "#001755",
      translate: { x: "348.44", y: "350.896" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M-8",
      name: "6M",
      fill: "#001755",
      translate: { x: "344.281", y: "369.361" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Wasagu/Danko",
    path: {
      id: "Path_7364",
      dataName: " Wasagu / Danko",
      d: "M530.505,406.77H514.349v1.923l-3.847,8.847-15,20v19.234l5.77,8.463,10.386,8.463,15.772,7.309,18.849,10.771,13.464,1.154,3.847,2.308,1.539,10-3.847,3.847v5H573.2l1.154,9.232,1.923,1.154,5.77,2.308,5.77-2.308,15.772-1.154,9.617-9.232-1.539-5V492.937l3.462-4.616v-7.309l-5.385-6.155v-2.693h8.078l1.539,1.539h6.155l6.539-1.539V457.547l-3.077-9.617-6.539-8.078-7.309-3.462-7.309-6.155L597.438,416l-6.155-5-7.693-3.077L576.281,401l-5,2.693L568.2,401l-10,5.77H539.737l-3.462-3.077H533.2Z",
      translate: { x: "-114.059", y: "-92.262" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_Wasagu_Danko-2",
      name: " Wasagu / Danko",
      fill: "#001755",
      translate: { x: "407.599", y: "345.047" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M-9",
      name: "6M",
      fill: "#001755",
      translate: { x: "440.099", y: "368.633" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "_Wasagu_Danko-3",
    path: {
      id: "Path_7365",
      dataName: " Wasagu / Danko",
      d: "M418,388.81l-5,3.462v10l5,15,9.232,6.539h13.079l6.924-2.308,4.231,5h10.771l5.385-5,4.231-10.386,3.462-7.693-1.539-11.156-6.155-8.078-5.385-7.693-1.923,4.231H449.159l-1.923,2.308h-3.077l-3.847-5.385h-6.539L438,383.039l-2.693,3.847-9.617,3.847-3.077-1.923Z",
      translate: { x: "-95.03", y: "-86.611" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_Wasagu_Danko-4",
      name: " Wasagu / Danko",
      fill: "#001755",
      translate: { x: "333.742", y: "282.425" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-6",
      name: "5M",
      fill: "#001755",
      translate: { x: "335.281", y: "326.583" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Sakaba",
    path: {
      id: "Path_7366",
      d: "M482.5,482.232l10-9.232,6.155,9.232,10,8.078L514.813,493l11.925,5.77,16.156,9.617,13.848,1.154,3.847,2.693,1.539,9.232L557.9,525.7l.769,5h11.54l1.154,9.232,7.693,3.462-30,15.772h-32.7l-6.539,3.077-4.231-11.156-1.923-6.539,1.923-10V521.469l-9.617-3.462-7.693-9.617V496.85Z",
      translate: { x: "-111.06", y: "-108.869" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Sakaba-2",
      name: "Sakaba",
      fill: "#001755",
      translate: { x: "394.984", y: "408.209" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-7",
      name: "5M",
      fill: "#001755",
      translate: { x: "410.212", y: "426.446" },
      tspan: { X: "0", y: "0" },
    },
  },
];
