import { numFormtter } from "../../helperFunctions/numFormatter";
import "./style.css";

const ZonalCard = ({ data }) => {
  return data.map((item, i) => (
    <div
      className="col-sm-12 col-md-4 col-lg-4 col-xl-4 my-2 py-1"
      style={{}}
      key={i}
    >
      <div
        className="d-flex py-2 rounded-2 px-2 align-items-center justify-content-between "
        style={{
          background: "#F9F9F9",
        }}
      >
        <div className="col-2 d-flex align-items-center justify-content-center z-c">
          <div className="">
            {item.zone.split(" ")[0][0].toUpperCase()}.
            {item.zone.split(" ")[1][0].toUpperCase()}
          </div>
        </div>
        <div className="col-3 d-flex align-items-center justify-content-center">
          <div className="z-text">
            {parseInt(item.volume).toLocaleString("en-us", { currency: "USD" })}{" "}
          </div>
        </div>
      </div>
    </div>
  ));
};

export default ZonalCard;
