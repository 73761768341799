import { useState } from "react";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import { color } from "../../constant/color";
import "./style.css";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const LoaderIcon = ({ text }) => {
  let [loading, setLoading] = useState(true);
  return (
    <div
      className=""
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="d-block">
        <PulseLoader
          color={color.baseColor}
          loading={loading}
          css={override}
          size={12}
        />
      </div>
      <div
        className="d-block text-center"
        style={{
          color: color.baseColor,
          fontWeight: "510",
        }}
      >
        {text}...
      </div>
    </div>
  );
};

export default LoaderIcon;
