import { createSlice } from "@reduxjs/toolkit";
import { getToken } from "../utils/token";

export const optionSlice = createSlice({
  name: "option",
  initialState: {
    threshold: localStorage.getItem("epump_agr_thrs")
      ? JSON.parse( localStorage.getItem("epump_agr_thrs"))
      : true,
    intervention: localStorage.getItem("epump_agr_int")
      ? JSON.parse(localStorage.getItem("epump_agr_int"))
      : true,
  },
  reducers: {
    UPDATE_THRESHOLD: (state, action) => {
      return (state = {
        threshold: action.payload.threshold,
        intervention: state.intervention,
      });
    },
    UPDATE_INTERVENTION: (state, action) => {
      return (state = {
        intervention: action.payload.intervention,
        threshold: state.threshold,
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const { UPDATE_THRESHOLD, UPDATE_INTERVENTION } = optionSlice.actions;

let optionReducer = optionSlice.reducer;
export default optionReducer;
