import SideNav from "../SideNav";
import TopNav from "../TopNav";
import "./style.css";

const Layout = ({ children }) => {
  return (
    <div className="layout-cover d-flex align-items-start">
      <div className="side-nav">
        <SideNav />
      </div>
      <div className="l-content ">{children}</div>
    </div>
  );
};

export default Layout;
