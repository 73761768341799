import StateMap from "../../Maps/StateMap";
import { nigeria } from "../../../helperFunctions/mapdata";
import { useState, useEffect } from "react";

const StatePMS = ({ data }) => {
  return (
    // <div className="d-block">
    <StateMap data={data} />
    // </div>
  );
};

export default StatePMS;
