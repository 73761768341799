export const data = [
  {
    state: "Baruten",
    path: {
      id: "Path_105",
      d: "M172,13l2-11,1.5,1.5,3,2V8l2,5v4.5l3.5,1V15l1.5,3.5,4,3,3,5.5V21.5h2l3-4,1.5,4v160l-5,4-7,5v14l-3.5,6-15.5,8L155.5,223l-7,2L118,243.5,92,254l-9-6L67,261.5H61l-6.5,6-18,18H14l-7.5,8-1-3v-8L4,279V259l-3-1,1-7.5,5.5-1v-21l2-7.5,1-4.5-1-5.5,2-4-1-5h19l6-4h7l4.5,2h5.5l6-2,9-3.5v-6l4.5-7V169l6-6V149.5l-2-8-2-6,1-8.5,5-3.5,5-1.5,1.5-3,1-3.5L95,104l1-6.5,2-3,2.5-1.5h8l3-2.5,2.5-2-.5-3c-.634-1.057-1.589-2.662-2-3.391-.411-.5-1.366-1.764-2-2.609l-1-4.5,1-5,2-3,6-4,13-5h14l2.5-1,2-1,2-1,1.5-1V47.5l1-1,2.5-2,2.5-2,2.5-3V20l1.5-2.5,5-2.5Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Baruten-2",
      name: "Baruten",
      fill: null,
      translate: { x: "108.5", y: "144.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6K",
      name: "6K",
      fill: null,
      translate: { x: "113.5", y: "129.796" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "KAIAMA",
    path: {
      id: "Path_107",
      d: "M201.5,25l-2-2.5-.5,159,2.5-1h10l5.5,1,3,3.5,4.5,6.5,2.5,10,7.5,6,13,4L265,218l10.5,2,2-1.5,3.5-2,5.5-2h4l1,.5H294l6-3.5,9-4,7-3,6.5-2.5,6.5-2,5.5-1H340l8.5,1,7.5-1.5,7-4.5,4.5-4.5L369,186v-2l-.5-3V168.5L367,166l-3-2-7-2.5-5-2-5-2-2.5-2-2-8-19-13-2-1.5-2.5-3-3-4.5-12.5-12-3.5-3-3-1-11-17L283,88l-5.5-7-13-13.5-2-3.5-1-3.5-1.5-3-3-.5v1l-1.5,2H254V57.5l.5-3-2.5-1h-2.5L248,55v4l-6-1-1-1.5-3.5.5v2l-1.5-.5-1-1-.5-1,.5-2v-2l-2-2-1.5-.5L230,52h-1.5V50l-1-.5L225,50h-3.5l-1-2-1-3H222l3-1,1.5-2L225,40h-2l-2.5.5v-2l-2-1.5H221l2.5-1,.5-2-4.5-3H218l-2.5,4h-4V31l2-1.5V28l-2-1-5-4.5H203Z",
      translate: { x: "", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "KAIAMA-2",
      name: "KAIAMA",
      fill: null,
      translate: { x: "256.5", y: "126.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5K",
      name: "5K",
      fill: null,
      translate: { x: "239.5", y: "107.796" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Asa",
    path: {
      id: "Path_108",
      d: "M272,273v-4h20l24,18.5V291l4,4.5-2,3.5v5.5l5,10.5,3.5,4.5L329,321h12l4,3.5,4,3,2,2.5-4,6-3.5,8-5.5,7v5.5l3,7.5-19.5,9v-6.5l-2.5-10-6-7-9-7L297,331l-12.5-26.5-10-19Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Asa-2",
      name: "Asa",
      fill: null,
      translate: { x: "292.5", y: "310.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5k-2",
      name: "5k",
      fill: null,
      translate: { x: "297.5", y: "321.796" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Moro",
    path: {
      id: "Path_109",
      d: "M284.5,229c6-5.6,7.5-4.667,7.5-3.5v-8l2-1,4-2,7-3.5,6.5-3.5,7-2.5,10-3.5,5.5-1h7l7.5,1,8-2,6.5-4,5-5,1.5-4H371l7,5h4.5l7.5-5,2,7.5a9.709,9.709,0,0,1,0,10.5c-3.5,5.5-2,7.5-6.5,9.5a56.749,56.749,0,0,0-7.5,4v21l4.5,9.5L392,263h-2l-4.5,3.5-3,4-14,11-20,15.5H333l-3.5-4.5H324l-3.5,4.5-4-4.5v-3l-12-10.5L292,270.5H269.5l-3-13,3-11A135.268,135.268,0,0,1,284.5,229Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Moro-2",
      name: "Moro",
      fill: null,
      translate: { x: "326.5", y: "235.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6k-2",
      name: "6k",
      fill: null,
      translate: { x: "347.5", y: "254.796" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Oyun",
    path: {
      id: "Path_112",
      d: "M322,376.5V373l19.5-8.5-4-8.5v-5.5l6.5-8,2.5-6.5,5-6.5,2,2.5v2l3,5,5.5,9,4.5,6-1.5,4,4.5,5,1.5,9,2,5.5,3,4.5-4,1.5h-5.5L362,385l-4.5,1-4,2.5-7-2.5-1-4-2.5-1.5-1.5,3h-4L335,386l-4.5-2.5-4-3Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Oyun-2",
      name: "Oyun",
      fill: null,
      translate: { x: "334.5", y: "389.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M",
      name: "4M",
      fill: null,
      translate: { x: "353.5", y: "397.796" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Offa",
    path: {
      id: "Path_113",
      d: "M352,360.5l5-4.5h4.5v7.5l3.5,1,2,4.5v3l-2,2.5-3.5,1-4.5-1h-5l-3.5,1L346,372h-1.5v-3l2.5-3,3.5-2.5Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_5K-3",
      name: "5K",
      fill: null,
      translate: { x: "358.5", y: "379.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "Offa-2",
      name: "Offa",
      fill: null,
      translate: { x: "352.5", y: "373.796" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Ifelodun",
    path: {
      id: "Path_114",
      d: "M366,354.5,353.5,334l-1.5-4-9-6,6-4,4.5,2.5,7,1.5H367l1.5-6-1.5-5.5-6.5-5.5-7-4,.5-1,14.5-10,19.5-2,4.5-5,5-2.5,14-7-3-5-14.5-9h-3l-8-13-5-12L376.5,217l10.5-5,4-8.5,4.5,2.5v9l-1.5,2v17l3.5,2.5,7,3.5,7,4,7,1.5,5,3,4,11,7.5,5,5.5,4.5,6.5,1.5,9,5,4,2.5,4-4.5,3.5-3,5-4.5,6-.5,6,.5,3.5,3v4.5l1.5,9L488,289l1.5,13,5,7-6.5,3.5-3.5,4.5-6.5,4.5-5.5,6.5-2,8-5.5,9.5-9,3-4,1.5-6,2v-6.5l-4.5-13L436,325h-7l-4.5,3-4,3H400l-8.5-3h-9l-5.5,3v17.5c-2,2-6.2,6-7,6s-2.333,2-3,3V360l-2-1.5,1-2.5Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Ifelodun-2",
      name: "Ifelodun",
      fill: null,
      translate: { x: "421.5", y: "304.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6K-3",
      name: "6K",
      fill: null,
      translate: { x: "420.5", y: "296.796" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Irepodun",
    path: {
      id: "Path_115",
      d: "M377,349V331.5l5.5-3.5H391v10.5l1.5,6.5,6,7.5,9,8.5,6.5,6,4,1.5,9.5-.5,3.5-1,5-3.5,5.5-4h3l1,3V365l-4,3L431,379c-2,1.667-6,5.4-6,7s-2,.667-3,0v-3.5h-4.5L415,386l-3,3-4.5,3-1.5-6-4.5-3.5-5-3.5h-4l-7-1.5,1,1.5v3.5l-7,3.5-3.5-3.5-2.5-3.5-1.5-5-2.5-10.5-2-2.5-1-3.5,4-2.5Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Irepodun-2",
      name: "Irepodun",
      fill: null,
      translate: { x: "381.5", y: "372.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6k-4",
      name: "6k",
      fill: null,
      translate: { x: "386.5", y: "363.796" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "ISIN",
    path: {
      id: "Path_116",
      d: "M391,338.5v-10l9,2.5h20.5l9-6,7,.5,5,7.5,4,12v7l5-1v3.5l-5,4-3.5,1-10,7-7.5,2H418l-7-4L399,353l-6.5-8Z",
      translate: { x: "", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ISIN-2",
      name: "ISIN",
      fill: null,
      translate: { x: "413.5", y: "346.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5K-4",
      name: "5K",
      fill: null,
      translate: { x: "413.5", y: "356.796" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "OKE-ERO",
    path: {
      id: "Path_117",
      d: "M475.5,337l-4-1.5-6.5,10L450,350v4.5l-6,5,1.5,4.5-7.5,6.5L426.5,383l-3,4.5,4.5,3,5.5-7.5,7.5,1.5,3-4.5v-3l4.5-3.5,1.5-3h4l7-3.5,10.5-3,9.5-3,10.5-3,1.5-3.5-5-2.5v-8.5l-2.5-1.5-7,3.5h-3Z",
      translate: { x: "", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OKE-ERO-2",
      name: "OKE-ERO",
      fill: null,
      translate: { x: "458.5", y: "358.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6K-5",
      name: "6K",
      fill: null,
      translate: { x: "447.5", y: "367.796" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Ekiti",
    path: {
      id: "Path_118",
      d: "M447.5,390l-6-5.5,2.5-5V377l4.5-4,1.5-3,4,1,7.5-4,18.5-5h6l2.5-1H493l3.5-1.5L504,355l4.5.5,1,1.5,2,4-3,8-5,7H483l-3,2-2.5,3.5v4a9.379,9.379,0,0,0,1,3l-2,4-3.5,2h-6l-7.5-2L458,391h-4l-2.5,1.5h-3Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_5K-5",
      name: "5K",
      fill: null,
      translate: { x: "458.5", y: "386.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "Ekiti-2",
      name: "Ekiti",
      fill: null,
      translate: { x: "457.5", y: "377.796" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "EDU",
    path: {
      id: "Path_119",
      d: "M507,298l-12.5,9H493l-3-4.5-1.5-13L490,282l-1.5-4v-8.5l-4.5-4H473l-8.5,7.5-4,5L446,270.5l-5-1-1.5-1.5-12-9L424,249l-5-3.5-7.5-1.5-13-7-4-3V216.5L396,215v-8.5l-4.5-2.5v-1.5H393v-6l-1.5-4-1-7h1l3-2.5,8.5-3,5-2.5,4.5-2,4.5,2,5,2.5,3.5,3,4.5,5,4,2,9,4.5,3,4.5,1.5,5,4,4.5L456,210l7.5,2.5,14,2.5,9,2.5,8,3,14,6-12,9.5-3.5,6,3.5,5.5V259l-2,6.5,2,6L498,279l9,15Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_6K-6",
      name: "6K",
      fill: null,
      translate: { x: "413.5", y: "228.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "EDU-2",
      name: "EDU",
      fill: null,
      translate: { x: "412.5", y: "217.796" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Group_14",
    path: {
      id: "Path_120",
      dataName: "Group 14",
      d: "M496.5,236.5l12-10,4,4,4.5,3,3.5,1.5,1.5,2.5,7.5,6,14,1.5,2.5,2.5,9.5,12H584l6-1,6-1.5h20.5l3.5,3.5V263l2,2.5,3.5-2.5,7-1.5-2,4-4,6L623,281l-4,9.5-2.5,6L615,306v7.5l-2.5,6.5-6,4L590,322.5l-9.5-3.5-7-1H554l-13.5-3.5-16.5-6L507,298v-4l-9-15.5-1.5-7-1.5-6,1.5-6v-12l-3-5.5Z",
      translate: { x: "", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Pategi",
      fill: null,
      translate: { x: "511.5", y: "265.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6k-7",
      name: "6k",
      fill: null,
      translate: { x: "510.5", y: "276.796" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Ilorin_south",
    path: {
      id: "Path_121",
      dataName: "Ilorin south",
      d: "M353,303.5H335.5l2,2.5,6,1.5,3.5,4-1.5,4h-9l1,5H340l3.5,3,2-1.5,2.5-1.5,3.5,1.5,6,1.5,8.5,1,2.5-4v-5l-2.5-4-5-4Z",
      translate: { x: "", y: "" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Ilorin_south-2",
      name: "Ilorin south",
      fill: null,
      translate: { x: "350.5", y: "322.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-2",
      name: "4M",
      fill: null,
      translate: { x: "356.5", y: "332.796" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Ilorin_west",
    path: {
      id: "Path_122",
      dataName: "Ilorin west",
      d: "M393.5,261.5h-2V263h-2l-4,3.5-3,4-34.5,27H333.5L329,292l-1,1v8.5l1,6,4.5,8.5h12l1.5-4.5-3.5-4-6-1.5-2.5-2.5h18.5l.5-2,14.5-9.5,19-2,5-5,19.5-9-3.5-5-8-5.5Z",
      translate: { x: "", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Ilorin_west-2",
      name: "Ilorin west",
      fill: null,
      translate: { x: "357.5", y: "304.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5k-6",
      name: "5k",
      fill: null,
      translate: { x: "360.5", y: "293.796" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "ilorin_west-3",
    path: {
      id: "Path_111",
      dataName: "ilorin west",
      d: "M318.5,299l4.5-8h6l-1,3.5v7l1,6.5,1.5,2.5,2.5,5,4.5,1V321H329l-2.5-1-3.5-4.5L318.5,304Z",
      translate: { x: "", y: "" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ilorin_west-4",
      name: "ilorin west",
      fill: null,
      translate: { x: "322.5", y: "313.796" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5k-7",
      name: "5k",
      fill: null,
      translate: { x: "327.5", y: "322.796" },
      tspan: { x: 0, y: 0 },
    },
  },
];
