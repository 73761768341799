export const data = [
  {
    state: "YAMAITU",
    path: {
      id: "Path_7078",
      dataName: "YAMAITU/DEBA",
      d: "M2130.544,2004.368l-11.185,23.8-17.323,16.71-1.16,1.091-25.031,24.348-43.31,18.211-18.62,13.573,5.047,8.048-.2,8.457h-10.844l-11.254,5.525h-2.728l-2.933,8.048,3.137,5.525.75,1.636,6.07,12.891,10.981,4.16,1.978.818,31.851,36.558,15.005,9.344,17.392,6.684h33.351l16.915,6.684,11.117-.682,10.572-6.343,4.229-12.754h66.294l23.8,9.549-34.443-42.9-28.1-3.683-31.783-11.663-5.8-20.666-13.777-12.209-3.751-9,3.751-4.774,36.557-24.894V1989.5l-22.78,5.865Z",
      translate: { x: "-1215.631", y: "-1120.754" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "YAMAITU_DEBA-2",
      name: "YAMAITU/DEBA",
      fill: "#001755",
      translate: { x: "813.186", y: "1015.223" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M",
      name: "4M",
      fill: "#001755",
      translate: { x: "859.395", y: "1035.398" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "AKKO",
    path: {
      id: "Path_7080",
      d: "M633.586,2596.646l5.32-31.646h.887l6.275,1.091,9.344,1.569,17.938,3.069,32.124,5.456v-7.093l4.774,9.48,5.32,4.024H743.6l5.8,13.232,3.956,2.592.614.341.409.409-6.957-.409-9.89,6.684-5.388,3.819,4.433,5.934,2.387,3,7.3,1.5,6.752,1.432,5.456,2.8,5.388,5.388.273-.068h5.388l3.206,5.729,6.684,14.255,12.891,4.91,31.646,36.558,15.209,9.412,17.392,6.684h33.42l16.983,6.684-4.911,31.237h-17.87l-30.282-20.529H839.152l-26.8,11.594-8.457-8.458-11.595-3.137-19.574,16.5-38.4,4.024-8.457,13.368-6.684,26.259-9.344,8.525H680.374l-4.911-34.784-63.77-39.285L576.5,2690.7l6.206-12h42.354c1.341-6.979,4.2-21.661,4.911-24.553s10.117-15.755,14.732-21.825c-2.819-4.457-8.989-14.8-11.117-20.529S632.7,2599.306,633.586,2596.646Z",
      translate: { x: "5.922", y: "-1617.751" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "AKKO-2",
      name: "AKKO",
      fill: "#001755",
      translate: { x: "694.5", y: "1042.938" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M",
      name: "5M",
      fill: "#001755",
      translate: { x: "705", y: "1080.085" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "FUNAKAYe",
    path: {
      id: "Path_7081",
      d: "M1889.047,649.4l-30.214-27.9-.409.341-2.865,2.8L1851.195,629l-2.114,9.958-16.574,15.823-2.114,7.844,6.275,8.73,6.615,61.451-10.776,3.751h-14.459l-14.6-2.455-7.5-1.3L1767.3,719.986l-10.776-10.572-8.73-8.525,1.637,5.456v11.731l-5.934,10.64-3,47.2,59.337,19.506,3.683,6h101.624l1.978-43.991,5.934-29.941-2.046-44.878-3.478-5.456-9.685-16.3Z",
      translate: { x: "-999.301", y: "60.641" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "FUNAKAYO-2",
      name: "FUNAKAYO",
      fill: "#001755",
      translate: { x: "813.186", y: "814.066" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.5M",
      name: "3.5M",
      fill: "#001755",
      translate: { x: "836.732", y: "837.383" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "NAFADA",
    path: {
      id: "Path_7082",
      d: "M1373.167,24.257,1349.5,51.2l23.667,27.827,14.527,10.367h8.73l6.207,3.751,12.481,6.275,5.388,6.616-5.388,19.1-7.912,24.485,2.933,15.414,19.506,19.1,28.645,12.822,22.03,3.751h14.527l10.776-3.751L1499,135.5l-6.275-8.73,2.114-7.843,16.573-15.823,2.114-9.958,7.5-7.434-37.376-61.452h-25.372l-21.143-9.139h-19.984V9.662C1414.543,6.775,1408.4,1,1404.745,1h-31.578Z",
      translate: { x: "-661.637", y: "596.5" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "NAFADA-2",
      name: "NAFADA",
      fill: "#001755",
      translate: { x: "755.91", y: "669.604" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-2",
      name: "5M",
      fill: "#001755",
      translate: { x: "778.91", y: "694.35" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "KWAmi",
    path: {
      id: "Path_7083",
      d: "M1326,1764.344l-.818-10.844,10.367,3.41,25.918,8.525,22.985,7.571,3.683,6h38.535l7.775-.068h55.313l-1.432,44.674-44.2,42.9-43.378,18.21L1382.2,1898.3h-14.459l-9.548-9.071-13.982,9.071-4.979-3.274-5.8-13.232h-28.1l-5.32-4.092-8.662-17.392-28.1-48.288-3.751-18.142,3.751-12.822,12.822-9.889Z",
      translate: { x: "-583.912", y: "-916.946" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "KWARNI-2",
      name: "KWARNI",
      fill: "#001755",
      translate: { x: "771.955", y: "896.591" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-2",
      name: "4M",
      fill: "#001755",
      translate: { x: "789.73", y: "920.127" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "DUKKU",
    path: {
      id: "Path_7084",
      d: "M168.327,379.3,184.082,369l20.666,24.28.955,1.159,1.773,2.046.341.477,8.73,6.138,5.729,4.092h7.843l.818-.068,6.207,3.751,7.3,3.683,5.32,2.728,5.32,6.684-2.182,7.434-5.32,18.006-1.364,4.3-4.5,13.777,3.069,15.414,1.569,5.32v11.8l-5.934,10.64-.477,7.23-2.455,39.49v.409l.818,10.981L219,571.292l-30.623,4.229-3.547,2.728-9.276,7.161-1.228,4.229-2.524,8.594,1.228,5.865,2.524,12.277,7.3,12.55,5.661,9.753,3.615,6.207,3.206,5.456,6.479,11.049,1.364,2.387,1.023,1.978,3.41,6.888v7.093l-66.7-11.322-14.664-21.007L97.258,634.79l-43.378-5.865L31.305,609.9l-9.48-30.487L0,565.017v-4.911l21.825-32.329,9.48-19.984,4.638-19.779,2.319-5.115,3.069-1.569,12.549,12.891,6,2.524,5.593-11.526L49.107,459.37V446.411l10.776-14.323H73.8L82.185,417.9l3.069-5.183,2.524-2.865,22.371-16.505h7.98l19.847,16.505h5.456c2.615-1.614,8.034-4.993,8.8-5.593s.091-4.024-.341-5.661l5.593-8.185,5.252-5.524Z",
      translate: { x: "503.783", y: "278.698" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "DUKKU-2",
      name: "DUKKU",
      fill: "#001755",
      translate: { x: "622.324", y: "794.2" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-3",
      name: "4M",
      fill: "#001755",
      translate: { x: "632.824", y: "823.383" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "BALANGA",
    path: {
      id: "Path_7085",
      d: "M2728.4,3526.278l-5.593-7.5-5.865-2.251-6.889-2.728-3.888-1.569-5.115-2.114-1.773-.614h-66.43l-4.365,12.685-10.435,6.411-11.117.545-1.909,12.073-3,19.1,1.909,15.823,21.211,16.164v26.6l-4.433,24.212-21.62,13.436h-25.577l-11.526,9.139L2545,3704.426v10.64h16.573l22.3-24.758h47.47l66.771-47.538,5.524-4.911,2.66-3.069,8.252-5.593,5.8-3.07,5.183-2.933,5.525-4.433v-5.934l-5.525-11.594v-16.847l2.865-5.729Z",
      translate: { x: "-1694.061", y: "-2433.415" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "BALANGA-2",
      name: "BALANGA",
      fill: "#001755",
      translate: { x: "941.658", y: "1135.876" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-3",
      name: "5M",
      fill: "#001755",
      translate: { x: "968.658", y: "1167.223" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "KALTUNGO",
    path: {
      id: "Path_7086",
      d: "M2203.525,3748.641l6.548-5.047,14.869-6.411,11.868-5.183h14.8l17.46,11.8,12.822,8.8h17.869l1.978,15.551,21.143,16.3v26.6l-4.434,24.212-14.459,9-7.229,4.433h-25.44l-11.594,9.139-3.888,8.935-1.3-1.091-5.661-5.115-11.049-11.253-1.364-8.866-9-2.388v-7.912l-14.322-1.363-3.684-17.87-5.934-35.056-5.661-13.436-2.864-5.388.273-5.388,2.591-2.865Z",
      translate: { x: "-1391.803", y: "-2625.564" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "KALTUNGO-2",
      name: "KALTUNGO",
      fill: "#001755",
      translate: { x: "841.092", y: "1167.223" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-4",
      name: "4M",
      fill: "#001755",
      translate: { x: "859.395", y: "1189.04" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "BILLIri",
    path: {
      id: "Path_7087",
      d: "M1451.9,3771.217l3.069-15.141v-1.842l.2-3.888,2.592-2.864,5.729-.2,6.548-5.184-8.8-8.321-8.866-2.387-2.251-.887-19.643,16.778-22.916,2.319-10.026,1.023-5.593.614-4.57,7.229-3.887,6.07-6.547,26.327-4.843,4.3-4.638,4.229H1338l.682,36.9,15.823,7.434,14.664,6.684,37.375-2.046,20.734-8.389,11.8-9.276c2.182-6.161,6.548-18.619,6.548-19.165s2.182-10.595,3.274-15.55Z",
      translate: { x: "-651.705", y: "-2624.269" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_4M-5",
      name: "4M",
      fill: "#001755",
      translate: { x: "746.73", y: "1190.759" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "BILLIN-2",
      name: "BILLIN",
      fill: "#001755",
      translate: { x: "743.799", y: "1167.223" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "SHOMGOM",
    path: {
      id: "Path_7088",
      d: "M1348.365,4039.63c0-3.546-2.91-28.669-4.365-40.786l30.487,13.914,37.171-1.978,20.8-8.526,11.867-9.343,6.548-19.3,3.206-15.959,3.205-24.349,2.933-14.8,2.933,5.592,5.525,12.822,5.661,34.238,3.956,18.62,14.186,1.432.136,7.844,9.071,2.591,1.228,8.867,18.005,17.392-13.027,29.941v10.572H1369.3l-6.343-2.524-5.388-5.183C1354.5,4055.157,1348.365,4043.177,1348.365,4039.63Z",
      translate: { x: "-656.887", y: "-2786.625" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "SHOMGOM-2",
      name: "SHOMGOM",
      fill: "#001755",
      translate: { x: "749", y: "1238.205" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M",
      name: "3M",
      fill: "#001755",
      translate: { x: "770.799", y: "1261.741" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "GOMBE",
    path: {
      id: "Path_7079",
      d: "M1731.115,2759.012l.569-.4,14.155-8.609,9.886,8.945H1770.8l5.264,8-.213,8.273h-11.31l-5.477,2.69-6.258,2.757h-2.845l-3.129,8.071h-5.762l-5.619-5.313-5.691-2.758c-4.788-.941-14.424-2.837-14.651-2.892s-4.836-5.9-7.112-8.81l5.335-3.564,10.6-6.793Z",
      translate: { x: "-971.234", y: "-1777.517" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M",
      name: "2M",
      fill: "#001755",
      translate: { x: "764.73", y: "992.97" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "GOMBE-2",
      name: "GOMBE",
      fill: "#001755",
      translate: { x: "755.91", y: "969.434" },
      tspan: { X: "0", y: "0" },
    },
  },
];
