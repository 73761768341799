export const data = [
  {
    state: "BIASE",
    g: { translate: { x: "-0.5", y: "-0.5" } },
    path: {
      id: "Path_7468",
      d: "M40.291,931.312l2.72,2.72,24.144-.68,2.38,3.911v5.1l-.68,1.87.68,4.081v5.1l4.251,5.271,3.911.85v4.251l-2.04,6.8-1.87,5.951-1.36,6.631v5.951l3.741,3.4,3.571,1.87h4.591l4.761,2.55h18.193l6.291-3.231,3.571-5.271,5.951-10.882,5.441-5.441,6.971-5.1v-6.8l-16.153-29.925-6.971-19.553-5.1-11.222v-3.911h-3.741l-3.06-4.421-1.87-6.291-3.4-2.55-4.931-3.911-5.441-6.8-2.38-3.911-2.38-5.1-3.06-3.23-2.21-2.891h-3.4l-2.21,1.87-8.161,10.542H57.294l-2.21-1.19-2.38-2.38-2.04.85-5.611,2.72-2.04,2.72-.85,3.4-1.19,1.36v4.761l-5.611,3.741L34,888.3l9.011,9.011v6.971l-2.04,5.1v9.182Z",
      translate: { x: "-21.778", y: "-564.584" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "BIASE-2",
      name: "BIASE",
      fill: "#001755",
      translate: { x: "36.517", y: "359.137" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_7M",
      name: "7M",
      fill: "#001755",
      translate: { x: "64.08", y: "380.031" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Bakassi",
    g: { translate: { x: "-0.5", y: "-0.5" } },
    path: {
      id: "Path_7463",
      d: "M537.033,1717.3l4.081-3.4h1.02l-.51,1.36v1.19l1.02,1.36v1.36l-2.21-.51-1.7,2.21h-1.7v3.23h-1.19v1.53l1.19.51v.85h5.1l1.19-.85h31.625l1.53-1.53.51-1.02,2.04-2.04,1.02-1.02,1.02-1.87-1.36-.85v-1.87l-1.36-1.19-1.36-1.53H575.8l-.85-1.7v-.68l-1.19-1.36h-4.081v1.36l-1.53.68-2.04.85-1.7.85-.68-.85v-.85l-.51-.68-.51-.85h1.02l2.04.85,1.02-1.36,1.36-1.02v-1.7l-.68-1.02v-2.38l-1.36-2.55-.85-1.87-2.04-2.55-.51-2.21,1.02-3.4v-2.04l.68-2.21v-1.87l-1.7-2.89v-2.72l-2.38-.85v-2.72l-1.53-.68-.51-2.21h-2.04a8.239,8.239,0,0,1-.51-1.87v-2.04l.51-1.87-.51-2.38.51-1.7,1.19-1.87v-3.061h-2.38v-2.89l1.19-.85,1.7-.51v-2.04l-1.02-1.19-1.87-.68V1634.5l-1.02.68-1.36,1.53-2.72,1.7-1.36,1.19-2.72,1.87h-2.04l-1.53-.85-1.19-.51H538.9l-3.06,1.36-1.87-.85-2.21,1.36-1.53,2.38v2.72l-1.02,4.421-1.53,4.761,1.53,3.4h1.87l1.19.68-.51,1.53,1.02,2.551,3.741,3.571-.68,1.53-3.571,4.421-.85-2.72h-2.21l-1.02.68-1.87.68v4.931l-1.87-.68-.51.68-.68,3.571-.68,1.7h-1.02v1.87l-.85,1.36-.51,3.231-.85,2.04,2.21,2.89,1.7,2.891,3.06.85,1.87-.85,1.02-2.891,5.951-2.89-.51,2.04-7.311,6.291v3.741l-2.04.51-1.36-2.55-2.38-.51-1.36,2.04-1.7,2.891v2.04l1.7,2.72c.227,1.132.544,3.571,0,4.251s-.227,1.983,0,2.55l4.081,3.571,2.55,2.72h4.251l2.55-2.72Z",
      translate: { x: "-341.522", y: "-1078.021" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Bakassi-2",
      name: "Bakassi",
      fill: "#001755",
      translate: { x: "238.274", y: "617.672" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M",
      name: "5M",
      fill: "#001755",
      translate: { x: "201.122", y: "631.672" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "AKPABUYO",
    g: { translate: { x: "-0.5", y: "-0.5" } },
    path: {
      id: "Path_7464",
      d: "M453.348,1488.663l4.251-3.911v2.891l1.36,1.02,1.36-1.02h2.21l1.19-.51h6.121l.51-1.7,3.06-.68,3.23-2.04-.51-2.55a16.907,16.907,0,0,0,.51-3.061c0-1.02.34-2.21,0-2.55s-2.04,0-2.72-1.19c-.544-.952-.907-1.078-1.02-1.02l-.68-2.04.68-1.53v-3.23l1.02-1.02-.51-2.89v-2.72l4.251,2.21,5.1-.85,2.38-2.72-1.36-3.741-1.02-2.891h3.4l2.04-1.53v-2.21l1.02-1.53h1.53v-2.04l.68-2.04,2.21-1.19h2.72V1438l-1.02-1.02H482.763l-2.21,1.02H466.44l-1.87.85h-2.38l-.68-.85h-4.931l-1.19.85-2.55,1.53h-5.781l-2.04-1.53-2.04-2.891-2.04-2.21-2.04-1.7-2.38-4.761V1423.2l-1.87-3.23-3.4-2.38-3.911-1.36v-1.53l-2.04-.68-.51-2.891.51-3.06-1.87-2.72-3.231-.85H417.3v6.971c.68.51,2.04,1.734,2.04,2.55a1.847,1.847,0,0,1-1.02,1.53v1.36l-3.571,1.87a18.88,18.88,0,0,0-.85,3.23c0,.68-1.53,2.38-1.53,3.061a14.563,14.563,0,0,1-1.53,3.4v3.571l1.53,2.38,3.571,1.53h4.251l1.87,2.891-.85,1.53-1.87,1.19-3.4,2.04v5.781l-1.19,1.53v1.36h-2.38l-2.55-1.36H404.55v2.891l-1.02,4.761-1.53,1.87v3.4l1.53,1.7,4.591,3.911,2.72,2.891,1.53,1.02,1.53-1.02,5.441,5.781.85,2.38-.85,3.231v1.7l-1.02,3.23v4.761l-2.38,5.1-3.571,2.21v2.04l4.931,4.251,2.89,1.87,5.951-1.87v-1.53h3.4l2.72-4.761,1.87,2.04,2.21,4.251h2.89l3.911-4.251-1.36,4.251-1.53,1.87h-6.121l-1.87,1.36v3.911l-1.87,2.21h3.741l4.081,1.36h7.651v-3.571h5.951v-2.38l-1.87-2.891-1.36-4.251v-1.87l1.36-7.311,1.87-2.04,1.53-2.38Z",
      translate: { x: "-264.638", y: "-926.234" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "AKPABUYO-2",
      name: "AKPABUYO",
      fill: "#001755",
      translate: { x: "160.76", y: "535.039" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M",
      name: "4M",
      fill: "#001755",
      translate: { x: "162.696", y: "556.52" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "Calabar_South",
    g: { translate: { x: "-0.5", y: "-0.5" } },
    path: {
      id: "Path_7465",
      dataName: "Calabar South",
      d: "M370.175,1553.344l-1.19,1.19-1.87-1.19-2.55-2.72-6.291-5.611v-3.4l1.7-1.87.85-5.1v-2.72l-2.21.85-3.571-.85-3.741-1.02-2.04-1.7-1.87-1.7-.68,1.7-3.741,2.72-3.23,4.421-1.36,1.87-2.38,4.251v5.271l1.19,2.891v3.911l1.19,4.591v1.7l1.36,4.421,1.02,3.74,2.21,2.55,1.36,1.36,2.38-.51v-2.04h.68l1.02-.51,2.89-3.23v-5.781l1.53,1.19v2.04l1.02,1.19,1.19,2.55.85,2.04,1.19,1.7c.4.622,1.258,2.006,1.53,2.55s.793-.228,1.02-.68l1.19.68.85.68a9.546,9.546,0,0,0,2.891,1.7h2.21l1.02-.68,2.38.68c.952.272,1.077-1.02,1.02-1.7l1.36-1.36v-1.87l1.19-3.741.85-1.19v-5.781l1.02-1.7Z",
      translate: { x: "-221.082", y: "-1007.407" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "Calabar_South-2",
      name: "Calabar South",
      fill: "#001755",
      translate: { x: "64.08", y: "568.84" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M",
      name: "6M",
      fill: "#001755",
      translate: { x: "118.149", y: "549.039" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "ODUKPANI",
    g: { translate: { x: "-0.5", y: "-0.5" } },
    path: {
      id: "Path_7466",
      d: "M125.46,1235.854l.85-1.7h1.02v-2.72l-1.02-5.271-.85-1.53,1.87-3.061V1218l4.761-2.72,4.591-1.19-2.38-4.761v-6.291l2.38-2.89,3.231-1.7h12.922v-6.291l-2.21-1.19-2.55-2.04-1.53-3.571-1.19-1.87-2.55-4.251-6.121-2.55-6.291-3.911-6.8-4.421H119l-5.271-1.87-4.251-2.21-7.651-3.4-4.931-2.55-6.121-2.891-4.421-2.891-2.21-1.87-2.55-4.421H67.651l-5.271-2.55H58.129l-4.081-1.87-3.4-3.4v-5.441l1.19-6.8,2.21-5.951,1.87-6.971v-3.911l-4.081-1.36-3.911-5.271v-4.591l-.68-4.081.68-2.891v-4.591L45.377,1083H20.893v2.04l1.19,2.72,1.7,3.4,1.87,3.571,1.02,3.4,1.87,1.53,11.392,23.974v3.06l-2.72,1.36-5.781-1.36-8.331-.51-4.591-.51-6.291-1.02-1.19,1.02,1.19,2.38h1.53l1.7,1.53,1.87,1.02v3.231l-1.87-.85-4.421-.85-2.38-.85-4.421-1.7L1,1130.608v2.38l5.781,5.441h8.671l1.87,1.36,6.461,3.911,1.87,4.081a48.083,48.083,0,0,1,1.02,4.761v2.891l1.87,2.891,2.21,1.36h3.4l.85,2.38,1.19,3.23,1.02,3.061h2.72l3.571-1.87,1.87-4.421,1.02-3.741,2.89-2.04,3.571,1.19a36.474,36.474,0,0,1,3.06,2.21,31.509,31.509,0,0,1,2.21,4.591l2.72,4.081v2.55l1.53,3.23-1.53,3.741v4.591l1.53,3.741,4.251,2.72,1.87,2.04,1.87,2.55v21.763l1.7,1.7,2.55,3.571,2.21,4.081,2.21,4.421v3.741l5.1,5.781h2.89l3.231,1.7,2.21,2.55,3.231,2.04,5.781,4.081.85,3.571,2.38,4.761,1.87,3.74h1.87l2.72,1.19,1.87,2.04,2.04,2.55,3.741,1.7-1.53-2.38-1.19-5.1v-3.74l-1.19-3.061v-5.271l2.38-4.081,1.53-2.04,3.231-4.251Z",
      translate: { x: "0", y: "-714.062" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ODUKPANI-2",
      name: "ODUKPANI",
      fill: "#001755",
      translate: { x: "66.46", y: "473.509" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M-2",
      name: "6M",
      fill: "#001755",
      translate: { x: "85.164", y: "509.215" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "AKAMKPA",
    g: { translate: { x: "-0.5", y: "-0.5" } },
    path: {
      id: "Path_7469",
      d: "M391.875,1070.453l-6.291,7.311v1.36l-11.052-.34-2.21,1.19H358.379l-2.04.85h-2.38l-.51-.85h-5.1l-3.571,2.38h-5.951l-2.04-1.53-1.19-1.53-1.19-1.87-1.7-1.53-1.87-1.87-2.72-4.761v-4.251l-1.7-3.061-3.571-2.38-3.741-1.53v-1.36l-2.04-.85-.68-2.72.85-2.72-2.21-3.4-2.72-.68h-3.06l-4.081-2.55-5.951-10.2-5.951-2.72-12.752-8.5h-4.591l-5.1-1.36-12.412-5.781-15.3-7.821-3.06-3.06-2.04-3.741h3.911l6.121-3.4,3.741-4.931,6.121-11.052,5.1-5.611,6.8-4.761v-7.141l-4.591-8.841-11.222-21.083-6.971-19.383L241.911,904v-4.591h19.383l31.455-21.253L317.4,862l17.173,14.962,26.864,3.571,13.942,3.911L420.1,890.9l6.121,7.141L432.171,904l4.251,3.4,4.591,7.481V922.7l-2.04,6.8-6.8,11.392v9.862l-4.931,13.092v8.331l-10.2,24.484v4.931l2.04,6.971V1020.3l-2.04,10.032-1.53,8.5-4.251,5.951-12.412,9.522-5.271,5.781v2.72l1.36,4.931Z",
      translate: { x: "-156.407", y: "-568.214" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "AKAMKPA-2",
      name: "AKAMKPA",
      fill: "#001755",
      translate: { x: "146.373", y: "349.427" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M",
      name: "3M",
      fill: "#001755",
      translate: { x: "155", y: "402" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "ETUNG",
    g: { translate: { x: "-0.5", y: "-0.5" } },
    path: {
      id: "Path_7470",
      d: "M613,738.522v9.862l9.181,3.571,26.354,3.4,19.383,3.4V752.8l8.161-4.421,3.06-6.631v-6.121l-1.7-4.761-3.061-7.141,3.061-1.7,1.7-3.571,4.591-1.7,3.06-3.23,3.741,1.7,1.53-3.741v-3.741l1.19-2.89h2.72l6.121-6.121,7.311-6.121,6.121-3.741-8.841-9.011-4.591-4.591L685.262,663.2l-7.821-1.7-3.061,1.7-2.89,5.611-3.571,4.081-3.741,6.971-7.311,9.011L643.6,698.736h-3.911v10.882l-2.38,5.611-7.481,3.231h-5.441l-5.1,3.571-1.7,4.251-2.38,3.061v6.291Z",
      translate: { x: "-403.887", y: "-435.895" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ETUNG-2",
      name: "ETUNG",
      fill: "#001755",
      translate: { x: "251.376", y: "268.173" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-2",
      name: "4M",
      fill: "#001755",
      translate: { x: "242.376", y: "291.135" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "IKOM",
    g: { translate: { x: "-0.5", y: "-0.5" } },
    path: {
      id: "Path_7471",
      d: "M492.595,409.441H484.6l-1.02,3.231h2.89l-2.89,2.38h-1.7l2.72,5.781-4.591-.85v3.911l-1.19,1.02,1.19,2.21h-1.19v3.571h-4.591l-1.53,1.36-1.87,2.55,1.87,1.36,3.231,1.7-1.7,1.36h-5.951v2.38l-2.04,2.38a40.736,40.736,0,0,1-3.231,4.421c-.408.272-.51,1.927-.51,2.72l2.89,2.55,2.38,2.38c.17,1.473.51,4.625.51,5.441a.817.817,0,0,1-1.02.85l.68,5.611h3.741l1.36,1.36v3.571l-1.36,1.36-1.19,2.55v2.55h.68l2.72,1.87a5.949,5.949,0,0,0,1.53.68,16.52,16.52,0,0,1,2.55,2.89c.227,1.36.544,4.183,0,4.591s.227,1.417.68,1.87l4.591,1.87,7.651,6.291,2.55,2.55v3.4c-.567.68-1.87,2.21-2.55,2.89a28.279,28.279,0,0,1-4.081,3.06,39.822,39.822,0,0,1-5.1.85c-.816.136-3.514,1.984-4.761,2.89l.68,3.231c.793,1.587,2.38,4.965,2.38,5.781s2.607,2.72,3.911,3.571c-.227.793-.782,2.38-1.19,2.38a11.323,11.323,0,0,0-2.72,1.53l-6.461,3.4v11.562l4.421,12.072,1.02.85v1.53l-1.02,2.38-1.7,6.631,6.121,4.761,26.864,3.741,4.421.68V568.926l2.04-3.231v-6.461l2.72-3.06,1.36-4.081,5.271-3.4h5.271l7.821-3.4,2.04-5.611V528.8h3.741l13.432-9.862,7.311-9.352,3.4-6.631,4.251-3.741,2.72-6.461,3.4-1.36h2.38l-5.781-1.36-4.081-3.911-.85-1.53-.85-3.571c-.907-1.3-2.924-3.945-3.741-4.081s-3.174-1.87-4.251-2.72l-7.651-4.761-5.441-1.53-3.06-4.081,1.36-1.19v-2.89l1.7-5.611,1.36-4.251v-5.951l3.231-9.862-3.231-10.2-4.421-13.432-4.251-4.931-1.87-1.53-3.911,3.571-6.121,4.251-10.032,2.21-8.5,1.02-6.631-1.02c-1.3-.51-4.149-1.666-5.1-2.21A62.4,62.4,0,0,0,492.595,409.441Z",
      translate: { x: "-304.565", y: "-265.959" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "IKOM-2",
      name: "IKOM",
      fill: "#001755",
      translate: { x: "195.001", y: "192.32" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-3",
      name: "4M",
      fill: "#001755",
      translate: { x: "199.104", y: "218.335" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "ABI",
    g: { translate: { x: "-0.5", y: "-0.5" } },
    path: {
      id: "ABI-2",
      d: null,
      translate: { x: "26.674", y: "235.847" },
      fill: "#001755",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_4M-4",
      name: "4M",
      fill: "#001755",
      translate: { x: "42.017", y: "285.896" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "Path_7472",
      name: "Path 7472",
      fill: "#fa5252",
      translate: { x: "-49.826", y: "-461.633" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "YAKUR",
    g: { translate: { x: "-0.5", y: "-0.5" } },
    path: {
      id: "Path_7474",
      d: "M184.053,738.141,196.3,731l6.631,6.121,5.1,2.04,4.591,2.04,3.911,2.72-1.02,2.55-2.89,4.931,1.87,4.591,2.89,3.06,7.481.85,5.1,7.821,1.02,7.311,4.591,4.761,7.651,12.582-30.6,20.573H195.1l-1.7-.17h-3.741l-3.06-4.421-1.87-6.291-7.991-6.291-1.19-1.02-1.87-2.55-1.36-1.53-1.36-2.04-2.04-3.4-2.55-5.441-1.87-1.7,1.87-2.04,1.53-4.081,4.761-6.291V759.9l-1.19-2.89v-2.72l2.89-7.821,6.121-3.4,1.19-4.931Z",
      translate: { x: "-107.901", y: "-481.761" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "YAKURR-2",
      name: "YAKURR",
      fill: "#001755",
      translate: { x: "71.664", y: "294.216" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-2",
      name: "5M",
      fill: "#001755",
      translate: { x: "85.503", y: "314.74" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "ODUBRA",
    g: { translate: { x: "-0.5", y: "-0.5" } },
    path: {
      id: "Path_7475",
      d: "M263.961,500.18l-6.461-5.441,9.011-7.481,5.781-3.571,5.781-2.55h2.55v2.55l1.19,3.571h4.421l3.741-2.72v-9.692l2.21-5.781,2.38-10.372L302.9,447.3l7.141-6.291,3.4-7.991,6.461-2.55V425.2l5.781-6.461-2.21-7.141H319.9v-7.141l-1.7-12.242,5.271-5.781,8.5-4.251,3.06-6.971,9.181-2.21v2.21l1.19.17.17,1.87h.85v4.931l.68,1.19,2.04,5.611-.85,3.06,3.06.17-3.231,2.38-1.53-.17,2.55,5.781-4.591-.68v3.911l-1.19,1.02,1.53,2.21-1.53-.17v3.741h-4.251l-1.7,1.36-1.87,2.55,2.21,1.53,2.55,1.53-1.36,1.53h-5.951v2.38l-5.271,6.631-.51,2.72,5.271,5.1.51,5.441-.34.51h-.68l.68,5.781h3.911l1.19,1.53v3.231l-1.53,1.53-1.19,2.55v2.55h.85l4.421,2.55,2.72,2.89v4.081l-.51.85.85,1.53,4.421,1.7,10.372,8.841v3.4l-2.38,2.72-4.421,3.571-5.1.68-4.591,2.72.85,3.741,2.21,5.271.34,1.02,3.571,2.72-1.02,2.55h-.85l-2.04,1.53-6.461,3.06v11.562l4.421,12.072,1.02.85v1.87l-1.02,2.04-1.19,6.461-11.222-9.692-25.844,16.323-6.8-11.222-4.761-5.781-1.36-6.971-5.271-8.5-8.161-1.02-2.21-2.38-1.7-4.591,3.911-7.311-3.911-3.06Z",
      translate: { x: "-169.276", y: "-245.5" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OBUBRA-2",
      name: "OBUBRA",
      fill: "#001755",
      translate: { x: "124.95", y: "230.066" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_7M-2",
      name: "7M",
      fill: "#001755",
      translate: { x: "133.564", y: "254.173" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "BOKI",
    g: { translate: { x: "-0.5", y: "-0.5" } },
    path: {
      id: "Path_7476",
      d: "M687.5,298.968v3.571l2.04,1.53,4.081,4.591,7.651,23.8c-1.077,3.23-3.231,9.726-3.231,9.862v5.951l-1.36,4.591-1.7,5.271v2.89l-1.36,1.19,3.06,4.081,5.611,1.53,4.081,2.55L713,374.8l1.36.51,3.231,3.911.85,3.571,1.02,1.7,4.251,3.911,4.931,1.19-1.7.34,7.821,2.04,2.55,1.87L751.26,403.7l13.772,13.432,11.222-14.622,14.452-11.562,8.671-8.5,18.533-7.821,20.233-9.692,9.011-14.112,8.161-17.173-8.161-12.582V305.429l-1.19-15.642-6.291-7.311L831,275.5l-9.692,1.02-5.441,3.571-5.441,5.271-8.671,2.72-15.472,3.23h-9.521l-6.121-3.23-3.741-5.611-4.761-1.02L750.41,278.9l-2.04-3.4-2.38-2.89-4.931,1.87h-6.291l-6.971-3.231L724.906,267l-2.04-6.971-1.36-7.141L718.1,250l-5.441,2.89c-1.7,2.1-5.237,6.461-5.781,7.141s-1.02,4.024-1.19,5.611c.17,1.927.408,6.019,0,6.971a58.2,58.2,0,0,0-1.36,7.481l-2.55,3.571-2.38,2.89-4.251,1.53-4.081,5.271Z",
      translate: { x: "-453.053", y: "-164.327" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "BOKI-2",
      name: "BOKI",
      fill: "#001755",
      translate: { x: "287.156", y: "166.156" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M-3",
      name: "6M",
      fill: "#001755",
      translate: { x: "293.979", y: "185.32" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "OBANLIKU",
    g: { translate: { x: "-0.5", y: "-0.5" } },
    path: {
      id: "Path_7477",
      d: "M1054.708,246.157l8.671,12.752V255l2.72-5.611v-3.231l-2.72-5.781h2.72l5.781,1.53,4.591,2.55,5.951,1.7,9.181-4.251,1.87-6.461,1.02-7.481v-5.1l2.55-2.21.51-6.8.85-10.372,1.7-9.181v-5.1h-3.061l-5.441-5.271-4.591-7.141-7.311-6.971-4.421-5.951-9.182-8.331-7.311-11.392L1053.008,137h-7.141l-7.481,1.7-7.651,3.741-10.032,3.571,2.55,9.522-2.55,5.781-2.38,7.141-9.182,8.331-6.291,7.141L1002,189.2l.85,3.911,1.53,3.06,1.02,4.591,2.04,4.931,5.1,6.461,5.781-1.7,4.931-4.761,5.781-4.081,9.351-.85,9.011,6.971,5.611,7.141,1.7,14.962Z",
      translate: { x: "-660.606", y: "-89.753" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OBANLIKU-2",
      name: "OBANLIKU",
      fill: "#001755",
      translate: { x: "362.648", y: "98.201" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M-4",
      name: "6M",
      fill: "#001755",
      translate: { x: "393.264", y: "120.967" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "OBUDU",
    g: { translate: { x: "-0.5", y: "-0.5" } },
    path: {
      id: "Path_7478",
      d: "M924.509,165.5h5.611l2.72,8.671-2.72,5.951-2.21,7.141-9.352,8.331-6.121,7.141-.85,5.271.85,3.4,1.53,3.741.85,4.421,2.04,4.931,5.441,6.8-19.213,4.251h-9.352l-5.781-2.89-3.741-5.951-16.663-3.741L865,219.229l.85-.85,2.55-2.55,2.72-2.38,2.38-2.89,2.38-4.761,2.21-4.251,2.04-3.23,1.02-3.571v-2.89l1.02-5.1-1.02-3.741-2.55-2.38-3.061-2.89V175.7l.85-4.081,2.21-4.421,2.55-1.7,5.271,4.591,3.741,4.081,4.421,2.38h8.161l4.761-2.38,7.821-4.081,4.931-2.89Z",
      translate: { x: "-570.193", y: "-108.562" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OBUDU-2",
      name: "OBUDU",
      fill: "#001755",
      translate: { x: "315.156", y: "78.933" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M-5",
      name: "6M",
      fill: "#001755",
      translate: { x: "315.156", y: "105.201" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "OGOJA",
    g: { translate: { x: "-0.5", y: "-0.5" } },
    path: {
      id: "Path_7479",
      d: "M638.623,172.784l-5.441-5.271-3.4,2.38-6.971,4.421-9.181,4.421-11.392,1.53-4.761-1.53v-5.951l-.85-7.651-3.571-7.141L591.185,150H588.8l-4.251,3.06-8.5,4.251-7.311,6.121-3.06,4.761-4.421,3.741c-1.53,1.643-4.693,4.931-5.1,4.931a47.42,47.42,0,0,0-5.441,2.55l-8.161,7.821-5.951,6.121L531,195.737l-5.441,1.87-3.911-1.87-3.911,1.02-4.081,1.19-2.55,1.7.85,3.571,1.7,3.231,1.87,4.761,2.21,5.611-2.21,1.36-3.571.85-4.251-2.21-4.931-1.53-3.741,2.21-1.53,3.911,2.72,2.89,2.55,1.53v1.87l1.19.51v1.87l1.02.34v4.591l2.72,6.8h7.821l11.562,4.591,6.631.85,7.991-.85,10.372-2.38,5.611-3.571,4.421-4.081V232.8l7.651-10.542,4.421-1.87,4.761-6.121,1.36-7.821v-6.8l.85-5.781,5.951-6.631,5.951-3.4,2.89,2.89,1.53,6.631,1.02,4.251,1.36,3.23,2.38,4.251,7.311,3.741,5.781-.34,5.271-2.04,2.21,2.38,7.821-7.991,6.8-12.242,1.19-3.741v-3.4l1.02-4.591Z",
      translate: { x: "-327.663", y: "-98.332" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OGOJA-2",
      name: "OGOJA",
      fill: "#001755",
      translate: { x: "208.263", y: "106.967" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M-6",
      name: "6M",
      fill: "#001755",
      translate: { x: "211.26", y: "132.981" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "BEKWARA",
    g: { translate: { x: "-0.5", y: "-0.5" } },
    path: {
      id: "Path_7480",
      d: "M790.423,76.442,799.775,62.5h1.7l5.1,2.72,6.121,4.251a47.866,47.866,0,0,0,4.591,4.931c.68.408,1.417,2.777,1.7,3.911l-1.36,4.931-3.4,4.251-2.89,5.441-.51,3.231h1.87l3.741-.34,2.55,1.19-1.02.68-2.89,1.53-2.04,4.081-1.02,4.761v1.87l-10.2,6.461-8.841,4.591-11.732,1.53-4.421-1.53-1.02-13.6-3.4-6.8-2.21-8.331H769l13.262-8.841Z",
      translate: { x: "-506.838", y: "-40.587" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "BEKWARA-2",
      name: "BEKWARA",
      fill: "#001755",
      translate: { x: "300.727", y: "18.913" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M-7",
      name: "6M",
      fill: "#001755",
      translate: { x: "277.634", y: "62.079" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "YALA",
    g: { translate: { x: "-0.5", y: "-0.5" } },
    path: {
      id: "Path_7481",
      d: "M520.485,13.242,531.2,21.233l-8.5,12.582-2.21,2.55-6.291,5.441L500.082,51.5h-1.53L496,54.048l-10.2,4.931-7.141,6.291-3.06,4.421L471.178,73.6l-4.761,4.591-5.611,2.89L446.694,94.855l-10.882,4.421-4.081-1.87c-2.664.68-7.991,2.108-7.991,2.38s-1.813,1.133-2.72,1.53l1.02,3.06,1.7,4.421,3.911,9.522-2.04,1.36-3.571.85-5.951-2.72-3.741-1.02-3.231,2.38-1.53,3.741,2.38,2.89,2.38,1.7-7.991,2.04-.85-2.55-3.4-1.7h-2.72l-.68-2.38,1.7-1.19-1.02-2.55-2.21-3.06-.34-2.89h-4.251l.34-.85-.34-3.571,1.7-2.04,1.19-2.38-1.53-2.04-4.931-.51-.34-3.06h1.02l.51-1.36-1.53-1.87-2.04-1.36h-3.061l-.68-3.571-3.571-1.02-.68-2.04-4.251-1.53-4.931-4.931-1.87-3.231.51-1.53,2.04-1.87-.68-3.231-3.741-3.571-.85-3.571-1.19-2.38-.68-2.72h5.1l5.1-2.04,5.441-1.7,4.251-2.89,3.06-4.761,2.72-6.121,3.741-2.21,4.761-1.87h4.251l3.4,2.89,4.421,1.19,3.911-4.081h16.323l13.432-3.571,10.2-5.441,3.571-4.081-3.571-3.06-7.481-7.991v-3.06h4.761l13.432-3.571L470.667,1H486.82l12.752,5.611Z",
      translate: { x: "-237.581", y: "" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "YALA-2",
      name: "YALA",
      fill: "#001755",
      translate: { x: "177.26", y: "58.247" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M-8",
      name: "6M",
      fill: "#001755",
      translate: { x: "175.544", y: "78.933" },
      tspan: { x: 0, y: 0 },
    },
  },
  {
    state: "CALABAR_MUNICIPALITY",
    g: { translate: { x: "-0.5", y: "-0.5" } },
    path: {
      id: "Path_7467",
      dataName: "CALABAR MUNICIPAL",
      d: "M381.622,1422.5H394.2v.85l1.36,1.19.85,1.36-.34.85-.85.68v1.36l-3.571,1.87-.68,2.72-1.53,2.891v1.02l-1.7,2.891v3.74l1.36,2.21,3.741,1.7h4.251l1.7,2.891-.85,1.7-5.1,3.23v5.611l-1.19,1.53v1.36h-2.21l-2.55-1.36h-5.1l-.68-.34-2.04,1.02-7.141-1.7-2.04-1.7c-.567-.68-1.768-2.04-2.04-2.04h1.02v-2.89l-1.02-4.761-.85-1.7,1.87-2.89v-3.741l4.931-2.891,4.421-1.19-2.21-4.591v-6.291l2.21-3.06Z",
      translate: { x: "-241.54", y: "-938.113" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "CALABAR_MUNICIPAL-2",
      name: "CALABAR MUNICIPAL",
      fill: "#001755",
      translate: { x: "147.322", y: "502.215" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_6M-9",
      name: "6M",
      fill: "#001755",
      translate: { x: "131.751", y: "518.434" },
      tspan: { x: 0, y: 0 },
    },
  },
];
