export const data = [
  {
    state: "ONA_ARA",
    path: {
      id: "Path_7413",
      dataName: "ONA ARA",
      d: "M237.654,300.65c-2.635,9.448-8.751,13.468-9.51,23.245-.19,2.468,1.409,4.3,0,6.34-1.486,2.145-3.789,1.56-6.34,2.113-6.464,1.405-10.629.023-16.906,2.113-4.778,1.591-6.156,1.794-10.566,4.226a94.788,94.788,0,0,1-9.509,4.227s4.226-9.045,4.226-15.849c0-17.962,3.17-26.415,1.057-44.378a22.091,22.091,0,0,0-6.34-13.736c-3.84-4.009-12.679-11.623-12.679-11.623v-5.283l3.17-4.226s9.26-.659,14.793,1.057c5.475,1.7,7.477,4.987,12.679,7.4,7.819,3.62,12.584,5.253,21.132,6.34,6.549.833,16.906,0,16.906,0s-.313,7.739,0,12.679c.235,3.73,1.069,5.773,1.057,9.509C240.8,291.113,239.35,294.57,237.654,300.65Z",
      translate: { x: "190.458", y: "275.74" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M",
      name: "2M",
      fill: "#001755",
      translate: { x: "395.357", y: "575.197" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "ONA_ARA-2",
      name: "ONA ARA",
      fill: "#001755",
      translate: { x: "431.282", y: "585.031" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OLUYOLE",
    path: {
      id: "Path_7414",
      d: "M191.578,340.755c-5.858,1.336-8.886,3.132-14.793,4.226-11.361,2.107-19.019,0-29.585,0-1.845,0-1.809-2.407-2.113-4.226-.607-3.641,2.056-5.469,4.226-8.453,2.06-2.832,4.165-3.595,6.34-6.34,3.21-4.053,5.038-6.619,6.34-11.623,1.35-5.192,1.109-8.487,0-13.736-.919-4.349-3.565-6.171-4.226-10.566-.429-2.857.809-4.624,0-7.4a9.491,9.491,0,0,0-2.113-4.226c-1.824-2.084-6.34-3.17-6.34-3.17s.543-4.835,2.113-7.4c2.155-3.519,4.765-4.486,8.453-6.34,4.527-2.274,7.559-2.608,12.563-3.157l.116-.013c4.1-.45,6.46.4,10.566,0,5.48-.535,8.622.549,12.681-3.17,1.521-1.395,3.17-3.17,3.17-3.17a54.03,54.03,0,0,1,9.507,8.453c3.212,3.88,9.51,6.587,9.51,11.623,0,6.34,3.17,8.453,2.113,19.019-1.2,11.988-1.057,20.076-2.113,34.868-.344,4.818-5.283,11.623-5.283,11.623S199.714,338.9,191.578,340.755Z",
      translate: { x: "161.417", y: "280.533" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-2",
      name: "2M",
      fill: "#001755",
      translate: { x: "339.259", y: "587.34" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "OLUYOLE-2",
      name: "OLUYOLE",
      fill: "#001755",
      translate: { x: "326.58", y: "571.031" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IREPO",
    path: {
      id: "Path_7415",
      d: "M266.154,49.14c8.193,2.289,20.076,8.453,20.076,8.453s-16.659,15.259-29.585,21.132c-14.222,6.461-39.095,8.453-39.095,8.453s-27.472-11.623-45.434-26.415c-7.644-6.3-28.529-3.17-28.529-3.17s14.5-4.251,15.849-10.566c3.17-14.793-8.544-19.08-2.113-30.642,5.34-9.6,12.413-14.012,23.245-15.849,11.965-2.029,20.192,1.825,29.585,9.51C221.776,19.555,224.946,35.4,240.8,45.97,249.1,51.506,256.541,46.455,266.154,49.14Z",
      translate: { x: "159.845" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-3",
      name: "2M",
      fill: "#001755",
      translate: { x: "350.979", y: "65.369" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "IREPO-2",
      name: "IREPO",
      fill: "#001755",
      translate: { x: "333.89", y: "41.185" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ORELOPE",
    path: {
      id: "Path_7416",
      d: "M144.993,64.879C141.43,54.584,128.087,42.69,128.087,42.69s7.859-6.676,13.736-9.51c7.663-3.695,21.132-5.283,21.132-5.283s17.409-3.849,25.359,2.113a244.544,244.544,0,0,0,21.677,14.477l.511.315c8.493,5.241,22.189,12.679,22.189,12.679S220.877,75.03,214.729,87.068c-4.114,8.06-9.2,12.088-9.51,21.132-.338,9.8,11.623,22.189,11.623,22.189s-9.233,5.944-14.793,7.4c-5.647,1.474-15.849,1.057-15.849,1.057s3.17-12.679,2.113-17.962c-2.069-10.34-17.379-10.014-25.359-16.906-7.318-6.32-12.362-10-15.849-19.019C144.264,77.6,147.571,72.329,144.993,64.879Z",
      translate: { x: "142.59", y: "29.695" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-4",
      name: "2M",
      fill: "#001755",
      translate: { x: "308.531", y: "114.494" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "ORELOPE-2",
      name: "ORELOPE",
      fill: "#001755",
      translate: { x: "287.399", y: "86.563" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "SAKI EAST",
    path: {
      id: "Path_7417",
      dataName: "SAKI EAST",
      d: "M86.329,94.48c-3.414-3.1-2.113-11.623-2.113-11.623S96.025,73.8,104.291,69.122c9.807-5.553,16.18-6.9,26.414-11.623C149.139,49,177.2,34.254,177.2,34.254S191.864,47.017,195.159,57.5c2.117,6.738-2.253,11.268,0,17.962,2.847,8.46,9.767,12.484,15.849,19.019,9.357,10.055,23.387,5.622,26.415,19.019,1.467,6.49-1.448,9.51-2.113,16.906s-1.477,12.72-2.113,22.189c-.427,6.34,3.523,13.953,0,20.076-2.329,4.048-6.365,4.278-8.453,8.454-2.952,5.9,0,16.906,0,16.906s-7.229-6.925-9.509-12.679c-1.551-3.912,1.333-8.153-2.113-10.566-10.566-7.4-14.748-9.153-25.359-11.624-12.056-2.808-20.118,4.372-31.7,0-8.571-3.236-15.815-5.153-19.019-13.736-2.02-5.412.712-9.06,0-14.793-.79-6.357.131-11.153-4.226-15.849-2.889-3.114-5.325-6.024-9.51-5.283-2.369.419-3.17,4.322-5.283,3.17C106.4,110.33,97.951,105.047,86.329,94.48Z",
      translate: { x: "93.48", y: "38.132" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-5",
      name: "2M",
      fill: "#001755",
      translate: { x: "247.431", y: "160.464" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "SAKI_EAST-2",
      name: "SAKI EAST",
      fill: "#001755",
      translate: { x: "213.436", y: "135.167" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OLORUNSOGO",
    path: {
      id: "Path_7418",
      d: "M294.571,27.275c7.515-.464,19.019,3.17,19.019,3.17a165.632,165.632,0,0,1-24.3,26.415C276.976,67.278,268.8,71.334,266.042,85.389c-1.767,8.99,2.113,22.189,2.113,22.189s-17.189,21.147-32.755,26.415c-14.461,4.895-24.158,3.165-39.095,0-6.767-1.434-21.132-4.226-21.132-4.226s-9.926-12.136-10.566-22.189c-.575-9.031,5.317-13.113,9.51-21.132,6.365-12.172,19.019-29.585,19.019-29.585s24.925-1.076,40.151-8.453c11.959-5.794,29.585-21.132,29.585-21.132s10.849,3.541,17.962,3.17C286.333,30.158,289.076,27.614,294.571,27.275Z",
      translate: { x: "183.202", y: "30.318" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-6",
      name: "2M",
      fill: "#001755",
      translate: { x: "390.947", y: "131.4" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "OLORUNSOGO-2",
      name: "OLORUNSOGO",
      fill: "#001755",
      translate: { x: "376.154", y: "105.581" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ORI IRE",
    path: {
      id: "Path_7419",
      dataName: "ORI IRE",
      d: "M236.984,84.773c9.057-5.883,22.189-19.019,22.189-19.019s2.823,13.891,6.34,22.189c6.344,14.972,17.071,19.247,24.3,33.812,7.84,15.789,12.679,43.322,12.679,43.322l-28.529,31.7s-14.1,1.126-19.019,7.4c-3.466,4.415-4.226,13.736-4.226,13.736s-6.777,4.831-8.453,9.509c-2.265,6.325,3.17,16.906,3.17,16.906s-10.313,4.727-13.736,10.566c-2.38,4.06-1.238,7.333-3.17,11.623-3.6,8-14.793,16.906-14.793,16.906l-4.226-9.51s-11.274-8.037-13.736-15.849c-1.86-5.9,2.4-9.7,0-15.849-2.87-7.341-11.826-7.489-14.793-14.793-2.639-6.5-4.055-10.165-2.113-16.906,2.606-9.042,16.906-20.076,16.906-20.076a23.151,23.151,0,0,0,8.453-17.962c0-13.736-6.445-22.047-13.736-33.812-5.913-9.542-16.906-9.908-16.906-21.133,0-10.566,6.34-28.529,6.34-28.529s17.434,5.484,27.472,5.283C221.113,94.008,225.481,92.245,236.984,84.773Z",
      translate: { x: "193.242", y: "73.199" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-7",
      name: "2M",
      fill: "#001755",
      translate: { x: "427.056", y: "234.428" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "ORI_IRE-2",
      name: "ORI IRE",
      fill: "#001755",
      translate: { x: "409.966", y: "203.903" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ATIBA",
    path: {
      id: "Path_7420",
      d: "M211.323,94.773c.873-5.944,4.226-15.849,4.226-15.849l-13.736-3.17s-9.788,5.729-15.849,7.4c-5.625,1.548-14.793,2.113-14.793,2.113a138.171,138.171,0,0,0-2.113,15.849c-.382,9.107,0,12.679,0,22.189,0,6.421-7.772,6.5-9.509,12.68-1.469,5.224.964,8.432,2.113,13.736,1,4.6,3.17,2.113,3.17,9.51,0,16.164-5.283,27.472-12.679,44.378-.993,2.267-6.34,0-6.34,0L141.587,218.4s13.066,14.848,23.245,22.189c10.927,7.88,17.962,4.226,30.642,15.849,7.821,7.168-2.94,21.272,6.34,26.415,4.034,2.238,11.623,2.113,11.623,2.113l30.642-22.189s-8.6-8.453-11.623-13.736c-4.226-7.4,1.743-11.623-1.057-17.962-3.781-8.556-15.241-9.814-16.906-19.019-1.283-7.092.471-12.337,3.17-19.019,5.165-12.787,20.982-12.677,22.189-26.415.833-9.49-1.357-15.623-5.283-24.3-5.993-13.244-12.679-19.02-23.245-30.643C206.881,106.793,210.364,101.3,211.323,94.773Z",
      translate: { x: "157.618", y: "84.331" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-8",
      name: "2M",
      fill: "#001755",
      translate: { x: "336.003", y: "266.646" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "ATIBA-2",
      name: "ATIBA",
      fill: "#001755",
      translate: { x: "331.776", y: "234.488" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ATIGBO",
    path: {
      id: "Path_7421",
      d: "M9.4,146.34l3.17-11.623s16.678,3.077,27.472,4.226c24.619,2.625,38.639,0,63.4,0,6.6,0,10.646,2.1,16.906,0,6.416-2.151,7.02-9.729,13.736-10.566,8.076-1.006,17.962,10.566,17.962,10.566a42.5,42.5,0,0,0,15.849-22.189c3.17-10.566,12.918-22.477,20.076-26.415,9.821-5.4,17.549-.91,28.529-3.17,5-1.029,12.679-3.17,12.679-3.17s-4.085,10.708,0,14.793c8.453,8.453,13.778,10.717,24.3,12.679,10.617,1.98,12.686-4.376,23.245-2.113,10.864,2.328,21.132,5.283,28.529,13.736,3.1,3.54,1.112,7.392,3.17,11.623,2.565,5.275,12.679,11.623,12.679,11.623s1.767,10.425,0,16.906c-3.17,11.623-7.4,20.076-12.679,30.642-1.405,2.811-7.4,3.17-7.4,3.17s5.42-10.207,4.227-16.906c-1.006-5.644-2.94-9.076-7.4-12.679-5.292-4.279-10.325-2.494-16.906-4.226-4.55-1.2-7.7-.571-11.623-3.17-5.249-3.48-5.325-10.243-10.566-13.736A41.044,41.044,0,0,0,245.021,140c-3.17-.792-4.486-.505-7.4,2.113-10.566,9.51-13.736,15.849-22.189,24.3-8.86,8.859-17.128,10.277-29.585,11.623a89.722,89.722,0,0,1-17.634.021l-.328-.021c-15.849-1.057-25.359-5.283-39.095-4.226-5.022.387-11.623,6.34-11.623,6.34s-9.167,5.885-16.906,6.34c-35.925,2.113-87.7,2.113-87.7,2.113L2,172.755Z",
      translate: { x: "2.227", y: "93.511" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-9",
      name: "2M",
      fill: "#001755",
      translate: { x: "194.417", y: "239.174" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "ATISBO-2",
      name: "ATISBO",
      fill: "#001755",
      translate: { x: "188.949", y: "212.819" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "SAKI_WEST",
    path: {
      id: "Path_7422",
      dataName: "SAKI WEST",
      d: "M161.41,63.423c3.888-.2,8.453-5.283,8.453-5.283a39.4,39.4,0,0,0,2.113,8.453c2.5,5.6,7.024,7.563,11.623,11.623,7.905,6.978,22.188,13.736,22.188,13.736s1.325-3.3,3.17-4.226,3.269-.448,5.283,0c5.725,1.275,7.791,6.016,9.509,11.623,1.329,4.34,0,11.623,0,11.623s-9.981,3.531-17.962,4.226c-5.754.5-11.326-1.5-16.9,0-7.949,2.14-11.544,6.37-16.866,12.633l-.04.047c-6.215,7.313-5.089,14.308-10.566,22.19-4.832,6.953-14.793,15.849-14.793,15.849s-6.182-7.073-11.623-8.453c-3.6-.913-5.971-1.126-9.51,0-4.734,1.509-4.966,6.441-9.51,8.453-6.037,2.673-9.247,2.039-15.849,2.113-19,.213-30.662,0-49.661,0a154.8,154.8,0,0,1-44.378-6.34l12.679-33.812S31.432,117.3,40.956,113.084c8.969-3.968,15.319-2.4,24.3-6.34,12.7-5.567,14.782-19.329,28.529-21.132,3.682-.483,5.844.6,9.51,0,9.943-1.615,13.356-8.389,21.132-14.793,5.636-4.64,6.79-10.431,13.736-12.679,5.1-1.652,13.736,0,13.736,0S157.167,63.642,161.41,63.423Z",
      translate: { x: "6.777", y: "63.906" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-10",
      name: "2M",
      fill: "#001755",
      translate: { x: "137.545", y: "196.389" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "SAKI_WEST-2",
      name: "SAKI WEST",
      fill: "#001755",
      translate: { x: "86.643", y: "175.778" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IBADAN NORTH",
    path: {
      id: "Path_7423",
      d: "M173.1,238.87a84.384,84.384,0,0,1,11.623,0l-3.17,7.4s-3.06,4.007-5.283,6.34a96.858,96.858,0,0,1-8.453,7.4l-4.226-3.17a39.533,39.533,0,0,0,2.113-6.34c.7-3.647,0-11.623,0-11.623A46.2,46.2,0,0,0,173.1,238.87Z",
      translate: { x: "182.109", y: "265.67" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-11",
      name: "2M",
      fill: "#001755",
      translate: { x: "349.224", y: "516.545" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "N",
      fill: "#001755",
      translate: { x: "355.224", y: "508.945" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IBADAN North West",
    path: {
      id: "Path_7424",
      dataName: "IBADAN North. West ",
      d: "M160.726,240.557l10.566-1.057s1.388,7.737,0,11.623a24.726,24.726,0,0,1-3.17,5.283L156.5,254.293s3.381-2.781,4.226-5.283C161.519,246.664,160.726,240.557,160.726,240.557Z",
      translate: { x: "175.515", y: "266.413" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-12",
      name: "2M",
      fill: "#001755",
      translate: { x: "336.616", y: "518.711" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "N.W",
      fill: "#001755",
      translate: { x: "330.616", y: "510.711" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IWAJOWA",
    path: {
      id: "Path_7425",
      d: "M106.9,134.037c5.283-2.113,11.623-5.283,11.623-5.283s8.715,14.5,15.849,21.132c8.4,7.8,25.359,14.793,25.359,14.793a23.645,23.645,0,0,0-1.057,5.283c-.069,3.914,2.737,4.562,3.17,8.453.652,5.873-3.472,9.949-3.17,15.849.388,7.576,4.535,10.41,8.453,16.906,4.531,7.513,5.283,10.566,13.736,15.849,7.389,4.617,16.906,6.34,16.906,6.34s-8.819,7.958-16.906,10.566c-9.458,3.049-27.472,2.113-27.472,2.113s-9.859-3.967-16.906-4.226c-7.824-.29-11.208,5.8-19.019,6.34-6.23.427-15.849-4.226-15.849-4.226s-5.812-3.009-9.51-2.113c-5.136,1.243-6.46,5.672-8.453,10.566-2.645,6.5,4.77,12.819,0,17.962-2.763,2.98-5.451,4.438-9.51,4.226-7.565-.4-6.877-9.8-9.51-16.906-4.18-11.28-.045-19.025-3.17-30.642-2.309-8.584-.459-17.246-8.453-21.132-5.2-2.525-9.138-1.181-14.793,0-11.553,2.411-14.195,11.87-24.3,17.962-5.218,3.144-13.736,7.4-13.736,7.4s-.416-9.03,0-14.793c1.306-18.1,9.691-26.692,13.736-44.378,1.967-8.6,5.4-13.446,4.226-22.189-.695-5.173-4.226-12.679-4.226-12.679S101.62,136.15,106.9,134.037Z",
      translate: { x: "0", y: "143.332" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-13",
      name: "2M",
      fill: "#001755",
      translate: { x: "86.827", y: "344.315" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "IWAJOWA-2",
      name: "IWAJOWA",
      fill: "#001755",
      translate: { x: "57.057", y: "319.017" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IBARAPA_NORTH",
    path: {
      id: "Path_7426",
      dataName: "IBARAPA NORTH",
      d: "M120.147,277.94c7.756-1.145,20.076,0,20.076,0s6.942-21.825,4.226-32.755c-1.557-6.268-7.4-10.566-7.4-10.566s-13.293-8.3-16.906-16.906c-2.575-6.135.13-10.642-2.113-16.906-2.124-5.93-8.453-13.736-8.453-13.736s-8.008-4.818-13.736-5.283c-7.288-.592-11,3.037-17.962,5.283-2.483.8-3.746,1.826-6.34,2.113-5.188.577-12.679-4.226-12.679-4.226s.229,5.8,0,9.509c-1.087,17.595-11.872,25.092-15.849,42.264-1.607,6.94-4.9,11.052-3.17,17.962,2.055,8.214,7.952,10.86,14.793,15.849,4.923,3.59,10.206,2.43,13.736,7.4,2.043,2.874,3.17,8.453,3.17,8.453s14.937,1.536,24.3,0C105.761,284.767,110.207,279.406,120.147,277.94Z",
      translate: { x: "43.813", y: "202.299" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-14",
      name: "2M",
      fill: "#001755",
      translate: { x: "129.03", y: "440.918" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "IBARAPA_NORTH-2",
      name: "IBARAPA NORTH",
      fill: "#001755",
      translate: { x: "90.031", y: "458.149" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IBARAPA_CENTRAL",
    path: {
      id: "Path_7427",
      dataName: "IBARAPA CENTRAL",
      d: "M137.5,274c1.435-4.74,1.057-14.793,1.057-14.793a31.025,31.025,0,0,0-3.17-9.509c-2.347-4.036-8.453-9.51-8.453-9.51l-4.226-12.679s-12.954-1.1-21.132,0c-9.831,1.325-14.476,6.035-24.3,7.4-8.583,1.19-22.189,0-22.189,0s2.571,9.29,5.283,14.793c5.443,11.042,10.154,17.014,20.076,24.3,9.388,6.9,15.982,9.7,27.472,11.623,8.14,1.359,14.174,4.438,21.132,0C133.783,282.608,135.876,279.375,137.5,274Z",
      translate: { x: "61.325", y: "252.727" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-15",
      name: "2M",
      fill: "#001755",
      translate: { x: "158.677", y: "532.393" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "IBARAPA_CENTRAL-2",
      name: "IBARAPA CENTRAL",
      fill: "#001755",
      translate: { x: "121.99", y: "560.018" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IBARAPA_EAST",
    path: {
      id: "Path_7428",
      dataName: "IBARAPA  EAST",
      d: "M184.908,198.533c1.38,1.226,2.113,4.226,2.113,4.226l-5.283,5.283v6.34L168,223.891s-9.471,6.454-12.679,12.679c-2.891,5.611-3.356,9.539-3.17,15.849.163,5.5,3.17,8.231,3.17,13.736s.146,9.34-3.17,13.736c-2.688,3.563-6.431,2.361-8.453,6.34-1.87,3.677,1.388,6.68,0,10.566-1,2.8-5.283,6.34-5.283,6.34s-3.2-8.73-6.34-13.736c-2.5-3.988-2.832-8.366-7.4-9.51-5.015-1.255-7.834,2.823-11.622,6.34-5.411,5.021-8.453,16.906-8.453,16.906s-1.557-7.6-4.226-11.623c-2.258-3.4-7.4-7.4-7.4-7.4l-3.17-12.679s2.677-11.5,3.17-19.019c.324-4.941,1.327-7.91,0-12.679-1.442-5.184-7.4-11.623-7.4-11.623s6.584-5.509,11.623-7.4c4.32-1.619,9.18,1.8,11.623-2.113,1.748-2.8-1.171-5.368,0-8.453,2.162-5.7,7.809-5.983,13.735-7.4,7.669-1.828,12.489,4.258,20.076,2.113,2.139-.6,3.747-.509,5.283-2.113,2.568-2.682-1.737-6.228,0-9.51,2.158-4.076,6.19-6.741,10.566-5.283,2.768.923,2.959,3.521,5.283,5.283a24.257,24.257,0,0,0,12.679,5.283C179.72,199.025,182.44,196.339,184.908,198.533Z",
      translate: { x: "95.278", y: "208.8" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-16",
      name: "2M",
      fill: "#001755",
      translate: { x: "210.986", y: "477.883" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "IBARAPA_EAST-2",
      name: "IBARAPA \n          EAST",
      fill: "#001755",
      translate: { x: "192.488", y: "441.661" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IDO",
    path: {
      id: "Path_7429",
      d: "M157.578,195.941c4.167-2.8,12.679-2.113,12.679-2.113s2.086,11.859,6.34,17.962c4.531,6.5,9.294,10.471,16.906,12.679,5.547,1.61,13.736,0,13.736,0s2.113,3.17-2.113,7.4c-3.047,3.047-8.453,4.226-8.453,4.226s-3.17,8.453,0,14.793c3.407,6.815,7.367,9.133,10.566,15.849,4.218,8.854,5.983,14.5,6.34,24.3a29.09,29.09,0,0,1,0,4.226c-.182,2.1-.013,3.457-1.057,5.283-.869,1.519-2.828,1.454-3.17,3.17-1.057,5.283-2.113,6.209-2.113,10.566,0,1.057,2.445,1.577,3.17,3.17.854,1.879,0,5.283,0,5.283a66.5,66.5,0,0,0-10.566,2.113c-4.372,1.471-6.792,1.555-9.509,5.283-2.479,3.4-3.17,10.566-3.17,10.566s-7.3-5.473-9.51-10.566c-2.306-5.313-1.4-10.1-2.113-15.849-.83-6.665-2.113-15.849-2.113-15.849s-4.839,1.342-7.4,0c-2.635-1.382-2.322-2.874-5.283-3.17a141.943,141.943,0,0,0-20.076-1.057c-3.8,0-5.135,2.365-8.453,4.226-4.45,2.5-7.734,4.431-12.679,3.17-4-1.019-8.453-6.34-8.453-6.34s3.377-4.18,4.226-7.4c.843-3.191-.97-5.3,0-8.453,1.545-5.019,7.166-4.81,9.51-9.51a18.686,18.686,0,0,0,2.113-11.623c-.3-2.99-1.553-4.444-2.113-7.4-1.154-6.082-1.676-9.89,0-15.849,2.234-7.944,6.633-11.289,12.679-16.906,5.45-5.063,15.849-10.566,15.849-10.566s-1.221-4.778,0-7.4C154.338,198.052,155.64,197.243,157.578,195.941Z",
      translate: { x: "123.665", y: "215.691" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-17",
      name: "2M",
      fill: "#001755",
      translate: { x: "279.13", y: "481.232" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "IDO-2",
      name: "IDO",
      fill: "#001755",
      translate: { x: "278.946", y: "460.086" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "AKINYELE",
    path: {
      id: "Path_7430",
      d: "M237.792,213.184v12.679s-11.073,3.928-16.906,8.453l-.239.186c-5.879,4.56-9.78,7.587-12.441,14.607-2.344,6.185,1.781,10.932-1.057,16.906-2.132,4.488-8.453,9.509-8.453,9.509a110.959,110.959,0,0,0-14.793,0,110.943,110.943,0,0,0-14.793,3.17s-.418-9.207-2.113-14.793c-1.874-6.181-4.188-9.186-7.4-14.793-2.673-4.67-5.7-6.519-7.4-11.623s-1.057-13.736-1.057-13.736,9.368-1.686,11.623-6.34c1.095-2.259,1.057-6.34,1.057-6.34l11.623-3.17s5.16-1.306,8.453-1.057c5.892.446,8.907,3.715,14.793,4.226,4.11.357,6.538.9,10.566,0,4.5-1,5.97-4.875,10.566-5.283,3.789-.336,5.987.678,9.51,2.113C232.945,209.37,237.792,213.184,237.792,213.184Z",
      translate: { x: "168.131", y: "229.017" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-18",
      name: "2M",
      fill: "#001755",
      translate: { x: "338.116", y: "474.282" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "AKINYELE-2",
      name: "AKINYELE",
      fill: "#001755",
      translate: { x: "333.89", y: "456.973" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "LAGELU",
    path: {
      id: "Path_7431",
      d: "M194.219,225.32c6.521-6,21.132-10.566,21.132-10.566s12.679,17.962,15.849,19.019,15.849,16.906,15.849,16.906-15.642,7.851-26.415,10.566c-8.487,2.141-13.814.623-22.189,3.17-6.014,1.828-8.588,5.338-14.793,6.34a52.031,52.031,0,0,1-10.566,0l2.113-6.34s7.7-6.042,9.51-11.623c1.524-4.71-2.3-6.828-1.057-11.623C185.43,234.3,189,230.126,194.219,225.32Z",
      translate: { x: "192.685", y: "239.069" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-19",
      name: "2M",
      fill: "#001755",
      translate: { x: "388.89", y: "492.97" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "LAGELU-2",
      name: "LAGELU",
      fill: "#001755",
      translate: { x: "431.282", y: "475.225" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "EGBEDA",
    path: {
      id: "Path_7432",
      d: "M238.1,271.349a51.693,51.693,0,0,0,0,8.453s-11.718,1.359-19.019,0c-6.931-1.291-10.374-2.627-16.906-5.283-7.529-3.062-10.97-8.377-19.019-9.51a59.426,59.426,0,0,0-10.566,0,16.4,16.4,0,0,0,2.113-5.283c.306-2.988-2.113-7.4-2.113-7.4s6.532.862,10.566,0c3.975-.85,5.718-2.76,9.509-4.226a94.624,94.624,0,0,1,9.51-3.17c9.262-2.65,15.078-1.45,24.3-4.226,8.146-2.451,20.076-8.453,20.076-8.453s1.065,13.776-1.057,22.189C243.731,261.431,239.02,264.2,238.1,271.349Z",
      translate: { x: "192.128", y: "258.551" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-20",
      name: "2M",
      fill: "#001755",
      translate: { x: "399.179", y: "524.533" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "EGBEDA-2",
      name: "EGBEDA",
      fill: "#001755",
      translate: { x: "441.282", y: "520.436" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "SURULERE",
    path: {
      id: "Path_7433",
      d: "M220.587,144.452l30.642-31.7s6.135,25.623,17.962,35.925c5.444,4.74,10.636,4.518,15.849,9.509,4.712,4.512,7.183,7.641,9.509,13.736,3.829,10.029-6.044,17.965-1.057,27.472,2.982,5.685,5.786,8.95,11.623,11.623,6.014,2.756,16.906,1.057,16.906,1.057s-3.675,18.514-12.679,24.3c-6.063,3.9-13.041-1.038-17.962,4.226-2.521,2.7-.951,6.754-4.226,8.453-2.563,1.329-7.4,0-7.4,0l-6.34,3.17-52.831-51.774s13.212-15.667,12.679-27.472c-.226-5.03-1.308-7.838-3.1-12.487l-.074-.192C227.506,153.565,220.587,144.452,220.587,144.452Z",
      translate: { x: "245.563", y: "125.521" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-21",
      name: "2M",
      fill: "#001755",
      translate: { x: "499.087", y: "315.207" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "SURULERE-2",
      name: "SURULERE",
      fill: "#001755",
      translate: { x: "549.941", y: "316.517" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Oyo_north",
    path: {
      id: "Path_7434",
      dataName: "Oyo north",
      d: "M222.287,131.98c4.626-2.984,14.793-4.226,14.793-4.226s8.5,10.676,10.566,17.962c1.921,6.76,2.747,11.143,1.057,17.962-2.067,8.335-11.623,20.076-11.623,20.076l-14.793-13.736-12.679,4.226s-5.752-9.649-3.17-15.849c1.794-4.309,8.453-9.509,8.453-9.509S215.922,136.086,222.287,131.98Z",
      translate: { x: "229.07", y: "142.219" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-22",
      name: "2M",
      fill: "#001755",
      translate: { x: "450.376", y: "302.517" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "O.N",
      fill: "#001755",
      translate: { x: "450.376", y: "287.568" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ITESIWAJU",
    path: {
      id: "Path_7435",
      d: "M244.02,150.176c1.137,5.723-2.113,14.793-2.113,14.793l-5.283,14.793-2.113,8.453s-18.846,4.286-30.642,2.113c-5.52-1.016-7.067-2.033-12.679-2.113-4.207-.061-8.768-1.551-12.679,0-5.754,2.28-9.288,3.776-11.623,9.51-1.712,4.2,0,7.084,0,11.623,0,7.014,3.024,11.633,0,17.962-1.891,3.958-7.4,8.453-7.4,8.453h-9.51s6.078-10.693,5.283-17.962c-.581-5.332-5.458-10.342-8.453-14.793a43.646,43.646,0,0,0-6.34-7.4s4.443-2.677,6.34-5.283c2.075-2.851,3.071-4.928,3.17-8.453.161-5.731-3.522-8.455-7.4-12.679-4.641-5.061-8.335-7.17-14.793-9.51-6.641-2.4-9.51-2.113-15.849-2.113-4.085,0-8.218,3.521-9.51,7.4-.913,2.741.7,4.863,0,10.566S97.151,186.1,97.151,186.1s-19.459-9.169-28.529-19.019C63.2,161.188,57,150.176,57,150.176a58.753,58.753,0,0,1,7.4-4.226c10.617-4.556,18.04-.435,29.585,0,11.164.421,17.427,3.36,28.529,2.113,11.155-1.253,17.793-2.768,27.472-8.453,10.566-6.207,12.88-14.22,22.189-22.189,4.013-3.436,5.3-6.972,10.566-7.4,5.154-.416,12.572,4.575,16.906,7.4,8.328,5.42,4.86,10.321,13.736,14.793,8.313,4.188,16.346-.966,23.245,5.283C240.874,141.345,242.9,144.553,244.02,150.176Z",
      translate: { x: "63.454", y: "122.455" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-23",
      name: "2M",
      fill: "#001755",
      translate: { x: "247.248", y: "304.71" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "ITESIWAJU-2",
      name: "ITESIWAJU",
      fill: "#001755",
      translate: { x: "209.209", y: "276.752" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OYO_WEST",
    path: {
      id: "Path_7436",
      dataName: "OYO WEST",
      d: "M140.124,153.82c.359-3.9,3.17-10.566,3.17-10.566s14.983,17.288,27.472,24.3c7.749,4.353,14.364,3.749,21.132,9.51,4.024,3.426,7.477,5.374,8.453,10.566.778,4.136-.767,6.428,0,10.566.814,4.389-.569,7.071,3.17,9.509,3.525,2.3,11.623,2.113,11.623,2.113s-5.49,2.944-6.34,6.34c-.4,1.6.357,2.616,0,4.226-.4,1.8-1.253,2.593-2.113,4.226-2.242,4.258-6.34,10.566-6.34,10.566s-17.481-8.573-29.585-10.566c-10.993-1.809-28.529,0-28.529,0l-2.113-25.359,6.34-17.962c2.477-7.014-2.868-9.59-4.226-15.849C141.259,160.935,139.7,158.414,140.124,153.82Z",
      translate: { x: "155.911", y: "159.474" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-24",
      name: "2M",
      fill: "#001755",
      translate: { x: "319.281", y: "365.988" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "OYO_WEST-2",
      name: "OYO WEST",
      fill: "#001755",
      translate: { x: "302.375", y: "346.565" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Oyo EAST",
    path: {
      id: "Path_7437",
      d: "M182.823,188.056l31.7-24.3L219.8,174.32l-1.057,17.962s-6.293,6.665-11.623,7.4c-3.3.452-5.19-1.71-8.453-1.057s-4.527,2.544-7.4,4.226c-5.433,3.187-13.736,8.453-13.736,8.453l-8.453,1.057s3.1-5.042,4.226-7.4c1.285-2.684,3.614-3.428,4.226-6.34.34-1.615-.378-2.62,0-4.226C178.277,191.258,182.823,188.056,182.823,188.056Z",
      translate: { x: "188.232", y: "182.295" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-25",
      name: "2M",
      fill: "#001755",
      translate: { x: "382.678", y: "375.037" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "O.EAST",
      fill: "#001755",
      translate: { x: "361.673", y: "363.012" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ISEYIN",
    path: {
      id: "Path_7438",
      d: "M181.531,148.367c13.461,2.048,34.868,0,34.868,0a65.521,65.521,0,0,0,2.113,11.623c1.458,4.376,4.467,6.025,5.283,10.566a23.16,23.16,0,0,1,0,7.4c-.64,6.492-3.694,9.522-5.283,15.849-1.025,4.081.169,7.417,0,11.623-.29,7.2,0,11.623,2.113,16.906.651,1.625,2.031,1.841,3.17,3.17,3.6,4.2,7.026,7.187,6.34,12.679-.554,4.429-2.306,7.6-6.34,9.51-3.356,1.589-5.8,0-9.51,0h-4.226c-2.889,0-6.34,2.113-6.34,2.113s-3.263-5.171-5.283-6.34c-3.929-2.274-7.384,1.623-11.623,0-4.1-1.57-4.516-5.463-8.453-7.4-2.7-1.325-4.457-2.734-7.4-2.113-3.426.725-5.129,3.054-6.34,6.34-1,2.711,1.329,4.833,0,7.4-1.691,3.261-4.77,3.7-8.4,4.218l-.053.008c-5.167.738-5.351-4.051-10.566-4.226-4.205-.142-7.4,2.113-12.679,2.113-4.385,0-6.288,4.21-7.4,8.453-.625,2.4,1.485,4.36,0,6.34-1.107,1.477-2.455,1.6-4.226,2.113-2.773.809-4.552-.5-7.4,0-5.645,1-12.679,7.4-12.679,7.4s-14.507-10.11-16.906-20.076c-1.255-5.215,1.583-8.611,0-13.736C82,232.761,70.587,225.5,70.587,225.5s14.909,1.359,24.3,0c5.449-.788,9.332.133,13.736-3.17,12.679-9.51,24.3-28.529,24.3-28.529s6.756,1.585,10.566,0c7.591-3.157,4.681-12.022,6.34-20.076,1.591-7.722-2.652-13.8,2.113-20.076,4.816-6.348,11.05-7.422,19.019-7.4C175.172,146.267,177.37,147.736,181.531,148.367Z",
      translate: { x: "78.58", y: "162.813" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-26",
      name: "2M",
      fill: "#001755",
      translate: { x: "251.658", y: "378.667" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "ISEYIN-2",
      name: "ISEYIN",
      fill: "#001755",
      translate: { x: "245.318", y: "360.245" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "KAJOLA",
    path: {
      id: "Path_7439",
      d: "M132.882,195.622c.23,5.1-3.17,12.679-3.17,12.679l-16.9,20.076s-8.817-.7-13.736-3.17c-7.839-3.939-10.112-9.486-14.793-16.906-4.336-6.874-10.052-11.05-8.453-19.019.726-3.618-.971-4.892,0-8.453,1.3-4.778,1.518-6.91,0-11.623-1.02-3.166,0-8.453,0-8.453s4.406-8.056,5.283-13.736c.629-4.079-1.7-6.8,0-10.566,3.583-7.933,22.189-2.113,22.189-2.113s13.108,4.034,17.962,10.566c3.892,5.237,7.421,9.685,5.283,15.849-1.53,4.41-8.453,8.453-8.453,8.453a106.542,106.542,0,0,1,8.453,11.623C129.683,186.273,132.6,189.343,132.882,195.622Z",
      translate: { x: "83.907", y: "147.257" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-27",
      name: "2M",
      fill: "#001755",
      translate: { x: "168.37", y: "355.172" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "KAJOLA-2",
      name: "KAJOLA",
      fill: "#001755",
      translate: { x: "162.903", y: "327.547" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "AFIJIO",
    path: {
      id: "Path_7440",
      d: "M252.088,245.434s6.473-4.9,7.4-9.509c3.17-15.849,1.057-38.038,1.057-38.038s-4.3-1.773-5.283-4.226c-2.113-5.283-3.17-16.906-3.17-16.906s-4.89,6.6-10.566,7.4c-3.7.518-5.8-.414-9.51,0-7.963.888-13.736,7.4-19.019,11.623-3.6,2.882-11.623,2.113-11.623,2.113s-16.83-8.5-28.529-10.566c-11.378-2.01-29.585,0-29.585,0s9.974,9.345,9.51,16.906c-.184,3-.123,5.146-2.113,7.4-2.79,3.151-10.566,2.113-10.566,2.113s1.969,15.56,8.453,22.189c4.9,5.015,8.966,7.1,15.849,8.453,6.086,1.2,15.849-1.057,15.849-1.057s8.791-4.493,15.849-4.226c8.9.338,13.3,5.9,22.189,5.283,6.9-.478,9.991-5.454,16.906-5.283C242.232,239.267,252.088,245.434,252.088,245.434Z",
      translate: { x: "155.948", y: "196.767" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-28",
      name: "2M",
      fill: "#001755",
      translate: { x: "340.413", y: "429.385" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "AFIJIO-2",
      name: "AFIJIO",
      fill: "#001755",
      translate: { x: "331.96", y: "410.963" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OGO OLUWA",
    path: {
      id: "Path_7441",
      dataName: "OGO OLUWA",
      d: "M251.951,154.254l52.831,51.774s-.161,8.062-3.17,10.566a8.7,8.7,0,0,1-7.4,2.113c-5.57-.691-6.363-6.414-8.453-11.623-1.568-3.905-.116-6.862-2.113-10.566-3.1-5.744-7.231-9-13.736-9.509-3.793-.3-6.3.063-9.51,2.113-3.762,2.4-3.275,6.264-6.34,9.509-4.087,4.326-10.539,2.9-12.679,8.453-1.336,3.466,1.874,6.3,0,9.51a8.891,8.891,0,0,1-5.283,4.226c-2.711,1-4.535-.393-7.4,0-2.975.408-4.463,2.76-7.4,2.113-3.715-.818-2.971-5.63-6.34-7.4-3.309-1.735-6.234-2.857-9.509-1.057-3.235,1.777-4.226,8.453-4.226,8.453s-5.513-1.8-6.34-6.34l-2.113-11.623c-.826-4.539,0-20.076,0-20.076s9.205-6.6,12.679-12.679c2.589-4.531,4.226-12.679,4.226-12.679s8.335,7.4,14.793,7.4c6.34,0,11.623,0,17.962-2.113C247.707,163.064,251.951,154.254,251.951,154.254Z",
      translate: { x: "214.199", y: "171.719" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-29",
      name: "2M",
      fill: "#001755",
      translate: { x: "424.758", y: "374.421" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "OGO_OLUWA-2",
      name: "OGO OLUWA",
      fill: "#001755",
      translate: { x: "420.716", y: "353.905" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Oyo South",
    path: {
      id: "Path_7442",
      dataName: "Oyo South",
      d: "M228.616,147.254l14.793,14.793s-3.1,5.894-6.34,8.453c-5.025,3.971-9.448,3.992-15.849,4.226-5.425.2-8.924.4-13.736-2.113-3.373-1.76-7.4-6.34-7.4-6.34s7.7-10.207,14.793-13.736A136.473,136.473,0,0,1,228.616,147.254Z",
      translate: { x: "222.742", y: "163.927" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-30",
      name: "2M",
      fill: "#001755",
      translate: { x: "439.735", y: "332.773" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "O.S",
      fill: "#001755",
      translate: { x: "422.829", y: "322.207" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Ibadan_south west",
    path: {
      id: "Path_7443",
      dataName: "Ibadan south west",
      d: "M156.5,256.566,158.613,246l12.679,2.113,4.226,3.17s-1.183,4.662-2.113,7.4a22.882,22.882,0,0,0-1.057,6.34H159.67s.344-4.256-1.057-6.34A12.237,12.237,0,0,0,156.5,256.566Z",
      translate: { x: "174.22", y: "273.854" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-31",
      name: "2M",
      fill: "#001755",
      translate: { x: "334.759", y: "530.511" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "S.W",
      fill: "#001755",
      translate: { x: "317.473", y: "535.533" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Ibadan South East",
    path: {
      id: "Path_7444",
      dataName: "Ibadan South East",
      d: "M181.719,249.918s.736,5.557-1.164,8.151a13.769,13.769,0,0,1-5.822,4.658c-4.241,1.875-11.645,1.164-11.645,1.164s2.315-5.422,3.493-9.316a60.126,60.126,0,0,1,2.329-5.822Z",
      translate: { x: "180.683", y: "276.212" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-32",
      name: "2M",
      fill: "#001755",
      translate: { x: "348.259", y: "534.646" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "S.E",
      fill: "#001755",
      translate: { x: "351.39", y: "545.956" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "Ibadan_North East",
    path: {
      id: "Path_7445",
      dataName: "Ibadan North East",
      d: "M182.406,246.226c.313,2.492-.526,6.509-2.113,8.453a41.687,41.687,0,0,1-3.17,3.17H171.84l-6.34-1.057s4.706-3.65,7.4-6.34,7.4-8.453,7.4-8.453A9.141,9.141,0,0,1,182.406,246.226Z",
      translate: { x: "184.239", y: "269.4" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2M-33",
      name: "2M",
      fill: "#001755",
      translate: { x: "355.715", y: "523.511" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "N.E",
      fill: "#001755",
      translate: { x: "363.39", y: "530.818" },
      tspan: { X: "0", y: "0" },
    },
  },
];
