export const data = [
  {
    state: "OJU",
    path: {
      id: "Path_7013",
      d: "M255.5,512.5l-6.5-9-7-8-7-5-9-5-8-5-6-4-3-9v-2l4-3,1-1,2-2,6-4,2-1,3-1,8,3,5,1,5,1,6,1,5,1h22l5-1,11.5-4,5.5-4,5-3.5,7-5,2-2.5,3-4,4-5.5.5-1,1-2.5,1-2.5.5-1.5V419l1.5-4,1.5-3.5,4-5,7.5,7,4.5,4,3,3,1.5,1L351,424l2,1.5,3.5,2.5,5.5,4,2,.5,5.5,2,1.5,2,6,7.5,10,11.5,6,6,3,3,4.5,4,.5,1,1,2,1,2.5-9,5.5,2,5,4,7,4,7,1.5,6,.5,5-5,6h-4l-3,1-2,1-1,2-.5,3-2,3-21-1-19-1H332l-10.5,3-10,7L304,542l-5.5,7.5-4,7.5-5.5,5-4,1.5-2.5-.5-5-7L273,545.5l-2-5.5-2-5-4.5-8.5L260,521Z",
      translate: { x: "370.5", y: "664" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OJU-2",
      name: "OJU",
      fill: "#001755",
      translate: { x: "661.5", y: "1143.091" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M",
      name: "3M",
      fill: "#001755",
      translate: { x: "664.5", y: "1160.091" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OBI",
    path: {
      id: "Path_7014",
      d: "M283,375.5l-15-6-3-1.5-1.5-2.5H263l-4.5,2-1,.5-.5.5-1.5,1.5-16,31-2.5,5-1,.5-5,3-9,2-6,1-5.5,1.5-5.5,1.5L188,414l1,5,1.5,1.5L193,423l3.5,3.5,1.5,2,3.5,8.5,2,5.5,1,4L206,452l1.5,7,1.5,6.5,4-3,3-3,7.5-5,3.5-1,7.5,3,22,4H278l5-1,11-3.5,18.5-13.5,9-12,2.5-7V419l1-3,2-4.5,4.5-5.5-6.5-5.5L313.5,391l-6.5-5-10-4.5Z",
      translate: { x: "370.5", y: "664" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OBI-2",
      name: "OBI",
      fill: "#001755",
      translate: { x: "622.5", y: "1085.091" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M",
      name: "4M",
      fill: "#001755",
      translate: { x: "622.5", y: "1101.091" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ADO",
    path: {
      id: "Path_7015",
      d: "M113,494.5,108.5,484l3-.5H131l.5-.5,1.5-1.5,2-2,1-1.5v-2.5l-3.5-3.5-2.5-3.5-4-6.5V442.5l.5-1.5.5-1.5.5-1.5.5-1.5.5-1.5.5-1.5,1-1,1-1,2-2,2-2,3-3,2-1.5,2-1.5,2-1.5,4.5-3,8.5-4.5,2-.5,2.5-.5,3-.5,3.359-.5H185l3,3.5,1,5,9,9,5.5,14.5L207,456l2,11.5,3,9,9,6,9,5.5,7,3.5,5,4,7,8-2.5,1-6.5,1L229.5,513,209,532l-5.5,2-4.5-1-5-2.5h-7L176,532l-5,1.5-4,3-2,4-1,6-2,14L160,574l-2,12.5-2,8-3,4H139l-7-1-8-4-9-8-7-8-3-5-2-4-1-4v-9l1-3.5,1-3.5,1-3.5,4-7.5,4-8,2-6.5,1-4.5v-10l-1-8Z",
      translate: { x: "370.5", y: "664" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ADO-2",
      name: "ADO",
      fill: "#001755",
      translate: { x: "517.5", y: "1144.091" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-2",
      name: "4M",
      fill: "#001755",
      translate: { x: "521.5", y: "1160.091" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OGBADIBO",
    path: {
      id: "Path_7016",
      d: "M40,375.5l6-1v2l3,3,3,4,1,1.5,2,2.5,1,2,1,2,.5,2,.5,3,4,4,10,6,8,9,1,8,3,6,2,3,2,4,1,2v8l-.5,12-.5,9H84.5L64,483l-6.5,2.5H46l-6-.5-6.5-3-8-6.5-5-7.5L14,459.5l-5-6-3.5-5-2.5-4-2-6-1-3,1-2,1-2,1.5-2,8-7,10.5-9,7.5-5,1-2-.5-2L28,401l-6-6-4-4-1.5-3.5.5-3,4-3,3-1,3-1,3-1,4-1Z",
      translate: { x: "370.5", y: "664" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OGBADIBO-2",
      name: "OGBADIBO",
      fill: "#001755",
      translate: { x: "379.5", y: "1103.091" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "396.5", y: "1120.091" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OKPOKWU",
    path: {
      id: "Path_7017",
      d: "M81,347.5l4-7,6,6,5,3.5,2,1.5,1.5,1,2.5,1,7,3,5,2,9.5,4.5,12,5.5,1-.5,1-.5,2-.5.5,3.5,7,6,13,11L176,402l9,8.5H167l-5,1-5,1-11,6-8,6-9,9-3,9V462l4,6.5,3,4,2,2,1,1v3l-5,5H111l-2.5.5-2-3L98,473l-6-4.5-4-1v-1l1-17v-11l-2-4-4-7-2-4-1-8-2.652-2.68L72,406.5l-7-4-3-2-2-2-1-1-1-1-1-5-2-4-3-4-3-4-3-3-.5-2,2-1,2-1,11.5-5,5-3,7-5,3-3,2-2,1-2,1-2Z",
      translate: { x: "370.5", y: "664" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OKPOKWU-2",
      name: "OKPOKWU",
      fill: "#001755",
      translate: { x: "437.5", y: "1053.091" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-2",
      name: "3M",
      fill: "#001755",
      translate: { x: "458.5", y: "1069.091" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OHIMINI",
    path: {
      id: "Path_7018",
      d: "M84,333l4.5-7,16.5-.5h5l9.5-.5H127l6.5-.5h12l1.5-1,5-3,4-2.5,6-6,1-.5,8-3.5,2-.5,6-1h6.5l6.5,7,2,4,2,5,2,9V347l-2,7-8.5,6.5-2,.5-8,2-1-.5-6.5-3h-6.5l-4,3.5L140,366.5l-4.5,2-19.5-9-16.5-7-9-6.5L85,340.5l-1.5-4Z",
      translate: { x: "370.5", y: "664" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OHIMINI-2",
      name: "OHIMINI",
      fill: "#001755",
      translate: { x: "483.5", y: "1006.091" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M",
      name: "2M",
      fill: "#001755",
      translate: { x: "505.5", y: "1023.091" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "GWER EAST",
    path: {
      id: "Path_7019",
      d: "M222,276.5,209.5,284l5,10,3.5,7.5,5.5,10.5,8,16,9.5,10,16,15.5,4,8.5,4.5,6.5L287,377l21,9.5L331.5,406l4-4.5c4.5-5.167,13.5-15.8,13.5-17v-18L347.5,338l1.5-17.5,10-9.5,1.5-2.5,17-21,12-16,5-6,5.5-13,6-14.5,4-9.5,2-4L424,213l4-9,2-4,1.5-6.5L430,187l-2-5v-5.5l3.5-2,2-3.5-3.5-5-6-5-9,2.5-12.5.5L387,166l-9,5-10,8-11,2h-9l-8-4.5-6.5-8.5L300,179l-4,4.5-1,3.5-1.5,11-6.5,9-9,4.5-9,17L258,249l-16,17Z",
      translate: { x: "370.5", y: "664" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "GWER-2",
      name: "GWER",
      fill: "#001755",
      translate: { x: "665.5", y: "919.091" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M",
      name: "5M",
      fill: "#001755",
      translate: { x: "666.5", y: "944.091" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "KONSHISHA",
    path: {
      id: "Path_7020",
      d: "M383.5,280.5l5.5-9V277l1.5,8,2,9.5,2,12L398,316l3.5,9,4,9,7.5,9,10.5,10L438,364l10,10,27,25.5v7L492.5,430l7,10,3,10v7.5L494,466l-4.5,11-7,14c-3.333-2.167-10.1-6.6-10.5-7-.5-.5-12.5-8.5-13-8.5-.4,0-9.167-3.667-13.5-5.5h-8l-17,2.5-11,3-6-1.5-3-5.5L390,459l-12-13.5-8.5-11L362,432l-16.5-12L331,406.5,348.5,386V361l-1-20,.5-20,2-2,9-8,7.5-10.5Z",
      translate: { x: "370.5", y: "664" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "KONSHISHA-2",
      name: "KONSHISHA",
      fill: "#001755",
      translate: { x: "724.5", y: "1068.091" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-3",
      name: "4M",
      fill: "#001755",
      translate: { x: "756.5", y: "1083.091" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "KWANDE",
    path: {
      id: "Path_7021",
      d: "M597,526c-1.2,0-14.5-1-21-1.5L565.5,514l-3.5-5V498.5l.5-2L569,477l1.5-6.5.5-5.5,5-26.5,4-8v-10l2.5-6.5,1.5-3.5,2.5-2.5,2-1h4l2.5,3.5,3,3.5,4.5,3.5h8L624,415l10-6.5,8-6,5-6.5,6-6,5-5.5,9.5-10.5,7,9,9.5,10.5,5.5,12.5h.5l9.5-.5,8.5-3,5.5-3.5,2.5-3c1.167-1.833,3.6-5.6,4-6s4.5-6.5,6.5-9.5c2.333-1.833,7-5.6,7-6s3-2.833,4.5-4l5.5-6.5,3-4,7.5-8,4-3,8.5-4,4-2,1-.5L784,340l6.5-.5H795l4.5,1.5,1.5,1,3,2.5,2.5,3.5,5.5,8-11,1.5H788.5a45.477,45.477,0,0,0-6.5,2l-5,7.5-4.5,13L770,400.5,768,415l-10.5,20-14,13.5,1,24,5,26.5v35.5l-3,8.5-4.5,6v29.5l-5,3.5-7-6-7.5,1.5-6.5,5-2,5.5v18.5l-7.5,6.5-10,5c-3.833,1.833-11.7,5.5-12.5,5.5s-5.333.667-7.5,1L674,623l-4-7-1.5-25.5c-.167-1.5-.5-4.6-.5-5s-.333-2.167-.5-3l-4-3-7-3L648,571l-4-6.5L640,554l-8-11.5-8.5-11-8-3.5C609.833,527.333,598.2,526,597,526Z",
      translate: { x: "370.5", y: "664" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "KWANDE-2",
      name: "KWANDE",
      fill: "#001755",
      translate: { x: "972.5", y: "1145.091" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-2",
      name: "5M",
      fill: "#001755",
      translate: { x: "1000.5", y: "1160.091" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "VANDEIKYA",
    path: {
      id: "Path_7022",
      d: "M475,406.5V399l.5,1,5.5-2h69.5l4.5-1h5.5l1.5,1,2,1,5,1.5,7.5,1.5,6,5H588l-1.5,1-3.5,4.5-3,8V431l-4,7.5-2,10c-1,5-3,15.6-3,18s-1.667,9-2.5,12l-6.5,20V509l3.5,5L576,524.5l-5,1.5-11,6.5-10.5,7c-2.5.167-8.1.8-10.5,2s-16.333-2.5-23-4.5l-13.5-11-5-17-7-10.5L482,491l1.5-2.5,7-13.5,3.5-8.5,8.5-9V450l-3-10-14-19.5Z",
      translate: { x: "370.5", y: "664" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "VANDEIKYA-2",
      name: "VANDEIKYA",
      fill: "#001755",
      translate: { x: "863.5", y: "1091.091" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4.5M",
      name: "4.5M",
      fill: "#001755",
      translate: { x: "883.5", y: "1107.091" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "USHONGO",
    path: {
      id: "Path_7023",
      d: "M475.5,400l-38-36,34-22,.5-15.5.5-1,10.5-10,9-3h3l4.5.5,7,.5,11.5,7,2,5.5,2,5,2,2,2,2.5,1.5-.5,4,10.5L540,354l10,5.5,13.5,2.5,9,1.5,2.5-1,10-3,14-10,.5-1c.5-.667,1.6-2.1,2-2.5a2.375,2.375,0,0,1,1.5-.5c1.333-.333,4.1-1,4.5-1s8.833-3.333,13-5v-9l21.5,23,5.5,6L652,363l7,5,8,5.5L658.5,384c-3,3.5-9.4,10.7-11,11.5s-4.667,5.667-6,8l-18.5,12c-4.333,1-14.1,2.9-18.5,2.5s-9.833-7.5-12-11H582l-6-5c-4.333-.667-13.2-2.4-14-4s-8.333-.667-12,0H481Z",
      translate: { x: "370.5", y: "664" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "USHONGO-2",
      name: "USHONGO",
      fill: "#001755",
      translate: { x: "873.5", y: "1040.091" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-3",
      name: "3M",
      fill: "#001755",
      translate: { x: "899.5", y: "1054.091" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "GBOKO",
    path: {
      id: "Path_7024",
      d: "M478,226.5l2.5-3.5,4.5,2.5,7.5,4H503l17,3.5,17,8.5,10,10v17l20,6.5,2.5,1.5,3,3-20,22-10,11.5-7,10-8,12-1.5.5c-.667-1-2.2-3.1-3-3.5s-1.667-2.5-2-3.5l-3-8c-3.667-2.333-11.2-7-12-7h-2l-12-1-9,3L472,326l-.5,16.5L445,359c-2.833,2-8.5,5.9-8.5,5.5S428.833,358,425,355l-6.5-6.5-7-7-6.5-8-3.5-8.5-3-8-4-11L392,292.5l-2.5-13-1-7.5,5.5-5.5,5.5-13,6-14,2-7,2-4.5,5.5-7,8-8,4.5,9,22,8H452l17,1,2.5-1,5.5-2Z",
      translate: { x: "370.5", y: "664" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "GBOKO-2",
      name: "GBOKO",
      fill: "#001755",
      translate: { x: "792.5", y: "961.091" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.5M",
      name: "3.5M",
      fill: "#001755",
      translate: { x: "795.5", y: "979.091" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "BURUKU",
    path: {
      id: "Path_7025",
      d: "M492.5,229.5l-12-6V207l-1.5-4.5-2-10v-11l1-5,2.5-7.5,3-4-1.5-4,9.5,1,10,6,1,6,5.5,14.5,6.5,4L523,194l2.5-5.5L528,183v2.5l3,5,5.5,3.5,6.5,1,6,4,11,9,10.5,9,5.5,8.5,9,4,7.5,2.5,4-2.5,8,2.5,11,5,5,6.5L623,249v13.5L618,274v35l2.5,20.5V340c-5,1.833-15.5,5.5-17.5,5.5s-3.5,2.667-4,4l-6.5,4.5-7.5,5.5c-3.167.833-9.9,2.7-11.5,3.5s-7.667-.333-10.5-1l-13.5-2.5L540,354l-8.5-8.5-4-10.5L542,313.5l30.5-34L569,276l-22-7.5v-17l-10-10L519.5,233l-17-3.5Z",
      translate: { x: "370.5", y: "664" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "BURUKU-2",
      name: "BURUKU",
      fill: "#001755",
      translate: { x: "864.5", y: "890.091" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-2",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "917.5", y: "908.091" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "UKUM",
    path: {
      id: "Path_7026",
      d: "M660,96.5l-8.5,4,3,6.5-1,6-1,8.5-2,5.5-3,5.5V136l-1.5,8.5-1,6.5v19.5l1,2.5,3.5,9.5,5,9.5,6,9.5L664,204l1,5,6,10,23.5,35,7.5,9.5L713,253l9.5-6.5,23-11.5,12-6.5,4-4,1-2.5a87.215,87.215,0,0,0-1-9c-.5-2,1.5-2,2.5-2.5.8-.4,4.333-3.167,6-4.5l32-17c1.333-1.167,3.5-3.9,1.5-5.5-2.5-2-14-12.5-16-15s-16-16.5-19-18c-2.4-1.2-13.667-11.833-19-17-4.667-3.833-15-12-19-14s-13-10.833-17-15l-13.5-8-12-3-16.5,1Z",
      translate: { x: "370.5", y: "664" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "UKUM-2",
      name: "UKUM",
      fill: "#001755",
      translate: { x: "1050.5", y: "837.091" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-4",
      name: "4M",
      fill: "#001755",
      translate: { x: "1061.5", y: "853.091" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "LOGO",
    path: {
      id: "Path_7027",
      d: "M562.5,83H554l-10,3.5-13,7-7,6.5-6.5,9.5-5,13.5,3.5,10.5,3,11,5,17.5,3.5,10.5V184l2.5,7,5.5,3,8,1.5,8,6.5,11.5,8.5,8,6.5,4,7,5.5,4.5c2.167.5,6.8,1.7,8,2.5,1.5,1,5,0,6.5,0s4.5.5,6,0a21.481,21.481,0,0,0,2.5-1l25.5-1.5c8-.667,24.4-2,26-2s6.667-.667,9-1l5-1.5,2.5-4-2.5-5c-.4-.8-2.5-4-3.5-5.5L664,204a37.8,37.8,0,0,1-3.5-2c-1.2-.8-2.5-3.333-3-4.5l-6-11-6-15v-25L648,132l4.5-10,2-15.5-2-6.5h-7l-13,6.5-15,6.5c-5.5.5-16.7,1.5-17.5,1.5H587l-4,2-5.5,9.5c-3.5,3.5-10.5,10.6-10.5,11s-8.667,1.5-13,2c-3.667-1-11.4-3.5-13-5.5s-.667-4.5,0-5.5l5.5-10.5,7.5-9c2.833-2.167,8.8-6.9,10-8.5s3.5-5,4.5-6.5A27.444,27.444,0,0,0,567,85C565.8,81.8,563.5,82.333,562.5,83Z",
      translate: { x: "370.5", y: "664" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "LOGO-2",
      name: "LOGO",
      fill: "#001755",
      translate: { x: "932.5", y: "840.091" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-4",
      name: "3M",
      fill: "#001755",
      translate: { x: "943.5", y: "858.091" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "TARKA",
    path: {
      id: "Path_7028",
      d: "M433.5,173l2-4,2.5-6.5,2-5.5,1.5-2.5H454l1-3,3.5-1.5,5,4.5,8.5,4,10,3,1,3-3.5,5.5L477,180v14l3.5,14v14l-3.5,6-9,3-18.5-1-8-3L427,222l-4-9c1.333-1.667,4-5.4,4-7s2-5.333,3-7l1-9c-.833-2.167-2.5-6.7-2.5-7.5v-6Z",
      translate: { x: "370.5", y: "664" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "TARKA-2",
      name: "TARKA",
      fill: "#001755",
      translate: { x: "800.5", y: "857.091" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-2",
      name: "2M",
      fill: "#001755",
      translate: { x: "809.5", y: "871.091" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "GUMA",
    path: {
      id: "Path_7029",
      d: "M355,0l-9.5,1,1,11.5,2,11.5,3.5,5.5,5,7,7,7.5,5,8.5,5.5,13L379,77l3.5,6.5L389,86l6.5,3.5L398,96l1.5,6.5V115l1.5,4,4.5,10,7.5,9,1,5.5,2.5,9,3.5,5,5,4,2,3.5,7.5,6.5L436,168l2-6.5c.667-2.167,2.4-6.5,4-6.5h12l1-3.5,3-1.5,5.5,5,9.5,4,8,2.5h9.5L501,168l2.5,9.5,2,6,2,5,7,4.5,8,1.5c1.5-2.667,4.5-8.6,4.5-11v-12c-1.167-3.833-3.7-11.7-4.5-12.5s-3.667-14.333-5-21l-5-15,4-11.5c2.5-3.5,7.5-10.8,7.5-12,0-1.5,5.5-5,7-5,1.2,0,9.167-5.667,13-8.5l11-3V78.5l-4-5c-8.167-12.333-24.5-37.2-24.5-38,0-1-3.5-8.5-4.5-9.5-.8-.8-3.667-4-5-5.5L503.5,26l-19,10.5c-5.667.667-19.3,1.4-28.5-1s-9.5-4-8.5-4.5L421,26,393,16c-2.667-1.667-8.6-5.3-11-6.5C379,8,361,0,360,0Z",
      translate: { x: "370.5", y: "664" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "GUMA-2",
      name: "GUMA",
      fill: "#001755",
      translate: { x: "782.5", y: "745.091" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M-5",
      name: "4M",
      fill: "#001755",
      translate: { x: "804.5", y: "760.091" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "GWER WEST",
    path: {
      id: "Path_7030",
      d: "M245,111l-8.5-1.5L229,114l-11.5,6.5-4,2.5-8.5,9-12.5,13-8,9-1.5,2-4.5,5.5v3l2,7,4,7.5,4.5,12.5,5.5,23.5,1.5,12.5,5,29.5,4,16.5,4,10.5,2-1,9.5-6,8-3.5,15-8.5,9-9.5,8-11c2.167-4.5,6.7-13.7,7.5-14.5s4.333-8.667,6-12.5l5.5-7.5,7-2.5,4-4,2.5-5,1-7,1-5,1-3,4-4.5,6.5-2,11.5-4,12-3.5c3.833-1.5,11.8-4.5,13-4.5s2.5-2.333,3-3.5l-4.5-4-10-4.5c-2.167-.833-7.4-2.8-11-4a18.8,18.8,0,0,1-7.5-5l-3.5,1.5L300,139l-20-14-13-8.5L255.5,112Z",
      translate: { x: "370.5", y: "664" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "GWER-WEST-2",
      name: "GWER-WEST",
      fill: "#001755",
      translate: { x: "580.5", y: "842.091" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-5",
      name: "3M",
      fill: "#001755",
      translate: { x: "608.5", y: "864.091" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "MAKURDI",
    path: {
      id: "Path_7031",
      d: "M346,2c.4-1.6-3.833.667-6,2L323,15.5,309.5,28,296,42.5l-8,14-2,8,5.5,17,7.5,17,9,22,5.5,17V145l11,5,17,7,4,5-2,3L333,168l7,8.5,8,4.5h9l11-2,6.5-5.5,8-5.5,4.5-2,13-1,15-1.5,9.5-2.5-6-4-3.5-9.5-2.5-10-7-8-5.5-13-1.5-21-4.5-7L382,84l-4.5-10-3-9.5c-1.667-4-5.3-12.5-6.5-14.5-1.5-2.5-7-9-9-11.5S348,24,348,22,345.5,4,346,2Z",
      translate: { x: "370.5", y: "664" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "MAKURDI-2",
      name: "MAKURDI",
      fill: "#001755",
      translate: { x: "671.5", y: "744.091" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4.5M-2",
      name: "4.5M",
      fill: "#001755",
      translate: { x: "687.5", y: "761.091" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "APA",
    path: {
      id: "Path_7032",
      d: "M179,161.5c.4-2,4.167-6.167,6-8H61V163l3,22.5L65,221l1,36,22,20,24.5-10,22-10,16.5-7,10.5-7,6-7,10-12,6-4,11-5-1.5-9.5c-1.667-6.167-5.1-18.7-5.5-19.5-.5-1-5.5-12.5-6.5-14S178.5,164,179,161.5Z",
      translate: { x: "370.5", y: "664" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "APA-2",
      name: "APA",
      fill: "#001755",
      translate: { x: "463.5", y: "864.091" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-6",
      name: "3M",
      fill: "#001755",
      translate: { x: "466.5", y: "879.091" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "AGATU",
    path: {
      id: "Path_7033",
      d: "M87,67,35.5,52l-6,2.5V83L27,91v17.5l2.5,4.5,4,5L49,132l9.5,14.5L61,154H184.5l10-11.5L216,121l21-10.5-2-2L204.5,98l-22-11-18-6.5-22.5-5Z",
      translate: { x: "370.5", y: "664" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "AGATU-2",
      name: "AGATU",
      fill: "#001755",
      translate: { x: "442.5", y: "781.091" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-3",
      name: "2M",
      fill: "#001755",
      translate: { x: "459.5", y: "799.091" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "KATSINA ALA",
    path: {
      id: "Path_7034",
      d: "M818,203c0-2-10-13.5-15-19v4l-4.5,2.5L785,198l-10,5.5-6,2.5-3,3-3.5,2-1,1v1.5l1,4.5v3l-1,2-3,4-7,4L740,238l-16.5,8L713,253.5l-11,10c-8-11.333-24.2-34.2-25-35s-4.333-6.333-6-9l-2.5,4.5-9.5,2-12,1-16.5,1.5H618L602,231l12.5,6,9,10.5V262L618,273.5V310l3,21,11,12c4.833,5.167,14.8,15.8,16,17s13.167,9.5,19,13.5l5.5,7.5L684,393.5l5.5,12.5H699c4.333-1.333,13.6-4.9,16-8.5L726,381c4.833-4,15.1-13,17.5-17,3-5,12.5-14.5,14.5-15,1.6-.4,9-4.167,12.5-6,5.333-1.5,17.6-4.3,24-3.5a14.561,14.561,0,0,1,11,7L812,356c.667-2.5,2.1-7.9,2.5-9.5s1.833-9.333,2.5-13c1.5-7.833,4.5-23.9,4.5-25.5,0-2,.5-13.5,0-17-.4-2.8,1.833-15.167,3-21l3-12,5.5-16V231c-2.167-4-6.3-12.5-5.5-14.5C828.5,214,818,205.5,818,203Z",
      translate: { x: "370.5", y: "664" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "KATSHINA-ALA-2",
      name: "KATSHINA-ALA",
      fill: "#001755",
      translate: { x: "1009.5", y: "969.091" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5.5M",
      name: "5.5M",
      fill: "#001755",
      translate: { x: "1053.5", y: "988.091" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OTURKPO",
    path: {
      id: "Path_7035",
      d: "M95,283.5,88,277l21-8.5,6.5-3L130,259l12.5-5.5L151,250l1.5-1,9.5-6.5L177.5,224l6-4,6.5-3,4.5-2,1.5,13,2,11.5,3.5,19.5,3.5,14.5,1.5,4,3,6.5L223,311l8.5,17,1.5,1.5L249.5,346l7.5,7.5,1,2,5.5,10-6,2.5-2,2L237,406l-5.5,3.5-15,3-12,3-16-1.5L167,393.5,139.5,370v-3.5l20-3.5,4-3.5H170l7.5,3.5,10-2.5L196,354l2-7V332l-2-10-4-8.5-6.5-7H179l-8,1.5-9,4-6,6-10.5,6.5-57,1.5,2.5-5,2.5-6,3-4.5L104,304l5.5-7,1-4.5v-10Z",
      translate: { x: "370.5", y: "664" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OTUKPO-2",
      name: "OTUKPO",
      fill: "#001755",
      translate: { x: "499.5", y: "947.091" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.5M-2",
      name: "3.5M",
      fill: "#001755",
      translate: { x: "510.5", y: "967.091" },
      tspan: { X: "0", y: "0" },
    },
  },
];
