export const data = [
  {
    state: "UMUAHIA_SOUTH",
    path: {
      id: "Path_6967",
      dataName: "UMUAHIA SOUTH",
      d: "M125.314,280.135a73.781,73.781,0,0,0,5.771-8.188s9.575-3.249,15.215-1.781c4.565,1.189,7.995,2.536,9.968,6.821.959,2.081.633,3.519,1.049,5.771.986,5.335-.044,8.917,2.623,13.641a22.55,22.55,0,0,0,4.2,4.929h37.775l-2.623,17.107s.738,8.209-1.574,12.592c-3.022,5.731-13.641,9.444-13.641,9.444s-6.3-3.148-12.067-6.3-14.69-5.771-14.69-5.771-13.162.925-21.511,0c-11.164-1.237-18.712-4.9-27.807-7.345,0,0,6-6.168,8.394-11.018,2.444-4.945,3.056-8.158,3.673-13.641.457-4.072-1.693-6.761,0-10.493C121.325,283.133,123.295,282.416,125.314,280.135Z",
      translate: { x: "658.829", y: "630.096" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "UMUAHIA_SOUTH-2",
      name: "UMUAHIA SOUTH",
      fill: "#001755",
      translate: { x: "703.667", y: "931.932" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.2M",
      name: "3.2M",
      fill: "#001755",
      translate: { x: "804.604", y: "946.727" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IKWUANO",
    path: {
      id: "Path_6968",
      d: "M244.107,317.049c4.513,7.129,10.493,18.888,10.493,18.888s-5.247,13.116-5.247,20.986c0,2.672,1.044,6.249,3.673,5.771,5.771-1.049,7.957-4.842,13.641-6.3,2.219-.568,3.591-1.751,5.771-1.049,2.337.752,3.143,2.471,4.182,4.689l.016.033c.8,1.71,1.944,3.059,1.049,4.722-3.673,6.821-3.242,10.938-7.87,16.264a28.9,28.9,0,0,1-12.067,8.919c-6.459,2.736-17.838,2.1-17.838,2.1l-18.363-1.049s-2.967-.559-5.771-1.049c-3.576-.625-6.12-1.461-9.444,0-3.281,1.442-7.05,1.648-7.87,4.722l-2.1,7.87c-.82,3.073,4.722,5.771,4.722,5.771h-5.771s.569-1.771,0-2.623c-.508-.763-1.215-.808-2.1-1.049-1.186-.323-1.918,0-3.148,0-1.024,0-1.678.393-2.623,0-1.364-.569-2.1-3.148-2.1-3.148s-1.329-7.537,1.574-10.493c1.605-1.634,3.022-2.079,5.247-2.623,2.388-.584,3.977.818,6.3,0,2.2-.778,4.611-1.339,4.722-3.673.079-1.65-1.056-2.391-2.1-3.673-2.488-3.057-5.151-3.567-8.919-4.722-3.937-1.208-10.493-1.049-10.493-1.049L178,365.842l8.919-28.331,8.919-8.919s-.3-7.045,0-11.542c.423-6.42-.549-10.667,2.623-16.264,2.428-4.285,4.134-7.749,8.919-8.919a10.126,10.126,0,0,1,5.771,0c2.307.619,3.4,1.628,5.247,3.148a32.183,32.183,0,0,1,4.2,4.722s3.417-2.551,5.771-2.1c2.013.386,2.64,1.816,4.2,3.148C238.483,305.848,239.941,310.468,244.107,317.049Z",
      translate: { x: "662.28", y: "631.164" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "IKWUANO-2",
      name: "IKWUANO",
      fill: "#001755",
      translate: { x: "854.421", y: "989.385" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.2M-2",
      name: "3.2M",
      fill: "#001755",
      translate: { x: "880.154", y: "970.861" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ISIALA NGWA NORTH",
    path: {
      id: "Path_6969",
      dataName: "ISIALA-NGWA NORTH",
      d: "M185.628,338.258l5.771-3.148-8.395,29.905,3.673,8.919a29.539,29.539,0,0,1,5.771,0c3.271.507,7.168,1.384,9.968,3.148,3.432,2.163,7.306,3.851,5.247,7.345-.981,1.665-2.389,1.94-4.2,2.623-3.258,1.231-5.934-1.794-8.919,0-2.832,1.7-3.673,2.623-4.2,11.542s8.919,1.574,9.968,5.771a1.5,1.5,0,0,1,0,2.1c-2.1,2.1-5.633.683-8.919,2.1-3.654,1.574-8.395,5.771-8.395,5.771a16.767,16.767,0,0,1-3.673-2.623c-2.441-2.688-2.574-5.289-2.623-8.919-.076-5.6,5.214-7.548,5.771-13.116.525-5.247-.912-7.052-5.771-9.968-6.872-4.124-16.159-3.42-24.134-2.623-9.413.94-13.876,5.176-23.085,7.345-14.448,3.4-20.986,2.623-37.775,4.2-6.137.575-15.215-4.2-15.215-4.2a81,81,0,0,1,2.623-12.592c2.109-6.157,7.87-14.69,7.87-14.69a47.114,47.114,0,0,1,1.574-6.821c3.148-8.395,3.148-12.067,7.345-19.412,3.529-6.175,13.641-12.067,13.641-12.067s17.734,5.669,28.856,6.821c8.356.865,20.986,0,20.986,0l14.69,6.3Z",
      translate: { x: "657.275", y: "632.516" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ISIALA-NGWA_NORTH-2",
      name: "ISIALA-NGWA NORTH",
      fill: "#001755",
      translate: { x: "621.497", y: "982.385" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.2M-3",
      name: "3.2M",
      fill: "#001755",
      translate: { x: "752.83", y: "992.057" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ISIALA NGWA SOUTH",
    path: {
      id: "Path_6970",
      dataName: "ISIALA-NGWA SOUTH",
      d: "M178.844,427.406c6.821-7.345,5.247-14.69,5.247-14.69s-5.946-3.9-6.821-7.87c-.717-3.257.19-5.36,1.574-8.395.919-2.017,2.176-2.73,3.148-4.722,1.395-2.858,2.477-4.711,2.1-7.87-.752-6.267-7.2-7.247-13.116-9.444-9.443-3.507-15.852.019-25.708,2.1-8.875,1.872-13.2,5.3-22.036,7.345-11.639,2.694-16.789-1.049-30.43,2.1-5.872,1.355-14.69-3.673-14.69-3.673s-3.893,5.678-5.247,10.493c-1.109,3.945-2.1,10.493-2.1,10.493s-7.872,11.09-9.968,18.888c-1.543,5.738-.887,7.3-2.1,13.116-.939,4.508-4.2,11.018-4.2,11.018s6.165.831,9.968,0c3.5-.764,5.85-1.157,8.395-3.673,2.281-2.255,1.4-5.086,3.673-7.345,2.407-2.389,5.143-1.656,7.87-3.673,4.889-3.616,8.395-13.116,8.395-13.116a52.834,52.834,0,0,0,4.722-3.148c5.872-4.672,3.651-14.315,11.018-15.74l.1-.019a10.027,10.027,0,0,1,5.149.019c2.623.525,2.73,6.907,3.673,11.542,2.083,10.248-.7,18.188-1.049,26.757,0,0-5.53,16.232,0,23.61,4.013,5.353,9.968,6.3,15.215,6.3,9.675,0,14.42-5.764,22.56-12.067C165.3,447.129,168.995,438.013,178.844,427.406Z",
      translate: { x: "656.19", y: "635.183" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ISIALA-NGWA_SOUTH-2",
      name: "ISIALA-NGWA SOUTH",
      fill: "#001755",
      translate: { x: "718.99", y: "1028.853" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.2M-4",
      name: "3.2M",
      fill: "#001755",
      translate: { x: "786.164", y: "1053.756" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OSISIOMA ngwa",
    path: {
      id: "Path_6971",
      d: "M113.934,454.514c0,4.322,5.771,9.444,5.771,9.444s-.414,4.808-2.623,6.3a9.809,9.809,0,0,1-3.148,1.049l-7.345,5.771s2.739,3.134,2.1,5.247a7.256,7.256,0,0,1-2.1,2.623s-3.673-4.265-6.821-5.771c-5.36-2.563-9.634-2.04-15.215,0-2.411.881-3.346,2.306-5.771,3.148-4.839,1.68-13.116,0-13.116,0l2.1-5.247s-1.2-3.548-2.1-5.771c-1.441-3.57-1.891-5.837-4.2-8.919-2.437-3.256-5.52-3.5-7.87-6.821a34.181,34.181,0,0,1-2.1-3.673l3.148-6.821a29.566,29.566,0,0,0,14.69-1.049c2.907-.909,3.148-2.623,4.722-5.247l3.148-5.247s2.6-1.2,4.2-2.1c2.577-1.446,4.172-2.143,6.3-4.2,3.233-3.127,5.247-10.493,5.247-10.493s4.794-1.887,6.821-4.2c3.212-3.661,2.79-9.994,4.722-11.542s2.8-2.741,5.247-3.148c3.148-.525,5.837.923,6.3,3.673,2.1,12.592,2.1,16.789,2.1,16.789.377,8.432-2.1,20.462-2.1,20.462S113.934,448.218,113.934,454.514Z",
      translate: { x: "656.043", y: "636.406" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OSISIOMA-2",
      name: "OSISIOMA",
      fill: "#001755",
      translate: { x: "651.997", y: "1099.986" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.2M-5",
      name: "3.2M",
      fill: "#001755",
      translate: { x: "738.497", y: "1092.581" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "oboma ngwa",
    path: {
      id: "Path_6972",
      d: "M198.438,402.846h5.771s2.5,3.635,2.623,6.3c.525,11.018,0,18.363,0,27.807,0,1.449-2.623,2.623-2.623,2.623s1.917,18.068,0,29.381a137.551,137.551,0,0,1-4.2,15.74,115.7,115.7,0,0,1,4.2,14.69c1.746,9.887,0,13.641,0,25.708,0,1.3-3.148,1.049-3.148,1.049v3.673l3.148,6.3a37.182,37.182,0,0,1-4.2,5.247,51.123,51.123,0,0,1-4.2,3.148c-2.97,2.336-7.345,6.3-7.345,6.3a13.541,13.541,0,0,0-4.2-2.623c-2.6-.7-4.219.345-6.821,1.049a48.584,48.584,0,0,0-8.395,3.673,27.04,27.04,0,0,0-7.345,1.574c-3.818,1.76-4.569,4.742-7.87,7.345-6.265,4.942-17.838,9.968-17.838,9.968a36.037,36.037,0,0,0-1.049-7.345c-1.378-4.393-3.446-6.353-6.3-9.969-3.857-4.894-5.86-8.459-11.542-11.018-3.262-1.469-8.919-2.1-8.919-2.1s1.374-3.646,3.148-5.247c1.838-1.658,5.771-2.623,5.771-2.623s5.605-7.846,7.345-13.641c1.484-4.941,2.929-8.138,1.574-13.116-1.453-5.342-5.077-7.032-8.919-11.018C112.473,490.895,104,484.692,104,484.692s2.312-1.664,1.574-3.673c-.354-.961-1.574-3.673-1.574-3.673s4.325-3.724,7.345-5.771c1.6-1.085,3-1.109,4.2-2.623,1.329-1.676,1.574-5.247,1.574-5.247a42.379,42.379,0,0,0,8.919,2.623c4.924.558,8.468,1.175,12.592-1.574,9.444-6.3,13.948-10.313,21.511-18.363,6.4-6.811,9.044-11.442,14.69-18.888,2.476-3.265,5.187-4.45,6.3-8.395.832-2.959,0-7.87,0-7.87l8.919-7.345Z",
      translate: { x: "658.632", y: "636.657" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OBINGWA-2",
      name: "OBINGWA",
      fill: "#001755",
      translate: { x: "798.667", y: "1121.892" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.2M-6",
      name: "3.2M",
      fill: "#001755",
      translate: { x: "812.667", y: "1138.646" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ABA_SOUTH",
    path: {
      id: "Path_6973",
      dataName: "ABA SOUTH",
      d: "M121.672,503.117c2.768,2.377,5.771,7.345,5.771,7.345a73.543,73.543,0,0,1-3.673,9.444,94.188,94.188,0,0,1-5.247,8.395,29.91,29.91,0,0,0-5.247,3.148A17.684,17.684,0,0,0,109.6,536.7s-4.111,1.447-6.821,2.1c-5.412,1.3-8.645,2.284-14.166,1.574-4.5-.579-8.484.089-11.018-3.673-1.416-2.1-1.184-3.791-1.574-6.3a27.345,27.345,0,0,1,0-11.018c.414-2.1.489-3.4,1.574-5.247,1.7-2.9,6.3-6.3,6.3-6.3s7.415.787,11.018,0c3.761-.822,5.308-.275,7.87-3.148,2.077-2.329,3.673-7.345,3.673-7.345s5.815.466,9.444,2.1A20.6,20.6,0,0,1,121.672,503.117Z",
      translate: { x: "657.225", y: "641.317" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ABA_SOUTH-2",
      name: "ABA SOUTH",
      fill: "#001755",
      translate: { x: "741.646", y: "1158.688" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.2M-7",
      name: "3.2M",
      fill: "#001755",
      translate: { x: "741.646", y: "1168.953" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "UKWA_EAST",
    path: {
      id: "Path_6974",
      dataName: "UKWA EAST",
      d: "M185.178,541.362a18.4,18.4,0,0,1,4.722,2.623s-8.822,5.535-13.116,10.493c-5.009,5.784-6.8,10.431-9.392,17.179l-.051.134c-.823,2.138-.047,3.76,1.049,5.771,1.182,2.166,2.855,2.583,4.722,4.2,2.422,2.092,4.659,2.5,6.3,5.247,2.514,4.226.98,7.773,0,12.592-1.278,6.285-7.147,8.279-7.345,14.69-.116,3.776,2.1,9.444,2.1,9.444s8.892,6.922,12.067,13.116c2.7,5.262,2.03,9.009,3.673,14.69.773,2.677,2.431,4.053,2.1,6.82-.241,2-.081,4.722-2.1,4.722-3.673,0-7.225-3.187-10.493-6.821-2.822-3.136-5.247-9.444-5.247-9.444L151.075,631.6s-4.963-2.469-8.395-2.623c-4.984-.225-7.093,3.8-12.067,4.2A21.592,21.592,0,0,1,119.6,631.6c-4.772-1.86-5.675-6.13-10.493-7.87-5.589-2.018-9.817-2.485-15.215,0a28.525,28.525,0,0,0-4.722,3.148v-8.394s-3.148-1.023-3.148-2.623a64.236,64.236,0,0,1,1.574-11.018s-10.134-5.689-12.067-12.067c-1.188-3.921,0-10.493,0-10.493s27.437-4.078,44.071-9.968c7.1-2.515,17.838-7.345,17.838-7.345s12.082-5.129,18.363-10.493c2.644-2.258,3.263-4.595,6.3-6.3,2.774-1.556,7.87-2.1,7.87-2.1s2.507-1.391,4.2-2.1C178.24,542.277,180.919,540.168,185.178,541.362Z",
      translate: { x: "657.201", y: "643.471" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "UKWA_EAST-2",
      name: "UKWA EAST",
      fill: "#001755",
      translate: { x: "760.129", y: "1234.238" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.2M-8",
      name: "3.2M",
      fill: "#001755",
      translate: { x: "784.667", y: "1255.889" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ABA_NORTH",
    path: {
      id: "Path_6975",
      dataName: "ABA NORTH",
      d: "M128.772,502.732c2.136,3.5,0,8.919,0,8.919s-6.2-8.908-10.493-11.018c-3.75-1.844-10.493-2.1-10.493-2.1s-1.14,4.682-3.148,6.82c-2.2,2.338-4.346,2.536-7.345,3.673-5.638,2.136-10.721-1.4-15.215,2.623-2.937,2.627-4.722,8.919-4.722,8.919s-2.377,1.889-4.2,2.1c-2.382.275-5.771-2.1-5.771-2.1L48.5,505.355s4.363-3.753,6.3-6.82c1.664-2.64,1.814-4.524,3.148-7.345,2.509-5.307,7.345-13.116,7.345-13.116s7.7,2.992,12.067,1.049c4.722-2.1,8.919-4.722,15.215-5.771,5.72-.954,9.984,4.789,14.166,7.87C116.705,488.566,123,493.288,128.772,502.732Z",
      translate: { x: "655.895", y: "640.129" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ABA_NORTH-2",
      name: "ABA NORTH",
      fill: "#001755",
      translate: { x: "640.997", y: "1142.097" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.2M-9",
      name: "3.2M",
      fill: "#001755",
      translate: { x: "727.646", y: "1140.186" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "UKWA_WEST",
    path: {
      id: "Path_6977",
      dataName: "UKWA WEST",
      d: "M47.744,512.241c0-3.5,3.148-8.395,3.148-8.395l17.838,14.69v5.771s2.118,5.371,3.148,8.919c1.4,4.824,2.586,7.569,2.623,12.592.048,6.559-3.831,9.715-4.2,16.264-.311,5.557-.669,9.071,1.574,14.166,1.767,4.014,6.821,8.919,6.821,8.919s-.861,5.232,0,8.394c1.12,4.113,3.248,5.938,6.3,8.919a47.251,47.251,0,0,0,6.3,4.722s-3.373,6.761-1.574,10.493a14.9,14.9,0,0,0,3.148,3.673v7.345s-5.745-3.877-9.968-4.722c-3.416-.683-4.2,0-8.919,0-4.549,0-8.08,2.531-12.067,4.722-5.508,3.027-6.373,4.86-12.592,5.771-7.525,1.1-12.494,3.16-19.412,0-6.722-3.07-13.641-13.116-13.641-13.116s-4.929.893-7.87,0C4.226,620.1,0,614.024,0,614.024L3.673,609.3a31.817,31.817,0,0,1,0-6.82c.864-5.062,7.87-14.166,7.87-14.166s-1.973-6.645,0-12.067a48.146,48.146,0,0,1,3.148-6.3s-2.673-4.131-3.148-6.3c-.836-3.809,1.35-5.2,4.618-7.279l.1-.066c5.771-3.673,13.869-6.475,20.462-12.067C43.15,538.8,49.318,528.5,49.318,528.5A134.754,134.754,0,0,1,47.744,512.241Z",
      translate: { x: "653.503", y: "641.639" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "UKWA_WEST-2",
      name: "UKWA WEST",
      fill: "#001755",
      translate: { x: "665.198", y: "1242.519" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.2M-10",
      name: "3.2M",
      fill: "#001755",
      translate: { x: "685.936", y: "1258.626" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OHAFIA",
    path: {
      id: "Path_6978",
      d: "M394.059,266.272l9.968-7.345-4.2-13.641s.655-8.358,1.574-13.641c1.27-7.295,3.914-11,4.722-18.363.783-7.131,3.043-12.139-.525-18.363-1.659-2.894-3.434-3.916-5.771-6.3-2.945-3-7.87-7.345-7.87-7.345s-21.1-9.219-30.43-19.937c-4.663-5.359-7.257-8.649-9.968-15.215s-.592-11.428-3.673-17.838c-3.273-6.81-6.169-10.71-12.592-14.69a27.545,27.545,0,0,0-14.69-4.722c-6.256-.388-15.74,3.148-15.74,3.148H281.782s3.466,23.383,2.1,38.3c-1.406,15.341-2.452,24.573-9.444,38.3-5.766,11.321-19.937,25.708-19.937,25.708s7.863,5.52,13.641,5.771c4.866.211,7.837-.733,12.067-3.148,4.278-2.442,8.919-8.919,8.919-8.919s12.956,8.067,19.412,15.215c6.784,7.511,10.031,12.58,13.641,22.036,2.553,6.686,1.714,11.127,4.2,17.838,3.732,10.084,12.9,12.932,14.166,23.61a25.187,25.187,0,0,1,0,7.87,40.87,40.87,0,0,1-1.574,5.771,36.483,36.483,0,0,0,3.673,2.1,12.307,12.307,0,0,0,4.722,1.574c4.873.378,6.511-3.987,10.493-6.821,5.345-3.8,13.116-10.493,13.116-10.493s5.835.76,9.444,0c4.753-1,7.975-1.985,11.018-5.771C395.086,276.42,394.059,266.272,394.059,266.272Z",
      translate: { x: "666.053", y: "622.16" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OHAFIA-2",
      name: "OHAFIA",
      fill: "#001755",
      translate: { x: "989.283", y: "809.267" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.2M-11",
      name: "3.2M",
      fill: "#001755",
      translate: { x: "995.719", y: "835.739" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "AROCHUKWU",
    path: {
      id: "Path_6979",
      d: "M394.937,260.984l8.919-5.771s3.591,7.641,5.771,12.592c5.859,13.3,13.641,34.627,13.641,34.627s8.137,10.734,12.067,18.363l.151.294c3.445,6.687,5.487,10.651,7.194,18.069,2.527,10.982,0,12.592,0,25.708,0,4.423-6.6,5.612-11.018,5.771-7.512.271-11.3-3.208-18.363-5.771-3.72-1.349-5.925-1.862-9.444-3.673-5.771-2.97-21.511-22.036-21.511-22.036s-27.94-13.411-46.17-24.134c-17.838-10.493-11.018-30.43-20.986-40.923-4.3-4.526-10.493-2.623-10.493-2.623H293.153s-7.123.886-11.542,0c-3.88-.778-5.5-3.933-9.444-3.673-3.384.222-5.416,1.332-7.87,3.673-3.194,3.046-2.623,10.493-2.623,10.493s-4.96-7.146-7.345-11.542c-2.722-5.017-6.092-6.931-6.821-12.592a48.256,48.256,0,0,1,0-7.87,100.9,100.9,0,0,1,8.395-7.345c3.328-2.389,6-1.4,8.394-4.722,1.934-2.686,3.229-4.56,3.148-7.87-.077-3.12-4.114-4.176-5.771-6.821a66.206,66.206,0,0,0-8.919-10.493l3.148-2.1s6.142,2.061,9.968,3.148c5.88,1.671,8.207,1.748,13.641-1.049,4.41-2.27,9.968-7.87,9.968-7.87s11.412,5.955,17.314,11.542c7.387,6.994,11.066,12.006,14.69,21.511,2.821,7.4,3.673,13.641,5.247,19.937s5.141,8.314,9.444,13.641c0,0,4.9,7.611,5.247,13.116.3,4.813-2.623,12.067-2.623,12.067a25.88,25.88,0,0,0,5.247,2.1c2.605.551,4.3.855,6.821,0,2.758-.935,5.771-4.722,5.771-4.722l15.215-12.067s7.916,1.524,12.592,0c4.87-1.587,6.1-3.289,8.395-7.87S394.937,260.984,394.937,260.984Z",
      translate: { x: "665.699", y: "626.894" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "AROCHUKWU-2",
      name: "AROCHUKWU",
      fill: "#001755",
      translate: { x: "1011.268", y: "937.23" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.2M-12",
      name: "3.2M",
      fill: "#001755",
      translate: { x: "1058.268", y: "960.727" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "UMUAHIA NORTH",
    path: {
      id: "Path_6980",
      dataName: "UMUAHIA NORTH",
      d: "M149.56,196.093c3.372.962,8.919-1.049,8.919-1.049s8.664,20.222,13.116,33.578c4.2,12.592,15.215,22.56,15.215,22.56s14.527,4.24,22.036,9.968c5.185,3.955,10.823,5.585,11.542,12.067,1.049,9.444,0,26.757,0,26.757s-2.593-2.531-4.722-3.148c-1.967-.571-3.608-1.23-5.247,0a110.478,110.478,0,0,0-9.444,8.395H164.251c-3.866,0-6.821-8.919-6.821-8.919a66.563,66.563,0,0,1-1.574-12.592c0-3.936-3.045-5.651-6.3-7.87-6.261-4.274-19.412,0-19.412,0s5.261-8.548,5.771-14.69c.5-6.047-2.372-9.2-3.148-15.215-1.31-10.16-1.156-16.053,0-26.233.445-3.916,1.574-9.968,1.574-9.968L127,196.093s6.4,1.052,9.968-.525c2.663-1.178,5.771-4.722,5.771-4.722S146.328,195.17,149.56,196.093Z",
      translate: { x: "659.766", y: "626.203" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "UMUAHIA_NORTH-2",
      name: "UMUAHIA NORTH",
      fill: "#001755",
      translate: { x: "721.146", y: "870.842" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.2M-13",
      name: "3.2M",
      fill: "#001755",
      translate: { x: "831.886", y: "904.754" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ISUIKWATO",
    path: {
      id: "Path_6981",
      d: "M205.142,85.846l3.673,11.542a27.71,27.71,0,0,0-3.673,4.2c-2.35,3.747-2.623,11.018-2.623,11.018a17.029,17.029,0,0,1,2.623,3.148c1.982,3.587.686,6.453,0,10.493-1.014,5.968-3.281,8.181-4.2,14.166-.528,3.443,0,11.018,0,11.018a28.774,28.774,0,0,1,7.87,8.395,29.2,29.2,0,0,1,3.673,15.215,9.282,9.282,0,0,1-3.673,6.3s2.707,1.149,3.673,2.623c2.168,3.311-.9,6.616-3.673,9.444a20.28,20.28,0,0,1-3.673,2.623h-14.69s-6.42,4.434-11.018,4.2c-2.783-.144-4.061-1.71-6.821-2.1a21.387,21.387,0,0,0-6.3,0c-3.161.36-4.72,1.655-7.87,2.1-3.652.514-5.992,1.3-9.444,0-2.609-.981-5.771-4.2-5.771-4.2s-3.839,3.253-6.821,4.2c-3.711,1.175-6.157.793-9.968,0-3.951-.823-9.444-4.2-9.444-4.2V183.957s4.6.829,7.345,0a11.158,11.158,0,0,0,4.722-2.623c3.912-3.4,1.431-8.5,2.1-13.641a43.588,43.588,0,0,1,2.1-7.87,28.218,28.218,0,0,1-4.2-6.3c-1.876-4.766,0-13.116,0-13.116s-2.948-3.226-2.623-4.2c2.623-7.87,0-28.856,0-28.856a6.449,6.449,0,0,1-2.1,0c-3.073-.82,2.39-4.7,2.1-7.87C125.939,94,117,88.994,117,88.994l9.444-3.148L153.2,93.191s10.774-.4,22.56,0c11.7.4,9.747,2.62,20.462-2.1A69.384,69.384,0,0,0,205.142,85.846Z",
      translate: { x: "659.272", y: "621.025" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ISUIKWUATO-2",
      name: "ISUIKWUATO",
      fill: "#001755",
      translate: { x: "791.667", y: "755.646" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.2M-14",
      name: "3.2M",
      fill: "#001755",
      translate: { x: "805.693", y: "780.55" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "BENDE",
    path: {
      id: "Path_6982",
      d: "M262.431,109.438c9.148,1.544,23.61,2.623,23.61,2.623s2.1,21.815,2.1,25.708v9.968s1.509,12.615,0,20.462c-1.681,8.745-8.919,20.986-8.919,20.986l-22.036,29.381c4.508,5.327,8.084,8.838,12.592,14.166S268.2,242.7,268.2,242.7a43.115,43.115,0,0,0-9.968,7.345c-3.839,3.816-9.484,7.15-6.821,12.067s1.534,6.1,4.2,11.018c3.688,4.1,6.8,10.593,10.493,14.69,0,0,8.395,10.493,8.395,22.036,0,4.766.34,9.869-3.148,13.116-2.084,1.939-6.3,6.821-6.3,6.821l-9.444,15.74s-3.148-11.018-23.085-36.726c-2.134-2.752-8.919,0-8.919,0l-4.2-4.2a171.715,171.715,0,0,0,0-24.659c-.56-5.883-8.394-12.592-8.394-12.592s-7.039-6.508-24.659-11.542C175.338,252.669,157.5,200.2,157.5,200.2s6.921-2.723,11.542-2.623c4.223.091,6.289,3.025,10.493,2.623,4.289-.409,9.968-4.722,9.968-4.722s9.444,2.623,14.69,0,4.157-3.682,6.821-8.395-4.2-6.821-4.2-6.821,3.766-2.658,4.2-5.247c1.049-6.3-1.212-9.525-4.2-14.69-2.469-4.272-8.395-9.444-8.395-9.444s1.3-11.559,2.623-18.888c1.044-5.773,5.045-9.138,3.148-14.69a22.967,22.967,0,0,0-3.148-5.247V106.29c.8-4.248,5.771-9.444,5.771-9.444l16.789,7.345a138.051,138.051,0,0,1,15.74,0C248.555,105.014,253.315,107.9,262.431,109.438Z",
      translate: { x: "661.27", y: "621.568" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "BENDE-2",
      name: "BENDE",
      fill: "#001755",
      translate: { x: "887.796", y: "792.583" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.2M-15",
      name: "3.2M",
      fill: "#001755",
      translate: { x: "881.421", y: "825.618" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "umu neochi",
    path: {
      id: "Path_6983",
      d: "M213.6,69.617c-1.784,9.752-7.87,24.134-7.87,24.134s-2.727,5.887-8.919,7.345c-7.119,1.676-17.9-.607-25.184,0-12.537,1.045-16.264,0-30.955-3.148a89.022,89.022,0,0,1-12.067-4.2l-8.395,4.2a64.693,64.693,0,0,0-6.821-8.919c-7.154-7.1-13.539-8.308-23.085-11.542C81.593,74.534,76.2,74.8,67.223,72.765c-5.137-1.166-9.718.877-13.116-3.148-3.7-4.383-2.286-9.428,0-14.69,1.454-3.346,5.771-7.345,5.771-7.345a35.024,35.024,0,0,1,0-4.722c1-9.419,7.752-13.056,15.215-18.888,9.054-7.075,16.179-8.059,27.282-11.018,8.235-2.195,21.511-3.673,21.511-3.673L128.608.887l7.87,4.2s8.478-3.226,14.166-4.2c8.079-1.38,12.848-.968,20.986,0,7.3.869,11.5,1.542,18.363,4.2,10.335,4,23.61,15.74,23.61,15.74l6.821,3.148s-4.852,6.691-6.821,11.542C208.6,47.856,216,56.517,213.6,69.617Z",
      translate: { x: "656.059", y: "616.792" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "NNOCHI-2",
      name: "NNOCHI",
      fill: "#001755",
      translate: { x: "784.224", y: "656.172" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.2M-16",
      name: "3.2M",
      fill: "#001755",
      translate: { x: "798.667", y: "681.075" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "UGWUNAGBO",
    path: {
      id: "Path_6976",
      d: "M135.907,557.72c3.673,8.919,1.574,7.87,1.574,7.87a156.81,156.81,0,0,1-15.215,6.821,190.407,190.407,0,0,1-21.511,6.3c-9.768,2.347-25.183,5.247-25.183,5.247s-8.513-7.319-8.919-15.215c-.273-5.313.91-10.03,2.1-15.215a49.55,49.55,0,0,0,1.574-7.87c.482-5.546-.128-8.791-1.574-14.166-1.028-3.822-3.246-5.51-3.673-9.444a30.472,30.472,0,0,1,0-4.2s3.413,3.167,6.3,2.623c1.623-.306,5.693-2.623,5.693-2.623s-1.446,8.941,0,13.641c.808,2.627.589,4.537,2.7,6.3,2.564,2.133,4.028,1.227,7.345,1.574a37.787,37.787,0,0,0,11.018,0c4.679-.692,11.543-3.673,11.543-3.673l9.444,2.1,6.3,4.722A48.152,48.152,0,0,1,135.907,557.72Z",
      translate: { x: "656.708", y: "642.328" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_3.2M-17",
      name: "3.2M",
      fill: "#001755",
      translate: { x: "738.497", y: "1212.307" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "UGWUNAGBO-2",
      name: "UGWUNAGBO",
      fill: "#001755",
      translate: { x: "704.83", y: "1196.008" },
      tspan: { X: "0", y: "0" },
    },
  },
];
