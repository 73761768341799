import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

function VerticalBar({ data }) {
  console.log(data);
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            className: "chartcontainer",
            backgroundColor: "transparent",
            height: 300,
            title: {
              text: null,
            },
            type: "bar",
          },
          title: {
            text: "Zonal consumption",
            style: {
              fontFamily: "Segoe UI",
              color: "#001755",
              fontSize: "14px",
              fontWeight: "510",
            },
            useHTML: true,
          },
          series: [
            {
              // type: "bar",
              // name: "<p style='color:dodgerblue;font-weight:600;font-size: 15px;'>Zonal consumption</p>",
              data: data.map((item) => item.volume),
              color: "dodgerblue",
              fillColor: "dodgerblue",
              marker: {
                enabled: false,
                symbol: "circle",
                radius: 0,
                states: {
                  hover: {
                    enabled: true,
                  },
                },
              },
            },
          ],
          yAxis: {
            gridLineDashStyle: "solid",
            gridLineColor: "#eee",
            title: {
              text: "",
            },
          },
          xAxis: {
            categories: [
              "South West",
              "South South",
              "North Central",
              "North West",
              "North East",
              "South East",
            ],
          },
          credits: {
            enabled: false,
          },
          noData: {
            style: {
              fontWeight: "bold",
              fontSize: "15px",
              color: "#303030",
            },
          },
        }}
      />
    </div>
  );
}

export default VerticalBar;

//,
