import { numFormtter } from "../../../../helperFunctions/numFormatter";
import { data as nigerData } from "./data";

const Niger = ({ data, type }) => {
  console.log(
    data
      .filter((item) => item.product === "PMS")
      .map((item) => item.lga.replaceAll("-", " ").trim().toLowerCase())
      .sort(),
    nigerData
      .map((item) => item.state.replaceAll("_", " ").trim().toLowerCase())
      .sort()
  );
  let test = [];
  nigerData
    .map((item) => {
      return {
        state: item.state
          .replaceAll("_", " ")
          .replaceAll("-", " ")
          .trim()
          .toLowerCase(),
        path: {
          id: item.path.id,
          d: item.path.d,
          translate: {
            x: item.path.translate.x,
            y: item.path.translate.y,
          },
          fill: "#fa5252",
          strokeLinejoin: "round",
          strokeLinecap: "round",
          strokeWidth: "0.5",
          stroke: "#000",
        },
        textValue: {
          id: item.textValue.id,
          name: item.textValue.name,
          fill: "#001755",
          translate: {
            x: item.textValue.translate.x,
            y: item.textValue.translate.y,
          },
          tspan: { x: item.textValue.tspan.X, y: item.textValue.tspan.y },
        },
        textState: {
          id: item.textState.id,
          name: item.textState.name,
          fill: "#001755",
          translate: {
            x: item.textState.translate.x,
            y: item.textState.translate.y,
          },
          tspan: { X: item.textState.tspan.X, y: item.textState.tspan.y },
        },
      };
    })
    .forEach((item) => {
      data
        .filter((item) => item.product === type)
        .map((item) => {
          return {
            product: item.product,
            amount: item.amount,
            volume: item.volume,
            state: item.state,
            lga: item.lga.replaceAll("-", " ").trim().toLowerCase(),
            threasholdStatus: item.threasholdStatus,
          };
        })
        .forEach((e) => {
          if (item.state === e.lga) {
            test.push({
              ...item,
              product: e.product,
              volume: e.volume,
              amount: e.amount,
              threasholdStatus: e.threasholdStatus,
            });
          }
        });
    });
  return (
    <div
      className="map py-3 "
      style={{
        width: "100%",
        height: "500px",
        marginTop: "20px",
      }}
    >
      <div className="ml-cover d-flex justify-content-start align-items-center    ">
        {Array.from(Array(3)).map((item, i) => (
          <div
            className="m-legend d-flex justify-content-between align-items-center"
            key={i}
          >
            <div
              className="ml-i p-2 mx-2 rounded-1"
              style={{
                background:
                  i === 0
                    ? "#FFC078"
                    : i === 1
                    ? "#82C91E"
                    : i === 2
                    ? "#FA5252"
                    : "#000000",
              }}
            ></div>
            <div
              className="ml-t"
              style={{
                color: "#707070",
              }}
            >
              {i === 0
                ? "Below threshold"
                : i === 1
                ? " WithIn threshold"
                : i === 2
                ? "Above threshold"
                : "none"}
            </div>
          </div>
        ))}
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 836.5 628.758"
      >
        <g
          id="NIGER_STATE_MAP"
          data-name="NIGER STATE MAP"
          transform="translate(-408.5 -637.505)"
        >
          {test.map((item, i) => (
            <a href={`/nigeria/niger/${item.state}`} key={i}>
              <g id={item.state}>
                <path
                  id={`${item.path.id}`}
                  data-name="Path 6660"
                  d={item.path.d}
                  transform={`translate(${item.path.translate.x} ${item.path.translate.y})`}
                  fill={
                    item.threasholdStatus === 0
                      ? "#FFC078"
                      : item.threasholdStatus === 1
                      ? "#82C91E"
                      : item.threasholdStatus === 2
                      ? "#FA5252"
                      : item.state === "lagos"
                      ? "#fff"
                      : "#eee"
                  }
                  stroke="#000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="0.5"
                />
                <text
                  id={item.textValue.id}
                  data-name={item.textValue.name}
                  transform={`translate(${item.textValue.translate.x} ${item.textValue.translate.y})`}
                  fill={
                    item.state.toLowerCase() === "lagos"
                      ? "green"
                      : item.state.toLowerCase() === "ogun"
                      ? "green"
                      : item.state.toLowerCase() === "enugu"
                      ? "green"
                      : "#fff"
                  }
                  fontSize="14"
                  fontFamily="QuietSans-SemiBold, Quiet Sans"
                  fontWeight="600"
                >
                  <tspan x={item.textValue.tspan.x} y={item.textValue.tspan.y}>
                    {numFormtter(item.volume)}
                  </tspan>
                </text>
                <text
                  id={item.textState.id}
                  data-name={item.textState.name}
                  transform={`translate(${item.textState.translate.x} ${item.textState.translate.y})`}
                  fill={item.textState.fill}
                  fontSize="12"
                  fontFamily="QuietSans-SemiBold, Quiet Sans"
                  fontWeight="600"
                >
                  <tspan x={item.textState.tspan.x} y={item.textState.tspan.y}>
                    {item.state[0].toUpperCase() + item.state.substring(1)}
                  </tspan>
                </text>
              </g>
            </a>
          ))}
        </g>
      </svg>
    </div>
  );
};

export default Niger;
