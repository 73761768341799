import { useState, useEffect, useRef } from "react";
import Layout from "../../component/Layout";
import StateMap from "../../component/Maps/StateMap";
import TopCard from "../../component/TopCards";
import TopNav from "../../component/TopNav";
import "./style.css";
import StateImg from "../../assets/svg/stateimg.svg";
import Buildimg from "../../assets/svg/buildimg.svg";
import { color } from "../../constant/color";
import axios from "../../utils/axios";
import StatePMS from "../../component/StateProduct/PMS";
import StateAGO from "../../component/StateProduct/AGO";
import StateDPK from "../../component/StateProduct/DPK";
import StateLPG from "../../component/StateProduct/LPG";
import LoaderIcon from "../../component/LoaderIcon";
import { nigeria } from "../../helperFunctions/mapdata";
import { numFormtter } from "../../helperFunctions/numFormatter";
import { chart, numberFormat } from "highcharts";
import { BiPlus, BiMinus } from "react-icons/bi";
import ScaleBtn from "../../component/ScaleBtn";
import { BsArrowsFullscreen } from "react-icons/bs";
import { PanZoom } from "react-easy-panzoom";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import moment from "moment";
import toast from "react-hot-toast";
import ThreasholdLegend from "../../component/ThreasholdLegend";
import { percentageDiffCalc } from "../../helperFunctions/percentageDiffCalc";
import ZonalCard from "../../component/ZonalCard";
import LineCharts from "../../component/Charts/LineChart";
import BarCharts from "../../component/Charts/BarChart";
import VerticalBar from "../../component/Charts/VerticalBar";
import DoughnutChart from "../../component/Charts/DoughtnutChart";
import { Button } from "../../component/Button";
import { Link } from "react-router-dom";
import { Select } from "antd";
import Skimmer from "../../component/Skimmer";

const Dashboard = () => {
  const [tabIndex, setTabIndex] = useState(1);
  const [data, setData] = useState([]);
  const [scaleNum, setScaleNum] = useState(1);
  const [date, setDate] = useState(moment(Date.now()).format("YYYY-MM-DD"));
  const [fsMode, setFsMode] = useState(false);
  const Option = Select;
  // Loader
  const [loading, setLoading] = useState(false);
  // Chart instance
  const [dataLoading, setDataLoading] = useState({
    map: true,
    zonal: true,
    chart: true,
    stats: true,
  });
  const [loadingChart, setLoadingChart] = useState(true);
  const [loadingMap, setLoadingMap] = useState(true);

  const [pmsData, setPmsData] = useState([]);
  const [lpgData, setLpgData] = useState([]);
  const [dpkData, setDpkData] = useState([]);
  const [agoData, setAgoData] = useState([]);

  // Map instance
  const [pmsMap, setPmsMap] = useState([]);
  // const [lpgMap, setLpgMap] = useState([]);
  // const [dpkMap, setDpkMap] = useState([]);
  // const [agoMap, setAgoMap] = useState([]);

  // Price Instance
  const [pmsVol, setPmsVol] = useState("");
  const [agoVol, setAgoVol] = useState("");
  const [dpkVol, setDpkVol] = useState("");
  const [lpgVol, setLpgVol] = useState("");

  const [topState, setTopState] = useState([]);
  const [topCompany, setTopCompany] = useState([]);
  const [tabLoader, setTabLoader] = useState({
    pms: false,
    ago: false,
    lpg: false,
    dpk: false,
  });

  // state product
  const [pmsState, setPmsState] = useState([]);
  const [agoState, setAgoState] = useState([]);
  const [dpKState, setDpKState] = useState([]);
  const [lpgState, setLpgState] = useState([]);

  // PMS Chart
  const [pmsChart, setPmsChart] = useState([]);
  const [agoChart, setAgoChart] = useState([]);
  const [dpkChart, setDpkChart] = useState([]);
  const [lpgChart, setlpgChart] = useState([]);

  // Percentages
  const [pmsPer, setPmsPer] = useState(0);
  const [agoPer, setAgoPer] = useState(0);
  const [dpkPer, setDpkPer] = useState(0);
  const [lpgPer, setlpgPer] = useState(0);

  // Zones
  const [pmsZone, setPmsZone] = useState([]);
  const [agoZone, setAgoZone] = useState([]);
  const [dpkZone, setDpkZone] = useState([]);
  const [lpgZone, setlpgZone] = useState([]);

  // const [startDate, ]

  const [charttype, setChartType] = useState("agol");
  // refresh states
  const [refresh, setRefresh] = useState(false);

  // Dashboard cards
  useEffect(() => {
    (async () => {
      try {
        setLoadingChart(true);
        let res = await axios.get(
          `/Aggregated/CountrySalesSummary/nigeria?date=${date}`
        );
        //  pms percentage
        let cdvp = parseInt(
          res.data
            .filter((item) => item.product === "PMS")
            .find(
              (item) =>
                moment(item.date).format("YYYY-MM-DD") ===
                moment(date).format("YYYY-MM-DD")
            ).volume
        );
        let pdvp = parseInt(
          res.data
            .filter((item) => item.product === "PMS")
            .find(
              (item) =>
                moment(item.date).format("YYYY-MM-DD") ===
                moment(date).subtract(1, "days").format("YYYY-MM-DD")
            ).volume
        );
        //  ago percentage
        let cdva = parseInt(
          res.data
            .filter((item) => item.product === "AGO")
            .find(
              (item) =>
                moment(item.date).format("YYYY-MM-DD") ===
                moment(date).format("YYYY-MM-DD")
            ).volume
        );
        let pdva = parseInt(
          res.data
            .filter((item) => item.product === "AGO")
            .find(
              (item) =>
                moment(item.date).format("YYYY-MM-DD") ===
                moment(date).subtract(1, "days").format("YYYY-MM-DD")
            ).volume
        );
        //  LPG percentage
        let cdvl = parseInt(
          res.data
            .filter((item) => item.product === "LPG")
            .find(
              (item) =>
                moment(item.date).format("YYYY-MM-DD") ===
                moment(date).format("YYYY-MM-DD")
            ).volume
        );
        let pdvl = parseInt(
          res.data
            .filter((item) => item.product === "LPG")
            .find(
              (item) =>
                moment(item.date).format("YYYY-MM-DD") ===
                moment(date).subtract(1, "days").format("YYYY-MM-DD")
            ).volume
        );

        //  DPK percentage
        let cdvd = parseInt(
          res.data
            .filter((item) => item.product === "DPK")
            .find(
              (item) =>
                moment(item.date).format("YYYY-MM-DD") ===
                moment(date).format("YYYY-MM-DD")
            ).volume
        );
        let pdvd = parseInt(
          res.data
            .filter((item) => item.product === "DPK")
            .find(
              (item) =>
                moment(item.date).format("YYYY-MM-DD") ===
                moment(date).subtract(1, "days").format("YYYY-MM-DD")
            ).volume
        );

        setPmsPer((prev) => percentageDiffCalc(cdvp, pdvp));
        setAgoPer((prev) => percentageDiffCalc(cdva, pdva));
        setlpgPer((prev) => percentageDiffCalc(cdvl, pdvl));
        setDpkPer((prev) => percentageDiffCalc(cdvd, pdvd));

        // PMs chart
        setPmsChart((prev) =>
          res.data
            .filter((item) => item.product === "PMS")
            .map((item) => {
              return {
                volume: item.volume,
                date: moment(item.date).format("YYYY-MM-DD"),
              };
            })
        );
        // Ago chart
        setAgoChart((prev) =>
          res.data
            .filter((item) => item.product === "AGO")
            .map((item) => {
              return {
                volume: item.volume,
                date: moment(item.date).format("YYYY-MM-DD"),
              };
            })
        );

        // DPk chart
        setDpkChart((prev) =>
          res.data
            .filter((item) => item.product === "DPK")
            .map((item) => {
              return {
                volume: item.volume,
                date: moment(item.date).format("YYYY-MM-DD"),
              };
            })
        );

        // LPG chart
        setlpgChart((prev) =>
          res.data
            .filter((item) => item.product === "LPG")
            .map((item) => {
              return {
                volume: item.volume,
                date: moment(item.date).format("YYYY-MM-DD"),
              };
            })
        );

        setPmsVol((prev) =>
          res.data
            .filter((item) => item.product === "PMS")
            .find((item) => moment(item.date).format("YYYY-MM-DD") === date)
            .volume.toLocaleString("en-US", { currency: "USD" })
        );

        setAgoVol((prev) =>
          res.data
            .filter((item) => item.product === "AGO")
            .find((item) => moment(item.date).format("YYYY-MM-DD") === date)
            .volume.toLocaleString("en-US", { currency: "USD" })
        );
        setDpkVol((prev) =>
          res.data
            .filter((item) => item.product === "DPK")
            .find((item) => moment(item.date).format("YYYY-MM-DD") === date)
            .volume.toLocaleString("en-US", { currency: "USD" })
        );
        setLpgVol((prev) =>
          res.data
            .filter((item) => item.product === "LPG")
            .find((item) => moment(item.date).format("YYYY-MM-DD") === date)
            .volume.toLocaleString("en-US", { currency: "USD" })
        );
        setLoadingChart(false);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, [refresh]);
  // map data
  useEffect(() => {
    (async () => {
      try {
        setLoadingMap(true);
        let res = await axios.get(
          `/Aggregated/CountryStateSalesSummary/nigeria?date=${date}`
        );
        // enugu
        let responseData =
          charttype == "agol"
            ? res.data.map((item) => {
                return {
                  amount: item.amount,
                  country: item.country,
                  product: item.product,
                  state: item.state,
                  threasholdStatus: item.volThreasholdStatus,
                  volume: item.volume,
                };
              })
            : res.data;

        let arr = [];
        // PMS
        nigeria.forEach((item, i) => {
          responseData
            .filter((item) => item.product === "PMS")
            .forEach((e) => {
              if (item.state.toLowerCase() === e.state.toLowerCase()) {
                arr.push({
                  ...item,
                  product: e.product,
                  volume: e.volume,
                  amount: e.amount,
                  threasholdStatus: e.threasholdStatus,
                });
              }
            });
        });
        // PMS State
        setPmsState((prev) => arr);
        // console.log(arr, "pmsState", "line 105");

        let arro = [];
        nigeria.forEach((item, i) => {
          responseData
            .filter((item) => item.product === "AGO")
            .forEach((e) => {
              if (item.state.toLowerCase() === e.state.toLowerCase()) {
                arro.push({
                  ...item,
                  product: e.product,
                  volume: e.volume,
                  amount: e.amount,
                  threasholdStatus: e.threasholdStatus,
                });
              }
            });
        });

        setAgoState((prev) => arro);
        // console.log(arro, "ago state", "line 125");
        // DPK
        let arrd = [];
        nigeria.forEach((item, i) => {
          responseData
            .filter((item) => item.product === "DPK")
            .forEach((e) => {
              if (item.state.toLowerCase() === e.state.toLowerCase()) {
                arrd.push({
                  ...item,
                  product: e.product,
                  volume: e.volume,
                  amount: e.amount,
                  threasholdStatus: e.threasholdStatus,
                });
              }
            });
        });
        // Dpk state
        setDpKState((prev) => arrd);
        // console.log(arrd, "dpk state ", "line 145");
        // setDpKState((prev) => arrd);

        // LPG
        let arrl = [];
        nigeria.forEach((item, i) => {
          responseData
            .filter((item) => item.product === "LPG")
            .forEach((e) => {
              if (item.state.toLowerCase() === e.state.toLowerCase()) {
                arrl.push({
                  ...item,
                  product: e.product,
                  volume: e.volume,
                  amount: e.amount,
                  threasholdStatus: e.threasholdStatus,
                });
              }
            });
        });

        setLpgState((prev) => arrl);

        // Map
        setPmsMap((prev) => res.data.filter((item) => item.product === "PMS"));

        setLoading((prev) => false);
        // pms
        setPmsData((prev) =>
          responseData
            .filter((item) => item.product === "PMS")
            .map((item) => item.volume)
        );

        //ago
        setAgoData((prev) =>
          responseData
            .filter((item) => item.product === "AGO")
            .map((item) => item.volume)
        );

        //lpg
        setLpgData((prev) =>
          responseData
            .filter((item) => item.product === "LPG")
            .map((item) => item.volume)
        );

        //dkp
        setDpkData((prev) =>
          responseData
            .filter((item) => item.product === "DPK")
            .map((item) => item.volume)
        );
        setLoading((prev) => false);
        setLoadingMap(false);
      } catch (err) {
        // setLoadingMap(false)
        toast.error(err.message);
      }
    })();
  }, [charttype, refresh]);

  // stats
  useEffect(() => {
    (async () => {
      try {
        setDataLoading({
          stats: true,
        });
        const res = await Promise.all([
          axios.get(
            `/Aggregated/CountryTopStateSalesSummary/nigeria?count=5&date=${date}`
          ),
          axios.get(
            `/Aggregated/CountryTopCompanySalesSummary/nigeria?count=5&date=${date}`
          ),
        ]);
        setTopState((prev) => [...res[0].data]);
        setTopCompany((prev) => [...res[1].data]);
        setDataLoading({
          stats: false,
        });
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, [refresh]);

  // Zonal
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.get(
          `/Aggregated/CountryZoneSalesSummary/Nigeria?date=${date}`
        );
        setPmsZone((prev) => res.data.filter((item) => item.product === "PMS"));
        setAgoZone((prev) => res.data.filter((item) => item.product === "AGO"));
        setDpkZone((prev) => res.data.filter((item) => item.product === "DPK"));
        setlpgZone((prev) => res.data.filter((item) => item.product === "LPG"));
        // console.log(
        //   res.data.filter((item) => item.product === "PMS"),
        //   res.data.filter((item) => item.product === "AGO"),
        //   res.data.filter((item) => item.product === "DPK"),
        //   res.data.filter((item) => item.product === "LPG")
        // );
      } catch (err) {
        toast.error(err.message);
      }
      setLoading((prev) => false);
    })();
  }, [date]);
  const [association, setAssociation] = useState([]);
  const [classification, setClassification] = useState([]);
  // Classification
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.get(
          `/Aggregated/ClassifiedSalesSummary/Nigeria?date=${date}`
        );
        // console.log(res.data, "line 466");
        setClassification((prev) => res.data);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, [date]);
  //association
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.get(
          `/Aggregated/AssocitedSalesSummary/Nigeria?date=${date}`
        );
        // console.log(
        //   res.data
        //     .filter((item) => item.product == "PMS")
        //     .map((item) => item.volume),
        //   "line 479"
        // );
        setAssociation((prev) => res.data);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, [date]);

  const handlePMS = useFullScreenHandle();
  const handleAGO = useFullScreenHandle();
  const handleLPG = useFullScreenHandle();
  const handleDPK = useFullScreenHandle();
  return (
    <Layout>
      <TopNav
        date={date}
        showDate={true}
        setDate={setDate}
        setRefresh={setRefresh}
        loading={loading}
        setLoading={setLoadingChart}
        title={"Downstream Retail Accountability System"}
        caption={"Fuel volume management"}
      />
      <div className="db-c p-2">
        <div>
          <div className="dashbboard-cover">
            <div className="container-fluid ">
              {/* Cards */}
              <div className="row mt-3 d-done">
                <div className="col-sm-3 col-md-3 col-mg-3 col-lg-3 d-tc">
                  <TopCard
                    percentageChange={pmsPer}
                    bgType={"blue"}
                    tag="pms"
                    price={pmsVol}
                    data={pmsChart}
                    loading={loadingChart}
                  />
                </div>
                <div className="col-sm-3 col-md-3 col-mg-3 col-lg-3 d-tc">
                  <TopCard
                    percentageChange={agoPer}
                    bgType={"ago"}
                    tag="ago"
                    price={agoVol}
                    data={agoChart}
                    loading={loadingChart}
                  />
                </div>
                <div className="col-sm-3 col-md-3 col-mg-3 col-lg-3 d-tc">
                  <TopCard
                    percentageChange={dpkPer}
                    bgType={"ago"}
                    tag="dpk"
                    price={dpkVol}
                    data={dpkChart}
                    loading={loadingChart}
                  />
                </div>
                <div className="col-sm-3 col-md-3 col-mg-3 col-lg-3 d-tc">
                  <TopCard
                    percentageChange={lpgPer}
                    bgType={"blue"}
                    tag="lpg"
                    price={lpgVol}
                    data={lpgChart}
                    loading={loadingChart}
                  />
                </div>
              </div>
              {/* maps */}
              <div
                className={`row mt-3 p-0 m-0 ${loadingMap ? "bg-white" : ""}`}
              >
                {loadingMap ? (
                  <div className="p-2">
                    <Skimmer width={"100%"} heigth="40px" />
                  </div>
                ) : (
                  <div className="tab-top bg-white d-flex align-items-center justify-content-between ">
                    <div className="d-flex align-items-center justify-content-start">
                      <div
                        className={
                          tabIndex === 1
                            ? "tab-t active px-1 pb-2 pt-2 mx-3"
                            : "tab-t px-4 pb-2 pt-2 mx-3"
                        }
                        onClick={() => {
                          setTabLoader({ pms: true });
                          setTabIndex((prev) => 1);
                          setTimeout(() => {
                            setTabLoader({ pms: false });
                          }, 2000);
                        }}
                      >
                        PMS
                      </div>
                      <div
                        className={
                          tabIndex === 2
                            ? "tab-t active px-1 pb-2 pt-2 mx-3"
                            : "tab-t px-2 pb-2 pt-2 mx-3"
                        }
                        onClick={() => {
                          setTabLoader({ ago: true });
                          setTabIndex((prev) => 2);
                          setTimeout(() => {
                            setTabLoader({ ago: false });
                          }, 2000);
                        }}
                      >
                        AGO
                      </div>
                      <div
                        className={
                          tabIndex === 3
                            ? "tab-t active px-1 pb-2 pt-2 mx-3"
                            : "tab-t px-2 pb-2 pt-2 mx-3"
                        }
                        onClick={() => {
                          setTabLoader({ dpk: true });
                          setTabIndex((prev) => 3);
                          setTimeout(() => {
                            setTabLoader({ dpk: false });
                          }, 2000);
                        }}
                      >
                        DPK
                      </div>
                      <div
                        className={
                          tabIndex === 4
                            ? "tab-t active px-1 pb-2 pt-2 mx-3"
                            : "tab-t px-2 pb-2 pt-2 mx-3"
                        }
                        onClick={() => {
                          setTabLoader({ lpg: true });
                          setTabIndex((prev) => 4);
                          setTimeout(() => {
                            setTabLoader({ lpg: false });
                          }, 2000);
                        }}
                      >
                        LPG
                      </div>
                    </div>
                    <div className="">
                      <Select
                        placeholder="Select map type"
                        style={{
                          width: "120px",
                        }}
                        value={charttype}
                        defaultValue={"Volumetric"}
                        onChange={(e) => setChartType(e)}
                      >
                        {[
                          { id: 1, value: "vol", title: "Algorithmic" },
                          { id: 2, value: "agol", title: "Volumetric" },
                        ].map(({ title, id, value }) => (
                          <Option key={id} value={value}>
                            {title}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                )}

                {loadingMap ? (
                  <div className="p-2">
                    <Skimmer width={"100%"} heigth="300px" />
                  </div>
                ) : (
                  <div className="tab-body bg-white ">
                    <div
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "15px",
                        cursor: "pointer",
                        zIndex: "20",
                      }}
                      onClick={
                        tabIndex === 1
                          ? handlePMS.enter
                          : tabIndex === 2
                          ? handleAGO.enter
                          : tabIndex === 3
                          ? handleDPK.enter
                          : tabIndex === 4
                          ? handleLPG.enter
                          : null
                      }
                      className="p-1"
                    >
                      <BsArrowsFullscreen size={18} color={color.darkColor} />
                    </div>
                    {/* PMS State */}
                    <div
                      className={
                        tabIndex === 1 ? "tab-content active " : "tab-content"
                      }
                    >
                      <div className="tab-content__map">
                        {tabLoader.pms ? (
                          <div className="mt-5">
                            <LoaderIcon text={"Fetching map data"} />
                          </div>
                        ) : (
                          <FullScreen handle={handlePMS}>
                            <div className="state-map d-flex align-items-center justify-content-center">
                              <PanZoom autoCenter={true}>
                                <StatePMS data={pmsState} />
                                <ThreasholdLegend />
                              </PanZoom>
                            </div>
                          </FullScreen>
                        )}
                      </div>
                    </div>
                    {/* Agos state */}
                    <div
                      className={
                        tabIndex === 2 ? "tab-content active" : "tab-content"
                      }
                    >
                      <div className="tab-content__map">
                        {tabLoader.ago ? (
                          <div className="mt-5">
                            <LoaderIcon text={"Fetching map data"} />
                          </div>
                        ) : (
                          <FullScreen handle={handleAGO}>
                            <div className="state-map d-flex align-items-center justify-content-center">
                              <PanZoom autoCenter={true}>
                                <StatePMS data={agoState} />
                                <ThreasholdLegend />
                              </PanZoom>
                            </div>
                          </FullScreen>
                        )}
                      </div>
                    </div>
                    {/* DPK State */}
                    <div
                      className={
                        tabIndex === 3 ? "tab-content active" : "tab-content"
                      }
                    >
                      <div className="tab-content__map">
                        {tabLoader.dpk ? (
                          <div className="mt-5">
                            <LoaderIcon text={"Fetching map data"} />
                          </div>
                        ) : (
                          <FullScreen handle={handleDPK}>
                            <div className="state-map d-flex align-items-center justify-content-center">
                              <PanZoom autoCenter={true}>
                                <StatePMS data={dpKState} />
                                <ThreasholdLegend />
                              </PanZoom>
                            </div>
                          </FullScreen>
                        )}
                      </div>
                    </div>
                    {/* LPG State */}
                    <div
                      className={
                        tabIndex === 4 ? "tab-content active" : "tab-content"
                      }
                    >
                      <div className="tab-content__map">
                        {tabLoader.lpg ? (
                          <div className="mt-5">
                            <LoaderIcon text={"Fetching map data"} />
                          </div>
                        ) : (
                          <FullScreen handle={handleLPG}>
                            <div className="state-map d-flex align-items-center justify-content-center">
                              <PanZoom autoCenter={true}>
                                <StatePMS data={lpgState} />
                                <ThreasholdLegend />
                              </PanZoom>
                            </div>
                          </FullScreen>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="container-fluid bg-white d-none">
                <div
                  className="text-center"
                  style={{
                    color: "#001755",
                    fontSize: "16px",
                    fontWeight: "510",
                  }}
                >
                  Zonal Volume (Litres)
                </div>
                {tabIndex === 1 && (
                  <div className="row">
                    <ZonalCard data={pmsZone} />
                  </div>
                )}
                {tabIndex === 2 && (
                  <div className="row">
                    <ZonalCard data={agoZone} />
                  </div>
                )}
                {tabIndex === 3 && (
                  <div className="row">
                    <ZonalCard data={dpkZone} />
                  </div>
                )}

                {tabIndex === 4 && (
                  <div className="row">
                    <ZonalCard data={lpgZone} />
                  </div>
                )}
              </div>

              {/* Bar charts */}
              <div className="container-fluid bg-white mt-4 d-none">
                <div className="row align-items-center justify-content-center p-3 dsk-c__c">
                  <div className="chart-t__c ">
                    <div className="chart-t__t">Zonal Consumption</div>
                    <div className="ct-t__c ">
                      Graphical representation showing the zonal consumption
                    </div>
                  </div>
                  <div
                    className="col-sm-12 col-md-6 col-lg-6 col-xl-6  "
                    style={{
                      height: "auto",
                    }}
                  >
                    <BarCharts
                      pmsData={pmsZone.map((item) => item.volume)}
                      agoData={agoZone.map((item) => item.volume)}
                      dpkData={dpkZone.map((item) => item.volume)}
                      lpgData={lpgZone.map((item) => item.volume)}
                    />
                  </div>
                </div>
              </div>

              {/* Association and Classification charts*/}
              <div
                className="container-fluid bg-white mt-4 "
                style={{
                  height: "550px",
                  width: "100%",
                }}
              >
                <div className="row align-items-center justify-content-between">
                  <div
                    className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center justify-content-center dsk-c__c "
                    style={{
                      height: "500px",
                      borderRight: "1px solid #eee",
                    }}
                  >
                    <div className="chart-t__c ">
                      <div className="chart-t__t">Zonal consumption</div>
                      <div className="ct-t__c d-done"></div>
                    </div>
                    <div
                      className="mt-5"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <BarCharts
                        pmsData={pmsZone.map((item) => item.volume)}
                        agoData={agoZone.map((item) => item.volume)}
                        dpkData={dpkZone.map((item) => item.volume)}
                        lpgData={lpgZone.map((item) => item.volume)}
                      />
                    </div>
                  </div>
                  <div
                    className="col-6 mobile"
                    style={{
                      height: "500px",
                      // width: "",
                    }}
                  >
                    <div className="row">
                      <div
                        className="col-sm-12 mt-3 col-md-12 col-lg-12 col-xl-12 d-flex align-items-center justify-content-center dsk-c__c "
                        style={{
                          height: "250px",
                        }}
                      >
                        <div className="chart-t__c ">
                          <div className="chart-t__t">
                            Sales by Classification
                          </div>
                          <div className="ct-t__c d-none">
                            Graphical representation showing the sales by
                            Classification
                          </div>
                        </div>
                        {tabIndex == 1 && (
                          <DoughnutChart
                            title={"Sales by classification"}
                            type={"c"}
                            data={classification
                              .filter((item) => item.product === "PMS")
                              .map((item, index) => item.volume)}
                          />
                        )}
                        {tabIndex == 2 && (
                          <DoughnutChart
                            title={"Sales by classification"}
                            type={"c"}
                            data={classification
                              .filter((item) => item.product === "AGO")
                              .map((item, index) => item.volume)}
                          />
                        )}
                        {tabIndex == 3 && (
                          <DoughnutChart
                            title={"Sales by classification"}
                            type={"c"}
                            data={classification
                              .filter((item) => item.product === "DPK")
                              .map((item, index) => item.volume)}
                          />
                        )}

                        {tabIndex == 4 && (
                          <DoughnutChart
                            title={"Sales by classification"}
                            type={"c"}
                            data={classification
                              .filter((item) => item.product === "LPG")
                              .map((item, index) => item.volume)}
                          />
                        )}
                      </div>
                      <div
                        className="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex align-items-center justify-content-center dsk-c__c "
                        style={{
                          height: "250px",
                          borderRight: "1px solid #eee",
                        }}
                      >
                        <div className="chart-t__c ">
                          <div className="chart-t__t">Sales by Association</div>
                          <div className="ct-t__c d-done"></div>
                        </div>
                        {tabIndex == 1 && (
                          <DoughnutChart
                            cuts={90}
                            title={"Sales by association"}
                            type={"a"}
                            data={association
                              .filter((item) => item.product == "PMS")
                              .map((item) => item.volume)}
                          />
                        )}

                        {tabIndex == 2 && (
                          <DoughnutChart
                            title={"Sales by association"}
                            type={"a"}
                            data={association
                              .filter((item) => item.product == "AGO")
                              .map((item) => item.volume)}
                          />
                        )}
                        {tabIndex == 3 && (
                          <DoughnutChart
                            title={"Sales by association"}
                            type={"a"}
                            data={association
                              .filter((item) => item.product == "DPK")
                              .map((item) => item.volume)}
                          />
                        )}
                        {tabIndex == 4 && (
                          <DoughnutChart
                            title={"Sales by association"}
                            type={"a"}
                            data={association
                              .filter((item) => item.product == "LPG")
                              .map((item) => item.volume)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Stats */}
              <div className="d-block p-0 rounded-2 mt-3">
                <div className="row m-0 d-flex justify-content-between align-items-start  stats-cover">
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                    <div className="stats-container px-3 py-2 bg-white rounded-3">
                      <div className="stats-t text-center pt-1">
                        Top five companies (Litres)
                      </div>
                      {dataLoading.stats ? (
                        Array.from(Array(6)).map((_, i) => (
                          <div
                            key={i}
                            className="stats-e my-1 py-2 d-flex justify-content-between align-items-center"
                          >
                            <Skimmer width={"100%"} height={70} />
                          </div>
                        ))
                      ) : (
                        <>
                          {tabIndex === 1 && (
                            <div className="">
                              {topCompany
                                .filter((item) => item.product === "PMS")
                                .map((item, i) => (
                                  <div
                                    className="stats-e my-1 py-2 d-flex justify-content-between align-items-center"
                                    key={i}
                                  >
                                    <div className="stats-left d-flex justify-content-center align-items-center">
                                      <div className="stats-i__c d-flex justify-content-center align-items-center">
                                        <img
                                          src={Buildimg}
                                          className="stats-img"
                                        />
                                      </div>
                                      <Link
                                        to={`/aggregate/${
                                          item?.companyId
                                        }/dashboard/${item?.company.replaceAll(
                                          " ",
                                          "_"
                                        )}`}
                                        className="stats-name mx-3"
                                      >
                                        {item.company}
                                      </Link>
                                    </div>
                                    <div
                                      className="stats-right"
                                      style={{
                                        color: color.greenColor,
                                      }}
                                    >
                                      {/* {numFormtter(item.volume)} */}
                                      {numFormtter(item.volume).toLocaleString(
                                        "en-US",
                                        {
                                          currency: "USD",
                                        }
                                      )}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                          {tabIndex === 2 && (
                            <div className="">
                              {topCompany
                                .filter((item) => item.product === "AGO")
                                .map((item, i) => (
                                  <div
                                    className="stats-e my-1 py-2 d-flex justify-content-between align-items-center"
                                    key={i}
                                  >
                                    <div className="stats-left d-flex justify-content-center align-items-center">
                                      <div className="stats-i__c d-flex justify-content-center align-items-center">
                                        <img
                                          src={Buildimg}
                                          className="stats-img"
                                        />
                                      </div>
                                      <Link
                                        to={`/aggregate/${item?.companyId}/dashboard`}
                                        className="stats-name mx-3"
                                      >
                                        {item.company}
                                      </Link>
                                    </div>
                                    <div
                                      className="stats-right"
                                      style={{
                                        color: color.greenColor,
                                      }}
                                    >
                                      {item.amount.toLocaleString("en-US", {
                                        currency: "USD",
                                      })}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                          {tabIndex === 3 && (
                            <div className="">
                              {topCompany
                                .filter((item) => item.product === "DPK")
                                .map((item, i) => (
                                  <div
                                    className="stats-e my-1 py-2 d-flex justify-content-between align-items-center"
                                    key={i}
                                  >
                                    <div className="stats-left d-flex justify-content-center align-items-center">
                                      <div className="stats-i__c d-flex justify-content-center align-items-center">
                                        <img
                                          src={Buildimg}
                                          className="stats-img"
                                        />
                                      </div>
                                      <Link
                                        to={`/aggregate/${item?.companyId}/dashboard`}
                                        className="stats-name mx-3"
                                      >
                                        {item.company}
                                      </Link>
                                    </div>
                                    <div
                                      className="stats-right"
                                      style={{
                                        color: color.greenColor,
                                      }}
                                    >
                                      {numFormtter(item.volume)}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                          {tabIndex === 4 && (
                            <div className="">
                              {topCompany
                                .filter((item) => item.product === "LPG")
                                .map((item, i) => (
                                  <div
                                    className="stats-e my-1 py-2 d-flex justify-content-between align-items-center"
                                    key={i}
                                  >
                                    <div className="stats-left d-flex justify-content-center align-items-center">
                                      <div className="stats-i__c d-flex justify-content-center align-items-center">
                                        <img
                                          src={Buildimg}
                                          className="stats-img"
                                        />
                                      </div>
                                      <div className="stats-name mx-3">
                                        {item.company}
                                      </div>
                                    </div>
                                    <div
                                      className="stats-right"
                                      style={{
                                        color: color.greenColor,
                                      }}
                                    >
                                      {numFormtter(item.volume)}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                        </>
                      )}

                      <div className="row mt-3 align-items-center justify-content-center">
                        <div className="col-sm-5 col-md-5 col-lg-3 col-xl-3">
                          {dataLoading.stats ? (
                            <Skimmer width={200} height={70} />
                          ) : (
                            <Link to="/aggregate/tabledata/company/nigeria/00000000-0000-0000-0000-000000000000">
                              <Button
                                text={"All Companies"}
                                color={color.baseColor}
                                bg={color.whiteColor}
                                fontSize={"14px"}
                                border={`1px solid ${color.baseColor}`}
                              />
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                    <div className="stats-container px-3 py-2 bg-white rounded-3">
                      <div className="stats-t text-center pt-1">
                        Top five states (Litres)
                      </div>

                      {dataLoading.stats ? (
                        Array.from(Array(6)).map((_, i) => (
                          <div
                            key={i}
                            className="stats-e my-1 py-2 d-flex justify-content-between align-items-center"
                          >
                            <Skimmer width={"100%"} height={70} />
                          </div>
                        ))
                      ) : (
                        <>
                          {tabIndex === 1 && (
                            <div className="">
                              {topState
                                .filter((item) => item.product === "PMS")
                                .map((item, i) => (
                                  <div
                                    className="stats-e my-1 py-2 d-flex justify-content-between align-items-center"
                                    key={i}
                                  >
                                    <div className="stats-left d-flex justify-content-start align-items-center">
                                      <div className="stats-i__c d-flex justify-content-center align-items-center">
                                        <img
                                          src={StateImg}
                                          className="stats-img"
                                        />
                                      </div>
                                      <div className="stats-name mx-3">
                                        {item.state == "FCT"
                                          ? "FCT"
                                          : item.state + " state"}
                                      </div>
                                    </div>
                                    <div
                                      className="stats-right"
                                      style={{
                                        color: color.baseColor,
                                      }}
                                    >
                                      {numFormtter(item.volume)}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                          {tabIndex === 2 && (
                            <div className="">
                              {topState
                                .filter((item) => item.product === "AGO")
                                .map((item, i) => (
                                  <div
                                    className="stats-e my-1 py-2 d-flex justify-content-between align-items-center"
                                    key={i}
                                  >
                                    <div className="stats-left d-flex justify-content-start align-items-center">
                                      <div className="stats-i__c d-flex justify-content-center align-items-center">
                                        <img
                                          src={StateImg}
                                          className="stats-img"
                                        />
                                      </div>
                                      <div className="stats-name mx-3">
                                        {item.state == "FCT"
                                          ? "FCT"
                                          : item.state + " state"}
                                      </div>
                                    </div>
                                    <div
                                      className="stats-right"
                                      style={{
                                        color: color.baseColor,
                                      }}
                                    >
                                      {numFormtter(item.volume)}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                          {tabIndex === 3 && (
                            <div className="">
                              {topState
                                .filter((item) => item.product === "DPK")
                                .map((item, i) => (
                                  <div
                                    className="stats-e my-1 py-2 d-flex justify-content-between align-items-center"
                                    key={i}
                                  >
                                    <div className="stats-left d-flex justify-content-start align-items-center">
                                      <div className="stats-i__c d-flex justify-content-center align-items-center">
                                        <img
                                          src={StateImg}
                                          className="stats-img"
                                        />
                                      </div>
                                      <div className="stats-name mx-3">
                                        {item.state == " FCT"
                                          ? "FCT"
                                          : item.state + " state"}
                                      </div>
                                    </div>
                                    <div
                                      className="stats-right"
                                      style={{
                                        color: color.baseColor,
                                      }}
                                    >
                                      {numFormtter(item.volume)}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                          {tabIndex === 4 && (
                            <div className="">
                              {topState
                                .filter((item) => item.product === "LPG")
                                .map((item, i) => (
                                  <div
                                    className="stats-e my-1 py-2 d-flex justify-content-between align-items-center"
                                    key={i}
                                  >
                                    <div className="stats-left d-flex justify-content-start align-items-center">
                                      <div className="stats-i__c d-flex justify-content-center align-items-center">
                                        <img
                                          src={StateImg}
                                          className="stats-img"
                                        />
                                      </div>
                                      <div className="stats-name mx-3">
                                        {item.state == "FCT"
                                          ? "FCT"
                                          : item.state + "state"}
                                      </div>
                                    </div>
                                    <div
                                      className="stats-right"
                                      style={{
                                        color: color.baseColor,
                                      }}
                                    >
                                      {numFormtter(item.volume)}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                        </>
                      )}
                      <div className="row mt-3 align-items-center justify-content-center">
                        <div className=" col-sm-5 col-md-5 col-lg-3 col-xl-3">
                          {dataLoading.stats ? (
                            <Skimmer width={200} height={70} />
                          ) : (
                            <Link to="/aggregate/tabledata/nigeria">
                              <Button
                                text={"All States"}
                                color={color.baseColor}
                                bg={color.whiteColor}
                                fontSize={"14px"}
                                border={`1px solid ${color.baseColor}`}
                              />
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
