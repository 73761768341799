import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import { color } from "../../../constant/color";
import moment from "moment";
import { numFormtter } from "../../../helperFunctions/numFormatter";

const LineCharts = ({ data, percentagePriceChange, tag }) => {
  // console.log(data, "line 8");
  const switchBg = (tag) => {
    switch (tag) {
      case "pms":
        return `#76e7763d`;
      case "ago":
        return `#afd9ff`;
      case "dpk":
        return `#ffd0c8`;
      case "lpg":
        return `#d2d2d2`;
      default:
        return `white`;
    }
  };
  // let date = [
  //   moment("2022-05-05T00:00:00Z").format("lll"),
  //   moment("2022-05-04T00:00:00Z").format("lll"),
  //   moment("2022-05-03T00:00:00Z").format("lll"),
  // ];
  return (
    <>
      <Line
        data={{
          labels: data.map((item) => item.date), // x axis
          datasets: [
            {
              type: "line",
              label: "",
              fill: true,
              backgroundColor: switchBg(tag),
              data: data.map((item) => item.volume),
              borderColor:
                tag === "pms"
                  ? color.greenColor
                  : tag === "ago"
                  ? color.blue
                  : tag === "dpk"
                  ? color.redColor
                  : tag === "lpg"
                  ? "#000000"
                  : "dodgerblue",
              radius: 1,
              borderWidth: 1,
              pointBorderWidth: 1,
              tension: 0.4,
            },
          ],
        }}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: false,
            title: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: function (tooltipItem, data) {
                  return numFormtter(parseInt(tooltipItem.parsed.y));
                },
              },
            },
          },
          scales: {
            x: {
              display: false,
              grid: {
                display: false,
              },
            },
            y: {
              display: false,
              grid: {
                display: false,
              },
            },
          },
        }}
      />
    </>
  );
};

export default LineCharts;

// x: {
//   type: "time",
// },
// x: {
//   display: false,
//   grid: {
//     display: false,
//   },
// type: "time",
// ticks: {
//   callback: (value, index, ticks) => {
//     return "Name";
//   },
// },
// },
// y: {
//   display: false,
//   grid: {
//     display: false,
//   },
// },
