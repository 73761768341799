export const data = [
  {
    state: "ESE-ODO",
    path: {
      id: "Path_6949",
      d: "M222.121,382.89l-5.09-1.382h-8.483l-5.09-4.148-3.393-6.914-1.7-4.149c-1.7-3.226-5.091-9.955-5.091-11.061s-4.524-8.757-6.786-12.445l-11.876-8.3-6.787,2.766v6.914L155.953,358v12.445l8.483,11.061-20.358,19.358L162.74,422.99l6.787,6.914,22.055,23.506,18.662,17.975,13.574,13.828,3.392,1.382,8.484,9.679,6.786,2.766,3.393-6.914,3.393-5.531a24.261,24.261,0,0,0,1.7-5.531c0-1.382,3.393-1.382,5.089-2.764s0-2.766,0-5.531,3.393-4.148,3.393-5.531c0-1.107,2.262-4.148,3.393-5.531l3.393-4.148,1.7-4.148c1.132-1.844,3.393-5.807,3.393-6.914v-6.913l-5.09-1.383-3.393-11.061H242.48V422.99l-1.7-6.914-3.393-2.766-6.787-4.149-6.786-2.764-8.483-2.766c-2.263-.462-6.787-1.936-6.787-4.148v-2.766h3.393v-2.766l5.09-1.383-1.7-1.382v-5.531h6.786Z",
      translate: { x: "476.941", y: "761.183" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ESE-ODO-2",
      name: "ESE-ODO",
      fill: "#001755",
      translate: { x: "756.008", y: "1218.69" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "653.889", y: "1185.297" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ILAJE",
    path: {
      id: "Path_6950",
      d: "M73.643,293.9l8.483-5.531,6.786,1.383,3.393,9.679-1.7,6.913-5.09,5.53-3.393,8.3,8.483,9.679,5.09,11.061,27.146,27.655,11.876,4.148,18.663,2.766,10.179-2.766,22.055-6.914,6.787-6.913,13.572-6.914v6.914L194.1,372.711v12.445l8.483,11.061-20.358,19.358,3.392,5.531,30.54,33.185,49.2,48.394,13.572,12.445c-2.828,3.688-8.483,11.338-8.483,12.445s-7.917,11.523-11.876,16.593l-1.7,6.914,1.7,9.679-5.09,2.766h-5.091V558L245,555.23l-11.876-12.444c-32.236-26.733-97.046-80.752-98.4-82.965S108.141,444.151,95.7,437.7l-10.18-6.914c-3.959-2.305-12.555-7.467-15.269-9.679-3.393-2.766-3.393-4.149-5.09-8.3-1.357-3.318-5.09-1.382-6.786,0,0-.922-1.357-2.766-6.786-2.766-6.787,0-11.876-6.914-15.269-9.679-2.714-2.212-1.131-4.608,0-5.53,5.09,0,15.609.276,16.966,1.382l5.09,4.148c1.357,1.107,8.483.462,11.876,0,1.131-1.382,4.072-4.425,6.786-5.53,3.393-1.383,0-5.531,0-6.915,0-1.105-2.262-2.3-3.393-2.764-1.7-.922-5.768-3.043-8.483-4.149-3.393-1.382-10.18,0-13.573,0H39.711l-15.269-2.764v-8.3c0-3.318,2.262-4.148,3.393-4.148l8.483-2.766H51.587l13.573-8.3c5.429-3.318,5.655-3.226,5.09-2.764l6.786-2.766h5.09l3.393-2.766-3.393-9.679-5.09-1.383V314.636l5.09-5.531V296.661Z",
      translate: { x: "438.793", y: "746.473" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ILAJE-2",
      name: "ILAJE",
      fill: "#001755",
      translate: { x: "561.568", y: "1149.053" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M",
      name: "5M",
      fill: "#001755",
      translate: { x: "567.059", y: "1169.418" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OKITIPUPA",
    path: {
      id: "Path_6951",
      d: "M74.966,297.056H68.18l3.393-5.531,5.09-5.53v-5.531l1.7-5.531,5.09-4.148,8.483-9.679,11.876,2.766,10.18,2.766h11.876V269.4l5.089,9.679,6.786,12.444,10.18,8.3,6.786,8.3,5.091,5.53h20.359l5.09-5.53,3.393,2.764-3.393,9.679v15.211l-11.876,6.914-3.393,11.061,3.393,8.3,3.393,8.3-6.787,5.531-6.786,1.383L149.616,381.4c-3.393.922-11.2,2.489-15.269,1.382-5.091-1.382-11.876,0-15.27-1.382l-10.18-4.148c-2.828-3.227-9.162-10.233-11.876-12.445a25.252,25.252,0,0,1-5.09-5.531,66.656,66.656,0,0,0-8.483-8.3c-4.072-3.32-5.09-6.915-5.09-8.3l-3.393-6.914-6.786-5.531v-5.53l3.393-4.149,3.393-1.382v-2.766l3.393-5.531Z",
      translate: { x: "452.74", y: "737.781" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OKITIPUPA-2",
      name: "OKITIPUPA",
      fill: "#001755",
      translate: { x: "556.293", y: "1063.326" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4M",
      name: "4M",
      fill: "#001755",
      translate: { x: "560.25", y: "1073.512" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IRELE",
    path: {
      id: "Path_6952",
      d: "M259.036,273.26l-11.876-6.913-6.786,6.913-5.09,6.915-6.787,9.678c-5.655,1.383-17.3,3.872-18.662,2.766s-9.615-2.3-13.574-2.766H175.9l-8.483,5.531-5.09,6.913v6.914l-1.7,13.828V334.1l-8.483,2.766-3.393,5.53-3.393,5.531v8.3l3.393,2.764,3.393,8.3,3.392-2.766,5.091-2.766,6.786-2.766c1.7-.921,5.43-2.764,6.787-2.764s5.09,3.686,6.787,5.53l5.09,2.766,5.089,9.679,5.089,11.061,3.393,5.531,1.7,6.913,6.787,5.531h8.483l-5.09-4.148,1.7-1.384h3.393l6.787-2.766v-5.53h16.966l6.786-4.149V371.434a21.263,21.263,0,0,1,11.876-9.679c4.073-1.105,3.959-2.3,3.393-2.766l5.09-2.764V342.4c1.7,0,5.091-.553,5.091-2.764,0-2.766,0-8.3-3.393-11.063a25.231,25.231,0,0,1-5.09-5.53l-1.7-8.3h-8.483l-3.393-4.148v-5.531c-1.13-2.766-2.035-8.85,3.393-11.061,2.714-1.107,3.393-4.149,3.393-5.531l1.7-11.061Z",
      translate: { x: "477.352", y: "739.453" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "IRELE-2",
      name: "IRELE",
      fill: "#001755",
      translate: { x: "674.99", y: "1065.964" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M",
      name: "3M",
      fill: "#001755",
      translate: { x: "683", y: "1087.661" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ODIGBO",
    path: {
      id: "Path_6953",
      d: "M76.347,247.356l3.393-5.531H98.4l6.786-4.148,11.876,1.382h11.876l10.179-1.382,3.393-2.766,22.057-8.3,6.786-5.53,15.27-12.445h18.662l8.483-4.148,15.27,1.382,6.787,5.531,6.786,5.53,6.787,5.531,6.787,1.382v13.828l5.09,12.443L271.456,268.1h6.787l5.09,11.061,6.787,9.679-6.787,9.679-8.483,11.063-13.572,4.148-18.663-4.148H222.254l-6.786,4.148-6.787,9.679c-1.13,2.766-4.071,8.3-6.786,8.3a67.91,67.91,0,0,0-10.18,1.383L178.143,331.7l-8.483-13.827c-1.7-2.766-5.769-8.3-8.483-8.3s-7.918-10.141-10.18-15.211l-3.393-5.531v-4.148H135.729c-3.393,0-8.483,0-11.877-2.766-2.715-2.212-7.918-2.766-10.18-2.766l-5.09,5.531-3.393,4.148L98.4,294.368v11.061l-6.786,8.3-15.269,9.679-5.09,8.3-10.18,6.913L40.718,341.38H16.966l-8.483-8.3L0,323.405v-5.531l3.393-8.3v-4.149L0,298.516l8.483-9.679,8.483-4.148,5.09-5.531,18.663-12.444,6.786-2.766,13.573-2.766,11.876-6.913Z",
      translate: { x: "431", y: "719.729" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ODIGBO-2",
      name: "ODIGBO",
      fill: "#001755",
      translate: { x: "593.221", y: "968.367" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_4.5M",
      name: "4.5M",
      fill: "#001755",
      translate: { x: "590.582", y: "979.553" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ONDO_WEST",
    path: {
      id: "Path_6954",
      dataName: "ONDO WEST",
      d: "M194.437,176.715l3.393-5.531h11.876l5.09,4.148,6.787,11.063L213.1,204.37l-3.392,15.211h-15.27l-10.179,4.148H167.291l-5.09,4.148-15.269,12.445c-9.049,2.766-27.146,8.85-27.146,11.063s-22.621.922-33.932,0l-6.786,4.148H60.405V244.47l-5.09-4.149V219.58l5.09-4.148L72.282,209.9H85.854V198.84l5.09-16.593,13.573-11.063,15.269-9.679h32.236l10.179,9.679,11.876,5.531,10.18,5.531h10.179Z",
      translate: { x: "448.639", y: "706.022" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "ONDO_WEST-2",
      name: "ONDO WEST",
      fill: "#001755",
      translate: { x: "548.895", y: "907.699" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-2",
      name: "3M",
      fill: "#001755",
      translate: { x: "565.395", y: "927.885" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "LE-OLUJI_OKEIGBO",
    path: {
      id: "Path_6956",
      dataName: "LE-OLUJI/OKEIGBO",
      d: "M208.715,88.116H215.5l8.483,9.679,3.393,5.531,5.09,9.679,1.7,12.445-1.7,9.679-5.09,11.063-3.393,6.913-5.089,5.53-10.18,4.149-6.786,2.764H179.874l-10.18,2.766H164.6l-5.09-2.766v-9.679l1.7-6.914,5.09-8.3,3.393-5.531V121.3l3.393-8.3,6.787-4.148,6.786-1.382h6.787l8.483-1.383,6.786,1.383h5.091l1.7-4.149V100.56l-6.787-2.765V88.116Z",
      translate: { x: "481.863", y: "682.62" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "LE-OLUJI_OKEIGBO-2",
      name: "LE-OLUJI/OKEIGBO",
      fill: "#001755",
      translate: { x: "550.418", y: "776.663" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M",
      name: "2M",
      fill: "#001755",
      translate: { x: "0.747,", y: "-0.665," },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IFEDORE",
    path: {
      id: "Path_6957",
      d: "M230.808,84.971h44.111V98.8l-1.7,8.3-11.876,9.679-10.179,9.679-15.27,8.3-8.483,6.913-1.7,9.679-6.787,1.382-5.09-1.382,1.7-8.3,5.09-11.061V119.539c-1.13-4.148-3.732-12.72-5.09-13.827-1.7-1.383-3.393-5.531-8.483-8.3-4.071-2.212-5.089-7.375-5.089-9.679h15.27Z",
      translate: { x: "495.4", y: "681.617" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "IFEDORE-2",
      name: "IFEDORE",
      fill: "#001755",
      translate: { x: "718.709", y: "754.082" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-2",
      name: "2M",
      fill: "#001755",
      translate: { x: "726.209", y: "789.886" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "IDANRE",
    path: {
      id: "Path_6958",
      d: "M224.786,136.343c-2.262.922-7.465,2.212-10.18,0l-6.786,6.914,6.786,2.766-1.7,19.358-5.09,5.531-8.483,4.148-1.7,13.827v17.975l3.393,5.531v17.976l-5.09,5.53-1.7,9.679v8.3l1.7,9.679L201.034,276l6.787,13.827H216.3l1.7,4.148,3.393,5.531,6.787,8.3,6.786,1.384,8.483,4.148,6.787-4.148,23.753-15.211-3.393-5.531,8.483-2.766,3.393-2.764h5.09V276c0-2.213-3.393-.922-5.09,0v-6.915l5.09-2.764v-6.915H397.839v4.149h11.876v-4.149H418.2l6.787,6.915,1.7,6.913,3.393-2.766,8.483-6.914-5.09-4.149-8.483-4.148-6.787-8.3L409.715,227.6l-8.483-15.211-11.876-20.741-6.786-2.766L365.6,186.121l-18.663-4.148L316.4,179.208l-23.753-5.531L279.078,164l-23.753-9.679-18.662-11.061Z",
      translate: { x: "492.939", y: "697.999" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "IDANRE-2",
      name: "IDANRE",
      fill: "#001755",
      translate: { x: "780.5", y: "911.656" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_5M-2",
      name: "5M",
      fill: "#001755",
      translate: { x: "783.139", y: "923.16" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "AKURE_SOUTH",
    path: {
      id: "Path_6959",
      dataName: "AKURE SOUTH",
      d: "M262.391,108.279l6.787-9.679,1.7,13.827,1.7,8.3,1.7,6.913,1.7,6.914,3.393,4.148,5.09,6.914,6.787,9.679,10.179,6.913,8.483,4.149,6.787,4.148,8.483,5.531,5.09,6.913,5.091,5.53,1.7,2.766,3.393,2.766h-10.18l-11.876-1.382-20.358-4.149-11.876-2.764-5.09-2.766-6.787-5.531-8.483-4.148c-5.09-2.305-15.948-7.19-18.663-8.3-3.392-1.382-11.876-5.53-13.572-6.913a54.959,54.959,0,0,0-10.179-5.531c-3.393-1.382-3.393-4.148-3.393-5.53v-4.149l3.393-6.914,11.875-8.3,11.876-6.913Z",
      translate: { x: "501.143", y: "685.963" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "AKURE_SOUTH-2",
      name: "AKURE SOUTH",
      fill: "#001755",
      translate: { x: "743.721", y: "814.188" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-3",
      name: "2M",
      fill: "#001755",
      translate: { x: "755.707", y: "837.065" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "AKURE_NORTH",
    path: {
      id: "Path_6960",
      dataName: "AKURE NORTH",
      d: "M348.9,139.6l10.179-2.766-6.787,6.913v29.038l1.7,6.914,3.393,1.383,1.7,5.53v5.531H382.83v2.766l-5.09,2.766v5.53h-6.787l-3.393,2.766-8.483-1.382H348.9l-15.27-4.149-8.483-2.764v-2.766l-6.787-6.914-6.786-8.3c-6.787-4.148-21.039-12.445-23.753-12.445-3.393,0-8.483-6.913-10.179-8.3s-5.091-9.679-10.18-15.211-1.7-4.148-3.393-6.913a56.75,56.75,0,0,1-3.393-6.914l-1.7-13.827a22.648,22.648,0,0,1-1.7-6.914V88.437c0-5.531,1.13-5.992,1.7-5.531h52.6a20.616,20.616,0,0,1,11.876,4.148l6.786,9.679V131.3l10.18,5.531Z",
      translate: { x: "513.039", y: "680.916" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "AKURE_NORTH-2",
      name: "AKURE NORTH",
      fill: "#001755",
      translate: { x: "835.797", y: "731.101" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-3",
      name: "3M",
      fill: "#001755",
      translate: { x: "816.119", y: "806.432" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OSE",
    path: {
      id: "Path_6961",
      d: "M382.251,274.785l-6.787-4.149,3.393-2.764,3.393-11.063c1.13-2.766,3.392-8.85,3.392-11.061v-9.679l3.393-6.914,18.663-4.148V204.265a5.576,5.576,0,0,1,1.7-4.148l13.572-2.766,10.18,1.384,1.7-8.3h-6.786v-2.766l1.7-5.531,10.179-4.148,6.787-2.766,5.089-2.764H458.6L451.81,169.7c-2.262-1.383-6.786-4.7-6.786-6.914v-2.766l-5.091-8.3h-6.786c3.393,0-3.393-3.318-3.393-5.53v-4.149l-5.09-6.913V129.6l5.09-2.764,8.483-1.383,8.483-2.766,13.572-6.913,11.876-8.3,11.876-6.914,3.393-5.531V92.264l1.7-4.148,5.09,4.148,15.27,9.679,22.055,9.679,16.966,5.531,6.787-1.383,1.7,6.913-1.7,8.3-5.09,2.766-5.089,4.148-6.787,4.148-6.787,2.766h-6.786v6.913l-6.787,4.148-5.089,4.149,5.089,5.53-1.7,6.915v5.53l-5.09,4.148v6.915L506.1,193.2h-3.393v5.531h-3.392l-3.393,4.148,3.393,4.148v8.3l-3.393,1.382-11.876,1.382v6.915l-6.787,2.764-1.7,8.3v23.506h5.089v8.3l-6.786,1.382,1.7,9.679c-1.132.46-4.751,1.107-10.18,0s-4.524.46-3.392,1.382L456.9,283.08v23.506l-5.09,9.679-6.786,2.766c-2.263.922-7.126,3.318-8.483,5.531s-7.353.922-10.18,0L416.183,321.8c.564-1.382,1.357-4.425,0-5.531a24.419,24.419,0,0,0-5.091-2.766h-6.786l-3.393,5.531a10.514,10.514,0,0,1-6.787,5.531c-4.071,1.107-5.089,3.226-5.089,4.148l-6.786,4.148-6.787-1.382a78.875,78.875,0,0,1-5.09-6.913,15.087,15.087,0,0,1-1.7-6.914l-3.393-11.063h-5.09v-6.914c0-2.212,2.262-5.53,3.393-6.914l5.09-2.766,6.787-2.766,3.393-4.148,6.786-4.148Z",
      translate: { x: "545.854", y: "682.62" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OSE-2",
      name: "OSE",
      fill: "#001755",
      translate: { x: "1000.75", y: "880.003" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3.5M",
      name: "3.5M",
      fill: "#001755",
      translate: { x: "992.838", y: "893.827" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "OWO",
    path: {
      id: "Path_6962",
      d: "M364.949,119.523l13.573-4.148H424.33l3.393,1.382,3.393,2.766,3.393,5.531,1.7,5.53,3.393,4.149v5.53l5.09,2.766h5.09l3.393,4.148,1.7,5.531,1.7,5.531,5.089,2.766,5.09,2.764-8.483,1.383-6.786,4.148-8.483,2.766-5.091,2.766v8.3h6.787l-1.7,6.914c-1.7,0-5.43-.276-6.787-1.382s-3.959-.46-5.09,0l-6.787,1.382-6.786,2.766v23.506l-8.484,2.766-11.875,1.383-1.7,12.445c-.566,5.53-2.035,16.868-3.393,17.975s-1.7,5.07-1.7,6.914l-5.09,4.148-5.09-1.382-3.393-5.531-6.787-8.3-3.393-9.679-3.393-4.148-3.392-8.3-5.091-11.061-5.09-11.063-5.089-5.53,3.393-4.149h6.786v-4.148l3.393-2.766,1.7-4.148H336.107l-1.7-2.766v-6.914L329.32,169.3V131.968c0-3.318,3.393-5.07,5.091-5.531l8.483-1.382Z",
      translate: { x: "536.01", y: "691.313" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "OWO-2",
      name: "OWO",
      fill: "#001755",
      translate: { x: "909.75", y: "851.988" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-2",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "905.793", y: "864.812" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "AKOKO_SOUTHWEST",
    path: {
      id: "Path_6963",
      dataName: "AKOKO SOUTHWEST",
      d: "M485.8,93.419l-1.7-4.148-1.7-6.914V72.678l1.7-2.765,3.393-2.765H477.316l-8.483,2.765h-5.091l-5.089,2.765H406.059l-8.483,1.383-3.393,15.21L390.789,94.8,384,107.247l-8.483,9.68-6.786,8.3-3.393,5.531h47.5l3.393,1.382c1.132,1.384,3.732,4.149,5.089,4.149s3.959-1.844,5.091-2.766l6.786-1.384c2.262,0,7.126-.276,8.483-1.382s5.09-2.305,6.787-2.766c2.828-1.382,8.822-4.425,10.179-5.531,1.7-1.382,8.483-4.148,10.18-6.913s5.09-2.766,6.786-4.148,5.091-2.766,5.091-4.148c0-1.106,3.392-3.226,5.089-4.148V93.419Z",
      translate: { x: "547.496", y: "675.934" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "AKOKO_SOUTHWEST-2",
      name: "AKOKO SOUTHWEST",
      fill: "#001755",
      translate: { x: "0.982,", y: "-0.189," },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-4",
      name: "3M",
      fill: "#001755",
      translate: { x: "0.982,", y: "-0.189," },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "AKOKO_SOUTHEAST",
    path: {
      id: "Path_6964",
      dataName: "AKOKO SOUTHEAST",
      d: "M540.629,67.436V61.905l-10.18,2.765-13.572,2.766L505,68.819H465.978L457.5,70.2,454.1,74.35V88.177l1.7,5.531,5.091,4.148,5.09,4.148,6.786,4.148,6.787,4.148,8.483,4.148,8.483,2.766,6.787,4.148,5.09,1.384c2.262.922,7.465,2.766,10.179,2.766h5.091V114.449c-1.132-.922-3.393-3.042-3.393-4.148v-4.148l3.393-5.531V95.091l-5.091-1.383-6.786-4.148-1.7-2.765V77.115l3.393-2.765V70.2h23.753Z",
      translate: { x: "575.799", y: "674.263" },
      fill: "#82c91e",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "AKOKO_SOUTHEAST-2",
      name: "AKOKO SOUTHEAST",
      fill: "#001755",
      translate: { x: "1109.758", y: "774.192" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2M-4",
      name: "2M",
      fill: "#001755",
      translate: { x: "1062.293", y: "777.096" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "AKOKO_NORTHEAST",
    path: {
      id: "Path_6965",
      dataName: "AKOKO NORTHEAST",
      d: "M531.009,24.117a19.2,19.2,0,0,1-3.392-4.148l-1.7,6.914v2.766l-3.393,1.383-1.7,5.531L510.65,47.623l-3.392,5.531L498.775,57.3l-6.787,1.383L485.2,61.45l-8.483,1.383h-8.483l-15.268,1.383L434.3,65.6H417.337l-13.572-2.765-5.09-4.148-1.7,1.383L393.585,65.6l-5.09,11.062V87.722H446.18c3.393,0,3.393,1.383,6.787-1.383l1.7-1.383h6.787l8.483-2.765h8.483c2.828.461,8.822,1.106,10.179,0,1.7-1.383,15.27,0,20.359,0H520.83l15.27-1.383,8.483-1.383,10.179-2.765c2.262-.461,7.126-1.659,8.483-2.766a4.1,4.1,0,0,0,1.7-2.765l5.09-2.766,5.09-5.531-3.393-4.148-5.09-4.148-10.18-11.062c-1.7-1.383-1.7-2.766-3.392-4.148s-1.7-2.765-16.966-12.445C534.4,25.5,532.707,25.5,531.009,24.117Z",
      translate: { x: "554.879", y: "660.89" },
      fill: "#fa5252",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "AKOKO_NORTHEAST-2",
      name: "AKOKO NORTHEAST",
      fill: "#001755",
      translate: { x: "0.997,", y: "-0.071," },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_2.5M-3",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "0.997,", y: "-0.071," },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "AKOKO_NORTHWEST",
    path: {
      id: "Path_6966",
      dataName: "AKOKO NORTHWEST",
      d: "M408,44.247l-8.483,11.062L396.5,66.371l6.41,5.531H408l5.091,1.383h13.572c4.524-.461,14.93-1.383,20.359-1.383,6.786,0,8.483-1.383,11.876-1.383s10.179,1.383,11.876,0c1.357-1.106,6.22-1.383,8.483-1.383,3.392-.461,10.518-1.659,11.875-2.766,1.7-1.383,6.787-1.383,6.787-2.765,0-1.106,4.525-2.3,6.787-2.766A81.158,81.158,0,0,1,514.885,48.4c3.393-2.765,1.7-1.383,3.393-4.148s1.7-1.383,1.7-4.148,3.393-1.383,3.393-2.765-1.7-2.766,0-4.148,1.7-1.383,1.7-2.765V27.655l-1.7-5.531a4.1,4.1,0,0,0-1.7-2.765c-1.357-1.106.566-3.226,1.7-4.148V12.445l1.7-2.765L528.457,8.3V6.914l3.393-1.383V2.765l-3.393-1.383h-1.7L523.367,0h-8.483l-3.393,2.765L508.1,5.531H484.346l-3.393,4.148-6.787,2.765-3.392,5.531-1.7,2.765a6.228,6.228,0,0,1-3.393,1.383H457.2V15.21l-1.7-2.765h-8.483l-8.483,2.765V27.655a24.328,24.328,0,0,0-5.089,2.766c-1.358,1.106-7.353,3.226-10.18,4.148l-6.786,4.148Z",
      translate: { x: "557.432", y: "654.523" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "AKOKO_NORTHWEST-2",
      name: "AKOKO NORTHWEST",
      fill: "#001755",
      translate: { x: "989.313", y: "651.576" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "_3M-5",
      name: "3M",
      fill: "#001755",
      translate: { x: "1016.939", y: "698.792" },
      tspan: { X: "0", y: "0" },
    },
  },
  {
    state: "ONDO_EAST",
    path: {
      id: "Path_6955",
      dataName: "ONDO EAST",
      d: "M163.318,157.13v-6.913L171.8,151.6l11.875-2.766h20.359l16.966-8.3,5.091,5.531-1.7,17.975L221,168.191l-10.179,5.531-1.7,12.445v17.975l3.392,8.3v17.975l-6.786,5.531v8.3H202.34l-6.787-2.764-6.786-5.531-3.393-5.531-6.787-4.148v-4.148l1.7-13.827,3.392-4.149,1.7-4.148,3.393-9.679a19.225,19.225,0,0,0-3.393-4.148,4.1,4.1,0,0,1-1.7-2.766l-8.483-5.531H163.318l-5.09-5.53V157.13Z",
      translate: { x: "481.453", y: "699.336" },
      fill: "#ffc078",
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeWidth: "0.5",
      stroke: "#000",
    },
    textValue: {
      id: "_2.5M-4",
      name: "2.5M",
      fill: "#001755",
      translate: { x: "667.924", y: "868.693" },
      tspan: { X: "0", y: "0" },
    },
    textState: {
      id: "ONDO_EAST-2",
      name: "ONDO EAST",
      fill: "#001755",
      translate: { x: "565.049", y: "861.934" },
      tspan: { X: "0", y: "0" },
    },
  },
];
