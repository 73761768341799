import React, { useState } from "react";
import Layout from "../Layout";
import Options from "../Options";
import { DatePicker } from "antd";
import "./style.css";
import moment from "moment";
import { FaUserCircle } from "react-icons/fa";
import { color } from "../../constant/color";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";
import MobileNav from "../MobileNav";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { useLocation } from "react-router-dom";

const TopNav = ({
  date,
  setDate,
  setRefresh,
  loading,
  setLoading,
  showArrow,
  title,
  caption,
  showDate,
  dshbTitle,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="d-flex top-nav bg-white align-items-start justify-content-between shadow-sm px-1 py-3">
      {showModal && <MobileNav closeModal={setShowModal} />}
      <div className="left-c d-flex justify-content-start align-items-center">
        <div className="left-i__c d-flex align-items-center justify-content-center">
          {showArrow && (
            <div
              onClick={() => history.goBack()}
              style={{
                cursor: "pointer",
              }}
            >
              <RiArrowLeftSLine size={25} color={color.baseColor} />
            </div>
          )}
        </div>
        <div className="left-c__c">
          <div className="left-t__title">{title}</div>
          <div className="left-t__caption">
            {/* Petroleum product consumption dashboard */}
            {caption}
          </div>
        </div>
      </div>

      {/* right */}
      <div className="right-c mt-1 d-flex justify-content-end align-items-center">
        {showDate && (
          <>
            <div
              className="right-date "
              style={{
                width: "100%",
              }}
            >
              <DatePicker
                bordered={false}
                disabledTime={false}
                showTime={false}
                format={"YYYY-MM-DD"}
                style={{
                  width: "100%",
                }}
                placeholder="select a date"
                onChange={(e) => {
                  setDate((prev) => moment(e._d).format("YYYY-MM-DD"));
                  setRefresh((prev) => !prev);
                }}
              />
            </div>
            <div className="profile-i d-none">
              <div className="profile-cover d-flex justify-content-center align-items-center">
                <FaUserCircle size={30} color={color.baseColor} />
              </div>
            </div>
          </>
        )}
        <div
          className="mobile-nav__btn mx-1 d-flex align-items-center justify-content-center"
          onClick={() => setShowModal(true)}
        >
          <HiOutlineMenuAlt3 size={25} color={color.baseColor} />
        </div>
      </div>
    </div>
  );
};

export default TopNav;
